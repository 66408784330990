import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  fetchS3KeyForEQ,
  uploadingToS3
} from "../../../../models/survey.model";
import FieldDropDown from "../../Fields/fieldDropdown.component";
import FieldDropDownWithSearch from "../../Fields/fieldDropdownWithSearch.component";
import FieldNumber from "../../Fields/fieldNumber.component";
import FieldText from "../../Fields/fieldText.component";
import { AddSurveyWizardStepConfigurationProps } from "../addSurveyWizard.component";
import AddSurveyWizardFooter from "../addSurveyWizardFooter.component";

interface StepConfigurationProps
  extends AddSurveyWizardStepConfigurationProps {}

const StepConfiguration: React.FC<StepConfigurationProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  surveyType,
  handleWizardBack,
  handleWizardSubmit,
  handleInputChange,
  // Fields
  activeDays,
  graceDays,
  bonusDays,
  minimumDailyCompletes,
  maximumDailyCompletes,
  surveySystem,
  surveySystemDetails,
  setSurveySystemDetails,
  pageValid,
  inputErrors,
  wscSurveyOptionsData,
  wscSurveysData,
  surveyName
}) => {
  //Dropzone
  const { clientID } = useParams();
  const [files, setFiles] = useState<File[]>([]);
  const allowedFileTypes = ["video/mp4"];
  const [fileInfo, setFileInfo] = useState({
    name: "",
    type: ""
  });
  const [previewURLs, setPreviewURLs] = useState<string[]>([]);

  const onFilesAdded = async (acceptedFiles: File[]) => {
    console.log(acceptedFiles);
    //We should allow for Images(png, jpg , JPEG) Audio(mp3, wav) Videos (mp4) and PDFs
    // Validate file types
    const invalidFiles = acceptedFiles.filter(
      (file) => !allowedFileTypes.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      Swal.fire({
        title: "Invalid File Type",
        text: "Please select a valid file type.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // Check file size
    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    if (oversizedFiles.length > 0) {
      Swal.fire({
        title: "File Too Large",
        text: "Please select a file smaller than 2GB.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // replace " " with "_"
    acceptedFiles = acceptedFiles.map((file) => {
      // Create a new Blob instance from the original file data
      const blob = new Blob([file], { type: file.type });
      // Create a new File instance with the modified name and original file properties
      return new File([blob], file.name.replace(/ /g, "_"), {
        type: file.type,
        lastModified: file.lastModified
      });
    });

    setFiles(acceptedFiles);

    // Create previews
    const urls = acceptedFiles.map((file) => URL.createObjectURL(file));
    setPreviewURLs(urls);

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFileInfo({
          name: file.name,
          type: "video"
        });
      };
    }
  };
  const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesAdded,
    maxSize: MAX_FILE_SIZE,
    multiple: false
  });

  const uploadEQ = async (acceptedFiles: File[]) => {
    //get access key from server
    if (!clientID) {
      Swal.fire({
        title: "Client ID not found",
        text: "Please select a client ID.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    let fileName = acceptedFiles[0].name;
    if (acceptedFiles[0].name.length > 25) {
      const fileExtension = "." + acceptedFiles[0].type.split("/")[1];
      fileName = acceptedFiles[0].name.substring(0, 25) + fileExtension;
    }

    Swal.fire({
      title: "Uploading File",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });
    const key = await fetchS3KeyForEQ(clientID, fileName);
    console.log(key);
    if (key.statusCode === 200) {
      const uploadToS3 = await uploadingToS3(key.signedURL, acceptedFiles);

      if (uploadToS3 === true) {
        Swal.fire({
          title: "Success",
          text: "File uploaded successfully.",
          icon: "success",
          confirmButtonColor: "#3085d6"
        });

        setSurveySystemDetails(key.key);
      } else {
        Swal.fire({
          title: "Error",
          text: "Error uploading file to server.",
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
        return;
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Error fetching key from server.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
    }
  };

  const onClickRemove = () => {
    setFiles([]);
    setPreviewURLs([]);
    setFileInfo({
      name: "",
      type: ""
    });
    setSurveySystemDetails("");
  };

  return (
    <div className="card card-default add_survey_form_page p-4">
      <h3 className="form_header">Survey Configuration: {surveyName}</h3>
      <div className="add_survey_form_page_inputs">
        {surveyType === "diary" && (
          <>
            <FieldNumber
              label="Active Days"
              inputName="activeDays"
              inputValue={activeDays ? activeDays : null}
              onInputChange={handleInputChange}
              min={1}
              error={inputErrors?.activeDays ? inputErrors.activeDays : null}
            />
            <FieldNumber
              label="Grace Days"
              inputName="graceDays"
              inputValue={graceDays ? graceDays : null}
              onInputChange={handleInputChange}
              min={0}
              error={inputErrors?.graceDays ? inputErrors.graceDays : null}
            />
            {/* <FieldNumber
              label="Bonus Days"
              inputName="bonusDays"
              inputValue={bonusDays ? bonusDays : null}
              onInputChange={handleInputChange}
              min={0}
              error={inputErrors?.bonusDays ? inputErrors.bonusDays : null}
            /> */}
            <FieldNumber
              label="Minimum Daily Completes"
              inputName="minimumDailyCompletes"
              inputValue={minimumDailyCompletes ? minimumDailyCompletes : null}
              onInputChange={handleInputChange}
              min={1}
              error={
                inputErrors?.minimumDailyCompletes
                  ? inputErrors.minimumDailyCompletes
                  : null
              }
            />
            <FieldNumber
              label="Maximum Daily Completes"
              inputName="maximumDailyCompletes"
              inputValue={maximumDailyCompletes ? maximumDailyCompletes : null}
              onInputChange={handleInputChange}
              min={1}
              error={
                inputErrors?.maximumDailyCompletes
                  ? inputErrors.maximumDailyCompletes
                  : null
              }
            />
          </>
        )}

        <FieldDropDown
          label="Survey System"
          tooltip="The platform linked to this survey for data collection. Options include default systems like Nfield or WSC, or a custom platform if using a non-standard survey system"
          tooltipPosition="bottom"
          inputName="surveySystem"
          inputValue={surveySystem ? surveySystem : ""}
          inputOptions={
            surveyType === "diary"
              ? wscSurveysData.filter((survey) => survey.id !== "viewEQ")
              : wscSurveysData
          }
          onInputChange={handleInputChange}
        />
        {surveySystem === "wsc" ? (
          <FieldDropDownWithSearch
            label="WSC Survey"
            tooltip="Specifies the survey source within the selected platform. This could be selected from a list or searched within the platform's interface"
            inputName="surveySystemDetails"
            inputValue={surveySystemDetails ? surveySystemDetails : ""}
            inputOptions={wscSurveyOptionsData}
            onInputChange={handleInputChange}
            error={
              inputErrors?.surveySystemDetails
                ? inputErrors.surveySystemDetails
                : null
            }
          />
        ) : surveySystem === "nfield" ? (
          <FieldText
            label="nField Survey"
            tooltip="Specifies the survey source within the selected platform. This could be selected from a list or searched within the platform's interface"
            inputName="surveySystemDetails"
            inputValue={surveySystemDetails ? surveySystemDetails : ""}
            onInputChange={handleInputChange}
            error={
              inputErrors?.surveySystemDetails
                ? inputErrors.surveySystemDetails
                : null
            }
          />
        ) : surveySystem === "custom" ? (
          <FieldText
            label="Custom Survey"
            tooltip="Specifies the survey source within the selected platform. This could be selected from a list or searched within the platform's interface"
            inputName="surveySystemDetails"
            inputValue={surveySystemDetails ? surveySystemDetails : ""}
            onInputChange={handleInputChange}
            error={
              inputErrors?.surveySystemDetails
                ? inputErrors.surveySystemDetails
                : null
            }
          />
        ) : surveySystem === "viewEQ" && surveyType === "single" ? (
          <div className="upload">
            <div {...getRootProps()} className="chat-upload-dropzone">
              <input {...getInputProps()} />
              {files.length === 0 && (
                <small className="dropzone-text">
                  Drag 'n' drop here, or click to select a file
                  <br />
                  Accepted File Types: Video types (MP4)
                </small>
              )}
              {/* Updated previews display */}
              <div className="preview-container">
                {files.map((file, index) => {
                  if (file.type.startsWith("video")) {
                    return (
                      <video
                        key={index}
                        src={previewURLs[index]}
                        controls
                        className="video-preview"
                      ></video>
                    );
                  } else {
                    return null;
                  }
                })}
              </div>
            </div>
            {files.length > 0 && (
              <div className="upload-buttons">
                <button
                  className="btn-eq"
                  onClick={() => {
                    onClickRemove();
                  }}
                >
                  Remove
                </button>
                <button
                  className="btn-eq"
                  onClick={() => {
                    uploadEQ(files);
                  }}
                >
                  Upload
                </button>
              </div>
            )}
          </div>
        ) : null}
        <AddSurveyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          handleWizardSubmit={handleWizardSubmit}
          pageValid={pageValid}
        />
      </div>
    </div>
  );
};

export default StepConfiguration;
