import { FC, useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { sendBulkImportedTranslations } from "../../../models/translations.model";
import {
  ConfigPhraseCategory,
  ImportPhrase,
  Language,
  Phrase
} from "../../../types";
import {
  getSystemConfigLanguages,
  getSystemConfigPhraseCategories
} from "../../../utilities/config.util";

interface ImportPhraseModalProps {
  show: boolean;
  handleClose: () => void;
  allLanguages: Language[];
}

const ImportPhraseModal: FC<ImportPhraseModalProps> = ({
  show,
  handleClose,
  allLanguages
}) => {
  const colLanguage = 0;
  const colISO = 1;

  const [busySubmitting, setBusySubmitting] = useState<boolean>(false);

  const [hasUploaded, setHasUploaded] = useState<boolean>(false);
  // state to hold the created import object to be sent to the API
  const [importPhrase, setImportPhrase] = useState<ImportPhrase | null>(null);
  const [fileName, setFileName] = useState<string>("");
  const [uploadErrors, setUploadErrors] = useState<string[] | null>(null);
  const [validCategories, setValidCategories] = useState<
    ConfigPhraseCategory[]
  >([]);
  const [validLanguages, setValidLanguages] = useState<Language[]>([]);

  useEffect(() => {
    const fetchPhraseCategory = async () => {
      try {
        const jsonData = await getSystemConfigPhraseCategories();
        if (!jsonData) {
          throw new Error("Error fetching categories");
        } else {
          setValidCategories(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };
    fetchPhraseCategory();
  }, []);
  //Get language ISO codes from config
  useEffect(() => {
    const fetchLanguages = async () => {
      try {
        const jsonData = await getSystemConfigLanguages();
        if (!jsonData) {
          throw new Error("Error fetching languages");
        } else {
          setValidLanguages(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
      }
    };
    fetchLanguages();
  }, []);

  function clearFile() {
    setImportPhrase(null);
    setHasUploaded(false);
    setFileName("");
  }

  const handleOnDrop = (acceptedFiles: File[]) => {
    console.log("asfasdfsdfgsdfgsdg:", validCategories);
    try {
      setHasUploaded(true);
      const file = acceptedFiles[0]; // Assuming only one file is accepted

      // Check if the file is an Excel file by MIME type or extension
      const fileExtension = file.name.split(".").pop() || "";
      if (
        !["xlsx", "xls"].includes(fileExtension) &&
        ![
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          "application/vnd.ms-excel"
        ].includes(file.type)
      ) {
        setUploadErrors(["Please upload a valid Excel file"]);
        return;
      }
      setFileName(file.name);
      let errors: string[] = [];

      const reader = new FileReader();
      reader.onload = (e) => {
        if (!e.target?.result) {
          setUploadErrors(["Error reading file"]);
          return;
        }

        const data = new Uint8Array(e.target.result as ArrayBuffer);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the data is in the first sheet
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];

        // Convert sheet to JSON
        const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        if (json.length < 1) {
          errors.push("Excel file does not contain enough rows");
        }

        // check all headers must be string
        if (
          !json[0] ||
          !Array.isArray(json[0]) ||
          json[0].some((header) => typeof header !== "string")
        ) {
          setUploadErrors(["Excel file headers must be strings"]);
          return;
        }
        const headers = json[0];
        // Check Headers
        if (!headers.includes("Phrase") || !headers.includes("Category")) {
          // Updated header check
          setUploadErrors([
            "Excel file missing required headers. Please see sample Excel file"
          ]);
          return;
        }

        //trim the rows to remove any empty rows
        const trimmedData: string[][] = [];
        let categoryIDs = [];
        let languageISOs = [];
        const phrases: Phrase[] = [];

        for (let i = 1; i < json.length; i++) {
          const row = json[i] as string[];
          // check for string or number
          if (
            !row ||
            !Array.isArray(row) ||
            // check for empty row
            row.every((cell) => cell.trim() === "")
          ) {
            console.log("row", row);
            continue;
          }

          if (
            typeof row[colLanguage] !== "string" ||
            typeof row[colISO] !== "string"
          ) {
            errors.push(`Invalid data type at row ${i + 1}`);
            console.log("row", row);
            continue;
          }

          if (row[colLanguage] && row[colISO]) {
            trimmedData.push(row);
            categoryIDs.push(row[colISO]);
            languageISOs.push(row[colISO]);

            console.log(row);
            // Construct phrases
            const phrase = row[colLanguage];
            const categoryName = row[colISO];
            const category = validCategories.find(
              (cat) =>
                cat.phrase_category_name.toLowerCase() ===
                categoryName.toLowerCase().trim()
            );
            const categoryID = category ? category.id : null;
            if (!categoryID) {
              errors.push(
                `Invalid category name at row ${i + 1}: ${categoryName}`
              );
              continue;
            }
            const translations = [];
            for (let j = 2; j < row.length; j++) {
              if (row[j] && row[j].trim() !== "") {
                translations.push({
                  languageISO: headers[j],
                  translation: row[j]
                });
              }
            }
            phrases.push({
              phrase,
              categoryID,
              translations
            });
          }
        }

        if (phrases.length < 1) {
          console.error("No valid phrases found in the Excel file");
          errors.push("No valid phrases found in the Excel file");
        }

        //Check trimmed data that no phrase and category name is the exact same
        const uniqueCheck = new Set();
        for (let i = 0; i < trimmedData.length; i++) {
          // Check if the phrase and category Name are the same
          const row = trimmedData[i];
          const key = `${row[colLanguage]}-${row[colISO]}`;
          if (uniqueCheck.has(key)) {
            errors.push(`Duplicate phrase and category Name at row ${i + 1}`);
          } else {
            uniqueCheck.add(key);
          }
        }

        console.log("errors", errors);
        console.log("phrases", phrases);
        if (errors.length > 0) {
          setUploadErrors(errors);
          setImportPhrase(null);
        } else {
          try {
            setImportPhrase({
              phrases
            });
            setUploadErrors(null);
          } catch (error) {
            setUploadErrors(["Error constructing import object"]);
            setImportPhrase(null);
          }
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error("Error reading file", error);
      Swal.fire({
        title: "Error!",
        text: "An error occurred while reading the file",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
    }
  };

  async function handleSubmit() {
    setBusySubmitting(true);
    if (!importPhrase) {
      setUploadErrors(["Error constructing import object"]);
      setBusySubmitting(false);
      return;
    }
    try {
      const response: { rStatus: "success" | "error"; rMessage: string } =
        await sendBulkImportedTranslations(importPhrase);

      console.log("response", response);
      if (response.rStatus !== "success") {
        Swal.fire({
          title: "Error!",
          text: response.rMessage,
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
      } else {
        Swal.fire({
          title: "Translations imported successfully",
          text: "A job has been queued to import the translations. This may take a few minutes to complete.",
          icon: "success",
          confirmButtonColor: "#3085d6"
        });
        handleClose();
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: "An error occurred while importing translations",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
    }
    setBusySubmitting(false);
  }

  function handleOpenLanguagesTable() {
    // navigate to languages
    const url = "/languages";
    window.open(url, "_blank");
  }
  // Open category table
  function handleOpenCategoryTable() {
    // navigate to languages
    const url = "/categories";
    window.open(url, "_blank");
  }

  const handleExcelDownload = () => {
    const url = "/assets/data/sample_phrase_import.xlsx";
    window.open(url, "_blank");
  };

  return (
    <>
      <div className={`modal import_phrase_modal ${show ? "show" : "hide"}`}>
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <div className="container-fluid">
                <div className="row">
                  <h4 className="modal-title">Import Phrase:</h4>
                </div>
              </div>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                <div className="row">
                  <p>
                    <strong>Import Guidelines:</strong> Please upload the
                    correct Excel file.
                    <br />
                    For reference, you can download a sample{" "}
                    <span
                      className="link clickable"
                      onClick={() => handleExcelDownload()}
                    >
                      Excel file
                    </span>
                  </p>
                  <p>
                    <strong>Excel Requirements:</strong> English phrases and
                    Category IDs are mandatory.
                    <br />
                    <strong>Adding New Phrases:</strong> Provide English phrases
                    and Category IDs. If ISO code columns are empty, new
                    translations will be created.
                    <br />
                    <strong>Updating Existing Phrases:</strong> To update
                    translations, ensure matching English phrases and Category
                    IDs. Populated ISO code columns will overwrite existing
                    translations.
                  </p>
                  <p>
                    Reference valid languages{" "}
                    <span
                      className="link clickable"
                      onClick={() => handleOpenLanguagesTable()}
                    >
                      here
                    </span>
                    .
                    <br />
                    Reference valid Category IDs{" "}
                    <span
                      className="link clickable"
                      onClick={() => handleOpenCategoryTable()}
                    >
                      here
                    </span>
                    .
                  </p>
                </div>
                <div className="row">
                  <Dropzone onDrop={handleOnDrop}>
                    {({ getRootProps, getInputProps }) => (
                      <div>
                        {hasUploaded && (
                          <span
                            className="clear_file"
                            onClick={() => {
                              clearFile();
                            }}
                          >
                            ×
                          </span>
                        )}
                        <div className="drop_zone_upload" {...getRootProps()}>
                          <input {...getInputProps()} />
                          {hasUploaded ? (
                            <p>{fileName}</p>
                          ) : (
                            <p>Drop file or click here to upload...</p>
                          )}
                        </div>
                      </div>
                    )}
                  </Dropzone>
                </div>
                <div className="row">
                  <div className="col-12">
                    {hasUploaded &&
                      !importPhrase &&
                      (!uploadErrors || uploadErrors.length < 1 ? (
                        <div className="alert alert-danger mt-2" role="alert">
                          Unknown error occurred. Please try again.
                        </div>
                      ) : (
                        <div className="alert alert-danger  mt-2" role="alert">
                          {uploadErrors.map((error, index) => (
                            <div key={`importPhraseError_${index}`}>
                              {error}
                            </div>
                          ))}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <div className="container-fluid">
                <div className="row w-100">
                  <div className="col d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary me-2"
                      disabled={busySubmitting || !importPhrase}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Import
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImportPhraseModal;
