import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  ExistingSurveyNotification,
  ExistingSurveyNotifications,
  SurveyDiary,
  SurveyPill,
  SurveySingle,
  SurveySwitchResponse
} from "../types";
import { getSystemConfigPhraseCategoryIDByNameID } from "../utilities/config.util";

// #region type validators
// #region isSurveyPill
function isSurveyPill(object: unknown): object is SurveyPill {
  if (object === null || typeof object !== "object") {
    return false;
  }

  // console.log(object, "object");
  const validID = "id" in object && typeof object.id === "string";
  if (!("id" in object)) {
    Swal.fire({
      icon: "error",
      title: `Invalid Survey`,
      html: `Missing ID`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    console.log("missing id");
    return false;
  }

  const validName =
    "survey_name" in object && typeof object.survey_name === "string";

  const validType =
    "survey_type" in object &&
    ((typeof object.survey_type === "string" &&
      object.survey_type === "diary") ||
      object.survey_type === "single");

  if (validType) {
    const test = object.survey_type;
    console.log(test);
  }

  const validSystem =
    "survey_system" in object &&
    typeof object.survey_system === "string" &&
    (object.survey_system === "wsc" ||
      object.survey_system === "nfield" ||
      object.survey_system === "custom" ||
      object.survey_system === "viewEQ");

  const allValid = validID && validName && validType && validSystem;

  if (!allValid) {
    console.log("Invalid survey object");
    console.log(object);
    let errors = [];
    if (!validID) {
      if (!object.hasOwnProperty("id")) {
        errors.push("missing id");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Survey`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!object.hasOwnProperty("survey_name")) {
        errors.push("missing survey_name");
      } else {
        errors.push(`Invalid type for survey_name`);
      }
    }

    if (!validType) {
      if (!object.hasOwnProperty("survey_type")) {
        errors.push("missing survey_type");
      } else {
        errors.push(`Invalid type for survey_type`);
      }
    }

    if (!validSystem) {
      if (
        !object.hasOwnProperty("survey_system") ||
        !("survey_system" in object)
      ) {
        errors.push("missing survey_system");
      } else {
        errors.push(
          `${object.survey_system} is an invalid type for survey_system`
        );
      }
    }

    console.log(`Invalid survey: ${object.id}`);
    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `SurveyID: ${object.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Survey`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return allValid;
}
// #endregion isSurveyPill

// #region isExistingSurveyNotification
function isExistingSurveyNotification(
  obj: any
): obj is ExistingSurveyNotification {
  // console.log(obj, "obj");
  const validID = typeof obj.phraseID === "string";
  const validCategoryID = typeof obj.categoryID === "string";
  const validPhrase = typeof obj.phrase === "string";
  const AllValid = validID && validCategoryID && validPhrase;
  // console.log("AllValid", AllValid);
  if (!AllValid) {
    console.log(obj);
    if (!validID) {
      console.log("Invalid ID");
    }
    if (!validCategoryID) {
      console.log("Invalid category ID");
    }
    if (!validPhrase) {
      console.log("Invalid phrase");
    }
  }
  return AllValid;
}

// #endregion isExistingSurveyNotification

// #region isExistingSurveyNotifications
function isExistingSurveyNotifications(
  obj: any
): obj is ExistingSurveyNotifications {
  const validDiaryWelcomeNotifications =
    Array.isArray(obj.diaryWelcomeNotifications) &&
    obj.diaryWelcomeNotifications.every(isExistingSurveyNotification);
  const validDiaryDailyReminderNotifications =
    Array.isArray(obj.diaryDailyReminderNotifications) &&
    obj.diaryDailyReminderNotifications.every(isExistingSurveyNotification);
  const validDiaryNonComplianceNotifications =
    Array.isArray(obj.diaryNonComplianceNotifications) &&
    obj.diaryNonComplianceNotifications.every(isExistingSurveyNotification);
  const validSingleWelcomeNotifications =
    Array.isArray(obj.singleWelcomeNotifications) &&
    obj.singleWelcomeNotifications.every(isExistingSurveyNotification);
  // const validSingleDailyReminderNotifications =
  //   Array.isArray(obj.singleDailyReminderNotifications) &&
  //   obj.singleDailyReminderNotifications.every(isExistingSurveyNotification);
  const validSingleNonComplianceNotifications =
    Array.isArray(obj.singleNonComplianceNotifications) &&
    obj.singleNonComplianceNotifications.every(isExistingSurveyNotification);

  const AllValid =
    validDiaryWelcomeNotifications &&
    validDiaryDailyReminderNotifications &&
    validDiaryNonComplianceNotifications &&
    validSingleWelcomeNotifications &&
    // validSingleDailyReminderNotifications &&
    validSingleNonComplianceNotifications;
  // console.log("AllValid", AllValid);
  if (!AllValid) {
    console.log(obj);
    if (!validDiaryWelcomeNotifications) {
      console.log("Invalid diary welcome notifications");
    }
    if (!validDiaryDailyReminderNotifications) {
      console.log("Invalid diary daily reminder notifications");
    }
    if (!validDiaryNonComplianceNotifications) {
      console.log("Invalid diary non compliance notifications");
    }
    if (!validSingleWelcomeNotifications) {
      console.log("Invalid single welcome notifications");
    }
    // if (!validSingleDailyReminderNotifications) {
    //   console.log("Invalid single daily reminder notifications");
    // }
    if (!validSingleNonComplianceNotifications) {
      console.log("Invalid single non compliance notifications");
    }
  }
  return AllValid;
}
// #endregion isExistingSurveyNotifications

// #region isSurveyDetails
function isSurveyDetails(
  object: SurveyDiary | SurveySingle
): object is SurveyDiary | SurveySingle {
  // Common fields are the same for diary and single so we do them here
  const validID = typeof object.id === "string";
  const validName = typeof object.survey_name === "string";
  const validDescription = typeof object.survey_desc === "string";
  const validStatus = typeof object.survey_status === "string";
  const validType =
    (typeof object.survey_type === "string" &&
      object.survey_type === "diary") ||
    object.survey_type === "single";
  // const validSurveyAppDisplayName =
  //   typeof object.survey_app_display_name === "string";
  const validSurveySystem = typeof object.survey_system === "string";
  // // Notify welcome object
  // const notifyWelcome = object.notify_welcome;
  // const validNotifyWelcome =
  //   typeof notifyWelcome === "object" &&
  //   notifyWelcome !== null &&
  //   typeof notifyWelcome.category_id === "string" &&
  //   typeof notifyWelcome.id === "string";
  // // Valid non compliant notification object
  // const notifyNonCompliant = object.notify_non_compliant;
  // const validNonCompliantNotification =
  //   typeof notifyNonCompliant === "object" &&
  //   notifyNonCompliant !== null &&
  //   typeof notifyNonCompliant.category_id === "string" &&
  //   typeof notifyNonCompliant.id === "string";
  // photo
  const validPhoto = typeof object.thumbnail_url === "string";

  // If this is valid it means single survey can be created. It might be diary though so we will check it later
  const validCommonFields =
    validID &&
    validName &&
    validDescription &&
    validStatus &&
    validType &&
    // validSurveyAppDisplayName &&
    validSurveySystem &&
    // validNotifyWelcome &&
    // validNonCompliantNotification &&
    validPhoto;

  if (!validCommonFields) {
    let errors: string[] = [];
    console.log("Invalid survey object");
    console.log(object);
    if (!validID) {
      if (!object.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }
    if (!validName) {
      if (!object.hasOwnProperty("survey_name")) {
        errors.push("missing survey_name");
      } else {
        errors.push(`Invalid type for survey_name`);
      }
    }
    if (!validDescription) {
      if (!object.hasOwnProperty("survey_desc")) {
        errors.push("missing survey_desc");
      } else {
        errors.push(`Invalid type for survey_desc`);
      }
    }
    if (!validStatus) {
      if (!object.hasOwnProperty("survey_status")) {
        errors.push("missing survey_status");
      } else {
        errors.push(`Invalid type for survey_status`);
      }
    }
    if (!validType) {
      if (!object.hasOwnProperty("survey_type")) {
        errors.push("missing survey_type");
      } else {
        errors.push(`Invalid type for survey_type`);
      }
    }
    // if (!validSurveyAppDisplayName) {
    //   if (!object.hasOwnProperty("survey_app_display_name")) {
    //     errors.push("missing survey_app_display_name");
    //   } else {
    //     errors.push(`Invalid type for survey_app_display_name`);
    //   }
    // }
    if (!validSurveySystem) {
      if (!object.hasOwnProperty("survey_system")) {
        errors.push("missing survey_system");
      } else {
        errors.push(`Invalid type for survey_system`);
      }
    }
    // if (!validNotifyWelcome) {
    //   if (!object.hasOwnProperty("notify_welcome")) {
    //     errors.push("missing notify_welcome");
    //   } else {
    //     errors.push(`Invalid type for notify_welcome`);
    //   }
    // }
    // if (!validNonCompliantNotification) {
    //   if (!object.hasOwnProperty("notify_non_compliant")) {
    //     errors.push("missing notify_non_compliant");
    //   } else {
    //     errors.push(`Invalid type for notify_non_compliant`);
    //   }
    // }
    if (!validPhoto) {
      if (!object.hasOwnProperty("thumbnail_url")) {
        errors.push("missing thumbnail_url");
      } else {
        errors.push(`Invalid type for thumbnail_url`);
      }
    }

    if (errors.length > 0) {
      errors.forEach((error) => {
        // remove first empty element
        if (error !== "") {
          console.log(error);
        }
      });

      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `ID: ${object.id}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid survey details`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  // Here we must check if it is dairy...
  if (object.survey_type === "diary" && validCommonFields) {
    // If it is diary we need to check the diary specific fields done in isDairy function
    return isDiary(object);
  }
  // Here we must check if it is single...
  if (object.survey_type === "single" && validCommonFields) {
    // If it is diary we need to check the diary specific fields done in isDairy function
    return isSingle(object);
  }

  // If it is neither then it is invalid
  return false;
}
// #endregion isSurveyDetails

// #region isDiary
function isDiary(object: any): object is SurveyDiary {
  const validActiveDays = typeof object.survey_active_days === "number";
  const validGraceDays = typeof object.survey_grace_days === "number";
  const validBonusDays = typeof object.bonus_days === "number";
  const validMinCompletes = typeof object.survey_min_completes === "number";
  const validMaxCompletes = typeof object.survey_max_completes === "number";
  // Valid non daily reminder object
  // const notifyDaily = object.notify_daily;
  // const validNotifyDailyReminder =
  //   typeof notifyDaily === "object" &&
  //   notifyDaily !== null &&
  //   typeof notifyDaily.category_id === "string" &&
  //   typeof notifyDaily.id === "string";
  // daily reminder time
  // const validDailyReminderTime =
  //   typeof object.survey_daily_reminder_time === "string";
  // const validNonComplianceTime =
  //   typeof object.survey_non_compliance_time === "string";

  const validDiary =
    validActiveDays &&
    validGraceDays &&
    validBonusDays &&
    validMinCompletes &&
    validMaxCompletes;
  // validNotifyDailyReminder &&
  // validDailyReminderTime &&
  // validNonComplianceTime;

  if (!validDiary) {
    let errors: string[] = [];
    console.log("Invalid survey object");
    console.log(object);
    if (!validActiveDays) {
      if (!object.hasOwnProperty("survey_active_days")) {
        errors.push("missing survey_active_days");
      } else {
        errors.push(`Invalid type for survey_active_days`);
      }
    }
    if (!validGraceDays) {
      if (!object.hasOwnProperty("survey_grace_days")) {
        errors.push("missing survey_grace_days");
      } else {
        errors.push(`Invalid type for survey_grace_days`);
      }
    }
    if (!validBonusDays) {
      if (!object.hasOwnProperty("bonus_days")) {
        errors.push("missing bonus_days");
      } else {
        errors.push(`Invalid type for bonus_days`);
      }
    }
    if (!validMinCompletes) {
      if (!object.hasOwnProperty("survey_min_completes")) {
        errors.push("missing survey_min_completes");
      } else {
        errors.push(`Invalid type for survey_min_completes`);
      }
    }
    if (!validMaxCompletes) {
      if (!object.hasOwnProperty("survey_max_completes")) {
        errors.push("missing survey_max_completes");
      } else {
        errors.push(`Invalid type for survey_max_completes`);
      }
    }
    // if (!validNotifyDailyReminder) {
    //   if (!object.hasOwnProperty("notify_daily")) {
    //     errors.push("missing notify_daily");
    //   } else {
    //     errors.push(`Invalid type for notify_daily`);
    //   }
    // }
    // if (!validDailyReminderTime) {
    //   if (!object.hasOwnProperty("survey_daily_reminder_time")) {
    //     errors.push("missing survey_daily_reminder_time");
    //   } else {
    //     errors.push(`Invalid type for survey_daily_reminder_time`);
    //   }
    // }
    // if (!validNonComplianceTime) {
    //   if (!object.hasOwnProperty("survey_non_compliance_time")) {
    //     errors.push("missing survey_non_compliance_time");
    //   } else {
    //     errors.push(`Invalid type for survey_non_compliance_time`);
    //   }
    // }
    if (errors.length > 0) {
      errors.forEach((error) => {
        // remove first empty element
        if (error !== "") {
          console.log(error);
        }
      });

      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `ID: ${object.id}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid diary object`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return validDiary;
}
// #endregion isDiary

// #region isSingle
function isSingle(object: any): object is SurveySingle {
  const validNonCompliantNotificationStartDelay =
    typeof object.survey_non_compliance_notification_start_delay === "number";
  const validNonCompliantNotificationInterval =
    typeof object.survey_non_compliance_notification_interval === "number";
  const validSingle =
    validNonCompliantNotificationStartDelay &&
    validNonCompliantNotificationInterval;

  if (!validSingle) {
    let errors: string[] = [];
    console.log("Invalid survey object");
    console.log(object);

    if (!validSingle) {
      if (
        !object.hasOwnProperty("survey_non_compliance_notification_start_delay")
      ) {
        errors.push("missing survey_non_compliance_notification_start_delay");
      } else {
        errors.push(
          `Invalid type for survey_non_compliance_notification_start_delay`
        );
      }
      if (
        !object.hasOwnProperty("survey_non_compliance_notification_interval")
      ) {
        errors.push("missing survey_non_compliance_notification_interval");
      } else {
        errors.push(
          `Invalid type for survey_non_compliance_notification_interval`
        );
      }
    }
    if (errors.length > 0) {
      errors.forEach((error) => {
        // remove first empty element
        if (error !== "") {
          console.log(error);
        }
      });

      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `ID: ${object.id}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid singles object`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return validSingle;
}
// #endregion isSingle

// #region isValidS3KeyResponse
function isValidS3KeyResponse(
  obj: unknown
): obj is { signedURL: string; key: string } {
  if (typeof obj !== "object") {
    return false;
  }

  if (obj === null) {
    return false;
  }

  if (!("signedURL" in obj) || typeof obj.signedURL !== "string") {
    return false;
  }

  if (!("key" in obj) || typeof obj.key !== "string") {
    return false;
  }

  return true;
}
// #endregion isValidS3KeyResponse

// #region survey switch

function isValidSurveySwitchResponse(
  obj: unknown
): obj is SurveySwitchResponse {
  if (typeof obj !== "object") {
    return false;
  }

  if (obj === null) {
    return false;
  }

  if (!("clientId" in obj) || typeof obj.clientId !== "string") {
    return false;
  }
  if (!("surveyId" in obj) || typeof obj.surveyId !== "string") {
    return false;
  }
  if (!("studyId" in obj) || typeof obj.studyId !== "string") {
    return false;
  }

  return true;
}

// #endregion survey switch

// #endregion type validators

// #region fetchAllSurveys
// A post request to fetch a single study by client id and study id
export async function fetchAllSurveys(
  clientID: string,
  studyID: string
): Promise<SurveyPill[] | string> {
  const response = await apiFetcher<any>("/surveys/get/forStudy", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID
    }
  });

  console.log("clientID: ", clientID);
  console.log("studyID: ", studyID);
  console.log("response: ", response.status);
  console.log("data: ", response.data);
  if (response.status === 200 && response.data !== null) {
    // Perform type checking

    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isSurveyPill)
    ) {
      // console.log("Successfully fetched all surveys");

      return response.data;
    } else {
      console.log("Invalid data received");

      return "Invalid data received";
    }
  } else {
    console.log("No surveys found");
    console.log(response.status);
    if (response.status === 204) {
      return [];
    }
    return "Failed to fetch all surveys";
  }
}
// #endregion fetchAllSurveys

// #region sendSurveyData
export async function sendSurveyData({
  clientID,
  studyID,
  surveyType,
  surveyName,
  surveyDescription,
  // surveyAppDisplayName,
  surveyIcon,
  surveyStartDate,
  surveyAutoStart,
  surveyActiveDays,
  surveyGraceDays,
  surveyBonusDays,
  surveyMinimumDailyCompletes,
  surveyMaximumDailyCompletes,
  surveySurveySystem,
  surveySurveySystemDetails,
  testLinkIncluded,
  testSurveySystem,
  testSurveySystemDetails,
  includeNotifications,
  welcomeNotification,
  dailyReminderNotification,
  nonComplianceNotification,
  newWelcomeNotification,
  newDailyReminderNotification,
  newNonComplianceNotification,
  dailyReminderTime,
  nonComplianceTime,
  singleNonComplianceNotificationStartDelay,
  singleNonComplianceNotificationInterval
}: {
  clientID: string;
  studyID: string;
  surveyType: "diary" | "single";
  surveyName: string;
  surveyDescription: string;
  // surveyAppDisplayName: string;
  surveyIcon: string;
  surveyStartDate: string | null;
  surveyAutoStart: boolean | null;

  surveyActiveDays: number;
  surveyGraceDays: number;
  surveyBonusDays: number;
  surveyMinimumDailyCompletes: number;
  surveyMaximumDailyCompletes: number;
  surveySurveySystem: string;
  surveySurveySystemDetails: string;

  testLinkIncluded: boolean | null;
  testSurveySystem: string | null;
  testSurveySystemDetails: string | null;

  includeNotifications: boolean;
  welcomeNotification: string;
  dailyReminderNotification: string;
  nonComplianceNotification: string;
  newWelcomeNotification?: string;
  newDailyReminderNotification?: string;
  newNonComplianceNotification?: string;
  dailyReminderTime?: string;
  nonComplianceTime?: string;
  singleNonComplianceNotificationStartDelay: number;
  singleNonComplianceNotificationInterval: number;
}): Promise<string> {
  type DiaryNotification = {
    category: string;
    phrase: string;
  };

  let phrases: DiaryNotification[] | null = [];
  if (includeNotifications) {
    try {
      if (surveyType !== "diary" && surveyType !== "single") {
        throw new Error("Invalid survey type");
      }

      // console log all the values
      // console.log("surveyType: ", surveyType);
      // console.log("surveyName: ", surveyName);
      // console.log("surveyDescription: ", surveyDescription);
      // console.log("surveyAppDisplayName: ", surveyAppDisplayName);
      // console.log("surveyIcon: ", surveyIcon);
      // console.log("surveyActiveDays: ", surveyActiveDays);
      // console.log("surveyGraceDays: ", surveyGraceDays);
      // console.log("surveyBonusDays: ", surveyBonusDays);
      // console.log("surveyMinimumDailyCompletes: ", surveyMinimumDailyCompletes);
      // console.log("surveyMaximumDailyCompletes: ", surveyMaximumDailyCompletes);
      // console.log("surveySurveySystem: ", surveySurveySystem);
      // console.log("surveySurveySystemDetails: ", surveySurveySystemDetails);
      // console.log("welcomeNotification: ", welcomeNotification);
      // console.log("dailyReminderNotification: ", dailyReminderNotification);
      // console.log("nonComplianceNotification: ", nonComplianceNotification);
      // console.log("newWelcomeNotification: ", newWelcomeNotification);
      // console.log("newDailyReminderNotification: ", newDailyReminderNotification);
      // console.log("newNonComplianceNotification: ", newNonComplianceNotification);
      // console.log("dailyReminderTime: ", dailyReminderTime);
      // console.log("nonComplianceTime: ", nonComplianceTime);

      const welcomeNotificationCategoryID =
        await getSystemConfigPhraseCategoryIDByNameID(
          surveyType === "diary"
            ? "notify-welcome-diary"
            : "notify-welcome-single"
        );
      const dailyReminderNotificationCategoryID =
        surveyType === "diary"
          ? await getSystemConfigPhraseCategoryIDByNameID("notify-daily-diary")
          : null;
      const nonComplianceNotificationCategoryID =
        await getSystemConfigPhraseCategoryIDByNameID(
          surveyType === "diary"
            ? "notify-non_compliant-diary"
            : "notify-non_compliant-single"
        );

      if (
        includeNotifications &&
        (!welcomeNotificationCategoryID ||
          welcomeNotificationCategoryID === "notFound" ||
          (surveyType === "diary" &&
            (!dailyReminderNotificationCategoryID ||
              dailyReminderNotificationCategoryID === "notFound")) ||
          !nonComplianceNotificationCategoryID ||
          nonComplianceNotificationCategoryID === "notFound")
      ) {
        console.log(
          "welcomeNotificationCategory: ",
          welcomeNotificationCategoryID
        );
        console.log(
          "dailyReminderNotificationCategory: ",
          dailyReminderNotificationCategoryID
        );
        console.log(
          "nonComplianceNotificationCategory: ",
          nonComplianceNotificationCategoryID
        );

        Swal.fire({
          title: "Error, please logout amd try again",
          text: `Failed to fetch ${surveyType} notification categories from local storage`,
          icon: "error",
          confirmButtonText: "Ok"
        });

        throw new Error(
          `Failed to fetch ${surveyType} notification categories`
        );
      }

      if (!welcomeNotification || welcomeNotification === "") {
        throw new Error("welcomeNotification is empty");
      }

      if (
        welcomeNotification === "new" &&
        (!newWelcomeNotification || newWelcomeNotification === "")
      ) {
        throw new Error("newWelcomeNotification is empty");
      }

      if (!dailyReminderNotification || dailyReminderNotification === "") {
        throw new Error("dailyReminderNotification is empty");
      }

      if (
        dailyReminderNotification === "new" &&
        surveyType === "diary" &&
        (!newDailyReminderNotification || newDailyReminderNotification === "")
      ) {
        throw new Error("newDailyReminderNotification is empty");
      }

      if (!nonComplianceNotification || nonComplianceNotification === "") {
        throw new Error("nonComplianceNotification is empty");
      }

      if (
        nonComplianceNotification === "new" &&
        (!newNonComplianceNotification || newNonComplianceNotification === "")
      ) {
        throw new Error("newNonComplianceNotification is empty");
      }

      if (!welcomeNotificationCategoryID) {
        throw new Error("welcomeNotificationCategoryID is empty");
      }

      phrases.push({
        category: welcomeNotificationCategoryID,
        phrase:
          welcomeNotification !== "new"
            ? welcomeNotification
            : newWelcomeNotification || "DEFAULT_ERROR"
      });

      if (surveyType === "diary" && dailyReminderNotificationCategoryID) {
        phrases.push({
          category: dailyReminderNotificationCategoryID,
          phrase:
            dailyReminderNotification && dailyReminderNotification !== "new"
              ? dailyReminderNotification
              : newDailyReminderNotification || "DEFAULT_ERROR"
        });
      }

      if (!nonComplianceNotificationCategoryID) {
        throw new Error("nonComplianceNotificationCategoryID is empty");
      }

      phrases.push({
        category: nonComplianceNotificationCategoryID,
        phrase:
          nonComplianceNotification && nonComplianceNotification !== "new"
            ? nonComplianceNotification
            : newNonComplianceNotification || "DEFAULT_ERROR"
      });
      if (phrases && phrases.some((p) => p.phrase === "DEFAULT_ERROR")) {
        throw new Error("One or more phrases have missing values.");
      }
    } catch (error: any) {
      console.log(error);
      Swal.fire({
        title: "Error",
        html: "Please contact support <br />" + error.message,
        icon: "error",
        confirmButtonText: "Ok"
      });
      return "failed";
    }
  }

  const diaryData =
    surveyType === "diary"
      ? {
          survey_type: "diary",
          survey_name: surveyName,
          survey_desc: surveyDescription,
          // survey_app_display_name: surveyAppDisplayName,
          thumbnail_url: surveyIcon,
          survey_start_date: surveyStartDate,
          survey_auto_start: surveyAutoStart,
          survey_active_days: surveyActiveDays,
          survey_grace_days: surveyGraceDays,
          bonus_days: surveyBonusDays,
          survey_min_completes: surveyMinimumDailyCompletes,
          survey_max_completes: surveyMaximumDailyCompletes,
          survey_system: surveySurveySystem,
          survey_system_details: surveySurveySystemDetails,
          test_survey_system: testLinkIncluded ? testSurveySystem : null,
          test_survey_system_details: testLinkIncluded
            ? testSurveySystemDetails
            : null,
          survey_daily_reminder_time: includeNotifications
            ? dailyReminderTime
            : null,
          survey_non_compliance_time: includeNotifications
            ? nonComplianceTime
            : null,
          phrases: includeNotifications ? phrases : []
        }
      : null;

  const singleData =
    surveyType === "single"
      ? {
          survey_type: "single",
          survey_name: surveyName,
          survey_desc: surveyDescription,
          // survey_app_display_name: surveyAppDisplayName,
          thumbnail_url: surveyIcon,
          survey_start_date: surveyStartDate,
          survey_auto_start: surveyAutoStart,
          survey_system: surveySurveySystem,
          survey_system_details: surveySurveySystemDetails,
          test_survey_system: testLinkIncluded ? testSurveySystem : null,
          test_survey_system_details: testLinkIncluded
            ? testSurveySystemDetails
            : null,
          survey_non_compliance_time: includeNotifications
            ? nonComplianceTime
            : null,
          phrases: includeNotifications ? phrases : [],
          // singleNonComplianceNotificationStartDelay
          survey_non_compliance_notification_start_delay: includeNotifications
            ? singleNonComplianceNotificationStartDelay
            : null,
          survey_non_compliance_notification_interval: includeNotifications
            ? singleNonComplianceNotificationInterval
            : null
        }
      : null;

  let body: {
    clientID: string;
    studyID: string;
    surveys?: {
      diary?: any;
      single?: any;
    };
  };

  body = {
    clientID: clientID,
    studyID: studyID
  };

  if (diaryData || singleData) {
    body.surveys = {};

    if (diaryData) {
      body.surveys = {
        ...body.surveys,
        diary: diaryData
      };
    }

    if (singleData) {
      body.surveys = {
        ...body.surveys,
        single: singleData
      };
    }
  }

  // console.log(body, "body");

  const response = await apiFetcher<any>("/survey/create", "POST", {
    body: body
  });

  if (response.status === 200) {
    return "success";
  } else {
    console.log(response.status, response.error);
    console.log("Failed to send study data");
    return "failed";
  }
}
// #endregion sendSurveyData

/*****************************************************************************/
/**************************** SEND SURVEY CHANGES ****************************/
/*****************************************************************************/

// #region sendUpdatedSurveyDiary
// DIARIES!!!!!!!!!!!
export async function sendUpdatedSurveyDiary(
  clientID: string,
  studyID: string,
  surveyID: string,
  surveyStatus: string,
  surveyName: string,
  surveyDescription: string,
  // surveyAppDisplayName: string,
  surveyThumbnailNewUrl: string,
  surveyActiveDays: number,
  surveyGraceDays: number,
  surveyBonusDays: number,
  surveyMinimumDailyCompletes: number,
  surveyMaximumDailyCompletes: number,
  testSurveyLink: string,
  testSurveySystem: string
  // surveyWelcomeNotificationPhrase: string,
  // surveyDailyReminderNotificationPhrase: string,
  // surveyDailyReminderTime: string,
  // surveyNonComplianceNotificationPhrase: string,
  // surveyNonComplianceTime: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  const surveyObj = {
    survey_status: surveyStatus,
    survey_name: surveyName,
    survey_desc: surveyDescription,
    // survey_app_display_name: surveyAppDisplayName,
    thumbnail_url: surveyThumbnailNewUrl,
    survey_active_days: surveyActiveDays,
    survey_grace_days: surveyGraceDays,
    bonus_days: surveyBonusDays,
    survey_min_completes: surveyMinimumDailyCompletes,
    survey_max_completes: surveyMaximumDailyCompletes,
    test_survey_system_link: testSurveyLink,
    test_survey_system: testSurveySystem
    // notify_welcome: surveyWelcomeNotificationPhrase,
    // notify_daily: surveyDailyReminderNotificationPhrase,
    // survey_daily_reminder_time: surveyDailyReminderTime,
    // notify_non_compliant: surveyNonComplianceNotificationPhrase,
    // survey_non_compliance_time: surveyNonComplianceTime
  };

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const response = await apiFetcher<any>("/survey/settings/update/id", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID,
      surveyID: surveyID,
      survey: surveyObj
    }
  });

  if (response.status === 200 || response.status === 202) {
    return {
      rStatus: "success",
      rMessage: "Survey updated successfully"
    };
  }

  if (response.status === 204) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Survey not found"
    };
  }

  if (response.status === 404) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Route not found"
    };
  }

  if (response.status === 400) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Server expecting different data"
    };
  }

  if (response.status === 404) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Route not found"
    };
  }

  if (response.status === 500) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Internal server error"
    };
  }

  console.log(response.status, response.error);
  console.log("Failed to update Survey");
  return {
    rStatus: "error",
    rMessage: "Failed to update Survey"
  };
}
// #endregion sendUpdatedSurveyDiary

/*****************************************************************************/
/**************************** SEND SURVEY CHANGES ****************************/
/*****************************************************************************/

// #region sendUpdatedSurveySingle
export async function sendUpdatedSurveySingle(
  clientID: string,
  studyID: string,
  surveyID: string,
  surveyStatus: string,
  surveyName: string,
  surveyDescription: string,
  // surveyAppDisplayName: string,
  surveyThumbnail: string,
  testSurveyLink: string,
  testSurveySystem: string
  // surveyWelcomeNotificationPhrase: string,
  // surveyNonComplianceNotificationPhrase: string,
  // singleNonComplianceNotificationStartDelay: number,
  // singleNonComplianceNotificationInterval: number
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  const surveyObj = {
    survey_status: surveyStatus,
    survey_name: surveyName,
    survey_desc: surveyDescription,
    // survey_app_display_name: surveyAppDisplayName,
    thumbnail_url: surveyThumbnail,
    test_survey_system_link: testSurveyLink,
    test_survey_system: testSurveySystem
    // notify_welcome: surveyWelcomeNotificationPhrase,
    // notify_non_compliant: surveyNonComplianceNotificationPhrase,
    // survey_non_compliance_notification_start_delay:
    //   singleNonComplianceNotificationStartDelay,
    // survey_non_compliance_notification_interval:
    //   singleNonComplianceNotificationInterval
  };

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const response = await apiFetcher<any>("/survey/settings/update/id", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID,
      surveyID: surveyID,
      survey: surveyObj
    }
  });

  if (response.status === 200 || response.status === 202) {
    return {
      rStatus: "success",
      rMessage: "Survey updated successfully"
    };
  }

  if (response.status === 204) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Survey not found"
    };
  }

  if (response.status === 404) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Route not found"
    };
  }

  if (response.status === 400) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Server expecting different data"
    };
  }

  if (response.status === 404) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Route not found"
    };
  }

  if (response.status === 500) {
    return {
      rStatus: "error",
      rMessage: "Please contact support <br />Internal server error"
    };
  }

  console.log(response.status, response.error);
  console.log("Failed to update Survey");
  return {
    rStatus: "error",
    rMessage: "Failed to update Survey"
  };
}
// #endregion sendUpdatedSurveySingle

/************************************************************************/
/**************************** FETCH SETTINGS ****************************/
/************************************************************************/

// #region fetchSurveySettings
export async function fetchSurveySettings(
  clientID: string,
  studyID: string,
  surveyID: string
): Promise<SurveyDiary | SurveySingle | string> {
  const response = await apiFetcher<any>("/surveys/get/id", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID,
      surveyID: surveyID
    }
  });
  console.log(response.data, "response");
  if (response.status === 200 && response.data !== null) {
    if (isSurveyDetails(response.data)) {
      return response.data;
    } else {
      throw new Error("Received data does not conform to the Survey type");
    }
  } else {
    console.log(`Failed to fetch all notifications`);
    return response.error ? response.error : "failed";
  }
}
// #endregion fetchSurveySettings

/************************************************************************/
/************************** FETCH NOTIFICATION **************************/
/************************************************************************/

// #region fetchAllExistingSurveyNotifications
export async function fetchAllExistingSurveyNotifications(): Promise<
  ExistingSurveyNotifications | string
> {
  const response = await apiFetcher<any>("/getWizardNotifications", "POST", {
    body: {}
  });
  // console.log(response.data, "response");
  if (response.status === 200 && response.data !== null) {
    if (isExistingSurveyNotifications(response.data)) {
      return response.data;
    } else {
      throw new Error(
        "Received data does not conform to the CreateSurveyNotifications type"
      );
    }
  } else {
    console.log(`Failed to fetch all notifications`);
    return response.error ? response.error : "failed";
  }
}
// #endregion fetchAllExistingSurveyNotifications

/**************************************************************************/
/**************************************************************************/
/**************************************************************************/

// #region sendDeleteSurvey
export async function sendDeleteSurvey(
  clientID: string,
  studyID: string,
  surveyID: string
): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
      rMessage: string;
    }
> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the request to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  const response = await apiFetcher<any>("/surveys/delete/id", "POST", {
    body: {
      clientID: clientID,
      studyID: studyID,
      surveyID: surveyID
    }
  });

  Swal.close();

  if (response.status === 200) {
    if (
      !response.data.jobID ||
      response.data.jobID === null ||
      response.data.jobID === undefined
    ) {
      return {
        rStatus: "error",
        rMessage: "No worker ID received"
      };
    }

    // set Worker ID in local storage
    localStorage.setItem("workerID", response.data.jobID);
    return {
      rStatus: "success"
    };
  } else {
    console.log(response.status, response.error);
    console.log("Failed to delete survey");
    return {
      rStatus: "error",
      rMessage: "Failed to delete survey"
    };
  }
}
// #endregion sendDeleteSurvey

type S3KeyResponse = {
  statusCode: number;
  signedURL: string;
  key: string;
};

// #region fetchS3KeyForEQ
export async function fetchS3KeyForEQ(
  clientID: string,
  fileName: string
): Promise<S3KeyResponse> {
  try {
    const response = await apiFetcher<unknown>(
      "/survey/presigned/get",
      "POST",
      {
        body: {
          clientID: clientID,
          fileName: fileName
        }
      }
    );

    const responseData = response.data;

    if (response.status === 200) {
      if (isValidS3KeyResponse(responseData)) {
        return {
          statusCode: 200,
          signedURL: responseData.signedURL,
          key: responseData.key
        };
      } else {
        return {
          statusCode: 500,
          key: "Failed to fetch s3 key",
          signedURL: ""
        };
      }
    } else if (response.status === 401) {
      return {
        statusCode: 401,
        key: "Failed to fetch s3 key",
        signedURL: ""
      };
    } else if (response.status === 503) {
      return {
        statusCode: 503,
        key: "Failed to fetch s3 key",
        signedURL: ""
      };
    } else {
      return {
        statusCode: 500,
        key: "Failed to fetch s3 key",
        signedURL: ""
      };
    }
  } catch (e) {
    return {
      statusCode: 500,
      key: "Failed to fetch s3 key",
      signedURL: ""
    };
  }
}
// #endregion fetchS3KeyForEQ

// #region uploadingToS3
export async function uploadingToS3(
  signedURL: string,
  file: File[]
): Promise<boolean> {
  try {
    const response = await fetch(signedURL, {
      method: "PUT",
      body: file[0]
    });

    if (response.ok) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
}
// #endregion uploadingToS3

// #region survey switch
export async function surveySwitch(
  clientID: string,
  studyID: string,
  surveyID: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher<any>("/survey/switch/link", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        surveyID: surveyID
      }
    });
    if (response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Please check your email to confirm the switch"
      };
    }

    if (response.status === 204) {
      return {
        rStatus: "error",
        rMessage: "Survey no longer exists"
      };
    }

    if (response.status === 400 || response.status === 500) {
      return {
        rStatus: "error",
        rMessage: "Failed to switch survey"
      };
    }

    return {
      rStatus: "error",
      rMessage: "Failed to switch survey"
    };
  } catch (e) {
    return {
      rStatus: "error",
      rMessage: "Failed to switch survey"
    };
  }
}
// #endregion survey switch

// #region confirm survey switch
export async function confirmSurveySwitch(
  token: string
): Promise<SurveySwitchResponse | string> {
  try {
    const response = await apiFetcher<any>(
      "/survey/confirm/link/switch",
      "POST",
      {
        body: {
          token: token
        }
      }
    );

    if (response.status === 200) {
      if (isValidSurveySwitchResponse(response.data)) {
        return response.data;
      }
    }

    if (response.status === 204) {
      return "notFound";
    }

    if (response.status === 400) {
      return "expired";
    }

    return "failed";
  } catch (e) {
    return "failed";
  }
}
