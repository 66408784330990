import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { addParticipantToClient } from "../../models/leads.model.ts";
import {
  Country,
  FetchResponseError,
  Language,
  LeadsParticipant
} from "../../types";
import "./addLeadsModal.scss";

import Swal from "sweetalert2";
import { IconBroom } from "../../assets/images/icons/icons";
import { fetchAllLeadsNotInClient } from "../../models/leads.model";
import { isFetchResponseError } from "../../utilities/utils";
import CountryFilter from "../Tables/Filters/filterCountries.component";
import LanguagesFilter from "../Tables/Filters/filterLanguages.component";
import MultipleParticipantIDsFilter from "../Tables/Filters/filterMultipleParticipantIDs.component.tsx";
import Search from "../Tables/Filters/filterSearch.component";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation.tsx";

interface AddLeadsModalProps {
  closeModal: () => void;
  shown: boolean;
  languages: Language[];
  countries: Country[];
  setUserAdded: (userAdded: boolean) => void;
}

const AddLeadsModal: React.FC<AddLeadsModalProps> = ({
  closeModal,
  shown,
  languages,
  countries,
  setUserAdded
}) => {
  const { clientID, studyID, surveyID } = useParams(); // Get from URL

  const [error, setError] = useState<FetchResponseError | null>({
    errorCode: "false",
    errorMessage: ""
  });

  // For loading spinner
  const [busySubmitting, setBusySubmitting] = useState(false);
  const [dots, setDots] = useState(".");
  const [loadingParticipants, setLoadingParticipants] = useState(false);

  // BE response
  const [response, setResponse] = useState(false);
  const [message, setMessage] = useState("");

  // Participant data
  const [originalParticipants, setOriginalParticipants] = useState<
    LeadsParticipant[]
  >([]);

  // ==================================== FILTERS ====================================

  const [participantsAddLanguagesFilter, setParticipantsAddLanguagesFilter] =
    useState<string[]>([]);

  const [participantsAddCountryFilter, setParticipantsAddCountryFilter] =
    useState<string[]>([]);

  // State variable to hold the filtered participants
  const [filteredAddParticipants, setFilteredAddParticipants] = useState<
    LeadsParticipant[]
  >([]);
  // Input For searching participants
  const [participantsSearch, setParticipantsSearch] = useState<string>("");

  // store current state of filters
  const [filterApplied, setFilterApplied] = useState<boolean>(false);

  // For selecting participants
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );

  const [currentPage, setCurrentPage] = useState(1);
  // const participantsPerPage = 20;
  const [participantsPerPage, setParticipantsPerPage] = useState(20);

  const [participantsLanguages, setParticipantsLanguages] = useState<
    Language[]
  >([]);
  const [participantsCountries, setParticipantsCountries] = useState<Country[]>(
    []
  );

  const [searchedMultiParticipantsIDs, setSearchedMultiParticipantsIDs] =
    useState<string[]>([]);

  // Select Single Participant
  const handleCheckboxChange = (participantID: string) => {
    setSelectedParticipants((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(participantID);

      if (isAlreadySelected) {
        // If the participantID is already selected, remove it from the array
        return prevSelected.filter((id) => id !== participantID);
      } else {
        // If the participantID is not selected, add it to the array
        return [...prevSelected, participantID];
      }
    });
  };

  const handleClearFilters = () => {
    // Clear the filter state
    setParticipantsSearch("");
    setFilterApplied(!filterApplied);
    // setParticipantsStatusFilter([]);
    // setParticipantsUDIDFilter(false);
    setParticipantsAddLanguagesFilter([]);
    setParticipantsAddCountryFilter([]);
    setSearchedMultiParticipantsIDs([]);
  };

  // Select all participants
  const handleSelectAllCheckboxChange = () => {
    const allParticipantsSelected =
      selectedParticipants.length === filteredAddParticipants.length;

    if (allParticipantsSelected) {
      // If all participants are already selected, remove them from the array
      setSelectedParticipants([]);
    } else {
      // If not all participants are selected, add them to the array
      setSelectedParticipants(filteredAddParticipants.map((p) => p.id));
    }
  };

  const [currentParticipants, setCurrentParticipants] = useState<
    LeadsParticipant[]
  >([]);

  // Adding participants to a client
  const handleAddParticipantToClient = async () => {
    try {
      if (clientID && clientID !== "" && clientID !== "undefined") {
        // console.log(clientID, studyID, selectedParticipants);

        // Adding participant to study
        const result = await addParticipantToClient(
          clientID,
          selectedParticipants
        );

        // Result is a string if there was an error
        // console.log(result);

        // Set response to true, to display the response message
        setBusySubmitting(false);

        if (result.rStatus !== "success") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: result.rMessage,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            html: result.rMessage,
            confirmButtonColor: "#3085d6"
          });
        }
      } else {
        console.log("clientID is undefined");
        Swal.fire({
          icon: "error",
          title: "Error!",
          html: "Please contact support.<br/>Unknown client ID.",
          confirmButtonColor: "#3085d6"
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddParticipant = async () => {
    if (selectedParticipants.length > 0) {
      const currectWorkerID = localStorage.getItem("workerID");
      if (
        currectWorkerID !== null &&
        currectWorkerID !== undefined &&
        currectWorkerID !== ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Please wait for the current worker to finish",
          confirmButtonColor: "#3085d6"
        });
      } else {
        handleAddParticipantToClient();
      }
    }
  };

  useEffect(() => {
    // console.log("selectedParticipants", selectedParticipants);
  }, [selectedParticipants]);

  // Fetch all participants, not in study
  const fetchAllParticipantsNotInClient = async () => {
    // Fetch participants data from database
    // console.log("Fetching participants data...");
    setLoadingParticipants(true);
    if (!clientID || clientID === "" || clientID === "undefined") {
      console.log("clientID is undefined");
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please contact support. <br/> Unknown client ID.",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    console.log("HIT");

    try {
      // console.log("Trying to fetch participants data...");
      const jsonData = await fetchAllLeadsNotInClient(clientID);
      // console.log(jsonData);
      if (isFetchResponseError(jsonData)) {
        console.log("error Status: " + jsonData.errorCode);
        console.log("error Message: " + jsonData.errorMessage);
        setError(jsonData);
      } else {
        setOriginalParticipants(jsonData);
        setFilteredAddParticipants(jsonData);
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching participants data:",
        error
      );
    }
    setLoadingParticipants(false);
  };

  useEffect(() => {
    // initial fetch of data
    fetchAllParticipantsNotInClient();
    setSelectedParticipants([]);
  }, [clientID]);

  // Use effect that applies filters to the participants, related to the mapping
  useEffect(() => {
    // Filter participants based on filters
    let filteredParticipants = originalParticipants;
    // Filter by languages
    if (participantsAddLanguagesFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter(
        (participant: LeadsParticipant) =>
          participantsAddLanguagesFilter.some((language) =>
            participant.language_iso?.includes(language)
          )
      );
    }

    // Filter by countries
    if (participantsAddCountryFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter(
        (participant: LeadsParticipant) =>
          participantsAddCountryFilter.some((country) =>
            participant.country_iso?.includes(country)
          )
      );
    }

    // Filter by search
    if (participantsSearch !== "") {
      const trimmedSearch = participantsSearch.trim().toLowerCase();
      filteredParticipants = filteredParticipants.filter(
        (participant: LeadsParticipant) =>
          participant.first_name?.toLowerCase().includes(trimmedSearch) ||
          participant.last_name?.toLowerCase().includes(trimmedSearch) ||
          participant.email?.toLowerCase().includes(trimmedSearch) ||
          (participant.first_name + " " + participant.last_name)
            ?.toLowerCase()
            .includes(trimmedSearch) ||
          participant.id === trimmedSearch ||
          participant.external_id === trimmedSearch
      );
    }

    // Filter by multiple participant IDs
    if (searchedMultiParticipantsIDs.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        searchedMultiParticipantsIDs.some(
          (id) => participant.id === id || participant.external_id === id
        )
      );
    }

    // Set filtered participants
    setFilteredAddParticipants(filteredParticipants);
  }, [
    originalParticipants,
    participantsAddLanguagesFilter,
    participantsAddCountryFilter,
    searchedMultiParticipantsIDs,
    participantsSearch
  ]);

  // Close modal and reset states
  const handleClose = () => {
    closeModal();
    setSelectedParticipants([]);
    setMessage("");
    fetchAllParticipantsNotInClient();
  };

  // const handleChangeRows = (rows: number) => {
  //   setParticipantsPerPage(rows);
  // };

  useEffect(() => {
    // console.log(currentParticipants, filteredParticipants);
    if (filteredAddParticipants && filteredAddParticipants.length > 0) {
      setCurrentParticipants(
        filteredAddParticipants.slice(
          (currentPage - 1) * participantsPerPage,
          currentPage * participantsPerPage
        )
      );
    }
  }, [currentPage, participantsPerPage, filteredAddParticipants]);

  useEffect(() => {
    // get all unique participants languages
    const pLanguages = [
      ...new Set(
        originalParticipants
          .map((p: LeadsParticipant) => p.language_iso)
          .filter((lang): lang is string => Boolean(lang))
      )
    ].sort();
    setParticipantsLanguages(
      pLanguages.map((lang) => {
        const pLanguage = languages.find((l: Language) => l.iso_code === lang);
        if (pLanguage) {
          return pLanguage;
        }
        return { iso_code: lang, name: lang } as Language;
      })
    );
  }, [originalParticipants, languages]);

  useEffect(() => {
    // get all unique participants countries
    const pCountries = [
      ...new Set(
        originalParticipants
          .map((p: LeadsParticipant) => p.country_iso)
          .filter((country): country is string => Boolean(country))
      )
    ].sort();
    setParticipantsCountries(
      pCountries.map((country) => {
        const pCountry = countries.find((c: Country) => c.iso_code === country);
        if (pCountry) {
          return pCountry;
        }
        return {
          iso_code: country,
          name: country,
          country_code: country
        } as Country;
      })
    );
  }, [originalParticipants, countries]);

  return (
    <div className={`modal add_leads_modal ${shown ? "show" : "hide"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          {/* HEADER */}
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row">
                <h3 className="modal_page_header">Add Leads to Client</h3>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="container-fluid modal_body_content card">
              {/* DROP DOWN ROWS */}
              <div className="row card-header dropdown_container">
                <div className="lang_country_container">
                  <div className="col language_drop_container">
                    <LanguagesFilter
                      jsonlanguagesData={participantsLanguages}
                      onLanguagesFilterChange={
                        setParticipantsAddLanguagesFilter
                      }
                      filtersCleared={!filterApplied}
                      location={"modal"}
                    />
                  </div>
                  <div className="col country_drop_container">
                    <CountryFilter
                      jsonCountriesData={participantsCountries}
                      onCountryFilterChange={setParticipantsAddCountryFilter}
                      filtersCleared={!filterApplied}
                      location={"modal"}
                    />
                  </div>
                </div>
                {/* <div>Groups</div> */}

                <div className="tags_keyword_container">
                  <div className="tags_drop_container col">
                    <MultipleParticipantIDsFilter
                      jsonData={searchedMultiParticipantsIDs}
                      onFilterChange={setSearchedMultiParticipantsIDs}
                      filtersCleared={!filterApplied}
                      location={"modal"}
                      name="Participant IDs"
                    />
                  </div>
                  {/* Keyword Search, Clear Filters */}
                  <div className="keyword_clear_container col">
                    <Search
                      onSearchChange={setParticipantsSearch}
                      filtersCleared={!filterApplied}
                      // location={"modal"}
                    />
                    {/* Clear Filters */}
                    <button
                      className="btn btn-primary ms-2 btn_clear_filters"
                      disabled={false}
                      onClick={handleClearFilters}
                    >
                      <IconBroom className="icon icon_white" />
                    </button>
                  </div>
                </div>

                <div className="p-0">
                  <div className="row card-header amount_container justify-content-between">
                    <div className="col-1 d-flex align-items-center p-0">
                      <div className="checkbox_select">
                        {/* SELECT ALL */}
                        <input
                          type="checkbox"
                          name=""
                          id=""
                          onChange={handleSelectAllCheckboxChange}
                          checked={
                            selectedParticipants.length ===
                              originalParticipants.length &&
                            originalParticipants.length > 0
                          }
                        />
                      </div>
                      <div className="ps-1">
                        {selectedParticipants.length > 0
                          ? selectedParticipants.length
                          : ""}
                      </div>
                    </div>
                    <div className="col-5 d-flex">
                      <div className="message">{message ? message : <></>}</div>
                    </div>
                    <div className="col-auto d-flex justify-content-end p-0">
                      {/* <TableRowsSelector
                        handleChangeRows={handleChangeRows}
                        rowsPerPage={participantsPerPage}
                        tableName="Leads"
                        arrayRowSelector={[10, 20, 50, 100]}
                      /> */}
                      {selectedParticipants.length !== 0 && (
                        <button
                          className={`add_button ${
                            selectedParticipants.length === 0 && "disabled"
                          }`}
                          onClick={() => handleAddParticipant()}
                        >
                          Add{" "}
                          {selectedParticipants.length ===
                          originalParticipants.length
                            ? "All "
                            : selectedParticipants.length ===
                                filteredAddParticipants.length
                              ? "All Filtered "
                              : "Selected "}
                          Participants to Client
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* PARTICIPANT CONTAINER */}
              {loadingParticipants ? (
                <div className="row d-flex justify-content-center align-items-center py-1">
                  May take a few seconds to load
                  <div className="d-flex justify-content-center custom_spinner_container">
                    <div className="spinner-border" role="status"></div>
                  </div>
                </div>
              ) : originalParticipants.length === 0 ? (
                <div className="row d-flex justify-content-center align-items-center py-1">
                  No participants found
                </div>
              ) : (
                <div className="row">
                  <div className="add_participants_container">
                    {/* Iterate through original participants, displaying names */}
                    {filteredAddParticipants.length === 0 ? (
                      <div className="row d-flex justify-content-center align-items-center py-1">
                        No results found. Please adjust your filters.
                      </div>
                    ) : (
                      <>
                        {currentParticipants.map((participant) => (
                          <div
                            key={participant.id.toString()}
                            className="row d-flex justify-content-between align-items-center add_participant_single"
                          >
                            <div className="col-5 d-flex">
                              <input
                                className="checkbox_select"
                                type="checkbox"
                                name=""
                                id=""
                                onChange={() => {
                                  handleCheckboxChange(participant.id);
                                }}
                                checked={selectedParticipants.includes(
                                  participant.id
                                )}
                              />
                              <div className="add_participant_name">
                                {participant.id}
                                {" | "}
                                {participant.external_id}
                                {" | "}
                                {participant.first_name} {participant.last_name}
                              </div>
                            </div>
                            <div className="col-3 add_participant_name">
                              {participant.email}
                            </div>
                            {/* COUNTRIES */}
                            <div className="col-2 d-flex ">
                              {
                                countries.find(
                                  (country) =>
                                    country.iso_code === participant.country_iso
                                )?.name
                              }{" "}
                            </div>
                            {/* LANGUAGES */}
                            <div className="col-2 d-flex">
                              {
                                languages.find(
                                  (language) =>
                                    language.iso_code ===
                                    participant.language_iso
                                )?.name
                              }
                            </div>
                          </div>
                        ))}
                        <PaginationNavigation
                          currentPage={currentPage}
                          totalResults={
                            filteredAddParticipants
                              ? filteredAddParticipants.length
                              : 0
                          }
                          resultsPerPage={participantsPerPage}
                          setCurrentPage={setCurrentPage}
                        />
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLeadsModal;
