import React, { useState } from "react";
import "./addMinorModal.scss";
import Swal from "sweetalert2";
import Dropzone, { FileRejection } from "react-dropzone";
import {
  confirmS3Upload,
  fetchAppBundleS3URL
} from "../../models/appVersions.model.ts";

interface AddMinorVersionModalProps {
  closeModal: () => void;
  shown: boolean;
  environment: string;
  platform: string;
  major_version: number;
  fetchMinorCallBack: () => void;
}

const AddMinorVersionModal: React.FC<AddMinorVersionModalProps> = ({
  closeModal,
  shown,
  environment,
  platform,
  major_version,
  fetchMinorCallBack
}) => {
  // For Dropzone
  const [hasUploaded, setHasUploaded] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const [description, setDescription] = useState("");

  // For loading spinner
  const [busySubmitting, setBusySubmitting] = useState(false);

  // Close modal and reset states
  const handleClose = () => {
    closeModal();
    clearFile();
  };

  const clearFile = () => {
    setHasUploaded(false);
    setFile(null);
    setBusySubmitting(false);
  };

  // Dropzone
  const onDropdown = (
    acceptedFiles: File[],
    fileRejections: FileRejection[]
  ) => {
    // Too many files
    if (
      fileRejections.some((rejection) =>
        rejection.errors.some((error) => error.code === "too-many-files")
      )
    ) {
      Swal.fire({
        icon: "error",
        title: "Too many files",
        text: "Only one file is allowed.",
        showConfirmButton: true
      });
      return;
    }

    // Incorrect File Type
    if (
      fileRejections.some((rejection) =>
        rejection.errors.some((error) => error.code === "file-invalid-type")
      )
    ) {
      Swal.fire({
        icon: "error",
        title: "Invalid file type",
        text: "Only ZIP files are allowed.",
        showConfirmButton: true
      });
      return;
    }

    const file = acceptedFiles[0];

    const fileExtension = file.name.split(".").pop() || "";
    if (!["zip"].includes(fileExtension)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid file type. Only ZIP files are allowed.",
        showConfirmButton: true
      });
      return;
    }

    // If platform is android, name must be index.android.bundle, iOS is main.jsbundle.zip
    if (
      platform === "android" &&
      file.name !== "index.android.bundle" &&
      file.name !== "index.android.bundle.zip"
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid file name. Must be index.android.bundle.zip",
        showConfirmButton: true
      });
      return;
    }

    if (
      platform === "ios" &&
      file.name !== "main.jsbundle" &&
      file.name !== "main.jsbundle.zip"
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid file name. Must be main.jsbundle.zip",
        showConfirmButton: true
      });
      return;
    }

    setHasUploaded(true);
    setFile(file);

    const reader = new FileReader();
    reader.onload = (e) => {
      if (!e.target?.result) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Error reading file",
          showConfirmButton: true
        });
        return;
      }
    };

    reader.readAsArrayBuffer(file);
  };

  // Get S3 upload url with key
  // Upload to S3
  // Send confirm to BE
  const onHandleSubmit = async () => {
    if (!file) {
      return;
    }

    if (description === "") {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Description is required",
        showConfirmButton: true
      });
      return;
    }

    if (description.length > 255 || description.length < 5) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Description must be between 5 and 255 characters",
        showConfirmButton: true
      });
      return;
    }

    Swal.fire({
      title: "Uploading App Bundle",
      text: "Please wait...",
      showConfirmButton: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await fetchAppBundleS3URL(
      "unique_app_id",
      environment,
      platform,
      major_version,
      file.name
    );

    if (response && response.signedURL && response.s3_key) {
      const uploadingToS3 = await fetch(response.signedURL, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type
        }
      });
      // S3 Upload
      if (uploadingToS3.status === 200) {
        // Send confirm BE
        const confirmResponse = await confirmS3Upload(
          platform,
          major_version,
          response.s3_key,
          description
        );

        if (confirmResponse) {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "File uploaded successfully",
            showConfirmButton: true,
            didClose: () => {
              fetchMinorCallBack();
            }
          });
          clearFile();
          handleClose();
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Failed to confirm upload",
            showConfirmButton: true
          });
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Failed to upload to S3",
          showConfirmButton: true
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to get S3 URL",
        showConfirmButton: true
      });
    }
  };

  return (
    <div className={`modal add_minor_modal ${shown ? "show" : "hide"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          {/* HEADER */}
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row">
                <h3 className="modal_page_header">New Minor Version</h3>
                <h4 className="modal_page_header">
                  Major Version {major_version}: {platform.toUpperCase()}
                </h4>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="container-fluid modal_body_content card">
              {/* CARD BODY */}
              <div className="card-body">
                {/* Version */}
                {/* <div className="row mt-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="version">Version</label>
                      <input
                        type="text"
                        className="form-control"
                        id="version"
                        placeholder="Enter version"
                      />
                    </div>
                  </div>
                </div> */}
                {/* Description */}
                <div className="row mt-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="description">Description</label>
                      <textarea
                        className="form-control"
                        id="description"
                        placeholder="Enter description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      ></textarea>
                    </div>
                  </div>
                </div>
                {/* Status */}
                {/* <div className="row mt-3">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <label htmlFor="status">Status</label>
                      <select
                        className="form-control"
                        id="status"
                        value={appStatus ? "active" : "inactive"}
                        onChange={(e) => handleStatusChange(e.target.value)}
                      >
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div> */}
                {/* Dropzone */}
                <div className="row mt-3">
                  <label htmlFor="">App Bundle</label>
                  <div className="col-sm-12">
                    <Dropzone
                      maxFiles={1}
                      // 5mb
                      maxSize={5 * 1024 * 1024}
                      accept={{ "application/zip": [".zip"] }}
                      onDrop={onDropdown}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div>
                          {hasUploaded && (
                            <span
                              className="clear_file"
                              onClick={() => {
                                clearFile();
                              }}
                            >
                              ×
                            </span>
                          )}
                          <div className="drop_zone_upload" {...getRootProps()}>
                            <input {...getInputProps()} />
                            {hasUploaded ? (
                              <p>{file ? file.name : ""}</p>
                            ) : (
                              <p>Drop file or click here to upload...</p>
                            )}
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>
                </div>
                {/* Submit/Cancel */}
                <div className="row mt-3">
                  <div className="col d-flex justify-content-end">
                    <button
                      type="submit"
                      className="btn btn-primary me-2"
                      disabled={busySubmitting}
                      onClick={() => {
                        onHandleSubmit();
                      }}
                    >
                      Submit
                    </button>
                    <button
                      type="submit"
                      className="btn btn-secondary"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddMinorVersionModal;
