import React from "react";

import FieldDropDown from "../../Fields/fieldDropdown.component";
import FieldDropDownWithSearch from "../../Fields/fieldDropdownWithSearch.component";
import FieldNumber from "../../Fields/fieldNumber.component";
import FieldText from "../../Fields/fieldText.component";
import { AddStudyWizardStepDiaryConfigurationProps } from "../addStudyWizard.component";
import AddStudyWizardFooter from "../addStudyWizardFooter.component";

interface StepDiaryConfigurationProps
  extends AddStudyWizardStepDiaryConfigurationProps {}

const StepDiaryConfiguration: React.FC<StepDiaryConfigurationProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  handleWizardBack,
  handleWizardSubmit,
  handleInputChange,
  // Fields
  diaryActiveDays,
  diaryGraceDays,
  diaryBonusDays,
  diaryMinimumDailyCompletes,
  diaryMaximumDailyCompletes,
  diarySurveySystem,
  diarySurveySystemDetails,
  pageValid,
  inputErrors,
  wscSurveyOptionsData,
  wscSurveysData,
  diaryName
}) => {
  return (
    <div className="card card-default add_study_form_page p-4">
      <h3 className="form_header">Diary Configuration: {diaryName}</h3>
      <div className="add_study_form_page_inputs">
        {/* submit */}
        <FieldNumber
          tooltip="The number of diary days that are needed for participants to fulfil the requirements of the diary.  This also determines the number of 'to-do' icons within the diary screen."
          tooltipPosition="bottom"
          label="Active Days"
          inputName="diaryActiveDays"
          inputValue={diaryActiveDays ? diaryActiveDays : null}
          onInputChange={handleInputChange}
          min={1}
          error={
            inputErrors?.diaryActiveDays ? inputErrors.diaryActiveDays : null
          }
        />
        <FieldNumber
          tooltip="Grace days are diary days that a participant can 'skip' through non-compliance without being penalized - e.g., they can still complete their diary."
          label="Grace Days"
          inputName="diaryGraceDays"
          inputValue={diaryGraceDays ? diaryGraceDays : null}
          onInputChange={handleInputChange}
          min={0}
          error={
            inputErrors?.diaryGraceDays ? inputErrors.diaryGraceDays : null
          }
        />
        {/* <FieldNumber
          tooltip="Bonus days allow participants to complete additional diary days, after the number of 'active days' have been completed. "
          label="Bonus Days"
          inputName="diaryBonusDays"
          inputValue={diaryBonusDays ? diaryBonusDays : null}
          onInputChange={handleInputChange}
          min={0}
          error={
            inputErrors?.diaryBonusDays ? inputErrors.diaryBonusDays : null
          }
        /> */}
        <FieldNumber
          tooltip="The minimum number of diaries that must be completed in a single day,  to be counted as compliant for that day. This will be where you select the amount of minimum daily completes for diary for the study"
          label="Minimum Daily Completes"
          inputName="diaryMinimumDailyCompletes"
          inputValue={
            diaryMinimumDailyCompletes ? diaryMinimumDailyCompletes : null
          }
          onInputChange={handleInputChange}
          min={1}
          error={
            inputErrors?.diaryMinimumDailyCompletes
              ? inputErrors.diaryMinimumDailyCompletes
              : null
          }
        />
        <FieldNumber
          tooltip="The maximum number of diaries that can be completed in a single day."
          label="Maximum Daily Completes"
          inputName="diaryMaximumDailyCompletes"
          inputValue={
            diaryMaximumDailyCompletes ? diaryMaximumDailyCompletes : null
          }
          onInputChange={handleInputChange}
          min={1}
          error={
            inputErrors?.diaryMaximumDailyCompletes
              ? inputErrors.diaryMaximumDailyCompletes
              : null
          }
        />
        <FieldDropDown
          tooltip="The platform which will be linked for this diary - if you want to add a platform that’s not Nfield or WSC, please use 'custom'."
          label="Survey System"
          inputName="diarySurveySystem"
          inputValue={diarySurveySystem ? diarySurveySystem : ""}
          inputOptions={wscSurveysData.filter(
            (survey) => survey.id !== "viewEQ"
          )}
          onInputChange={handleInputChange}
        />
        {diarySurveySystem === "wsc" ? (
          <FieldDropDownWithSearch
            tooltip="Selection of the diary survey as it is listed / named within WSC.  You can search and / or utilize the dropdown menu. "
            label="WSC Survey"
            inputName="diarySurveySystemDetails"
            inputValue={
              diarySurveySystemDetails ? diarySurveySystemDetails : ""
            }
            inputOptions={wscSurveyOptionsData}
            onInputChange={handleInputChange}
            error={
              inputErrors?.diarySurveySystemDetails
                ? inputErrors.diarySurveySystemDetails
                : null
            }
          />
        ) : diarySurveySystem === "nfield" ? (
          <FieldText
            tooltip="This will be where you select the nField survey for the study"
            label="nField Survey"
            inputName="diarySurveySystemDetails"
            inputValue={
              diarySurveySystemDetails ? diarySurveySystemDetails : ""
            }
            onInputChange={handleInputChange}
            error={
              inputErrors?.diarySurveySystemDetails
                ? inputErrors.diarySurveySystemDetails
                : null
            }
          />
        ) : diarySurveySystem === "custom" ? (
          <FieldText
            tooltip="This will be where you select the custom survey for the study"
            label="Custom Survey"
            inputName="diarySurveySystemDetails"
            inputValue={
              diarySurveySystemDetails ? diarySurveySystemDetails : ""
            }
            onInputChange={handleInputChange}
            error={
              inputErrors?.diarySurveySystemDetails
                ? inputErrors.diarySurveySystemDetails
                : null
            }
          />
        ) : null}
        <AddStudyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          handleWizardSubmit={handleWizardSubmit}
          pageValid={pageValid}
        />
      </div>
    </div>
  );
};

export default StepDiaryConfiguration;
