import { apiFetcher } from "../services/API.service";
import { EQType, ViewEQResponse } from "../types";

// #region isValidEQResponse
function isValidEQResponse(
  data: any
): data is { viewEQCompletes: EQType[]; viewEQLink: string } {
  return (
    Array.isArray(data.viewEQCompletes) &&
    typeof data.viewEQLink === "string" &&
    data.viewEQCompletes.every(
      (complete: EQType) =>
        typeof complete.id === "string" &&
        typeof complete.participant_id === "string" &&
        typeof complete.view_eq_link === "string" &&
        typeof complete.event_at === "string"
    )
  );
}
// #endregion isValidEQResponse

// #region fetchEQData
export async function fetchEQData(
  clientID: string,
  studyID: string,
  surveyID: string
): Promise<ViewEQResponse | string> {
  try {
    const response = await apiFetcher<unknown>("/survey/viewEQ", "POST", {
      body: {
        clientID,
        studyID,
        surveyID
      }
    });
    console.log(response);

    if (response.status === 200) {
      if (isValidEQResponse(response.data)) {
        return {
          statusCode: 200,
          ViewEQCompletes: response.data.viewEQCompletes,
          ViewEQLink: response.data.viewEQLink
        };
      } else {
        return "Invalid response, failed to validate data";
      }
    } else if (response.status === 204) {
      return { statusCode: 204, ViewEQCompletes: [], ViewEQLink: "" };
    } else {
      return "Failed to fetch data from server";
    }
  } catch (e) {
    console.error(e);
    return "Failed to make request to server";
  }
}
// #endregion fetchEQData
