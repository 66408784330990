import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { Client } from "../types";

// Type guard to check if an object is a Client
// #region isClient
function isClient(obj: any): obj is Client {
  // console.log("Checking if object is a client");
  // console.log(obj, "obj");
  const validID = typeof obj.id === "string";
  const validName = typeof obj.name === "string";
  const validStatus =
    typeof obj.status === "string" ||
    obj.status === null ||
    obj.status === undefined;
  const validLogo =
    typeof obj.img_url === "string" ||
    obj.img_url === null ||
    obj.img_url === undefined;
  const validLogoIcon =
    typeof obj.icon_url === "string" ||
    obj.icon_url === null ||
    obj.icon_url === undefined;

  const AllValid =
    validID && validName && validStatus && validLogo && validLogoIcon;
  // console.log(validID, "validID");
  // console.log(validName, "validName");
  // console.log(validStatus, "validStatus");
  // console.log(validLogo, "validLogo");
  // console.log(validLogoIcon, "validLogoIcon");
  // console.log(AllValid, "AllValid");

  return AllValid;
}
// #endregion isClient

// #region fetchAllClients
// A post request to fetch all the clients
export async function fetchAllClients(): Promise<Client[] | string> {
  // console.log("Fetching all clients");
  const response = await apiFetcher<any[]>("/getAllClients", "POST", {
    body: {}
  });

  // console.log(response, "response");

  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    // console.log(Array.isArray(response.data));
    // console.log(response ? response : "No response");
    if (Array.isArray(response.data) && response.data.every(isClient)) {
      // console.log("Successfully fetched all clients");
      const clients: Client[] = response.data;
      // console.log(clients, "clients");
      return clients;
    } else {
      console.log("Invalid data received");
      return "Invalid data received";
    }
  } else {
    console.log("Failed to fetch all clients");
    return "Failed to fetch all clients";
  }
}
// #endregion fetchAllClients

// #region fetchSingleClientByID
export async function fetchSingleClientByID(
  clientID: string
): Promise<Client | string> {
  // console.log("Fetching all clients");
  const response = await apiFetcher<any>("/getClient", "POST", {
    body: {
      clientID: clientID
    }
  });

  // console.log(response, "response");

  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    // console.log(Array.isArray(response.data));
    // console.log(response ? response : "No response");
    // if (Array.isArray(response.data) && response.data.every(isClient)) {
    if (isClient(response.data)) {
      // console.log("Successfully fetched all clients");
      const client: Client = response.data;
      // console.log("client: ", client);

      return client;
    } else {
      console.log("Invalid data received");
      return "Invalid data received";
    }
  } else {
    console.log("Status: ", response.status);
    console.log("Failed to fetch all clients");
    return "Failed to fetch all clients";
  }
}
// #endregion fetchSingleClientByID

// #region fetchAllClientsNames
export async function fetchAllClientsNames(): Promise<Client[] | false> {
  // console.log("Fetching all clients");
  const response = await apiFetcher<any[]>("/getClientName", "POST", {
    body: {}
  });

  // console.log(response, "response");

  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    // console.log(Array.isArray(response.data));
    // console.log(response ? response : "No response");
    if (Array.isArray(response.data) && response.data.every(isClient)) {
      // console.log("Successfully fetched all clients");
      const clients: Client[] = response.data;
      // console.log(clients, "clients");
      return clients as Client[];
    } else {
      console.log("Invalid data received");
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Type Issue"
      });
      return false;
    }
  } else {
    // console.log("Failed to fetch all clients");
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Failed to fetch all clients, please try again later."
    });
    return false;
  }
}
// #endregion fetchAllClientsNames
