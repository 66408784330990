import React, { useEffect, useState } from "react";
import {
  Bucket,
  BucketParticipant,
  BucketsNotifications,
  GsiAnswer,
  Participant
} from "../../types";

// api request to get buckets
import {
  fetchAllBuckets,
  fetchBucketNotifications
} from "../../models/bucket.model.ts";

import Swal from "sweetalert2";
import { IconDownload } from "../../assets/images/icons/icons.ts";
import { fetchAllParticipantsByID } from "../../models/participant.model.ts";
import { prepareAndDownloadExcel } from "../../utilities/exportBucketsV2.ts";
import { isFetchResponseError } from "../../utilities/utils.ts";
import LoadingPencilAnimation from "../Loaders/LoadingPencil.tsx";
import { BucketFilter } from "./BucketFiltering/bucket-filter.component.tsx";
import Histogram from "./Histogram/histogram.component.tsx";
import "./buckets.styles.scss";

type BucketsContainerProps = {
  client?: string;
  study: string | undefined;
  setTabClicked?: (tab: string) => void;
};

interface FilterCondition {
  type: keyof Bucket | "No GSI Answers" | "birthdate" | "currentage"; // Ensures 'type' is a valid key of 'Bucket'
  value: any; // You can replace 'any' with more specific types if possible
}

// interface BucketCounts {
// 	[bucketId: string]: {
// 		compliant: number;
// 		non_compliant: number;
// 		total_participants: number;
// 	};
// }

type TransformedDataType = { [key: string]: string[] };

export const BucketsContainer: React.FC<BucketsContainerProps> = ({
  client,
  study,
  setTabClicked
}) => {
  const [buckets, setBuckets] = useState<Bucket[]>([]);
  const [bucketParticipants, setBucketParticipants] = useState<
    BucketParticipant[] | null
  >([]);
  const [studyParticipants, setStudyParticipants] = useState<
    Participant[] | null
  >([]); // TODO: replace with type [Participant

  const [filteredBuckets, setFilteredBuckets] = useState<Bucket[]>([]);
  const [totalStudyParticipants, setTotalStudyParticipants] =
    useState<number>(0);
  const [notifications, setNotifications] =
    useState<BucketsNotifications | null>(null); // TODO: replace with type [Notifications]
  const [gsiData, setGsiData] = useState<TransformedDataType>({});
  const [ageRange, setAgeRange] = useState<{ min: number; max: number }>({
    min: 16,
    max: 99
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>();

  const clearFilters = () => {
    setFilteredBuckets(buckets);
  };

  const applyFilters = (
    filterConditions: FilterCondition[],
    useAndLogic: boolean
  ) => {
    // console.log(filterConditions);
    if (!bucketParticipants) return;

    let bucketsWithCounts = buckets.map((bucket) => ({
      ...bucket,
      compliant_participants_count: 0,
      non_compliant_participants_count: 0,
      total_participants_count: 0,
      bucket_participants_non_compliant: [] as string[],
      bucket_participants_compliant: [] as string[]
    }));

    const calculateAge = (dobStr: string) => {
      // Assuming dobStr format is 'YYYY.MM.DD.HH.MM' or 'YYYY-MM-DD HH:MM:SS'
      // Split the string to get the year, month, and day

      const parts = dobStr.split(/[-.]/);
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; // JS months are 0-indexed
      const day = parseInt(parts[2], 10);

      // Create the birthdate in epoch time (UTC)
      const birthdateEpoch = Date.UTC(year, month, day);

      // Get the current date in epoch time (UTC)
      const now = new Date();
      const currentEpoch = Date.UTC(
        now.getUTCFullYear(),
        now.getUTCMonth(),
        now.getUTCDate()
      );

      // Calculate the age in milliseconds, then convert to years
      const ageInMilliseconds = currentEpoch - birthdateEpoch;
      const ageInYears = ageInMilliseconds / (365.25 * 24 * 60 * 60 * 1000); // Using 365.25 accounts for leap years

      return Math.floor(ageInYears);
    };

    bucketParticipants.forEach((participant) => {
      // Check if participant matches any condition if useAndLogic is false
      const matchesAnyCondition =
        !useAndLogic &&
        filterConditions.some((condition) => {
          if (condition.type === "birthdate") {
            const [minAge, maxAge] = condition.value.split("-").map(Number);
            console.log(minAge, maxAge);
            return (
              participant.gsi_answers?.some((answer: GsiAnswer) => {
                if (answer.variable_name === "birthdate") {
                  console.log(answer.value);
                  const age = calculateAge(answer.value.toString()); // Use the adjusted calculateAge
                  return age >= minAge && age <= maxAge;
                }
                return false;
              }) || false
            );
          } else if (condition.type === "currentage") {
            const [minAge, maxAge] = condition.value.split("-").map(Number);
            return (
              participant.gsi_answers?.some((answer: GsiAnswer) => {
                if (answer.variable_name === "currentage") {
                  const age = answer.value; // Use the adjusted calculateAge
                  return age >= minAge && age <= maxAge;
                }
                return false;
              }) || false
            );
          } else if (condition.type === "No GSI Answers") {
            // Special handling for "No GSI Answers"
            return (
              (!participant.gsi_answers || participant.gsi_answers === null) ===
              (condition.value === "1")
            );
          } else {
            // Regular condition checking
            if (condition.value === "N/A") {
              // Check if the variable is selected and has "N/A" value
              return participant.gsi_answers?.some(
                (answer) =>
                  answer.variable_name === condition.type &&
                  String(answer.value) === ""
              );
            } else {
              return participant.gsi_answers?.some(
                (answer) =>
                  answer.variable_name === condition.type &&
                  String(answer.value) === String(condition.value)
              );
            }
          }
        });

      // Check if participant matches all conditions if useAndLogic is true
      const matchesAllConditions =
        useAndLogic &&
        filterConditions.every((condition) => {
          if (condition.type === "birthdate") {
            const [minAge, maxAge] = condition.value.split("-").map(Number);
            return (
              participant.gsi_answers?.some((answer: GsiAnswer) => {
                if (answer.variable_name === "birthdate") {
                  const age = calculateAge(answer.value.toString()); // Use the adjusted calculateAge
                  console.log(age);
                  return age >= minAge && age <= maxAge;
                }
                return false;
              }) || false
            );
          } else if (condition.type === "currentage") {
            const [minAge, maxAge] = condition.value.split("-").map(Number);
            return (
              participant.gsi_answers?.some((answer: GsiAnswer) => {
                if (answer.variable_name === "currentage") {
                  let age = answer.value; // Use the adjusted calculateAge
                  if (typeof age === "string") {
                    try {
                      age = parseInt(age);
                    } catch (e) {
                      console.error("Error parsing age:", e);
                      return false;
                    }
                  }
                  return age >= minAge && age <= maxAge;
                }
                return false;
              }) || false
            );
          } else if (condition.type === "No GSI Answers") {
            // Special handling for "No GSI Answers"
            if (condition.value === "N/A") {
              // Filter participants with null gsi_answers
              return (
                !participant.gsi_answers || participant.gsi_answers === null
              );
            } else {
              // Filter participants with non-null gsi_answers
              return participant.gsi_answers !== null;
            }
          } else {
            // Regular condition checking
            if (condition.value === "N/A") {
              // Check if the variable is selected and has "N/A" value
              return participant.gsi_answers?.some(
                (answer) =>
                  answer.variable_name === condition.type &&
                  String(answer.value) === ""
              );
            } else {
              return participant.gsi_answers?.some(
                (answer) =>
                  answer.variable_name === condition.type &&
                  String(answer.value) === String(condition.value)
              );
            }
          }
        });

      // If participant matches all conditions, update bucket counts
      if (matchesAnyCondition || matchesAllConditions) {
        participant.participant_buckets?.forEach(
          ({ bucket_id, compliance_status }) => {
            const bucketIndex = bucketsWithCounts.findIndex(
              (b) => String(b.id) === String(bucket_id)
            );
            if (bucketIndex !== -1) {
              const bucket = bucketsWithCounts[bucketIndex];
              bucket.total_participants_count++;
              if (compliance_status === "compliant") {
                // clear the bucket_participants_compliant array first
                bucket.bucket_participants_compliant.push(
                  String(participant.id)
                );
                bucket.compliant_participants_count++;
              } else if (compliance_status === "non-compliant") {
                bucket.bucket_participants_non_compliant.push(
                  String(participant.id)
                );
                bucket.non_compliant_participants_count++;
              }
            }
          }
        );
      }
    });

    setFilteredBuckets(bucketsWithCounts);
  };

  useEffect(() => {
    const fetchStudyParticipants = async () => {
      if (!client || !study) return;
      const response = await fetchAllParticipantsByID(
        "StudyParticipants",
        client,
        undefined,
        study,
        undefined,
        false
      );
      if (!isFetchResponseError(response)) {
        setStudyParticipants(response.participants);
      } else {
        setError(response.errorMessage);
      }
    };

    const fetchBucketsData = async () => {
      setLoading(true);
      try {
        // Simulate fetching data that includes participants with gsi_answers
        const response = await fetchAllBuckets(client, study); // This should return an array of participants

        if (response && typeof response !== "string") {
          // Assuming response includes the bucketParticipants
          let { buckets, bucketParticipant, totalParticipants } = response;

          buckets = buckets.map((bucket) => ({
            ...bucket,
            compliant_participants_count:
              bucket.compliant_participants_count || 0,
            non_compliant_participants_count:
              bucket.non_compliant_participants_count || 0,
            total_participants_count: bucket.total_participants_count || 0,
            bucket_participants_compliant:
              bucket.bucket_participants_compliant || [],
            bucket_participants_non_compliant:
              bucket.bucket_participants_non_compliant || []
          }));

          // Process bucketParticipants if it's defined
          if (bucketParticipant) {
            bucketParticipant.forEach((participant) => {
              // Process each participant and if they are in a bucket it must add the id to the bucket_participants array
              if (participant.participant_buckets) {
                // console.log(participant)
                participant.participant_buckets.forEach((pBucket) => {
                  const bucket = buckets.find(
                    (b) => b.id === String(pBucket.bucket_id)
                  );

                  if (bucket) {
                    if (pBucket.compliance_status === "compliant") {
                      bucket.bucket_participants_compliant?.push(
                        String(participant.id)
                      );
                      bucket.compliant_participants_count =
                        (bucket.compliant_participants_count || 0) + 1;
                    } else if (pBucket.compliance_status === "non-compliant") {
                      bucket.bucket_participants_non_compliant?.push(
                        String(participant.id)
                      );
                      bucket.non_compliant_participants_count =
                        (bucket.non_compliant_participants_count || 0) + 1;
                    }

                    // Add participant ID to bucket_participants array

                    if (
                      ["completes", "compliant", "non-compliant"].includes(
                        bucket.type
                      )
                    ) {
                      bucket.total_participants_count += 1; // Safe to increment
                    }
                  }
                });
              }
            });
          }

          const sortedBuckets = await buckets.sort((a, b) => {
            // Prioritize "Pending"
            // Next, prioritize "compliant"
            if (a.type === "compliant") return -1;
            if (b.type === "compliant") return 1;

            // Then, prioritize "Non-Compliant"
            if (a.type === "non-compliant") return -1;
            if (b.type === "non-compliant") return 1;

            if (a.type === "completes") return -1;
            if (b.type === "completes") return 1;
            // Prioritize "Bucket" over "Bonus"
            if (a.type === "bucket" && b.type === "bonus") return -1;
            if (a.type === "bonus" && b.type === "bucket") return 1;

            // Keep the rest as they are
            return 0;
          });

          setBuckets(sortedBuckets);
          setFilteredBuckets(sortedBuckets);
          // Transform gsi_answers for all participants
          const gsiDataTransformed = bucketParticipant
            ? bucketParticipant.reduce(
                (acc: TransformedDataType, participant) => {
                  // Handle participants with gsi_answers
                  if (
                    participant.gsi_answers &&
                    participant.gsi_answers.length > 0
                  ) {
                    participant.gsi_answers.forEach(
                      ({ variable_name, value }) => {
                        const processedValue =
                          value.toString().trim() === ""
                            ? "N/A"
                            : value.toString();
                        if (!acc[variable_name]) acc[variable_name] = [];
                        if (!acc[variable_name].includes(processedValue))
                          acc[variable_name].push(processedValue);
                      }
                    );
                  } else {
                    // Handle participants without gsi_answers by assigning them a specific variable_name and value
                    const noAnswersVariableName = "No GSI Answers"; // Define a key to represent participants without gsi_answers
                    const noAnswersValue = "N/A"; // Define a value for the key
                    if (!acc[noAnswersVariableName])
                      acc[noAnswersVariableName] = [];
                    if (!acc[noAnswersVariableName].includes(noAnswersValue))
                      acc[noAnswersVariableName].push(noAnswersValue);
                  }
                  return acc;
                },
                {}
              )
            : {}; // Use an empty object if 'bucketParticipant' is undefined or null

          // After transformation, proceed with setting state or other actions
          setBucketParticipants(bucketParticipant || []); // Handle potential undefined or null value gracefully
          setGsiData(gsiDataTransformed);
          setTotalStudyParticipants(totalParticipants || 0);
          // setBuckets(buckets); // Assuming sorting is done before this step
        } else {
          setError(response);
        }
      } catch (err: any) {
        setError(err.message || "An error occurred.");
      } finally {
        setLoading(false);
      }
    };

    fetchBucketsData();
    fetchStudyParticipants();
  }, [client, study]);

  // Fetch notifications when component mounts
  useEffect(() => {
    const fetchNotifications = async () => {
      const fetchedNotifications = await fetchBucketNotifications();
      if (typeof fetchedNotifications === "string") {
        console.error("Error fetching notifications:", fetchedNotifications);
        Swal.fire({
          title: "Error fetching notifications",
          text: fetchedNotifications,
          icon: "error"
        });
        setNotifications(null);
        return;
      }
      setNotifications(fetchedNotifications);
    };

    fetchNotifications();
  }, []);

  if (loading)
    return (
      <div className="p-0 d-flex justify-content-center align-items-center">
        <LoadingPencilAnimation isVisible={loading} title="Buckets" />
      </div>
    );
  if (error)
    return (
      <div className="p-0 d-flex justify-content-center align-items-center">
        <h3>There are no buckets for this study</h3>
      </div>
    );

  return (
    <>
      <button
        className="btn btn-sm btn-primary"
        onClick={() => {
          if (bucketParticipants !== null && studyParticipants !== null) {
            prepareAndDownloadExcel(
              buckets,
              bucketParticipants,
              studyParticipants,
              `BucketData-${study}.xlsx`
            );
          } else {
            console.log("No participants data available for export.");
          }
        }}
      >
        <IconDownload className="icon icon_white download" />
        Export Report
      </button>
      {buckets && buckets.length > 0 ? (
        <div className="row w-100">
          <div className="col-lg-8 col-xl-9 col-xxl-10 p-0">
            <Histogram
              buckets={filteredBuckets}
              notifications={notifications}
              totalParticipants={totalStudyParticipants}
              setTabClicked={setTabClicked}
            />
          </div>
          <div className="col-xxl-2 col-xl-3 col-lg-4 p-0">
            <BucketFilter
              gsiData={gsiData}
              // rangeChange={handleRangeChange}
              applyFilters={applyFilters}
              clearFilters={clearFilters}
            />
          </div>
          {/* <div className="buckets-container">
            {buckets.map((bucket) => {
              if (
                ![
                  "completes",
                  "compliant",
                  "non-compliant",
                  "single",
                  "days",
                ].includes(bucket.bucket_type)
              ) {
                return (
                  <BucketComponent
                    key={bucket.id}
                    client={client || ""}
                    study={study || ""}
                    bucket={bucket}
                    notifications={notifications}
                  />
                );
              }
              return null;
            })}
            <div className="special-buckets">
              {buckets.map((bucket, i) => {
                if (
                  [
                    "completes",
                    "compliant",
                    "non-compliant",
                    "single",
                  ].includes(bucket.bucket_type)
                ) {
                  return (
                    <React.Fragment key={i}>
                      <BucketComponent
                        key={bucket.id}
                        client={client || ""}
                        study={study || ""}
                        bucket={bucket}
                        notifications={notifications}
                      />
                    </React.Fragment>
                  );
                }
                return null;
              })}
            </div>
          </div> */}
        </div>
      ) : (
        <div className="p-0 d-flex justify-content-center align-items-center">
          <h4>There are no buckets for this study</h4>
        </div>
      )}
    </>
  );
};
