import React, { useEffect, useState } from "react";
import * as Icons from "../../assets/images/icons/icons.ts";
import "../../components/Tables/tableComponents.scss"; // shared styles for tables
import { fetchAllClientsNames } from "../../models/client.model.ts";
import { fetchAllLeadsParticipants } from "../../models/leads.model.ts";
import ErrorPage from "../../pages/Error/error.page.tsx";
import {
  Client,
  Country,
  FetchResponseError,
  Language,
  LeadsParticipant,
  Tags,
  Timezone
} from "../../types";
import {
  getResearcherConfigTimezone,
  getSystemConfigCountries,
  getSystemConfigLanguages,
  getSystemConfigTimezones
} from "../../utilities/config.util";
import { exportLeads } from "../../utilities/exportTable.util.ts";
import { isFetchResponseError } from "../../utilities/utils";
import AddParticipantsModal from "../AddParticipantsModal/addParticipantsModal.component.tsx";
import ImportParticipantsModal from "../ImportParticipantsModal/importParticipantsModal.component.tsx";
import BulkActions from "../Tables/BulkActions/bulkActions.tsx";
import FiltersContainer from "../Tables/Filters/filtersContainer.component.tsx";
import LeadsTable from "../Tables/LeadsTable/leadsTable.component.tsx";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation.tsx";
import TableRowsSelector from "../Tables/TableRowsSelector/tableRowsSelector.tsx";
import TimezoneSwitcher from "../Tables/TimeZoneSwitcher/timezoneSwitcher.tsx";
import ViewLeadModal from "../ViewLeadModal/viewLeadModal.tsx";
// import "./participants.scss";

interface DisplayLeadsProps {}
const DisplayLeads: React.FC<DisplayLeadsProps> = ({}) => {
  const [currentPage, setCurrentPage] = useState(1);
  // const participantsPerPage = 20;
  const [participantsPerPage, setParticipantsPerPage] = useState(20);

  const [participantsTagsFilter, setParticipantsTagsFilter] = useState<
    string[]
  >([]);

  const [participantsLanguagesFilter, setParticipantsLanguagesFilter] =
    useState<string[]>([]);

  const [participantsCountryFilter, setParticipantsCountryFilter] = useState<
    string[]
  >([]);
  // const [participantsGenderFilter, setParticipantsGenderFilter] = useState<
  //   string[]
  // >([]);

  // const [participantsGenders, setParticipantsGenders] = useState<string[]>([]);

  // const [participantsAgeRangeFilter, setParticipantsAgeRangeFilter] = useState<
  //   string[]
  // >([]);

  // const [participantsAgeRange, setParticipantsAgeRange] = useState<string[]>([
  //   "Under 18",
  //   "18-24",
  //   "25-34",
  //   "35-44",
  //   "45-54",
  //   "55-64",
  //   "65+",
  //   "Unknown"
  // ]);

  const [participantsStatusFilter, setParticipantsStatusFilter] = useState<
    string[]
  >([]);

  const [participantsUDIDFilter, setParticipantsUDIDFilter] =
    useState<boolean>(false);

  const [participantsSearch, setParticipantsSearch] = useState<string>("");

  // State variable to hold the original participants
  const [originalParticipants, setOriginalParticipants] = useState<
    LeadsParticipant[]
  >([]);
  // State variable to hold the filtered participants
  const [filteredParticipants, setFilteredParticipants] = useState<
    LeadsParticipant[]
  >([]);
  const [loadingParticipants, setLoadingParticipants] = useState<boolean>(true);

  const [languages, setLanguages] = useState<Language[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(true);
  const [allClients, setAllClients] = useState<Client[]>([]);
  const [dateFetchedDate, setDateFetchedDate] = useState<Date | null>(null);
  const [displayedDataDateOffset, setDisplayedDataDateOffset] =
    useState<string>("");
  const [refreshButtonEnabled, setRefreshButtonEnabled] =
    useState<boolean>(false);
  const [errorFetchingData, setErrorFetchingData] =
    useState<FetchResponseError | null>(null);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  const [searchedMultiParticipantsIDs, setSearchedMultiParticipantsIDs] =
    useState<string[]>([]);

  // State to track if a user has been added, for fetching latest data
  const [userAdded, setUserAdded] = useState<boolean>(false);

  const fetchData = async (isRealData: boolean = false) => {
    // Fetch participants data from database
    setLoadingParticipants(true);

    try {
      const jsonData = await fetchAllLeadsParticipants(isRealData);

      if (isFetchResponseError(jsonData)) {
        console.log(
          isRealData ? "error Status: " : "error Code: " + jsonData.errorCode
        );
        console.log("error Message: " + jsonData.errorMessage);
        setErrorFetchingData(jsonData);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          isRealData
            ? "067f178a46aa376bf83069d315b626a3"
            : "4813616a8c447c2d9b694607a0649e70"
        ]);
      } else {
        setErrorFetchingData(null);
        setOriginalParticipants(jsonData.leads);
        setFilteredParticipants(jsonData.leads);
        setDateFetchedDate(new Date(jsonData.utcLastUpdate));
      }
    } catch (error) {
      console.error(
        "An error occurred while fetching participants data:",
        error
      );
      setErrorFetchingData({
        errorCode: "500",
        errorMessage: "Error fetching data"
      });
      setLoadingErrorText((prevErrors) => [
        ...prevErrors,
        isRealData
          ? "54b60f7a5dad6daf1510357ec608a0fb"
          : "20dd3c192b851920d1a40f196fed5cde"
      ]);
    }

    setLoadingParticipants(false);
  };

  useEffect(() => {
    // initial fetch of data
    fetchData();
  }, []);

  useEffect(() => {
    // get langauges from local storage using getConfigLanguages
    const fetchAllConfigLanguages = async () => {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllConfigCountries = async () => {
      try {
        const jsonData: Country[] | false = await getSystemConfigCountries();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setCountries(jsonData);
          } catch {
            console.error("Error setting countries");
            throw new Error("Error setting countries");
          }

          // setCountries([{ iso_code: "en", name: "English" }]);
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllConfigTimezones = async () => {
      try {
        const jsonData: Timezone[] | false = await getSystemConfigTimezones();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setTimezones(jsonData);
          } catch {
            console.error("Error setting timezones");
            throw new Error("Error setting timezones");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllClientNames = async () => {
      try {
        const jsonData: Client[] | false = await fetchAllClientsNames();
        if (!jsonData) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setAllClients(jsonData);
          } catch {
            console.error("Error setting clients");
            throw new Error("Error setting clients");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    const promises = [
      fetchAllConfigLanguages(),
      fetchAllConfigCountries(),
      fetchAllConfigTimezones(),
      fetchAllClientNames()
    ];

    Promise.all(promises)
      .then(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      })
      .catch(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      });
  }, []);

  // Update the filtered participants list
  useEffect(() => {
    if (errorFetchingData || !originalParticipants) {
      return;
    }
    // Step 1: Apply all filters except UID filter
    let preliminaryFiltered = originalParticipants.filter(
      (participant: LeadsParticipant) => {
        // check PII
        if (participant && participantsSearch && participantsSearch === "PII") {
          //  check if any of the fields are not null
          if (
            !(
              participant.first_name ||
              participant.last_name ||
              participant.email ||
              participant.mobile_number ||
              participant.country_iso
            )
          ) {
            return true;
          }
        }

        // Apply search filter if participantsSearch is not empty
        if (
          participant &&
          participantsSearch &&
          participantsSearch !== "" &&
          !(
            participant.first_name
              ?.toLowerCase()
              .includes(participantsSearch.toLowerCase()) ||
            participant.last_name
              ?.toLowerCase()
              .includes(participantsSearch.toLowerCase()) ||
            participant.email
              ?.toLowerCase()
              .includes(participantsSearch.toLowerCase()) ||
            (participant.first_name + " " + participant.last_name)
              .toLowerCase()
              .includes(participantsSearch.toLowerCase()) ||
            // Convert `id` to string and check if it includes `participantsSearch`
            participant.id?.toString().includes(participantsSearch) ||
            // Ensure `mobile_number` is a string before calling `.includes()`
            participant.mobile_number?.toString().includes(participantsSearch)
          )
        ) {
          return false;
        }

        // Apply languages filter if participantsLanguagesFilter is not empty
        if (
          participantsLanguagesFilter.length > 0 &&
          participant.language_iso &&
          !participantsLanguagesFilter.includes(participant.language_iso)
        ) {
          return false;
        } else if (
          participantsLanguagesFilter.length > 0 &&
          !participant.language_iso
        ) {
          console.log("participant.language_iso is null");
          return false;
        }

        // Apply country filter if participantsCountryFilter is not empty
        if (
          participantsCountryFilter.length > 0 &&
          participant.country_iso &&
          !participantsCountryFilter.includes(participant.country_iso)
        ) {
          return false;
        } else if (
          participantsCountryFilter.length > 0 &&
          !participant.country_iso
        ) {
          console.log("participant.country_iso is null");
          return false;
        }

        // Apply ageRange filter if participantsAgeRangeFilter is not empty
        // if (participantsAgeRangeFilter.length > 0) {
        //   const age = participant.age;
        //   const ageRangeCheck = participantsAgeRangeFilter.some((range) => {
        //     if (age === null) {
        //       return range === "Unknown"; // Directly check for "Unknown" if age is null
        //     }
        //     // If age is not null, then proceed to check the other ranges
        //     switch (range) {
        //       case "Under 18":
        //         return age < 18;
        //       case "18-24":
        //         return age >= 18 && age <= 24;
        //       case "25-34":
        //         return age >= 25 && age <= 34;
        //       case "35-44":
        //         return age >= 35 && age <= 44;
        //       case "45-54":
        //         return age >= 45 && age <= 54;
        //       case "55-64":
        //         return age >= 55 && age <= 64;
        //       case "65+":
        //         return age >= 65;
        //       default:
        //         return false;
        //     }
        //   });

        //   // If the age does not match any of the selected ranges and "Unknown" is not selected, exclude the participant
        //   if (!ageRangeCheck) {
        //     return false;
        //   }
        // }

        // Apply gender filter if participantsGenderFilter is not empty
        // if (participantsGenderFilter.length > 0) {
        //   const gender = participant.gender;
        //   const genderCheck =
        //     (participantsGenderFilter.includes("unknown") && gender === null) ||
        //     (gender && participantsGenderFilter.includes(gender));

        //   if (!genderCheck) {
        //     return false;
        //   }
        // }

        // searchedMultiParticipantsIDs, filter by participantIDs
        if (
          searchedMultiParticipantsIDs.length > 0 &&
          !searchedMultiParticipantsIDs.includes(participant.id.toString())
        ) {
          return false;
        }

        // If none of the filters excluded the participant, include them
        return true;
      }
    );

    setFilteredParticipants(preliminaryFiltered);
    setCurrentPage(1);
  }, [
    originalParticipants,
    participantsSearch,
    participantsTagsFilter,
    participantsStatusFilter,
    participantsUDIDFilter,
    participantsLanguagesFilter,
    participantsCountryFilter,
    errorFetchingData,
    // participantsAgeRangeFilter,
    // participantsGenderFilter,
    searchedMultiParticipantsIDs
  ]);

  const [currentParticipants, setCurrentParticipants] = useState<
    LeadsParticipant[]
  >([]);

  useEffect(() => {
    // console.log(currentParticipants, filteredParticipants);
    if (!errorFetchingData && filteredParticipants) {
      setCurrentParticipants(
        filteredParticipants.slice(
          (currentPage - 1) * participantsPerPage,
          currentPage * participantsPerPage
        )
      );
    }
  }, [currentPage, participantsPerPage, filteredParticipants]);

  const totalParticipants = !errorFetchingData
    ? filteredParticipants?.length ?? 0
    : 0;

  // const timezones = JSON.parse(localStorage.getItem("timezones") || "[]");
  const [researcherTimezone, setResearcherTimezone] = useState<Timezone | null>(
    null
  );

  useEffect(() => {
    getResearcherConfigTimezone().then((result) => {
      // console.log("result", result);
      if (result) {
        setResearcherTimezone(result);
      } else {
        setResearcherTimezone(null);
      }
    });
  }, []);

  // TODO: Get actual user timezone from backend
  const [timezones, setTimezones] = useState<Timezone[]>([]);

  // User can switch between UTC, User timezone, and participant's timezone
  const [timezone, setTimezone] = useState<string>("ResearcherTimezone");

  const handleChangeTimezone = (timezone: string) => {
    setTimezone(timezone);
  };

  // User can view participant details
  const [showModal, setShowModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState("");

  const handleViewParticipant = (participantID: string) => {
    setSelectedParticipant(participantID);
    // console.log("View participant: " + participantID);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // delay of 0.5 seconds
    setTimeout(() => {
      setSelectedParticipant("");
    }, 500);
  };

  const [showImportModal, setShowImportModal] = useState(false);
  const handleCloseImportModal = () => {
    setShowImportModal(false);
    // delay of 0.5 seconds
  };

  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const handleCloseAddParticipantModal = () => {
    setShowAddParticipantModal(false);
    // delay of 0.5 seconds
  };

  const handleChangeRows = (rows: number) => {
    setParticipantsPerPage(rows);
  };

  const [activeParticipantId, setActiveParticipantId] = useState<string | null>(
    null
  );

  const handleParticipantActionsDropdown = (participantId: string | null) => {
    // TODO: handle outside click
    // if (participantId === "outside") {
    //   // close all dropdowns
    //   // console.log("outside");
    //   setActiveParticipantId(null);
    //   return;
    // }

    if (participantId === "close") {
      // reload the page
      // window.location.reload();
      setActiveParticipantId(null);
      // refresh data
      fetchData(true);
    } else {
      if (participantId === activeParticipantId) {
        setActiveParticipantId(null);
      } else {
        setActiveParticipantId(participantId);
      }
    }
  };

  // store current state of filters
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  useEffect(() => {
    if (
      participantsSearch !== "" ||
      participantsTagsFilter.length > 0 ||
      participantsStatusFilter.length > 0 ||
      participantsUDIDFilter ||
      participantsLanguagesFilter.length > 0 ||
      participantsCountryFilter.length > 0 ||
      // participantsAgeRangeFilter.length > 0 ||
      // participantsGenderFilter.length > 0 ||
      searchedMultiParticipantsIDs.length > 0
    ) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [
    participantsSearch,
    participantsTagsFilter,
    participantsStatusFilter,
    participantsUDIDFilter,
    participantsLanguagesFilter,
    participantsCountryFilter,
    // participantsAgeRangeFilter,
    // participantsGenderFilter,
    searchedMultiParticipantsIDs
  ]);

  const handleClearFilters = () => {
    // Clear the filter state
    setParticipantsSearch("");
    setParticipantsTagsFilter([]);
    setParticipantsStatusFilter([]);
    setParticipantsUDIDFilter(false);
    setParticipantsLanguagesFilter([]);
    setParticipantsCountryFilter([]);
    // setParticipantsAgeRangeFilter([]);
    // setParticipantsGenderFilter([]);
    setSearchedMultiParticipantsIDs([]);
  };

  // bulk actions
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );

  const isParticipantPII = (participant: LeadsParticipant) => {
    const fieldsToCheck = [
      participant.first_name,
      participant.last_name,
      participant.email,
      participant.mobile_number,
      participant.country_iso,
      // participant.age,
      // participant.gender,
      participant.region
    ];
    return fieldsToCheck.every((field) => field === null);
  };

  const handleSelectParticipant = (participantId: string) => {
    // add participantId to selectedParticipants, if already present, remove it
    if (selectedParticipants.includes(participantId)) {
      setSelectedParticipants(
        selectedParticipants.filter((id) => id !== participantId)
      );
    } else {
      setSelectedParticipants([...selectedParticipants, participantId]);
    }
  };

  const handleSelectAllParticipants = () => {
    // Filter out PII participants
    const nonPIIParticipantIDs = filteredParticipants
      .filter((participant) => !isParticipantPII(participant))
      .map((p) => p.id.toString());

    // Check if the number of selected participants matches the number of non-PII participants
    if (selectedParticipants.length === nonPIIParticipantIDs.length) {
      // If all non-PII participants are selected, deselect all
      setSelectedParticipants([]);
    } else {
      // Otherwise, select all non-PII participants
      console.log(nonPIIParticipantIDs);
      setSelectedParticipants(nonPIIParticipantIDs);
    }
  };

  // clock to display the offset of the displayed data, updates every second
  useEffect(() => {
    // console.log("dateFetchedDate: ", dateFetchedDate.getTime());
    const interval = setInterval(() => {
      if (
        dateFetchedDate === null ||
        dateFetchedDate === undefined ||
        isNaN(dateFetchedDate.getTime())
      ) {
        setDisplayedDataDateOffset("Date not provided");
      } else {
        const currentLocalDate = new Date();
        const currentUTCDate = new Date(
          currentLocalDate.getTime() +
            currentLocalDate.getTimezoneOffset() * 60000
        );
        const offset = new Date(
          currentUTCDate.getTime() - dateFetchedDate.getTime()
        );
        // if offset is less than 24 hours display, else display error
        const minute = 60000;
        const hour = minute * 60;
        const maxtime = 24 * hour;
        if (offset.getTime() < 0) {
          setDisplayedDataDateOffset("Error Data is in the future");
        } else if (offset.getTime() < maxtime) {
          const offsetString = offset.toISOString().substring(11, 19);
          setDisplayedDataDateOffset(offsetString);
        } else {
          setDisplayedDataDateOffset("Error Data is over 24 hours old");
        }
        // if less than 30 seconds disable refresh button
        const disableTime = 10 * 1000;
        if (offset.getTime() < disableTime) {
          setRefreshButtonEnabled(false);
        } else {
          setRefreshButtonEnabled(true);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [dateFetchedDate]);

  const handleRefreshData = () => {
    // does a fetch of the data again
    // console.log("handleRefreshData");
    setRefreshButtonEnabled(false);
    setDisplayedDataDateOffset("00:00:00");
    // Force pull real data
    fetchData(true);
  };

  // After closing add participants modal, fetch real data
  useEffect(() => {
    // console.log(showAddParticipantModal);
    // console.log(userAdded);
    if (!showAddParticipantModal && userAdded) {
      const fetchDataInUse = async () => {
        if (!showAddParticipantModal && userAdded) {
          console.log("REDNDEDE");
          await fetchData();
        }
      };

      fetchDataInUse();
    }
  }, [showAddParticipantModal, userAdded]);

  const [participantsStatuses, setParticipantsStatuses] = useState<string[]>(
    []
  );
  const [participantsLanguages, setParticipantsLanguages] = useState<
    Language[]
  >([]);
  const [participantsCountries, setParticipantsCountries] = useState<Country[]>(
    []
  );

  const [participantsTags, setParticipantsTags] = useState<Tags[]>([]);

  useEffect(() => {
    // get all unique participants languages
    const pLanguages = [
      ...new Set(
        originalParticipants
          .map((p) => p.language_iso)
          .filter((lang): lang is string => Boolean(lang))
      )
    ].sort();
    setParticipantsLanguages(
      pLanguages.map((lang) => {
        const pLanguage = languages.find((l: Language) => l.iso_code === lang);
        if (pLanguage) {
          return pLanguage;
        }
        return { iso_code: lang, name: lang } as Language;
      })
    );
  }, [originalParticipants, languages]);

  useEffect(() => {
    // get all unique participants countries
    const pCountries = [
      ...new Set(
        originalParticipants
          .map((p) => p.country_iso)
          .filter((country): country is string => Boolean(country))
      )
    ].sort();
    setParticipantsCountries(
      pCountries.map((country) => {
        const pCountry = countries.find((c: Country) => c.iso_code === country);
        if (pCountry) {
          return pCountry;
        }
        return {
          iso_code: country,
          name: country,
          country_code: country
        } as Country;
      })
    );
  }, [originalParticipants, countries]);

  // useEffect(() => {
  //   // get all unique participants genders
  //   const pGenders = [
  //     ...new Set(
  //       originalParticipants
  //         .map((p) => p.gender?.toLowerCase())
  //         .filter((gender): gender is string => Boolean(gender?.toLowerCase()))
  //     )
  //   ].sort();
  //   // add Unknown
  //   pGenders.push("unknown");
  //   setParticipantsGenders(pGenders);
  // }, [originalParticipants]);

  function handleExportParticipants() {
    console.log("handleExportParticipants");
    exportLeads(filteredParticipants, allClients);
  }

  return (
    <div>
      {/* loading */}
      {!loadingLanguages &&
      !loadingCountries &&
      !loadingTimezones &&
      !loadingParticipants &&
      !errorFetchingData?.errorCode ? (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="participants_page" className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h1 className="table_name">Leads Table</h1>
                  </div>
                  <div className="col-12 col-md-6 d-flex justify-content-end">
                    <div className="participants_page_heading_right p-0">
                      <h5>{totalParticipants} Leads</h5>
                      <button
                        className="btn btn-primary me-2"
                        onClick={() => handleExportParticipants()}
                      >
                        <Icons.IconUpload
                          className="icon icon_white"
                          style={{
                            transform: "rotate(180deg)"
                          }}
                        />
                        Export
                      </button>
                      <button
                        className="btn btn-primary"
                        onClick={() => setShowImportModal(true)}
                      >
                        <Icons.IconUpload className="icon icon_white" />
                        Import
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-end participants_page_header_second">
                    {/* timezone buttons */}
                    <TimezoneSwitcher
                      timezone={timezone}
                      handleChangeTimezone={handleChangeTimezone}
                      researcherTimezone={
                        researcherTimezone ? researcherTimezone : null
                      }
                      tableType={"leadsTable"}
                    />
                    {/* select rows buttons */}
                    <TableRowsSelector
                      handleChangeRows={handleChangeRows}
                      rowsPerPage={participantsPerPage}
                      tableName="Leads"
                      arrayRowSelector={[10, 20, 50, 100]}
                    />
                  </div>
                </div>
                <div className="row">
                  {/* FiltersContainer */}
                  <FiltersContainer
                    key="FiltersContainer"
                    tableType="leadsTable"
                    filterApplied={filterApplied}
                    setFilterApplied={setFilterApplied}
                    handleClearFilters={handleClearFilters}
                    languages={participantsLanguages}
                    countries={participantsCountries}
                    // genders={participantsGenders}
                    // ageRanges={participantsAgeRange}
                    participantIDs={searchedMultiParticipantsIDs}
                    setSearch={setParticipantsSearch}
                    setLanguagesFilter={setParticipantsLanguagesFilter}
                    setCountryFilter={setParticipantsCountryFilter}
                    setUDIDFilter={setParticipantsUDIDFilter}
                    // setGendersFilter={setParticipantsGenderFilter}
                    // setAgeRangesFilter={setParticipantsAgeRangeFilter}
                    setParticipantIDs={setSearchedMultiParticipantsIDs}
                  />
                  <div className="col-auto">
                    {/* show if there are selected Participants */}
                    {selectedParticipants &&
                      selectedParticipants.length > 0 && (
                        <BulkActions
                          selectedParticipants={selectedParticipants}
                          tableType="leadsTable"
                          allSelected={
                            selectedParticipants.length === totalParticipants
                          }
                          allClients={allClients}
                        />
                      )}
                  </div>
                </div>
                {!originalParticipants || originalParticipants.length === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="no_results_container">
                        <h3>No participants found</h3>
                      </div>
                    </div>
                  </div>
                ) : !filteredParticipants ||
                  (filteredParticipants &&
                    filteredParticipants.length === 0) ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="no_results_container">
                        <h3>No results found</h3>
                        <p>Try adjusting your search or filters</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <LeadsTable
                    currentParticipants={currentParticipants}
                    selectedParticipants={selectedParticipants}
                    filteredParticipantsLength={filteredParticipants.length}
                    participantID={selectedParticipant}
                    allTimezones={timezones}
                    timezone={timezone}
                    countries={participantsCountries}
                    languages={participantsLanguages}
                    activeParticipantId={activeParticipantId}
                    researcherTimezone={
                      researcherTimezone ? researcherTimezone : null
                    }
                    allClients={allClients}
                    handleSelectParticipant={handleSelectParticipant}
                    handleSelectAllParticipants={handleSelectAllParticipants}
                    handleViewParticipant={handleViewParticipant}
                    handleParticipantActionsDropdown={
                      handleParticipantActionsDropdown
                    }
                  />
                )}
                <div className="row">
                  <div className="col-auto">
                    <PaginationNavigation
                      currentPage={currentPage}
                      totalResults={
                        filteredParticipants ? filteredParticipants.length : 0
                      }
                      resultsPerPage={participantsPerPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                  <div className="col-auto ms-auto">
                    {displayedDataDateOffset}
                    <button
                      id="refresh_button"
                      className="btn btn-primary ms-2"
                      onClick={() => handleRefreshData()}
                      disabled={!refreshButtonEnabled}
                    >
                      Refresh
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="participants_page" className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="error_container">
                      {loadingLanguages ||
                      loadingCountries ||
                      loadingTimezones ||
                      loadingParticipants ? (
                        <div className="d-flex justify-content-center custom_spinner_container full_page">
                          <h2
                            style={{
                              position: "absolute",
                              top: "40%"
                            }}
                          >
                            May take a few seconds to load
                          </h2>
                          <div className="spinner-border" role="status"></div>
                        </div>
                      ) : (
                        <ErrorPage
                          errorCode={"500"}
                          error={
                            errorFetchingData
                              ? errorFetchingData.errorMessage
                              : "Error fetching data"
                          }
                          debugCode={loadingErrorText}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showImportModal && (
        <ImportParticipantsModal
          closeModal={() => handleCloseImportModal()}
          shown={showImportModal}
          tableType={"leadsTable"}
        />
      )}
      {selectedParticipant !== "" && (
        <ViewLeadModal
          participantID={selectedParticipant}
          closeLeadModal={() => handleCloseModal()}
          shown={showModal}
          jsonLanguagesData={languages}
          jsonCountriesData={countries}
          jsonTimezonesData={timezones}
          tableType={"leadsTable"}
          clientID={"clientID"}
          surveyID={"surveyID"}
          studyID={"studyID"}
          allClients={allClients}
        />
      )}

      {showAddParticipantModal && (
        <AddParticipantsModal
          closeModal={() => handleCloseAddParticipantModal()}
          shown={showAddParticipantModal}
          tableType="leadsTable"
          languages={languages}
          countries={countries}
          setUserAdded={setUserAdded}
        />
      )}
    </div>
  );
};

export default DisplayLeads;
