import {
  ResearcherConfigData,
  SystemConfigData,
  fetchResearcherConfig,
  fetchSystemConfig
} from "../models/config.model";
import { ConfigPhraseCategory, Country, Language, Timezone } from "../types";

// #region setSystemConfig
export async function setSystemConfig(): Promise<boolean> {
  // set systemConfig in local storage, fetch from server
  try {
    const config: string | SystemConfigData = await fetchSystemConfig();
    if (!config || typeof config === "string") {
      console.log("Failed to fetch all Config");
      return false;
    } else {
      localStorage.setItem("systemConfig", JSON.stringify(config));
      // console.log("Config set in local storage");
      return true;
    }
  } catch {
    console.log("Failed to fetch all Config");
    return false;
  }
}
// #endregion setSystemConfig

// #region getSystemConfig
export async function getSystemConfig(): Promise<SystemConfigData | false> {
  // check if config exists in local storage and return it
  const config: string | null = localStorage.getItem("systemConfig");
  if (config) {
    try {
      return JSON.parse(config);
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
// #endregion getSystemConfig

// #region setResearcherConfig
export async function setResearcherConfig(): Promise<boolean> {
  // set systemConfig in local storage, fetch from server
  try {
    const userConfig: string | ResearcherConfigData =
      await fetchResearcherConfig();
    if (!userConfig || typeof userConfig === "string") {
      console.log("Failed to fetch all Config");
      return false;
    } else {
      localStorage.setItem("researcherConfig", JSON.stringify(userConfig));
      // console.log("Config set in local storage");
      return true;
    }
  } catch {
    console.log("Failed to fetch all Config");
    return false;
  }
}
// #endregion setResearcherConfig

// #region getResearcherConfig
export async function getResearcherConfig(): Promise<
  ResearcherConfigData | false
> {
  // check if config exists in local storage and return it
  const researcherConfig: string | null =
    localStorage.getItem("researcherConfig");
  if (researcherConfig) {
    try {
      // console.log("From local storage: ", researcherConfig);
      // console.log(
      //   "------------------------------------------------------------"
      // );
      return JSON.parse(researcherConfig);
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
// #endregion getResearcherConfig

// #region getResearcherConfigTimezone
export async function getResearcherConfigTimezone(): Promise<Timezone | false> {
  // check if config exists in local storage and return it
  const researcherConfig: string | null =
    localStorage.getItem("researcherConfig");
  if (!researcherConfig) {
    return false;
  }
  try {
    // console.log("From local storage: ", researcherConfig);
    const parsedConfig: ResearcherConfigData = JSON.parse(researcherConfig);
    const timezone: Timezone | null = await getSystemConfigTimezoneByName(
      parsedConfig.timezone_name
    );
    if (!timezone) {
      throw new Error("Timezone not found");
    }
    return timezone;
  } catch {
    return false;
  }
}
// #endregion getResearcherConfigTimezone

// #region getSystemConfigLanguages
export async function getSystemConfigLanguages(): Promise<Language[] | false> {
  // check if config exists in local storage and return languages
  const config: string | null = localStorage.getItem("systemConfig");
  if (config) {
    try {
      const parsedConfig: SystemConfigData = JSON.parse(config);
      return parsedConfig.languages;
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
// #endregion getSystemConfigLanguages

// #region getSystemConfigTimezones
export async function getSystemConfigTimezones(): Promise<Timezone[] | false> {
  // check if config exists in local storage and return timezones
  const config: string | null = localStorage.getItem("systemConfig");
  if (config) {
    try {
      const parsedConfig: SystemConfigData = JSON.parse(config);
      return parsedConfig.timezones;
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
// #endregion getSystemConfigTimezones

// #region getSystemConfigTimezoneByName
export async function getSystemConfigTimezoneByName(
  TimezoneID: string
): Promise<Timezone | null> {
  // check if config exists in local storage and return timezones
  const config: string | null = localStorage.getItem("systemConfig");
  if (config) {
    try {
      const parsedConfig: SystemConfigData = JSON.parse(config);
      const timezone = parsedConfig.timezones.find(
        (timezone) => timezone.name === TimezoneID
      );
      return timezone || null;
    } catch {
      return null;
    }
  } else {
    return null;
  }
}
// #endregion getSystemConfigTimezoneByName

// #region getSystemConfigCountries
export async function getSystemConfigCountries(): Promise<Country[] | false> {
  // check if config exists in local storage and return countries
  // console.log("getConfigCountries");
  const config: string | null = localStorage.getItem("systemConfig");
  if (config) {
    try {
      const parsedConfig: SystemConfigData = JSON.parse(config);
      return parsedConfig.countries;
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
// #endregion getSystemConfigCountries

// #region getSystemConfigPhraseCategories
export async function getSystemConfigPhraseCategories(): Promise<
  ConfigPhraseCategory[] | false
> {
  // check if config exists in local storage and return languageLibraryCategories
  const config: string | null = localStorage.getItem("systemConfig");
  if (config) {
    try {
      const parsedConfig: SystemConfigData = JSON.parse(config);
      return parsedConfig.phraseCategories;
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
// #endregion getSystemConfigPhraseCategories

// #region getSystemConfigPhraseCategoryIDByNameID
export async function getSystemConfigPhraseCategoryIDByNameID(
  name: string
): Promise<string | "notFound" | false> {
  // check if config exists in local storage and return languageLibraryCategories
  const config: string | null = localStorage.getItem("systemConfig");
  if (config) {
    try {
      const parsedConfig: SystemConfigData = JSON.parse(config);
      const phraseCategory = parsedConfig.phraseCategories.find(
        (phraseCategory) => phraseCategory.category === name
      );
      return phraseCategory?.id || "notFound";
    } catch {
      return false;
    }
  } else {
    return false;
  }
}
// #endregion getSystemConfigPhraseCategoryIDByNameID
