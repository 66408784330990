import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { Reward, RewardsImportData } from "../types";

// #region isReward
function isReward(data: any): data is Reward {
  const validID = typeof data.id === "string";
  const validUsername = typeof data.username === "string";
  const validStatus = typeof data.status === "string";
  const validPoints = typeof data.points === "number";
  const validParticipant_id = typeof data.participant_id === "string";
  const validEarnedDate = typeof data.earned_at === "string";
  const validParticipantEmail = typeof data.participant_email === "string";
  const validCountry = typeof data.country === "string";
  const validTimezone = typeof data.participant_timezone === "string";
  const validParticipantMobile = typeof data.participant_mobile === "string";
  const validStudyName =
    typeof data.study_name === "string" ||
    typeof data.study_name === "undefined";

  const AllValid =
    validID &&
    validUsername &&
    validStatus &&
    validPoints &&
    validParticipant_id &&
    validEarnedDate &&
    validParticipantEmail &&
    validCountry &&
    validTimezone &&
    validParticipantMobile &&
    validStudyName;
  if (!AllValid) {
    console.log(data);
    let errors: string[] = [];

    if (!validID) {
      errors.push("Invalid ID");
    }
    if (!validUsername) {
      errors.push("Invalid username");
    }
    if (!validStatus) {
      errors.push("Invalid status");
    }
    if (!validPoints) {
      errors.push("Invalid points");
    }
    if (!validParticipant_id) {
      errors.push("Invalid participant_id");
    }
    if (!validEarnedDate) {
      errors.push("Invalid earned_at");
    }
    if (!validParticipantEmail) {
      errors.push("Invalid participant_email");
    }
    if (!validCountry) {
      errors.push("Invalid country");
    }
    if (!validTimezone) {
      errors.push("Invalid timezone");
    }
    if (!validParticipantMobile) {
      errors.push("Invalid participant_mobile");
    }
    if (!validStudyName) {
      errors.push("Invalid study_name");
    }

    const displayError = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      title: "Invalid rewards data",
      html: displayError + errors.join("<br />"),
      icon: "error",
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return AllValid;
}

// #endregion isReward

// #region fetchRewardsData
export async function fetchRewardsData(
  clientID: string,
  studyID: string
): Promise<Reward[] | string> {
  try {
    const response = await apiFetcher("/rewards/get/study", "POST", {
      body: {
        clientID,
        studyID
      }
    });
    console.log(response.data);

    if (response && response.status === 200 && response?.data !== null) {
      if (Array.isArray(response.data)) {
        if (response.data.some((item) => !isReward(item))) {
          return "Invalid data types";
        }
        return response.data;
      } else {
        return "Invalid rewards data structure";
      }
    } else {
      switch (response.status) {
        case 500:
          return "Internal server error";
        case 404:
          return "Rewards not found";
        case 403:
          return "Unauthorized, permission";
        default:
          return `Failed to sending request, status code: ${response.status}`;
      }
    }
  } catch (error) {
    return "Failed to sending request";
  }
}
// #endregion fetchRewardsData

// #region fetchAllRewardsData
export async function fetchAllRewardsData(
  clientID: string
): Promise<Reward[] | string> {
  try {
    const response = await apiFetcher("/rewards/get/all", "POST", {
      body: {
        clientID
      }
    });

    if (response && response.status === 200 && response?.data !== null) {
      if (Array.isArray(response.data)) {
        if (response.data.some((item) => !isReward(item))) {
          return "Invalid data types";
        }
        console.log(response.data);
        return response.data;
      } else {
        return "Invalid rewards data structure";
      }
    } else {
      console.log(response.status);
      switch (response.status) {
        case 500:
          return "Internal server error";
        case 404:
          return "Rewards not found";
        case 403:
          return "Unauthorized, permission";
        default:
          return `Failed to sending request, status code: ${response.status}`;
      }
    }
  } catch (error) {
    return "Failed to sending request";
  }
}
// #endregion fetchAllRewardsData

// #region fetchAllIDs
export async function fetchAllIDs(
  source: string,
  clientID: string,
  studyID?: string
): Promise<string[] | string> {
  try {
    let route;
    let sendBody = {};
    if (source === "client") {
      route = "/participants/get/ids";
      sendBody = {
        clientID
      };
    } else {
      route = "/participants/get/study/ids";
      sendBody = {
        clientID,
        studyID
      };
    }
    const response = await apiFetcher(route, "POST", {
      body: sendBody
    });

    if (response && response.status === 200 && response?.data !== null) {
      if (Array.isArray(response.data)) {
        if (
          response.data.every(
            (item) =>
              (typeof item === "object" &&
                typeof item.participant_id === "string") ||
              typeof item.id === "string"
          )
        ) {
          //Returnthe data only in array
          let arrID: string[] = response.data.map((item) =>
            source === "client" ? item?.id : item?.participant_id
          );

          return arrID;
        } else {
          return "Invalid data types";
        }
      } else {
        return "Invalid rewards data structure";
      }
    } else {
      switch (response.status) {
        case 500:
          return "Internal server error";
        case 404:
          return "Rewards not found";
        case 403:
          return "Unauthorized, permission";
        default:
          return `Failed to sending request, status code: ${response.status}`;
      }
    }
  } catch (error) {
    return "Failed to sending request";
  }
}
// #endregion fetchAllIDs

// #region bulkRewardImport
export async function bulkRewardImport(
  rewardImports: RewardsImportData,
  clientID: string,
  studyID: string,
  source: string
): Promise<string> {
  try {
    //Check if we have a work running
    const response = await apiFetcher<any>("/rewards/import", "POST", {
      body: {
        clientID,
        studyID,
        source,
        rewardImports
      }
    });

    if (response && response.status === 200 && response?.data?.jobID !== null) {
      localStorage.setItem("workerID", response.data.jobID);
      return "success";
    } else {
      switch (response.status) {
        case 500:
          return "Internal server error";
        case 404:
          return "Rewards not found";
        case 403:
          return "Unauthorized, permission";
        default:
          return `Failed to sending request, status code: ${response.status}`;
      }
    }
  } catch (error) {
    return "Failed to sending request";
  }
}
// #endregion bulkRewardImport
