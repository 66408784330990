import React from "react";
import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldNotification from "../../Fields/fieldNotification.component";
import FieldNumber from "../../Fields/fieldNumber.component";
import FieldTextArea from "../../Fields/fieldTextArea.component";
import FieldTime from "../../Fields/fieldTime.component";
import { AddSurveyWizardStepNotificationsProps } from "../addSurveyWizard.component";
import AddSurveyWizardFooter from "../addSurveyWizardFooter.component";

interface StepNotificationsProps
  extends AddSurveyWizardStepNotificationsProps {}

const StepNotifications: React.FC<StepNotificationsProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  surveyType,
  handleWizardBack,
  handleInputChange,
  handleWizardSubmit,
  // Fields
  welcomeNotification,
  dailyReminderNotification,
  dailyReminderTime,
  nonComplianceNotification,
  nonComplianceTime,
  pageValid,
  inputErrors,
  welcomeNotificationsData,
  dailyReminderNotificationsData,
  nonComplianceNotificationsData,
  newWelcomeNotification,
  newDailyReminderNotification,
  newNonComplianceNotification,
  singleNonComplianceNotificationStartDelay,
  singleNonComplianceNotificationInBetweenDelay,
  surveyName,
  includeNotifications
}) => {
  return (
    <div className="card card-default add_survey_form_page p-4">
      <h3 className="form_header">Survey Notifications: {surveyName}</h3>
      <div className="add_survey_form_page_inputs">
        <FieldCheckbox
          label="Send out notifications for this survey?"
          tooltip="Toggle setting to either enable or disable push notifications for this survey. Notifications can be crucial for participant engagement and compliance"
          tooltipPosition="bottom"
          inputName="includeNotifications"
          inputValue={includeNotifications}
          onInputChange={handleInputChange}
          error={
            inputErrors?.includeNotifications
              ? inputErrors.includeNotifications
              : null
          }
        />
        {includeNotifications && (
          <>
            <FieldNotification
              label="Welcome Notification"
              tooltip={
                surveyType === "diary"
                  ? "The push notification that each participant will receive when the diary becomes active. Typically to let them know the diary is now available to complete, and how often they should complete it."
                  : "The push notification that each participant will receive when this survey becomes active. This is typically to let participants know that the survey is available & must be completed prior to the next step."
              }
              inputName="welcomeNotification"
              inputValue={welcomeNotification}
              inputOptions={welcomeNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.welcomeNotification
                  ? inputErrors.welcomeNotification
                  : null
              }
            />
            {/* if welcomeNotification = new */}

            {welcomeNotification === "new" && (
              <>
                <FieldTextArea
                  label="New Welcome Message"
                  inputName="newWelcomeNotification"
                  inputValue={
                    newWelcomeNotification ? newWelcomeNotification : ""
                  }
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.newWelcomeNotification
                      ? inputErrors.newWelcomeNotification
                      : null
                  }
                />
              </>
            )}

            {surveyType === "diary" && (
              <>
                <FieldNotification
                  label="Daily Reminder Notification"
                  tooltip="A daily push notification reminder, to be sent at a selected time - typically to remind participants to complete their diary / remember to consider their diary that day."
                  inputName="dailyReminderNotification"
                  inputValue={dailyReminderNotification}
                  inputOptions={dailyReminderNotificationsData}
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.dailyReminderNotification
                      ? inputErrors.dailyReminderNotification
                      : null
                  }
                />

                {
                  // if dailyReminderNotification = new
                  dailyReminderNotification === "new" && (
                    <>
                      <FieldTextArea
                        label="New Daily Reminder Message"
                        inputName="newDailyReminderNotification"
                        inputValue={
                          newDailyReminderNotification
                            ? newDailyReminderNotification
                            : ""
                        }
                        onInputChange={handleInputChange}
                        error={
                          inputErrors?.newDailyReminderNotification
                            ? inputErrors.newDailyReminderNotification
                            : null
                        }
                      />
                    </>
                  )
                }
              </>
            )}
            {surveyType === "diary" && (
              <FieldTime
                label="Daily Reminder Time"
                tooltip="The time that the daily reminder should be sent."
                inputName="dailyReminderTime"
                inputValue={dailyReminderTime ? dailyReminderTime : ""}
                onInputChange={handleInputChange}
                subLabel="User local time"
                error={
                  inputErrors?.dailyReminderTime
                    ? inputErrors.dailyReminderTime
                    : null
                }
              />
            )}

            <FieldNotification
              label="Non-Compliance Notification"
              tooltip={
                surveyType === "diary"
                  ? "A daily push notification reminder, sent daily at a specified time, if the participant doesn't complete the minimum number of completes for that day."
                  : "A push notification sent at a specified time if the participant hasn't yet completed this survey."
              }
              inputName="nonComplianceNotification"
              inputValue={nonComplianceNotification}
              inputOptions={nonComplianceNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.nonComplianceNotification
                  ? inputErrors.nonComplianceNotification
                  : null
              }
            />
            {
              // if nonComplianceNotification = new
              nonComplianceNotification === "new" && (
                <>
                  <FieldTextArea
                    label="New Non-Compliance Message"
                    inputName="newNonComplianceNotification"
                    inputValue={
                      newNonComplianceNotification
                        ? newNonComplianceNotification
                        : ""
                    }
                    onInputChange={handleInputChange}
                    error={
                      inputErrors?.newNonComplianceNotification
                        ? inputErrors.newNonComplianceNotification
                        : null
                    }
                  />
                </>
              )
            }

            <FieldTime
              label="Non-Compliance Time"
              tooltip="The time that the non-compliance reminder should be sent."
              inputName="nonComplianceTime"
              inputValue={nonComplianceTime ? nonComplianceTime : ""}
              onInputChange={handleInputChange}
              subLabel="User local time"
              error={
                inputErrors?.nonComplianceTime
                  ? inputErrors.nonComplianceTime
                  : null
              }
            />

            {surveyType === "single" && (
              <>
                <FieldNumber
                  label="Non-Compliance Notification Start Delay (Days)"
                  tooltip="If you want to give participants some time to complete the survey before reminding them, you can set a number of days that they will not receive the non-compliance reminder."
                  inputName={"singleNonComplianceNotificationStartDelay"}
                  inputValue={singleNonComplianceNotificationStartDelay}
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.singleNonComplianceNotificationStartDelay
                      ? inputErrors.singleNonComplianceNotificationStartDelay
                      : null
                  }
                />
                <FieldNumber
                  label="Non-Compliance Notification Interval Delay (Days)"
                  tooltip="If not set, participants will be reminded daily if they have not completed this survey. If you want to give them some additional time, you can set it to send a notification every few days - for instance if you set this to 1, they will be reminded every second day, etc. (1 day interval in between reminders)."
                  inputName={"singleNonComplianceNotificationInBetweenDelay"}
                  inputValue={singleNonComplianceNotificationInBetweenDelay}
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.singleNonComplianceNotificationInBetweenDelay
                      ? inputErrors.singleNonComplianceNotificationInBetweenDelay
                      : null
                  }
                />
              </>
            )}
          </>
        )}

        <AddSurveyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          handleWizardSubmit={handleWizardSubmit}
          pageValid={pageValid}
        />
      </div>
    </div>
  );
};

export default StepNotifications;
