import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  CreateParticipantSingle,
  FetchResponseError,
  HistoryAPIResponse,
  Participant,
  SurveyCompletion
} from "../types";
import { convertTime, formatDate } from "../utilities/utils";
export interface ParticipantsData {
  utcLastUpdate: string;
  participants: Participant[];
}

// #region type validators
// #region isParticipant
function isParticipant(obj: any, clientID: string): obj is Participant {
  // console.log("Checking if object is a Participant");
  // console.log(obj);
  const validID = typeof obj.id === "string";
  const validName = typeof obj.participant_name === "string";
  const validSurname = typeof obj.participant_surname === "string";
  const validEmail = typeof obj.participant_email === "string";
  const validMobileNumber =
    typeof obj.participant_mobile_number === "string" ||
    obj.participant_mobile_number === null ||
    obj.participant_mobile_number === undefined;
  const validMobileCode =
    typeof obj.participant_mobile_code === "string" ||
    obj.participant_mobile_code === null ||
    obj.participant_mobile_code === undefined;
  const validCountryISO =
    typeof obj.participant_country_iso === "string" ||
    obj.participant_country_iso === null ||
    obj.participant_country_iso === undefined;
  const validLangISO =
    typeof obj.participant_lang_iso === "string" ||
    obj.participant_lang_iso === null ||
    obj.participant_lang_iso === undefined;
  const validFlagged =
    typeof obj.participant_flagged === "number" ||
    obj.participant_flagged === null ||
    obj.participant_flagged === undefined;
  const validLastLogin =
    typeof obj.participant_last_login === "string" ||
    obj.participant_last_login === null ||
    obj.participant_last_login === undefined;
  const validTags =
    (Array.isArray(obj.participant_tags) &&
      obj.participant_tags.every((tag: any) => typeof tag === "string")) ||
    obj.participant_tags === null;
  const validAppCode =
    typeof obj.participant_app_code === "string" ||
    obj.participant_app_code === null ||
    obj.participant_app_code === undefined;
  const validAppStatus =
    typeof obj.participant_app_status === "string" ||
    obj.participant_app_status === null ||
    obj.participant_app_status === undefined;
  const validTimezone =
    typeof obj.participant_timezone === "string" ||
    obj.participant_timezone === null ||
    obj.participant_timezone === undefined;
  const validScore =
    typeof obj.participant_score === "number" ||
    obj.participant_score === null ||
    obj.participant_score === undefined;
  const validCheatingScore =
    typeof obj.participant_cheating_score === "number" ||
    obj.participant_cheating_score === null ||
    obj.participant_cheating_score === undefined;
  const validUID =
    typeof obj.participant_uid === "string" ||
    obj.participant_uid === null ||
    obj.participant_uid === undefined;
  const validRefCode =
    typeof obj.participant_ref_code === "string" ||
    obj.participant_ref_code === null ||
    obj.participant_ref_code === undefined;
  const validStatus =
    typeof obj.participant_status === "string" ||
    obj.participant_status === null ||
    obj.participant_status === undefined;

  const validStudyID =
    typeof obj.study_id === "number" ||
    obj.study_id === null ||
    obj.study_id === undefined;
  const validStudyDays =
    typeof obj.study_days === "number" ||
    obj.study_days === null ||
    obj.study_days === undefined;
  const validBucket =
    typeof obj.bucket === "string" ||
    obj.bucket === null ||
    obj.bucket === undefined;
  const validStudyStatus =
    typeof obj.study_status === "string" ||
    obj.study_status === null ||
    obj.study_status === undefined;
  const validStudyJoinedDate =
    typeof obj.study_joined_date === "string" ||
    obj.study_joined_date === null ||
    obj.study_joined_date === undefined;
  const validGSIAnswers =
    obj.gsi_answers === null ||
    obj.gsi_answers === undefined ||
    Array.isArray(obj.gsi_answers) ||
    (Array.isArray(obj.gsi_answers) &&
      obj.gsi_answers.every(
        (answer: any) =>
          typeof answer.time === "string" &&
          typeof answer.value === "string" &&
          typeof answer.variable_name === "string"
      ));
  const validSurveyID =
    typeof obj.survey_id === "number" ||
    obj.survey_id === null ||
    obj.survey_id === undefined;
  const validSurveyDays =
    typeof obj.survey_days === "number" ||
    obj.survey_days === null ||
    obj.survey_days === undefined;
  const validSurveyStatus =
    typeof obj.survey_status === "string" ||
    obj.survey_status === null ||
    obj.survey_status === undefined;
  const validSurveyCompletes =
    typeof obj.completes === "string" ||
    obj.completes === null ||
    obj.completes === undefined;
  const validSurveyJoinedDate =
    typeof obj.survey_joined_date === "string" ||
    obj.survey_joined_date === null ||
    obj.survey_joined_date === undefined;

  const validWSCVariables =
    (Array.isArray(obj.wsc_variables) &&
      obj.wsc_variables.every(
        (variable: any) =>
          typeof variable.var_name === "string" &&
          typeof variable.var_value === "string"
      )) ||
    obj.wsc_variables === undefined ||
    obj.wsc_variables === null;

  const validParticipantBuckets =
    obj.participant_buckets === null ||
    obj.participant_buckets === undefined ||
    (Array.isArray(obj.participant_buckets) &&
      obj.participant_buckets.every(
        (bucket: any) =>
          typeof bucket.name === "string" &&
          typeof bucket.bucket_id === "string" &&
          (typeof bucket.survey_id === "string" ||
            bucket.survey_id === null ||
            bucket.survey_id === undefined) &&
          typeof bucket.compliance_status === "string"
      ));

  const AllValid: boolean =
    validID &&
    validName &&
    validSurname &&
    validEmail &&
    validMobileNumber &&
    validMobileCode &&
    validCountryISO &&
    validLangISO &&
    validFlagged &&
    validLastLogin &&
    validTags &&
    validAppCode &&
    validAppStatus &&
    validTimezone &&
    validScore &&
    validCheatingScore &&
    validUID &&
    validRefCode &&
    validStatus &&
    validStudyID &&
    validStudyDays &&
    validBucket &&
    validStudyStatus &&
    validStudyJoinedDate &&
    validGSIAnswers &&
    validSurveyID &&
    validSurveyDays &&
    validSurveyStatus &&
    validSurveyCompletes &&
    validSurveyJoinedDate &&
    validWSCVariables &&
    validParticipantBuckets;
  if (!AllValid) {
    console.log("---isParticipant---");
    console.log(obj);
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Participant`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("participant_name")) {
        errors.push("missing participant_name");
      } else {
        errors.push(`Invalid type for participant_name`);
      }
    }

    if (!validSurname) {
      if (!obj.hasOwnProperty("participant_surname")) {
        errors.push("missing participant_surname");
      } else {
        errors.push(`Invalid type for participant_surname`);
      }
    }

    if (!validEmail) {
      if (!obj.hasOwnProperty("participant_email")) {
        errors.push("missing participant_email");
      } else {
        errors.push(`Invalid type for participant_email`);
      }
    }

    if (!validMobileNumber) {
      if (!obj.hasOwnProperty("participant_mobile_number")) {
        errors.push("missing participant_mobile_number");
      } else {
        errors.push(`Invalid type for participant_mobile_number`);
      }
    }

    if (!validMobileCode) {
      if (!obj.hasOwnProperty("participant_mobile_code")) {
        errors.push("missing participant_mobile_code");
      } else {
        errors.push(`Invalid type for participant_mobile_code`);
      }
    }

    if (!validCountryISO) {
      if (!obj.hasOwnProperty("participant_country_iso")) {
        errors.push("missing participant_country_iso");
      } else {
        errors.push(`Invalid type for participant_country_iso`);
      }
    }

    if (!validLangISO) {
      if (!obj.hasOwnProperty("participant_lang_iso")) {
        errors.push("missing participant_lang_iso");
      } else {
        errors.push(`Invalid type for participant_lang_iso`);
      }
    }

    if (!validFlagged) {
      if (!obj.hasOwnProperty("participant_flagged")) {
        errors.push("missing participant_flagged");
      } else {
        errors.push(`Invalid type for participant_flagged`);
      }
    }

    if (!validLastLogin) {
      if (!obj.hasOwnProperty("participant_last_login")) {
        errors.push("missing participant_last_login");
      } else {
        errors.push(`Invalid type for participant_last_login`);
      }
    }

    if (!validTags) {
      if (!obj.hasOwnProperty("participant_tags")) {
        errors.push("missing participant_tags");
      } else {
        errors.push(`Invalid type for participant_tags`);
      }
    }

    if (!validAppCode) {
      if (!obj.hasOwnProperty("participant_app_code")) {
        errors.push("missing participant_app_code");
      } else {
        errors.push(`Invalid type for participant_app_code`);
      }
    }

    if (!validAppStatus) {
      if (!obj.hasOwnProperty("participant_app_status")) {
        errors.push("missing participant_app_status");
      } else {
        errors.push(`Invalid type for participant_app_status`);
      }
    }

    if (!validTimezone) {
      if (!obj.hasOwnProperty("participant_timezone")) {
        errors.push("missing participant_timezone");
      } else {
        errors.push(`Invalid type for participant_timezone`);
      }
    }

    if (!validScore) {
      if (!obj.hasOwnProperty("participant_score")) {
        errors.push("missing participant_score");
      } else {
        errors.push(`Invalid type for participant_score`);
      }
    }

    if (!validCheatingScore) {
      if (!obj.hasOwnProperty("participant_cheating_score")) {
        errors.push("missing participant_cheating_score");
      } else {
        errors.push(`Invalid type for participant_cheating_score`);
      }
    }

    if (!validUID) {
      if (!obj.hasOwnProperty("participant_uid")) {
        errors.push("missing participant_uid");
      } else {
        errors.push(`Invalid type for participant_uid`);
      }
    }

    if (!validRefCode) {
      if (!obj.hasOwnProperty("participant_ref_code")) {
        errors.push("missing participant_ref_code");
      } else {
        errors.push(`Invalid type for participant_ref_code`);
      }
    }

    if (!validStatus) {
      if (!obj.hasOwnProperty("participant_status")) {
        errors.push("missing participant_status");
      } else {
        errors.push(`Invalid type for participant_status`);
      }
    }

    if (!validStudyID) {
      if (!obj.hasOwnProperty("study_id")) {
        errors.push("missing study_id");
      } else {
        errors.push(`Invalid type for study_id`);
      }
    }

    if (!validStudyDays) {
      if (!obj.hasOwnProperty("study_days")) {
        errors.push("missing study_days");
      } else {
        errors.push(`Invalid type for study_days`);
      }
    }

    if (!validBucket) {
      if (!obj.hasOwnProperty("bucket")) {
        errors.push("missing bucket");
      } else {
        errors.push(`Invalid type for bucket`);
      }
    }

    if (!validStudyStatus) {
      if (!obj.hasOwnProperty("study_status")) {
        errors.push("missing study_status");
      } else {
        errors.push(`Invalid type for study_status`);
      }
    }

    if (!validGSIAnswers) {
      if (!obj.hasOwnProperty("gsi_answers")) {
        errors.push("Missing gsi_answers");
      } else {
        errors.push("Invalid type for gsi_answers");
      }
    }

    if (!validStudyJoinedDate) {
      if (!obj.hasOwnProperty("study_joined_date")) {
        errors.push("missing study_joined_date");
      } else {
        errors.push(`Invalid type for study_joined_date`);
      }
    }

    if (!validSurveyID) {
      if (!obj.hasOwnProperty("survey_id")) {
        errors.push("missing survey_id");
      } else {
        errors.push(`Invalid type for survey_id`);
      }
    }

    if (!validSurveyDays) {
      if (!obj.hasOwnProperty("survey_days")) {
        errors.push("missing survey_days");
      } else {
        errors.push(`Invalid type for survey_days`);
      }
    }

    if (!validSurveyStatus) {
      if (!obj.hasOwnProperty("survey_status")) {
        errors.push("missing survey_status");
      } else {
        errors.push(`Invalid type for survey_status`);
      }
    }

    if (!validSurveyCompletes) {
      if (!obj.hasOwnProperty("completes")) {
        errors.push("missing completes");
      } else {
        errors.push(`Invalid type for completes`);
      }
    }

    if (!validSurveyJoinedDate) {
      if (!obj.hasOwnProperty("survey_joined_date")) {
        errors.push("missing survey_joined_date");
      } else {
        errors.push(`Invalid type for survey_joined_date`);
      }
    }

    if (!validWSCVariables) {
      if (!obj.hasOwnProperty("wsc_variables")) {
        errors.push("missing wsc_variables");
      } else {
        errors.push(`Invalid type for wsc_variables`);
      }
    }

    if (!validParticipantBuckets) {
      if (!obj.hasOwnProperty("participant_buckets")) {
        errors.push("missing participant_buckets");
      } else {
        errors.push(`Invalid type for participant_buckets`);
      }
    }

    console.log(`Invalid participantID: ${obj.id}`);
    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `ParticipantID: ${obj.id}<br>`;
    displayMessage += `in ClientID: ${clientID}<br><br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Participant`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });

    console.log("---isParticipant END---");
  }
  return AllValid;
}
// #endregion isParticipant

// #region isParticipantDATA
function isParticipantDATA(
  obj: any,
  clientID: string
): obj is ParticipantsData {
  // console.log("Checking if object is a Participant");
  // console.log(obj, "obj");
  // console.log(obj.utcLastUpdate, "obj.utcLastUpdate");
  const validUTC = typeof obj.utcLastUpdate === "string";
  const validParticipants =
    (Array.isArray(obj.participants) &&
      obj.participants.every((participant: any) =>
        isParticipant(participant, clientID)
      )) ||
    obj.participants === 0;
  const AllValid: boolean = validUTC && validParticipants;
  if (!AllValid) {
    let errors: string[] = [];

    if (!validUTC) {
      if (!obj.hasOwnProperty("utcLastUpdate")) {
        errors.push("missing utcLastUpdate");
      }
    }

    if (!validParticipants) {
      if (!obj.hasOwnProperty("participants")) {
        errors.push("missing participants");
      }
    }

    if (errors && errors.length > 0) {
      // only show error if missing utcLastUpdate or participants
      let displayMessage = `<strong>Please contact support</strong><br><br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid ParticipantsData`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  // console.log(validUTC, "validUTC");
  // console.log(validParticipants, "validParticipants");
  // console.log(AllValid, "AllValid");

  return AllValid;
}
// #endregion isParticipantDATA

// #region isSurveyCompletion
function isSurveyCompletion(obj: any): obj is SurveyCompletion {
  const validDate = typeof obj.date === "string";
  const validParticipants =
    Array.isArray(obj.participants) &&
    obj.participants.every(
      (participant: any) =>
        typeof participant.participant_id === "string" &&
        typeof participant.completes === "number"
    );
  return validDate && validParticipants;
}
// #endregion isSurveyCompletion

// #region isValidApiResponse
function isValidApiResponse(data: HistoryAPIResponse): boolean {
  // Check for the participant section
  if (
    data.participant &&
    data.participant.some(
      (participant: any) =>
        typeof participant.category !== "string" ||
        typeof participant.eventAt !== "string"
    )
  ) {
    return false;
  }

  // Check for the buckets section
  if (
    data.buckets &&
    data.buckets.some(
      (bucket: any) =>
        typeof bucket.bucketID !== "string" ||
        typeof bucket.studyName !== "string" ||
        typeof bucket.surveyName !== "string" ||
        typeof bucket.bucketName !== "string" ||
        typeof bucket.addedAt !== "string"
    )
  ) {
    return false;
  }

  // Check for the completes section
  if (
    data.completes &&
    data.completes.some(
      (complete: any) =>
        typeof complete.studyID !== "string" ||
        typeof complete.surveyName !== "string" ||
        typeof complete.actionAt !== "string"
    )
  ) {
    return false;
  }

  // Check for the rewards section
  if (
    data.rewards &&
    data.rewards.some(
      (reward: any) =>
        typeof reward.category !== "string" ||
        typeof reward.points !== "number" ||
        typeof reward.earnedAt !== "string" ||
        typeof reward.eventAt !== "string"
    )
  ) {
    return false;
  }

  // Check for the tags section
  if (
    data.tags &&
    data.tags.some(
      (tag: any) =>
        typeof tag.tagName !== "string" || typeof tag.addedAt !== "string"
    )
  ) {
    return false;
  }

  // Check for the automations section
  if (
    data.automations &&
    data.automations.some(
      (automation: any) =>
        typeof automation.automationID !== "string" ||
        typeof automation.automationName !== "string" ||
        typeof automation.actionAt !== "string" ||
        typeof automation.studyName !== "string" ||
        typeof automation.status !== "string"
    )
  ) {
    return false;
  }

  // Check for the messages section
  if (
    data.messages &&
    data.messages.some(
      (message: any) =>
        typeof message.type !== "string" ||
        typeof message.studyName !== "string" ||
        typeof message.status !== "string" ||
        typeof message.actionAt !== "string" ||
        typeof message.createdAt !== "string"
    )
  ) {
    return false;
  }

  if (
    data.channels &&
    data.channels.some(
      (channel: any) =>
        typeof channel.studyName !== "string" ||
        typeof channel.channelID !== "string" ||
        typeof channel.channelName !== "string" ||
        typeof channel.addedAt !== "string"
    )
  ) {
    return false;
  }

  if (
    data.channelMessages &&
    data.channelMessages.some(
      (channelMessage: any) =>
        typeof channelMessage.channelName !== "string" ||
        typeof channelMessage.studyName !== "string" ||
        typeof channelMessage.sentAt !== "string"
    )
  ) {
    return false;
  }

  return true; // If all checks pass
}
// #endregion isValidApiResponse

// #endregion type validators

// #region fetchAllParticipantsByID
// A post request to fetch all the participants
export async function fetchAllParticipantsByID(
  type: string,
  clientID: string,
  forceNewData?: boolean,
  studyID?: string,
  surveyID?: string,
  notIncluded?: boolean
): Promise<ParticipantsData | FetchResponseError> {
  // console.log("Fetching all participants");
  console.log("TYPE", type);
  // console.log("CLIENT ID", clientID);
  // console.log("FORCE NEW DATA", forceNewData);
  // console.log("TYPE ID", typeID);

  let responseType: string;
  let apiRoute: string;
  // console.log("type: ", type);

  let body = {};
  body = {
    clientID: clientID ? clientID : null,
    studyID: studyID ? studyID : null,
    surveyID: surveyID ? surveyID : null,
    notIncluded: notIncluded ? notIncluded : false
  };

  if (forceNewData) {
    body = {
      ...body,
      forceRefresh: forceNewData
    };
  }
  console.log("type", type);
  switch (type) {
    case "ClientParticipants":
      responseType = "client";
      apiRoute = "/participants/get/all";
      if (!clientID) {
        console.error("Missing clientID");
        return {
          errorCode: "400",
          errorMessage: "Missing clientID"
        } as FetchResponseError;
      }

      break;
    case "StudyParticipants":
      // console.log("HIT", "StudyParticipants");
      responseType = "study";
      apiRoute = "/participants/get/study";

      if (!clientID) {
        console.error("Missing clientID");
        return {
          errorCode: "400",
          errorMessage: "Missing clientID"
        } as FetchResponseError;
      }

      if (!studyID) {
        console.error("Missing studyID");
        return {
          errorCode: "400",
          errorMessage: "Missing studyID"
        } as FetchResponseError;
      }

      break;
    case "SurveyParticipants":
      // console.log("HIT", "SurveyParticipants");
      responseType = "survey";
      apiRoute = "/participants/get/survey";

      if (!clientID) {
        console.error("Missing clientID");
        return {
          errorCode: "400",
          errorMessage: "Missing clientID"
        } as FetchResponseError;
      }

      if (!studyID) {
        console.error("Missing studyID");
        return {
          errorCode: "400",
          errorMessage: "Missing studyID"
        } as FetchResponseError;
      }

      if (!surveyID) {
        console.error("Missing surveyID");
        return {
          errorCode: "400",
          errorMessage: "Missing surveyID"
        } as FetchResponseError;
      }

      break;
    case "DiaryParticipants":
      responseType = "diary";
      apiRoute = "/participants/get/survey";
      if (!clientID) {
        console.error("Missing clientID");
        return {
          errorCode: "400",
          errorMessage: "Missing clientID"
        } as FetchResponseError;
      }

      if (!studyID) {
        console.error("Missing studyID");
        return {
          errorCode: "400",
          errorMessage: "Missing studyID"
        } as FetchResponseError;
      }

      if (!surveyID) {
        console.error("Missing surveyID");
        return {
          errorCode: "400",
          errorMessage: "Missing surveyID"
        } as FetchResponseError;
      }

      break;
    default:
      console.log("Invalid table type provided: ", type);
      responseType = "error";
      apiRoute = "error";
      body = {};
      break;
  }

  if (responseType !== "error") {
    console.log("apiRoute", apiRoute);
    console.log("clientID", clientID);
    console.log("surveyID", surveyID);
    console.log("studyID", studyID);
    console.log("body", body);
    // force: forceNewData ? forceNewData : false,
    try {
      const response = await apiFetcher<any>(apiRoute, "POST", {
        body: body
      });

      // console.log("response", response);

      if (response.status === 200 && response.data !== null) {
        // console.log(response.data);

        // THERE ARE PARTICIPANTS
        if (isParticipantDATA(response.data, clientID ? clientID : "")) {
          return response.data as ParticipantsData;
        } else {
          console.log("Invalid participant data received");
          return {
            errorCode: response.status.toString(),
            errorMessage: "Invalid participant data received"
          } as FetchResponseError;
        }
        // NO PARTICIPANTS
      } else if (response.status === 404) {
        console.log("No participants found");
        return {
          utcLastUpdate: "",
          participants: []
        };
      } else {
        console.log("Failed to fetch all participants");
        return {
          errorCode: "500",
          errorMessage: "Failed to fetch all participants"
        } as FetchResponseError;
      }
    } catch (error) {
      console.error("An error occurred while fetching participants:", error);
      return {
        errorCode: "500",
        errorMessage: "An error occurred while fetching participants"
      } as FetchResponseError;
    }
  } else {
    console.error("Invalid type provided");
    throw new Error("Invalid type provided");
  }
}
// #endregion fetchAllParticipantsByID

// #region fetchSingleParticipantByID
export async function fetchSingleParticipantByID(
  clientID: string,
  participantID: string
): Promise<Participant | string> {
  const body = {
    clientID: clientID,
    participantID: participantID
  };
  console.log("body", body);
  try {
    const response = await apiFetcher<any>("/participants/get/id", "POST", {
      body: body
    });

    // console.log("response", response);
    console.log("response", response.data);

    if (response.status === 200 && response.data !== null) {
      if (isParticipant(response.data, clientID)) {
        // console.log(
        //   "VALID participant data received"
        // );
        const participant = response.data as Participant;
        return participant;
      } else {
        console.log("Invalid participant data received");
        return "Invalid participant data received";
      }
    } else {
      console.log("response.status", response.status);
      console.log("Failed to fetch participant");
      return "Failed to fetch participant";
    }
  } catch (error) {
    console.log("HBVLJHBVLJHBVLJIHBLKJBLKJ");
    console.error("An error occurred while fetching participant:", error);
    return "An error occurred while fetching participant";
  }
}
// #endregion fetchSingleParticipantByID

// #region createParticipantImport
export async function createParticipantImport(
  participantsToSend: CreateParticipantSingle[],
  type: string,
  clientID: string,
  studyID?: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  if (!clientID || clientID === "") {
    console.log("Missing Client ID");
    return {
      rStatus: "error",
      rMessage: "Missing Client ID"
    };
  }

  if (!participantsToSend || participantsToSend.length < 1) {
    return {
      rStatus: "error",
      rMessage: "Missing Participants"
    };
  }

  if (!type || type === "") {
    return {
      rStatus: "error",
      rMessage: "Missing Type"
    };
  }

  if (type === "study" && (!studyID || studyID === "")) {
    return {
      rStatus: "error",
      rMessage: "Missing Study ID"
    };
  }

  let body: any;
  let route: string | null = null;
  console.log("type", type);

  if (type === "client") {
    route = "/participants/create";
    body = {
      clientID: clientID,
      participants: participantsToSend
    };
  } else if (type === "study") {
    route = "/leads/study/add";
    body = {
      clientID: clientID,
      studyID: studyID,
      participants: participantsToSend
    };
  } else {
    return {
      rStatus: "error",
      rMessage: "Invalid Type"
    };
  }

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: body
    });
    // console.log(response.status);
    // console.log(response.data);

    if (response.status === 200) {
      if (
        !response.data.jobID ||
        response.data.jobID === null ||
        response.data.jobID === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // console.log("response.data.id", response.data.jobID);
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "All participants added successfully to worker"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Server error"
  };
}
// #endregion createParticipantImport

// #region sendSingleParticipantActions
export async function sendSingleParticipantActions(
  participantsAction: string,
  participantID: string,
  level: string,
  clientID: string,
  studyID?: string,
  surveyID?: string,
  value?: string
): Promise<string> {
  let route = null;
  let routeType = null;
  let sendValue: any = null;

  if (!clientID || clientID === "") {
    console.log("Missing Client ID");
    return "Missing Client ID";
  }

  if (!participantID || participantID === "") {
    return "Missing Participant ID";
  }

  sendValue = {
    participants: [participantID] as string[],
    bulk: false
  };

  if (!participantsAction || participantsAction === "") {
    return "Missing Action";
  }

  if (!level || level === "") {
    return "Missing Level";
  }

  if (level === "client") {
    routeType = "client";
    sendValue = {
      ...sendValue,
      clientID: clientID
    };
  } else if (level === "study") {
    routeType = "study";
    sendValue = {
      ...sendValue,
      clientID: clientID,
      studyID: studyID
    };
  } else if (level === "survey") {
    routeType = "survey";
    sendValue = {
      ...sendValue,
      clientID: clientID,
      studyID: studyID,
      surveyID: surveyID
    };
  } else {
    return "Invalid Level";
  }

  if (participantsAction === "remove") {
    route = `/participants/${routeType}/remove`;
    // sendValue remains the same
  } else if (
    participantsAction === "block" ||
    participantsAction === "unblock"
  ) {
    route = `/participants/${routeType}/block`;
    sendValue = {
      ...sendValue,
      action: participantsAction
    };
  } else if (participantsAction === "flag" || participantsAction === "unflag") {
    route = `/participants/flag`;
    sendValue = {
      participants: [participantID] as string[],
      bulk: false,
      clientID: clientID,
      flag: participantsAction === "flag" ? true : false
    };
  } else if (participantsAction === "send invitation") {
    route = `/participants/sendInvitation`;
    sendValue = {
      participants: [participantID] as string[],
      bulk: false,
      clientID: clientID
    };
  } else if (participantsAction === "addreward") {
    if (!value || value === "") {
      return "Missing Reward";
    }
    route = `/rewards/create`;
    sendValue = {
      participantIDs: [participantID] as string[],
      bulk: false,
      studyID: studyID,
      clientID: clientID,
      points: parseInt(value)
    };
  } else if (
    participantsAction === "done" ||
    participantsAction === "approved" ||
    participantsAction === "rejected"
  ) {
    //we update with there rewards id not participant id
    route = `/rewards/update`;
    sendValue = {
      clientID: clientID,
      rewardID: participantID,
      status: participantsAction
    };
  } else {
    return `Invalid Action for ${participantsAction}`;
  }

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: sendValue
    });

    if (response.status === 202) {
      return "Success";
    }

    if (response.status === 400) {
      return "Please contact support";
    }

    if (response.status === 404) {
      return "Participant not found";
    }

    if (response.status === 406) {
      return "Participant blocked at a higher level";
    }

    if (
      response.status === 200 ||
      response.status === 206 ||
      response.status === 418
    ) {
      return "Incorrect response received";
    }

    return "Failed to send all participant actions";
  } catch (error) {
    console.error(
      "An error occurred while sending participant actions:",
      error
    );
    return "An error occurred while sending participant actions";
  }
}
// #endregion sendSingleParticipantActions

// #region sendBulkParticipantsActions
export async function sendBulkParticipantsActions(
  participantsAction: string,
  participants: string[],
  level: string,
  clientID: string,
  studyID?: string,
  surveyID?: string,
  participantsActionValue?: string | string[]
): Promise<string> {
  let route = null;
  let routeType = null;
  let sendValue: any = null;

  if (!clientID || clientID === "") {
    console.log("Missing Client ID");
    return "Missing Client ID";
  }

  if (!participants || participants.length < 1) {
    return "Missing Participant IDs";
  }

  sendValue = {
    participants: participants,
    bulk: true
  };

  if (!participantsAction || participantsAction === "") {
    return "Missing Action";
  }

  if (!level || level === "") {
    return "Missing Level";
  }

  if (level === "client") {
    routeType = "client";
    sendValue = {
      ...sendValue,
      clientID: clientID
    };
  } else if (level === "study") {
    routeType = "study";
    sendValue = {
      ...sendValue,
      clientID: clientID,
      studyID: studyID
    };
  } else if (level === "survey") {
    routeType = "survey";
    sendValue = {
      ...sendValue,
      clientID: clientID,
      studyID: studyID,
      surveyID: surveyID
    };
  } else {
    return "Invalid Level";
  }

  if (participantsAction === "remove") {
    route = `/participants/${routeType}/remove`;
    // sendValue remains the same
  } else if (
    participantsAction === "block" ||
    participantsAction === "unblock"
  ) {
    route = `/participants/${routeType}/block`;
    sendValue = {
      ...sendValue,
      action: participantsAction
    };
  } else if (participantsAction === "flag" || participantsAction === "unflag") {
    route = `/participants/flag`;
    sendValue = {
      participants: participants,
      bulk: true,
      clientID: clientID,
      flag: participantsAction === "flag" ? true : false
    };
  } else if (participantsAction === "tag") {
    if (!participantsActionValue || participantsActionValue.length < 1) {
      return "Missing Tag";
    }

    route = `/participants/tags/update`;
    sendValue = {
      participants: participants,
      bulk: true,
      clientID: clientID,
      tags: participantsActionValue
    };
  } else if (participantsAction === "reward") {
    if (!participantsActionValue || participantsActionValue.length < 1) {
      return "Missing Reward";
    }
    route = `/rewards/create`;
    sendValue = {
      participantIDs: participants,
      bulk: true,
      studyID: studyID,
      clientID: clientID,
      //  points : participantsActionValue should be a number when sending the request
      points: parseInt(participantsActionValue as string)
    };
  } else if (
    participantsAction === "approved" ||
    participantsAction === "done" ||
    participantsAction === "rejected"
  ) {
    //we update with there rewards id not participant id
    route = `/rewards/update/bulk`;
    sendValue = {
      clientID: clientID,
      rewardIDs: participants,
      status: participantsAction
    };
  } else {
    return `Invalid Action for ${participantsAction}`;
  }

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: sendValue
    });

    // console.log("response", response);

    if (response.status === 200 && response.data !== null) {
      if (
        !response.data ||
        !response.data.jobID ||
        typeof response.data.jobID !== "number"
      ) {
        console.log("Invalid worker data received");
        return "Invalid worker data received";
      }
      localStorage.setItem("workerID", response.data.jobID);
      return "Success";
    }

    if (response.status === 202) {
      return "Worker ID not received";
    }

    if (response.status === 400) {
      return "Please contact support";
    }

    if (response.status === 406) {
      return "Participant blocked at a higher level";
    }

    if (response.status === 404) {
      return "Participant not found";
    }

    return "Failed to send all participants actions";
  } catch (error) {
    console.error(
      "An error occurred while sending participants actions:",
      error
    );
    return "An error occurred while sending participants actions";
  }
}
// #endregion sendBulkParticipantsActions

// #region sendEditedParticipant
export async function sendEditedParticipant(
  participantID: string,
  clientID: string,
  studyID?: string,
  surveyID?: string,
  // participant Fields
  participant_name?: string,
  participant_surname?: string,
  participant_mobile_number?: string,
  participant_mobile_code?: string,
  participant_country_iso?: string,
  participant_lang_iso?: string,
  participant_timezone?: string,
  participant_score?: number,
  participant_cheating_score?: number,
  participant_tags?: string[],
  participant_password?: string | null
): Promise<
  | { rStatus: "success" }
  | { rStatus: "error"; rMessage: string; serverDataErrors?: string[] }
> {
  const lead = {
    id: participantID,
    participant_name: participant_name ? participant_name : null,
    participant_surname: participant_surname ? participant_surname : null,
    participant_mobile_number: participant_mobile_number
      ? participant_mobile_number
      : null,
    participant_mobile_code: participant_mobile_code
      ? participant_mobile_code
      : null,
    participant_country_iso: participant_country_iso
      ? participant_country_iso
      : null,
    participant_lang_iso: participant_lang_iso ? participant_lang_iso : null,
    participant_timezone: participant_timezone ? participant_timezone : null,
    participant_score:
      participant_score !== null || participant_score !== undefined
        ? participant_score
        : null,
    participant_cheating_score:
      participant_score !== null || participant_score !== undefined
        ? participant_cheating_score
        : null,
    participant_tags: participant_tags ? participant_tags : null,
    participant_password: participant_password ? participant_password : ""
  };

  let body = {};
  body = {
    clientID: clientID,
    action: "participant",
    participants: [participantID] as string[],
    lead: lead
  };
  console.log("body", body);

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  try {
    const response = await apiFetcher<unknown>(
      "/participants/update/id",
      "POST",
      {
        body: body
      }
    );
    console.log("response", response);
    if (response.status === 202) {
      // console.log(response.data);
      return {
        rStatus: "success"
      };
    }

    if (response.status === 206) {
      return {
        rStatus: "error",
        rMessage: "No fields changed"
      };
    }

    if (response.status === 409) {
      if (
        !("data" in response) ||
        !response.data ||
        typeof response.data !== "object" ||
        !("messages" in response.data) ||
        !Array.isArray(response.data.messages) ||
        !response.data.messages.every(
          (message: unknown) => typeof message === "string"
        )
      ) {
        return {
          rStatus: "error",
          rMessage: "Data could not be set. Please contact support"
        };
      }

      return {
        rStatus: "error",
        rMessage: "Error updating participant",
        serverDataErrors: response.data.messages
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }

    if (response.status === 404) {
      // return "Participant not found";
      return {
        rStatus: "error",
        rMessage: `Participant not found: ${participantID}`
      };
    }
  } catch (error) {
    console.error("An error occurred while :", error);
    return {
      rStatus: "error",
      rMessage: `An error occurred while updating participant: ${participantID}`
    };
  }

  return {
    rStatus: "error",
    rMessage: `An error occurred while updating participant: ${participantID}`
  };
}
// #endregion sendEditedParticipant

// #region addParticipantToStudy
export async function addParticipantToStudy(
  clientID: string,
  participants: string[],
  studyID: string
): Promise<any> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  try {
    const response = await apiFetcher<any>("/participants/study/add", "POST", {
      body: { clientID, participants, studyID }
    });

    if (response.status === 200) {
      if (
        !response.data.jobID ||
        response.data.jobID === null ||
        response.data.jobID === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // console.log("response.data.id", response.data.jobID);
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "All participants added successfully to worker"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Server error"
  };
}
// #endregion addParticipantToStudy

// #region addParticipantToSurvey
export async function addParticipantToSurvey(
  clientID: string,
  studyID: string,
  surveyID: string,
  participants: string[]
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  try {
    const response = await apiFetcher<any>("/participants/survey/add", "POST", {
      body: { clientID, studyID, surveyID, participants }
    });
    // console.log(body)

    if (response.status === 200) {
      if (
        !response.data.jobID ||
        response.data.jobID === null ||
        response.data.jobID === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // console.log("response.data.id", response.data.jobID);
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "All participants added successfully to worker"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Server error"
  };
}
// #endregion addParticipantToSurvey

// #region fetchAllParticipantsSurveyCompletes
export async function fetchAllParticipantsSurveyCompletes(
  clientID: string,
  surveyID: string
): Promise<
  | { rStatus: "success"; rData: SurveyCompletion[] }
  | { rStatus: "error"; rData: string }
> {
  try {
    console.log("fetching survey completes");
    console.log("clientID", clientID);
    console.log("surveyID", surveyID);
    if (!clientID) {
      console.error("Missing clientID");
      return {
        rStatus: "error",
        rData: "Missing clientID"
      };
    }

    if (!surveyID) {
      console.error("Missing surveyID");
      return {
        rStatus: "error",
        rData: "Missing surveyID"
      };
    }

    const response = await apiFetcher<any>("/survey/completes/export", "POST", {
      body: { clientID: clientID, surveyID: surveyID }
    });
    console.log("response", response);

    if (response.status === 200) {
      if (Array.isArray(response.data)) {
        if (response.data.every((item: any) => isSurveyCompletion(item))) {
          return {
            rStatus: "success",
            rData: response.data as SurveyCompletion[]
          };
        } else {
          return {
            rStatus: "error",
            rData: "Invalid data received"
          };
        }
      } else {
        return {
          rStatus: "error",
          rData: "Invalid data received"
        };
      }
    } else if (response.status === 204) {
      return {
        rStatus: "success",
        rData: []
      };
    } else {
      return {
        rStatus: "error",
        rData: "Failed to fetch data"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rData: "Server error"
    };
  }
}
// #endregion fetchAllParticipantsSurveyCompletes

// #region fetchAllParticipantHistoryById
//get all participant history
export async function fetchAllParticipantHistoryById(
  clientID: string,
  participantID: string
): Promise<any> {
  try {
    if (!clientID) {
      console.error("Missing clientID");
      return {
        rStatus: "error",
        rData: "Missing clientID"
      };
    }

    if (!participantID) {
      console.error("Missing participantID");
      return {
        rStatus: "error",
        rData: "Missing participantID"
      };
    }

    const response = await apiFetcher<any>(
      "/participants/get/history",
      "POST",
      {
        body: { clientID: clientID, participantID: participantID }
      }
    );
    // console.log("response", response);

    if (response.status === 200 && isValidApiResponse(response.data)) {
      // Here, we'll assume response.data contains the data structure you provided
      // Now, we incorporate the consolidation logic
      let consolidatedHistory = [];

      // Assuming response.data contains the structure like the one you've provided
      const data = response.data; // This should be set to the actual data structure

      // Process each array in the data
      // Example for participant
      if (data.participant && data.participant.length > 0) {
        for (let i = 0; i < data.participant.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.participant[i].eventAt,
            Action: data.participant[i].category
          });
        }
      }

      // Example for channelMessages
      if (data.channelMessages && data.channelMessages.length > 0) {
        for (let i = 0; i < data.channelMessages.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.channelMessages[i].sentAt,
            Action: `Sent a message in ${data.channelMessages[i].channelName} in study ${data.channelMessages[i].studyName}`
          });
        }
      }

      // Example for channelMessages
      if (data.channels && data.channels.length > 0) {
        for (let i = 0; i < data.channels.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.channels[i].addedAt,
            Action: `Added to channel ${data.channels[i].channelName} in study ${data.channels[i].studyName}`
          });
        }
      }

      if (data.buckets && data.buckets.length > 0) {
        for (let i = 0; i < data.buckets.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.buckets[i].addedAt,
            Action: `Added to bucket ${data.buckets[i].bucketName} in survey ${data.buckets[i].surveyName} in study ${data.buckets[i].studyName}`
          });
        }
      }

      if (data.completes && data.completes.length > 0) {
        for (let i = 0; i < data.completes.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.completes[i].actionAt,
            Action: `Completed survey ${data.completes[i].surveyName}`
          });
        }
      }

      // Process rewards
      if (data.rewards && data.rewards.length > 0) {
        for (let i = 0; i < data.rewards.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.rewards[i].eventAt,
            Action: `${data.rewards[i].points} ${data.rewards[i].category}. Earned date: ${formatDate(convertTime(data.rewards[i].earnedAt, 0))}`
          });
        }
      }

      // Process tags
      if (data.tags && data.tags.length > 0) {
        for (let i = 0; i < data.tags.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.tags[i].addedAt,
            Action: `Tagged with "${data.tags[i].tagName}"`
          });
        }
      }

      // Process automations
      if (data.automations && data.automations.length > 0) {
        for (let i = 0; i < data.automations.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.automations[i].actionAt,
            Action: `Automation "${data.automations[i].automationName}" in study ${data.automations[i].studyName} ${data.automations[i].status}`
          });
        }
      }

      // Process messages
      if (data.messages && data.messages.length > 0) {
        for (let i = 0; i < data.messages.length; i++) {
          consolidatedHistory.push({
            dateAndTime: data.messages[i].actionAt
              ? data.messages[i].actionAt
              : data.messages[i].createdAt,
            Action: `Message of type "${data.messages[i].type}" in study ${data.messages[i].studyName} was ${data.messages[i].status}`
          });
        }
      }

      // Sorting consolidated history by dateAndTime
      consolidatedHistory.sort(
        (a, b) =>
          new Date(a.dateAndTime).getTime() - new Date(b.dateAndTime).getTime()
      );

      // Return the successfully processed and consolidated history
      return {
        rStatus: "success",
        rData: consolidatedHistory
      };
    } else if (response.status === 204) {
      return {
        rStatus: "success",
        rData: []
      };
    } else {
      console.log("Failed to fetch data");
      return {
        rStatus: "error",
        rData: []
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rData: "Server error"
    };
  }
}
// #endregion fetchAllParticipantHistoryById

// #region resetParticipantPassword
// reset app participant password
export async function resetParticipantPassword(
  token: string,
  newPassword: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    // base64 encode the password
    newPassword = btoa(newPassword);
    console.log("newPassword", newPassword);
    const response = await apiFetcher<any>(
      "/reset/password/participant",
      "POST",
      {
        body: { token, newPassword }
      },
      undefined,
      true
    );
    console.log("response", response);

    if (response.status === 200 || response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Password reset successfully"
      };
    }

    if (response.status === 406) {
      return {
        rStatus: "error",
        rMessage:
          response.data.message && typeof response.data.message === "string"
            ? response.data.message
            : "Password reset failed"
      };
    }

    return {
      rStatus: "error",
      rMessage: "Failed to reset password"
    };
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }
}
// #endregion resetParticipantPassword
