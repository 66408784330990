import { javascript } from "@codemirror/lang-javascript";
import { json } from "@codemirror/lang-json";
import { EditorView } from "@codemirror/view";
import CodeMirror from "@uiw/react-codemirror";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import timeJsonData from "../../assets/data/times.json";
import { IconMoon, IconSun } from "../../assets/images/icons/icons";
import { usePermissions } from "../../contexts/UserContext";
import {
  fetchScript,
  submitCreateReport,
  submitUpdateReport,
  validateScript
} from "../../models/dfu.model";
import {
  convertEpochToStringTime,
  convertStringTimeToEpoch,
  sortTodayEpoch,
  useOutsideClick
} from "../../utilities/utils";
import { Times } from "../StudySurveyTabSingle/Automation/Timing/timing.component";
import "./reportsBuilder.scss";
import StructureModal from "./structureModal.component";

type ReportsBuilderProps = {
  reportID: number | undefined;
  backToTable: () => void;
};

const ReportsBuilder: React.FC<ReportsBuilderProps> = ({
  reportID,
  backToTable
}) => {
  const { clientID } = useParams();
  const { hasPermission } = usePermissions();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string>("");
  const [timeJsonDate, setTimeJsonDate] = useState<Times[]>(timeJsonData.time);
  const [reportName, setReportName] = useState<string>("");
  const [reportCode, setReportCode] = useState<string>(
    "// Write your custom JavaScript report here"
  );
  const [selectedTiming, setSelectedTiming] = useState<string[]>([]);
  const [timingDropdown, setTimingDropdown] = useState<boolean>(false);
  const [timeFilter, setTimeFilter] = useState<string>("");
  const [isScheduleTimeEnabled, setIsScheduleTimeEnabled] =
    useState<boolean>(false);
  const [codeEditorTheme, setCodeEditorTheme] = useState<"dark" | "light">(
    "light"
  );

  const handleCodeChange = (value: string) => {
    setReportCode(value);
  };

  // Handle timing dropdown visibility
  const handleTimingDropdown = (show: boolean) => {
    setTimingDropdown(show);
  };

  // Handle selecting a timing option
  const handleSelectTiming = (localTiming: string) => {
    // if the timing is already selected, remove it
    if (selectedTiming.includes(localTiming)) {
      handleRemoveTiming(localTiming);
    } else {
      // max 10 timing options
      if (selectedTiming.length >= 10) {
        Swal.fire({
          icon: "warning",
          // title: "Error",
          text: "Maximum 10 schedule times allowed"
        });
        return;
      }
      setSelectedTiming((prev) => orderTimes([...prev, localTiming]));
    }
  };

  // Handle removing a timing option
  const handleRemoveTiming = (localTiming: string) => {
    setSelectedTiming((prev) => prev.filter((time) => time !== localTiming));
  };

  // Handle filtering timing options
  const handleTimeSearchFilter = (filter: string) => {
    setTimeFilter(filter);
    setTimeJsonDate(
      timeJsonData.time.filter((time) =>
        time.name.toLowerCase().includes(filter.toLowerCase())
      )
    );
  };

  const localTimingRef = useRef<HTMLDivElement>(null);
  useOutsideClick(localTimingRef, () => {
    // Close the dropdown when clicking outside
    handleTimingDropdown(false);
  });

  function handleScheduleTimeEnabled(e: React.ChangeEvent<HTMLInputElement>) {
    setIsScheduleTimeEnabled(e.target.checked);
    if (!e.target.checked) {
      setSelectedTiming([]);
    }
  }

  function orderTimes(times: string[]): string[] {
    return times.sort((a, b) => {
      return convertStringTimeToEpoch(a) - convertStringTimeToEpoch(b);
    });
  }

  async function handleTestButtonClick() {
    console.log("Test button clicked");

    if (
      !reportCode ||
      reportCode.trim() === "" ||
      reportCode.trim() === "// Write your custom JavaScript report here"
    ) {
      Swal.fire({
        icon: "warning",
        text: "Please enter code to test"
      });
      return;
    }

    const response = await validateScript(reportCode);
    console.log(response);
    if (response.rStatus === "success") {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Script is valid"
      });
      return;
    }

    if (response.returnError) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `${response.message}<br />${response.returnError}`
      });
      return;
    }

    Swal.fire({
      icon: "error",
      title: "Error",
      text: response.message
    });
  }

  function validateSubmit(): boolean {
    const errors: string[] = [];
    const MAX_NAME_LENGTH = 64;

    if (!reportName || reportName.trim() === "") {
      errors.push("Please enter a report name");
    }

    if (reportName.length > MAX_NAME_LENGTH) {
      errors.push(
        `Report name must be less than ${MAX_NAME_LENGTH} characters`
      );
    }

    if (
      !reportCode ||
      reportCode.trim() === "" ||
      reportCode.trim() === "// Write your custom JavaScript report here"
    ) {
      errors.push("Please enter report code");
    }

    if (isScheduleTimeEnabled && selectedTiming.length === 0) {
      errors.push("Please select at least one schedule time");
    }

    if (errors.length === 0) {
      return true;
    }

    Swal.fire({
      icon: "warning",
      title: "Cannot submit",
      html: errors.join("<br>")
    });
    return false;
  }

  async function handleSubmitButtonClick() {
    console.log("Submit button clicked");

    const numClientID = clientID ? parseInt(clientID) : undefined;

    if (!numClientID || isNaN(numClientID)) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Invalid client ID"
      });
      console.error("Invalid client ID");
      return;
    }

    if (!validateSubmit()) {
      return;
    }

    const convertedTiming = selectedTiming.map((time) =>
      convertStringTimeToEpoch(time)
    );

    const response = reportID
      ? await submitUpdateReport({
          reportID,
          clientID: numClientID,
          reportName,
          reportCode,
          scheduleTime: convertedTiming
        })
      : await submitCreateReport({
          clientID: numClientID,
          reportName,
          reportCode,
          scheduleTime: convertedTiming
        });

    if (response.rStatus === "success") {
      await Swal.fire({
        icon: "success",
        title: "Success",
        text: reportID
          ? "Report updated successfully"
          : "Report created successfully",
        confirmButtonColor: "#3085d6"
      }).then((result) => {
        backToTable();
      });

      return;
    }

    Swal.fire({
      icon: "error",
      title: "Error",
      text: "Failed to create report"
    });
  }

  const getReportSettings = useCallback(async () => {
    const numClientID = clientID ? parseInt(clientID) : undefined;
    if (numClientID === undefined || isNaN(numClientID)) {
      setLoading(false);
      setLoadingError("Invalid client ID");
      return;
    }
    setLoading(true);
    setLoadingError("");
    if (!reportID) {
      setLoading(false);
      return;
    }

    // Fetch report settings from database
    const response = await fetchScript(numClientID, reportID);
    setLoading(false);
    if (response.rStatus === "error") {
      setLoadingError(response.message);
      return;
    }

    setReportName(response.rData.report_name);
    setReportCode(response.rData.report_script);
    // setSelectedTiming(response.rData.timings);

    const newTimings = sortTodayEpoch(response.rData.scheduled_timings).map(
      (time: number) => convertEpochToStringTime(time)
    );
    setSelectedTiming(newTimings);
    if (newTimings.length > 0) {
      setIsScheduleTimeEnabled(true);
    }
  }, [clientID, reportID]);

  useEffect(() => {
    // Fetch report data from database
    getReportSettings();
  }, [reportID, clientID, getReportSettings]);

  function permissionEditCheck(): boolean {
    return (
      hasPermission("subject") ||
      hasPermission("scripts", "all") ||
      hasPermission("scripts", "write")
    );
  }

  return (
    <div className="reports_builder">
      <div className="mb-2">
        <StructureModal />
      </div>
      {loading ? (
        <div className="d-flex justify-content-center custom_spinner_container">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : loadingError ? (
        <div>
          <div>{loadingError}</div>
          <button className="btn btn-secondary" onClick={() => backToTable()}>
            Back
          </button>
        </div>
      ) : (
        <div>
          <div className="d-flex flex-column flex-lg-row justify-content-between mb-2 align-items-center">
            <div className="d-flex flex-column flex-lg-row gap-4 align-items-center mb-2 mb-lg-0">
              <label className="d-flex align-items-center mb-2 mb-lg-0">
                <div className="text-nowrap">Report Name</div>
                <input
                  type="text"
                  className="ms-2 form-control"
                  placeholder="Report Name"
                  value={reportName}
                  onChange={(e) => setReportName(e.target.value)}
                  disabled={!permissionEditCheck()}
                />
              </label>
              <div className="d-flex flex-column flex-lg-row gap-2 align-items-center">
                <label className="mb-2 mb-lg-0">
                  <input
                    type="checkbox"
                    className="me-2 form-check-input"
                    checked={isScheduleTimeEnabled}
                    onChange={(e) => handleScheduleTimeEnabled(e)}
                    disabled={!permissionEditCheck()}
                  />
                  Schedule Time
                </label>
                <div className="dropdown dropdown_timing" ref={localTimingRef}>
                  <button
                    className="btn btn-primary dropdown-toggle timing_dropdown_button"
                    disabled={!isScheduleTimeEnabled || !permissionEditCheck()}
                    onClick={() => handleTimingDropdown(!timingDropdown)}
                  >
                    {selectedTiming.length > 0
                      ? `${selectedTiming.length} selected`
                      : "Select Time"}
                  </button>
                  <div
                    className={`dropdown-menu ${timingDropdown ? "show" : "hide"}`}
                  >
                    <input
                      type="text"
                      placeholder="Search Time"
                      value={timeFilter}
                      onChange={(e) => handleTimeSearchFilter(e.target.value)}
                      className="dropdown-timing-search mx-1"
                    />
                    <div className="dropdown-items">
                      {timeJsonDate.map((timing) => (
                        <div
                          key={timing.id}
                          className={`dropdown-item clickable ${selectedTiming.includes(timing.name) ? "selected" : ""}`}
                          onClick={() => handleSelectTiming(timing.name)}
                        >
                          <li>
                            <a>{timing.name}</a>
                          </li>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="d-flex gap-2 flex-wrap">
                  {selectedTiming.map((timing) => (
                    <div
                      key={timing}
                      className={`timing_span ${permissionEditCheck() ? "clickable" : ""}`}
                      onClick={() =>
                        permissionEditCheck() && handleRemoveTiming(timing)
                      }
                    >
                      {timing}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end gap-2">
              <button
                className="btn btn-secondary"
                onClick={() => backToTable()}
              >
                {permissionEditCheck() ? "Cancel" : "Back"}
              </button>

              <button
                className="btn btn-success"
                onClick={handleTestButtonClick}
              >
                Validate
              </button>
              {permissionEditCheck() && (
                <button
                  className="btn btn-primary text-nowrap"
                  onClick={handleSubmitButtonClick}
                >
                  {reportID ? "Update Report" : "Create Report"}
                </button>
              )}
            </div>
          </div>
          <div className="position-relative code_editor">
            <CodeMirror
              minHeight="calc(100vh - 240px)"
              maxHeight="calc(100vh - 240px)"
              value={reportCode}
              theme={codeEditorTheme}
              onChange={(value, viewUpdate) => handleCodeChange(value)}
              extensions={[javascript(), json(), EditorView.lineWrapping]} // Enable word wrap
              readOnly={!permissionEditCheck()}
            />
            <div
              className="code_editor_theme_button"
              onClick={() =>
                setCodeEditorTheme(
                  codeEditorTheme === "dark" ? "light" : "dark"
                )
              }
            >
              {codeEditorTheme === "dark" ? (
                <IconSun className="icon icon_sun clickable" />
              ) : (
                <IconMoon className="icon icon_moon clickable" />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReportsBuilder;
