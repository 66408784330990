import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IconUpload } from "../../../assets/images/icons/icons";
import { useSocket } from "../../../contexts/SocketContext";
import {
  createChannel,
  deleteChannel,
  updateChannel
} from "../../../models/chat.model";
import { getResources } from "../../../models/resources.model";
import {
  Channel,
  ChannelMessages,
  ChannelParticipants,
  Country,
  Language,
  NewMessageResponse,
  ResourceData,
  Timezone
} from "../../../types";
import {
  getSystemConfigCountries,
  getSystemConfigLanguages,
  getSystemConfigTimezones
} from "../../../utilities/config.util";
import { CustomModal as ChannelOptionsModal } from "../../Modal/custom-modal.component";
import ChannelContainer from "./Channels/channelsContainer.component";
import ConversationContainer from "./Conversation/conversationContainer.component";
import ParticipantsContainer from "./Participants/participantsContainer.component";
import "./chat.scss";

export type ChatProps = {
  studyClicked: string;
};

const Chat: React.FC<ChatProps> = ({ studyClicked }) => {
  const [channels, setChannels] = React.useState<Channel[]>([]);
  const [channelParticipants, setChannelParticipants] = React.useState<
    ChannelParticipants[]
  >([]);
  const [channelMessages, setChannelMessages] = React.useState<
    ChannelMessages[]
  >([]);
  const [selectedChannel, setSelectedChannel] = React.useState<string>("0");
  const [selectedParticipantId, setSelectedParticipantId] = React.useState<
    string | null
  >(null);
  const [forceRefresh, setForceRefresh] = React.useState<boolean>(false);
  const [selectedChannelType, setSelectedChannelType] =
    React.useState<string>("");
  const [unreadMessages, setUnreadMessages] = useState<number>(0);
  const [openChannelOptionsModal, setOpenChannelOptionsModal] = useState(false);
  const [isCreateChannel, setIsCreateChannel] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
  const [isChannelLoading, setIsChannelLoading] = useState<boolean>(true);
  const [participantsLoading, setParticipantsLoading] = useState<boolean>(true);
  const [messagesLoading, setMessagesLoading] = useState<boolean>(true);
  const [isChannelCreateLoading, setIsChannelCreateLoading] =
    useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const [countries, setCountries] = useState<Country[]>([]);
  const [languages, setLanguages] = useState<Language[]>([]);
  const [timezones, setTimezones] = useState<Timezone[]>([]);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [uploadedImage, setUploadedImage] = useState<string>("");
  const [channelName, setChannelName] = useState<string>("");
  const [channelType, setChannelType] = useState<string>("");
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>("");
  const [resources, setResources] = useState<ResourceData[] | string>([]);
  const [originalFileName, setOriginalFileName] = useState<string | undefined>(
    ""
  );
  // State for validation flags
  const [isChannelNameValid, setIsChannelNameValid] = useState(true);
  const [isChannelTypeValid, setIsChannelTypeValid] = useState(true);
  const [isImageValid, setIsImageValid] = useState(true);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [chatRefresh, setChatRefresh] = useState<boolean>(false);
  const [participantRefresh, setParticipantRefresh] = useState<boolean>(false);

  const { clientID, studyID } = useParams(); // Get from URL
  const MAX_FILE_SIZE = 2 * 1024 * 1024;
  //Sockets Chat Components
  const { state, dispatch, controller } = useSocket();

  useEffect(() => {
    //Get initial Data
    if (!clientID || !studyID) {
      showError("Client ID or Study ID is missing");
      return;
    }
    //Get all channels
    if (state.authenticated === true) {
      setIsPageLoading(false);
      setIsChannelLoading(true);
      controller.getAllChannels(clientID, studyID, null);
    }
  }, [state.authenticated]);

  useEffect(() => {
    //If Channels are not empty
    if (Array.isArray(state.allChannels) && state.allChannels.length > 0) {
      setChannels(state.allChannels);
      setIsChannelLoading(false);
      setSelectedChannel(state.allChannels[0].id);
      setSelectedChannelType(state.allChannels[0].channelType);
      controller.getChannelParticipants(
        clientID!,
        studyID!,
        state.allChannels[0].id
      );
    }
    //If Channel is empty
    if (Array.isArray(state.allChannels) && state.allChannels.length === 0) {
      setChannels([]);
      setSelectedChannel("");
      setIsChannelLoading(false);
    }
    //If There was was an error getting channels
    if (typeof state.allChannels === "string") {
      showError(state.allChannels);
      setChannels([]);
      setSelectedChannel("");
      setIsChannelLoading(false);
    }
  }, [state.allChannels]);

  //UseEffect for Channel Participants
  useEffect(() => {
    //we only want this to run when channels are updated
    if (
      Array.isArray(state.channelParticipants) &&
      state.channelParticipants.length > 0 &&
      Array.isArray(channels) &&
      channels.length > 0
    ) {
      setChannelParticipants(
        initialSortParticipants(state.channelParticipants)
      );
      setParticipantsLoading(false);
      updateUnreadMessages(state.channelParticipants);
      setSelectedParticipantId(state.channelParticipants[0].id);
      //Fetch Messages
      controller.getParticipantsMessages(
        clientID!,
        studyID!,
        selectedChannel,
        state.channelParticipants[0].id
      );
    }
    //Else empty array of participants
    if (
      Array.isArray(state.channelParticipants) &&
      state.channelParticipants.length === 0
    ) {
      setChannelParticipants([]);
      setParticipantsLoading(false);
      setMessagesLoading(false);
      setUnreadMessages(0);
      setChannelMessages([]);
    }
    //If there is an error
    if (typeof state.channelParticipants === "string") {
      showError(state.channelParticipants);
      setChannelParticipants([]);
      setParticipantsLoading(false);
      setMessagesLoading(false);
    }
  }, [state.channelParticipants]);

  //UseEffect for Channel Messages
  useEffect(() => {
    //we only want this to run when participants are updated
    if (Array.isArray(state.allMessages) && state.allMessages.length > 0) {
      setChannelMessages(state.allMessages);
      setMessagesLoading(false);
    }
    //Else empty array of messages
    if (Array.isArray(state.allMessages) && state.allMessages.length === 0) {
      setChannelMessages([]);
      setMessagesLoading(false);
    }
    //If there is an error
    if (typeof state.allMessages === "string") {
      showError(state.allMessages);
      setChannelMessages([]);
      setMessagesLoading(false);
    }

    //They want to see unread which normally comes form the state.participants but then that
  }, [state.allMessages]);

  //On channel select we want to get the participants and messages
  useEffect(() => {
    //Get Participants
    if (
      selectedChannel &&
      typeof selectedChannel === "string" &&
      selectedChannel !== "" &&
      selectedChannel !== "0"
    ) {
      setParticipantsLoading(true);
      setMessagesLoading(true);
      controller.getChannelParticipants(clientID!, studyID!, selectedChannel);
    }

    if (selectedChannelType === "Focus Group") {
      setSelectedParticipantId("");
      setMessagesLoading(false);
    }
  }, [selectedChannel]);

  //On participant select we want to get the messages
  useEffect(() => {
    //Get Messages
    if (
      selectedParticipantId &&
      typeof selectedParticipantId === "string" &&
      selectedParticipantId !== "" &&
      selectedParticipantId !== null
    ) {
      setMessagesLoading(true);
      controller.getParticipantsMessages(
        clientID!,
        studyID!,
        selectedChannel,
        selectedParticipantId
      );
    }
  }, [selectedParticipantId, forceRefresh]);

  useEffect(() => {
    if (
      typeof state.newMessage !== "string" &&
      state.newMessage &&
      state.newMessage !== null &&
      state?.newMessage?.message
    ) {
      const newMessageResponse = state.newMessage as NewMessageResponse;

      // Common message object construction
      const newMessage = {
        id: newMessageResponse.message.id,
        message: newMessageResponse.message.message,
        createdAt: newMessageResponse.message.createdAt,
        sender: newMessageResponse.message.sender,
        senderName: newMessageResponse.message.senderName,
        parentID: newMessageResponse.message.parentID,
        attachmentUrl: newMessageResponse.message.attachmentUrl,
        participantID: newMessageResponse.message.participantID,
        seen: newMessageResponse.message.seen,
        flagged: newMessageResponse.message.flagged,
        type: newMessageResponse.message.type,
        edited: newMessageResponse.message.edited,
        deleted: newMessageResponse.message.deleted,
        pinned: newMessageResponse.message.pinned
      };

      if (
        selectedParticipantId === newMessageResponse.message.participantID &&
        selectedChannel
      ) {
        setChannelMessages((prevMessages) => {
          const newMessages = [...prevMessages, newMessage];
          return newMessages;
        });
      } else if (
        channelType === "Focus Group" &&
        selectedChannel === newMessageResponse?.channelID
      ) {
        setChannelMessages((prevMessages) => {
          const newMessages = [...prevMessages, newMessage];
          return newMessages;
        });
      }
      //Need to Test ===========================================================================================
      //If we get a message and the user is not on the channel we want to update the unread count for that users
      if (
        selectedParticipantId !== newMessageResponse?.message?.participantID &&
        selectedChannel === newMessageResponse?.channelID
      ) {
        setChannelParticipants((prevParticipants) => {
          const updatedParticipants = prevParticipants.map((participant) => {
            if (participant.id === newMessageResponse.message.participantID) {
              return {
                ...participant,
                unread: participant.unread ? participant.unread + 1 : 1
              };
            }
            return participant;
          });
          return sortParticipants(updatedParticipants);
        });
      }
      //A Swal Alert is set to loading here i want to close that alert
      Swal.close();
    }

    if (typeof state.newMessage === "string" && state.newMessage !== "") {
      if (!clientID || !studyID || !selectedChannel || !selectedParticipantId) {
        showError("Failed to refetch messages. Missing required data.");
        return;
      }
      Swal.close();
      controller.getParticipantsMessages(
        clientID,
        studyID,
        selectedChannel,
        selectedParticipantId
      );
    }
  }, [state.newMessage]);

  //Show Error fetching data
  const showError = (message: string) => {
    Swal.fire({
      title: "Error, fetching data",
      text: message,
      icon: "error",
      showConfirmButton: false,
      timer: 1500
    });
  };

  // Define the sorting logic as a separate function
  const initialSortParticipants = (participants: ChannelParticipants[]) => {
    console.log("Sorting this data", participants);
    return participants.sort((a, b) => {
      // Use unread counts directly as they are already numbers
      const unreadCountA = a.unread || 0;
      const unreadCountB = b.unread || 0;

      // First, sort by unread count in descending order
      if (unreadCountB !== unreadCountA) {
        return unreadCountB - unreadCountA;
      }
      //Test with last open
      // Convert last_opened to timestamps, defaulting to 0 if not available
      const lastOpenedA = a?.last_opened
        ? new Date(a?.last_opened).getTime()
        : 0;
      const lastOpenedB = b?.last_opened
        ? new Date(b?.last_opened).getTime()
        : 0;

      // Then, sort by last_opened in descending order (newest first)
      if (lastOpenedB !== lastOpenedA) {
        return lastOpenedB - lastOpenedA;
      }

      // Convert last_seen to timestamps, defaulting to 0 if not available
      const lastSeenA = a.last_seen ? new Date(a.last_seen).getTime() : 0;
      const lastSeenB = b.last_seen ? new Date(b.last_seen).getTime() : 0;

      // Finally, sort by last_seen in descending order (newest first)
      return lastSeenB - lastSeenA;
    });
  };
  const sortParticipants = (participants: ChannelParticipants[]) => {
    console.log("Sorting this data", participants);
    return participants.sort((a, b) => {
      // Use unread counts directly as they are already numbers
      const unreadCountA = a.unread || 0;
      const unreadCountB = b.unread || 0;

      // First, sort by unread count in descending order
      if (unreadCountB !== unreadCountA) {
        return unreadCountB - unreadCountA;
      }

      // Convert last_seen to timestamps, defaulting to 0 if not available
      const lastSeenA = a.last_seen ? new Date(a.last_seen).getTime() : 0;
      const lastSeenB = b.last_seen ? new Date(b.last_seen).getTime() : 0;

      // Finally, sort by last_seen in descending order (newest first)
      return lastSeenB - lastSeenA;
    });
  };

  // Validation function
  const validateFields = () => {
    // Regex for channel name validation
    const channelNameRegex = /^[a-zA-Z0-9_\- ]+$/;

    const validChannelName =
      channelName !== "" && channelNameRegex.test(channelName);
    const validChannelType =
      channelType !== "" && channelType !== "Select Channel Type";
    const validImage = uploadedImage !== "";

    setIsChannelNameValid(validChannelName);
    setIsChannelTypeValid(validChannelType);
    setIsImageValid(validImage);

    return validChannelName && validChannelType && validImage;
  };

  const handleCloseChannelOptionsModal = () => {
    setOpenChannelOptionsModal(false);
  };

  // Separate function to update unread messages
  const updateUnreadMessages = (participants: ChannelParticipants[]) => {
    if (selectedChannelType === "Focus Group") {
      const totalUnread = participants.reduce(
        (acc, participant) => acc + (participant.unread || 0),
        0
      );
      setUnreadMessages(totalUnread);
    } else {
      //We should count the total amount of unread messages
      const totalUnread = participants.reduce(
        (acc, participant) => acc + (participant.unread || 0),
        0
      );

      setUnreadMessages(totalUnread);
    }
  };

  const handleSelectParticipant = useCallback(
    (participantId: string) => {
      if (channelType !== "Focus Group" && clientID && selectedChannel) {
        setChannelMessages([]);
        if (participantId === selectedParticipantId) {
          setForceRefresh(!forceRefresh);
        }
        setMessagesLoading(true);
        setSelectedParticipantId(participantId);

        // Update unread messages and re-sort the participants
        setChannelParticipants((prevParticipants) => {
          const updatedParticipants = prevParticipants.map((participant) => {
            if (participant.id === participantId) {
              // Update last_seen to the current time for the selected participant
              const lastSeen = new Date().toISOString();
              return {
                ...participant,
                unread: 0,
                last_seen: lastSeen
              };
            } else {
              return participant;
            }
          });

          // Update the total unread messages after selecting a participant
          updateUnreadMessages(updatedParticipants);

          return sortParticipants(updatedParticipants);
        });
      }
      let lastOpen = new Date().toISOString();
      controller.updateUnreadMessages(
        clientID!,
        studyID!,
        selectedChannel,
        participantId,
        lastOpen
      );
    },
    [
      channelType,
      clientID,
      selectedChannel,
      sortParticipants,
      updateUnreadMessages
    ]
  );

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];

    if (file) {
      // Check if the file size exceeds 2MB
      if (file.size > MAX_FILE_SIZE) {
        Swal.fire({
          title: "Image Size Too Large",
          text: "Please select a smaller image file",
          icon: "warning",
          showConfirmButton: false,
          timer: 1500
        });
        return;
      }

      const validTypes = ["image/png", "image/jpeg"];
      setOriginalFileName(file.name);
      if (!validTypes.includes(file.type)) {
        alert("Invalid file format. Please upload a PNG or JPG image.");
        return;
      }

      const reader = new FileReader();
      reader.onloadend = () => {
        setUploadedImage(reader.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCreateChannelClick = async () => {
    if (!validateFields()) return;

    setIsChannelCreateLoading(true); // Start loading

    if (
      clientID !== undefined &&
      studyID !== undefined &&
      channelName !== "" &&
      channelType !== "" &&
      uploadedImage !== undefined &&
      originalFileName !== undefined
    ) {
      try {
        const result: any = await createChannel(
          clientID,
          studyID,
          channelName,
          channelType,
          uploadedImage,
          originalFileName
        );

        if (result && result.status === 200) {
          Swal.fire({
            title: "Success!",
            text: "Channel created successfully.",
            icon: "success",
            showConfirmButton: false,
            timer: 1500
          });

          const newChannel = {
            id: result.data.channelID,
            channelName: result.data.channelName,
            channelType: result.data.channelType,
            created_at: result.data.created_at,
            updated_at: result.data.updated_at,
            thumbnailUrl: result.data.thumbnail_url,
            unread: result.data.unread
          };

          setChannels((prevChannels) => [...prevChannels, newChannel]);

          setOpenChannelOptionsModal(false);
          // fetchChannelData();
        } else {
          Swal.fire({
            title: "Something went wrong",
            text: "The channel was not created. Please try again.",
            icon: "error",
            showConfirmButton: false,
            timer: 1500
          });
        }
      } catch (error: any) {
        // Handle any errors
        Swal.fire({
          title: "Error",
          text: error.message || "An error occurred.",
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
      } finally {
        setChannelName("");
        setChannelType("");
        setUploadedImage("");
        setOriginalFileName("");
        setIsChannelCreateLoading(false); // Stop loading regardless of the outcome
      }
    }
  };

  const onEditChannelClick = async () => {
    if (clientID && studyID && selectedChannel && channelName && channelType) {
      const result: any = await updateChannel(
        clientID,
        selectedChannel,
        studyID,
        channelName,
        channelType
      );
      if (result && result.status === 202) {
        Swal.fire({
          title: "Success!",
          text: "Channel updated successfully.",
          icon: "success",
          showConfirmButton: false,
          timer: 1500
        });
        setOpenChannelOptionsModal(false);
      } else {
        Swal.fire({
          title: "Something went wrong",
          text: "The channel was not updated. Please try again.",
          icon: "error",
          showConfirmButton: false,
          timer: 1500
        });
      }
    }
  };

  const onDeleteChannelClick = async () => {
    if (!clientID || !selectedChannel || !studyID) return;
    const result: any = await deleteChannel(clientID, selectedChannel, studyID);
    if (result && result.status === 202) {
      Swal.fire({
        title: "Success!",
        text: "Channel deleted successfully.",
        icon: "success",
        showConfirmButton: false,
        timer: 1500
      });
      setOpenChannelOptionsModal(false);
    } else {
      Swal.fire({
        title: "Something went wrong",
        text: "The channel was not deleted. Please try again.",
        icon: "error",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };

  const fetchAllConfigCountries = async () => {
    try {
      const jsonData: Country[] | false = await getSystemConfigCountries();
      // console.log("jsonData:", jsonData);
      if (jsonData === false) {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      } else {
        try {
          // console.log("jsonData:", jsonData);
          setCountries(jsonData);
        } catch {
          console.error("Error setting countries");
          throw new Error("Error setting countries");
        }

        // setCountries([{ iso_code: "en", name: "English" }]);
      }
    } catch {
      setLoadingErrorText((prevErrors) => [
        ...prevErrors,
        "8425c694e2d2642b9940a2cda9e5dad7"
      ]);
    }
  };

  // get langauges from local storage using getConfigLanguages
  const fetchAllConfigLanguages = async () => {
    try {
      const jsonData: Language[] | false = await getSystemConfigLanguages();
      // console.log("jsonData:", jsonData);
      if (jsonData === false) {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      } else {
        try {
          // console.log("jsonData:", jsonData);
          setLanguages(jsonData);
        } catch {
          console.error("Error setting languages");
          throw new Error("Error setting languages");
        }
      }
    } catch {
      setLoadingErrorText((prevErrors) => [
        ...prevErrors,
        "8425c694e2d2642b9940a2cda9e5dad7"
      ]);
    }
  };

  const fetchAllConfigTimezones = async () => {
    try {
      const jsonData: Timezone[] | false = await getSystemConfigTimezones();
      // console.log("jsonData:", jsonData);
      if (jsonData === false) {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      } else {
        try {
          // console.log("jsonData:", jsonData);
          setTimezones(jsonData);
        } catch {
          console.error("Error setting timezones");
          throw new Error("Error setting timezones");
        }
      }
    } catch {
      setLoadingErrorText((prevErrors) => [
        ...prevErrors,
        "8425c694e2d2642b9940a2cda9e5dad7"
      ]);
    }
  };

  // Fetch Data Logic...
  // const fetchChannelData = async () => {
  //   try {
  //     const response = await fetchAllChannels(studyID, clientID);
  //     // console.log(response);
  //     if (Array.isArray(response) && response.length > 0) {
  //       setChannels(response);
  //       // Set the selectedChannel to the latest one
  //       // console.log(selectedChannel)
  //     } else {
  //       // If no channels or an error occurred, set channels to empty and selectedChannel to blank
  //       setChannels([]);
  //       setSelectedChannel("");
  //       setError("No channels available.");
  //     }
  //   } catch (err: any) {
  //     setError(err.message || "An error occurred.");
  //   }
  //   // finally {
  //   //   setLoading(false);
  //   // }
  // };

  // const getChannelMessages = async () => {
  //   if (!selectedChannel || !clientID) return;
  //   try {
  //     if (clientID && selectedChannel && studyID) {
  //       const response = await fetchChannelMessages(
  //         clientID,
  //         selectedChannel,
  //         studyID,
  //         selectedParticipantId // Use the state directly
  //       );

  //       if (typeof response !== "string" && response) {
  //         setChannelMessages(response);

  //         // Functionally update channelParticipants state
  //         setChannelParticipants((prevParticipants) =>
  //           prevParticipants.map((participant) => {
  //             if (
  //               selectedChannelType === "Focus Group" ||
  //               participant.id === selectedParticipantId
  //             ) {
  //               // Update only the unread count
  //               return { ...participant, unread: 0 };
  //             }
  //             return participant; // Keep other participants as is
  //           })
  //         );

  //         // setUnreadMessages(0);
  //       } else {
  //         setError("Failed to fetch data");
  //       }
  //     }
  //   } catch (err: any) {
  //     setError(err.message || "An error occurred.");
  //   }
  //   // finally {
  //   //   setMessagesLoading(false);
  //   // }
  // };

  // const getChannelParticipants = async () => {
  //   if (!selectedChannel || !clientID) return;
  //   try {
  //     if (clientID && selectedChannel && studyID) {
  //       const response = await fetchChannelParticipants(
  //         clientID,
  //         selectedChannel,
  //         studyID
  //       );

  //       if (Array.isArray(response) && response.length > 0) {
  //         // Sort participants by unread messages and then by last_seen date
  //         const sortedParticipants = response.sort((a, b) => {
  //           // Handle undefined in unread messages
  //           const unreadA = a.unread || 0; // If unread is undefined, default to 0
  //           const unreadB = b.unread || 0; // If unread is undefined, default to 0

  //           // Sort by unread messages in descending order
  //           if (unreadB !== unreadA) {
  //             return unreadB - unreadA;
  //           }

  //           // Sort by last_seen date in descending order (newest first)
  //           const dateA = a.last_seen ? new Date(a.last_seen).getTime() : 0;
  //           const dateB = b.last_seen ? new Date(b.last_seen).getTime() : 0;
  //           return dateB - dateA;
  //         });

  //         setChannelParticipants(sortedParticipants);
  //         updateUnreadMessages(sortedParticipants);
  //       } else {
  //         setChannelParticipants([]);
  //         setUnreadMessages(0);
  //       }
  //     }
  //   } catch (err: any) {
  //     setError(err.message || "An error occurred.");
  //   }
  //   // finally {
  //   //   setParticipantsLoading(false);
  //   // }
  // };

  useEffect(() => {
    // Fetch initial configuration
    fetchAllConfigCountries();
    fetchAllConfigLanguages();
    fetchAllConfigTimezones();
  }, []); // This only needs to run once on mount

  // useEffect(() => {
  //   const fetchInitialData = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await fetchAllChannels(studyID, clientID);
  //       if (Array.isArray(response) && response.length > 0) {
  //         setChannels(response);
  //         // Instead of setting the selected channel immediately,
  //         // set the flag indicating data has loaded
  //         setSelectedChannel(response[0].id);
  //         setSelectedChannelType(response[0].channel_type);
  //         // setSelectedParticipantId(
  //         // 	channelParticipants[channelParticipants.length - 1].id
  //         // );
  //       } else {
  //         // Handle no channels or error case
  //         setChannels([]);
  //         setSelectedChannel("");
  //         setError("No channels available.");
  //       }
  //     } catch (err: any) {
  //       setError(err.message || "An error occurred.");
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchInitialData();
  // }, []);

  // useEffect(() => {
  //   setParticipantsLoading(true);

  //   if (!selectedChannel || selectedChannel === "0") {
  //     setParticipantsLoading(false);
  //     setMessagesLoading(false);
  //     setSelectedParticipantId(""); // This schedules the state to be updated to null
  //     return;
  //   }
  //   setChannelMessages([]);
  //   setChannelParticipants([]);
  //   setUnreadMessages(0);

  //   const fetchData = async () => {
  //     await getChannelParticipants();
  //     setParticipantsLoading(false);

  //     if (selectedChannelType === "Focus Group") {
  //       setSelectedParticipantId("");
  //       // setMessagesLoading(true);
  //       await getChannelMessages();
  //       setMessagesLoading(false);
  //     }
  //   };

  //   fetchData();

  //   return;
  // }, [selectedChannel]);

  useEffect(() => {
    if (!isCreateChannel && selectedChannel !== "0") {
      // console.log("Selected Channel updated to:", selectedChannel);
      const channelToEdit = channels.find(
        (channel) => channel.id === selectedChannel
      );

      if (channelToEdit) {
        setChannelName(channelToEdit.channelName);
        setChannelType(channelToEdit.channelType);
        setThumbnailUrl(channelToEdit.thumbnailUrl);
      }
    } else {
      setChannelName("");
      setChannelType(""); // Ensure this resets the channelType to the default option value
      setUploadedImage("");
    }
  }, [isCreateChannel, selectedChannel, channels]);

  // useEffect(() => {
  //   // Perform actions only if selectedParticipantId is not nul
  //   if (selectedParticipantId !== null) {
  //     try {
  //       // Execute function to fetch messages or perform other actions
  //       getChannelMessages();
  //       // You might want to reset the selectedParticipantId here only if it's necessary
  //       // after certain conditions are met, like after successful data fetch
  //     } catch (err: any) {
  //       setError(err.message || "An error occurred.");
  //     } finally {
  //       setMessagesLoading(false);
  //     }
  //   }
  //   // Removed the immediate reset of setSelectedParticipantId(null);
  // }, [selectedParticipantId]);

  // useEffect(() => {
  //   // Function to fetch channel participants and messages
  //   const fetchData = async () => {
  //     if (selectedChannel && selectedChannel !== "0") {
  //       await getChannelParticipants();
  //       await getChannelMessages();
  //     }
  //   };

  //   // Set up the interval for auto-refresh
  //   const intervalId =
  //     selectedChannel && selectedChannel !== "0"
  //       ? setInterval(fetchData, 300000)
  //       : null;

  //   // Clean up function to clear the interval
  //   return () => {
  //     if (intervalId) {
  //       clearInterval(intervalId);
  //     }
  //   };
  // }, [selectedChannel, getChannelParticipants, getChannelMessages]);

  //setup a useeffect to trigger if clientID and studyID are present
  useEffect(() => {
    const fetchResources = async () => {
      if (clientID && studyID) {
        try {
          const resources: ResourceData[] | string = await getResources(
            clientID,
            studyID
          );
          console.log(resources, "resources");
          setResources(resources);
        } catch (error) {
          console.error("Failed to fetch resources", error);
        }
      }
    };

    fetchResources();
  }, [clientID, studyID]);

  return (
    <>
      {isPageLoading && (
        <div className="d-flex justify-content-center custom_spinner_container">
          <div className="spinner-border spinner_loader" role="status"></div>
        </div>
      )}
      <div className="chat_container container-fluid">
        <div className="row row_container">
          <div className="col-auto col-xs-12 col_1">
            <ChannelContainer
              channels={channels}
              selectedChannel={selectedChannel}
              setSelectedChannel={setSelectedChannel}
              setSelectedChannelType={setSelectedChannelType}
              setOpenChannelOptionsModal={setOpenChannelOptionsModal}
              setIsCreateChannel={setIsCreateChannel}
              loadingChannels={isChannelLoading}
            />
          </div>
          <div className="col col-sm col-xs-12 col_2 p-0">
            <ConversationContainer
              channelMessages={channelMessages}
              setChannelMessages={setChannelMessages}
              channelParticipants={channelParticipants.length}
              loading={messagesLoading}
              selectedChannel={selectedChannel}
              selectedChannelType={selectedChannelType}
              selectedParticipantId={selectedParticipantId}
              setOpenChannelOptionsModal={setOpenChannelOptionsModal}
              setIsCreateChannel={setIsCreateChannel}
              resources={resources}
              // setDataRefresh={setChatRefresh}
            />
          </div>
          <div className="col-sm-2 col-md-3 col-xs-12 col_3">
            <ParticipantsContainer
              channelParticipants={channelParticipants}
              setChannelParticipants={setChannelParticipants}
              selectedChannelType={selectedChannelType}
              channelID={selectedChannel}
              unreadMessages={unreadMessages}
              countries={countries}
              languages={languages}
              timezones={timezones}
              loading={participantsLoading}
              handleSelectParticipant={handleSelectParticipant}
              selectedParticipantId={selectedParticipantId}
              setDataRefresh={setParticipantRefresh}
            />
          </div>
        </div>
      </div>
      {openChannelOptionsModal && (
        <ChannelOptionsModal
          closeModal={() => handleCloseChannelOptionsModal()}
          shown={openChannelOptionsModal}
          title={isCreateChannel ? "Create Channel" : "Edit Channel"}
          modalSize=""
          // setOpen={setOpenParticipantModal}
        >
          <div className="d-flex mb-3 flex-column">
            {/* Image Upload with Preview */}
            <div className="mb-3">
              <div className="custom-file-upload">
                <img
                  className="img-fluid"
                  src={
                    isCreateChannel
                      ? uploadedImage || "via.placeholder.com/150"
                      : thumbnailUrl
                  }
                  alt=""
                  style={{ backgroundColor: "#737373" }}
                />
                {isCreateChannel && (
                  <>
                    <div className="channel-img-overlay">
                      <IconUpload className="icon icon_white" />
                      {/* Font Awesome upload icon */}
                    </div>
                    <input
                      type="file"
                      onChange={handleImageUpload}
                      accept=".png, .jpg, .jpeg"
                    />
                  </>
                )}
              </div>
              {!isImageValid && (
                <div className="invalid-feedback d-block">
                  Image is required.
                </div>
              )}
            </div>

            {/* Channel Name Input */}
            <div className="mb-3">
              <label htmlFor="channelName" className="form-label">
                Channel Name
              </label>
              <input
                type="text"
                className={`form-control ${
                  !isChannelNameValid ? "is-invalid" : ""
                }`}
                id="channelName"
                placeholder="Enter channel name"
                value={channelName}
                onChange={(e) => {
                  setChannelName(e.target.value);
                  // Reset validation state on change
                  if (!isChannelNameValid) {
                    setIsChannelNameValid(true);
                  }
                }}
              />
              {!isChannelNameValid && (
                <div className="invalid-feedback">
                  {channelName === ""
                    ? "Channel name is required."
                    : "Channel name cannot have special characters."}
                </div>
              )}
            </div>

            {/* Channel Type Dropdown */}
            {channelType.toLowerCase() !== "general chat" && (
              <div className="mb-3">
                <label htmlFor="channelType" className="form-label">
                  Channel Type
                </label>
                <select
                  className={`form-select ${
                    !isChannelTypeValid ? "is-invalid" : ""
                  }`}
                  aria-label="Default select example"
                  id="channelType"
                  value={channelType}
                  onChange={(e) => setChannelType(e.target.value)}
                >
                  <option value="">Select Channel Type</option>
                  <option value="Focus Group">Focus Group</option>
                  <option value="Topic Chat">Topic Chat</option>
                  {/* For now General chat was asked to be removed */}
                  {/* <option value="General Chat">General Chat</option> */}
                </select>
                {!isChannelTypeValid && (
                  <div className="invalid-feedback">
                    Please select a channel type.
                  </div>
                )}
              </div>
            )}
            {isChannelCreateLoading ? (
              <div className="d-flex justify-content-center custom_spinner_container pt-2">
                <div
                  className="spinner-border"
                  style={{
                    width: "20px",
                    height: "20px"
                  }}
                  role="status"
                ></div>
              </div>
            ) : (
              <>
                {isCreateChannel ? (
                  <button
                    className="btn btn-primary mt-3"
                    onClick={onCreateChannelClick}
                    disabled={isChannelCreateLoading} // Disable the button when loading
                  >
                    Create Channel
                  </button>
                ) : (
                  <div className="update-channel-buttons">
                    <button
                      className="btn btn-block btn-primary "
                      onClick={onEditChannelClick}
                    >
                      Edit Channel
                    </button>
                    {channelType.toLowerCase() !== "general chat" && (
                      <button
                        className="btn btn-block btn-danger"
                        onClick={onDeleteChannelClick}
                      >
                        Delete Channel
                      </button>
                    )}
                  </div>
                )}
              </>
            )}

            {/* Create Channel Button */}
          </div>
        </ChannelOptionsModal>
      )}
    </>
  );
};

export default Chat;
