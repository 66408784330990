import React, { useEffect, useState } from "react";
import { fetchScriptHistory } from "../../../models/dfu.model";
import { ScriptDetail } from "../../../types";
import { formatDate } from "../../../utilities/utils.ts";
import "./modalCustomReportDetails.scss";
import ModalCustomReportDetailsTable from "./modalCustomReportDetailsTable.component";

type ModalCustomReportDetailsProps = {
  clientID: number;
  reportID: number;
  close: () => void;
};

const ModalCustomReportDetails: React.FC<ModalCustomReportDetailsProps> = ({
  clientID,
  reportID,
  close
}) => {
  const [scriptDetails, setScriptDetails] = useState<ScriptDetail | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchScriptDetails = async () => {
      setLoading(true);
      setError(undefined);
      setScriptDetails(undefined);

      const response = await fetchScriptHistory(clientID, reportID);
      if (response.rStatus === "success") {
        setScriptDetails(response.rData);
      } else {
        setError(response.message);
      }
      setLoading(false);
    };
    fetchScriptDetails();
  }, [reportID, clientID]);

  return (
    <div className="modal show modal_report_structure modal_view_custom_report">
      <div className="modal-dialog">
        <div className="modal-content">
          <span
            className="close"
            onClick={() => {
              close();
            }}
          >
            &times;
          </span>

          <div className="container-fluid">
            <h5 className="modal_page_header mb-3">Report Details</h5>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : error ? (
            error
          ) : !scriptDetails ? (
            "No data found"
          ) : (
            <div className="container-fluid">
              <div className="modal-body">
                {/* export type ExecutionRecord = {
  id: number;
  scheduled_run_time: number;
  successful_execution: "Y" | "N" | null;
  // s3_link: string | null;
}; */}
                {/* } */}
                <div className="row">
                  <div className="col-4">ID</div>
                  <div className="col-8">{scriptDetails.id}</div>
                </div>
                <div className="row">
                  <div className="col-4">Report Name</div>
                  <div className="col-8">{scriptDetails.report_name}</div>
                </div>
                <div className="row">
                  <div className="col-4">Client ID</div>
                  <div className="col-8">{scriptDetails.report_client_id}</div>
                </div>
                <div className="row">
                  <div className="col-4">Created At</div>
                  <div className="col-8">
                    {formatDate(new Date(scriptDetails.created_at))}
                  </div>
                </div>
                <div className="row">
                  <div className="col-4">Updated At</div>
                  <div className="col-8">
                    {formatDate(new Date(scriptDetails.updated_at))}
                  </div>
                </div>
                <div className="row"></div>
                <div className="row">
                  <div className="col-12">
                    <h5 className="text-center mt-1">Executions History</h5>
                    <ModalCustomReportDetailsTable
                      executions={scriptDetails.executions}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ModalCustomReportDetails;
