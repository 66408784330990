import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { IconAdd } from "../../../../assets/images/icons/icons";
import {
  checkAutomation,
  saveAutomation,
  updateAutomation
} from "../../../../models/automations.model";
import Action from "../Actions/action.component";
import Condition from "../Conditions/conditionsContainer.component";
import Timing from "../Timing/timing.component";
import "./createRule.scss";

import {
  Actions,
  AutomationRuleData,
  ChildConditionGroup,
  Rules,
  SingleAutomationData,
  Timings
} from "../../../../types";

import Swal from "sweetalert2";
import {
  getActionFailures,
  getFailedChildConditionGroups,
  getFailedName,
  getFailedStatus,
  getFailedTiming
} from "../../../../utilities/automation.util";
import { capitalize, useOutsideClick } from "../../../../utilities/utils";

export type CreateRuleProps = {
  handleCreateRuleView: () => void;
  setSaveConfirmed: React.Dispatch<React.SetStateAction<boolean>>;
  dropdownRuleData: AutomationRuleData;
  allAutomations: Rules[];
  timing: Timings;
  actions: Actions[];
  childConditionGroups: ChildConditionGroup;
  setTiming: React.Dispatch<React.SetStateAction<Timings>>;
  setActions: React.Dispatch<React.SetStateAction<Actions[]>>;
  setChildConditionGroups: React.Dispatch<
    React.SetStateAction<ChildConditionGroup>
  >;
  viewEditRule: boolean;
  selectedStatus: string;
  setSelectedStatus: React.Dispatch<React.SetStateAction<string>>;
  workflowRuleName: string;
  setWorkflowRuleName: React.Dispatch<React.SetStateAction<string>>;
  originalRuleName: string;
  selectedRuleID: string;
};

const CreateRule: React.FC<CreateRuleProps> = ({
  handleCreateRuleView,
  dropdownRuleData,
  setSaveConfirmed,
  allAutomations,
  timing,
  actions,
  childConditionGroups,
  setTiming,
  setActions,
  setChildConditionGroups,
  viewEditRule,
  selectedStatus,
  setSelectedStatus,
  workflowRuleName,
  setWorkflowRuleName,
  originalRuleName,
  selectedRuleID
}) => {
  // number of applicable participants
  const [numOfApplicableParticipants, setNumOfApplicableParticipants] =
    useState<number | null>(null);

  const [loadingAutomationCheck, setLoadingAutomationCheck] =
    useState<boolean>(false);

  // Add state to track changes in actions and conditions
  const [actionsChanged, setActionsChanged] = useState(false);
  const [conditionsChanged, setConditionsChanged] = useState(false);
  const [statusChanged, setStatusChanged] = useState(false);
  const [timingChanged, setTimingChanged] = useState(false);
  const [nameChanged, setNameChanged] = useState(false);

  // Refs to store initial states of actions and conditions
  const initialActionsRef = useRef<Actions[]>([]);
  const initialConditionsRef = useRef<ChildConditionGroup>({});
  const initialStatusRef = useRef<string>("");
  // // Ref to store initial state of timing
  const initialTimingRef = useRef<Timings>();

  // Dropdown data
  const { clientID, studyID } = useParams();

  //Lets check if the are using phrase if they created a new phrase of if they are using an existing phrase
  const [newPhraseFlag, setNewPhraseFlag] = useState<string>("existing");

  // Wrap setActions to monitor changes
  const handleSetActions = (newActions: Actions[]) => {
    setActionsChanged(true);
    setActions(newActions);
  };

  // Wrap setChildConditionGroups to monitor changes
  const handleSetChildConditionGroups = (
    newChildConditionGroups: ChildConditionGroup
  ) => {
    setConditionsChanged(true);
    setChildConditionGroups(newChildConditionGroups);
  };

  // ================== STATUS & NAME ==================
  const [statusDropdown, setStatusDropdown] = useState(false);
  const [arrayStatus, setArrayStatus] = useState<string[]>([
    "Active",
    "Inactive"
  ]);

  const statusRef = useRef<HTMLDivElement>(null);
  useOutsideClick(statusRef, () => {
    // Action to take on outside click
    handleStatusDropdown(false);
  });

  const handleStatusDropdown = (show: boolean) => {
    // show/hide dropdown
    setStatusDropdown(show);
  };

  const handleSelectStatus = (status: string) => {
    setSelectedStatus(status);
    handleStatusDropdown(false);
  };

  const handleWorkflowRuleName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWorkflowRuleName(e.target.value);
  };

  // ================== CONDITIONS ==================

  // ================== ACTIONS ==================
  let actionCounter = 1;
  const addAction = () => {
    let newId = actionCounter.toString();
    while (actions.some((action) => action.id === newId)) {
      actionCounter++;
      newId = actionCounter.toString();
    }
    const newAction = {
      id: newId,
      type: "",
      variable: "",
      selection: "",
      value: ""
    };
    setActions([...actions, newAction]);
    actionCounter++;
  };

  // remove action by ID
  const removeAction = (id: string) => {
    setActions(actions.filter((action) => action.id !== id));
  };

  // ================== SAVE/EDIT AND CANCEL ==================
  // Validation, Edit
  const handleCreateRule = async (edit: boolean) => {
    console.log(workflowRuleName);
    console.log(selectedStatus);
    console.log(actions);
    console.log(childConditionGroups);
    console.log(timing);

    const singleAutomationData: SingleAutomationData = {
      name: workflowRuleName,
      status: selectedStatus.toLowerCase(),
      conditions: childConditionGroups,
      actions: actions,
      timing: timing
    };

    // Validation
    const checkChildGroups =
      getFailedChildConditionGroups(childConditionGroups);
    const checkActions = getActionFailures(actions);
    const checkTiming = getFailedTiming(timing);
    const checkStatus = getFailedStatus(selectedStatus);
    const checkName = getFailedName(
      workflowRuleName,
      allAutomations,
      viewEditRule,
      originalRuleName
    );

    // Logging Validation Results
    console.log(checkName);
    console.log(checkStatus);
    console.log(checkChildGroups);
    console.log(checkActions);
    console.log(checkTiming);

    //  Check if all conditions are valid
    if (
      checkChildGroups.length === 0 &&
      checkActions.length === 0 &&
      checkTiming.length === 0 &&
      checkStatus === "" &&
      checkName === ""
    ) {
      // If all conditions are valid we want to check if they are using sms , email or notification. IF they are we want to check if its an existing phase or if its a new phrase
      if (newPhraseFlag !== "existing") {
        const result = await Swal.fire({
          title: "Potential Delays in Creating a New Phrase",
          text: "Are you certain you wish to create a new phrase? This action may entail some delays attributable to translation processes. In the event of a missing translation, the default language will be English.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, send it!"
        });

        if (!result.isConfirmed) {
          return;
        }
      }
      console.log("ALL VALID");
      if (singleAutomationData) {
        console.log(singleAutomationData);
        // If edit is true, update automation
        if (
          edit === true &&
          studyID &&
          clientID &&
          singleAutomationData &&
          selectedRuleID
        ) {
          console.log(actionsChanged);
          console.log(conditionsChanged);
          console.log(statusChanged);
          console.log(timingChanged);
          console.log(nameChanged);
          if (
            actionsChanged ||
            conditionsChanged ||
            statusChanged ||
            timingChanged ||
            nameChanged
          ) {
            // Show the Swal pop-up here
            const confirmUpdate = await Swal.fire({
              icon: "info",
              title: "Please note",
              html: "Editing an existing rule can cause issues if the project is live. If the rule has already run for some participants, it will not run again and the history might be lost. If this is the case, please consider creating a new rule rather than editing an existing one.",
              showConfirmButton: true,
              showCancelButton: true,
              width: "40rem",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Continue",
              cancelButtonText: "Cancel"
            });

            if (!confirmUpdate.isConfirmed) {
              return;
            } else {
              const updateAutomationResponse = await updateAutomation(
                clientID,
                studyID,
                singleAutomationData,
                selectedRuleID
              );

              if (updateAutomationResponse === "202") {
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "Automation Updated!",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6"
                });
                console.log("SAVED");
                // To refetch automation list
                setSaveConfirmed(true);
                // Flips back to preview
                handleCreateRuleView();
              } else {
                // Alert on failure
                Swal.fire({
                  icon: "error",
                  title: "Failed to Update Automation",
                  text: "Please try again.",
                  showConfirmButton: true,
                  confirmButtonColor: "#3085d6"
                });
              }
              // After showing the pop-up, you may want to reset the change tracking
              setActionsChanged(false);
              setConditionsChanged(false);
              setStatusChanged(false);
              setTimingChanged(false);
            }
          }
        }
        // If edit is false, save automation
        else if (studyID && clientID && singleAutomationData) {
          console.log("SAVING");
          const saveAutomationResponse = await saveAutomation(
            clientID,
            studyID,
            singleAutomationData
          );

          // Alert on success
          if (saveAutomationResponse === "202") {
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Automation Saved!",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6"
            });
            console.log("SAVED");
            // To refetch automation list
            setSaveConfirmed(true);
            // Flips back to preview
            handleCreateRuleView();
          } else {
            // Alert on failure
            Swal.fire({
              icon: "error",
              title: "Failed to Create Automation",
              text: "Please try again.",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6"
            });
          }
        }
      }
      // If not all conditions are valid
    } else {
      console.log("NOT ALL VALID");
      // Condition error messages
      const errorMessagesCondition = checkChildGroups.map((group) => {
        const messages = group.failedChecks.map((check) => `<li>${check}</li>`);
        return `<ul style= "list-style: none;  padding:0">${messages.join(
          ""
        )}</ul>`;
      });
      const errorMessageCondition = `${errorMessagesCondition.join("")}`;

      // Action error messages
      const errorMessageActions = checkActions
        .map((action) => `<li>${action}</li>`)
        .join("");
      const errorMessageAction = `<ul style= "list-style: none; padding:0">${errorMessageActions}</ul>`;

      // Timing error messages
      const errorMessageTiming = checkTiming
        .map((timing) => `<li>${timing}</li>`)
        .join("");
      const errorMessageTimingDisplay = `<ul style= "list-style: none; padding:0">${errorMessageTiming}</ul>`;

      Swal.fire({
        icon: "error",
        title: "Failed to Create Automation",
        html: `
                ${
                  checkName
                    ? `<h5>Workflow Rule Name</h5><ul style= "list-style: none;  padding:0"><li>${checkName}</li></ul>`
                    : ""
                }
                ${
                  checkStatus
                    ? `<h5>Status</h5> <ul style= "list-style: none;  padding:0"><li>${checkStatus}</li></ul>`
                    : ""
                }
                ${
                  checkChildGroups.length > 0
                    ? `<h5>Conditions</h5> ${errorMessageCondition}`
                    : ""
                }
                ${
                  checkActions.length > 0
                    ? `<h5>Actions</h5> ${errorMessageAction}`
                    : ""
                }
                ${
                  checkTiming.length > 0
                    ? `<h5>Timing</h5> ${errorMessageTimingDisplay} `
                    : ""
                }
              `,
        confirmButtonColor: "#3085d6"
        // footer: '<a href="">Why do I have this issue?</a>',
      });
    }
  };

  // ================== UseEffects for changes ==================
  // if viewEditRule is false, default actions timings and child condition groups to empty
  useEffect(() => {
    if (viewEditRule === false) {
      setWorkflowRuleName("");
      setSelectedStatus("");
      setActions([
        {
          id: "0",
          type: "",
          variable: "",
          selection: "",
          value: ""
        }
      ]);
      setTiming({
        type: "noSchedule",
        actionMultipleTimes: false,
        timing: {
          participantLocalTime: "",
          date: ""
        }
      });
      setChildConditionGroups({
        "0": {
          operator: "and",
          childRules: [
            {
              id: "0",
              type: "",
              variable: "",
              selection: "",
              options: "",
              value: ""
            }
          ],
          childConditionGroups: {}
        }
      });
    }
  }, [viewEditRule]);
  // useEffect to log changes in states
  useEffect(() => {
    console.log(viewEditRule);
    console.log(workflowRuleName);
    console.log(selectedStatus);
    console.log(actions);
    console.log(childConditionGroups);
    console.log(timing);
  }, [timing, actions, childConditionGroups, selectedStatus, workflowRuleName]);
  useEffect(() => {
    if (viewEditRule) {
      console.log("hello");
      initialActionsRef.current = actions;
      initialConditionsRef.current = childConditionGroups;
      initialTimingRef.current = timing;
      initialStatusRef.current = selectedStatus;
    }
  }, [viewEditRule]);

  // useEffect to monitor changes in actions
  useEffect(() => {
    if (
      viewEditRule &&
      JSON.stringify(actions) !== JSON.stringify(initialActionsRef.current)
    ) {
      console.log("Changes in actions");
      setActionsChanged(true);
    }
  }, [actions, viewEditRule]);

  // useEffect to monitor changes in childConditionGroups
  useEffect(() => {
    if (
      viewEditRule &&
      JSON.stringify(childConditionGroups) !==
        JSON.stringify(initialConditionsRef.current)
    ) {
      console.log("Changes in childConditionGroups");
      setConditionsChanged(true);
    }
  }, [childConditionGroups, viewEditRule]);

  // useEffect to monitor changes in name
  useEffect(() => {
    if (viewEditRule && workflowRuleName !== originalRuleName) {
      setNameChanged(true);
    }
  }, [workflowRuleName, originalRuleName, viewEditRule]);

  //useEffect to monitor changes in timing
  useEffect(() => {
    if (
      viewEditRule &&
      JSON.stringify(timing) !== JSON.stringify(initialTimingRef.current)
    ) {
      console.log("Changes in timing");
      setTimingChanged(true);
    }
  }, [timing, viewEditRule]);

  //useEffect to monitor changes in status
  useEffect(() => {
    if (viewEditRule && selectedStatus !== initialStatusRef.current) {
      console.log("Changes in status");
      setStatusChanged(true);
    }
  }, [selectedStatus, viewEditRule]);

  function getPosition(element: Element) {
    var rect = element.getBoundingClientRect();
    return { top: rect.top, left: rect.left };
  }

  async function checkNumOfApplicableParticipants() {
    // send the conditions to the backend and get the number of applicable participants

    if (!clientID || !studyID || !childConditionGroups) {
      return;
    }

    const failedConditions =
      getFailedChildConditionGroups(childConditionGroups);
    console.log(failedConditions);
    if (failedConditions.length > 0) {
      return;
    }

    const singleAutomationData: SingleAutomationData = {
      name: workflowRuleName,
      status: selectedStatus.toLowerCase(),
      conditions: childConditionGroups,
      actions: actions,
      timing: timing
    };

    // Get the position of the "Check" span
    const checkSpan = document.querySelector(".link.clickable");
    if (!checkSpan) {
      console.error("Check span not found");
      return;
    }
    const position = getPosition(checkSpan);

    // Adding confirmation swal, if yes then continue if no then return
    const confirmation = await Swal.fire({
      icon: "warning",
      title: "Are you sure?",
      text: "This will take some significant processing on the servers.",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      toast: true,
      width: "30rem",
      position: "top-start",
      customClass: {
        popup: "check-automation-swal"
      },
      willOpen: (element) => {
        // Adjust the position dynamically
        element.style.top = `${position.top}px`;
        element.style.left = `${position.left}px`;
      }
    });

    if (!confirmation.isConfirmed) {
      return;
    }

    setLoadingAutomationCheck(true);

    const checkAutomationResponse: string = await checkAutomation(
      clientID,
      studyID,
      singleAutomationData
    );

    if (checkAutomationResponse === "success") {
      Swal.fire({
        icon: "success",
        title: "Automation Check running",
        text: "Please monitor your inbox for updates - details will be provided via email.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      setLoadingAutomationCheck(false);
    } else {
      Swal.fire({
        icon: "error",
        title: "Failed to Check Automation",
        html:
          checkAutomationResponse !== "Bad request"
            ? checkAutomationResponse
            : "Please check conditions and try again, or contact support.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      setLoadingAutomationCheck(false);
    }
  }

  return (
    <>
      {/* Header SAVE CANCEL */}
      <div className="create_rule_header">
        <h3 className="create_rule_heading">
          Workflow Rule {viewEditRule && "Edit"}
        </h3>
        <div className="create_rule_left_buttons">
          <button
            className="save_rule btn btn-primary"
            onClick={async () => await handleCreateRule(viewEditRule)}
          >
            Save
          </button>
          <button
            className="cancel_rule btn btn-secondary"
            onClick={handleCreateRuleView}
          >
            Cancel
          </button>
        </div>
      </div>

      {/* BODY */}
      <div className="create_rule_body">
        <div className="create_rule_name_container col-12">
          <input
            type="text"
            name="rule_name"
            id="create_rule_name"
            placeholder="Name of Workflow Rule..."
            value={workflowRuleName}
            onChange={handleWorkflowRuleName}
            maxLength={255}
          />
          <div className="dropdown dropdown_status ms-2" ref={statusRef}>
            <button
              className="btn btn-primary dropdown-toggle"
              onClick={() => handleStatusDropdown(!statusDropdown)}
            >
              {selectedStatus ? capitalize(selectedStatus) : "Status"}
            </button>
            <div
              className={`dropdown-menu ${statusDropdown ? "show" : "hide"}`}
            >
              {arrayStatus.map((status) => (
                <div key={status} className="dropdown-item status_item">
                  <li onClick={() => handleSelectStatus(status)}>{status}</li>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* WORKFLOW CONTAINER */}
        <div className="workflow_container col-12">
          {/* CONDITIONS */}
          <div className="col-5 rule_condition_container">
            <div className="rule_condition_header">
              <h5>Condition</h5>
              {getFailedChildConditionGroups(childConditionGroups).length ===
                0 && (
                <div>
                  {!loadingAutomationCheck || !childConditionGroups ? (
                    <>
                      <span
                        className="link clickable text-decoration-underline"
                        onClick={() => checkNumOfApplicableParticipants()}
                      >
                        Check
                      </span>

                      <span className="ms-2">
                        {numOfApplicableParticipants !== null &&
                          `(${numOfApplicableParticipants}) Participants`}
                      </span>
                    </>
                  ) : (
                    <>
                      Checking
                      <span
                        className="spinner-border spinner-border-sm ms-2"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    </>
                  )}
                </div>
              )}
            </div>

            <Condition
              childConditionGroups={childConditionGroups}
              setChildConditionGroups={setChildConditionGroups}
              dropdownRuleData={dropdownRuleData}
              editable={true}
              viewEditRule={viewEditRule}
            />
          </div>
          {/* ACTIONS */}
          <div className="col-4 rule_action_container">
            <div className="rule_action_header">
              <h5>Action</h5>
              <button className="btn btn-primary" onClick={() => addAction()}>
                <span>
                  <IconAdd />
                </span>
                Add Action
              </button>
            </div>
            <div id="action_container">
              {Array.isArray(actions) &&
                actions.length > 0 &&
                actions.map((index) => (
                  <Action
                    key={index.id}
                    actionID={index.id}
                    removeAction={removeAction}
                    setActions={setActions}
                    dropdownRuleData={dropdownRuleData}
                    editable={true}
                    actionType={index.type}
                    actionVariable={index.variable}
                    actionSelection={index.selection}
                    actionValue={index.value}
                    viewEditRule={viewEditRule}
                    newPhraseFlag={newPhraseFlag}
                    setNewPhraseFlag={setNewPhraseFlag}
                  />
                ))}
            </div>
          </div>
          {/* TIMINGS */}
          <div className="col-3 rule_timing_container">
            <div className="rule_condition_header">
              <h5>Timing</h5>
            </div>
            <Timing
              setTiming={setTiming}
              timing={timing}
              allAutomations={allAutomations}
              viewEditRule={viewEditRule}
              selectedRuleID={selectedRuleID}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateRule;
