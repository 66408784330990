import React from "react";
import {
  IconDelete,
  IconEdit,
  IconPlay
} from "../../../assets/images/icons/icons.ts";
import { usePermissions } from "../../../contexts/UserContext.tsx";
import { ShortCustomReport } from "../../../types";
import { formatDate } from "../../../utilities/utils.ts";
import ReportScheduledTimings from "./reportScheduledTimings.component.tsx";

type TableCustomReportsProps = {
  currentReports: ShortCustomReport[];
  filteredReportsLength: number;
  updateReport: (id: number) => void;
  openReport: (id: number) => void;
  runScript: (id: number) => void;
  deleteReport: (id: number) => void;
};

const TableCustomReports: React.FC<TableCustomReportsProps> = ({
  currentReports,
  filteredReportsLength,
  updateReport,
  openReport,
  runScript,
  deleteReport
}) => {
  const { hasPermission } = usePermissions();

  return (
    <div className="table-responsive">
      <table className="cust_table table">
        <thead>
          <tr>
            <th className="column_sticky">ID</th>
            <th className="">Name</th>
            <th className="">Scheduled Timings</th>
            <th className="">Date Created</th>
            <th className="">Date Modified</th>
            <th className="">Date Last Run</th>
            <th className="">Last Run Success</th>
            <th className="">Number of Runs</th>
            <th className="text-center td_icon_header">
              {hasPermission("subject") ||
              hasPermission("scripts", "all") ||
              hasPermission("scripts", "write")
                ? "Edit"
                : "View"}
            </th>

            {(hasPermission("subject") ||
              hasPermission("scripts", "all") ||
              hasPermission("scripts", "delete")) && (
              <th className="text-center td_icon_header">Delete</th>
            )}
            <th className="text-center td_icon_header">Run</th>
          </tr>
        </thead>
        <tbody>
          {currentReports.map((report) => (
            <tr key={report.id}>
              <td>{report.id}</td>
              <td>
                <span
                  className="clickable report_name"
                  onClick={() => openReport(report.id)}
                >
                  {report.report_name}
                </span>
              </td>
              <td>
                <ReportScheduledTimings
                  scheduledTimings={report.scheduled_timings}
                />
              </td>
              <td>{formatDate(new Date(report.date_created))}</td>
              <td>{formatDate(new Date(report.date_modified))}</td>
              <td>
                {report.date_last_run
                  ? formatDate(new Date(report.date_last_run))
                  : "N/A"}
              </td>
              <td>
                {report.last_run_success === "Y"
                  ? "Success"
                  : report.last_run_success === "N"
                    ? "Failure"
                    : "N/A"}
              </td>
              <td>{report.num_runs}</td>
              <td className="text-center td_icon">
                <span
                  className="clickable m-auto"
                  onClick={() => updateReport(report.id)}
                >
                  <IconEdit
                    width={20}
                    height={20}
                    className="icon icon_edit clickable"
                  />
                </span>
              </td>
              {(hasPermission("subject") ||
                hasPermission("scripts", "all") ||
                hasPermission("scripts", "delete")) && (
                <td className="text-center td_icon">
                  <span
                    className="clickable m-auto"
                    onClick={() => deleteReport(report.id)}
                  >
                    <IconDelete
                      width={20}
                      height={20}
                      className="icon icon_edit clickable"
                    />
                  </span>
                </td>
              )}
              <td className="text-center td_icon">
                <span
                  className="clickable m-auto"
                  onClick={() => runScript(report.id)}
                >
                  <IconPlay
                    width={20}
                    height={20}
                    className="icon icon_edit clickable"
                  />
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableCustomReports;
