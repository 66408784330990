import React from "react";
import { IconSettings } from "../../../../../assets/images/icons/icons";
import AppActions from "../AppActions/appAction.component";
import InformationTooltip from "../../../../InputInformation/InfoInputs.component";
import { formatDateSeconds } from "../../../../../utilities/utils";
import { MinorVersions } from "../../../../../types";

export type MinorVersionSingleProps = {
  handleSettingsClick: (action: string) => void;
  activeMinorID: number | null;
  environmentType: string;
  platformType: string;
  selectedMajorVersion: number;
  latestMinorVersion: number | null;
  fetchMinorCallBack: () => void;
  minorVersion: MinorVersions;
};

const MinorVersionSingle: React.FC<MinorVersionSingleProps> = ({
  handleSettingsClick,
  activeMinorID,
  environmentType,
  platformType,
  selectedMajorVersion,
  latestMinorVersion,
  fetchMinorCallBack,
  minorVersion
}) => {
  // Minor Version Data
  const {
    minor_version,
    description,
    active,
    successful_installs,
    rollbacks,
    created_at,
    from_version
  } = minorVersion;

  return (
    <tr>
      <th>
        {minor_version}{" "}
        {from_version && (
          <InformationTooltip
            marginTop="-12px"
            position={"top"}
            tooltipText={`From Staging minor version: ${from_version}`}
          />
        )}
      </th>
      <td>{description}</td>
      <td
        className={`${latestMinorVersion === minor_version && "latest_active_version"}`}
      >
        {active ? "Active" : "Inactive"}
      </td>
      <td>{successful_installs}</td>
      <td>{rollbacks}</td>
      <td>{formatDateSeconds(new Date(created_at))}</td>
      <td
        className="text-end icons_container action_cell"
        style={{ verticalAlign: "middle" }}
      >
        <button
          className="text-end large"
          onClick={() => handleSettingsClick(minor_version.toString())}
          disabled={activeMinorID === minor_version}
        >
          <IconSettings />
        </button>
        {activeMinorID === minor_version && (
          <AppActions
            minorID={minor_version}
            closeDropdown={() => handleSettingsClick("close")}
            shown={minor_version === activeMinorID}
            environmentType={environmentType}
            platformType={platformType}
            selectedMajorVersion={selectedMajorVersion}
            outsideDropdown={() => handleSettingsClick("close")}
            status={active}
            description={description}
            latestMinorVersion={latestMinorVersion}
            fetchMinorCallBack={fetchMinorCallBack}
          />
        )}
      </td>
    </tr>
  );
};

export default MinorVersionSingle;
