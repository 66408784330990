import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";

import {
  Bucket,
  BucketNotification,
  BucketParticipant,
  BucketsNotifications,
  BucketsResponse
} from "../types";

// #region typeCheckers

// #region isBucket
export const isBucket = (obj: any): obj is Bucket => {
  const validID = typeof obj.id === "string";
  const validBucket = typeof obj.bucket === "string";
  const validType = typeof obj.type === "string";
  const validPrefix = typeof obj.prefix === "string";

  // Handle optional properties more flexibly
  const validSurveyName =
    !("survey_name" in obj) ||
    typeof obj.survey_name === "string" ||
    obj.survey_name === null;
  const validWelcomeNotification =
    !("welcome_notification" in obj) ||
    (obj.welcome_notification &&
      typeof obj.welcome_notification.id === "string" &&
      typeof obj.welcome_notification.phrase === "string");
  const validReminderNotification =
    !("reminder_notifications" in obj) ||
    (obj.reminder_notifications &&
      typeof obj.reminder_notifications.id === "string" &&
      typeof obj.reminder_notifications.phrase === "string");
  const validNonCompliantNotification =
    !("non_compliant_notification" in obj) ||
    (obj.non_compliant_notification &&
      typeof obj.non_compliant_notification.id === "string" &&
      typeof obj.non_compliant_notification.phrase === "string");

  const allValid =
    validID &&
    validBucket &&
    validType &&
    validPrefix &&
    validSurveyName &&
    validWelcomeNotification &&
    validReminderNotification &&
    validNonCompliantNotification;

  if (!allValid) {
    console.log("Encountered non-bucket object:", obj);
  }

  return allValid;
};
// #endregion isBucket

// #region isBucketParticipant
const isBucketParticipant = (obj: any): obj is BucketParticipant => {
  // console.log(obj, "obj");
  const validID = typeof obj.id === "number";
  const validGSIAnswers =
    (Array.isArray(obj.gsi_answers) &&
      obj.gsi_answers.every(
        (answer: any) =>
          typeof answer.time === "string" ||
          (answer.time === "undefined" && typeof answer.value === "string") ||
          (typeof answer.value === "string" &&
            typeof answer.variable_name === "string")
      )) ||
    obj.gsi_answers === null;
  const validParticipantBuckets =
    Array.isArray(obj.participant_buckets) &&
    obj.participant_buckets.every(
      (bucket: any) =>
        typeof bucket.bucket_id === "string" &&
        typeof bucket.compliance_status === "string"
    );

  const allValid = validID && validGSIAnswers && validParticipantBuckets;

  if (!allValid) {
    // console.log("Encountered non-participant object:", obj);
    if (!validID) {
      console.log("Invalid ID");
    }
    if (!validGSIAnswers) {
      console.log("Invalid GSI Answers");
    }
    if (!validParticipantBuckets) {
      console.log("Invalid Participant Buckets");
    }
  }
  return allValid;
};
// #endregion isBucketParticipant

// #region isBucketNotification
export const isBucketNotification = (
  obj: unknown
): obj is BucketNotification => {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const validPhraseID = "phraseID" in obj && typeof obj.phraseID === "string";
  const validPhrase = "phrase" in obj && typeof obj.phrase === "string";
  const validCategoryID =
    "categoryID" in obj && typeof obj.categoryID === "string";

  return validPhraseID && validPhrase && validCategoryID;
};
// #endregion isBucketNotification

// #region isBucketsNotifications
const isBucketsNotifications = (obj: unknown): obj is BucketsNotifications => {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  const validNotifyDailyDiary =
    "notify-daily-diary" in obj &&
    Array.isArray(obj["notify-daily-diary"]) &&
    obj["notify-daily-diary"].every(isBucketNotification);
  const validNotifyNonCompliantDiary =
    "notify-non_compliant-diary" in obj &&
    Array.isArray(obj["notify-non_compliant-diary"]) &&
    obj["notify-non_compliant-diary"].every(isBucketNotification);
  const validNotifyNonCompliantSingle =
    "notify-non_compliant-single" in obj &&
    Array.isArray(obj["notify-non_compliant-single"]) &&
    obj["notify-non_compliant-single"].every(isBucketNotification);
  const validNotifyWelcomeDiary =
    "notify-welcome-diary" in obj &&
    Array.isArray(obj["notify-welcome-diary"]) &&
    obj["notify-welcome-diary"].every(isBucketNotification);
  const validNotifyWelcomeSingle =
    "notify-welcome-single" in obj &&
    Array.isArray(obj["notify-welcome-single"]) &&
    obj["notify-welcome-single"].every(isBucketNotification);

  return (
    validNotifyDailyDiary &&
    validNotifyNonCompliantDiary &&
    validNotifyNonCompliantSingle &&
    validNotifyWelcomeDiary &&
    validNotifyWelcomeSingle
  );
};
// #endregion isBucketsNotifications

// #endregion typeCheckers

// #region fetchAllBuckets
// Fetching all buckets from the database for a specific client and study and survey
export async function fetchAllBuckets(
  client: string | undefined,
  study: string | undefined
): Promise<BucketsResponse | string> {
  try {
    const response = await apiFetcher<unknown>("/buckets/info/get", "POST", {
      body: {
        clientID: client,
        studyID: study
      }
    });

    // const response = await apiFetcher<any[]>(
    //   "/buckets/participants/get",
    //   "POST",
    //   {
    //     body: {
    //       clientID: client,
    //       studyID: study,
    //     },
    //   }
    // );

    // console.log(response.data, "response");

    if (response.status === 200 && response.data !== null) {
      if (
        response.data &&
        typeof response.data === "object" &&
        "buckets" in response.data &&
        Array.isArray(response.data.buckets) &&
        "participants" in response.data &&
        Array.isArray(response.data.participants)
      ) {
        const buckets = response.data.buckets.filter(isBucket); // Filter out non-bucket items

        // console.log(buckets, "buckets");
        const bucketParticipants = response.data.participants.filter(
          isBucketParticipant // Ensure you have a correct implementation for this function
        ); // This type should represent the structure of participants correctly associated with buckets

        const totalParticipants = response.data.participants.length;

        const result: BucketsResponse = {
          buckets: buckets,
          bucketParticipant: bucketParticipants,
          totalParticipants: totalParticipants
        };

        // console.log(result, "result");

        return result;
      } else {
        console.log("Invalid data structure received");
        return "Invalid data structure received";
      }
    } else {
      console.log("Failed to fetch all buckets");
      return "Failed to fetch all buckets";
    }
  } catch (error) {
    console.log("Failed to fetch all buckets", error);
    return "Failed to fetch all buckets";
  }
}
// #endregion fetchAllBuckets

// function that will send a request to the api to update a buckets complete and grace_days which is sent in a object. We need to send the clientID and the bucketID as well.
// The api will update the bucket
// It is expecting a request like this {"clientID": "1", "bucketID": "1", "compliance": {"completes": 1, "grace_days": 1}}

// #region updateBucketCompliance
export async function updateBucketCompliance(
  client: string | undefined,
  bucket: string | undefined,
  completes: number | undefined,
  grace_days: number | undefined
): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
      rError: string;
    }
> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await apiFetcher<any>(
      "/buckets/compliance/update",
      "POST",
      {
        body: {
          clientID: client,
          bucketID: bucket,
          graceDays: grace_days,
          completes: completes
        }
      }
    );

    Swal.close();

    // Check if the response is valid and return the data
    if (response.status === 200 || response.status === 202) {
      // console.log(response.data, "response.data")
      return {
        rStatus: "success"
      };
    } else {
      console.error("Failed to update the bucket");
      return {
        rStatus: "error",
        rError: "Failed to update the bucket"
      };
    }
  } catch (err) {
    console.error("Failed to update the bucket:", err);
    return {
      rStatus: "error",
      rError: "Failed to update the bucket"
    };
  }
}
// #endregion updateBucketCompliance

// #region fetchBucketNotifications
export async function fetchBucketNotifications(): Promise<
  BucketsNotifications | string
> {
  try {
    const response = await apiFetcher<unknown>(
      "/buckets/phrases/get/all",
      "POST",
      {
        body: {}
      }
    );

    console.log(response.data);

    if (
      response.status === 200 &&
      response.data !== null &&
      response.data !== undefined &&
      typeof response.data === "object" &&
      isBucketsNotifications(response.data)
    ) {
      return response.data;
    } else {
      console.error("Failed to get bucket notifications");
      return "Failed to get bucket notifications";
    }
  } catch (err) {
    console.error("Failed to get bucket notifications", err);
    return "Failed to get bucket notifications";
  }
}
// #endregion fetchBucketNotifications

// #region updateBucketNotification
export async function updateBucketNotification(
  client: string | undefined,
  bucket: string | undefined,
  welcome: string | undefined | null,
  reminder: string | undefined | null,
  nonCompliance: string | undefined | null,
  reminderNotificationTime: string | undefined,
  nonComplianceNotificationTime: string | undefined
): Promise<
  | {
      rStatus: "success";
    }
  | {
      rStatus: "error";
      rError: string;
    }
> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const response = await apiFetcher("/buckets/notifications/update", "POST", {
      body: {
        clientID: client,
        bucketID: bucket,
        welcomeNotification: welcome,
        dailyNotification: reminder,
        nonCompliantNotification: nonCompliance,
        dailyReminderTime: reminderNotificationTime,
        nonComplianceTime: nonComplianceNotificationTime
      }
    });

    Swal.close();

    // Check if the response is valid and return the data
    if (response.status === 200 || response.status === 202) {
      // console.log(response.data, "response.data")
      return {
        rStatus: "success"
      };
    } else {
      console.error("Failed to update the notifications");
      //   return "Failed to update the notifications";
      return {
        rStatus: "error",
        rError: "Failed to update the notifications"
      };
    }
  } catch (err) {
    console.error("Failed to get update the bucket notifications", err);
    // return "Failed to update the bucket notifications";
    return {
      rStatus: "error",
      rError: "Failed to update the bucket notifications"
    };
  }
}
// #endregion updateBucketNotification

// #region addNotificationPhrase
export async function addNotificationPhrase(
  phraseCategoryId: string | undefined,
  phrase: string | undefined
): Promise<any> {
  try {
    console.log(phraseCategoryId, "phraseCategoryId");
    console.log(phrase, "phrase");
    const response = await apiFetcher("/createNotification", "POST", {
      body: {
        phraseCategoryId: phraseCategoryId,
        phrase: phrase
      }
    });

    console.log(response, "response");

    if (response.status === 200 && response.data !== null) {
      return response.data;
    } else {
      console.error("Failed to add notification phrase");
      return "Failed to add notification phrase";
    }
  } catch (err) {
    console.error("Failed to add notification phrase: ", err);
    return "Failed to add notification phrase";
  }
}
// #endregion addNotificationPhrase
