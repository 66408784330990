import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  ChevronLeft,
  ChevronRight
} from "../../../../assets/images/icons/icons";
import { usePermissions } from "../../../../contexts/UserContext";
import {
  fetchParticipantsNotInChannel,
  removeParticipantFromChannel
} from "../../../../models/chat.model";
import {
  ChannelParticipants,
  Country,
  FetchResponseError,
  Language,
  Tags,
  Timezone,
  UnassignedParticipant
} from "../../../../types";
import { isFetchResponseError } from "../../../../utilities/utils";
import CountryFilter from "../../../Tables/Filters/filterCountries.component";
import LanguagesFilter from "../../../Tables/Filters/filterLanguages.component";
import Search from "../../../Tables/Filters/filterSearch.component";
import TagsFilter from "../../../Tables/Filters/filterTags.component";
import ChatStatusFilter from "./Filters/chatFilterStatus.component";
import ChannelParticipantModal from "./channel-participant-modal.component";
import SingleParticipant from "./singleParticipant.component";

import InformationTooltip from "../../../InputInformation/InfoInputs.component";
import "./participants_chat.scss";

export type ParticipantsContainerProps = {
  channelParticipants: ChannelParticipants[];
  setChannelParticipants: React.Dispatch<
    React.SetStateAction<ChannelParticipants[]>
  >;
  selectedChannelType: string;
  channelID: string;
  unreadMessages: number;
  countries: Country[];
  languages: Language[];
  timezones: Timezone[];
  loading: boolean;
  handleSelectParticipant: (participantId: string) => void;
  selectedParticipantId: string | null;
  setDataRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const ParticipantsContainer: React.FC<ParticipantsContainerProps> = ({
  channelParticipants,
  setChannelParticipants,
  selectedChannelType,
  unreadMessages,
  channelID,
  countries,
  languages,
  timezones,
  loading,
  handleSelectParticipant,
  selectedParticipantId,
  setDataRefresh
}) => {
  const [showFilters, setShowFilters] = useState(false); // [state, function
  const [filteredLanguages, setFilteredLanguages] = useState<Language[]>([]);
  const [filteredCountries, setFilteredCountries] = useState<Country[]>([]);
  const [unassignedChannelParticipants, setUnassignedChannelParticipants] =
    useState<UnassignedParticipant[]>([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [filteredChannelParticipants, setFilteredChannelParticipants] =
    useState<ChannelParticipants[]>([]);
  const [
    participantsChannelCountryFilter,
    setParticipantsChannelCountryFilter
  ] = useState<string[]>([]);

  const [
    participantsChannelLanguagesFilter,
    setParticipantsChannelLanguagesFilter
  ] = useState<string[]>([]);
  const [participantsTagsFilter, setParticipantsTagsFilter] = useState<
    string[]
  >([]);
  const [participantsStatusFilter, setParticipantsStatusFilter] = useState<
    string[]
  >([]);
  const [participantsSearch, setParticipantsSearch] = useState<string>("");
  const [participantsTags, setParticipantsTags] = useState<Tags[]>([]);

  // For selecting participants
  const [selectedChannelParticipants, setSelectedChannelParticipants] =
    useState<any[]>([]);

  const [unassignedParticipants, setUnassignedParticipantsLoading] =
    useState<boolean>(true);
  const [errorFetchingData, setErrorFetchingData] =
    useState<FetchResponseError | null>(null);
  const [openParticipantModal, setOpenParticipantsModal] =
    React.useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [busySubmitting, setBusySubmitting] = useState<boolean>(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // Or any other number you prefer

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredChannelParticipants.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  const { clientID, studyID } = useParams(); // Get from URL

  const { hasPermission } = usePermissions();

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  const handleRemoveParticipant = useCallback(
    (participantID: string) => {
      // Filter out the message to delete
      const participantToRemove = channelParticipants.filter(
        (participant) => participant.id !== participantID
      );
      setChannelParticipants(participantToRemove);

      // Add any other logic you need for deleting the message, like API calls
    },
    [channelParticipants, setChannelParticipants]
  );

  const handleBulkParticipantRemove = async () => {
    try {
      if (
        selectedChannelParticipants.length > 0 &&
        channelID &&
        clientID &&
        studyID
      ) {
        const result = await removeParticipantFromChannel(
          clientID,
          channelID,
          selectedChannelParticipants, // Wrapping participantId in an array
          studyID
        );
        console.log(result);
        if (result) {
          // setResponse(true);
          console.log(result.message);
          setMessage(result.message);
          setSelectedChannelParticipants([]);
          // fetchAllStudyParticipantsNotInSurvey();
        }
        //
        setBusySubmitting(false);

        // console.log(result, "result");

        if (result.rStatus !== "success") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: result.rMessage,
            confirmButtonColor: "#3085d6"
          });
        } else {
          const bulkRemove = await Swal.fire({
            icon: "success",
            title: "Success",
            text: result.rMessage,
            confirmButtonColor: "#3085d6"
          });

          // if (bulkRemove.isConfirmed) {
          // 	setDataRefresh(true);
          // }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select at least one participant",
          confirmButtonColor: "#3085d6"
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleParticipantCheckboxChange = useCallback(
    (participantId: string) => {
      // console.log(channelParticipants, "channelParticipants");
      setSelectedChannelParticipants((prevSelected) => {
        const isAlreadySelected = prevSelected.includes(participantId);
        let newSelected;

        if (isAlreadySelected) {
          // If the participantId is already selected, remove it from the array
          newSelected = prevSelected.filter((id) => id !== participantId);
        } else {
          // If the participantId is not selected, add it to the array
          newSelected = [...prevSelected, participantId];
        }

        // Log the new state here
        // console.log(newSelected);

        return newSelected;
      });
    },
    [selectedChannelParticipants, setSelectedChannelParticipants]
  );

  const handleSelectAllCheckboxChange = useCallback(() => {
    setSelectedChannelParticipants((prevSelected) => {
      const allParticipantsSelected =
        prevSelected.length === filteredChannelParticipants.length;

      let newBulkSelected: any[];

      if (allParticipantsSelected) {
        // If all participants are already selected, remove them from the array
        newBulkSelected = [];
      } else {
        // If not all participants are selected, add them to the array
        newBulkSelected = filteredChannelParticipants.map((p) => p.id);
      }

      // Log the new state here
      // console.log(newBulkSelected, 'all participants');

      return newBulkSelected;
    });
  }, [filteredChannelParticipants]);

  const toggleParticipantFlag = (participantId: string) => {
    setChannelParticipants((prevParticipants) =>
      prevParticipants.map((participant) =>
        participant.id === participantId
          ? { ...participant, flagged: !participant.flagged }
          : participant
      )
    );
  };

  const handleShowParticipants = async () => {
    setOpenParticipantsModal(true);
    fetchUnassignedChannelParticipants();
  };

  const handleCloseParticipantModal = () => {
    setOpenParticipantsModal(false);
  };

  const handleShowFilters = () => {
    setShowFilters(!showFilters);
  };

  const fetchUnassignedChannelParticipants = async () => {
    setUnassignedParticipantsLoading(true); // Indicate the loading process has started
    setUnassignedChannelParticipants([]);
    try {
      if (clientID && channelID && studyID) {
        const response = await fetchParticipantsNotInChannel(
          clientID, // Which client ID
          channelID, // Type of participants
          studyID
        );

        if (isFetchResponseError(response)) {
          console.error("Error Message:", response.errorMessage);
          setErrorFetchingData(response);
        } else {
          setErrorFetchingData(null);

          // Ensure that both response and channelParticipants are arrays
          if (Array.isArray(response)) {
            setUnassignedChannelParticipants(response);
            // This log statement may not reflect the updated state immediately
          } else {
            // Handle the case where response or channelParticipants are not arrays
            console.log("Response or channelParticipants is not an array.");
          }
        }
      } else {
        console.log("Missing required data for fetching participants");
      }
    } catch (err: any) {
      console.error("An error occurred while fetching participants:", err);
      setErrorFetchingData(err.toString());
    } finally {
      setUnassignedParticipantsLoading(false); // Indicate the loading process has ended
    }
  };

  useEffect(() => {
    // Filter participants based on filters
    let filteredParticipants = channelParticipants;
    // Filter by tags
    if (participantsTagsFilter.length > 0) {
      filteredParticipants = filteredParticipants?.filter((participant: any) =>
        participantsTagsFilter.some((tag) =>
          participant.participant_tags?.includes(tag)
        )
      );
    }

    // Filter by languages
    if (participantsChannelLanguagesFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        participantsChannelLanguagesFilter.some((language) =>
          participant.participant_lang_iso.includes(language)
        )
      );
    }

    // Filter by countries
    if (participantsChannelCountryFilter.length > 0) {
      filteredParticipants = filteredParticipants?.filter((participant) =>
        participantsChannelCountryFilter.some((country) =>
          participant.participant_country_iso.includes(country)
        )
      );
    }

    if (participantsStatusFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) => {
        return participantsStatusFilter.some((status) => {
          switch (status) {
            case "unread":
              return participant.unread > 0;
            case "flagged":
              return participant.flagged === true;
            // case 'unanswered':
            // 	// Assuming there is a property to indicate 'unanswered' status
            // 	return participant.unanswered === 1;
            default:
              return true;
          }
        });
      });
    }

    // Filter by search
    if (participantsSearch !== "") {
      const trimmedSearch = participantsSearch.trim().toLowerCase();
      filteredParticipants = filteredParticipants.filter(
        (participant) =>
          participant.sender_name?.toLowerCase().includes(trimmedSearch) ||
          participant.id === trimmedSearch ||
          participant.external_id === trimmedSearch ||
          participant.participant_email?.toLowerCase().includes(trimmedSearch)
      );
    }
    // Set filtered participants
    setFilteredChannelParticipants(filteredParticipants);
  }, [
    channelParticipants,
    participantsTagsFilter,
    participantsChannelLanguagesFilter,
    participantsChannelCountryFilter,
    participantsStatusFilter,
    participantsSearch
  ]);

  useEffect(() => {
    if (Array.isArray(channelParticipants)) {
      // get all unique participants Tags
      const pTags = [
        ...new Set(
          channelParticipants
            .map((p) => p.participant_tags)
            .filter((tags: any[]): tags is string[] => Boolean(tags))
            .flat()
        )
      ].sort();

      setParticipantsTags(
        pTags.map((tag) => {
          return { id: tag, name: tag };
        })
      );
      // get all unique participants languages codes
      const pLanguages = [
        ...new Set(
          channelParticipants.map((p) => p.participant_lang_iso).filter(Boolean)
        )
      ];

      // filter the languages based on the participant languages
      const filteredLanguages = languages.filter((lang) =>
        pLanguages.includes(lang.iso_code)
      );

      // get all unique participants countries codes
      const pCountries = [
        ...new Set(
          channelParticipants
            .map((p) => p.participant_country_iso)
            .filter(Boolean)
        )
      ];

      // filter the countries based on the participant countries
      const filteredCountries = countries.filter((country) =>
        pCountries.includes(country.iso_code)
      );

      // set the filtered languages and countries
      setFilteredLanguages(filteredLanguages);
      setFilteredCountries(filteredCountries);
    }
  }, [channelParticipants]);

  useEffect(() => {
    if (
      indexOfFirstItem >= filteredChannelParticipants.length &&
      currentPage > 1
    ) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  }, [filteredChannelParticipants, currentPage, itemsPerPage]);

  useEffect(() => {
    if (channelID) {
      setSelectedChannelParticipants([]);
      setCurrentPage(1);
    }
  }, [channelID]);

  return (
    <>
      <div className="participants_container_chat card">
        <div className="top_bar card-header">
          <div>
            Participants{" "}
            <small className="small-text" style={{ color: "green" }}>
              {unreadMessages}
            </small>
            <InformationTooltip
              marginTop="-12px"
              position="bottom"
              tooltipText="This is the amount of unread messages in the channel."
            />
          </div>
          <div>
            {(hasPermission("subject") ||
              hasPermission("chat", "all") ||
              hasPermission("chat", "write")) && (
              <button
                className="add_button"
                onClick={() => {
                  handleShowParticipants();
                }}
              >
                Add
              </button>
            )}

            <button
              className="filter_select_button"
              onClick={() => {
                handleShowFilters();
              }}
            >
              Filter/Select
            </button>
          </div>
          {/* <div className={`create_channel `}>+</div> */}
        </div>
        {showFilters && (
          <div className="participants_filters_container">
            <LanguagesFilter
              jsonlanguagesData={filteredLanguages}
              onLanguagesFilterChange={setParticipantsChannelLanguagesFilter}
              filtersCleared={!filterApplied}
              location={"channel-participant"}
            />
            <CountryFilter
              jsonCountriesData={filteredCountries}
              onCountryFilterChange={setParticipantsChannelCountryFilter}
              filtersCleared={!filterApplied}
              location={"channel-participant"}
            />
            <TagsFilter
              jsonTagsData={participantsTags}
              onTagsFilterChange={setParticipantsTagsFilter}
              filtersCleared={!filterApplied}
              location={"channel-participant"}
            />
            <ChatStatusFilter
              onChatStatusFilterChange={setParticipantsStatusFilter}
              filtersCleared={!filterApplied}
              filterName="Status"
            />

            <Search
              onSearchChange={setParticipantsSearch}
              filtersCleared={!filterApplied}
              // location={"modal"}
            />
          </div>
        )}
        {(hasPermission("subject") ||
          hasPermission("chat", "all") ||
          hasPermission("chat", "delete")) &&
          selectedChannelParticipants.length > 1 && (
            <div className="bulk-participant-container">
              <div className="participant-bulk-checkbox">
                <input
                  type="checkbox"
                  name=""
                  id=""
                  onChange={handleSelectAllCheckboxChange}
                  checked={
                    selectedChannelParticipants.length ===
                      channelParticipants.length &&
                    channelParticipants.length > 0
                  }
                />
                {selectedChannelParticipants.length} selected
              </div>
              <button
                className="btn btn-sm btn-danger"
                onClick={handleBulkParticipantRemove}
              >
                Remove Participants
              </button>
            </div>
          )}
        <div
          className={`participant_singles_container ${
            showFilters ? "participant_filter_visible" : ""
          }`}
        >
          {/* {filteredChannelParticipants.length === 0 ? (
						<div className="d-flex justify-content-center align-items-center py-1">
							<small className="w-75 text-center">No results found. Please adjust your filters.</small>
						</div>
					) : (
						<> */}
          {loading ? (
            <div className="d-flex justify-content-center custom_spinner_container pt-2">
              <div
                className="spinner-border"
                style={{ width: "20px", height: "20px" }}
                role="status"
              ></div>
            </div>
          ) : (
            <>
              {filteredChannelParticipants &&
              filteredChannelParticipants.length > 0 ? (
                currentItems.map((participant) => (
                  <SingleParticipant
                    key={participant.id}
                    participantId={participant.id}
                    participantName={participant.sender_name}
                    participantLanguage={participant.participant_lang_iso}
                    participantFlagged={participant.flagged}
                    participantUnread={participant.unread}
                    isSelected={selectedParticipantId === participant.id}
                    channelID={channelID}
                    selectedChannelType={selectedChannelType}
                    handleSelectParticipant={handleSelectParticipant}
                    onSelectParticipants={handleParticipantCheckboxChange}
                    checkboxSelectedParticipants={selectedChannelParticipants}
                    onParticipantRemove={handleRemoveParticipant}
                    onToggleFlag={toggleParticipantFlag}
                    countries={countries}
                    languages={languages}
                    timezones={timezones}
                    // dataRefresh={setDataRefresh}
                  />
                ))
              ) : (
                <div className="d-flex justify-content-center pt-2">
                  <small>No participants available</small>
                </div>
              )}
              {channelParticipants.length > 20 && (
                <div className="d-flex justify-content-center participant-channel-pagination">
                  <button
                    className="btn btn-link"
                    onClick={handlePrevious}
                    disabled={currentPage === 1}
                  >
                    <ChevronLeft />
                  </button>
                  <button
                    className="btn btn-link"
                    onClick={handleNext}
                    disabled={
                      currentPage * itemsPerPage >=
                      filteredChannelParticipants.length
                    }
                  >
                    <ChevronRight />
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      {openParticipantModal && (
        <ChannelParticipantModal
          closeModal={handleCloseParticipantModal}
          shown={openParticipantModal}
          unassignedChannelParticipants={unassignedChannelParticipants}
          loadingParticipants={unassignedParticipants}
          countries={countries}
          languages={languages}
          channelID={channelID}
          clientID={clientID}
          studyID={studyID}
          // setDataRefresh={setDataRefresh}
        />
      )}
    </>
  );
};

export default ParticipantsContainer;
