import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { APIResponseWorker } from "../types";

// #region isAPIResponseWorker
export function isAPIResponseWorker(obj: any): obj is APIResponseWorker {
  const validID = typeof obj.jobID === "number";
  const validStatusType = typeof obj.status === "string";
  const validStatusValue =
    validStatusType &&
    (obj.status === "completed" ||
      obj.status === "queued" ||
      obj.status === "error" ||
      obj.status === "notFound");

  const AllValid: boolean = validID && validStatusValue;

  if (!AllValid) {
    let errors = [];
    console.log(obj);
    if (!validID) {
      if (!obj.hasOwnProperty("jobID")) {
        errors.push("missing jobID");
      } else {
        errors.push(`Invalid type for jobID`);
      }
    }
    if (!validStatusType) {
      if (!obj.hasOwnProperty("status")) {
        errors.push("missing status");
      } else {
        errors.push(`Invalid type for status`);
      }
    }
    if (!validStatusValue) {
      errors.push(`Invalid value for status`);
    }

    Swal.fire({
      icon: "error",
      title: `Invalid Worker`,
      html: errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isAPIResponseWorker

// #region isAPIResponseWorkerStatus
export function isAPIResponseWorkerStatus(obj: any): obj is APIResponseWorker {
  //   status: "busy" | "pending" | "completed" | "failed";
  const validStatusType = typeof obj.status === "string";
  const validStatusValue =
    validStatusType &&
    (obj.status === "busy" ||
      obj.status === "pending" ||
      obj.status === "completed" ||
      obj.status === "failed");

  const AllValid: boolean = validStatusValue;

  if (!AllValid) {
    console.log(obj);
    let errors = [];
    if (!validStatusType) {
      if (!obj.hasOwnProperty("status")) {
        errors.push("missing status");
      } else {
        console.log("Invalid status type");
        errors.push(`Invalid type for status`);
      }
    }
    if (validStatusType && !validStatusValue) {
      console.log("Invalid status value");
      errors.push(`Invalid value for status`);
    }

    const displayMessage =
      `<strong>Please contact support</strong><br>` + errors.join("<br>");

    Swal.fire({
      icon: "error",
      title: `Invalid Worker Status`,
      html: displayMessage,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  console.log(AllValid, "AllValid");
  return AllValid;
}
// #endregion isAPIResponseWorkerStatus

// #region checkAndFetchWorkerStatus
export async function checkAndFetchWorkerStatus(): Promise<
  | APIResponseWorker["status"]
  | "noWorkerFoundInLocal"
  | "noWorkerFoundInDatabase"
  | "contact"
  | false
> {
  // Check worker status from local storage
  const workerID: string | null = localStorage.getItem("workerID");
  if (!workerID) {
    return "noWorkerFoundInLocal";
  }

  if (
    typeof workerID === "undefined" ||
    workerID === null ||
    workerID === "" ||
    workerID === "undefined"
  ) {
    // Should never happen
    localStorage.removeItem("workerID");
    console.log("Missing workerID in local storage");
    return false;
  }

  if (workerID === "testSuccess") {
    return "completed";
  }

  // Make an API call to get the worker's status
  const response = await apiFetcher<any>("/jobStatus", "POST", {
    body: {
      jobID: workerID
    }
  });

  // console.log(response, "response");

  if (response.status === 200 && response.data !== null) {
    if (!isAPIResponseWorkerStatus(response.data)) {
      console.log("Invalid data received");
      // remove workerID from local storage as it is no longer needed
      localStorage.removeItem("workerID");
      return false;
    }
    return response.data.status;
  }

  if (response.status === 400) {
    // remove workerID from local storage as it is no longer needed

    return "contact";
  }

  if (response.status === 404) {
    // remove workerID from local storage as it is no longer needed

    return "noWorkerFoundInDatabase";
  }

  console.log("Failed to fetch worker status");
  return false;
}
// #endregion checkAndFetchWorkerStatus
