import Swal from "sweetalert2";
import { apiFetcher, tokenFetcher } from "../services/API.service";
// import { FetchResponse } from "../services/API.service"

import { FetchResponse, JWT, KeyUri } from "../types";

// We need to define the type of the data we expect to receive from the API
export type Tokens = {
  refreshTokenResponse: {
    refreshToken: string;
    authToken: string;
  };
};

export type MfaTokens = {
  mfaConfirmResponse: {
    refreshToken: string;
    authToken: string;
  };
};

// #region fetchJwt
// Give BE RefreshToken, get back JWT (R + Auth)
export async function fetchJwt(
  refreshToken: JWT
): Promise<FetchResponse<Tokens>> {
  const response = await tokenFetcher<Tokens>("/auth/refreshToken", "POST", {
    body: refreshToken
  });
  if (response.status === 200) {
    // console.log("Refresh token successful");
    return response;
  } else {
    console.log("Refresh token failed");
    window.location.href = "/login";
    return response;
  }
}
// #endregion fetchJwt

// #region requestEmail
// Give BE encoded Email, get back JWT (R + Auth)
export async function requestEmail(encodedEmail: string): Promise<any> {
  console.log("HIT REQUEST EMAIL");
  const response = await tokenFetcher<any>("/auth/ml", "POST", {
    body: { encodedEmail: encodedEmail }
  });
  console.log(response, "RESPONSE");
  if (response.status === 202) {
    // console.log("Email sent");
    return response.status;
  } else if (response.status === 400) {
    console.log("Contact admin");
    return response.status;
  } else if (response.status === 404) {
    console.log("Email not sent");
    return response.status;
  } else {
    console.log("Email not sent");
    return response.status;
  }
}
// #endregion requestEmail

// #region enableMfa
// Give BE encoded login details (Email, password), get back KeyUri (QR Scannable)
export async function enableMfa(
  encodedMFAEnableString: string
): Promise<FetchResponse<KeyUri>> {
  console.log("HIT REQUEST ENABLE MFA");
  const response = await apiFetcher<KeyUri>("/auth/mfa/enable", "POST", {
    body: { encodedMFAEnableString }
  });
  console.log(response);
  if (response.status === 200) {
    console.log("Request sent");
    return response;
  } else if (response.status === 409) {
    Swal.fire({
      icon: "error",
      title: "MFA already enabled",
      text: "Please contact support"
    });
    return response;
  } else {
    console.log("Request not sent");
    return response;
  }
}
// #endregion enableMfa

// #region mfaLogin
// Give BE encoded Email, Pw, Code, get back JWT (R + Auth)
export async function mfaLogin(
  encodedMFAConfirmString: string
): Promise<FetchResponse<MfaTokens>> {
  console.log("HIT REQUEST CONFIRM MFA");
  console.log(encodedMFAConfirmString, "ENCODED STRING");
  const response = await tokenFetcher<MfaTokens>("/auth/mfa/login", "POST", {
    body: { encodedMFAConfirmString: encodedMFAConfirmString }
  });
  console.log(response, "RESPONSE");
  if (response.status === 200) {
    console.log("MFA CONFIRM sent");
    return response;
  } else {
    console.log("MFA CONFIRM Failed");
    return response;
  }
}
// #endregion mfaLogin

// #region resetMFA
// Reset MFA: Give BE encoded login details (Email, password), get back KeyUri (QR Scannable)
export async function resetMFA(encodedString: string): Promise<string> {
  console.log("HIT REQUEST RESET MFA");
  const response = await apiFetcher<any>("/qrCodeReset", "POST", {
    body: { encodedString }
  });
  console.log(response);
  console.log(encodedString);
  if (response.status === 202) {
    console.log("Request sent");
    console.log(response);
    return "success";
  } else if (response.status === 401) {
    return "invalid";
  } else {
    console.log("Request not sent");
    return "error";
  }
}
// #endregion resetMFA
