import { ChangeEvent, useEffect, useMemo, useState } from "react";
import MDILogo from "../../assets/images/logo/mdi-logo-final.webp";
import TwoFactor from "../../components/MFA/two-factor.component";
import MagicLink from "../../components/MagicLink/magic-link.component";
import ToggleSwitch from "../../components/ToggleSwitch/toggleSwitch.component";
import { getBuildDate } from "../../utilities/utils";
import "./login.scss";

function LoginPage() {
  //When a user initially comes to the LoginPage it will log them out and flush the dns and service worker cache
  useEffect(() => {
    // Log the user out
    // Flush the service worker cache
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (let registration of registrations) {
          registration.unregister();
        }
      });
    }
  }, []);

  const [isMagicLink, setIsMagicLink] = useState(false); // State to toggle

  // Function to toggle between MagicLink and MFA components
  const handleToggleSwitch = (checked: boolean) => {
    setIsMagicLink(checked);
    console.log("isMagicLink: ", isMagicLink);
  };

  // Checks if magic link is sent
  const [magicLinkSent, setMagicLinkSent] = useState(false);

  // Persisting Email between MagicLink and MFA
  const [email, setEmail] = useState("");

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  // useEffect that clears local storage on login page
  useEffect(() => {
    localStorage.removeItem("pending_user_email");
    localStorage.removeItem("refresh-token");
    localStorage.clear();
    // clear session storage
    sessionStorage.clear();
  }, []);

  const buildDate = useMemo(() => getBuildDate(), []);

  return (
    <div id={`page_login`}>
      <img id="mdi_logo" src={MDILogo} alt="MDI Logo" />
      <div className="container d-flex justify-content-center align-items-center vh-100 p-3">
        <div className="login_container d-flex justify-content-center">
          {/* This card body css can be checked with #login_card_mfa & #login_card_magic */}
          <div
            id={`login_card_${isMagicLink ? "mfa" : "magic"}`}
            className={`card shadow-lg `}
          >
            <div className="card-body">
              {!magicLinkSent ? (
                <>
                  <div className={`text-center`}>
                    <h3 className="headerSpacing card-title text-center align-items-center">
                      MDI RMS V2
                    </h3>
                  </div>{" "}
                  <div className="d-grid gap-2 mb-4">
                    <div id="login_toggler_inner">
                      <div className="btn-group" role="group">
                        <ToggleSwitch
                          id="toggle_login"
                          checked={isMagicLink}
                          onChange={handleToggleSwitch}
                          optionLabels={["MFA", "Magic Link"]}
                          disabled={false}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {!isMagicLink ? (
                <MagicLink
                  handleEmailChange={handleEmailChange}
                  email={email}
                  setEmail={setEmail}
                  setMagicLinkSent={setMagicLinkSent}
                  magicLinkSent={magicLinkSent}
                />
              ) : (
                <TwoFactor
                  handleEmailChange={handleEmailChange}
                  email={email}
                  setEmail={setEmail}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {buildDate && (
        <div className="position-fixed bottom-0 end-0 z-1 text-white pe-1 opacity-75">
          Build Date: {buildDate}
        </div>
      )}
    </div>
  );
}

export default LoginPage;
