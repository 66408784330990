export { ReactComponent as IconGoogle } from "./Google_Translate_logo.svg";
export { ReactComponent as IconContacts } from "./address-book-solid.svg";
export { ReactComponent as IconAdmin } from "./admin-solid.svg";
export { ReactComponent as IconArrowDown } from "./arrow-down-solid.svg";
export { ReactComponent as IconUploadAlt } from "./arrow-up-from-bracket-solid.svg";
export { ReactComponent as IconArrowUp } from "./arrow-up-solid.svg";
export { ReactComponent as IconBan } from "./ban-solid.svg";
export { ReactComponent as IconBell } from "./bell-solid.svg";
export { ReactComponent as IconBroom } from "./broom-wide-solid.svg";
export { ReactComponent as IconBullhorn } from "./bullhorn-solid.svg";
export { ReactComponent as IconSideArrow } from "./caret-right-solid.svg";
export { ReactComponent as IconChartLine } from "./chart-line-solid.svg";
export { ReactComponent as IconCheck } from "./check-solid.svg";
export { ReactComponent as ChevronLeft } from "./chevron-left-solid.svg";
export { ReactComponent as ChevronRight } from "./chevron-right-solid.svg";
export { ReactComponent as IconCircleCheck } from "./circle-check-solid.svg";
export {
  ReactComponent as IconInfo,
  ReactComponent as IconResources
} from "./circle-info-solid.svg";
export { ReactComponent as IconCirclePause } from "./circle-pause-solid.svg";
export { ReactComponent as IconCirclePlay } from "./circle-play-solid.svg";
export { ReactComponent as IconHelp } from "./circle-question-regular.svg";
export { ReactComponent as IconClock } from "./clock-solid.svg";
export { ReactComponent as IconMessages } from "./comments-solid.svg";
export { ReactComponent as IconCopy } from "./copy-solid.svg";
export { ReactComponent as IconCustomReports } from "./custom-reports.svg";
export { ReactComponent as IconDb } from "./database-solid.svg";
export { ReactComponent as IconDownload } from "./download-solid.svg";
export { ReactComponent as IconEmail } from "./envelope-solid.svg";
export { ReactComponent as IconEyeDropper } from "./eye-dropper-solid.svg";
export { ReactComponent as IconInvisible } from "./eye-slash-solid.svg";
export { ReactComponent as IconVisible } from "./eye-solid.svg";
export { ReactComponent as IconEmoji } from "./face-smile-regular.svg";
export { ReactComponent as IconExcel } from "./file-excel-solid.svg";
export { ReactComponent as IconExport } from "./file-export-solid.svg";
export { ReactComponent as IconReports } from "./file-lines-solid.svg";
export { ReactComponent as IconUnflag } from "./flag-regular.svg";
export { ReactComponent as IconFlag } from "./flag-solid.svg";
export { ReactComponent as IconSettings } from "./gear-solid.svg";
export { ReactComponent as IconKeyboard } from "./keyboard-regular.svg";
export { ReactComponent as IconLanguages } from "./language-solid.svg";
export { ReactComponent as IconLeads } from "./leads_icon.svg";
export { ReactComponent as IconLocationPin } from "./location-pin-solid.svg";
export { ReactComponent as IconMobile } from "./mobile-screen-button-solid.svg";
export { ReactComponent as IconMoon } from "./moon-solid.svg";
export { ReactComponent as IconMultiReports } from "./multiple-files.svg";
export { ReactComponent as IconSend } from "./paper-plane-solid.svg";
export { ReactComponent as IconStudies } from "./paste-solid.svg";
export { ReactComponent as IconEdit } from "./pen-to-square-solid.svg";
export { ReactComponent as IconPlay } from "./play-solid.svg";
export { ReactComponent as IconAdd } from "./plus-solid.svg";
export { ReactComponent as IconReceipt } from "./receipt-solid.svg";
export { ReactComponent as IconAutomation } from "./retweet-solid.svg";
export { ReactComponent as IconLogout } from "./right-to-bracket-solid.svg";
export { ReactComponent as IconRobot } from "./robot-solid.svg";
export { ReactComponent as IconRewards } from "./star-solid.svg";
export { ReactComponent as IconClient } from "./suitcase-solid.svg";
export { ReactComponent as IconSun } from "./sun.svg";
export { ReactComponent as IconSwitch } from "./switch-horizontal.svg";
export { ReactComponent as IconTags } from "./tags-solid.svg";
export { ReactComponent as IconPin } from "./thumbtack-solid.svg";
export { ReactComponent as IconDelete } from "./trash-can-solid.svg";
export { ReactComponent as IconUpload } from "./upload-solid.svg";
export { ReactComponent as IconProCheck } from "./user-check-solid.svg";
export { ReactComponent as IconProfile } from "./user-koala-solid.svg";
export { ReactComponent as IconParticipants } from "./users-solid.svg";
export { ReactComponent as IconWand } from "./wand-magic-sparkles-solid.svg";
export { ReactComponent as IconX } from "./x-solid.svg";
