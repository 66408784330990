// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.reports_builder .form-control:focus,
.reports_builder .form-check-input:focus {
  box-shadow: none !important;
}
.reports_builder .form-check-input:checked {
  background-color: #39a7e0;
  border-color: #39a7e0;
}
.reports_builder .dropdown_timing .dropdown-items {
  max-height: 300px;
  overflow-y: auto;
  -webkit-user-select: none;
          user-select: none;
}
.reports_builder .dropdown_timing .dropdown-items .selected {
  background-color: #dbdbdb;
}
.reports_builder .btn-success {
  background-color: #93e200;
}
.reports_builder .btn-success:hover {
  background-color: #b4e756;
}
.reports_builder .btn-success:focus {
  background-color: #b4e756;
}
.reports_builder .btn-success:active {
  background-color: #b4e756;
}
.reports_builder .timing_span {
  display: block;
  background-color: #58c6fd;
  padding: 2px 5px;
  border-radius: 5px;
  font-size: small;
  -webkit-user-select: none;
          user-select: none;
  color: white;
}
.reports_builder .code_editor {
  outline: 1px solid #d1d1d1;
}
.reports_builder .code_editor .code_editor_theme_button {
  position: absolute;
  top: 5px;
  right: 5px;
  opacity: 0.5;
}
.reports_builder .code_editor .code_editor_theme_button:hover {
  opacity: 1;
}
.reports_builder .code_editor .code_editor_theme_button .icon {
  height: 20px;
  width: 20px;
}
.reports_builder .code_editor .code_editor_theme_button .icon.icon_moon {
  fill: black;
}
.reports_builder .code_editor .code_editor_theme_button .icon.icon_sun {
  fill: white;
  color: white;
  stroke: white;
}`, "",{"version":3,"sources":["webpack://./src/components/ReportsBuilder/reportsBuilder.scss"],"names":[],"mappings":"AAGI;;EACE,2BAAA;AADN;AAKE;EACE,yBAAA;EACA,qBAAA;AAHJ;AAME;EACE,iBAAA;EACA,gBAAA;EACA,yBAAA;UAAA,iBAAA;AAJJ;AAMI;EACE,yBAAA;AAJN;AAQE;EACE,yBAAA;AANJ;AAQI;EACE,yBAAA;AANN;AASI;EACE,yBAAA;AAPN;AAUI;EACE,yBAAA;AARN;AAYE;EACE,cAAA;EACA,yBAAA;EACA,gBAAA;EACA,kBAAA;EACA,gBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,YAAA;AAVJ;AAaE;EACE,0BAAA;AAXJ;AAaI;EACE,kBAAA;EACA,QAAA;EACA,UAAA;EACA,YAAA;AAXN;AAaM;EACE,UAAA;AAXR;AAcM;EACE,YAAA;EACA,WAAA;AAZR;AAcQ;EACE,WAAA;AAZV;AAeQ;EACE,WAAA;EACA,YAAA;EACA,aAAA;AAbV","sourcesContent":[".reports_builder {\n  .form-control,\n  .form-check-input {\n    &:focus {\n      box-shadow: none !important;\n    }\n  }\n\n  .form-check-input:checked {\n    background-color: #39a7e0;\n    border-color: #39a7e0;\n  }\n\n  .dropdown_timing .dropdown-items {\n    max-height: 300px;\n    overflow-y: auto;\n    user-select: none;\n\n    .selected {\n      background-color: #dbdbdb;\n    }\n  }\n\n  .btn-success {\n    background-color: #93e200;\n\n    &:hover {\n      background-color: #b4e756;\n    }\n\n    &:focus {\n      background-color: #b4e756;\n    }\n\n    &:active {\n      background-color: #b4e756;\n    }\n  }\n\n  .timing_span {\n    display: block;\n    background-color: #58c6fd;\n    padding: 2px 5px;\n    border-radius: 5px;\n    font-size: small;\n    user-select: none;\n    color: white;\n  }\n\n  .code_editor {\n    outline: 1px solid #d1d1d1;\n\n    .code_editor_theme_button {\n      position: absolute;\n      top: 5px;\n      right: 5px;\n      opacity: 0.5;\n\n      &:hover {\n        opacity: 1;\n      }\n\n      .icon {\n        height: 20px;\n        width: 20px;\n\n        &.icon_moon {\n          fill: black;\n        }\n\n        &.icon_sun {\n          fill: white;\n          color: white;\n          stroke: white;\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
