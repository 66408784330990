//Page to display the clients in cards

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ErrorPage from "../../pages/Error/error.page";
import { Client, SidebarProps } from "../../types";
// pages

import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { fetchSingleClientByID } from "../../models/client.model";
import CustomReportsPage from "../../pages/CustomReports/customReports.page";
import ReportsPage from "../../pages/Reports/reports.page";
import GroupsPage from "../../pages/SingleClient/Groups/groups.page";
import ImportPage from "../../pages/SingleClient/Import/import.page";
import LogsPage from "../../pages/SingleClient/Logs/logs.page";
import ParticipantsPage from "../../pages/SingleClient/Participants/participants.page";
import RewardsPage from "../../pages/SingleClient/Rewards/rewards.page";
import SettingsPage from "../../pages/SingleClient/Settings/settings.page";
import TagsPage from "../../pages/SingleClient/Tags/tags.page";

interface IconPageHandlerProps extends SidebarProps {}

const IconPageHandler: React.FC<IconPageHandlerProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  page
}) => {
  const { pageHandler, clientID } = useParams();

  // set foundClient to null if no client is found or if clientID is undefined
  // foundClient is type Client | undefined
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  //selectedClient is set based on the clientID in the url

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  useEffect(() => {
    const fetchClientsData = async () => {
      try {
        const jsonData = await fetchSingleClientByID(clientID as string);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "7b3abb9ab2b51c19b1840ea1250162ee"
          ]);
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setSelectedClient(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "268a98473e8f2729e4ace76b3bc54770"
        ]);
      }
    };

    const promises = [fetchClientsData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "daba6c17d04b1049c27e4234d705d675"
        ]);
      });
  }, [clientID]);

  const clientLogo = selectedClient?.icon_url;
  const clientNameString = selectedClient?.name;
  // console.log("foundClient: ", foundClient);
  return (
    <>
      {loading ? (
        <>
          <SidebarComponent
            page={page}
            sidebarPinned={sidebarPinned}
            onSidebarPinChange={onSidebarPinChange}
            currentClient={clientID}
            currentClientLogo={undefined}
          />
          <div className="d-flex justify-content-center custom_spinner_container full_page">
            <div className="spinner-border" role="status"></div>
          </div>
        </>
      ) : loadingError ? (
        <ErrorPage
          errorCode="500"
          error="Error fetching data"
          debugCode={loadingErrorText}
        />
      ) : selectedClient !== null && selectedClient !== undefined ? (
        /* Load the correct page based on pageHandler */
        (() => {
          const commonProps = {
            sidebarPinned,
            onSidebarPinChange,
            page,
            currentClientLogo: clientLogo,
            currentClientName: clientNameString
          };

          switch (pageHandler) {
            case "participants":
              return <ParticipantsPage {...commonProps} />;
            case "rewards":
              return <RewardsPage {...commonProps} />;
            case "import":
              return <ImportPage {...commonProps} />;
            case "groups":
              return <GroupsPage {...commonProps} />;
            case "logs":
              return <LogsPage {...commonProps} />;
            case "tags":
              return <TagsPage {...commonProps} />;
            case "settings":
              return <SettingsPage {...commonProps} />;
            case "reports":
              return <ReportsPage {...commonProps} />;
            case "custom-reports":
              return <CustomReportsPage {...commonProps} />;
            default:
              return <ErrorPage debugCode={loadingErrorText} />;
          }
        })()
      ) : (
        <ErrorPage
          errorCode="404"
          error="No Client found with matching ID"
          debugCode={loadingErrorText}
        />
      )}
    </>
  );
};

export default IconPageHandler;
