import { IconDelete, IconSend } from "../../../../assets/images/icons/icons";
import ChatTabs from "./chatTabs.component";

import EmojiPicker from "@emoji-mart/react";
import React, { useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import ReactQuill from "react-quill";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { sendMessageInChannel } from "../../../../models/chat.model";

import "react-quill/dist/quill.snow.css";
import { useSocket } from "../../../../contexts/SocketContext";
import {
  ChannelMessages,
  MessageData,
  NewMessage,
  ResourceData
} from "../../../../types";
import { resizeImageToImage } from "../../../../utilities/utils";
import ChatResourcesGrid from "./ChatInputModule/chatResourcesGrid";

export type ChatInputProps = {
  allChatHistory: ChannelMessages[];
  selectedChannel: string;
  setChannelMessages: React.Dispatch<React.SetStateAction<any[]>>;
  channelParticipants?: number;
  selectedParticipantId: string | null;
  selectedChannelType: string;
  replyMessageID: string | null;
  setReplyMessageID: React.Dispatch<React.SetStateAction<string | null>>;
  replyMessageContent: string;
  replyMessageAttachment: string;
  replySenderName: string;
  clearReply: () => void;
  replyMessageType: string;
  resources?: ResourceData[] | string;
  // setDataRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatInput: React.FC<ChatInputProps> = ({
  allChatHistory,
  selectedChannel,
  setChannelMessages,
  channelParticipants,
  selectedParticipantId,
  selectedChannelType,
  replyMessageID,
  setReplyMessageID,
  replyMessageContent,
  replyMessageAttachment,
  replySenderName,
  replyMessageType,
  clearReply,
  resources = []
  // setDataRefresh,
}) => {
  const [tabClicked, setTabClicked] = React.useState<string>("keyboard");
  const [files, setFiles] = useState<File[]>([]);
  const [uploadTypes, setUploadTypes] = useState<
    "image" | "audio" | "video" | "file"
  >("image");
  // const [selectedEmoji, setSelectedEmoji] = useState<string | null>(null);
  const [isEmojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [previewURLs, setPreviewURLs] = useState<string[]>([]);
  const [resource, setResource] = useState<ResourceData[]>([]);
  const [message, setMessage] = useState("");
  const [fileInfo, setFileInfo] = useState({
    name: "",
    type: ""
  });
  const allowedFileTypes = [
    "image/png",
    "image/jpg",
    "image/jpeg",
    "audio/mp3",
    "audio/wav",
    "audio/x-m4a",
    "video/mp4"
    /*    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/plain",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation" */
  ];

  const [formattedMessage, setFormattedMessage] = useState("");

  const { state, dispatch, controller } = useSocket();

  const handleRichTextChange = (content: string) => {
    setFormattedMessage(content);
    //localStorage.setItem(`unsentMessage_${selectedChannel}`, content);
  };

  const { clientID, studyID } = useParams();

  const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"], // Bold, italic, underline, and strikethrough
      [{ color: [] }, { background: [] }], // Dropdown with defaults from theme (font color and background)
      [{ list: "ordered" }, { list: "bullet" }], // Lists
      ["link", "clean"] // Links and images
      // Add any additional toolbar options you need
    ]
  };

  const quillRef = useRef<ReactQuill>(null);

  const onEmojiSelect = (emoji: any) => {
    if (quillRef.current) {
      // Check if the ref is not null
      const quill = quillRef.current.getEditor(); // Get the Quill editor instance
      const range = quill.getSelection(true); // Get the current selection range
      if (range) {
        const position = range.index; // Current cursor position
        quill.insertText(position, emoji.native); // Insert the emoji as plain text
        quill.setSelection(position + emoji.native.length); // Place the cursor after the inserted emoji
      }
    }
  };

  // console.log(selectedParticipantId, "chatInput")

  const onEmojiSingleSelect = async (emoji: any) => {
    if (
      selectedChannelType === "Topic Chat" &&
      selectedParticipantId === null
    ) {
      Swal.fire({
        title: "Select a Participant",
        text: "Please select a participant to send an emoji",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
    } else {
      // console.log(emoji);
      if (clientID && channelParticipants && studyID) {
        const result: any = await sendMessageInChannel(
          clientID,
          selectedChannel,
          selectedParticipantId !== null ? selectedParticipantId : null,
          studyID,
          emoji,
          "",
          replyMessageID ? replyMessageID : null
        );

        if (replyMessageID !== null) {
          clearReply();
        }

        // Check if the result was successful and the data exists
        if (result.status === 200 && typeof result.data === "object") {
          // Assume result.data is an array and we want to add its first item
          // Check that result.data is not empty and has the right structure
          // if (result.data.length > 0 && result.data[0].message_content) {
          setChannelMessages((prevMessages) => {
            const newMessages = [
              ...prevMessages,
              {
                id: result.data.id,
                message_content: result.data.message_content,
                sender_name: result.data.sender_name,
                created_at: new Date().toISOString(), // Adjust as necessary
                sender: result.data.sender // Adjust as necessary
              }
            ];
            return newMessages;
          });
          // }
          // setMessage("");
        }
      } else {
        console.log("Missing required data for sending message");
        // Handle the case where some IDs are not provided
      }
    }
  };
  //What needs to be done we will have two functions onFileAdded this is when the user uploads either an image, audio, video or pdf file we should make sure its only one of those types if not throw a Swal alert. If it is one of the 4 types we want to display a preview and the delete as well as the send button.
  //When we click onUploadSend we will send it to the socket controller using sendFile and from there we will receive a key and url which then we should upload to that url. and 0n that success we should send a message wit that key

  useEffect(() => {
    console.log("Resources prop:", selectedChannel);
  }, [resources]);

  const handleResourceClick = async (resource: ResourceData) => {
    if (clientID && channelParticipants && studyID) {
      let authToken = localStorage.getItem("auth-token");
      let refreshToken = localStorage.getItem("refresh-token");
      console.log("Resource clicked:", resource);

      // Construct the message content to include both thumbnail URL and resource URL
      const messageContent = `Resource: ${resource.resource_file_url} | Thumbnail: ${resource.thumbnail_url}`;

      const messageData: MessageData = {
        currentIndex:
          allChatHistory.length <= 0 ? 1 : allChatHistory.length - 1,
        message: {
          clientID: clientID,
          studyID: studyID,
          channelID: selectedChannel,
          participantID:
            selectedParticipantId !== null ? selectedParticipantId : "",
          message: `<a href="${resource.resource_file_url}" target="_blank"><img src="${resource.thumbnail_url}" class="parent-attachment-image"/></a>`,
          attachmentUrl: null,
          attachmentKey: null,
          replyMessageID: null,
          type: "text",
          authToken: authToken || "",
          refreshToken: refreshToken || ""
        }
      };
      controller.sendNewMessage(messageData);

      console.log("Message sent:", messageData);
    } else {
      console.log("Missing required data for sending message");
    }
  };

  const onFilesAdded = async (acceptedFiles: File[]) => {
    //We should allow for Images(png, jpg , JPEG) Audio(mp3, wav) Videos (mp4) and pdfs
    // Validate file types
    const invalidFiles = acceptedFiles.filter(
      (file) => !allowedFileTypes.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      Swal.fire({
        title: "Invalid File Type",
        text: "Please select a valid file type.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // Check file size
    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    if (oversizedFiles.length > 0) {
      Swal.fire({
        title: "File Too Large",
        text: "Please select a file smaller than 2GB.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    if (acceptedFiles.length <= 0) {
      Swal.fire({
        title: "No File Selected",
        text: "Please select a file to send",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    let tempFile = acceptedFiles[0];
    const reader = new FileReader();
    reader.readAsDataURL(tempFile);
    reader.onload = async () => {
      let fileType = "file"; // Default to 'file'

      if (tempFile.type.startsWith("image/")) {
        fileType = "image";
        const resizedFile = await resizeImageToImage(tempFile, 800);
        if (resizedFile) {
          tempFile = resizedFile;
        } else {
          console.error("Error resizing image");
        }
      } else if (tempFile.type.startsWith("video/")) {
        fileType = "video";
      } else if (tempFile.type.startsWith("audio/")) {
        fileType = "audio";
      }

      setFiles([tempFile]);

      // Create previews
      const url = URL.createObjectURL(tempFile);
      setPreviewURLs([url]);

      setFileInfo({
        name: tempFile.name,
        type: fileType
      });
    };
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: onFilesAdded,
    maxSize: MAX_FILE_SIZE,
    multiple: false
  });

  const onUploadSend = async () => {
    if (selectedChannel === "") {
      Swal.fire({
        title: "Create a Channel to send a message",
        text: "You can not send a message if there are no channels or participants",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    if (channelParticipants === 0) {
      Swal.fire({
        title: "Add Participants to the Channel",
        text: "You can not send a message if there are no participants in the channel",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    if (
      selectedChannelType !== "Focus Group" &&
      selectedParticipantId === null
    ) {
      Swal.fire({
        title: "Select a Participant",
        text: "Please select a participant to send a message",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }
    //check that the file is not empty
    if (files.length === 0) {
      Swal.fire({
        title: "No File Selected",
        text: "Please select a file to send",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }
    if (!clientID || !studyID) {
      Swal.fire({
        title: "Missing required data for sending message",
        text: "Error sending message, please try again later",
        icon: "warning",
        confirmButtonColor: "#3085d6",
        timer: 1500
      });
      return;
    }
    //uploading the file
    let authToken = localStorage.getItem("auth-token");
    let refreshToken = localStorage.getItem("refresh-token");

    if (!authToken || !refreshToken) {
      Swal.fire({
        title: "Error",
        text: "Failed to get authentication token, please try again later",
        icon: "error",
        confirmButtonColor: "#3085d6",
        timer: 1500
      });
      return;
    }
    try {
      //Blob the file
      const fileBlob = new Blob([files[0]], { type: files[0].type });

      const currentIndex = allChatHistory.length - 1;

      controller.uploadFile({
        authToken: authToken,
        refreshToken: refreshToken,
        clientID: clientID,
        studyID: studyID,
        fileName: fileInfo.name,
        fileType: fileInfo.type,
        fileBlob: fileBlob,
        channelID: selectedChannel,
        participantID:
          selectedParticipantId !== null ? selectedParticipantId : "",
        message: formattedMessage !== null ? formattedMessage : null,
        replyID: replyMessageID ? replyMessageID : null,
        currentIndex: currentIndex
      });
    } catch (error) {
      console.error("Error sending message:", error);
    }
    Swal.fire({
      title: "Sending data",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        setFiles([]);
        setPreviewURLs([]);
        setFileInfo({ name: "", type: "" });
        setReplyMessageID(null);
        setTabClicked("keyboard");
      }
    });
    // Clear the files and fileInfo after sending
  };

  const onMessageSend = async () => {
    // Send the message via the API
    if (selectedChannel === "") {
      Swal.fire({
        title: "Create a Channel to send a message",
        text: "You can not send a message if there are no channels or participants",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    if (channelParticipants === 0) {
      Swal.fire({
        title: "Add Participants to the Channel",
        text: "You can not send a message if there are no participants in the channel",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }
    //Looking for emprty formatted message
    console.log(formattedMessage, message, "message");
    if (formattedMessage === "") {
      Swal.fire({
        title: "Empty Message",
        text: "Please type a message to send",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
      return;
    }

    if (
      selectedChannelType !== "Focus Group" &&
      selectedParticipantId === null
    ) {
      Swal.fire({
        title: "Select a Participant",
        text: "Please select a participant to send a message",
        icon: "warning",
        showConfirmButton: false,
        timer: 1500
      });
    } else {
      if (
        clientID &&
        channelParticipants &&
        studyID &&
        selectedParticipantId !== null
      ) {
        let index: number = 0;
        let replayID = null;
        let authToken = localStorage.getItem("auth-token");
        let refreshToken = localStorage.getItem("refresh-token");

        if (allChatHistory.length > 0) {
          index = allChatHistory.length - 1;
        }
        if (replyMessageID !== null) {
          replayID = replyMessageID;
        }

        if (typeof authToken === "string" && typeof refreshToken === "string") {
          let newMessageObj: NewMessage = {
            clientID: clientID,
            studyID: studyID,
            channelID: selectedChannel,
            participantID: selectedParticipantId,
            message: formattedMessage,
            attachmentUrl: null,
            replyMessageID: replayID,
            type: "text",
            authToken: authToken,
            refreshToken: refreshToken
          };

          let messageData: MessageData = {
            currentIndex: index,
            message: newMessageObj
          };

          controller.sendNewMessage(messageData);
          //Loading
          Swal.fire({
            title: "Sending data",
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading();
              setFormattedMessage("");
              setReplyMessageID(null);
            }
          });
        } else {
          console.log("Missing required data for sending message");
          Swal.fire({
            title: "Missing required data for sending message",
            text: "Error sending message, please try again later",
            icon: "warning",
            confirmButtonColor: "#3085d6",
            timer: 1500
          });
        }
      } else {
        console.log("Missing required data for sending message");
        Swal.fire({
          title: "Missing required data for sending message",
          text: "Error sending message, please try again later",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          timer: 1500
        });
      }
    }
  };

  const toggleEmojiPicker = (e: any) => {
    e.stopPropagation(); // Stop the click event from propagating
    setEmojiPickerVisible((prev) => !prev);
  };

  const handleOutsideEmojiClick = () => {
    setEmojiPickerVisible(false);
  };

  useEffect(() => {
    //When they change the the select a new user, we should clear the message
    setMessage("");
    setFormattedMessage("");
    setFiles([]);
  }, [selectedParticipantId]);

  useEffect(() => {
    if (fileRejections.length > 0) {
      Swal.fire({
        title: "File Too Large",
        text: "Please select a file smaller than 2MB.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
    }
  }, [fileRejections]);

  const clearFile = () => {
    setFiles([]);
    setPreviewURLs([]);
    setFileInfo({ name: "", type: "" });
  };

  // Function to handle key press
  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && event.shiftKey) {
      return;
    }
    if (event.key === "Enter") {
      event.preventDefault(); // Prevent form submission
      event.stopPropagation(); // Stop the event from propagating

      if (tabClicked === "keyboard") {
        onMessageSend();
      } else if (tabClicked === "upload") {
        onUploadSend();
      }
    }
  };

  return (
    <>
      {replyMessageID && (
        <div className="reply-message-preview-container">
          <div className="reply-details">
            <div className="preview-header">
              <p>Replying to: {replySenderName} </p>
              <div className="reply-close" onClick={clearReply}>
                &times;
              </div>
            </div>
            <div className="preview-body">
              {replyMessageType === "text" ? (
                <ReactQuill
                  value={replyMessageContent}
                  readOnly={true}
                  theme={"bubble"}
                  className="my-custom-quill-reply" // Apply custom class
                />
              ) : replyMessageType === "image" ? (
                <p className="reply-attachment">Image Attached</p>
              ) : replyMessageType === "audio" ? (
                <p className="reply-attachment">Audio Attached</p>
              ) : replyMessageType === "video" ? (
                <p className="reply-attachment">Video Attached</p>
              ) : (
                <div>Unsupported message type</div>
              )}
            </div>
          </div>
        </div>
      )}
      <div className="chat-input-group">
        <div className="icon_tabs mb-2">
          <ChatTabs tabClicked={tabClicked} setTabClicked={setTabClicked} />
        </div>
        <div
          className={`chat-input ${
            tabClicked === "upload" ? "overflow-auto" : ""
          }`}
        >
          {tabClicked === "keyboard" && (
            <div
              className="chat-keyboard"
              onKeyDownCapture={(e) => {
                handleKeyPress(e);
              }}
            >
              <ReactQuill
                modules={modules}
                ref={quillRef}
                className="my-custom-quill"
                value={formattedMessage}
                onChange={handleRichTextChange}
              />
              <span className="icon-smiley" onClick={toggleEmojiPicker}>
                ☺
              </span>
              {isEmojiPickerVisible && (
                <EmojiPicker
                  onEmojiSelect={onEmojiSelect}
                  searchPosition="none"
                  previewPosition="none"
                  onClickOutside={handleOutsideEmojiClick}
                />
              )}
              <div className="icon_send">
                <IconSend onClick={onMessageSend} />
              </div>
            </div>
          )}
          {tabClicked === "resources" && Array.isArray(resources) && (
            <ChatResourcesGrid
              resources={resources}
              onResourceClick={handleResourceClick}
            />
          )}
          {tabClicked === "upload" && (
            <div className="chat-upload">
              <div {...getRootProps()} className="chat-upload-dropzone">
                <input {...getInputProps()} onKeyDown={handleKeyPress} />
                {files.length === 0 && (
                  <small className="dropzone-text">
                    Drag 'n' drop some files here, or click to select a file
                    <br />
                    Accepted File Types: Images (JPEG, PNG, JPG), Audio (MP3,
                    WAV), Video (MP4)
                  </small>
                )}
                {/* Updated previews display */}
                <div className="file-previews">
                  {files.map((file, index) => {
                    if (file.type.startsWith("image")) {
                      return (
                        <img
                          key={index}
                          src={previewURLs[index]}
                          alt="Preview"
                          style={{
                            maxWidth: "500px", // Set max width for images
                            height: "auto"
                          }}
                        />
                      );
                    } else if (file.type.startsWith("audio")) {
                      return (
                        <audio
                          key={index}
                          src={previewURLs[index]}
                          controls
                        ></audio>
                      );
                    } else if (file.type.startsWith("video")) {
                      return (
                        <video
                          key={index}
                          src={previewURLs[index]}
                          controls
                          style={{
                            maxWidth: "500px", // Set max width for videos
                            height: "auto"
                          }}
                        ></video>
                      );
                    } else if (allowedFileTypes.includes(file.type)) {
                      return <div key={index}>{file.name}</div>;
                    }
                  })}
                </div>
              </div>
              {files.length > 0 && (
                <div className="nav-buttons">
                  <span className="icon_send_attachment">
                    <IconSend onClick={onUploadSend} />
                  </span>
                  <span
                    className="remove-attachment"
                    onClick={() => {
                      clearFile();
                    }}
                  >
                    <IconDelete />
                  </span>
                </div>
              )}
            </div>
          )}
          {/* {tabClicked === "emoji" && (
            <div className="input-emoji">
              <EmojiDisplay onEmojiSelect={onEmojiSingleSelect} />
            </div>
            // <EmojiPicker
            // 	onEmojiSelect={onEmojiSelect}
            // 	searchPosition="none"
            // 	previewPosition="none"
            // 	onClickOutside={handleOutsideEmojiClick}
            // />
          )} */}
        </div>
      </div>
    </>
  );
};

export default ChatInput;
