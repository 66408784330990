import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CustomReportsTableContainer from "../../components/CustomReportsTableContainer/customReportsTableContainer.component";
import ReportsBuilder from "../../components/ReportsBuilder/reportsBuilder.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";

interface CustomReportsPageProps extends SidebarProps {}

const CustomReportsPage: React.FC<CustomReportsPageProps> = ({
  sidebarPinned,
  onSidebarPinChange,
  page,
  currentClientName,
  currentClientLogo
}) => {
  const { clientID } = useParams();

  const [section, setSection] = useState<"table" | "builder_new" | number>(
    "table"
  );

  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        <div className="container-fluid">
          <div className="row">
            <div id="reports_builder_page" className="container-fluid">
              <h1>
                {currentClientName ? currentClientName : "No Client Found"}:
                Reports Builder {section === "table" ? "Table" : ""}
              </h1>
              {section === "table" ? (
                <CustomReportsTableContainer
                  updateReport={(id: number) => setSection(id)}
                  createNewReport={() => setSection("builder_new")}
                />
              ) : (
                <ReportsBuilder
                  reportID={section === "builder_new" ? undefined : section}
                  backToTable={() => setSection("table")}
                />
              )}
            </div>
          </div>
        </div>
        <SidebarComponent
          page={page}
          sidebarPinned={sidebarPinned}
          onSidebarPinChange={onSidebarPinChange}
          currentClient={clientID}
          currentClientLogo={currentClientLogo}
        />
      </div>
    </div>
  );
};

export default CustomReportsPage;
