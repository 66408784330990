import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  AppPlatforms,
  MajorVersions,
  MinorVersions,
  S3AppKeyResponse
} from "../types";

// #region Validations

function isAppPlatform(obj: any): obj is AppPlatforms {
  const validIos =
    obj.ios &&
    Array.isArray(obj.ios.production) &&
    obj.ios.production.every(isMajorVersions) &&
    Array.isArray(obj.ios.staging) &&
    obj.ios.staging.every(isMajorVersions);

  const validAndroid =
    obj.android &&
    Array.isArray(obj.android.production) &&
    obj.android.production.every(isMajorVersions) &&
    Array.isArray(obj.android.staging) &&
    obj.android.staging.every(isMajorVersions);

  return validIos && validAndroid;
}

function isMajorVersions(obj: any): obj is MajorVersions {
  return (
    typeof obj.major_version === "number" &&
    typeof obj.alias === "string" &&
    typeof obj.deprecated === "boolean"
  );
}

function isS3KeyResponse(obj: unknown): obj is S3AppKeyResponse {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }
  // Validate S3key and signedURL
  const validS3Key = "s3_key" in obj && typeof obj.s3_key === "string";
  const validSignedURL =
    "signedURL" in obj && typeof obj.signedURL === "string";

  const allValid = validS3Key && validSignedURL;
  return allValid;
}

function isMinorVersions(obj: any): obj is MinorVersions[] {
  if (!Array.isArray(obj)) {
    return false;
  }

  return obj.every((item) => {
    return (
      typeof item.minor_version === "number" &&
      (item.alias === null || typeof item.alias === "string") &&
      typeof item.successful_installs === "number" &&
      typeof item.rollbacks === "number" &&
      typeof item.active === "boolean" &&
      typeof item.created_at === "string" &&
      (item.description === null || typeof item.description === "string")
    );
  });
}

// #endregion Validations

// #region Fetch App Versions
export async function fetchAppVersions(): Promise<AppPlatforms | string> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/major/get",
      "POST",
      { body: {} }
    );

    if (response.status === 200 && response.data !== null) {
      // Validate
      if (
        response.data &&
        typeof response.data === "object" &&
        "platform" in response.data &&
        isAppPlatform(response.data.platform)
      ) {
        return response.data.platform;
      } else {
        console.error("Invalid data received");
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Invalid data received",
          confirmButtonColor: "#3085d6"
        });
        return "Invalid data received";
      }
    }

    return "Error";
  } catch (error) {
    console.error("An error occurred while fetching app versions:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while fetching app versions",
      confirmButtonColor: "#3085d6"
    });
    return "Error";
  }
}

// #endregion Fetch App Versions

// #region Fetch Minor Versions By Major

export async function fetchMinorVersionsByMajor(
  unique_app_id: string,
  platform: string,
  major_version: number,
  environment: string
): Promise<MinorVersions[] | string> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/minor/get",
      "POST",
      {
        body: {
          platform: platform,
          major_version: major_version,
          environment: environment
        }
      }
    );

    if (response.status === 200 && response.data !== null) {
      // Validate
      if (
        typeof response.data === "object" &&
        "minor_versions" in response.data &&
        isMinorVersions(response.data.minor_versions)
      ) {
        return response.data.minor_versions;
      } else {
        console.error("Invalid data received");
        return "Invalid data received";
      }
    }

    if (response.status === 204) {
      return [];
    }

    return "Error";
  } catch (error) {
    console.error(
      "An error occurred while fetching minor versions by major:",
      error
    );
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while fetching minor versions by major",
      confirmButtonColor: "#3085d6"
    });
    return "Error";
  }
}

// #endregion Fetch Minor Versions By Major

// #region New Major Version

export async function newMajorVersion(
  platform: string,
  alias: string
): Promise<boolean> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/major/upload",
      "POST",
      { body: { platform: platform, alias: alias } }
    );

    if (response.status === 200 && response.data !== null) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(
      "An error occurred while creating a new major version:",
      error
    );
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while creating a new major version",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }
}

// #endregion New Major Version

// #region Update Minor Version Status

export async function updateMinorVersion(
  platform: string,
  major_version: number,
  minor_version: number,
  environment: string,
  description: string | undefined,
  app_active: boolean | undefined
): Promise<boolean> {
  try {
    const response = await apiFetcher<unknown>("/app/codepush/update", "POST", {
      body: {
        platform: platform,
        major_version: major_version,
        minor_version: minor_version,
        environment: environment,
        description: description,
        app_active: app_active
      }
    });

    if (response.status === 200 || response.status === 202) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(
      "An error occurred while updating minor version status:",
      error
    );
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while updating minor version status",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }
}

// #endregion Update Minor Version Status

// #region Promote Staging Minor Version

export async function promoteStagingMinorVersion(
  platform: string,
  environment: string,
  major_version: number,
  minor_version: number
): Promise<boolean> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/minor/promote",
      "POST",
      {
        body: {
          platform: platform,
          environment: environment,
          major_version: major_version,
          staging_minor_version: minor_version
        }
      }
    );

    if (
      (response.status === 200 && response.data !== null) ||
      response.status === 202
    ) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(
      "An error occurred while promoting a staging minor version:",
      error
    );
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while promoting a staging minor version",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }
}

// #endregion Promote Staging Minor Version

// #region Rollback To Minor Version

export async function rollbackMinorVersion(
  platform: string,
  major_version: number,
  minor_version: number
): Promise<boolean> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/production/rollback",
      "POST",
      {
        body: {
          platform: platform,
          major_version: major_version,
          target_minor_version: minor_version
        }
      }
    );

    if (response.status === 200 || response.status === 202) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(
      "An error occurred while rolling back a minor version:",
      error
    );
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while rolling back a minor version",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }
}

// #endregion Rollback To Minor Version

// #region S3 Upload Key

export async function fetchAppBundleS3URL(
  unique_app_id: string,
  environment: string,
  platform: string,
  major_version: number,
  file_name: string
): Promise<S3AppKeyResponse | false> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/minor/upload/link",
      "POST",
      {
        body: {
          platform: platform,
          environment: environment,
          major_version: major_version,
          file_name: file_name
        }
      }
    );

    if (
      response.status === 200 &&
      response.data &&
      isS3KeyResponse(response.data)
    ) {
      return {
        signedURL: response.data.signedURL,
        s3_key: response.data.s3_key
      };
    }

    return false;
  } catch {
    console.error("An error occurred while fetching the S3 key");
    return false;
  }
}

// #endregion S3 Upload Key

// #region S3 Upload Confirm BE

export async function confirmS3Upload(
  platform: string,
  major_version: number,
  s3_key: string,
  description: string
  // appStatus: boolean
): Promise<boolean> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/minor/upload",
      "POST",
      {
        body: {
          platform: platform,
          major_version: major_version,
          s3_key: s3_key,
          // appStatus: appStatus,
          description: description
        }
      }
    );

    if (response.status === 200 && response.data !== null) {
      return true;
    }

    return false;
  } catch {
    console.error("An error occurred while confirming the S3 upload");
    return false;
  }
}

// #endregion S3 Upload Confirm BE

// #region Update Major Version Deprecation Status

export async function updateMajorVersion(
  major_version: number
): Promise<boolean> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/major/update",
      "POST",
      {
        body: {
          major_version: major_version
        }
      }
    );

    if (
      response.status === 200 ||
      (response.status && response.data !== null)
    ) {
      return true;
    }

    return false;
  } catch (error) {
    console.error("An error occurred while updating major version:", error);
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while updating major version",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }
}

// #endregion Update Major Version Deprecation Status

// #region Update Major Version Alias
export async function updateMajorVersionAlias(
  major_version: number,
  alias: string
): Promise<boolean> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/major/alias/update",
      "POST",
      {
        body: {
          major_version: major_version,
          alias: alias
        }
      }
    );

    if (response.status === 200 && response.data !== null) {
      return true;
    }

    return false;
  } catch (error) {
    console.error(
      "An error occurred while updating major version alias:",
      error
    );
    Swal.fire({
      icon: "error",
      title: "Error",
      text: "An error occurred while updating major version alias",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }
}

// #endregion Update Major Version Alias

// #region Confirm Update Major Version

type MajorUpdateResponse = {
  status: string;
  message: string;
};

export async function confirmUpdate(
  token: string
): Promise<MajorUpdateResponse | string> {
  try {
    const response = await apiFetcher<unknown>(
      "/app/codepush/major/status",
      "POST",
      {
        body: {
          token: token
        }
      }
    );

    if (response.status === 202) {
      const data: MajorUpdateResponse = {
        status: "success",
        message: "Major version Updated successfully"
      };
      return data;
    }

    if (response.status === 400) {
      return "expired";
    }

    return "failed";
  } catch (e) {
    return "failed";
  }
}

// #endregion Deprecate Major Version
