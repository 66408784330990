import jwtDecode from "jwt-decode";
import React, {
  createContext,
  useContext,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { useNavigate, useParams } from "react-router-dom";

// Define the shape of your JWT payload
interface PermissionsPayload {
  permissions: string[];
}

interface PermissionsContextType {
  hasPermission: (area: string, right?: string) => boolean;
  getPermission: (area: string) => string | undefined;
  isPermissionsLoaded: boolean; // Indicator for permissions loading completion
}

const PermissionsContext = createContext<PermissionsContextType | undefined>(
  undefined
);

export const PermissionsProvider: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState<string[]>([]);
  const [isPermissionsLoaded, setIsPermissionsLoaded] = useState(false); // Track if permissions are loaded
  const [studyAccessGranted, setStudyAccessGranted] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    console.log("permissions", permissions);
  }, [permissions]);

  const { clientID, studyID } = useParams<{
    clientID: string;
    studyID: string;
  }>();

  // Run before any rendering to check if the user has access to the study
  useLayoutEffect(() => {
    if (!isPermissionsLoaded) return;
    if (clientID && studyID && isPermissionsLoaded) {
      const hasAllPermissions =
        permissions.includes("study_all") ||
        permissions.includes("subject") ||
        permissions.includes(`study_${clientID}_${studyID}`);

      if (!hasAllPermissions) {
        console.log(
          `No matching permission found for clientID: ${clientID} and studyID: ${studyID}`
        );
        navigate("/403");
        setStudyAccessGranted(false);
      } else {
        console.log(
          `Permission granted for clientID: ${clientID} and studyID: ${studyID}`
        );
        setStudyAccessGranted(true);
      }
    } else {
      console.log("no clientID or studyID or permissions not loaded");
      setStudyAccessGranted(null);
    }
  }, [clientID, studyID, permissions, isPermissionsLoaded, navigate]);

  useEffect(() => {
    const loadPermissions = async () => {
      // Use an async function to handle permissions loading
      try {
        // Retrieve the JWT token. Replace this with your actual retrieval logic.
        const token = localStorage.getItem("auth-token");

        if (token) {
          // Decode the token
          const decoded: PermissionsPayload =
            jwtDecode<PermissionsPayload>(token);

          // Set the permissions from the decoded token
          setPermissions(decoded.permissions);
        }
      } catch (error) {
        console.error("Error decoding JWT token", error);
        // Handle error cases, such as invalid token format
      } finally {
        setIsPermissionsLoaded(true); // Ensure we mark permissions as loaded
      }
    };

    loadPermissions();
  }, []);

  const hasPermission = (area: string, right?: string): boolean => {
    if (!isPermissionsLoaded) {
      // Ideally, this condition should never be true when called due to the guard at the consumer level
      console.log(
        "Permissions are being checked before they have been fully loaded."
      );
      return false;
    }
    if (right) {
      const permissionToCheck = `${area}_${right}`;
      return permissions.includes(permissionToCheck);
    } else {
      return permissions.some((permission) => permission.startsWith(area));
    }
  };

  const getPermission = (area: string): string | undefined => {
    if (!isPermissionsLoaded) {
      console.log(
        "Attempting to get a permission before permissions have been fully loaded."
      );
      return undefined;
    }
    return permissions.find((permission) => permission.startsWith(area));
  };

  return (
    <PermissionsContext.Provider
      value={{ hasPermission, getPermission, isPermissionsLoaded }}
    >
      {isPermissionsLoaded
        ? studyAccessGranted === null || studyAccessGranted === true
          ? children
          : null
        : null}
      {/* Only render children if permissions are loaded */}
    </PermissionsContext.Provider>
  );
};

// Custom hook for using permissions context
export const usePermissions = () => {
  const context = useContext(PermissionsContext);
  if (!context) {
    throw new Error("usePermissions must be used within a PermissionsProvider");
  }
  return context;
};
