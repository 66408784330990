import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { IconBroom } from "../../../../assets/images/icons/icons";
import {
  MessagesHistory,
  MessagesSent,
  fetchAllReceivedMessages
} from "../../../../models/messages.model";
import { ParamsSurveyPage, Timezone } from "../../../../types";
import {
  getResearcherConfigTimezone,
  getSystemConfigTimezones
} from "../../../../utilities/config.util";
import { convertTime } from "../../../../utilities/utils";
import MultipleDropdown from "../../../Dropdown/multipleDropdown.component";
import InformationTooltip from "../../../InputInformation/InfoInputs.component";
import LoadingPencil from "../../../Loaders/LoadingPencil";
import PaginationNavigation from "../../../Tables/PaginationNavigation/paginationNavigation";
import TimezoneSwitcher from "../../../Tables/TimeZoneSwitcher/timezoneSwitcher";
import RecipientHistory from "../History/recipientHistory.component";
import "./history.scss";

interface historyProps {
  studyClicked: string;
}

const History: React.FC<historyProps> = () => {
  //Controls
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  //Data
  const [data, setData] = useState<MessagesHistory | undefined>();
  const [filterData, setFilterData] = useState<MessagesHistory | undefined>();
  const [fromDate, setFromDate] = useState<string>("");
  const [toDate, setToDate] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const { clientID, studyID } = useParams<ParamsSurveyPage>();
  const [showRecipientHistory, setShowRecipientHistory] = useState<boolean>();
  const [selectedCommunication, setSelectedCommunication] =
    useState<MessagesSent | null>(null);
  const [selectedPhraseLibraryID, setSelectedPhraseLibraryID] =
    useState<string>();
  //Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [timezone, setTimezone] = useState<string>("UTC");
  const [researcherTimezone, setResearcherTimezone] = useState<Timezone | null>(
    null
  );
  const [timezones, setTimezones] = useState<Timezone[]>([]);
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(true);

  /*----------------------------------------------------*/
  /*------------------Loading data----------------------*/
  const loadData = async () => {
    if (clientID && studyID) {
      setIsLoading(true);
      try {
        const response = await fetchAllReceivedMessages(clientID, studyID);
        if (typeof response !== "string") {
          setData(response);
        } else setError("No History Data");
      } catch (error) {
        setError("Error loading data");
      }
      setIsLoading(false);
    } else setError("No Client or Study ID");
  };
  useEffect(() => {
    loadData();
  }, []);
  //Filter Data for the date from and to , the input and the types dropdown
  useEffect(() => {
    if (data) {
      let filteredData = data;
      if (fromDate) {
        filteredData = filteredData.filter((item) => item.date >= fromDate);
      }
      if (toDate) {
        filteredData = filteredData.filter((item) => item.date <= toDate);
      }
      if (selectedType.length) {
        filteredData = filteredData.filter((item) => {
          switch (item.type.toUpperCase()) {
            case "SMS":
              return selectedType.includes("SMS");
            case "EMAIL":
              return selectedType.includes("Email");
            case "PUSH":
              return selectedType.includes("Push Notification");
            default:
              return false;
          }
        });
      }
      if (searchInput) {
        filteredData = filteredData.filter((item) =>
          item.phraseLibrary.message.includes(searchInput)
        );
      }
      setFilterData(filteredData);
    }
  }, [fromDate, toDate, selectedType, searchInput, data]);
  const currentItems =
    filterData?.slice(indexOfFirstItem, indexOfLastItem) || [];

  /*----------------------------------------------------*/
  /*------------------Functions-------------------------*/
  const viewRecipientHistory = (
    communicationID: string,
    phraseLibraryID: string
  ) => {
    // setSelectedCommunicationID(communicationID);
    // find the communication in the data
    const selectedCommunication = data?.find(
      (item) => item.id === communicationID
    );
    setSelectedCommunication(selectedCommunication || null);
    setSelectedPhraseLibraryID(phraseLibraryID);
    setShowRecipientHistory(true);
  };

  const hideRecipientHistory = () => {
    setShowRecipientHistory(false);
  };

  const handleTypeChange = (option: string) => {
    setSelectedType((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };
  const handleClearFilters = () => {
    setFromDate("");
    setToDate("");
    setSelectedType([]);
    setSearchInput("");
  };
  /*----------------------------------------------------*/
  /*---------------------Renders------------------------*/
  const formatDateAndTime = (isoDate: string | Date | null) => {
    if (!isoDate) {
      return { date: "Scheduled for sending", time: "Scheduled for sending" };
    }

    const timeOptions = {
      hour12: false,
      hour: "2-digit" as "2-digit",
      minute: "2-digit" as "2-digit",
      second: "2-digit" as "2-digit",
      timeZone: "UTC" // Use the same timezone as the input
    };

    const dateOptions = {
      year: "numeric" as "numeric",
      month: "2-digit" as "2-digit",
      day: "2-digit" as "2-digit",
      timeZone: "UTC" // Use the same timezone as the input
    };

    const date = new Date(isoDate);
    const formattedDate = new Intl.DateTimeFormat(
      undefined,
      dateOptions
    ).format(date);
    const formattedTime = new Intl.DateTimeFormat(
      undefined,
      timeOptions
    ).format(date);

    return { date: formattedDate, time: formattedTime };
  };

  useEffect(() => {
    getResearcherConfigTimezone().then((result) => {
      // console.log("result", result);
      if (result) {
        setResearcherTimezone(result);
      } else {
        setResearcherTimezone(null);
      }
    });
  }, []);

  const handleChangeTimezone = (timezone: string) => {
    setTimezone(timezone);
  };

  useEffect(() => {
    const fetchAllConfigTimezones = async () => {
      try {
        const jsonData: Timezone[] | false = await getSystemConfigTimezones();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setError("Error getting timezones");
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setTimezones(jsonData);
          } catch {
            console.error("Error setting timezones");
            throw new Error("Error setting timezones");
          }
        }
      } catch {
        setError("Error getting timezones");
      }
    };

    const promises = [fetchAllConfigTimezones()];

    Promise.all(promises)
      .then(() => {
        setLoadingTimezones(false);
      })
      .catch(() => {
        setLoadingTimezones(false);
      });
  }, []);

  return isLoading || loadingTimezones ? (
    <LoadingPencil isVisible={isLoading} title={"history"} />
  ) : (
    <div className="history-container">
      {showRecipientHistory &&
      selectedCommunication &&
      selectedPhraseLibraryID ? (
        <RecipientHistory
          communication={selectedCommunication}
          phraseLibraryID={selectedPhraseLibraryID}
          hideRecipientHistory={hideRecipientHistory}
          timezones={timezones}
          timezone={timezone}
          handleChangeTimezone={handleChangeTimezone}
          researcherTimezone={researcherTimezone}
        />
      ) : (
        <>
          <div className="history-title-container d-flex justify-content-between">
            <h5 className="history-title">Message History</h5>
          </div>
          <div className="history-info-container ">
            <div className="d-flex justify-content-between">
              <div className="history-filter">
                <div className="history-filter-date">
                  <label className="date-header" htmlFor="fromDate">
                    From
                  </label>
                  <input
                    className="date-input"
                    type="date"
                    id="fromDate"
                    name="fromDate"
                    value={fromDate}
                    onChange={(e) => setFromDate(e.target.value)}
                  />
                </div>
                <div className="history-filter-date">
                  <label className="date-header" htmlFor="toDate">
                    To{" "}
                  </label>
                  <input
                    className="date-input"
                    type="date"
                    id="toDate"
                    name="toDate"
                    value={toDate}
                    onChange={(e) => setToDate(e.target.value)}
                  />
                </div>
                <div className="history-filter-dropdown">
                  <MultipleDropdown
                    placeholder="Types"
                    options={["SMS", "Email", "Push Notification"]}
                    onOptionToggled={handleTypeChange}
                    selectedOptions={selectedType}
                  />
                </div>
                <div className="history-filter-search-container">
                  <input
                    className="search-input"
                    type="text"
                    id="search"
                    name="search"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <button
                    className="btn btn-primary btn-clear"
                    disabled={false}
                    onClick={handleClearFilters}
                  >
                    <IconBroom className="icon icon_white" />
                  </button>
                </div>
              </div>
              <TimezoneSwitcher
                tableType="messagesTable"
                timezone={timezone}
                handleChangeTimezone={handleChangeTimezone}
                researcherTimezone={researcherTimezone}
                disableParticipantTimezone={true}
              />
            </div>
            <div className="table-responsive">
              <table className="table table-container">
                <thead>
                  <tr>
                    <th className="medium-col">Date</th>
                    <th className="medium-col">Time</th>
                    <th className="small-col">Amount</th>
                    <th className="small-col">
                      Sent
                      <InformationTooltip
                        marginTop="-12px"
                        marginLeft="2px"
                        position="bottom"
                        tooltipText="Successfully sent to participant"
                      />
                    </th>
                    <th className="small-col">
                      Delivered
                      <InformationTooltip
                        marginTop="-12px"
                        marginLeft="2px"
                        position="bottom"
                        tooltipText="Participant viewed and opened message"
                      />
                    </th>
                    <th className="small-col">Type</th>
                    <th className="large-col">Preview</th>
                    <th className="small-col"></th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((item, index) => {
                      let timezoneOffset = 0;
                      // get participant's timezone offset based off of their timezone, filter though allTimezones
                      const participant_offset = undefined;
                      if (
                        timezone === "Participant's Timezone" &&
                        participant_offset
                      ) {
                        timezoneOffset = Number(participant_offset);
                      } else if (timezone === "UTC") {
                        timezoneOffset = 0;
                      } else if (timezone === "ResearcherTimezone") {
                        timezoneOffset = researcherTimezone
                          ? researcherTimezone.offset
                          : 0;
                      }

                      const { date, time } = formatDateAndTime(
                        convertTime(item.date, timezoneOffset)
                      );

                      return (
                        <tr key={index}>
                          <td>{date}</td>
                          <td>{time}</td>
                          <td>{item.amount}</td>
                          <td>{item.sent}</td>
                          <td>{item.delivered}</td>
                          <td>{item.type}</td>
                          <td className="preview-col">
                            {item.phraseLibrary.message}
                          </td>
                          <td className="text-center">
                            <button
                              className="btn btn-primary btn-view"
                              onClick={() =>
                                viewRecipientHistory(
                                  item.id,
                                  item.phraseLibrary.id
                                )
                              }
                            >
                              View
                            </button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>No Data Found</td>
                    </tr>
                  )}
                </tbody>
              </table>
              <PaginationNavigation
                currentPage={currentPage}
                totalResults={filterData?.length || 0}
                resultsPerPage={itemsPerPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default History;
