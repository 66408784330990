import React, { useEffect, useState } from "react";
import { Country, Language, ResearcherRole, Timezone } from "../../../types";
import {
  getSystemConfigCountries,
  getSystemConfigLanguages,
  getSystemConfigTimezones
} from "../../../utilities/config.util";

import Swal from "sweetalert2";
import { createResearcher } from "../../../models/researcher.model";
import { isEmailValid } from "../../../utilities/InputTesting.util";

type Issues = {
  [key: string]: string[];
};

type CreateResearcherContainerProps = {
  allRoles: ResearcherRole[];
  setTabControl: (value: string) => void;
};

const CreateResearcherContainer: React.FC<CreateResearcherContainerProps> = ({
  allRoles,
  setTabControl
}) => {
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(true);

  const [languages, setLanguages] = useState<Language[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [timezones, setTimezones] = useState<Timezone[]>([]);

  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);
  const [errorFetchingData, setErrorFetchingData] = useState<{
    errorCode: string;
    errorMessage: string;
  } | null>(null);

  const [newResearcher, setNewResearcher] = useState<{
    firstName: string;
    lastName: string;
    email: string;
    mobile: string;
    countryID: string;
    timezoneID: string;
    languageID: string;
    roleID: string;
  }>({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    countryID: "",
    timezoneID: "",
    languageID: "",
    roleID: allRoles[0].id ? allRoles[0].id : ""
  });

  useEffect(() => {
    // get langauges from local storage using getConfigLanguages
    const fetchAllConfigLanguages = async () => {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllConfigCountries = async () => {
      try {
        const jsonData: Country[] | false = await getSystemConfigCountries();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setCountries(jsonData);
          } catch {
            console.error("Error setting countries");
            throw new Error("Error setting countries");
          }

          // setCountries([{ iso_code: "en", name: "English" }]);
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllConfigTimezones = async () => {
      try {
        const jsonData: Timezone[] | false = await getSystemConfigTimezones();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setTimezones(jsonData);
          } catch {
            console.error("Error setting timezones");
            throw new Error("Error setting timezones");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const promises = [
      fetchAllConfigLanguages(),
      fetchAllConfigCountries(),
      fetchAllConfigTimezones()
    ];

    Promise.all(promises)
      .then(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      })
      .catch(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      });
  }, []);

  useEffect(() => {
    // set default values for newResearcher to first value in each array

    if (!loadingLanguages && !loadingCountries && !loadingTimezones) {
      if (languages.length > 0) {
        setNewResearcher((prevResearcher) => ({
          ...prevResearcher,
          languageID: languages[0].iso_code
        }));
      } else {
        console.error("Error setting languageID");
      }

      if (countries.length > 0) {
        setNewResearcher((prevResearcher) => ({
          ...prevResearcher,
          countryID: countries[0].iso_code
        }));
      } else {
        console.error("Error setting countryID");
      }

      if (timezones.length > 0) {
        setNewResearcher((prevResearcher) => ({
          ...prevResearcher,
          timezoneID: timezones[0].name
        }));
      }
    } else {
      // TODO: Investigate why this is happening
      console.error("Error setting timezoneID");
    }
  }, [
    languages,
    countries,
    timezones,
    loadingLanguages,
    loadingCountries,
    loadingTimezones
  ]);

  function handleInputChange(
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) {
    const { name, value } = event.target;

    console.log("name:", name);
    console.log("value:", value);

    let newValue = value;

    if (name === "firstName") {
      // remove invalid characters
      newValue = value.replace(/[^a-zA-Z0-9]/g, "");
    }

    if (name === "lastName") {
      // remove invalid characters
      newValue = value.replace(/[^a-zA-Z0-9]/g, "");
    }

    if (name === "email") {
      // remove invalid characters. Allow !#$%&'*+-/=?^_`{|}~
      newValue = value.replace(/[^a-zA-Z0-9!#$%&'*+-/=?^_`{|}~@.]/g, "");
    }

    if (name === "mobile") {
      // remove invalid characters
      newValue = value.replace(/[^0-9]/g, "");
    }

    setNewResearcher((prevResearcher) => ({
      ...prevResearcher,
      [name]: newValue
    }));
  }

  function handleCreateRole() {
    // set current Researcher to local storage
    localStorage.setItem("currentNewResearcher", JSON.stringify(newResearcher));

    // set active page to createRole
    setTabControl("roles");
  }

  // get currentNewResearcher from local storage
  useEffect(() => {
    const currentNewResearcher = localStorage.getItem("currentNewResearcher");
    if (currentNewResearcher) {
      setNewResearcher(JSON.parse(currentNewResearcher));
    }
  }, []);

  async function handleCreateResearcher(): Promise<void> {
    // createResearcher
    const validated = await validate();
    if (!validated) {
      return;
    }
    const sendingCreatedResearcher = async () => {
      try {
        const response: string = await createResearcher(
          newResearcher.firstName,
          newResearcher.lastName,
          newResearcher.email,
          newResearcher.mobile,
          newResearcher.countryID,
          newResearcher.timezoneID,
          newResearcher.languageID,
          newResearcher.roleID
        );

        if (response !== "success") {
          Swal.fire({
            icon: "error",
            title: "Error creating researcher",
            text: response
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Researcher created successfully",
            confirmButtonColor: "#3085d6"
          }).then((result) => {
            if (result.isConfirmed) {
              // //  reload page
              // window.location.reload();

              // reset newResearcher
              setNewResearcher({
                firstName: "",
                lastName: "",
                email: "",
                mobile: "",
                countryID: countries[0].iso_code ? countries[0].iso_code : "",
                timezoneID: timezones[0].name ? timezones[0].name : "",
                languageID: languages[0].iso_code ? languages[0].iso_code : "",
                roleID: allRoles[0].id ? allRoles[0].id : ""
              });
            }
          });
          //  remove current Researcher from local storage
          localStorage.removeItem("currentNewResearcher");
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error creating researcher"
          // text: "Error creating researcher",
        });
      }
    };

    const promises = [sendingCreatedResearcher()];

    Promise.all(promises)
      .then(() => {})
      .catch(() => {
        // At least one promise failed
        console.log("At least one promise failed");
        Swal.fire({
          icon: "error",
          title: "Error creating researcher",
          text: "At least one promise failed"
        });
      });
  }

  async function validate(): Promise<boolean> {
    const stringShortMax = 255;
    let issues: Issues = {};
    let hasIssues = false;

    new Map(Object.entries(newResearcher)).forEach((value, key) => {
      if (!issues[key]) {
        issues[key] = [];
      }

      if (value === "") {
        issues[key].push("Required");
        hasIssues = true;
      }

      if (value.length > stringShortMax) {
        issues[key].push("Too long");
        hasIssues = true;
      }

      if (key === "mobile" && value.length > 20) {
        issues[key].push("Cannot be more than 20 characters");
        hasIssues = true;
      }

      if (key === "email") {
        console.log("email", key, value);
        const emailValid = isEmailValid(value);
        console.log("emailValid", emailValid);
        if (!emailValid) {
          console.log("Email invalid");
          issues[key].push("Please enter a valid email");
          hasIssues = true;
        }
      }
    });

    console.log("issues", issues);

    if (hasIssues) {
      Swal.fire({
        icon: "error",
        title: "Error Creating Researcher",
        html: `<div class="text-start">Please correct the following issues:<ul>${Object.entries(
          issues
        )
          .filter(([key, value]) => value.length > 0) // Filter out entries with empty value arrays
          .map(([key, value]) => `<li>${key}: ${value.join(", ")}</li>`)
          .join("")}</ul></div>`
      });
    }

    return !hasIssues;
  }

  //TODO: please check if there needs to be any truncation for the selects in here. check diaries editing on selects for examples
  return loadingLanguages && loadingCountries && loadingTimezones ? (
    <div className="d-flex justify-content-center custom_spinner_container full_page">
      <div className="spinner-border" role="status"></div>
    </div>
  ) : (
    <div className="container">
      <div className="row">
        <div className="card m-auto col-12 col-lg-6">
          <div className="row">
            <div className="col">
              <h2>Create Researcher</h2>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label htmlFor="firstName" className="form-label mb-1 mx-1">
                First Name
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={newResearcher.firstName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label htmlFor="lastName" className="form-label mb-1 mx-1">
                Last Name
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={newResearcher.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label htmlFor="email" className="form-label mb-1 mx-1">
                Email
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                placeholder="Email"
                value={newResearcher.email}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label htmlFor="mobile" className="form-label mb-1 mx-1">
                Mobile
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <input
                type="text"
                className="form-control"
                id="mobile"
                name="mobile"
                placeholder="Mobile"
                value={newResearcher.mobile}
                onChange={handleInputChange}
              />
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label htmlFor="countryID" className="form-label mb-1 mx-1">
                Country
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <select
                className="form-select"
                aria-label="Default select example"
                value={newResearcher.countryID}
                onChange={handleInputChange}
                name="countryID"
              >
                {countries.map((country) => (
                  <option value={country.iso_code}>{country.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label htmlFor="timezoneID" className="form-label mb-1 mx-1">
                Timezone
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <select
                className="form-select"
                aria-label="Default select example"
                value={newResearcher.timezoneID}
                onChange={handleInputChange}
                name="timezoneID"
              >
                {timezones.map((timezone) => (
                  <option value={timezone.name}>{timezone.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4">
              <label htmlFor="languageID" className="form-label mb-1 mx-1">
                Language
              </label>
            </div>
            <div className="col-12 col-lg-8">
              <select
                className="form-select"
                aria-label="Default select example"
                value={newResearcher.languageID}
                onChange={handleInputChange}
                name="languageID"
              >
                {languages.map((language) => (
                  <option value={language.iso_code}>{language.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-12 col-lg-4 d-flex justify-content-between">
              <label htmlFor="email" className="form-label mb-1 mx-1">
                Role{" "}
              </label>
              <button
                className="btn btn-primary btn_small"
                onClick={handleCreateRole}
              >
                Create Role
              </button>
            </div>
            <div className="col-12 col-lg-8">
              <select
                className="form-select"
                aria-label="Default select example"
                value={newResearcher.roleID}
                onChange={handleInputChange}
                name="roleID"
              >
                {allRoles.map((role: ResearcherRole) => (
                  <option value={role.id}>{role.name}</option>
                ))}
              </select>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col d-flex justify-content-end">
              <button
                className="btn btn-primary"
                onClick={async () => await handleCreateResearcher()}
              >
                Create Researcher
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateResearcherContainer;
