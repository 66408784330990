//Page to display the Study, with it's surveys
// Receives the study as a prop

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Automation from "../../components/StudySurveyTabSingle/Automation/automation.component.tsx";
import ChatContainer from "../../components/StudySurveyTabSingle/Chat/ChatContainer.component.tsx";
import GeoFences from "../../components/StudySurveyTabSingle/Geo-Fences/geo-fences.component.tsx";
import Messages from "../../components/StudySurveyTabSingle/Messages/messages.component.tsx";
import Overview from "../../components/StudySurveyTabSingle/Overview/overview.component.tsx";
import Participants from "../../components/StudySurveyTabSingle/Participants/participants.component.tsx";
import Resources from "../../components/StudySurveyTabSingle/Resources/resources.component.tsx";
import Rewards from "../../components/StudySurveyTabSingle/Rewards/rewards.component.tsx";
import Settings from "../../components/StudySurveyTabSingle/Settings/StudySurveySettingsHandler/studySurveySettingsHandler.component.tsx";
import Tags from "../../components/StudySurveyTabSingle/Tags/tags.component.tsx";
import ViewEQ from "../../components/StudySurveyTabSingle/ViewEQ/ViewEQ.component.tsx";
import StudySurveyWSCHandler from "../../components/StudySurveyTabSingle/WSC/studySurveyWSCHandler.component.tsx";
import StudyDetailsTabs from "../../infrastructure/StudyDetailsTabs/studyDetailsTabs.component.tsx";
import StudyNavbar from "../../infrastructure/StudyNavbar/studyNavbar.component.tsx";
import { Study, SurveyPill, Tabs } from "../../types.ts";
import "./studyDashboard.scss";
import { usePermissions } from "../../contexts/UserContext.tsx";

interface StudyDashboardProps {
  clientID: string;
  study: Study;
  surveys?: SurveyPill[] | null;
}

const StudyDashboard = ({ study, clientID, surveys }: StudyDashboardProps) => {
  const [pageTitle, setPageTitle] = useState<string>(study.study_name);
  const { surveyID } = useParams(); // Get from URL
  const { hasPermission } = usePermissions();

  // Study or Survey Clicked
  const [studyClicked, setStudyClicked] = useState<
    "study" | "survey" | "diary"
  >("study");

  // Tab Clicked
  const [tabClicked, setTabClicked] = useState<Tabs>(null);
  useEffect(() => {
    //  Function to safely parse JSON from sessionStorage
    const safeParse = (item: string) => {
      const data = sessionStorage.getItem(item);
      return data ? JSON.parse(data) : null;
    };

    // Retrieve the stored tab information safely
    const storedStudyTab = safeParse("studyTabComponentLocation");
    const storedSurveyTab = safeParse("surveyTabComponentLocation");

    if (surveyID) {
      // find the survey from the surveys array
      const survey = surveys?.find((s) => s.id === surveyID);
      if (survey) {
        console.log(survey.survey_type);
        if (survey.survey_type === "diary") {
          setStudyClicked("diary");
        } else {
          setStudyClicked("survey");
        }
      } else {
        console.error("Survey not found");
        setStudyClicked("survey");
      }

      setPageTitle("Surveys");
      // Check if the stored survey tab matches the current survey
      if (storedSurveyTab && storedSurveyTab.id === surveyID) {
        setTabClicked(storedSurveyTab.tabName);
      } else {
        setTabClicked("participants");
      }
    } else {
      setStudyClicked("study");
      setPageTitle(study.study_name);
      // Check if the stored study tab matches the current study
      if (storedStudyTab && storedStudyTab.id === study.id) {
        setTabClicked(storedStudyTab.tabName);
      } else {
        if (hasPermission("subject") || hasPermission("bucket")) {
          setTabClicked("overview");
        } else {
          setTabClicked(null);
        }
      }
    }
  }, [surveys, study, surveyID]);

  return (
    <>
      <div
        id="study_page_header_component"
        className="col-12 client_page_header"
      >
        {/* Contains Study, Diary and Surveys Pills */}
        <StudyNavbar
          studyClicked={studyClicked}
          setStudyClicked={setStudyClicked}
          setTabClicked={setTabClicked}
          clientID={clientID}
          studyID={study.id}
          surveys={surveys}
          study={study}
        />
        {/* Contains Overview, Tags, Automation etc */}
        <StudyDetailsTabs
          tabClicked={tabClicked}
          setTabClicked={setTabClicked}
          studyClicked={studyClicked}
          surveySystem={
            surveys?.find((s) => s.id === surveyID)?.survey_system || undefined
          }
        />
        {/* <h4>{pageTitle}</h4> */}
        {tabClicked === "overview" && (
          <Overview studyClicked={studyClicked} setTabClicked={setTabClicked} />
        )}
        {tabClicked === "tags" && <Tags studyClicked={studyClicked} />}
        {tabClicked === "automation" && <Automation />}
        {tabClicked === "settings" && <Settings studyClicked={studyClicked} />}
        {tabClicked === "chat" && <ChatContainer studyClicked={studyClicked} />}
        {tabClicked === "messages" && <Messages studyClicked={studyClicked} />}
        {tabClicked === "rewards" && <Rewards studyClicked={studyClicked} />}
        {tabClicked === "participants" && (
          <Participants
            studyClicked={studyClicked}
            clientID={clientID}
            studyID={study.id}
            tableLevelName={
              studyClicked === "study"
                ? study.study_name
                : surveys?.find((s) => s.id === surveyID)?.survey_name || ""
            }
          />
        )}
        {tabClicked === "resources" && (
          <Resources studyClicked={studyClicked} />
        )}
        {tabClicked === "geo-fences" && (
          <GeoFences studyClicked={studyClicked} />
        )}
        {tabClicked === "wsc" && (
          <StudySurveyWSCHandler studyClicked={studyClicked} />
        )}
        {tabClicked === "viewEQ" && <ViewEQ studyClicked={studyClicked} />}
        {tabClicked === null && (
          <h1 className="table_name mb-0">{study.study_name}</h1>
        )}
      </div>
    </>
  );
};

export default StudyDashboard;
