import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { usePermissions } from "../../../../../contexts/UserContext";
import {
  MessagesParticipants,
  MessagesPhrases,
  createNewMessage,
  createTestCommunication
} from "../../../../../models/messages.model";
import { ParamsSurveyPage } from "../../../../../types";
import MultipleDropdown from "../../../../Dropdown/multipleDropdown.component";
import LoadPhrase from "./loadPhrase.component";
import "./messenger.scss";

interface MessengerProps {
  recipients: MessagesParticipants[] | undefined;
  phrases: MessagesPhrases[] | undefined;
  attachments: any;
  resources: any;
}
const Messenger: React.FC<MessengerProps> = ({
  recipients,
  phrases,
  attachments,
  resources
}) => {
  const { clientID, studyID } = useParams<ParamsSurveyPage>();
  const [loadPhrase, setLoadPhrase] = useState<string | undefined>(undefined);
  const [selectedPhrase, setSelectedPhrase] = useState<string[]>([]);
  const [selectedVariable, setSelectedVariable] = useState<string[]>([]);
  const [message, setMessage] = useState<string>("");
  const variableOptions = [
    "{first_name}",
    "{last_name}",
    "{mobile_access_code}"
  ];
  //TODO: Add survey link and resources
  const [surveyLink, setSurveyLink] = useState<any>([]);
  const [resourcesList, setResourcesList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const hasBeenEdited: boolean = false;
  const [showHiddenText, setShowHiddenText] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [showTestButton, setShowTestButton] = useState<string | null>(null);

  const { hasPermission } = usePermissions();

  /*----------------------------------------------------*/
  /*------------------Functions-------------------------*/
  const handleAddVariable = (variable: string) => {
    setMessage((prev) => prev + variable);
  };

  const isCommunicationValid = async () => {
    let tempErrors = []; // Use an array to store all errors

    if (typeof clientID !== "string") {
      tempErrors.push("No client ID provided");
    }
    if (typeof studyID !== "string") {
      tempErrors.push("No study ID provided");
    }
    if (!recipients || recipients.length === 0) {
      tempErrors.push("No recipients selected");
    }

    let whitespaceRegex = /^\s*$/; // Regex to match empty or whitespace-only strings

    if (!message || message === "" || whitespaceRegex.test(message)) {
      tempErrors.push("No message entered for communication");
    }

    // Check if the message is at least 5 characters long
    if (!message || message.length < 5) {
      tempErrors.push("Message is too short");
    }

    // Count the number of alphabetic characters
    const alphabeticChars = message.match(/[A-Za-z]/g) || [];
    if (alphabeticChars.length < 5) {
      tempErrors.push("Message must contain at least 5 alphabetic characters");
    }

    if (message && message.length >= 1500) {
      tempErrors.push("Message is too long");
    }

    if (tempErrors.length > 0) {
      // Check if there are any errors
      await Swal.fire({
        icon: "error",
        title: "Please fix the following :",
        html: tempErrors.join("<br>"), // Join all errors with a line break for HTML display
        confirmButtonColor: "#3085d6"
      });
      return false;
    }
    return true;
  };

  //Create communication
  const sendCommunication = async (type: string) => {
    if (message !== phrases?.[0].phrase) {
      const result = await Swal.fire({
        title: "Potential Delays in Creating a New Phrase",
        text: "Are you certain you wish to create a new phrase? This action may entail some delays attributable to translation processes. In the event of a missing translation, the default language will be English.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, send it!"
      });

      if (!result.isConfirmed) {
        return;
      }
    }
    //Load swal to show loading
    Swal.fire({
      title: "Sending communication...",
      text: "Please wait",
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });
    const valid = await isCommunicationValid();
    //lets push the recipients.id into an array of strings
    const recipientID = recipients?.map((recipient) => recipient.id);
    let category = "0";
    switch (type) {
      case "Email":
        category = "13";
        break;
      case "SMS":
        category = "15";
        break;
      case "Push Notification":
        category = "14";
        break;
      default:
        category = "13";
    }
    const workerID = localStorage.getItem("workerID");
    if (workerID && workerID !== null) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
    } else {
      if (valid) {
        //Extra
        try {
          setIsLoading(true);
          const response = await createNewMessage(
            clientID!,
            studyID!,
            recipientID!,
            category!,
            type,
            message
          );
          if (typeof response === "string") {
            setError(response);
            Swal.fire({
              icon: "error",
              title: "Error",
              text: `An error occurred while sending the communication.${response}`,
              confirmButtonColor: "#3085d6"
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Communication has been queued",
              text: `The communication has been queued and will be sent shortly.`,
              confirmButtonColor: "#3085d6"
            });
          }
          setIsLoading(false);
        } catch (error) {
          setError("An error occurred while sending the communication.");
          console.error(error);
        }
      } else {
        console.log("invalid");
      }
    }
  };
  //Create test communication
  const validTestCommunication = async () => {
    let tempErrors = [];
    let regexMessage = /^\s*$/; // Regex to match empty or whitespace-only strings

    if (!message || message === "" || regexMessage.test(message)) {
      tempErrors.push("No message entered for communication");
    }
    if (!message || message.length < 5) {
      tempErrors.push("Message is too short");
    }
    // Count the number of alphabetic characters
    const alphabeticChars = message.match(/[A-Za-z]/g) || [];
    if (alphabeticChars.length < 5) {
      tempErrors.push("Message must contain at least 5 alphabetic characters");
    }
    if (message && message.length >= 1500) {
      tempErrors.push("Message is too long");
    }

    if (tempErrors.length > 0) {
      // Check if there are any errors
      await Swal.fire({
        icon: "error",
        title: "Please fix the following :",
        html: tempErrors.join("<br>"),
        confirmButtonColor: "#3085d6"
      });
      return false;
    }
    return true;
  };

  const sendTestCommunication = async (type: string) => {
    Swal.fire({
      title: "Sending Test Communication...",
      text: "Please wait",
      showConfirmButton: false,
      allowOutsideClick: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });
    const validTest = await validTestCommunication();
    if (validTest) {
      try {
        setIsLoading(true);
        const response = await createTestCommunication(type, message);
        if (typeof response === "string") {
          setError(response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `An error occurred while sending the communication.${response}`,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Communication has been queued",
            text: `The communication has been queued and will be sent shortly.`,
            confirmButtonColor: "#3085d6"
          });
        }
        setIsLoading(false);
      } catch (error) {
        setError("An error occurred while sending the communication.");
        console.error(error);
      }
    } else {
      console.log("invalid");
    }
  };

  const handlePhraseSelect = (phrase: string) => {
    setMessage(phrase);
    setLoadPhrase(phrase);
  };
  //Check for {} in message and show hidden text if we find it
  useEffect(() => {
    if (message.includes("{") || message.includes("}")) {
      setShowHiddenText(
        "We have detected a variable in your message. Please use a test communication to ensure the variable is replaced with the correct value."
      );
    } else {
      setShowHiddenText("");
    }
  }, [message]);
  //Check in message has been edited
  useEffect(() => {
    if (loadPhrase) {
      if (message !== loadPhrase) {
        setShowHiddenText(
          "You have edited the phrase. Please use a test communication to ensure the message is correct."
        );
      } else {
        setShowHiddenText("");
      }
    }
    if (message === "") {
      setShowHiddenText("");
    }
  }, [message, loadPhrase]);

  return (
    <div className="message-container-display">
      <div className="message-title">
        <h5 className="title">Message</h5>
        <LoadPhrase phrases={phrases!} onPhraseSelected={handlePhraseSelect} />
      </div>
      <div className="message-phrase">
        <div className="message-text">
          <textarea
            className="message-textarea"
            placeholder="Enter your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        {(hasPermission("subject") ||
          hasPermission("message", "all") ||
          hasPermission("messages", "write")) && (
          <div className="messenger-toolbar">
            <div className="message-variables">
              <MultipleDropdown
                placeholder="Add Variable"
                options={variableOptions}
                onOptionToggled={handleAddVariable}
                selectedOptions={selectedVariable}
              />
            </div>
            {showHiddenText && (
              <div className="message-hidden-text">
                <p className="message-text">{showHiddenText}</p>
              </div>
            )}
          </div>
        )}
        {/* <div className='message-attachments'>
          <div className='attachments-title-container'>
            <h5 className="attachments-title">Attachments</h5>
            <div className='attachment-buttons'>
              <button className='btn-attachment'>Attach Survey Link</button>
              <button className='btn-attachment'>Attach Resources</button>
            </div>
          </div>
          <div className='message-attachments-list'>
            <div className='message-attachments-list-name'>
              <h5 className="attachments-title">Name</h5>
            </div>
            <div className='message-attachments-list-type'>
              <h5 className="attachments-title">Type</h5>
            </div>
          </div>
        </div> */}
        {(hasPermission("subject") ||
          hasPermission("message", "all") ||
          hasPermission("messages", "write")) && (
          <div className="message-send-buttons">
            {[
              // TODO: Add Email Action back in when email is implemented
              // "Email",
              "SMS",
              "Push Notification"
            ].map((type, index) => (
              <div
                className="send-button-container"
                key={index}
                onMouseEnter={() => setShowTestButton(type)}
                onMouseLeave={() => setShowTestButton(null)}
              >
                <button
                  className="message-send-button"
                  onClick={() => sendCommunication(type)}
                >
                  Send as {type}
                </button>
                {/* For now we are removing push notification, cause there is no way of doing so.*/}
                {showTestButton === type && type !== "Push Notification" && (
                  <button
                    className="btn-test message-test-button"
                    onClick={() => sendTestCommunication(type)}
                  >
                    Test {type}
                  </button>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
export default Messenger;
