import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { isTokenExpired } from "../../utilities/tokenHandler.util";
import "./majorUpdate.scss";
import { useNavigate } from "react-router-dom";
import { confirmUpdate } from "../../models/appVersions.model";

type MajorVersionUpdateProps = {
  token: string;
};

const MajorVersionUpdate: React.FC<MajorVersionUpdateProps> = ({ token }) => {
  const [validToken, setValidToken] = useState<boolean | "loading">("loading");
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const nav = useNavigate();

  useEffect(() => {
    if (isTokenExpired(token)) {
      setValidToken(false);
    } else {
      setValidToken(true);
    }
  }, [token]);

  useEffect(() => {
    if (validToken === true) {
      handleSubmit();
    }

    async function handleSubmit() {
      Swal.fire({
        title: "Updating Major Version",
        html: "Please wait...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const response = await confirmUpdate(token);

      // Failure.
      if (typeof response === "string") {
        if (response === "expired") {
          setSubmitMessage(
            "The link has expired. Please request another email."
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            html: "The link has expired. Please request another email.",
            confirmButtonColor: "#1a2345"
          }).then((result) => {
            if (result.dismiss || result.isDismissed || result.isConfirmed) {
              // timeout redirect
              setTimeout(() => {
                nav("/config/app-versions");
              }, 2000);
            }
          });
        }

        if (response === "failed") {
          setSubmitMessage(
            "Failed to update Major Version, please contact support."
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            html: "Failed to update Major Version, please contact support.",
            confirmButtonColor: "#1a2345"
          }).then((result) => {
            if (result.dismiss || result.isDismissed || result.isConfirmed) {
              setTimeout(() => {
                nav("/config/app-versions");
              }, 2000);
            }
          });
        }
        return;
      }

      setSubmitMessage("Major Version update successful. Redirecting...");
      // Success
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Major Version update successful. Redirecting...",
        confirmButtonColor: "#1a2345"
      });

      // timeout redirect
      setTimeout(() => {
        if (typeof response !== "string") {
          nav(`/config/app-versions`);
        }
      }, 3000);
    }
  }, [token, validToken]);

  return (
    <div className="container major-vers-link">
      {submitMessage ? (
        <div className="alert alert-success mt-2">{submitMessage}</div>
      ) : validToken === "loading" ? (
        <div className="d-flex justify-content-center custom_spinner_container full_page">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : !validToken ? (
        <div className="d-flex flex-column justify-content-start align-items-center custom_spinner_container full_page">
          <h3 className="text-danger">The link has expired or is incorrect.</h3>
          <p>Please request another email.</p>
          <button
            className="btn btn-primary"
            onClick={() => nav("/config/app-versions")}
          >
            Go To App Version Manager
          </button>
        </div>
      ) : (
        <div className="text-center major-vers-link card col-12 col-lg-5 m-auto my-3 p-3">
          <h3>Updating Major Version</h3>
          <p>Please wait...</p>
        </div>
      )}
    </div>
  );
};

export default MajorVersionUpdate;
