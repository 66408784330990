import { RefObject, useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import CustomFontAwesomeIcons from "../assets/data/FontAwesome5Pro.json";
import { FetchResponseError, Timezone } from "../types";

// #region capitalize
// Capitalizes the first letter of a string
export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
// #endregion capitalize

// #region capitalizeFirstLetterOfEachWord
export const capitalizeFirstLetterOfEachWord = (string: string) => {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
// #endregion capitalizeFirstLetterOfEachWord

// #region convertTime
// Converts a date and time from UTC to the date and time + passed in offset
// Date and time are passed in as a string, UTC time
// time example "2023-07-27T00:00:00.000Z"
// Offset is passed in as a number, representing the timezone offset in hours
// offset example -4
// Check date is valid before converting, else return "null"
// returns a date object with the converted date and time
export function convertTime(
  time: string | Date | null,
  offset: number
): Date | null {
  // If time is a Date object, convert it to string in ISO format, else use it as is.
  let timeStr: string | null = time instanceof Date ? time.toISOString() : time;

  // Check if time is empty or null
  if (timeStr !== "" && timeStr !== null) {
    const dateObj = new Date(timeStr);

    // Check if date is valid
    if (!isNaN(dateObj.getTime())) {
      // Decompose offset into hours and minutes
      const offsetHours = Math.floor(offset);
      const offsetMinutes = Math.round((offset - offsetHours) * 60);

      dateObj.setUTCHours(
        dateObj.getUTCHours() + offsetHours,
        dateObj.getUTCMinutes() + offsetMinutes
      );

      return dateObj;
    }
  }
  return null; // Return null for invalid or empty input
}
// #endregion convertTime

// #region convertTimeToISOString
// Converts a date and time from UTC to the date and time + passed in offset
// Date and time are passed in as a string or Date object, UTC time
// time example "2023-07-27T00:00:00.000Z"
// Offset is passed in as a number, representing the timezone offset in hours
// offset example -4
// Returns the converted date and time as an ISO string without milliseconds, or null if invalid
export function convertTimeToISOString(
  time: string | Date | null,
  offset: number
): string | null {
  const dateObj = convertTime(time, offset);
  if (!dateObj) {
    return null;
  }
  return dateObj.toISOString().replace(".000Z", "");
}
// #endregion convertTimeToISOString

// #region formatDateSeconds
// Receives a date object and returns a formatted date string with seconds
// Format the date as YYYY-MM-DD HH:mm:ss
// Returns null if the date object is invalid
export function formatDateSeconds(date: Date | null): string | null {
  if (!date) {
    return null;
  }
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  const second = date.getUTCSeconds();

  const formattedDay = day < 10 ? `0${day}` : day;
  const formattedMonth = month < 10 ? `0${month}` : month;
  const formattedHour = hour < 10 ? `0${hour}` : hour;
  const formattedMinute = minute < 10 ? `0${minute}` : minute;
  const formattedSecond = second < 10 ? `0${second}` : second;

  return `${year}-${formattedMonth}-${formattedDay} ${formattedHour}:${formattedMinute}:${formattedSecond}`;
}
// #endregion formatDateSeconds

// #region getTimezoneOffset
// Returns the timezone offset in hours based on the selected timezone
export function getTimezoneOffset(
  timezone: string,
  participantTimezone: string,
  allTimezones: Timezone[],
  researcherTimezone: Timezone | null
): number {
  if (timezone === "UTC") return 0;

  if (timezone === "Participant's Timezone") {
    const participantOffset = allTimezones.find(
      (tz) => tz.name === participantTimezone
    )?.offset;
    return participantOffset !== undefined ? Number(participantOffset) : 0;
  }

  if (timezone === "ResearcherTimezone") {
    return researcherTimezone ? researcherTimezone.offset : 0;
  }

  return 0;
}

// #endregion getTimezoneOffset

// #region formatDate
// Converts a date and time from UTC to the date and time + passed in offset
// Date and time are passed in as a string, UTC time
// time example "2023-07-27T00:00:00.000Z"
export const formatDate = (date: Date | null): string => {
  if (!date) {
    return "Invalid Date";
  }
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  // const ampm = hour >= 12 ? "pm" : "am";
  // const formattedHour = hour % 12 || 12;
  const formattedHour = hour < 10 ? "0" + hour.toString() : hour.toString();
  const formattedMinute =
    minute < 10 ? "0" + minute.toString() : minute.toString();

  // return `${day}.${month}.${year} ${formattedHour}:${formattedMinute} ${ampm}`;
  return `${day}.${month}.${year} ${formattedHour}:${formattedMinute}`;
};
// #endregion formatDate

// #region useOutsideClick
// Hook that listens for clicks outside the specified ref element.
// When a click outside is detected, the provided callback is invoked.
// @param ref - React ref object representing the element to listen for outside clicks.
// @param callback - Function to be called when an outside click is detected.
export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]); // Only handleClickOutside in the dependencies array
};
// #endregion useOutsideClick

// #region isFetchResponseError
export function isFetchResponseError(
  object: any
): object is FetchResponseError {
  // console.log(object, "object");
  return (
    typeof object.errorCode === "string" &&
    typeof object.errorMessage === "string"
  );
}
// #endregion isFetchResponseError

// #region resizeImage
export async function resizeImage(
  file: File,
  size: number
): Promise<string | false> {
  return new Promise((resolve, reject) => {
    // Check if the file type is allowed
    if (!file.type.match(/image\/(png|jpg|jpeg|webp|svg\+xml|gif|tiff)/)) {
      resolve(false);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const imageUrl = reader.result as string;

      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (ctx) {
          let width = img.width;
          let height = img.height;
          const maxSide = Math.max(width, height);

          if (maxSide > size) {
            const scaleFactor = size / maxSide;
            width *= scaleFactor;
            height *= scaleFactor;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          const resizedImageUrl = canvas.toDataURL("image/webp");
          resolve(resizedImageUrl);
        } else {
          resolve(false);
        }
      };
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      resolve(false);
    };

    reader.readAsDataURL(file);
  });
}

export async function resizeImageToImage(
  file: File,
  size: number
): Promise<File | false> {
  const strBase64 = await resizeImage(file, size);

  if (!strBase64) {
    return false;
  }

  const blob = await fetch(strBase64).then((r) => r.blob());
  const newImageType = blob.type;
  console.log("newImageType", newImageType);
  const resizedFile = new File([blob], file.name, { type: newImageType });
  return resizedFile;
}
// #endregion resizeImage

// #region copyToClipboard
export function copyToClipboard(type: string, text: string) {
  //------------Copy to clipboard----------------
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
  });
  navigator.clipboard.writeText(text);
  //Swal toast message to Say Link has been copied to your clipboard
  Toast.fire({
    icon: "success",
    title: `${type} copied to clipboard`
  });
}
// #endregion copyToClipboard

export function isWorkerBusy(): boolean {
  //Check for worker
  let workerID = localStorage.getItem("workerID");
  if (workerID !== null) {
    Swal.fire({
      icon: "error",
      title: "Please wait for the current worker to finish",
      confirmButtonColor: "#3085d6"
    });
    return true;
  }
  return false;
}

// #region logStorage
// log all localStorage and sessionStorage items
export function logStorage() {
  console.log("-----BEGIN LOGGING STORAGE ITEMS-----");
  console.log("localStorage:");
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!key) {
      continue;
    }
    const value = localStorage.getItem(key);
    console.log(key, value);
  }

  console.log("sessionStorage:");
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (!key) {
      continue;
    }
    const value = sessionStorage.getItem(key);
    console.log(key, value);
  }

  console.log("-----END LOGGING STORAGE ITEMS-----");
}
// #endregion logStorage

// #region isValidHexColor
// validate if color is valid hex color
export function isValidHexColor(color: string): boolean {
  return /^#[0-9A-F]{6}$/i.test(color);
}
// #endregion isValidHexColor

// #region getAllFontAwesomeIconNames
export function getAllFontAwesomeIconNames(): string[] {
  return Object.keys(CustomFontAwesomeIcons);
}
// #endregion getAllFontAwesomeIconNames

// #region getBuildDate
export function getBuildDate(): string | null {
  // get the build date from footer id="build-info"
  const buildInfo = document.getElementById("build-info");
  if (!buildInfo) {
    return null;
  }
  // Text after Build Date: is the build date, keep text up to and including Z
  return buildInfo.innerText.split("Build Date: ")[1].split("Z")[0] + "Z";
}
// #endregion getBuildDate

export function convertStringTimeToEpoch(time: string): number {
  // Convert time string to epoch in UTC, accounting for AM and PM
  const today = new Date();
  const [timePart, period] = time.split(" ");
  let [hours, minutes] = timePart.split(":").map((t) => parseInt(t));

  // // Adjust hours for AM/PM
  // if (period.toUpperCase() === "PM" && hours < 12) {
  //   hours += 12;
  // } else if (period.toUpperCase() === "AM" && hours === 12) {
  //   hours = 0;
  // }

  // Create a new date object in UTC
  const timeToday = new Date(
    Date.UTC(
      today.getUTCFullYear(),
      today.getUTCMonth(),
      today.getUTCDate(),
      hours,
      minutes
    )
  );

  // Return the epoch time in milliseconds
  return timeToday.getTime();
}

export function convertEpochToStringTime(epoch: number): string {
  // Convert epoch time to string time
  const date = new Date(epoch);
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  // Format hours and minutes
  const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
  const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

  const period = hours < 12 ? "AM" : "PM";

  return `${formattedHours}:${formattedMinutes} ${period}`;
}

export function sortTodayEpoch(epochs: number[]): number[] {
  // set the epochs to today and then sort by time
  const today = new Date();
  const todayEpochs = epochs.map((epoch) => {
    const date = new Date(epoch);
    date.setUTCFullYear(today.getUTCFullYear());
    date.setUTCMonth(today.getUTCMonth());
    date.setUTCDate(today.getUTCDate());
    return date.getTime();
  });
  return todayEpochs.sort();
}
