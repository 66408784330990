import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ApplyFilters from "../../../assets/images/apply-filters.png";
import EditNotification from "../../../assets/images/edit-notification.png";
import IconBell from "../../../assets/images/icons/bell-solid.svg";
import IconCheck from "../../../assets/images/icons/check-solid.svg";
import { IconHelp } from "../../../assets/images/icons/icons";
import IconPlus from "../../../assets/images/icons/plus-solid.svg";
import ViewParticipants from "../../../assets/images/view-participants.png";
import { BucketsContainer } from "../../Buckets/buckets-container.component";
import "./overview.styles.scss";
import { Tabs } from "../../../types";

export type OverviewProps = {
  studyClicked: string;
  setTabClicked: (tab: Tabs) => void;
};

const Overview: React.FC<OverviewProps> = ({ studyClicked, setTabClicked }) => {
  const { clientID, studyID, surveyID } = useParams(); // Read values passed on stat
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const images = [ViewParticipants, EditNotification, ApplyFilters];

  const texts = [
    {
      main: "View Participant Count:",
      sub: "Hover on the histogram column to see compliant and non-compliant participant numbers. Click the green or red side to filter participants on the participant page.",
      className: "view-participants"
    },
    {
      main: "Edit Notifications & Compliancy:",
      sub: "Hover on the bucket name to see notification and compliancy icons. Click the bell icon to set the Welcome, Reminder, and Non-compliant notifications. Customize compliancy settings: required completions per day and grace days specific to the bucket.",
      className: "edit-notification",
      icons: [IconBell, IconCheck]
    },
    {
      main: "Apply Filters:",
      sub: "Click the green + icon on the right to apply filters based on saved demographics. Use the + icon to add more conditions or the magic wand icon to clear filters.",
      className: "apply-filters",
      icons: [IconPlus]
    }
  ];

  const toggleModal = () => {
    if (!isModalOpen) {
      setCurrentImageIndex(0); // Reset to the first image when opening the modal
    }
    setIsModalOpen(!isModalOpen);
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="container-fluid p-0 over_view_container">
      <div className="row align-items-center">
        <div className="col d-flex align-items-center">
          <h1 className="table_name mb-0">Study Overview</h1>
          <button className="btn btn-icon ml-1" onClick={toggleModal}>
            <IconHelp className="icon-image-inner" />
            <span className="tooltip">Click here for instructions</span>
          </button>
        </div>
        <div className="col p-0 d-flex justify-content-end align-items-center">
          {/* <div className="p-0"> */}
          {/* 
							// TODO: Implement download report
							<button className="btn btn-primary">
								<Icons.IconDownload className="icon icon_white download" />
								Download Report
							</button> */}
          {/* </div> */}
        </div>
      </div>
      {/* Study is selected */}
      {studyClicked === "study" && (
        <BucketsContainer
          client={clientID}
          study={studyID}
          setTabClicked={setTabClicked}
        />
      )}

      {isModalOpen && (
        <div className="modal-overlay" onClick={toggleModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-image-container">
              <img
                src={images[currentImageIndex]}
                alt="Modal Content"
                className="modal-image"
              />
            </div>
            {/* text for the modal */}
            <div
              className={`overlay-text ${texts[currentImageIndex].className}`}
            >
              <strong className="main-text">
                {texts[currentImageIndex].main}
              </strong>
              <div className="sub-text">{texts[currentImageIndex].sub}</div>
              {texts[currentImageIndex].icons && (
                <div className="icons-container">
                  {texts[currentImageIndex].icons?.map((Icon, index) => (
                    <img
                      key={index}
                      src={Icon.toString()}
                      alt={`Icon ${index}`}
                      className={`modal-icon small-icon ${texts[currentImageIndex].className === "apply-filters" ? "bold-icon" : ""}`}
                    />
                  ))}
                </div>
              )}
            </div>

            <div className="pagination-buttons">
              {currentImageIndex !== 0 && (
                <button className="prev-button" onClick={prevImage}>
                  Previous
                </button>
              )}
              {currentImageIndex !== images.length - 1 && (
                <button className="next-button" onClick={nextImage}>
                  Next
                </button>
              )}
            </div>
            <button className="btn btn-close" onClick={toggleModal}>
              X
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Overview;
