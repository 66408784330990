import React, { useEffect, useState } from "react";
import {
  Country,
  Language,
  Tags,
  UnassignedParticipant
} from "../../../../types";

import Swal from "sweetalert2";
import { IconBroom } from "../../../../assets/images/icons/icons";
import { addParticipantToChannel } from "../../../../models/chat.model";
import CountryFilter from "../../../Tables/Filters/filterCountries.component";
import LanguagesFilter from "../../../Tables/Filters/filterLanguages.component";
import Search from "../../../Tables/Filters/filterSearch.component";
import TagsFilter from "../../../Tables/Filters/filterTags.component";
import PaginationNavigation from "../../../Tables/PaginationNavigation/paginationNavigation";

type ChannelParticipantModalProps = {
  closeModal: () => void;
  shown: boolean;
  unassignedChannelParticipants: UnassignedParticipant[];
  loadingParticipants: boolean;
  countries: Country[];
  languages: Language[];
  channelID: string | undefined;
  clientID: string | undefined;
  studyID: string | undefined;
  // setDataRefresh: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChannelParticipantModal: React.FC<ChannelParticipantModalProps> = ({
  closeModal,
  shown,
  unassignedChannelParticipants,
  loadingParticipants,
  countries,
  languages,
  channelID,
  clientID,
  studyID
  // setDataRefresh,
}) => {
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [viewedParticipant, setViewedParticipant] =
    useState<UnassignedParticipant | null>(null);
  const [showDetails, setShowDetails] = useState(false);
  // const [originalParticipants, setOriginalParticipants] = useState<any[]>(
  // 	unassignedChannelParticipants
  // );
  const [message, setMessage] = useState<string>("");
  const [currentPage, setCurrentPage] = useState(1);
  const [participantsPerPage, setParticipantsPerPage] = useState(20);
  const [currentParticipants, setCurrentParticipants] = useState<any[]>([]);

  const [response, setResponse] = useState<boolean>(false);
  const [userAdded, setUserAdded] = useState<boolean>(false);

  // State variable to hold the filtered participants
  const [filteredAddParticipants, setFilteredAddParticipants] = useState<any[]>(
    []
  );
  const [participantsAddCountryFilter, setParticipantsAddCountryFilter] =
    useState<string[]>([]);

  const [participantsAddLanguagesFilter, setParticipantsAddLanguagesFilter] =
    useState<string[]>([]);
  const [participantsTagsFilter, setParticipantsTagsFilter] = useState<
    string[]
  >([]);
  const [participantsTags, setParticipantsTags] = useState<Tags[]>([]);
  const [participantsSearch, setParticipantsSearch] = useState<string>("");
  // For selecting participants
  const [selectedParticipants, setSelectedParticipants] = useState<any[]>([]);
  const [busySubmitting, setBusySubmitting] = useState<boolean>(false);

  const handleViewParticipantDetails = (participant: UnassignedParticipant) => {
    // console.log(participant);
    setViewedParticipant(participant);
    setShowDetails(true);
  };

  // Adding participants to a study
  // const handleAddParticipantToChannel = async () => {
  // 	// console.log("CLICKED");
  // 	try {
  // 		if (
  // 			clientID &&
  // 			clientID !== "" &&
  // 			clientID !== "undefined" &&
  // 			studyID &&
  // 			channelID
  // 		) {
  // 			// console.log(clientID, studyID, surveyID, selectedParticipants);
  // 			const result = await addParticipantToChannel(
  // 				clientID,
  // 				channelID,
  // 				selectedParticipants
  // 			);
  // 			//   console.log(result);
  // 			//   if (result) {
  // 			//     setResponse(true);
  // 			//     console.log(result.message);
  // 			//     setMessage(result.message);
  // 			//     setSelectedParticipants([]);
  // 			//     fetchAllStudyParticipantsNotInSurvey();

  // 			//     if (result.success === true) {
  // 			//       setUserAdded(true);
  // 			//     }
  // 			//   }
  // 			//
  // 			setBusySubmitting(false);

  // 			// console.log(result, "result");

  // 			if (result.rStatus !== "success") {
  // 				Swal.fire({
  // 					icon: "error",
  // 					title: "Error",
  // 					text: result.rMessage,
  // 					confirmButtonColor: "#3085d6",
  // 				});
  // 			} else {
  // 				Swal.fire({
  // 					icon: "success",
  // 					title: "Success",
  // 					text: result.rMessage,
  // 					confirmButtonColor: "#3085d6",
  // 				});
  // 			}
  // 		} else {
  // 			console.log("clientID is undefined");
  // 			setMessage("Failed to Send");
  // 		}
  // 	} catch (error) {
  // 		console.error(error);
  // 	}
  // };

  const handleClearFilters = () => {
    // Clear the filter state
    setParticipantsSearch("");
    setFilterApplied(!filterApplied);
    setParticipantsTagsFilter([]);
    // setParticipantsStatusFilter([]);
    // setParticipantsUDIDFilter(false);
    setParticipantsAddLanguagesFilter([]);
    setParticipantsAddCountryFilter([]);
  };

  // Select all participants
  const handleSelectAllCheckboxChange = () => {
    const allParticipantsSelected =
      selectedParticipants.length === filteredAddParticipants.length;

    if (allParticipantsSelected) {
      // If all participants are already selected, remove them from the array
      setSelectedParticipants([]);
    } else {
      // If not all participants are selected, add them to the array
      setSelectedParticipants(filteredAddParticipants.map((p) => p.id));
    }
  };

  const handleAddParticipantToChannel = async () => {
    // console.log("CLICKED");
    try {
      if (selectedParticipants.length > 0 && channelID && clientID && studyID) {
        const result: any = await addParticipantToChannel(
          clientID,
          channelID,
          selectedParticipants,
          studyID
        );
        // console.log(result);
        if (result) {
          // setResponse(true);
          // console.log(result.rMessage);
          setMessage(result.rMessage);
          setSelectedParticipants([]);
          // fetchAllStudyParticipantsNotInSurvey();
        }
        //
        setBusySubmitting(false);

        // console.log(result, "result");

        if (result.rStatus !== "success") {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: result.rMessage,
            confirmButtonColor: "#3085d6"
          });
        } else {
          const response = await Swal.fire({
            icon: "success",
            title: "Success",
            text: result.rMessage,
            confirmButtonColor: "#3085d6"
          });

          // if (response.isConfirmed) {
          // 	setDataRefresh(true);
          // }
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please select at least one participant",
          confirmButtonColor: "#3085d6"
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddParticipant = async () => {
    if (selectedParticipants.length > 0) {
      const currectWorkerID = localStorage.getItem("workerID");
      if (
        currectWorkerID !== null &&
        currectWorkerID !== undefined &&
        currectWorkerID !== ""
      ) {
        Swal.fire({
          icon: "error",
          title: "Please wait for the current worker to finish",
          confirmButtonColor: "#3085d6"
        });
      } else {
        handleAddParticipantToChannel();
      }
    }
  };

  // Select Single Participant
  const handleCheckboxChange = (participantId: string) => {
    setSelectedParticipants((prevSelected) => {
      const isAlreadySelected = prevSelected.includes(participantId);

      if (isAlreadySelected) {
        // If the participantId is already selected, remove it from the array
        return prevSelected.filter((id) => id !== participantId);
      } else {
        // If the participantId is not selected, add it to the array
        return [...prevSelected, participantId];
      }
    });
  };

  useEffect(() => {
    // console.log(currentParticipants, filteredParticipants);
    if (filteredAddParticipants && filteredAddParticipants.length > 0) {
      setCurrentParticipants(
        filteredAddParticipants.slice(
          (currentPage - 1) * participantsPerPage,
          currentPage * participantsPerPage
        )
      );
    }
  }, [currentPage, participantsPerPage, filteredAddParticipants]);

  // Use effect that applies filters to the participants, related to the mapping
  useEffect(() => {
    // Filter participants based on filters
    let filteredParticipants = Array.isArray(unassignedChannelParticipants)
      ? [...unassignedChannelParticipants]
      : [];

    // Filter by tags
    if (participantsTagsFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        participantsTagsFilter.some((tag) =>
          participant.participant_tags?.includes(tag)
        )
      );
    }

    // Filter by languages
    if (participantsAddLanguagesFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        participantsAddLanguagesFilter.some((language) =>
          participant?.participant_lang_iso?.includes(language)
        )
      );
    }

    // Filter by countries
    if (participantsAddCountryFilter.length > 0) {
      filteredParticipants = filteredParticipants.filter((participant) =>
        participantsAddCountryFilter.some((country) =>
          participant.participant_country_iso?.includes(country)
        )
      );
    }

    // Filter by search
    if (participantsSearch !== "") {
      filteredParticipants = filteredParticipants.filter(
        (participant) =>
          participant.sender_name
            ?.toLowerCase()
            .includes(participantsSearch.toLowerCase()) ||
          participant.participant_email
            ?.toLowerCase()
            .includes(participantsSearch.toLowerCase()) ||
          participant.sender_name
            ?.toLowerCase()
            .includes(participantsSearch.toLowerCase())
      );
    }

    // console.log(filteredParticipants);

    // Set filtered participants
    setFilteredAddParticipants(filteredParticipants);
    // console.log(filteredAddParticipants);
  }, [
    unassignedChannelParticipants,
    participantsTagsFilter,
    participantsAddLanguagesFilter,
    participantsAddCountryFilter,
    participantsSearch
  ]);

  useEffect(() => {
    if (Array.isArray(unassignedChannelParticipants)) {
      // get all unique participants Tags
      const pTags = [
        ...new Set(
          unassignedChannelParticipants
            .map((p) => p.participant_tags)
            .filter((tags): tags is string[] => Boolean(tags))
            .flat()
        )
      ].sort();

      setParticipantsTags(
        pTags.map((tag) => {
          return { id: tag, name: tag };
        })
      );
    }
  }, [unassignedChannelParticipants]);

  return (
    <>
      {shown && (
        <div
          className={`modal channel-participant-modal ${
            shown ? "show" : "hide"
          }`}
        >
          <div className={`modal-dialog modal-dialog-centered`}>
            <div className="modal-content">
              <span className="close" onClick={closeModal}>
                &times;
              </span>
              <div className="modal-header">
                <div className="container-fluid">
                  <div className="row">
                    <h3 className="modal_page_header">
                      {showDetails
                        ? `${viewedParticipant?.sender_name} Details`
                        : "Add Participants to Channel"}
                    </h3>
                  </div>
                </div>
              </div>
              <div className="modal-body">
                <div className="slide-container">
                  <div
                    className={`slide-content ${
                      showDetails ? "show-details" : ""
                    }`}
                  >
                    {/* Participants List View */}
                    <div className="slide-view">
                      <div className="container-fluid modal_body_content card">
                        <div className="row card-header dropdown_container">
                          <div className="lang_country_container">
                            <div className="col language_drop_container">
                              <LanguagesFilter
                                jsonlanguagesData={languages}
                                onLanguagesFilterChange={
                                  setParticipantsAddLanguagesFilter
                                }
                                filtersCleared={!filterApplied}
                                location={"chat-modal"}
                              />
                            </div>
                            <div className="col country_drop_container">
                              <CountryFilter
                                jsonCountriesData={countries}
                                onCountryFilterChange={
                                  setParticipantsAddCountryFilter
                                }
                                filtersCleared={!filterApplied}
                                location={"chat-modal"}
                              />
                            </div>
                          </div>
                          {/* <div>Groups</div> */}

                          <div className="tags_keyword_container">
                            <div className="tags_drop_container col">
                              <TagsFilter
                                jsonTagsData={participantsTags}
                                onTagsFilterChange={setParticipantsTagsFilter}
                                filtersCleared={!filterApplied}
                                location={"chat-modal"}
                              />
                            </div>

                            {/* Keyword Search, Clear Filters */}
                            <div className="keyword_clear_container col">
                              <Search
                                onSearchChange={setParticipantsSearch}
                                filtersCleared={!filterApplied}
                                // location={"modal"}
                              />
                              {/* Clear Filters */}
                              <button
                                className="btn btn-primary ms-2 btn_clear_filters"
                                disabled={false}
                                onClick={handleClearFilters}
                              >
                                <IconBroom className="icon icon_white" />
                              </button>
                            </div>
                          </div>

                          <div className="p-0">
                            <div className="row card-header amount_container justify-content-between">
                              <div className="col-1 d-flex align-items-center p-0">
                                <div className="checkbox_select">
                                  {/* SELECT ALL */}
                                  <input
                                    type="checkbox"
                                    name=""
                                    id=""
                                    onChange={handleSelectAllCheckboxChange}
                                    checked={
                                      selectedParticipants.length ===
                                        unassignedChannelParticipants.length &&
                                      unassignedChannelParticipants.length > 0
                                    }
                                  />
                                </div>
                                <div className="ps-1">
                                  {selectedParticipants.length > 0
                                    ? selectedParticipants.length
                                    : ""}
                                </div>
                              </div>
                              <div className="col-5 d-flex">
                                <div className="message">
                                  {message ? message : <></>}
                                </div>
                              </div>
                              <div className="col-auto d-flex justify-content-end p-0">
                                <button
                                  className="add_button"
                                  onClick={() => handleAddParticipant()}
                                >
                                  Add Selected Participants to Channel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="channel-modal-participants">
                        {/* PARTICIPANT CONTAINER */}
                        {loadingParticipants ? (
                          <div className="row d-flex justify-content-center align-items-center py-1">
                            <div className="spinner-border"></div>
                          </div>
                        ) : (
                          <>
                            {typeof unassignedChannelParticipants ===
                            "string" ? (
                              <div className="row d-flex justify-content-center align-items-center py-1">
                                No participants found
                              </div>
                            ) : (
                              <div className="row">
                                <div className="add_channel_participants_container">
                                  {/* Iterate through original participants, displaying names */}
                                  {filteredAddParticipants.length === 0 ? (
                                    <div className="row d-flex justify-content-center align-items-center py-1">
                                      No results found. Please adjust your
                                      filters.
                                    </div>
                                  ) : (
                                    <>
                                      {currentParticipants?.map(
                                        (participant, i) => (
                                          <div
                                            key={i}
                                            className="row d-flex justify-content-between align-items-center add_channel_participant_single"
                                          >
                                            <div className="col-4 d-flex">
                                              <input
                                                className="checkbox_select"
                                                type="checkbox"
                                                name=""
                                                id=""
                                                onChange={() => {
                                                  handleCheckboxChange(
                                                    participant.id
                                                  );
                                                }}
                                                checked={selectedParticipants.includes(
                                                  participant.id
                                                )}
                                              />
                                              <div
                                                className="add_channel_participant_name"
                                                onClick={() =>
                                                  handleViewParticipantDetails(
                                                    participant
                                                  )
                                                }
                                              >
                                                {participant.sender_name}
                                              </div>
                                            </div>
                                            <div className="col-4 add_channel_participant_name">
                                              {participant.participant_email}
                                            </div>
                                            {/* COUNTRIES */}
                                            <div className="col-2 d-flex ">
                                              {
                                                countries.find(
                                                  (country) =>
                                                    country.iso_code ===
                                                    participant.participant_country_iso
                                                )?.name
                                              }{" "}
                                            </div>
                                            {/* LANGUAGES */}
                                            <div className="col-2 d-flex">
                                              {
                                                languages.find(
                                                  (language) =>
                                                    language.iso_code ===
                                                    participant.participant_lang_iso
                                                )?.name
                                              }
                                            </div>
                                          </div>
                                        )
                                      )}
                                      <PaginationNavigation
                                        currentPage={currentPage}
                                        totalResults={
                                          filteredAddParticipants
                                            ? filteredAddParticipants.length
                                            : 0
                                        }
                                        resultsPerPage={participantsPerPage}
                                        setCurrentPage={setCurrentPage}
                                      />
                                    </>
                                  )}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {/* Participant Details View */}
                    <div className="slide-view">
                      {/* Display the details of the viewedParticipant here */}
                      {viewedParticipant && (
                        <form className="participant-details pt-3">
                          <div className="row g-2">
                            <div className="col-md">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="participantID"
                                  placeholder="name@example.com"
                                  value={viewedParticipant.id}
                                  readOnly
                                />
                                <label htmlFor="participantID">
                                  Participant ID
                                </label>
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="participantEmail"
                                  value={viewedParticipant.participant_email}
                                  readOnly
                                />
                                <label htmlFor="participantEmail">Email</label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-2">
                            <div className="col-md">
                              <div className="form-floating mb-3">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="participantName"
                                  placeholder="name@example.com"
                                  value={viewedParticipant.sender_name}
                                  readOnly
                                />
                                <label htmlFor="participantName">
                                  Participant Name
                                </label>
                              </div>
                            </div>
                            <div className="col-md"></div>
                          </div>
                          <div className="row g-2">
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="participantLanguage"
                                  value={
                                    // viewedParticipant.lang_iso
                                    languages.find(
                                      (language) =>
                                        language.iso_code ===
                                        viewedParticipant.participant_lang_iso
                                    )?.name
                                  }
                                  readOnly
                                />
                                <label htmlFor="participantLanguage">
                                  Language
                                </label>
                              </div>
                            </div>
                            <div className="col-md">
                              <div className="form-floating">
                                <input
                                  type="text"
                                  className="form-control"
                                  id="participantCountry"
                                  value={
                                    // viewedParticipant.lang_iso
                                    countries.find(
                                      (country) =>
                                        country.iso_code ===
                                        viewedParticipant.participant_country_iso
                                    )?.name
                                  }
                                  readOnly
                                />
                                <label htmlFor="participantCountry">
                                  Country
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="row g-2 pt-3">
                            <div className="col-md">
                              <div className="form-floating">
                                {/* tags */}
                                <input
                                  type="text"
                                  className="form-control"
                                  id="participantTags"
                                  value={viewedParticipant.participant_tags?.join(
                                    ", "
                                  )}
                                  readOnly
                                />
                                <label htmlFor="participantTags">Tags</label>
                              </div>
                            </div>
                            <div className="col-md"></div>
                          </div>
                          <div className="d-flex justify-content-between">
                            {/* Add a back button to go back to the list */}
                            <button
                              className="btn btn-primary mt-3"
                              type="button"
                              onClick={() => {
                                setShowDetails(false);
                                setTimeout(() => {
                                  setViewedParticipant(null);
                                }, 300); // match the transition duration
                              }}
                            >
                              Go Back
                            </button>
                            {/* <button
															className="btn btn-info text-white mt-3"
															type="button"
															onClick={() => {
																setShowDetails(
																	false
																);
																setTimeout(
																	() => {
																		setViewedParticipant(
																			null
																		);
																	},
																	300
																); // match the transition duration
															}}
														>
															Edit Profile
														</button> */}
                          </div>
                        </form>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-danger"
                            onClick={closeModal}
                        >
                            Close
                        </button>
                    </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChannelParticipantModal;
