import React from "react";
import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldNotification from "../../Fields/fieldNotification.component";
import FieldTextArea from "../../Fields/fieldTextArea.component";
import FieldTime from "../../Fields/fieldTime.component";
import { AddStudyWizardStepDiaryNotificationsProps } from "../addStudyWizard.component";
import AddStudyWizardFooter from "../addStudyWizardFooter.component";

interface StepDiaryNotificationsProps
  extends AddStudyWizardStepDiaryNotificationsProps {}

const StepDiaryNotifications: React.FC<StepDiaryNotificationsProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  handleWizardBack,
  handleInputChange,
  handleWizardSubmit,
  // Fields
  diaryWelcomeNotification,
  diaryDailyReminderNotification,
  diaryDailyReminderTime,
  diaryNonComplianceNotification,
  diaryNonComplianceTime,
  pageValid,
  inputErrors,
  diaryWelcomeNotificationsData,
  diaryDailyReminderNotificationsData,
  diaryNonComplianceNotificationsData,
  diaryNewWelcomeNotification,
  diaryNewDailyReminderNotification,
  diaryNewNonComplianceNotification,
  diaryName,
  diaryIncludeNotifications
}) => {
  return (
    <div className="card card-default add_study_form_page p-4">
      <h3 className="form_header">Diary Notifications: {diaryName}</h3>
      <div className="add_study_form_page_inputs">
        {/* checkbox  */}
        <FieldCheckbox
          label="Send out notifications for this diary?"
          tooltip="This will allow you to include notifications for this diary"
          tooltipPosition="bottom"
          inputName="diaryIncludeNotifications"
          inputValue={diaryIncludeNotifications}
          onInputChange={handleInputChange}
          error={
            inputErrors?.diaryIncludeNotifications
              ? inputErrors.diaryIncludeNotifications
              : null
          }
        />
        {diaryIncludeNotifications && (
          <>
            <FieldNotification
              label="Welcome Notification"
              tooltip="The push notification that each participant will receive when the diary becomes active. Typically to let them know the diary is now available to complete, and how often they should complete it."
              inputName="diaryWelcomeNotification"
              inputValue={diaryWelcomeNotification}
              inputOptions={diaryWelcomeNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryWelcomeNotification
                  ? inputErrors.diaryWelcomeNotification
                  : null
              }
            />
            {/* if diaryWelcomeNotification = new */}

            {diaryWelcomeNotification === "new" && (
              <>
                <FieldTextArea
                  label="New Welcome Message"
                  tooltip="This will be where you select the new welcome message for the study"
                  inputName="diaryNewWelcomeNotificationPhrase"
                  inputValue={
                    diaryNewWelcomeNotification
                      ? diaryNewWelcomeNotification
                      : ""
                  }
                  onInputChange={handleInputChange}
                  error={
                    inputErrors?.diaryNewWelcomeNotificationPhrase
                      ? inputErrors.diaryNewWelcomeNotificationPhrase
                      : null
                  }
                />
              </>
            )}

            <FieldNotification
              label="Daily Reminder Notification"
              tooltip="A daily push notification reminder, to be sent at a selected time - typically to remind participants to complete their diary / remember to consider their diary that day."
              inputName="diaryDailyReminderNotification"
              inputValue={diaryDailyReminderNotification}
              inputOptions={diaryDailyReminderNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryDailyReminderNotification
                  ? inputErrors.diaryDailyReminderNotification
                  : null
              }
            />

            {
              // if diaryDailyReminderNotification = new
              diaryDailyReminderNotification === "new" && (
                <>
                  <FieldTextArea
                    label="New Daily Reminder Message"
                    tooltip="This will be where you select the new daily reminder message for the study"
                    inputName="diaryNewDailyReminderNotificationPhrase"
                    inputValue={
                      diaryNewDailyReminderNotification
                        ? diaryNewDailyReminderNotification
                        : ""
                    }
                    onInputChange={handleInputChange}
                    error={
                      inputErrors?.diaryNewDailyReminderNotificationPhrase
                        ? inputErrors.diaryNewDailyReminderNotificationPhrase
                        : null
                    }
                  />
                </>
              )
            }
            <FieldTime
              label="Daily Reminder Time"
              tooltip="The time that the daily reminder should be sent."
              inputName="diaryDailyReminderTime"
              inputValue={diaryDailyReminderTime ? diaryDailyReminderTime : ""}
              onInputChange={handleInputChange}
              subLabel="User local time"
              error={
                inputErrors?.diaryDailyReminderTime
                  ? inputErrors.diaryDailyReminderTime
                  : null
              }
            />
            <FieldNotification
              label="Non-Compliance Notification"
              tooltip="A daily push notification reminder, sent daily at a specified time, if the participant doesn't complete the minimum number of completes for that day."
              inputName="diaryNonComplianceNotification"
              inputValue={diaryNonComplianceNotification}
              inputOptions={diaryNonComplianceNotificationsData}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryNonComplianceNotification
                  ? inputErrors.diaryNonComplianceNotification
                  : null
              }
            />
            {
              // if diaryNonComplianceNotification = new
              diaryNonComplianceNotification === "new" && (
                <>
                  <FieldTextArea
                    label="New Non-Compliance Message"
                    tooltip="This will be where you select the new non-compliance message for the study"
                    inputName="diaryNewNonComplianceNotificationPhrase"
                    inputValue={
                      diaryNewNonComplianceNotification
                        ? diaryNewNonComplianceNotification
                        : ""
                    }
                    onInputChange={handleInputChange}
                    error={
                      inputErrors?.diaryNewNonComplianceNotificationPhrase
                        ? inputErrors.diaryNewNonComplianceNotificationPhrase
                        : null
                    }
                  />
                </>
              )
            }
            <FieldTime
              label="Non-Compliance Time"
              tooltip="The time that the non-compliance reminder should be sent."
              inputName="diaryNonComplianceTime"
              inputValue={diaryNonComplianceTime ? diaryNonComplianceTime : ""}
              onInputChange={handleInputChange}
              subLabel="User local time"
              error={
                inputErrors?.diaryNonComplianceTime
                  ? inputErrors.diaryNonComplianceTime
                  : null
              }
            />
          </>
        )}

        <AddStudyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          handleWizardSubmit={handleWizardSubmit}
          pageValid={pageValid}
        />
      </div>
    </div>
  );
};

export default StepDiaryNotifications;
