import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";

/* ------------------------------------------------- */
/* --------------Validation Function---------------- */
/* ------------------------------------------------- */
// #region type validators
// #region isTag
function isTag(obj: any): obj is Tag {
  const validID: boolean = typeof obj.id === "string";
  const validName: boolean = typeof obj.name === "string";
  const AllValid = validID && validName;

  if (!AllValid) {
    let errors: string[] = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
        Swal.fire({
          icon: "error",
          title: `Invalid Tag`,
          html: "Please contact support<br /><br />missing id",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }

      if (!validName) {
        if (!obj.hasOwnProperty("name")) {
          errors.push("missing name");
        } else {
          errors.push(`Invalid type for name`);
        }
      }

      const displayMessage = `<strong>Please contact support</strong><br /><br />`;
      Swal.fire({
        icon: "error",
        title: `Invalid Tag: ${obj.id}`,
        html: displayMessage + errors.join("<br />"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }

  return AllValid;
}
// #endregion isTag

// #region isFullTag
//All tags with participants count
function isFullTag(obj: any): obj is Tags {
  // return (
  //   typeof obj.id === "number" &&
  //   typeof obj.name === "string" &&
  //   typeof obj.created_at === "string" &&
  //   typeof obj.updated_at === "string" &&
  //   typeof obj.participant_count === "number"
  // );

  const validID: boolean = typeof obj.id === "string";
  const validName: boolean = typeof obj.name === "string";
  const validCreatedAt: boolean = typeof obj.created_at === "string";
  const validUpdatedAt: boolean = typeof obj.updated_at === "string";
  const validParticipantCount: boolean =
    typeof obj.participant_count === "number";

  const AllValid =
    validID &&
    validName &&
    validCreatedAt &&
    validUpdatedAt &&
    validParticipantCount;

  if (!AllValid) {
    let errors: string[] = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Invalid type for name`);
      }
    }

    if (!validCreatedAt) {
      if (!obj.hasOwnProperty("created_at")) {
        errors.push("missing created_at");
      } else {
        errors.push(`Invalid type for created_at`);
      }
    }

    if (!validUpdatedAt) {
      if (!obj.hasOwnProperty("updated_at")) {
        errors.push("missing updated_at");
      } else {
        errors.push(`Invalid type for updated_at`);
      }
    }

    if (!validParticipantCount) {
      if (!obj.hasOwnProperty("participant_count")) {
        errors.push("missing participant_count");
      } else {
        errors.push(`Invalid type for participant_count`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid Tag`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isFullTag

// #region isStudy
function isStudy(obj: any): obj is Study {
  // id: string;
  //   study_name: string;
  //   bucket: string[];

  const validID: boolean = typeof obj.id === "string";
  const validName: boolean = typeof obj.study_name === "string";
  const validBucket: boolean =
    Array.isArray(obj.bucket) &&
    obj.bucket.every((bucket: any) => typeof bucket === "string");

  const AllValid = validID && validName && validBucket;

  if (!AllValid) {
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        console.log("missing id");
      } else {
        console.log(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("study_name")) {
        console.log("missing study_name");
      } else {
        console.log(`Invalid type for study_name`);
      }
    }

    if (!validBucket) {
      if (!obj.hasOwnProperty("bucket")) {
        console.log("missing bucket");
      } else {
        console.log(`Invalid type for bucket`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid Tag Study`,
      html: displayMessage,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isStudy

// #region isSurvey
function isSurvey(obj: any): obj is Survey {
  const validID: boolean = typeof obj.id === "string";
  const validName: boolean = typeof obj.survey_name === "string";
  const validCreatedAt: boolean = typeof obj.created_at === "string";

  const AllValid = validID && validName && validCreatedAt;

  if (!AllValid) {
    console.log(obj);
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        console.log("missing id");
      } else {
        console.log(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("survey_name")) {
        console.log("missing survey_name");
      } else {
        console.log(`Invalid type for survey_name`);
      }
    }

    if (!validCreatedAt) {
      if (!obj.hasOwnProperty("created_at")) {
        console.log("missing created_at");
      } else {
        console.log(`Invalid type for created_at`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid Tag Survey`,
      html: displayMessage,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isSurvey

// #region isParticipant
function isParticipant(obj: any): obj is ParticipantTag {
  //   type Participant = {
  //     id: string;
  //     participant_full_name: string;
  //     participant_email: string;
  //     participant_full_mobile: string;
  //     participant_study_id: string;
  //     participant_buckets: ParticipantBuckets[];
  //     participant_surveys: string[];
  //     participant_tags: string[];
  //     study_id: string;
  //     study_status: string;
  // }

  const validID: boolean = typeof obj.id === "string";
  const validExternalID: boolean = typeof obj.external_id === "string";
  const validParticipantFullName: boolean =
    typeof obj.participant_full_name === "string";
  const validParticipantEmail: boolean =
    typeof obj.participant_email === "string";
  const validParticipantFullMobile: boolean =
    typeof obj.participant_full_mobile === "string";
  const validParticipantSurveys: boolean =
    Array.isArray(obj.participant_surveys) &&
    obj.participant_surveys.every((survey: any) => typeof survey === "string");
  const validParticipantTags: boolean =
    Array.isArray(obj.participant_tags) &&
    obj.participant_tags.every((tag: any) => typeof tag === "string");
  const validStudyID: boolean = typeof obj.study_id === "string";
  const validStudyStatus: boolean = typeof obj.study_status === "string";

  const validParticipantBuckets: boolean =
    Array.isArray(obj.participant_buckets) &&
    obj.participant_buckets.every((bucket: any) => typeof bucket === "object");

  const AllValid =
    validID &&
    validExternalID &&
    validParticipantFullName &&
    validParticipantEmail &&
    validParticipantFullMobile &&
    validParticipantSurveys &&
    validParticipantTags &&
    validStudyID &&
    validStudyStatus &&
    validParticipantBuckets;

  if (!AllValid) {
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validExternalID) {
      if (!obj.hasOwnProperty("external_id")) {
        errors.push("missing external_id");
      } else {
        errors.push(`Invalid type for external_id`);
      }
    }

    if (!validParticipantFullName) {
      if (!obj.hasOwnProperty("participant_full_name")) {
        errors.push("missing participant_full_name");
      } else {
        errors.push(`Invalid type for participant_full_name`);
      }
    }

    if (!validParticipantEmail) {
      if (!obj.hasOwnProperty("participant_email")) {
        errors.push("missing participant_email");
      } else {
        errors.push(`Invalid type for participant_email`);
      }
    }

    if (!validParticipantFullMobile) {
      if (!obj.hasOwnProperty("participant_full_mobile")) {
        errors.push("missing participant_full_mobile");
      } else {
        errors.push(`Invalid type for participant_full_mobile`);
      }
    }

    if (!validStudyID) {
      if (!obj.hasOwnProperty("study_id")) {
        errors.push("missing study_id");
      } else {
        errors.push(`Invalid type for study_id`);
      }
    }

    if (!validParticipantBuckets) {
      if (!obj.hasOwnProperty("bucket")) {
        errors.push("missing bucket");
      } else {
        errors.push(`Invalid type for bucket`);
      }
    }

    if (!validParticipantSurveys) {
      if (!obj.hasOwnProperty("participant_surveys")) {
        errors.push("missing participant_surveys");
      } else {
        errors.push(`Invalid type for participant_surveys`);
      }
    }

    if (!validParticipantTags) {
      if (!obj.hasOwnProperty("participant_tags")) {
        errors.push("missing participant_tags");
      } else {
        errors.push(`Invalid type for participant_tags`);
      }
    }

    if (!validStudyID) {
      if (!obj.hasOwnProperty("study_id")) {
        errors.push("missing study_id");
      } else {
        errors.push(`Invalid type for study_id`);
      }
    }

    if (!validStudyStatus) {
      if (!obj.hasOwnProperty("study_status")) {
        errors.push("missing study_status");
      } else {
        errors.push(`Invalid type for study_status`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;
    console.log("errors", errors);
    console.log(obj);
    Swal.fire({
      icon: "error",
      title: `Invalid Tag Participant`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isParticipant

// #region isTagDetails
//Full validation for the tag details
function isTagDetails(obj: any): obj is TypeTagDetails {
  const validTag = obj.tag && isTag(obj.tag);
  const validParticipantsWithTag: boolean =
    Array.isArray(obj.participantsWithTag) &&
    obj.participantsWithTag.every(isParticipant);
  const validParticipantsWithoutTag: boolean =
    Array.isArray(obj.participantsWithoutTag) &&
    obj.participantsWithoutTag.every(isParticipant);

  const AllValid =
    validTag && validParticipantsWithTag && validParticipantsWithoutTag;
  if (!AllValid) {
    let errors: string[] = [];
    if (!validTag) {
      console.log("tag not valid");
      if (!obj.hasOwnProperty("tag")) {
        errors.push("missing tag");
      }
    }
    if (!validParticipantsWithTag) {
      console.log("participantsWithTag not valid");
      if (!obj.hasOwnProperty("participantsWithTag")) {
        errors.push("missing participantsWithTag");
      }
    }
    if (!validParticipantsWithoutTag) {
      console.log("participantsWithoutTag not valid");
      if (!obj.hasOwnProperty("participantsWithoutTag")) {
        errors.push("missing participantsWithoutTag");
      }
    }

    if (errors.length > 0) {
      console.log("errors", errors);
      const displayMessage = `<strong>Please contact support</strong><br /><br />`;

      Swal.fire({
        icon: "error",
        title: `Invalid Tag Object`,
        html: displayMessage + errors.join("<br />"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return AllValid;
}
// #endregion isTagDetails

// #region isStudySurveys
//functions validation for study and surveys only
function isStudySurveys(obj: any): obj is StudySurveysFilters {
  const validStudy = isStudy(obj.study);
  const validSurveys =
    Array.isArray(obj.surveys) && obj.surveys.every(isSurvey);
  const AllValid = validStudy && validSurveys;
  if (!AllValid) {
    console.log("Not valid");
    if (!validStudy) {
      console.log("study not valid");
    }
    if (!validSurveys) {
      console.log("surveys not valid");
    }
  } else {
    console.log("valid");
  }
  return AllValid;
}
// #endregion isStudySurveys
// #endregion type validators

/* ------------------------------------------------- */
/* ---------------------Types----------------------- */
/* ------------------------------------------------- */
// #region types
type Tags = {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
  participant_count: number;
};

export type Tag = {
  id: string;
  name: string;
  created_at: Date;
  updated_at: Date;
};

export type ParticipantBuckets = {
  name: string;
  bucket_id: string;
};

export type ParticipantTag = {
  id: string;
  external_id: string;
  participant_full_name: string;
  participant_email: string;
  participant_full_mobile: string;
  participant_buckets: ParticipantBuckets[];
  participant_surveys: string[];
  participant_tags: string[];
  study_id: string;
  study_status: string;
};

type Study = {
  id: string;
  study_name: string;
  bucket: string[];
};

type Survey = {
  id: string;
  survey_name: string;
  created_at: string;
};

export type StudySurveysFilters = {
  study: Study;
  surveys: Survey[];
};

export type TypeTagDetails = {
  id: string;
  tag: Tag;
  participantsWithTag?: ParticipantTag[];
  participantsWithoutTag?: ParticipantTag[];
};
// #endregion types

/* ------------------------------------------------- */
/* ------------------Functions---------------------- */
/* -----------------------Get----------------------- */
/*--------------Just the Tags--------------*/
// #region fetchAllTags
//For cache clearing we will pass study ID too TODO Remove study ID
export async function fetchAllTags(clientID: string): Promise<Tag[] | string> {
  try {
    const response = await apiFetcher<any>("/tags/get/all", "POST", {
      body: {
        clientID: clientID
      }
    });

    if (response && response.status === 200 && response.data !== null) {
      // Check if every item in the response.data is of type Tags
      if (!Array.isArray(response.data)) {
        Swal.fire({
          icon: "error",
          title: `Invalid Tags`,
          html: "Please contact support<br /><br />object is not an array",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        throw new Error("Response data does not match Tags type");
      }

      if (!response.data.every(isTag)) {
        throw new Error("Response data does not match Tags type");
      }

      return response.data;
    } else {
      return "Failed to fetch all tags";
    }
  } catch (error) {
    return "Failed to fetch all tags";
  }
}
// #endregion fetchAllTags
/*--------------Tag and count--------------*/
// #region fetchAllClientTagsAndParticipants
export async function fetchAllClientTagsAndParticipants(
  clientID: string
): Promise<Tags[] | string> {
  try {
    const response = await apiFetcher<any>("/tags/client/get/all", "POST", {
      body: {
        clientID: clientID
      }
    });
    /*-----------Mock Data----------------*/
    // with a added delay
    // await new Promise((resolve) => setTimeout(resolve, 2000));
    // const mockData: any = [{ "id": 1, "name": "Tedddsting", "created_at": "2023-10-13T13:17:57.000Z", "updated_at": "2023-10-13T13:18:03.000Z", "dateAdded": "2023-10-13T13:52:24.267Z", "participant_count": 0 }, { "id": 2, "name": "Confirm", "created_at": "2023-10-13T13:17:59.000Z", "updated_at": "2023-10-13T13:18:03.000Z", "dateAdded": "2023-10-13T13:52:24.267Z", "participant_count": 4 }, { "id": 3, "name": "Women over 50", "created_at": "2023-10-13T13:18:00.000Z", "updated_at": "2023-10-13T13:18:03.000Z", "dateAdded": "2023-10-13T13:52:24.267Z", "participant_count": 3 }];
    // return mockData;
    /*-----------Mock Data----------------*/
    if (response && response.status === 200 && response.data !== null) {
      // Check if every item in the response.data is of type Tags
      if (!Array.isArray(response.data)) {
        Swal.fire({
          icon: "error",
          title: `Invalid Tags`,
          html: "Please contact support<br /><br />object is not an array",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        throw new Error("Response data does not match Tags type");
      }

      if (!response.data.every(isFullTag)) {
        throw new Error("Response data does not match Tags type");
      }
      return response.data;
    } else if (response.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Please contact support`,
        html: "Server expecting different data",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return "Failed to fetch all tags";
    } else {
      return "Failed to fetch all tags";
    }
  } catch (error) {
    return "Failed to fetch all tags";
  }
}
// #endregion fetchAllClientTagsAndParticipants
/* -----------------------Add-------------------------- */
//this function we will pass the ClientID , tags which will be a string in the body this is a delete function
// #region addTag
export async function addTag(
  clientID: string,
  studyID: string,
  tagIDs: string
): Promise<string> {
  try {
    const response = await apiFetcher<any>("/tags/create", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        tags: [tagIDs],
        bulk: false
      }
    });
    console.log(response.status, "response");
    //Single action
    if (response && response.status === 202) {
      return "success";
    } else if (response && response.status === 200 && response.data.jobID) {
      localStorage.setItem("workerID", response.data.jobID);
      return "successfulBulk";
    } else {
      return "Failed to add tag";
    }
  } catch (error) {
    return "Failed to add tag";
  }
}
// #endregion addTag
/* -----------------------Edit-------------------------- */
//this function we will pass the ClientID , tagID , tags which will be a string in the body this is a delete function
// #region editTag
export async function editTag(
  clientID: string,
  studyID: string,
  tagID: string,
  tagName: string
): Promise<string> {
  try {
    const response = await apiFetcher<any>("/updateTag", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        tag: {
          id: tagID,
          name: tagName
        }
      }
    });
    /*-----------Mock Data----------------*/
    /*-----------Mock Data----------------*/
    if (response && response.status === 202) {
      return "success";
    } else {
      throw new Error("Failed to edit tag");
    }
  } catch (error) {
    return "Failed to edit tag";
  }
}
// #endregion editTag
/* ------------------------Delete-------------------------- */
//this function we will pass the ClientID , tagID in the body this is a delete function
// This is for single and bulk deleting
// #region deleteTag
export async function deleteTag(
  clientID: string,
  tagIDs: string[]
): Promise<string> {
  try {
    const response = await apiFetcher<any>("/tags/delete", "POST", {
      body: {
        clientID: clientID,
        tagIDs: tagIDs,
        bulk: tagIDs.length > 1 ? true : false
      }
    });
    if (response && response.status === 202) {
      return "success";
    } else if (response && response.status === 200 && response.data.jobID) {
      //200 is for bulk
      localStorage.setItem("workerID", response.data.jobID);
      return "successfulBulk";
    } else {
      return "Failed to delete tag";
    }
  } catch (error) {
    return "Failed to delete tag";
  }
}
// #endregion deleteTag
//-------------------------Get all tag information-------------------------*/
// #region fetchTagDetails
export async function fetchTagDetails(
  clientID: string,
  studyID: string,
  tagID: string
): Promise<TypeTagDetails | string> {
  try {
    const response = await apiFetcher<any>(
      "/participants/study/tags/get",
      "POST",
      {
        body: {
          clientID: clientID,
          studyID: studyID,
          tagID: tagID
        }
      }
    );
    //Create the type that the response will be
    if (response && response.status === 200 && response.data !== null) {
      // Check if every item in the response.data is of type Tags
      if (!isTagDetails(response.data)) {
        throw new Error("Response data does not match Tags type");
      } else {
        return response.data;
      }
    } else if (response.status === 204 || response.status === 404) {
      return "Cannot find tag. The tag may have been deleted";
    } else {
      return "Failed to fetch all tags with participants";
    }
  } catch (error) {
    return "Failed to fetch all tags with participants";
  }
}
// #endregion fetchTagDetails

//------------------------getStudyTagsDetails--------------------------*/
//This is for the filters
// #region fetchStudyTagsDetails
export async function fetchStudyTagsDetails(
  clientID: string,
  studyID: string
): Promise<StudySurveysFilters | string> {
  try {
    const response = await apiFetcher<any>("/getStudyTagsDetails", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID
      }
    });
    if (response && response.status === 200 && response.data !== null) {
      // Check if every item in the response.data is of type Tags
      if (!isStudySurveys(response.data)) {
        throw new Error("Response data does not match study type");
      }

      return response.data;
    } else {
      return "Failed to fetch filters details";
    }
  } catch (error) {
    return "Failed to fetch filters details";
  }
}
// #endregion fetchStudyTagsDetails

/* -----------------------Add Tag to Participant-------------------------- */
// #region addTagToParticipant
export async function addTagToParticipant(
  clientID: string,
  studyID: string,
  tagID: string,
  participantID: string[]
): Promise<string> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>("/tags/participant/add", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        tagID: tagID,
        participantIDs: participantID,
        bulk: participantID.length > 1 ? true : false
      }
    });
    if (response && response.status === 202) {
      return "success";
    } else if (response && response.status === 200 && response.data.jobID) {
      localStorage.setItem("workerID", response.data.jobID);
      return "successfulBulk";
    } else {
      return "Failed to add tag";
    }
  } catch (error) {
    return "Failed to add tag";
  }
}

// #endregion addTagToParticipant
/* -----------------------Remove Tag from Participant-------------------------- */
// #region removeTagFromParticipant
export async function removeTagFromParticipant(
  clientID: string,
  studyID: string,
  tagID: string,
  participantID: string[]
): Promise<string> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const response = await apiFetcher<any>("/tags/participant/remove", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        tagID: tagID,
        participantIDs: participantID,
        bulk: participantID.length > 1 ? true : false
      }
    });
    if (response && response.status === 200) {
      if (response.data.jobID) {
        localStorage.setItem("workerID", response.data.jobID);
        return "successfulBulk";
      } else {
        return "success";
      }
    }

    if (response && response.status === 202) {
      return "success";
    }

    if (response && response.status === 400) {
      return "Failed to remove tag. Please contact support";
    }

    return "Failed to remove tag";
  } catch (error) {
    return "Failed to remove tag";
  }
}
// #endregion removeTagFromParticipant
