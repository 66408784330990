import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  ChevronLeft,
  ChevronRight,
  IconBroom
} from "../../../../../assets/images/icons/icons";
import { usePermissions } from "../../../../../contexts/UserContext";
import {
  MessagesParticipants,
  MessagesTags
} from "../../../../../models/messages.model";
import { Country, Language } from "../../../../../types";
import MultipleDropdown from "../../../../Dropdown/multipleDropdown.component";
import LoadingPencil from "../../../../Loaders/LoadingPencil";

import "./listing.scss";

interface listingProps {
  recipients: MessagesParticipants[] | undefined;
  setRecipients: (recipients: MessagesParticipants[]) => void;
  tags: MessagesTags[] | undefined;
  languages: Language[];
  countries: Country[];
}

const RecipientList: React.FC<listingProps> = ({
  recipients,
  setRecipients,
  tags,
  languages,
  countries
}) => {
  const [showFilters, setShowFilters] = useState<boolean>(false);
  //Data
  const [filterData, setFilterData] = useState<
    MessagesParticipants[] | undefined
  >(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  //Filters
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const statusOption: string[] = [
    "Active",
    "Pending Invitation",
    "Blocked",
    "Declined",
    "In Progress",
    "Completed",
    "Invitation Not Sent",
    "App Invitation Sent",
    "Flagged"
  ];
  //The other options are pass in as props
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [checkedParticipants, setCheckedParticipants] = useState<string[]>([]);
  const { hasPermission } = usePermissions();

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  /*------------------Loading data----------------------*/
  /*------------------Filtering----------------------*/
  const handleStatusChange = (option: string) => {
    if (selectedStatus.includes(option)) {
      setSelectedStatus((prev) => prev.filter((item) => item !== option));
    } else {
      setSelectedStatus((prev) => [...prev, option]);
    }
  };
  const handleLanguageChange = (option: string) => {
    if (selectedLanguage.includes(option)) {
      setSelectedLanguage((prev) => prev.filter((item) => item !== option));
    } else {
      setSelectedLanguage((prev) => [...prev, option]);
    }
  };
  const handleCountryChange = (option: string) => {
    if (selectedCountry.includes(option)) {
      setSelectedCountry((prev) => prev.filter((item) => item !== option));
    } else {
      setSelectedCountry((prev) => [...prev, option]);
    }
  };
  const handleTagsChange = (option: string) => {
    if (selectedTags.includes(option)) {
      setSelectedTags((prev) => prev.filter((item) => item !== option));
    } else {
      setSelectedTags((prev) => [...prev, option]);
    }
  };
  useEffect(() => {
    if (!Array.isArray(recipients)) {
      setFilterData([]);
      return;
    }
    setIsLoading(true);
    let filteredParticipants = [...recipients];
    // Filter by tags
    if (selectedTags.length > 0) {
      //Set selected tags to lower case
      const lowerCaseSelectedTags = selectedTags.map((st) => st.toLowerCase());
      //Filter the participants by the selected tags
      filteredParticipants = filteredParticipants.filter((p) =>
        p.participant_tags.some((tag) =>
          lowerCaseSelectedTags.includes(tag.toString().toLowerCase())
        )
      );
    }

    // Filter by status, with special handling for "Flagged"
    if (selectedStatus.length > 0) {
      const lowerCaseSelectedStatus = selectedStatus.map((st) =>
        st.toLowerCase()
      );
      if (lowerCaseSelectedStatus.includes("flagged")) {
        filteredParticipants = filteredParticipants.filter(
          (participant) => participant.participant_flagged === 1
        );
      } else {
        // If "Flagged" is not selected, filter by the selected statuses
        filteredParticipants = filteredParticipants.filter((participant) =>
          lowerCaseSelectedStatus.includes(
            participant.study_status.toLowerCase()
          )
        );
      }
    }

    // Filter by language
    //Filtering for language is a bit different because the language is stored as a iso_code but the options are iso_code - name but some
    if (selectedLanguage.length > 0) {
      const languageISO = languages
        .filter((language) =>
          selectedLanguage.includes(language.iso_code + " - " + language.name)
        )
        .map((language) => language.iso_code);
      filteredParticipants = filteredParticipants.filter((participant) =>
        languageISO.some(
          (languageIso) => participant.participant_lang_iso === languageIso
        )
      );
    }

    // Filter by country using the country ISO code country_iso "ZA" the selectedCountry is the names of the countries
    if (selectedCountry.length > 0) {
      const countryISO = countries
        .filter((country) => selectedCountry.includes(country.name))
        .map((country) => country.iso_code);
      filteredParticipants = filteredParticipants.filter((participant) =>
        countryISO.some(
          (countryIso) => participant.participant_country_iso === countryIso
        )
      );
    }

    // Filter by search term
    if (searchInput.length > 0) {
      const trimmedSearchInput = searchInput.trim().toLowerCase();
      filteredParticipants = filteredParticipants.filter(
        (participant) =>
          participant.participant_full_name
            .toLowerCase()
            .includes(trimmedSearchInput) ||
          participant.id === trimmedSearchInput ||
          participant.external_id === trimmedSearchInput ||
          participant.participant_email
            .toLowerCase()
            .includes(trimmedSearchInput)
      );
    }
    setIsLoading(false);
    setFilterData(filteredParticipants);
  }, [
    selectedStatus,
    selectedLanguage,
    selectedCountry,
    selectedTags,
    searchInput,
    recipients,
    setRecipients
  ]);
  //Clear Filters
  const handleClearFilters = () => {
    setSelectedStatus([]);
    setSelectedLanguage([]);
    setSelectedCountry([]);
    setSelectedTags([]);
    setSearchInput("");
  };
  /*-----------------/Filtering/---------------------*/
  /*------------------Function----------------------*/
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  const toggleCheckParticipant = (id: string, checked: boolean) => {
    if (checked) {
      setCheckedParticipants((prev) => [...prev, id]);
    } else {
      setCheckedParticipants((prev) => prev.filter((item) => item !== id));
    }
  };
  const toggleSelectAll = (checked: boolean) => {
    if (checked) {
      setCheckedParticipants(
        filterData?.map((participant) => participant.id) || []
      );
    } else {
      setCheckedParticipants([]);
    }
  };
  const handleMultipleRemoveRecipient = () => {
    if (checkedParticipants.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "No participants selected",
        text: "Please select at least one participant.",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    const foundRemoveParticipants = filterData?.filter((participant) =>
      checkedParticipants.includes(participant.id)
    );
    if (foundRemoveParticipants) {
      setFilterData((currentFilterData: MessagesParticipants[] | undefined) =>
        currentFilterData?.filter(
          (participant) => !checkedParticipants.includes(participant.id)
        )
      );
      setRecipients(
        filterData?.filter(
          (participant) => !checkedParticipants.includes(participant.id)
        ) || []
      );
      setCheckedParticipants([]);
    }
  };

  //Remove Recipient
  const handleSingleRemoveRecipient = (participantId: string) => {
    const foundRemoveParticipant = filterData?.find(
      (participant) => participant.id === participantId
    );

    if (foundRemoveParticipant) {
      setFilterData((currentFilterData: MessagesParticipants[] | undefined) =>
        currentFilterData?.filter(
          (participant) => participant.id !== participantId
        )
      );
      setRecipients(
        filterData?.filter((participant) => participant.id !== participantId) ||
          []
      );
    }
  };

  return (
    <div className="recipient-container">
      <div className="header-container">
        <h5 className="title">Recipients</h5>
        <h5 className="count">
          {recipients && recipients.length ? recipients.length : 0}
        </h5>
        <button className="filter-toggle-btn" onClick={toggleFilters}>
          Filter
        </button>
      </div>
      {showFilters && (
        <div className="container p-4 filter-container">
          <div className="row pb-1">
            <div className="col p-1">
              <MultipleDropdown
                placeholder="Status"
                options={statusOption}
                selectedOptions={selectedStatus}
                onOptionToggled={handleStatusChange}
              />
            </div>
            <div className="col p-1">
              <MultipleDropdown
                placeholder="Language"
                options={languages.map(
                  (language) => language.iso_code + " - " + language.name
                )}
                selectedOptions={selectedLanguage}
                onOptionToggled={handleLanguageChange}
              />
            </div>
          </div>
          <div className="row  pb-1">
            <div className="col p-1">
              <MultipleDropdown
                placeholder="Country"
                options={countries.map((country) => country.name)}
                selectedOptions={selectedCountry}
                onOptionToggled={handleCountryChange}
              />
            </div>
            <div className="col p-1">
              <MultipleDropdown
                placeholder="Tags"
                options={
                  tags && Array.isArray(tags)
                    ? tags.map((tag) => tag.name)
                    : ["No tags Found"]
                }
                selectedOptions={selectedTags}
                onOptionToggled={handleTagsChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col p-1 search-input">
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button
                className="btn btn-primary btn-clear"
                disabled={false}
                onClick={handleClearFilters}
              >
                <IconBroom className="icon icon_white" />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="list-container recipients">
        {filterData && (
          <div className="participant-list">
            {(hasPermission("subject") ||
              hasPermission("message", "all") ||
              hasPermission("messages", "delete")) && (
              <div className="list-header">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    onChange={(e) => toggleSelectAll(e.target.checked)}
                    checked={
                      checkedParticipants.length === filterData.length &&
                      filterData.length !== 0
                    }
                    // The above will check the 'Select All' checkbox if all participants are selected
                  />
                  <h5 className="select-all">Remove All</h5>
                  <div className="participant-actions">
                    <button
                      className="remove-btn"
                      onClick={handleMultipleRemoveRecipient}
                    >
                      Remove <strong>{checkedParticipants.length}</strong>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {filterData && filterData.length > 0 ? (
              filterData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((participant) => (
                  <div className="list-item" key={participant.id}>
                    {(hasPermission("subject") ||
                      hasPermission("message", "all") ||
                      hasPermission("messages", "delete")) && (
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            toggleCheckParticipant(
                              participant.id,
                              e.target.checked
                            )
                          }
                          checked={checkedParticipants.includes(participant.id)}
                        />
                      </div>
                    )}
                    <div className="participant-info">
                      <h5 className="name">
                        {participant.participant_full_name}
                      </h5>
                      <h5 className="language">
                        {participant.participant_lang_iso}
                      </h5>
                    </div>
                    <div className="participant-actions">
                      {(hasPermission("subject") ||
                        hasPermission("message", "all") ||
                        hasPermission("messages", "delete")) && (
                        <button
                          className="remove-btn"
                          onClick={() =>
                            handleSingleRemoveRecipient(participant.id)
                          }
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </div>
                ))
            ) : isLoading ? (
              <LoadingPencil title={"participants"} isVisible={isLoading} />
            ) : (
              <div className="no-participants">
                <p>No participants found</p>
              </div>
            )}
          </div>
        )}
        {filterData && filterData.length > itemsPerPage && (
          <div className="d-flex justify-content-center participant-pagination">
            <button
              className="btn btn-link"
              onClick={handlePrevious}
              disabled={currentPage === 1}
            >
              <ChevronLeft />
            </button>
            <button
              className="btn btn-link"
              onClick={handleNext}
              disabled={currentPage * itemsPerPage >= filterData.length}
            >
              <ChevronRight />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RecipientList;
