import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import automationConditions from "../../../../assets/data/automationConditions.json";
import { AutomationRuleData, ConditionRulesJson } from "../../../../types";
import { convertIDToName } from "../../../../utilities/automation.util";
import { capitalize, useOutsideClick } from "../../../../utilities/utils";
import "./conditions.scss";

export type ConditionRuleProps = {
  id: string;
  selection: string;
  options: string;
  values: string;
  variable: string;
  type: string;

  removeConditionRule: (groupId: string, ruleId: string) => void;
  conditionGroupID: string;
  // Rule Handlers
  addRuleSelection: (
    groupId: string,
    ruleId: string,
    ruleSelection: string
  ) => void;
  addRuleOption: (groupId: string, ruleId: string, ruleOption: string) => void;
  addRuleValue: (groupId: string, ruleId: string, ruleValue: string) => void;
  addRuleType: (groupId: string, ruleId: string, ruleType: string) => void;
  addRuleVariable: (
    groupId: string,
    ruleId: string,
    ruleVariable: string
  ) => void;

  // data for dropdowns
  dropdownRuleData: AutomationRuleData;
  editable: boolean;
  viewEditRule: boolean;
};

const ConditionRule: React.FC<ConditionRuleProps> = ({
  // conditionRuleType, conditionRuleValue
  removeConditionRule,
  conditionGroupID,
  id,
  selection,
  options,
  values,
  variable,
  type,
  addRuleSelection,
  addRuleOption,
  addRuleValue,
  addRuleType,
  addRuleVariable,
  dropdownRuleData,
  editable,
  viewEditRule
}) => {
  const { clientID, studyID } = useParams(); // Get from URL

  // Static Json Data, used for selection and determining what to render
  const [automationConditionsObj, setAutomationConditionsObj] =
    useState<ConditionRulesJson>();

  // Fetch Static Json Data
  useEffect(() => {
    // If the automationConditionsObj is empty, set it to the automationConditions object
    if (!automationConditionsObj) {
      setAutomationConditionsObj(automationConditions);
    }
    console.log(automationConditionsObj);
  }, [automationConditionsObj]);

  useEffect(() => {
    console.log(id, selection, variable, options, values);
  }, [id, selection, options, values, variable]);

  useEffect(() => {
    console.log(id, selection, variable, options, values);
  }, []);

  // //======================================== TYPES //========================================
  const [conditionRuleType, setConditionRuleType] = useState<string>("");

  // ======================================== SELECTION ========================================
  const [conditionRuleDropdown, setConditionRuleDropdown] = useState(false);
  const [selectedConditionRule, setSelectedConditionRule] =
    useState<string>("");

  const selectionRef = useRef<HTMLDivElement>(null);
  useOutsideClick(selectionRef, () => {
    // Action to take on outside click
    handleConditionRuleDropdown(false);
  });

  const handleConditionRuleDropdown = (show: boolean) => {
    setConditionRuleDropdown(show);
  };

  const handleSelectConditionRule = (
    groupId: string,
    ruleId: string,
    ruleSelection: string,
    ruleType: string
  ) => {
    console.log(groupId, ruleId, ruleSelection, ruleType);

    // If a type of study is selected, set that variable ID, useParams
    if (ruleType === "study" && studyID) {
      addRuleVariable(groupId, ruleId, studyID);
    }

    // If ruleType is not study, set variable to empty
    if (ruleType !== "study") {
      setConditionRuleVariable("");
      addRuleVariable(groupId, ruleId, "");
    }

    // Sets the name and type of the selected rule
    setSelectedConditionRule(ruleSelection);
    setConditionRuleType(ruleType);

    // Reset Option, Value
    setSelectedConditionOption("");
    setSelectedConditionValue("");
    setVariableDisplayName("");
    setValueDisplayName("");
    addRuleOption(groupId, ruleId, "");
    addRuleValue(groupId, ruleId, "");

    addRuleSelection(groupId, ruleId, ruleSelection);
    addRuleType(groupId, ruleId, ruleType);

    if (ruleSelection === "Last Login") {
      // set condition value to not blank string
      handleSelectConditionValue(groupId, ruleId, "Not used", "Not used");
    }

    // Close after selection
    setConditionRuleDropdown(false);
  };
  // ===========================================================================================

  // ======================================== VARIABLES ========================================
  const [conditionVariableType, setConditionRuleVariable] =
    useState<string>("");
  const [conditionVariableDropdown, setConditionVariableDropdown] =
    useState(false);
  const [variableDisplayName, setVariableDisplayName] = useState<string>("");

  // Ref for outside click
  const variableRef = useRef<HTMLDivElement>(null);
  useOutsideClick(variableRef, () => {
    // Action to take on outside click
    handleConditionVariableDropdown(false);
  });
  const handleConditionVariableDropdown = (show: boolean) => {
    setConditionVariableDropdown(show);
  };

  const handleSelectConditionVariable = (
    type: "survey" | "bucket" | "study",
    groupId: string,
    ruleId: string,
    ruleVariable: string,
    variableDisplayName: string
  ) => {
    console.log(variableDisplayName);
    console.log(ruleId, ruleVariable, variableDisplayName);

    // If the type is bucket, check if the bucket is acceptable, if not set the value to compliant
    if (type === "bucket") {
      if (
        unacceptableBuckets.some((bucket) =>
          variableDisplayName.includes(bucket)
        )
      ) {
        addRuleValue(groupId, ruleId, "Compliant");
      }
      // Reset value
      else {
        console.log("IN HERE");
        addRuleValue(groupId, ruleId, "");
        setSelectedConditionValue("");
        setValueDisplayName("");
      }
    }
    // If the type is survey/study, clear the value on variable select
    else {
      console.log("IN HERE");
      addRuleValue(groupId, ruleId, "");
      setSelectedConditionValue("");
      setValueDisplayName("");
    }

    // Add rule variable to state
    addRuleVariable(groupId, ruleId, ruleVariable);
    setVariableDisplayName(variableDisplayName);
    setConditionRuleVariable(ruleVariable);
    setConditionVariableDropdown(false);
  };
  // =========================================================================================

  //========================================  Options ========================================
  const [conditionOptionDropdown, setConditionOptionDropdown] = useState(false);
  const [selectedConditionOption, setSelectedConditionOption] =
    useState<string>("");

  const optionRef = useRef<HTMLDivElement>(null);
  useOutsideClick(optionRef, () => {
    // Action to take on outside click
    handleConditionOptionDropdown(false);
  });

  const handleConditionOptionDropdown = (show: boolean) => {
    setConditionOptionDropdown(show);
  };

  const handleSelectConditionOption = (
    groupId: string,
    ruleId: string,
    ruleOption: string
  ) => {
    console.log(groupId, ruleId, ruleOption);
    addRuleOption(groupId, ruleId, ruleOption);
    setSelectedConditionOption(ruleOption);
    setConditionOptionDropdown(false);

    // Reset Value and Variable
    if (
      (conditionVariableType && conditionRuleType === "survey") ||
      (conditionVariableType && conditionRuleType === "buckets")
    ) {
      return;
    } else if (conditionRuleType !== "study") {
      addRuleVariable(groupId, ruleId, "");
    }
  };
  // ========================================================================================

  // ======================================== Values ========================================
  const [conditionValueDropdown, setConditionValueDropdown] = useState(false);
  const [selectedConditionValue, setSelectedConditionValue] =
    useState<string>("");
  const [valueDisplayName, setValueDisplayName] = useState<string>("");

  // The value section will not display if one of these are chosen (set in variable selection)
  const unacceptableBuckets = ["completes", "non compliant", "Compliant"];

  const valueRef = useRef<HTMLDivElement>(null);
  useOutsideClick(valueRef, () => {
    // Action to take on outside click
    handleConditionValueDropdown(false);
  });

  const handleConditionValueDropdown = (show: boolean) => {
    setConditionValueDropdown(show);
  };

  const handleSelectConditionValue = (
    groupId: string,
    ruleId: string,
    conditionValue: string,
    variableDisplayName: string
  ) => {
    console.log(groupId, ruleId, conditionValue);
    addRuleValue(groupId, ruleId, conditionValue);
    setValueDisplayName(variableDisplayName);
    setSelectedConditionValue(conditionValue);
    setConditionValueDropdown(false);
  };

  // ========================================================================================
  // if viewEditRule is true, set the id, selection, option and value
  useEffect(() => {
    if (viewEditRule) {
      // set the id, selection, option and value
      setSelectedConditionRule(selection);
      setSelectedConditionOption(options);
      setSelectedConditionValue(values);
      setVariableDisplayName(variable);
      if (selection === "Survey Status" || selection === "Buckets") {
        if (
          selection === "Buckets" &&
          unacceptableBuckets.some((bucket) => {
            const bucketName = convertIDToName(
              "bucket",
              values,
              dropdownRuleData.automationConditionDataDB.buckets
            );

            console.log(bucketName);
            console.log(values);
            console.log(bucket);
            return bucketName ? bucketName.includes(bucket) : false;
          })
        ) {
          setValueDisplayName("");
        } else {
          setValueDisplayName(values);
        }
      } else {
        setValueDisplayName(variable);
      }
      setConditionRuleType(type);
      setConditionRuleVariable(variable);

      console.log(
        selectedConditionOption,
        selectedConditionValue,
        selectedConditionRule,
        conditionVariableType,
        conditionRuleType
      );
    }
  }, [viewEditRule]);

  useEffect(() => {
    console.log(selection, options, values, variable, type);
    console.log(dropdownRuleData.automationConditionDataDB);
  }, [selection, options, values, variable, type]);

  // ========================================== FILTERS ==============================================

  // languages
  const [languagesFilter, setLanguagesFilter] = useState<string>("");
  const handleLanguagesSearchFilter = (search: string) => {
    setLanguagesFilter(search);
  };

  const filteredLanguages = dropdownRuleData.languages.filter((language) =>
    language.name.toLowerCase().includes(languagesFilter.toLowerCase())
  );

  // countries
  const [countriesFilter, setCountriesFilter] = useState<string>("");
  const handleCountriesSearchFilter = (search: string) => {
    setCountriesFilter(search);
  };

  const filteredCountries = dropdownRuleData.countries.filter((country) =>
    country.name.toLowerCase().includes(countriesFilter.toLowerCase())
  );

  // tags
  const [tagsFilter, setTagsFilter] = useState<string>("");
  const handleTagsSearchFilter = (search: string) => {
    setTagsFilter(search);
  };

  const filteredTags = dropdownRuleData.automationConditionDataDB.tags.filter(
    (tag) => tag.name.toLowerCase().includes(tagsFilter.toLowerCase())
  );

  // buckets
  const [bucketsFilter, setBucketsFilter] = useState<string>("");
  const handleBucketsSearchFilter = (search: string) => {
    setBucketsFilter(search);
  };

  const filteredBuckets =
    dropdownRuleData.automationConditionDataDB.buckets.filter((bucket) =>
      bucket.name.toLowerCase().includes(bucketsFilter.toLowerCase())
    );

  // surveys
  const [surveysFilter, setSurveysFilter] = useState<string>("");
  const handleSurveysSearchFilter = (search: string) => {
    setSurveysFilter(search);
  };

  const filteredSurveys =
    dropdownRuleData.automationConditionDataDB.surveys.filter((survey) =>
      survey.name.toLowerCase().includes(surveysFilter.toLowerCase())
    );

  return (
    <div className="d-flex single_condition_rule_container">
      <div className="condition_option_value_container">
        {/*====================  Selection Section  ==================== */}
        <div className="selection_variable_container">
          <div
            className="dropdown dropdown_condition_rule selection"
            ref={selectionRef}
          >
            {editable ? (
              <>
                <button
                  className="btn btn-primary dropdown-toggle condition_rule_dropdown_button selection_button"
                  onClick={() =>
                    handleConditionRuleDropdown(!conditionRuleDropdown)
                  }
                >
                  {selectedConditionRule
                    ? selectedConditionRule
                    : "System Variables"}
                </button>
                <div
                  className={`dropdown-menu ${
                    conditionRuleDropdown ? "show" : "hide"
                  }`}
                >
                  {/* FROM STATIC */}
                  <div className="dropdown-items">
                    {automationConditionsObj?.conditions.map((condition) => (
                      <div
                        key={condition.id}
                        className="dropdown-item"
                        onClick={() => {
                          handleSelectConditionRule(
                            conditionGroupID,
                            id,
                            condition.selection,
                            condition.type
                          );

                          if (condition.type === "study") {
                            handleSelectConditionVariable(
                              "study",
                              conditionGroupID,
                              id,
                              studyID ? studyID : "studyID",
                              condition.selection
                            );
                          }
                        }}
                      >
                        <li key={condition.id + condition.selection}>
                          <a>{condition.selection}</a>
                        </li>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            ) : (
              // ON PREVIEW
              <button className="btn btn-primary condition_rule_dropdown_button selection_button">
                {editable
                  ? selectedConditionRule
                    ? selectedConditionRule
                    : "System Variables"
                  : selection}
              </button>
            )}
          </div>
          {/* ==================== Variable Section ==================== */}
          {/* // if type is survey, render surveys */}
          {editable ? (
            <>
              {selectedConditionRule && conditionRuleType === "survey" ? (
                <div
                  className="dropdown dropdown_condition_rule variable_drop"
                  ref={variableRef}
                >
                  <button
                    className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                    onClick={() =>
                      handleConditionVariableDropdown(
                        !conditionVariableDropdown
                      )
                    }
                  >
                    {/* Editing an Existing Automation */}
                    {viewEditRule ? (
                      <>
                        {variable &&
                        dropdownRuleData.automationConditionDataDB.surveys
                          .length > 0
                          ? convertIDToName(
                              "survey",
                              variable,
                              dropdownRuleData.automationConditionDataDB.surveys
                            )
                          : "Select Survey"}
                      </>
                    ) : (
                      <>
                        {dropdownRuleData && conditionVariableType !== ""
                          ? variableDisplayName
                          : capitalize(conditionRuleType)}
                      </>
                    )}
                  </button>
                  <div
                    className={`dropdown-menu ${
                      conditionVariableDropdown ? "show" : "hide"
                    }`}

                    // On Edit
                  >
                    <input
                      type="text"
                      className="filter_rules_input"
                      placeholder="Search Surveys"
                      value={surveysFilter}
                      onChange={(e) =>
                        handleSurveysSearchFilter(e.target.value)
                      }
                    />
                    {/*if survey type is selected, render survey*/}
                    <div className="dropdown-items">
                      {conditionRuleType === "survey" &&
                      filteredSurveys.length > 0 ? (
                        filteredSurveys.map((survey) => (
                          <div
                            key={survey.id}
                            className="dropdown-item"
                            onClick={() =>
                              handleSelectConditionVariable(
                                "survey",
                                conditionGroupID,
                                id,
                                survey.id,
                                survey.name
                              )
                            }
                          >
                            <li key={survey.id + survey.name}>
                              <a>{survey.name}</a>
                            </li>
                          </div>
                        ))
                      ) : (
                        <div className="text-center">No Surveys Found</div>
                      )}
                    </div>
                    {/*if buckets type is selected, render buckets*/}
                  </div>
                </div>
              ) : conditionRuleType === "buckets" ? (
                <div
                  className="dropdown dropdown_condition_rule variable_drop"
                  ref={variableRef}
                >
                  <button
                    className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                    onClick={() =>
                      handleConditionVariableDropdown(
                        !conditionVariableDropdown
                      )
                    }
                  >
                    {viewEditRule ? (
                      <>
                        {variable
                          ? convertIDToName(
                              "bucket",
                              variable,
                              dropdownRuleData.automationConditionDataDB.buckets
                            )
                          : "Select Bucket"}
                      </>
                    ) : (
                      <>
                        {dropdownRuleData && conditionVariableType !== ""
                          ? variableDisplayName
                          : capitalize(conditionRuleType)}
                      </>
                    )}
                  </button>
                  <div
                    className={`dropdown-menu ${
                      conditionVariableDropdown ? "show" : "hide"
                    }`}
                  >
                    <input
                      type="text"
                      className="filter_rules_input"
                      placeholder="Search Buckets"
                      value={bucketsFilter}
                      onChange={(e) =>
                        handleBucketsSearchFilter(e.target.value)
                      }
                    />
                    {/*if bucket type is selected, render bucket*/}
                    <div className="dropdown-items">
                      {conditionRuleType === "buckets" &&
                      filteredBuckets.length > 0 ? (
                        filteredBuckets.map((bucket) => (
                          <div
                            key={bucket.id}
                            className="dropdown-item"
                            onClick={() =>
                              handleSelectConditionVariable(
                                "bucket",
                                conditionGroupID,
                                id,
                                bucket.id,
                                bucket.name
                              )
                            }
                          >
                            <li key={bucket.id + bucket.name}>
                              <a>{bucket.name}</a>
                            </li>
                          </div>
                        ))
                      ) : (
                        <div className="text-center">No Buckets Found</div>
                      )}
                    </div>
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            <>
              {variable &&
                (selection.includes("Survey") ||
                  selection.includes("Today")) && (
                  <div
                    className="dropdown dropdown_condition_rule variable_drop"
                    ref={optionRef}
                  >
                    <button className="btn btn-primary condition_rule_dropdown_button">
                      {/* if selection contains "Survey" */}
                      {(selection.includes("Survey") ||
                        selection.includes("Today")) && (
                        <span>
                          {dropdownRuleData.automationConditionDataDB.surveys?.map(
                            (survey) =>
                              // log the survey
                              survey.id === variable ? (
                                <span key={survey.id}>{survey.name}</span>
                              ) : null
                          )}
                        </span>
                      )}
                    </button>
                  </div>
                )}

              {/* Buckets */}
              {variable && selection.includes("Bucket") && (
                <div
                  className="dropdown dropdown_condition_rule variable_drop"
                  ref={optionRef}
                >
                  <button className="btn btn-primary condition_rule_dropdown_button">
                    {/* if selection contains "Bucket" */}
                    {selection.includes("Bucket") && (
                      <span>
                        {dropdownRuleData.automationConditionDataDB.buckets?.map(
                          (bucket) =>
                            bucket.id === variable ? (
                              <span key={bucket.id}>{bucket.name}</span>
                            ) : null
                        )}
                      </span>
                    )}
                  </button>
                </div>
              )}
            </>
          )}
        </div>

        {/* ==================== Option Section ==================== */}
        {/* // If options is empty, render null instead */}
        {/* ON EDIT/NEW */}
        {editable ? (
          <>
            {automationConditionsObj?.conditions.map((condition) =>
              condition.selection === selectedConditionRule ? (
                condition.options.length === 0 ? null : (
                  // render dropdown
                  <div
                    className="dropdown dropdown_condition_rule value"
                    ref={optionRef}
                  >
                    <button
                      className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                      onClick={() =>
                        handleConditionOptionDropdown(!conditionOptionDropdown)
                      }
                    >
                      {editable
                        ? selectedConditionOption
                          ? selectedConditionOption
                          : "Options"
                        : options}
                    </button>
                    <div
                      className={`dropdown-menu ${
                        conditionOptionDropdown ? "show" : "hide"
                      }`}
                    >
                      {/* Map values based on selectedConditionRule (ID)*/}
                      <div className="dropdown-items">
                        {automationConditionsObj?.conditions.map((condition) =>
                          condition.selection === selectedConditionRule
                            ? condition.options.map((option) => (
                                <div
                                  key={option}
                                  className="dropdown-item"
                                  onClick={() =>
                                    handleSelectConditionOption(
                                      conditionGroupID,
                                      id,
                                      option
                                    )
                                  }
                                >
                                  <li key={id + option}>
                                    <a>{option}</a>
                                  </li>
                                </div>
                              ))
                            : null
                        )}
                      </div>
                    </div>
                  </div>
                )
              ) : null
            )}
          </>
        ) : (
          // ON PREVIEW
          <div
            className="dropdown dropdown_condition_rule value"
            ref={optionRef}
          >
            <button className="btn btn-primary condition_rule_dropdown_button">
              {options}
            </button>
          </div>
        )}

        {/* ==================== Value Section ==================== */}
        {/* If values is empty, render an input instead */}
        {/* ON EDIT/NEW */}
        {editable ? (
          <>
            {automationConditionsObj?.conditions.map((condition) =>
              condition.selection === selectedConditionRule ? (
                // Check if options length > 2 but less than 6
                condition.options?.length === 2 &&
                conditionRuleType !== "study" ? (
                  // if conditionRuleType is tag, render accordingly
                  // ======================== TAGS ========================
                  conditionRuleType === "tags" ? (
                    <div
                      className="dropdown dropdown_condition_rule value"
                      ref={valueRef}
                    >
                      <button
                        className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                        onClick={() =>
                          handleConditionValueDropdown(!conditionValueDropdown)
                        }
                      >
                        {viewEditRule ? (
                          <>
                            {dropdownRuleData.automationConditionDataDB.tags?.map(
                              (tag) =>
                                tag.id === values ? (
                                  <span key={tag.id}>{tag.name}</span>
                                ) : null
                            )}
                          </>
                        ) : (
                          <>{valueDisplayName ? valueDisplayName : "Values"}</>
                        )}
                      </button>
                      <div
                        className={`dropdown-menu ${
                          conditionValueDropdown ? "show" : "hide"
                        }`}
                      >
                        <input
                          type="text"
                          className="filter_rules_input"
                          placeholder="Search Tags"
                          value={tagsFilter}
                          onChange={(e) =>
                            handleTagsSearchFilter(e.target.value)
                          }
                        />
                        {/* HERE */}
                        <div className="dropdown-items">
                          {filteredTags.length > 0 ? (
                            filteredTags.map((tag) => (
                              <div
                                key={tag.id}
                                className="dropdown-item"
                                onClick={() => {
                                  handleSelectConditionValue(
                                    conditionGroupID,
                                    id,
                                    tag.id,
                                    tag.name
                                  );
                                }}
                              >
                                <li key={tag.id + tag.name}>
                                  <a>{tag.name}</a>
                                </li>
                              </div>
                            ))
                          ) : (
                            <div className="text-center">No Tags Found</div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : // ======================== SURVEY ========================
                  condition.options?.length === 2 &&
                    conditionRuleType === "survey" ? (
                    <div
                      className="dropdown dropdown_condition_rule value"
                      ref={valueRef}
                    >
                      <button
                        className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                        onClick={() =>
                          handleConditionValueDropdown(!conditionValueDropdown)
                        }
                      >
                        {valueDisplayName ? valueDisplayName : "Values"}
                      </button>
                      <div
                        className={`dropdown-menu ${
                          conditionValueDropdown ? "show" : "hide"
                        }`}
                      >
                        {/* Map values based on surveys*/}
                        {automationConditionsObj?.conditions.map((cond) =>
                          cond.selection === selectedConditionRule
                            ? cond.values.map((value) => (
                                <div
                                  key={value}
                                  className="dropdown-item"
                                  onClick={() => {
                                    handleSelectConditionValue(
                                      conditionGroupID,
                                      id,
                                      value,
                                      value
                                    );
                                  }}
                                >
                                  <li key={id + value}>
                                    <a>{value}</a>
                                  </li>
                                </div>
                              ))
                            : null
                        )}
                      </div>
                    </div>
                  ) : // ======================== LANGS ========================
                  conditionRuleType === "languages" ? (
                    <div
                      className="dropdown dropdown_condition_rule value"
                      ref={valueRef}
                    >
                      <button
                        className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                        onClick={() =>
                          handleConditionValueDropdown(!conditionValueDropdown)
                        }
                      >
                        {viewEditRule ? (
                          <>
                            {dropdownRuleData.languages?.map((language) =>
                              language.iso_code === values ? (
                                <span key={language.iso_code}>
                                  {language.name}
                                </span>
                              ) : null
                            )}
                          </>
                        ) : (
                          <>{valueDisplayName ? valueDisplayName : "Values"}</>
                        )}
                      </button>
                      <div
                        className={`dropdown-menu ${
                          conditionValueDropdown ? "show" : "hide"
                        }`}
                      >
                        <input
                          type="text"
                          className="filter_rules_input"
                          placeholder="Search Languages"
                          value={languagesFilter}
                          onChange={(e) =>
                            handleLanguagesSearchFilter(e.target.value)
                          }
                        />
                        <div className="dropdown-items">
                          {/* Map values based on languages*/}
                          {filteredLanguages.length > 0 ? (
                            filteredLanguages.map((language) => (
                              <li
                                className="dropdown-item"
                                key={language.iso_code}
                                onClick={() => {
                                  handleSelectConditionValue(
                                    conditionGroupID,
                                    id,
                                    language.iso_code,
                                    language.name
                                  );
                                }}
                              >
                                <a>{language.name}</a>
                              </li>
                            ))
                          ) : (
                            <div className="text-center">
                              No Languages Found
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : // ======================== COUNTRIES ========================
                  conditionRuleType === "countries" ? (
                    <div
                      className="dropdown dropdown_condition_rule value"
                      ref={valueRef}
                    >
                      <button
                        className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                        onClick={() =>
                          handleConditionValueDropdown(!conditionValueDropdown)
                        }
                      >
                        {viewEditRule ? (
                          <>
                            {dropdownRuleData.countries?.map((country) =>
                              country.iso_code === values ? (
                                <span key={country.iso_code}>
                                  {country.name}
                                </span>
                              ) : null
                            )}
                          </>
                        ) : (
                          <>{valueDisplayName ? valueDisplayName : "Values"}</>
                        )}
                      </button>
                      <div
                        className={`dropdown-menu ${
                          conditionValueDropdown ? "show" : "hide"
                        }`}
                      >
                        <input
                          type="text"
                          className="filter_rules_input"
                          placeholder="Search Countries"
                          value={countriesFilter}
                          onChange={(e) =>
                            handleCountriesSearchFilter(e.target.value)
                          }
                        />
                        <div className="dropdown-items">
                          {/* Map values based on countries*/}
                          {filteredCountries.length > 0 ? (
                            filteredCountries.map((country) => (
                              <li
                                className="dropdown-item"
                                key={country.iso_code}
                                onClick={() => {
                                  handleSelectConditionValue(
                                    conditionGroupID,
                                    id,
                                    country.iso_code,
                                    country.name
                                  );
                                }}
                              >
                                <a>{country.name}</a>
                              </li>
                            ))
                          ) : (
                            <div className="text-center">
                              No Countries Found
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ) : // ======================== BUCKETS ========================
                  // if the bucket is not acceptable, do not show the value dropdown
                  conditionRuleType === "buckets" &&
                    !unacceptableBuckets.some((bucket) => {
                      const bucketName = convertIDToName(
                        "bucket",
                        variable,
                        dropdownRuleData.automationConditionDataDB.buckets
                      );
                      const includesBucket = bucketName
                        ? bucketName.includes(bucket)
                        : false;
                      return includesBucket;
                    }) ? (
                    <div
                      className="dropdown dropdown_condition_rule value"
                      ref={valueRef}
                    >
                      <button
                        className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                        onClick={() =>
                          handleConditionValueDropdown(!conditionValueDropdown)
                        }
                      >
                        {valueDisplayName ? valueDisplayName : "Values"}
                      </button>
                      <div
                        className={`dropdown-menu ${
                          conditionValueDropdown ? "show" : "hide"
                        }`}
                      >
                        {/* Map values based on BUCKETS*/}
                        <div className="dropdown-items">
                          {automationConditionsObj?.conditions.map((cond) =>
                            cond.selection === selectedConditionRule ? (
                              cond.values.length > 0 ? (
                                cond.values.map((value) => (
                                  <div
                                    key={value}
                                    className="dropdown-item"
                                    onClick={() => {
                                      handleSelectConditionValue(
                                        conditionGroupID,
                                        id,
                                        value,
                                        value
                                      );
                                    }}
                                  >
                                    <li key={id + value}>
                                      <a>{value}</a>
                                    </li>
                                  </div>
                                ))
                              ) : (
                                <div>No buckets found</div>
                              )
                            ) : null
                          )}
                        </div>
                      </div>
                    </div>
                  ) : conditionRuleType === "participants" ? null : (
                    // if the bucket is not acceptable, and do not show the value dropdown
                    !unacceptableBuckets.some((bucket) => {
                      const bucketName = convertIDToName(
                        "bucket",
                        variable,
                        dropdownRuleData.automationConditionDataDB.buckets
                      );
                      const includesBucket = bucketName
                        ? bucketName.includes(bucket)
                        : false;
                      return includesBucket;
                    }) && (
                      <input
                        type="text"
                        name=""
                        id={id}
                        value={selectedConditionValue}
                        onChange={(e) => {
                          if (e.target.value.match(/^\d*$/))
                            handleSelectConditionValue(
                              conditionGroupID,
                              id,
                              e.target.value,
                              e.target.value
                            );
                        }}
                        maxLength={255}
                      />
                    )
                  )
                ) : condition.values.length === 0 ? (
                  <input
                    type="text"
                    name=""
                    id={id}
                    value={selectedConditionValue}
                    onChange={(e) => {
                      if (e.target.value.match(/^\d*$/))
                        handleSelectConditionValue(
                          conditionGroupID,
                          id,
                          e.target.value,
                          e.target.value
                        );
                    }}
                    maxLength={255}
                  />
                ) : (
                  // render dropdown for study
                  <div
                    className="dropdown dropdown_condition_rule value"
                    ref={valueRef}
                  >
                    <button
                      className="btn btn-primary dropdown-toggle condition_rule_dropdown_button"
                      onClick={() =>
                        handleConditionValueDropdown(!conditionValueDropdown)
                      }
                    >
                      {selectedConditionValue
                        ? selectedConditionValue
                        : "Values"}
                    </button>
                    <div
                      className={`dropdown-menu ${
                        conditionValueDropdown ? "show" : "hide"
                      }`}
                    >
                      {/* Map values based on selectedConditionValue(ID)*/}
                      {automationConditionsObj?.conditions.map((cond) =>
                        cond.selection === selectedConditionRule
                          ? cond.values.map((value) => (
                              <div
                                key={value}
                                className="dropdown-item"
                                onClick={() => {
                                  handleSelectConditionValue(
                                    conditionGroupID,
                                    id,
                                    value,
                                    value
                                  );
                                }}
                              >
                                <li key={id + value}>
                                  <a>{value}</a>
                                </li>
                              </div>
                            ))
                          : null
                      )}
                    </div>
                  </div>
                )
              ) : null
            )}
          </>
        ) : (
          // ON PREVIEW
          <div
            className="dropdown dropdown_condition_rule value"
            ref={valueRef}
          >
            {/*  
            If bucket is not acceptable, do not show the value dropdown
            */}
            {unacceptableBuckets.some((bucket) => {
              const bucketName = convertIDToName(
                "bucket",
                variable,
                dropdownRuleData.automationConditionDataDB.buckets
              );
              const includesBucket = bucketName
                ? bucketName.includes(bucket)
                : false;
              console.log("includesBucket:", includesBucket);
              return includesBucket;
            }) ? null : selection === "Last Login" ? (
              <></>
            ) : (
              <button className="btn btn-primary condition_rule_dropdown_button">
                {(selection === "Days in Study" ||
                  selection === "Days in Survey" ||
                  selection === "Buckets" ||
                  selection === "Today Completes" ||
                  selection === "Study Status" ||
                  selection === "Survey Progress" ||
                  selection === "Survey Completes" ||
                  selection === "Days in Bucket") && <>{values}</>}

                {/* if selection = Tag, using value, search through dropdownRuleData and display the name */}
                {selection === "Tag" && (
                  <span>
                    {dropdownRuleData.automationConditionDataDB.tags?.map(
                      (tag) =>
                        tag.id === values ? (
                          <span key={tag.id}>{tag.name}</span>
                        ) : null
                    )}
                  </span>
                )}

                {/* Countries */}
                {selection === "Country" && (
                  <span>
                    {dropdownRuleData.countries?.map((country) =>
                      country.iso_code === values ? (
                        <span key={country.iso_code}>{country.name}</span>
                      ) : null
                    )}
                  </span>
                )}

                {/* Languages */}
                {selection === "Language" && (
                  <span>
                    {dropdownRuleData.languages?.map((language) =>
                      language.iso_code === values ? (
                        <span key={language.iso_code}>{language.name}</span>
                      ) : null
                    )}
                  </span>
                )}

                {/* Buckets */}
                {selection === "Buckets" && (
                  <span>
                    {dropdownRuleData.automationConditionDataDB.buckets?.map(
                      (bucket) =>
                        bucket.id === values ? (
                          <span key={bucket.id}>{bucket.name}</span>
                        ) : null
                    )}
                  </span>
                )}

                {/* Surveys */}
                {selection === "Survey" && (
                  <span>
                    {dropdownRuleData.automationConditionDataDB.surveys?.map(
                      (survey) =>
                        survey.id === values ? (
                          <span key={survey.id}>{survey.name}</span>
                        ) : null
                    )}
                  </span>
                )}

                {selection === "Survey Status" && <span>{values}</span>}
              </button>
            )}
          </div>
        )}
      </div>
      {editable && (
        <button
          className="condition_rule_delete_button btn btn-danger"
          onClick={() => removeConditionRule(conditionGroupID, id)}
        >
          Delete
        </button>
      )}
    </div>
  );
};

export default ConditionRule;
