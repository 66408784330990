import ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { Bucket, BucketParticipant, Participant } from "../types"; // Adjust the import path as necessary

interface AggregatedData {
  variable: string | number;
  value: string | number;
  counts: Record<string, { compliant: number; nonCompliant: number }>;
}

export async function prepareAndDownloadExcel(
  buckets: Bucket[],
  participants: BucketParticipant[],
  studyParticipants: Participant[],
  filename: string = "BucketData.xlsx"
): Promise<void> {
  console.log("prepareAndDownloadExcel");
  console.log("buckets: ", buckets);
  console.log("participants: ", participants);
  console.log("studyParticipants: ", studyParticipants);
  console.log("filename: ", filename);

  const workbook = new ExcelJS.Workbook();

  // Creating the Chart Data Sheet
  const chartDataSheet = workbook.addWorksheet("Bucket Charts");

  // Define the order of the buckets for sorting
  const bucketOrder = [
    "Bucket",
    "Bonus",
    "Single",
    "Compliant",
    "Non-Compliant",
    "Completes"
  ];

  // Define columns for the "Bucket Chart Data" sheet
  chartDataSheet.columns = [
    { header: "Bucket", key: "bucket", width: 20 },
    { header: "Compliant", key: "compliant", width: 15 },
    { header: "Non-Compliant", key: "non_compliant", width: 15 }
  ];

  // Custom sorting function for buckets
  function sortBuckets(a: Bucket, b: Bucket) {
    // Extract the prefix or keyword from the bucket's name to determine its type
    const getTypeOrder = (bucket: Bucket) => {
      const prefixMatch = bucket.bucket.match(
        /^(Bucket|Bonus|Single|Compliant|Non-Compliant|Completes)/
      );
      return prefixMatch
        ? bucketOrder.indexOf(prefixMatch[0])
        : bucketOrder.length;
    };

    // Compare the order of bucket types
    const orderA = getTypeOrder(a);
    const orderB = getTypeOrder(b);

    if (orderA !== orderB) {
      return orderA - orderB;
    }

    // If the types are the same, sort by the bucket's name
    return a.bucket.localeCompare(b.bucket);
  }

  // Sort the buckets as per the custom criteria
  buckets.sort(sortBuckets);

  // Initialize the chart data with headers
  const chartData = [["Bucket", "Compliant", "Non-Compliant"]];

  // Populate the chart data with compliant and non-compliant counts for each bucket
  buckets.forEach((bucket) => {
    let compliantCount = 0;
    let nonCompliantCount = 0;

    participants.forEach((participant) => {
      participant.participant_buckets?.forEach((partBucket) => {
        if (partBucket.bucket_id === bucket.id) {
          if (partBucket.compliance_status === "compliant") {
            compliantCount++;
          } else if (partBucket.compliance_status === "non-compliant") {
            nonCompliantCount++;
          }
        }
      });
    });

    // Add the bucket data to the chart data sheet
    chartDataSheet.addRow({
      bucket: bucket.survey_name || bucket.bucket,
      compliant: compliantCount,
      non_compliant: nonCompliantCount
    });
  });

  // Add the chart data to the worksheet
  chartData.forEach((row, index) => {
    // Skip header row since it's already defined in `chartDataSheet.columns`
    if (index > 0) {
      chartDataSheet.addRow({
        bucket: row[0],
        compliant: row[1],
        non_compliant: row[2]
      });
    }
  });

  const summarySheet = workbook.addWorksheet("GSI Summary");

  // Define special and regular buckets based on your criteria
  const specialBucketNames = ["completes", "Compliant", "non compliant"];
  const specialBuckets = buckets.filter((bucket) =>
    specialBucketNames.includes(bucket.bucket)
  );
  const regularBuckets = buckets.filter(
    (bucket) => !specialBucketNames.includes(bucket.bucket)
  );

  // Create the headers for the second row
  // Add 'GSI Variable' and 'Value' headers
  const gsiVariableCell = summarySheet.getCell("A2");
  gsiVariableCell.value = "GSI Variable";
  const valueCell = summarySheet.getCell("B2");
  valueCell.value = "Value";

  // Add headers for special buckets
  specialBuckets.forEach((bucket, index) => {
    const cell = summarySheet.getCell(2, 3 + index);
    cell.value = `${bucket.bucket} Total`;
  });

  // Add sub-headers for regular buckets
  regularBuckets.forEach((bucket, index) => {
    const baseColumn = 3 + specialBuckets.length + index * 3;
    summarySheet.getCell(2, baseColumn).value = "Compliant";
    summarySheet.getCell(2, baseColumn + 1).value = "Non-Compliant";
    summarySheet.getCell(2, baseColumn + 2).value = "Total";
  });

  const columnHeaders = ["GSI Variable", "Value"];
  specialBuckets.forEach((bucket) => {
    columnHeaders.push(`${bucket.bucket} Total`);
  });
  regularBuckets.forEach((bucket) => {
    columnHeaders.push(
      `${bucket.bucket} Compliant`,
      `${bucket.bucket} Non-Compliant`,
      `${bucket.bucket} Total`
    );
  });

  const headerRow = summarySheet.getRow(2);
  columnHeaders.forEach((header, index) => {
    headerRow.getCell(index + 1).value = header;
  });

  summarySheet.columns = columnHeaders.map((header, index) => ({
    header: header,
    key: header.replace(/ /g, ""), // Remove spaces to create a key
    width: 20 // Set a default width for all columns
  }));

  summarySheet.getRow(1).values = [null, null]; // Start with two empty cells for 'GSI Variable' and 'Value'

  // Now, merge cells for regular bucket names in the first row and set their values
  let columnIndex = 3 + specialBuckets.length; // Start after 'GSI Variable' and 'Value' columns
  regularBuckets.forEach((bucket) => {
    summarySheet.mergeCells(1, columnIndex, 1, columnIndex + 2); // Merge cells for the bucket name
    summarySheet.getCell(1, columnIndex).value =
      bucket.survey_name || bucket.bucket; // Set the bucket name
    columnIndex += 3; // Prepare for the next bucket's columns
  });
  // Set up the second row with headers for "GSI Variable", "Value", and special buckets
  const secondRow = summarySheet.getRow(2);
  secondRow.getCell(1).value = "GSI Variable"; // This will be in 'A2'
  secondRow.getCell(2).value = "Value"; // This will be in 'B2'

  // Set headers for special buckets starting from the third column
  columnIndex = 3; // Reset the index to start from the first special bucket
  specialBuckets.forEach((bucket) => {
    secondRow.getCell(columnIndex).value = `${bucket.bucket} Total`; // These will be in 'C2' onwards
    columnIndex++;
  });

  // Set sub-headers for regular buckets beneath the merged cells in the first row
  columnIndex = 3 + specialBuckets.length; // Start from the first regular bucket sub-header
  regularBuckets.forEach(() => {
    secondRow.getCell(columnIndex).value = "Compliant"; // These will be in the cells right under the merged cells
    secondRow.getCell(columnIndex + 1).value = "Non-Compliant";
    secondRow.getCell(columnIndex + 2).value = "Total";
    columnIndex += 3;
  });

  const aggregatedData: Record<string, AggregatedData> = {};
  // Populate data
  // Simplified data population logic goes here
  participants.forEach((participant) => {
    participant.gsi_answers?.forEach((answer) => {
      const key = `${answer.variable_name}_${answer.value}`;
      if (!aggregatedData[key]) {
        aggregatedData[key] = {
          variable: answer.variable_name,
          value: answer.value,
          counts: buckets.reduce(
            (acc, bucket) => {
              acc[bucket.id] = { compliant: 0, nonCompliant: 0 };
              return acc;
            },
            {} as Record<string, { compliant: number; nonCompliant: number }>
          )
        };
      }

      participant.participant_buckets?.forEach(
        ({ bucket_id, compliance_status }) => {
          if (compliance_status === "compliant") {
            aggregatedData[key].counts[bucket_id].compliant++;
          } else {
            aggregatedData[key].counts[bucket_id].nonCompliant++;
          }
        }
      );
    });
  });

  const sortedEntries = Object.values(aggregatedData).sort((a, b) => {
    const varA = String(a.variable).toUpperCase();
    const varB = String(b.variable).toUpperCase();
    if (varA < varB) {
      return -1;
    }
    if (varA > varB) {
      return 1;
    }
    return 0;
  }); // If sorting is needed, apply it here

  summarySheet.getColumn("A").alignment = { horizontal: "left" };

  // Adding rows based on aggregated data
  sortedEntries.forEach((dataItem) => {
    const rowData: any = {};

    // Set the values for the GSI Variable and Value columns
    rowData["GSIVariable"] = dataItem.variable;
    rowData["Value"] = dataItem.value;

    // Set the values for each bucket
    buckets.forEach((bucket) => {
      const bucketCounts = dataItem.counts[bucket.id];
      const bucketKey = bucket.bucket.replace(/ /g, ""); // Again, remove spaces to match the key
      if (specialBucketNames.includes(bucket.bucket)) {
        rowData[`${bucketKey}Total`] =
          bucketCounts.compliant + bucketCounts.nonCompliant;
      } else {
        rowData[`${bucketKey}Compliant`] = bucketCounts.compliant;
        rowData[`${bucketKey}Non-Compliant`] = bucketCounts.nonCompliant;
        rowData[`${bucketKey}Total`] =
          bucketCounts.compliant + bucketCounts.nonCompliant;
      }
    });

    // Add the row data to the summarySheet
    summarySheet.addRow(rowData);
  });

  summarySheet.columns.forEach((column: any) => {
    const maxLength = Math.max(
      ...column.values.map((val: any) => (val ? String(val).length : 0)),
      10
    ); // Ensure a minimum width of 10
    column.width = maxLength < 10 ? 10 : maxLength + 2; // Add some padding
  });

  const gsiVariableWidth = 20;
  const valueWidth = 15;
  const bucketTotalWidth = 15;
  const compliantWidth = 15;
  const nonCompliantWidth = 15;
  const totalWidth = 15;

  // Set the width for the 'GSI Variable' and 'Value' columns
  summarySheet.getColumn("A").width = gsiVariableWidth;
  summarySheet.getColumn("B").width = valueWidth;

  // Set the width for the special bucket total columns
  specialBuckets.forEach((bucket, index) => {
    summarySheet.getColumn(3 + index).width = bucketTotalWidth;
  });

  // Set the width for the regular bucket columns
  regularBuckets.forEach((bucket, index) => {
    const baseColumn = 3 + specialBuckets.length + index * 3;
    summarySheet.getColumn(baseColumn).width = compliantWidth;
    summarySheet.getColumn(baseColumn + 1).width = nonCompliantWidth;
    summarySheet.getColumn(baseColumn + 2).width = totalWidth;
  });

  // Creating the Participant Details Sheet
  const participantDetailsSheet = workbook.addWorksheet("Participant Details");
  // Define columns for Participant Details Sheet based on your needs

  const allGsiVariables = new Set<number | string>();
  participants.forEach((participant) => {
    participant.gsi_answers?.forEach((answer) => {
      allGsiVariables.add(answer.variable_name);
    });
  });

  const gsiVariableHeaders = Array.from(allGsiVariables)
    .sort()
    .map((gsiVariable) => {
      const headerString = String(gsiVariable); // Explicitly convert to string
      return {
        header: headerString, // Now guaranteed to be a string
        key: headerString.replace(/ /g, "_"), // Replace spaces with underscores
        width: 20 // Set a default width
      };
    });

  // Define standard columns for the Participant Details Sheet
  const standardColumns = [
    { header: "Participant ID", key: "id", width: 15 },
    { header: "Name", key: "name", width: 20 },
    { header: "Surname", key: "surname", width: 20 },
    { header: "Email", key: "email", width: 25 },
    { header: "Mobile Phone", key: "mobile_phone", width: 20 },
    { header: "Timezone", key: "timezone", width: 15 },
    { header: "Language", key: "language", width: 15 },
    { header: "Country", key: "country", width: 15 },
    { header: "Bucket", key: "bucket", width: 20 },
    { header: "Last Login", key: "last_login", width: 20 },
    { header: "Status", key: "status", width: 15 },
    { header: "Joined Date", key: "joined_date", width: 20 },
    { header: "Tags", key: "tags", width: 25 }
  ];

  // Combine standard columns with dynamic GSI variable columns
  participantDetailsSheet.columns = [
    ...standardColumns,
    ...gsiVariableHeaders.map((header) => ({
      ...header,
      header: String(header.header) // Ensure header is a string
    }))
  ];

  // Populate Participant Details Sheet with data
  studyParticipants?.forEach((participant) => {
    const bucketNames =
      participant.participant_buckets &&
      participant.participant_buckets.length > 0
        ? participant.participant_buckets
            .map((participantBucket) => {
              // Find the matching bucket in the buckets array
              const matchingBucket = buckets.find(
                (bucket) => bucket.id === participantBucket.bucket_id
              );

              // Check if matchingBucket is not undefined before accessing its properties
              if (matchingBucket) {
                // If the bucket is of type "single", use the survey_name, else use the bucket name
                return matchingBucket.type === "single"
                  ? matchingBucket.survey_name
                  : matchingBucket.bucket;
              } else {
                // Handle the case where no matching bucket is found, e.g., return a default name or "N/A"
                return "N/A";
              }
            })
            .join("; ")
        : "N/A";

    const row: { [key: string]: any } = {
      id: participant.id,
      name: participant.participant_name,
      surname: participant.participant_surname,
      email: participant.participant_email,
      mobile_phone: `${participant.participant_mobile_code} ${participant.participant_mobile_number}`,
      timezone: participant.participant_timezone,
      language: participant.participant_lang_iso,
      country: participant.participant_country_iso,
      bucket: bucketNames,
      last_login: participant.participant_last_login,
      status: participant.study_status,
      joined_date: participant.study_joined_date,
      tags: participant.participant_tags.join("; ")
    };

    // Initialize GSI variables with "N/A"
    gsiVariableHeaders.forEach((header) => {
      const key = header.key; // Already converted to a valid key format
      row[key] = "N/A"; // Default value
    });

    // Populate dynamic GSI variable data for each participant
    participants
      .find((p) => String(p.id) === String(participant.id)) // Ensure comparison as strings
      ?.gsi_answers?.forEach((answer) => {
        const key = String(answer.variable_name).replace(/ /g, "_"); // Ensure conversion to string before replace
        row[key] = answer.value; // Overwrite "N/A" if there's a value
      });

    participantDetailsSheet.addRow(row);
  });

  // Write to buffer and initiate download
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  });
  saveAs(blob, filename);
}
