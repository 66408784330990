import React from "react";
import FieldRadio from "../../Fields/FieldRadio.component";
import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldDate from "../../Fields/fieldDate.component";
import FieldImage from "../../Fields/fieldImage.component";
import FieldText from "../../Fields/fieldText.component";
import FieldTextArea from "../../Fields/fieldTextArea.component";
import { AddSurveyWizardStepInformationProps } from "../addSurveyWizard.component";
import AddSurveyWizardFooter from "../addSurveyWizardFooter.component";

interface StepAddSurveyInformationProps
  extends AddSurveyWizardStepInformationProps {}

//   export interface AddSurveyWizardStepInformationProps
//   extends AddSurveyWizardProps {
//   surveyType: "diary" | "single";
//   surveyName: string | null;
//   surveyDescription: string | null;
//   surveyAppDisplayName: string | null;
//   surveyIcon: string | null;
// }

const StepAddSurveyInformation: React.FC<StepAddSurveyInformationProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  handleWizardBack,
  handleWizardSubmit,
  handleInputChange,
  // Fields
  pageValid,
  surveyType,
  surveyName,
  surveyDescription,
  // surveyAppDisplayName,
  surveyIcon,
  surveyAutoStart,
  surveyStartDate,
  inputErrors,
  availableSurveyTypes
}) => {
  // console.log("availableSurveyTypes", availableSurveyTypes);

  return (
    <div className="card card-default add_survey_form_page p-4">
      <h3 className="form_header mb-3">Survey Information</h3>
      <div className="add_survey_form_page_inputs">
        {/* <FieldDropDown
          label="Survey Type"
          inputName="surveyType"
          inputValue={surveyType}
          onInputChange={handleInputChange}
          inputOptions={[
            {
              id: "diary",
              name: "diary",
            },
            {
              id: "single",
              name: "single",
            },
          ]}
          error={inputErrors?.surveyType ? inputErrors.surveyType : null}
        /> */}
        <FieldRadio
          label="Survey Type"
          tooltip="Specifies whether the survey is a continuous diary or a single, standalone survey. This setting affects the survey setup and participant interface within the RMS and app"
          tooltipPosition="bottom"
          inputName="surveyType"
          inputValue={surveyType}
          onInputChange={handleInputChange}
          options={availableSurveyTypes}
          error={inputErrors?.surveyType ? inputErrors.surveyType : null}
        />

        <FieldText
          label="Survey Name"
          tooltip={
            surveyType === "diary"
              ? "The name of the diary that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
              : "The name of the single, standalone survey that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
          }
          inputName="surveyName"
          inputValue={surveyName ? surveyName : ""}
          onInputChange={handleInputChange}
          error={inputErrors?.surveyName ? inputErrors.surveyName : null}
        />
        <FieldTextArea
          label="Survey Description"
          tooltip={
            surveyType === "diary"
              ? "A description of the diary that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
              : "A description of the single, standalone survey that will be displayed in both the RMS, and within the App for respondents to see.  It will be translated within the app."
          }
          inputName="surveyDescription"
          inputValue={surveyDescription ? surveyDescription : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.surveyDescription
              ? inputErrors.surveyDescription
              : null
          }
        />
        {/* <FieldText
          tooltip="This is the name that will be displayed in the app."
          label="Survey App Display Name"
          inputName="surveyAppDisplayName"
          inputValue={surveyAppDisplayName ? surveyAppDisplayName : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.surveyAppDisplayName
              ? inputErrors.surveyAppDisplayName
              : null
          }
        /> */}
        <FieldCheckbox
          label="Auto Start"
          tooltip="Controls whether the survey begins automatically on the specified start date or requires manual initiation. Useful for coordinating the survey launch with other project timelines"
          subLabel={`Automatically moves participants with the "auto start" tag to the ${surveyType}'s first ${
            surveyType === "diary" ? "bucket" : "day"
          } on the Start Date`}
          inputName="surveyAutoStart"
          inputValue={surveyAutoStart ? surveyAutoStart : false}
          onInputChange={handleInputChange}
        />
        <FieldDate
          label="Start Date"
          tooltip="The date when the survey is scheduled to begin. This can be set in advance to align with project planning and participant availability"
          inputName="surveyStartDate"
          inputValue={surveyStartDate ? surveyStartDate : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.surveyStartDate ? inputErrors.surveyStartDate : null
          }
        />
        <FieldImage
          label="Survey Icon"
          tooltip="The image associated with the survey, which will be displayed as an icon in both the RMS and the app. This helps participants easily identify the survey"
          inputName="surveyIcon"
          inputImage={surveyIcon}
          onInputChange={handleInputChange}
          error={inputErrors?.surveyIcon ? inputErrors.surveyIcon : null}
        />

        {/* submit */}
        <AddSurveyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          pageValid={pageValid}
          handleWizardSubmit={handleWizardSubmit}
        />
      </div>
    </div>
  );
};

export default StepAddSurveyInformation;
