import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { sendCreatedRole } from "../../../models/roles.model";
import {
  ResearcherPermission,
  ResearcherRole,
  SidebarData
} from "../../../types";
import { getSidebarData } from "../../../utilities/sideBarInfo.util";
import { capitalize } from "../../../utilities/utils";

type CreateRoleProps = {
  handleCreateRoleView: () => void;
  setSaveConfirmed: (value: boolean) => void;
  allRoles: ResearcherRole[];
  allPermissions: ResearcherPermission[];
};

type ClientStudy = {
  studyID: string;
  studyName: string;
};

type ClientStudyInfo = {
  clientID: string;
  clientName: string;
  clientStudies: ClientStudy[];
};

type SectionType = "Clients" | "Sidebar" | "Permissions";

const CreateRole: React.FC<CreateRoleProps> = ({
  handleCreateRoleView,
  setSaveConfirmed,
  allRoles,
  allPermissions
}) => {
  const [roleName, setRoleName] = useState<string>("");
  const [selectedPermissions, setSelectedPermissions] = useState<Set<string>>(
    new Set()
  );
  const [checkedBaseNames, setCheckedBaseNames] = useState<Set<string>>(
    new Set()
  );
  const [groupedPermissions, setGroupedPermissions] = useState<{
    Clients: Map<string, ResearcherPermission[]>;
    Sidebar: Map<string, ResearcherPermission[]>;
    Permissions: Map<string, ResearcherPermission[]>;
  }>({ Clients: new Map(), Sidebar: new Map(), Permissions: new Map() });

  const [selectAllStatus, setSelectAllStatus] = useState<
    Record<SectionType, boolean>
  >({
    Clients: false,
    Sidebar: false,
    Permissions: false
  });
  const studyPermissions = allPermissions.filter((perm) =>
    perm.permission.startsWith("study_")
  );
  const [toggledClients, setToggledClients] = useState<Set<string>>(new Set());
  const [selectedClients, setSelectedClients] = useState<Map<string, boolean>>(
    new Map()
  );

  const [clientStudyInfo, setClientStudyInfo] = useState<ClientStudyInfo[]>([]);

  // For displaying tick/tilde icons for client studies
  const [clientToggleState, setClientToggleState] = useState<
    Map<string, number>
  >(new Map());

  useEffect(() => {
    async function fetchData() {
      const sideBarData = await getSidebarData();
      if (!sideBarData) {
        console.log("Failed to fetch sidebar data");
      }
      console.log("sideBarData", sideBarData);

      // Transform the data into the desired shape
      const transformedData: ClientStudyInfo[] = sideBarData
        ? sideBarData.map((client: SidebarData) => ({
            clientID: client.clientID,
            clientName: client.clientName,
            clientStudies: client.clientStudies.map((study) => ({
              studyID: study.studyID,
              studyName: study.studyName
            }))
          }))
        : [];

      console.log(transformedData);
      setClientStudyInfo(transformedData);
    }
    fetchData();
  }, []);

  // console.log(allPermissions, "allPermissions");
  useEffect(() => {
    const groupPermissions = (type: string) => {
      const perms = allPermissions.filter(
        (p) => p.type === type && !p.permission.includes("leads")
      );
      const map = new Map<string, ResearcherPermission[]>();
      // TODO: CHECK THIS, NO LONGER SPLITTING IF TYPE IS CLIENT, ACCOUNTING FOR STAGING
      perms.forEach((perm) => {
        const baseName =
          perm.type === "client"
            ? perm.permission
            : perm.permission.split("_")[0];
        if (!map.has(baseName)) {
          map.set(baseName, []);
        }
        map.get(baseName)?.push(perm);
      });
      return map;
    };

    setGroupedPermissions({
      Clients: groupPermissions("client"),
      Sidebar: groupPermissions("config"),
      Permissions: groupPermissions("subject")
    });
  }, [allPermissions]);

  type ExcludeClientsKey = Exclude<keyof typeof groupedPermissions, "Clients">;

  useEffect(() => {
    // Function to determine if all permissions for a given baseName are selected
    const areAllPermissionsSelected = (
      baseName: string,
      permissionsMap: Map<string, ResearcherPermission[]>
    ) => {
      const permissions = permissionsMap.get(baseName);
      if (!permissions) return false;

      return permissions
        .filter((perm) => !perm.permission.endsWith("_all"))
        .every((perm) => selectedPermissions.has(perm.id));
    };

    // Function to check if any section needs an update
    const anySectionNeedsUpdate = () => {
      // Define the sections we want to check
      const sections: ExcludeClientsKey[] = ["Sidebar", "Permissions"];

      return sections.some((section) => {
        const permissionsMap = groupedPermissions[section];
        return Array.from(permissionsMap.keys()).some((baseName) => {
          const allPermission = permissionsMap
            .get(baseName)
            ?.find((perm) => perm.permission.endsWith("_all"));
          const allSelected = areAllPermissionsSelected(
            baseName,
            permissionsMap
          );
          const allPermissionId = allPermission ? allPermission.id : null;

          return (
            allPermissionId !== null &&
            ((allSelected && !selectedPermissions.has(allPermissionId)) ||
              (!allSelected && selectedPermissions.has(allPermissionId)))
          );
        });
      });
    };

    if (anySectionNeedsUpdate()) {
      const updatedSelectedPermissions = new Set(selectedPermissions);

      const sectionsToUpdate: ExcludeClientsKey[] = ["Sidebar", "Permissions"];
      sectionsToUpdate.forEach((section) => {
        const permissionsMap = groupedPermissions[section];
        permissionsMap.forEach((perms, baseName: string) => {
          const allPermission = perms.find((perm) =>
            perm.permission.endsWith("_all")
          );
          const allPermissionId = allPermission ? allPermission.id : null;

          if (allPermissionId) {
            const allSelected = areAllPermissionsSelected(
              baseName,
              permissionsMap
            );
            const allPermissionSelected =
              selectedPermissions.has(allPermissionId);

            if (allSelected || allPermissionSelected) {
              // If _all is selected, keep only the _all permission
              updatedSelectedPermissions.add(allPermissionId);
              perms.forEach((perm) => {
                if (perm.id !== allPermissionId) {
                  updatedSelectedPermissions.delete(perm.id);
                }
              });
            } else {
              // If _all is not selected, remove it
              updatedSelectedPermissions.delete(allPermissionId);
            }
          }
        });
      });

      // Update only if there's a difference in selected permissions
      if (
        updatedSelectedPermissions.size !== selectedPermissions.size ||
        ![...updatedSelectedPermissions].every((permId) =>
          selectedPermissions.has(permId)
        ) ||
        ![...selectedPermissions].every((permId) =>
          updatedSelectedPermissions.has(permId)
        )
      ) {
        setSelectedPermissions(updatedSelectedPermissions);
      }
    }
  }, [groupedPermissions, selectedPermissions]);

  const handlePermissionChange = (permId: string) => {
    setSelectedPermissions((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(permId)) {
        newSet.delete(permId);
      } else {
        newSet.add(permId);
      }
      return newSet;
    });
  };

  const handleSelectAllChange = (section: SectionType) => {
    const currentStatus = !selectAllStatus[section];
    setSelectAllStatus((prevStatus) => ({
      ...prevStatus,
      [section]: currentStatus
    }));

    // Mapping section to the corresponding permission value for 'all'
    const sectionToPermissionMap = {
      Clients: "clients",
      Sidebar: "config",
      Permissions: "subject"
    };

    // Find the "all" permission ID for the current section
    const allPermission = allPermissions.find(
      (perm) =>
        perm.type === "all" &&
        perm.permission === sectionToPermissionMap[section]
    );

    if (!allPermission) {
      return; // Handle the case where "all" permission is not found
    }

    const allPermissionID = allPermission.id;

    setSelectedPermissions((prevSelected) => {
      const newSelected = new Set(prevSelected);

      if (currentStatus && allPermissionID) {
        // Add the "all" permission ID without removing other permissions
        newSelected.add(allPermissionID);
      } else if (!currentStatus && allPermissionID) {
        // Remove the "all" permission ID
        newSelected.delete(allPermissionID);
      }

      // Handle the case where an individual permission was deselected
      if (!currentStatus && selectedPermissions.has(allPermissionID)) {
        // Find all the individual permissions in the section
        const sectionPermissions = allPermissions.filter(
          (perm) =>
            perm.type === sectionToPermissionMap[section] &&
            !perm.permission.endsWith("_all")
        );

        // Remove those individual permissions from the selected set
        sectionPermissions.forEach((perm) => {
          if (selectedPermissions.has(perm.id)) {
            newSelected.delete(perm.id);
          }
        });
      }

      return newSelected;
    });
  };

  const handleBaseNameChange = (section: string, baseName: string) => {
    if (section === "Clients") {
      const clientPerm = groupedPermissions.Clients.get(baseName)?.[0];
      if (clientPerm && clientPerm.id) {
        setSelectedPermissions((prevSelected) => {
          const newSelected = new Set(prevSelected);
          if (newSelected.has(clientPerm.id)) {
            newSelected.delete(clientPerm.id);
          } else {
            newSelected.add(clientPerm.id);
          }
          return newSelected;
        });

        // Update the selectedClients Map to track the selected state of the client
        setSelectedClients((prevSelected) => {
          const newSelected = new Map(prevSelected);
          const isSelected = newSelected.get(baseName) || false;
          newSelected.set(baseName, !isSelected);
          return newSelected;
        });
      }
    } else {
      // For other sections, update the checked base names
      setCheckedBaseNames((prevChecked) => {
        const newChecked = new Set(prevChecked);
        const key = `${section}_${baseName}`;
        if (newChecked.has(key)) {
          newChecked.delete(key);
        } else {
          newChecked.add(key);
        }
        return newChecked;
      });
    }
  };

  // 1 Click toggle dropdown, 2 Click select all, 3 Click deselect all
  const handleClientToggle = (clientID: string) => {
    setClientToggleState((prev) => {
      const newState = new Map(prev);
      const currentState = newState.get(clientID) || 0;
      const nextState = (currentState + 1) % 3;
      newState.set(clientID, nextState);

      const client = clientStudyInfo.find(
        (client) => client.clientID === clientID
      );
      if (client) {
        if (nextState === 1) {
          // First click: Toggle visibility
          console.log("in here");
          setToggledClients((prev) => new Set(prev).add(clientID));
        } else if (nextState === 2) {
          // Second click: Select all studies
          console.log("in here");
          console.log("client", client);
          console.log("client.clientStudies", client.clientStudies);
          client.clientStudies.forEach((study) => {
            const permissionId = `study_${client.clientID}_${study.studyID}`;
            console.log("permissionId", permissionId);
            const permission = studyPermissions.find(
              (perm) => perm.permission === permissionId
            );
            if (permission) {
              console.log(permission);
              setSelectedPermissions((prev) =>
                new Set(prev).add(permission.id)
              );
            }
          });
        } else {
          // Third click: Deselect all studies and hide
          console.log("in here");

          setToggledClients((prev) => {
            const newSet = new Set(prev);
            newSet.delete(clientID);
            return newSet;
          });
          client.clientStudies.forEach((study) => {
            const permissionId = `study_${client.clientID}_${study.studyID}`;
            const permission = studyPermissions.find(
              (perm) => perm.permission === permissionId
            );
            if (permission) {
              setSelectedPermissions((prev) => {
                const newPermissions = new Set(prev);
                newPermissions.delete(permission.id);
                return newPermissions;
              });
            }
          });
        }
      }
      return newState;
    });
  };

  useEffect(() => {
    console.log(selectedPermissions);
  }, [selectedPermissions]);

  const renderPermissions = (
    section: SectionType,
    permissionsMap: Map<string, ResearcherPermission[]>
  ) => {
    return (
      <div className="permissions_container">
        <div className="section_title">
          <h2>{section}</h2>
          <input
            type="checkbox"
            className="form-check-input"
            checked={selectAllStatus[section]}
            onChange={() => handleSelectAllChange(section)}
            id={`selectAll-${section}`}
          />
        </div>
        <div className={`section-body ${section}-body`}>
          {Array.from(permissionsMap.keys()).map((baseName) => {
            const basePermissions = permissionsMap.get(baseName);
            if (!basePermissions) return null; // Guard against undefined values

            const clientPermission = allPermissions.find(
              (perm) => perm.type === "client" && perm.permission === baseName
            );

            // Use the client name from JSON data if available, otherwise use the baseName
            const displayName =
              baseName === "global"
                ? "Global Reports"
                : baseName === "researcher"
                  ? "RMS Account"
                  : baseName === "study"
                    ? "Studies"
                    : baseName === "survey"
                      ? "Surveys"
                      : baseName === "bucket"
                        ? "Buckets"
                        : baseName === "participant"
                          ? "Participants"
                          : baseName === "tag"
                            ? "Tags"
                            : baseName === "automation"
                              ? "Automations"
                              : baseName === "chat"
                                ? "Chats"
                                : clientPermission
                                  ? clientPermission.name || baseName // Use permission if name is blank
                                  : capitalize(baseName);

            return (
              <div key={baseName}>
                <div className="form-check">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={
                      selectAllStatus[section] ||
                      (section === "Clients"
                        ? selectedPermissions.has(basePermissions[0].id)
                        : checkedBaseNames.has(`${section}_${baseName}`))
                    }
                    onChange={() => handleBaseNameChange(section, baseName)}
                    id={`baseName-${baseName}`}
                  />
                  <label
                    className="form-check-label"
                    htmlFor={`baseName-${baseName}`}
                  >
                    {displayName}
                  </label>
                </div>
                {section !== "Clients" &&
                  checkedBaseNames.has(`${section}_${baseName}`) &&
                  basePermissions
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .filter(
                      (perm) => (perm.permission.match(/_/g) || []).length !== 2
                    ) // Filter out permissions with exactly 2 underscores
                    .sort((a, b) => {
                      return a.permission.localeCompare(b.permission);
                    })
                    .map((perm) => {
                      if (!perm.id) return null; // Guard against undefined id
                      return (
                        <div
                          className="form-check sub-permissions"
                          key={perm.id}
                        >
                          <input
                            className="form-check-input"
                            type="checkbox"
                            checked={
                              selectAllStatus[section] ||
                              selectedPermissions.has(perm.id)
                            }
                            onChange={() => handlePermissionChange(perm.id)}
                            id={`perm-${perm.id}`}
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`perm-${perm.id}`}
                          >
                            {perm.permission}
                          </label>
                        </div>
                      );
                    })}

                {/* Study Permissions */}
                {section === "Clients" &&
                  selectedClients.get(baseName) && ( // Only render if the client is selected
                    <div>
                      {clientStudyInfo
                        .filter((client) => client.clientName === displayName) // Filter for the current client
                        .map((client) => {
                          const toggleState =
                            clientToggleState.get(client.clientID) || 0;
                          return (
                            <div key={client.clientID}>
                              <div className="form-check sub-permissions">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  checked={toggleState === 2}
                                  ref={(el) => {
                                    if (el)
                                      el.indeterminate = toggleState === 1;
                                  }}
                                  onChange={() =>
                                    handleClientToggle(client.clientID)
                                  }
                                  id={`client-${client.clientID}`}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor={`client-${client.clientID}`}
                                >
                                  Studies
                                </label>
                              </div>
                              {toggledClients.has(client.clientID) && (
                                <div className="sub-permissions">
                                  {client.clientStudies.map((study) => {
                                    const permissionId = `study_${client.clientID}_${study.studyID}`;
                                    const permission = studyPermissions.find(
                                      (perm) => perm.permission === permissionId
                                    );
                                    if (permission) {
                                      return (
                                        <div
                                          className="form-check sub-permissions"
                                          key={study.studyID}
                                        >
                                          <input
                                            type="checkbox"
                                            className="form-check-input"
                                            checked={selectedPermissions.has(
                                              permission.id
                                            )}
                                            onChange={() =>
                                              handlePermissionChange(
                                                permission.id
                                              )
                                            }
                                            id={permission.id}
                                          />
                                          <label
                                            className="form-check-label"
                                            htmlFor={permission.id}
                                          >
                                            {study.studyName}
                                          </label>
                                        </div>
                                      );
                                    }
                                    return null;
                                  })}
                                </div>
                              )}
                            </div>
                          );
                        })}
                    </div>
                  )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  // useEffect(() => {
  //   console.log("selectedClients", selectedClients);
  //   console.log("clientStudyInfo", clientStudyInfo);
  //   console.log("allPermissions", allPermissions);
  //   console.log("toggledClients", toggledClients);
  //   console.log("studyPermissions", studyPermissions);
  // }, [
  //   selectedClients,
  //   clientStudyInfo,
  //   allPermissions,
  //   toggledClients,
  //   studyPermissions
  // ]);

  function handleRoleName(event: React.ChangeEvent<HTMLInputElement>) {
    setRoleName(event.target.value);
  }

  function handleValidation(): boolean {
    // must be between 1 and 50 characters
    const validNameLength = roleName.length > 4 && roleName.length < 50;
    // must be unique
    const validNameDuplicate: boolean = !allRoles.some(
      (role) => role.name.toLowerCase() === roleName.toLowerCase()
    );
    // must not contain special characters, only letters, numbers, and "-"
    const validNameCharacters: boolean = Boolean(
      roleName.match(/^[a-zA-Z0-9-]*$/)
    );
    // must have at least one permission
    const validPermissions = selectedPermissions.size > 0;
    const AllValid =
      validNameLength &&
      validNameDuplicate &&
      validNameCharacters &&
      validPermissions;

    if (!AllValid) {
      const errorMessages = [];
      if (!validNameLength) {
        errorMessages.push("Role name must be between 5 and 50 characters");
      }
      if (!validNameDuplicate) {
        errorMessages.push("Role name must be unique");
      }
      if (!validNameCharacters) {
        errorMessages.push(
          `Role name must not contain special characters or spaces.<br />Only letters, numbers and "-" are allowed`
        );
      }
      if (!validPermissions) {
        errorMessages.push("Role must have at least one permission");
      }

      let errorMessagesHTML = `<strong>Please fix the following</strong>`;
      errorMessages.forEach((message) => {
        errorMessagesHTML += `<br /><br />${message}`;
      });

      Swal.fire({
        title: "Error!",
        html: errorMessagesHTML,
        icon: "error",
        confirmButtonText: "Ok"
      });
    }

    return AllValid;
  }

  function handleSubmit() {
    if (!handleValidation()) {
      console.log("Validation failed");
      return;
    } else {
      console.log("Submitting");

      const sendingCreatedRole = async () => {
        try {
          console.log(Array.from(selectedPermissions), "selectedPermissions");
          const response = await sendCreatedRole({
            roleName: roleName,
            permissionsIDs: Array.from(selectedPermissions) // Convert Set to array
          });
          if (response.rStatus !== "success") {
            Swal.fire({
              title: "Error!",
              text: response.rMessage,
              icon: "error",
              confirmButtonText: "Ok"
            });
            return;
          }
          Swal.fire({
            title: "Success!",
            text: "Role created successfully",
            icon: "success",
            confirmButtonText: "Ok"
          });
          window.location.reload();
          setSaveConfirmed(true);
        } catch (error) {
          Swal.fire({
            title: "Error!",
            text: "Something went wrong, please try again later",
            icon: "error",
            confirmButtonText: "Ok"
          });
          return;
        }
      };

      const promises = [sendingCreatedRole()];

      Promise.all(promises)
        .then(() => {
          // All promises completed successfully
          console.log("All promises completed successfully");
        })
        .catch(() => {
          // At least one promise failed
          console.log("At least one promise failed");
        });
    }
  }

  useEffect(() => {
    console.log("selectedPermissions", selectedPermissions);
  }, [selectedPermissions]);

  return (
    <div className="create_role_container">
      <div className="card m-auto col-12 p-3">
        <div className="row mb-3">
          <div className="col-6">
            <label htmlFor="roleName" className="form-label mb-1">
              <strong>Role Name</strong>
            </label>
            <input
              type="text"
              className="form-control"
              id="roleName"
              placeholder="Enter role name"
              value={roleName}
              onChange={handleRoleName}
            />
          </div>
        </div>

        <div className="row pl-2 pr-2">
          <div className="col-md-6 p-0">
            {renderPermissions("Clients", groupedPermissions.Clients)}
            {renderPermissions("Sidebar", groupedPermissions.Sidebar)}
          </div>
          <div className="col-md-6 p-0">
            {renderPermissions("Permissions", groupedPermissions.Permissions)}
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary mx-1"
              onClick={handleCreateRoleView}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleSubmit}
              disabled={selectedPermissions.size === 0 || roleName.length === 0}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRole;
