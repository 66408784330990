import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { SidebarData, SidebarDataStudy, SidebarDataSurvey } from "../types";

// #region type validators
// #region isSidebarData
function isSidebarData(obj: any): obj is SidebarData {
  const validID = typeof obj.clientID === "string";
  const validName = typeof obj.clientName === "string";
  const validIcon = typeof obj.clientPicture === "string";

  const validStudies =
    (obj.clientStudies && obj.clientStudies.length === 0) ||
    (Array.isArray(obj.clientStudies) &&
      obj.clientStudies.every(isSidebarStudy));

  const AllValid = validID && validName && validIcon && validStudies;
  // console.log(AllValid);
  if (!AllValid) {
    console.log(obj);
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("clientID")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for clientID`);
      }
    }
    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Invalid type for name`);
      }
    }
    if (!validIcon) {
      if (!obj.hasOwnProperty("clientPicture")) {
        errors.push("missing clientPicture");
      } else {
        errors.push(`Invalid type for clientPicture`);
      }
    }
    if (!validStudies) {
      if (!obj.hasOwnProperty("clientStudies")) {
        errors.push("missing studies");
      } else {
        // This error is already handled elsewhere
      }
    }

    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    if (errors.length > 0) {
      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `StudyID: ${obj.clientID}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid SidebarData`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }

  return AllValid;
}
// #endregion isSidebarData

// #region isSidebarStudy
function isSidebarStudy(obj: any): obj is SidebarDataStudy {
  // console.log(obj);

  const validID = typeof obj.studyID === "string";
  const validName = typeof obj.studyName === "string";
  const validIcon = typeof obj.studyPicture === "string";
  const validSurveys =
    (Array.isArray(obj.studySurveys) &&
      obj.studySurveys.every(isSidebarSurvey)) ||
    obj.studySurveys === undefined;

  const AllValid = validID && validName && validIcon && validSurveys;

  if (!AllValid) {
    console.log(obj);
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("studyID")) {
        errors.push("missing studyID");
      } else {
        errors.push(`Invalid type for studyID`);
      }
    }
    if (!validName) {
      if (!obj.hasOwnProperty("studyName")) {
        errors.push("missing studyName");
      } else {
        errors.push(`Invalid type for studyName`);
      }
    }
    if (!validIcon) {
      if (!obj.hasOwnProperty("studyPicture")) {
        errors.push("missing studyPicture");
      } else {
        errors.push(`Invalid type for studyPicture`);
      }
    }
    if (!validSurveys) {
      if (!obj.hasOwnProperty("studySurveys")) {
        errors.push("missing studySurveys");
      } else {
      }
    }

    if (errors.length > 0) {
      errors.forEach((error) => {
        // remove first empty element
        if (error !== "") {
          console.log(error);
        }
      });

      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `StudyID: ${obj.studyID}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid SidebarDataStudy`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return AllValid;
}
// #endregion isSidebarStudy

// #region isSidebarSurvey
function isSidebarSurvey(obj: any): obj is SidebarDataSurvey {
  const validID = typeof obj.surveyID === "string";
  const validName = typeof obj.surveyName === "string";
  const validIcon = typeof obj.surveyPicture === "string";
  const validType = typeof obj.surveyType === "string";

  const AllValid = validID && validName && validIcon && validType;
  if (!AllValid) {
    console.log(obj);
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("surveyID")) {
        errors.push("missing surveyID");
      } else {
        errors.push(`Invalid type for surveyID`);
      }
    }
    if (!validName) {
      if (!obj.hasOwnProperty("surveyName")) {
        errors.push("missing surveyName");
      } else {
        errors.push(`Invalid type for surveyName`);
      }
    }
    if (!validIcon) {
      if (!obj.hasOwnProperty("surveyPicture")) {
        errors.push("missing surveyPicture");
      } else {
        errors.push(`Invalid type for surveyPicture`);
      }
    }
    if (!validType) {
      if (!obj.hasOwnProperty("type")) {
        errors.push("missing type");
      } else {
        errors.push(`Invalid type for type`);
      }
    }
    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `SurveyID: ${obj.surveyID}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid SidebarDataSurvey`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return AllValid;
}
// #endregion isSidebarSurvey
// #endregion type validators

// #region fetchSidebarData
export async function fetchSidebarData(): Promise<SidebarData[] | false> {
  const response = await apiFetcher<any>("/getSidebarInformation", "POST", {
    body: {}
  });

  if (response.status === 200 && response.data !== null) {
    // console.log(response.data);
    // Perform type checking
    if (Array.isArray(response.data) && response.data.every(isSidebarData)) {
      return response.data;
    } else {
      console.log("Invalid data received");
      return false;
    }
  }

  if (response.status === 404) {
    Swal.fire({
      title: "Error 404",
      html: "<strong>Please contact support</strong><br/>Sidebar fetch data not found",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }

  if (response.status === 400) {
    Swal.fire({
      title: "Error 400",
      html: "<strong>Please contact support</strong><br/>Server expecting different data for sidebar fetch",
      icon: "error",
      confirmButtonText: "Ok",
      confirmButtonColor: "#3085d6"
    });
    return false;
  }

  Swal.fire({
    title: "Error",
    html: "<strong>Please contact support</strong><br/>Failed to fetch sidebar data",
    icon: "error",
    confirmButtonText: "Ok",
    confirmButtonColor: "#3085d6"
  });
  return false;
}

// #endregion fetchSidebarData
