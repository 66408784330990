import AppVersions from "../../components/Config/MobileApps/AppVersions/appVersions.component";
import SidebarComponent from "../../infrastructure/Sidebar/sidebar.component";
import { SidebarProps } from "../../types";

interface AppVersionPageProps extends SidebarProps {}

const AppVersionPage: React.FC<AppVersionPageProps> = ({
  sidebarPinned,
  onSidebarPinChange
}) => {
  return (
    <div className="main_container">
      <div className="col-sm p-3 min-vh-100">
        <div className="" id="app_version_page">
          <AppVersions />
        </div>
      </div>
      <SidebarComponent
        page="config"
        sidebarPinned={sidebarPinned}
        onSidebarPinChange={onSidebarPinChange}
      />
    </div>
  );
};

export default AppVersionPage;
