import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import * as Icons from "../../assets/images/icons/icons.ts";
import "../../components/Tables/tableComponents.scss"; // shared styles for tables
import {
  fetchAllParticipantsByID,
  fetchAllParticipantsSurveyCompletes
} from "../../models/participant.model";
import ErrorPage from "../../pages/Error/error.page.tsx";
import {
  Country,
  FetchResponseError,
  Language,
  Participant,
  ParticipantBucket,
  SurveyCompletion,
  Tags,
  Timezone
} from "../../types";
import {
  getResearcherConfigTimezone,
  getSystemConfigCountries,
  getSystemConfigLanguages,
  getSystemConfigTimezones
} from "../../utilities/config.util";
import { exportParticipants } from "../../utilities/exportTable.util.ts";
import { isFetchResponseError } from "../../utilities/utils";
import AddLeadsModal from "../AddLeadsModal/addLeadsModal.component.tsx";
import AddParticipantsModal from "../AddParticipantsModal/addParticipantsModal.component.tsx";
import ImportParticipantsModal from "../ImportParticipantsModal/importParticipantsModal.component.tsx";
import BulkActions from "../Tables/BulkActions/bulkActions.tsx";
import FiltersContainer from "../Tables/Filters/filtersContainer.component.tsx";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation.tsx";
import { ParticipantsTable } from "../Tables/TableParticipants/participantsTable.component.tsx";
import TableRowsSelector from "../Tables/TableRowsSelector/tableRowsSelector.tsx";
import TimezoneSwitcher from "../Tables/TimeZoneSwitcher/timezoneSwitcher.tsx";
import ViewParticipantModal from "../ViewParticipantModal/viewParticipantModal.tsx";
// import "./participants.scss";

import Swal from "sweetalert2";
import { usePermissions } from "../../contexts/UserContext.tsx";

interface DisplayParticipantsProps {
  tableType:
    | "ClientParticipants"
    | "StudyParticipants"
    | "SurveyParticipants"
    | "DiaryParticipants";
  tableDisplayName: string;
  clientID: string;
  studyID?: string;
  surveyID?: string;
}

const DisplayParticipants: React.FC<DisplayParticipantsProps> = ({
  tableType,
  tableDisplayName
  // clientID,
  // surveyID,
  // studyID,
}) => {
  const { clientID, surveyID, studyID } = useParams(); // Get from URL
  const [currentPage, setCurrentPage] = useState(1);
  // const participantsPerPage = 20;
  const [participantsPerPage, setParticipantsPerPage] = useState(20);

  const [participantsTagsFilter, setParticipantsTagsFilter] = useState<
    string[]
  >([]);
  const [participantsFlagFilter, setParticipantsFlagFilter] = useState<
    string[]
  >([]);

  const [participantsBucketsFilter, setParticipantsBucketsFilter] = useState<
    string[]
  >([]);
  const [surveyCompletionData, setSurveyCompletionData] = useState<
    SurveyCompletion[] | null | "error"
  >(null);

  // tableType reference
  const tableTypeRef = useRef(tableType);

  useEffect(() => {
    tableTypeRef.current = tableType;
  }, [tableType]);

  const [
    participantsBucketComplianceStatusFilter,
    setParticipantsBucketComplianceStatusFilter
  ] = useState<string[]>([]);

  const [participantsLanguagesFilter, setParticipantsLanguagesFilter] =
    useState<string[]>([]);

  const [participantsCountryFilter, setParticipantsCountryFilter] = useState<
    string[]
  >([]);

  const [participantsStatusFilter, setParticipantsStatusFilter] = useState<
    string[]
  >([]);

  const [participantsUDIDFilter, setParticipantsUDIDFilter] =
    useState<boolean>(false);

  const [participantsSearch, setParticipantsSearch] = useState<string>("");

  // State variable to hold the original participants
  const [originalParticipants, setOriginalParticipants] = useState<
    Participant[]
  >([]);
  // State variable to hold the filtered participants
  const [filteredParticipants, setFilteredParticipants] = useState<
    Participant[]
  >([]);
  const [loadingParticipants, setLoadingParticipants] = useState<boolean>(true);

  const [languages, setLanguages] = useState<Language[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);
  const [loadingTimezones, setLoadingTimezones] = useState<boolean>(true);
  const [datefetchedDate, setDatefetchedDate] = useState<Date | null>(null);
  const [displayedDataDateOffet, setDisplayedDataDateOffet] =
    useState<string>("");
  const [refreshButtonEnabled, setRefreshButtonEnabled] =
    useState<boolean>(false);
  const [errorFetchingData, setErrorFetchingData] =
    useState<FetchResponseError | null>(null);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  // State to track if a user has been added, for fetching latest data
  const [userAdded, setUserAdded] = useState<boolean>(false);

  // bulk actions
  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );

  // const [currentParticipants, setCurrentParticipants] = useState<Participant[]>(
  //   []
  // );

  // const timezones = JSON.parse(localStorage.getItem("timezones") || "[]");
  const [researcherTimezone, setResearcherTimezone] = useState<Timezone | null>(
    null
  );

  const [timezones, setTimezones] = useState<Timezone[]>([]);

  // User can switch between UTC, User timezone, and participant's timezone
  const [timezone, setTimezone] = useState<string>("ResearcherTimezone");

  // User can view participant details
  const [showModal, setShowModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState("");

  const [showImportModal, setShowImportModal] = useState(false);

  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);

  const [activeParticipantId, setActiveParticipantId] = useState<string | null>(
    null
  );

  // store current state of filters
  const [filterApplied, setFilterApplied] = useState<boolean>(false);

  const [participantsStatuses, setParticipantsStatuses] = useState<string[]>(
    []
  );
  const [participantsLanguages, setParticipantsLanguages] = useState<
    Language[]
  >([]);
  const [participantsCountries, setParticipantsCountries] = useState<Country[]>(
    []
  );

  const [participantsTags, setParticipantsTags] = useState<Tags[]>([]);
  const [participantsBuckets, setParticipantsBuckets] = useState<
    Participant["participant_buckets"]
  >([]);
  const [participantsBucketCompliance, setParticipantsBucketCompliance] =
    useState<any[]>([]);
  const [bucketsSelected, setBucketsSelected] = useState<boolean>(false);

  const [searchedMultiParticipantsIDs, setSearchedMultiParticipantsIDs] =
    useState<string[]>([]);

  const { hasPermission } = usePermissions();

  const [sortOrder, setSortOrder] = useState<"asc" | "desc" | null>(null);

  useEffect(() => {
    // useEffect to sort the participants by date
    console.log("#########sortOrder: ", sortOrder);
    let sortedParticipants: Participant[] = filteredParticipants;

    const dateField: "study_joined_date" | "survey_joined_date" =
      tableType === "StudyParticipants"
        ? "study_joined_date"
        : "survey_joined_date";

    console.log("dateField: ", dateField);

    if (sortOrder === "asc") {
      console.log("Sort by date ascending");
      setFilteredParticipants(
        filteredParticipants.sort((a: Participant, b: Participant) => {
          const valA = a[dateField];
          const valB = b[dateField];
          if (valA && valB) {
            return valA < valB ? -1 : valA > valB ? 1 : 0;
          }

          return 0; // Default value added here
        })
      );
    } else if (sortOrder === "desc") {
      console.log("Sort by date Descending");
      setFilteredParticipants(
        filteredParticipants.sort((a: Participant, b: Participant) => {
          const valA = a[dateField];
          const valB = b[dateField];
          if (valA && valB) {
            return valA < valB ? 1 : valA > valB ? -1 : 0;
          }
          return 0; // Default value added here
        })
      );
    }

    setFilteredParticipants(sortedParticipants);
  }, [sortOrder]);

  // clear compliance status if no buckets have been selected
  useEffect(() => {
    if (participantsBucketsFilter.length === 0) {
      setParticipantsBucketComplianceStatusFilter([]);
    }
  }, [participantsBucketsFilter]);

  const handleChangeSortOrder = (sortOrder: string) => {
    setSortOrder(sortOrder as "asc" | "desc" | null);
  };

  const fetchData = useCallback(
    async (isRealData: boolean = false) => {
      // Fetch participants data from database
      setLoadingParticipants(true);
      setSurveyCompletionData(null);
      // clear values
      setSelectedParticipant("");
      setSelectedParticipants([]);
      setFilterApplied(false);
      handleClearFilters();
      // setOriginalParticipants([]);
      // setFilteredParticipants([]);

      try {
        const jsonData = await fetchAllParticipantsByID(
          tableTypeRef.current,
          clientID || "",
          isRealData,
          tableTypeRef.current === "StudyParticipants" ||
            tableTypeRef.current === "SurveyParticipants" ||
            tableTypeRef.current === "DiaryParticipants"
            ? studyID
            : "",
          surveyID || "",
          false
        );

        if (isFetchResponseError(jsonData)) {
          console.log(
            isRealData ? "error Status: " : "error Code: " + jsonData.errorCode
          );
          console.log("error Message: " + jsonData.errorMessage);
          setErrorFetchingData(jsonData);
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            isRealData
              ? "067f178a46aa376bf83069d315b626a3"
              : "4813616a8c447c2d9b694607a0649e70"
          ]);
        } else {
          setErrorFetchingData(null);
          setDatefetchedDate(new Date(jsonData.utcLastUpdate));
          setOriginalParticipants(jsonData.participants);
          setFilteredParticipants(jsonData.participants);
          setLoadingParticipants(false);
          console.log(tableTypeRef.current);
          if (
            (tableTypeRef.current === "SurveyParticipants" ||
              tableTypeRef.current === "DiaryParticipants") &&
            surveyID
          ) {
            fetchSurveyCompletionData();
          }
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participants data:",
          error
        );
        setErrorFetchingData({
          errorCode: "500",
          errorMessage: "Error fetching data"
        });
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          isRealData
            ? "54b60f7a5dad6daf1510357ec608a0fb"
            : "20dd3c192b851920d1a40f196fed5cde"
        ]);
      }

      setLoadingParticipants(false);
    },
    [
      // Add dependencies here
      // tableType,
      clientID,
      studyID,
      surveyID
      // fetchSurveyCompletionData,
      // Include any other props or state values your function depends on
      // For example, if you use a context or a prop inside fetchData, include it here
    ]
  );

  // export async function fetchAllParticipantsSurveyCompletes(
  //   cleintID: string,
  //   surveyID: string
  // ): Promise<
  //   | { rStatus: "success"; rData: SurveyCompletion[] }
  //   | { rStatus: "error"; rData: string }
  // > {

  const fetchSurveyCompletionData = async () => {
    // fetchAllParticipantsSurveyCompletes

    if (!clientID || !surveyID) {
      return;
    }

    try {
      const jsonData = await fetchAllParticipantsSurveyCompletes(
        clientID,
        surveyID
      );
      if (jsonData.rStatus === "error") {
        console.log("Error fetching survey completion data: ", jsonData.rData);
        Swal.fire({
          icon: "error",
          title: "Error fetching survey completion data",
          text: jsonData.rData
        });
        return;
      }

      console.log("Survey completion data: ", jsonData.rData);
      setSurveyCompletionData(jsonData.rData);
    } catch (error) {
      setSurveyCompletionData("error");
    }
  };

  useEffect(() => {
    // initial fetch of data
    setTimeout(() => {
      // delay fetch so that the tableType is set first
      // TODO: Need a better way to handle this
      fetchData();
    }, 100);
  }, [clientID, studyID, surveyID, fetchData]);

  useEffect(() => {
    // get langauges from local storage using getConfigLanguages
    const fetchAllConfigLanguages = async () => {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllConfigCountries = async () => {
      try {
        const jsonData: Country[] | false = await getSystemConfigCountries();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setCountries(jsonData);
          } catch {
            console.error("Error setting countries");
            throw new Error("Error setting countries");
          }

          // setCountries([{ iso_code: "en", name: "English" }]);
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const fetchAllConfigTimezones = async () => {
      try {
        const jsonData: Timezone[] | false = await getSystemConfigTimezones();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "8425c694e2d2642b9940a2cda9e5dad7"
          ]);
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setTimezones(jsonData);
          } catch {
            console.error("Error setting timezones");
            throw new Error("Error setting timezones");
          }
        }
      } catch {
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "8425c694e2d2642b9940a2cda9e5dad7"
        ]);
      }
    };

    const promises = [
      fetchAllConfigLanguages(),
      fetchAllConfigCountries(),
      fetchAllConfigTimezones()
    ];

    Promise.all(promises)
      .then(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      })
      .catch(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
        setLoadingTimezones(false);
      });
  }, []);

  // Update the filtered participants list
  useEffect(() => {
    if (errorFetchingData || !originalParticipants) {
      return;
    }
    // Step 1: Apply all filters except UID filter
    let preliminaryFiltered = originalParticipants.filter(
      (participant: Participant) => {
        // Apply search filter if participantsSearch is not empty
        if (
          participantsSearch !== "" &&
          !(
            participant.participant_name
              ?.toLowerCase()
              .includes(participantsSearch.toLowerCase()) ||
            participant.participant_surname
              ?.toLowerCase()
              .includes(participantsSearch.toLowerCase()) ||
            participant.participant_email
              ?.toLowerCase()
              .includes(participantsSearch.toLowerCase()) ||
            // Check name + surname
            (
              participant.participant_name +
              " " +
              participant.participant_surname
            )
              .toLowerCase()
              .includes(participantsSearch.toLowerCase()) ||
            // User ID
            participant.id
              .toString()
              .includes(participantsSearch.toLowerCase()) ||
            // External ID
            participant.external_id.includes(participantsSearch.toLowerCase())
          )
        ) {
          return false;
        }

        // Convert filters to sets for faster lookup
        const tagsFilterSet = new Set(participantsTagsFilter);
        const flagFilterSet = new Set(participantsFlagFilter);
        const bucketsFilterSet = new Set(participantsBucketsFilter);
        const complianceStatusFilterSet = new Set(
          participantsBucketComplianceStatusFilter
        );
        const languagesFilterSet = new Set(participantsLanguagesFilter);
        const countryFilterSet = new Set(participantsCountryFilter);
        const statusFilterSet = new Set(participantsStatusFilter);

        // Apply tags filter if participantsTagsFilter is not empty
        if (
          tagsFilterSet.size > 0 &&
          participant.participant_tags !== undefined &&
          !participant.participant_tags.some((tag) => tagsFilterSet.has(tag))
        ) {
          return false;
        }

        // Apply flagged filter if participantsFlagFilter is not empty
        if (
          flagFilterSet.size > 0 &&
          participant.participant_flagged !== undefined
        ) {
          if (
            !flagFilterSet.has("flagged") &&
            participant.participant_flagged
          ) {
            return false;
          }
          if (
            !flagFilterSet.has("unflagged") &&
            !participant.participant_flagged
          ) {
            return false;
          }
        }
        // Apply Buckets Filter
        if (bucketsFilterSet.size > 0) {
          const anyBucketMatch = Array.from(bucketsFilterSet).some((bucket) => {
            return participant.participant_buckets?.some(
              (participantBucket) => participantBucket.name === bucket
            );
          });

          if (!anyBucketMatch) {
            return false; // No matching buckets
          }
        }

        if (
          participant.participant_buckets &&
          participant.participant_buckets.length > 0 &&
          complianceStatusFilterSet.size > 0
        ) {
          // MUST CHECK ONLY THE FILTERED BUCKS NOT ALL
          const filteredBuckets = participant.participant_buckets.filter(
            (bucket) => bucketsFilterSet.has(bucket.name)
          );

          const complianceMatch = filteredBuckets.some((bucket) => {
            if (
              bucket.name !== "non compliant" &&
              bucket.name !== "Compliant" &&
              bucket.name !== "completes"
            ) {
              if (
                complianceStatusFilterSet.has("non-compliant") &&
                bucket.name !== "Compliant" &&
                !complianceStatusFilterSet.has(bucket.compliance_status)
              ) {
                return false;
              }
              if (
                complianceStatusFilterSet.has("compliant") &&
                !complianceStatusFilterSet.has(bucket.compliance_status)
              ) {
                return false;
              }
            }
            return true;
          });

          if (!complianceMatch) {
            return false;
          }
        }

        // Apply languages filter if participantsLanguagesFilter is not empty
        if (
          languagesFilterSet.size > 0 &&
          participant.participant_lang_iso &&
          !languagesFilterSet.has(participant.participant_lang_iso)
        ) {
          return false;
        }

        // Apply country filter if participantsCountryFilter is not empty
        if (
          countryFilterSet.size > 0 &&
          participant.participant_country_iso &&
          !countryFilterSet.has(participant.participant_country_iso)
        ) {
          return false;
        }

        // Apply status filter if participantsStatusFilter is not empty
        if (
          tableTypeRef.current === "ClientParticipants" &&
          statusFilterSet.size > 0 &&
          participant.participant_status &&
          !statusFilterSet.has(participant.participant_status)
        ) {
          return false;
        }

        if (
          tableTypeRef.current === "StudyParticipants" &&
          statusFilterSet.size > 0 &&
          participant.study_status &&
          !statusFilterSet.has(participant.study_status)
        ) {
          return false;
        }

        if (
          (tableTypeRef.current === "SurveyParticipants" ||
            tableTypeRef.current === "DiaryParticipants") &&
          statusFilterSet.size > 0 &&
          participant.survey_status &&
          !statusFilterSet.has(participant.survey_status)
        ) {
          return false;
        }
        return true;
      }
    );
    // searchedMultiParticipantsIDs, filter by participantIDs
    if (
      searchedMultiParticipantsIDs.length > 0
      // && tableTypeRef.current !== "StudyParticipants"
    ) {
      preliminaryFiltered = preliminaryFiltered.filter((participant) => {
        // check id or external id
        if (
          searchedMultiParticipantsIDs.includes(participant.id.toString()) ||
          searchedMultiParticipantsIDs.includes(participant.external_id)
        ) {
          return true;
        }
        return false;
      });
    }

    // Step 2: If participantsUDIDFilter is true, identify duplicates only among preliminary filtered participants
    const duplicateUIDs = new Set();
    const seenUIDs = new Set();

    if (participantsUDIDFilter) {
      preliminaryFiltered.forEach((p) => {
        if (seenUIDs.has(p.participant_uid)) {
          duplicateUIDs.add(p.participant_uid);
        }
        seenUIDs.add(p.participant_uid);
      });
    }

    // Step 3: Apply UID filter on preliminaryFiltered if necessary
    const result = preliminaryFiltered.filter((participant: Participant) => {
      if (
        participantsUDIDFilter &&
        !duplicateUIDs.has(participant.participant_uid)
      ) {
        return false;
      }
      return true;
    });

    // Sort by udid if participantsUDIDFilter is true
    if (participantsUDIDFilter) {
      result.sort((a, b) => {
        if (a.participant_uid && b.participant_uid) {
          if (a.participant_uid < b.participant_uid) {
            return -1;
          }
          if (a.participant_uid > b.participant_uid) {
            return 1;
          }
          return 0;
        }
        return 0; // Default value added here
      });
    }

    if (participantsBucketsFilter.length > 0) {
      setBucketsSelected(true);
    } else {
      setBucketsSelected(false);
    }

    setFilteredParticipants(result);
    setCurrentPage(1);
  }, [
    originalParticipants,
    participantsSearch,
    participantsTagsFilter,
    participantsFlagFilter,
    participantsBucketsFilter,
    participantsBucketComplianceStatusFilter,
    participantsStatusFilter,
    participantsUDIDFilter,
    participantsLanguagesFilter,
    participantsCountryFilter,
    searchedMultiParticipantsIDs,
    errorFetchingData
  ]);

  // useEffect(() => {
  //   //console.log(currentParticipants, filteredParticipants);
  //   if (!errorFetchingData && filteredParticipants) {
  //     setCurrentParticipants(
  //       filteredParticipants.slice(
  //         (currentPage - 1) * participantsPerPage,
  //         currentPage * participantsPerPage
  //       )
  //     );
  //   }
  // }, [
  //   currentPage,
  //   participantsPerPage,
  //   filteredParticipants,
  //   errorFetchingData,
  // ]);

  const totalParticipants = !errorFetchingData
    ? filteredParticipants?.length ?? 0
    : 0;

  useEffect(() => {
    getResearcherConfigTimezone().then((result) => {
      // console.log("result", result);
      if (result) {
        setResearcherTimezone(result);
      } else {
        setResearcherTimezone(null);
      }
    });
  }, []);

  const handleChangeTimezone = (timezone: string) => {
    setTimezone(timezone);
  };

  const handleViewParticipant = (participantID: string) => {
    setSelectedParticipant(participantID);
    // console.log("View participant: " + participantID);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    // delay of 0.5 seconds
    setTimeout(() => {
      setSelectedParticipant("");
    }, 500);
  };

  const handleCloseImportModal = () => {
    setShowImportModal(false);
    // delay of 0.5 seconds
  };

  const handleCloseAddParticipantModal = () => {
    setShowAddParticipantModal(false);
    // delay of 0.5 seconds
  };

  const handleChangeRows = (rows: number) => {
    setParticipantsPerPage(rows);
  };

  const handleParticipantActionsDropdown = (participantId: string | null) => {
    if (participantId === "close") {
      // reload the page
      // window.location.reload();
      setActiveParticipantId(null);
      // refresh data
      fetchData(true);
    } else {
      if (participantId === activeParticipantId) {
        setActiveParticipantId(null);
      } else {
        setActiveParticipantId(participantId);
      }
    }
  };

  useEffect(() => {
    console.log(sortOrder);
    if (
      participantsSearch !== "" ||
      participantsTagsFilter.length > 0 ||
      participantsFlagFilter.length > 0 ||
      participantsBucketsFilter.length > 0 ||
      participantsBucketComplianceStatusFilter.length > 0 ||
      participantsStatusFilter.length > 0 ||
      participantsUDIDFilter ||
      participantsLanguagesFilter.length > 0 ||
      participantsCountryFilter.length > 0 ||
      searchedMultiParticipantsIDs.length > 0 ||
      sortOrder !== null
    ) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [
    participantsSearch,
    participantsTagsFilter,
    participantsFlagFilter,
    participantsBucketsFilter,
    participantsBucketComplianceStatusFilter,
    participantsStatusFilter,
    participantsUDIDFilter,
    participantsLanguagesFilter,
    participantsCountryFilter,
    searchedMultiParticipantsIDs,
    sortOrder
  ]);

  const handleClearFilters = () => {
    // Clear the filter state
    setParticipantsSearch("");
    setParticipantsTagsFilter([]);
    setParticipantsFlagFilter([]);
    setParticipantsBucketsFilter([]);
    setParticipantsBucketComplianceStatusFilter([]);
    setParticipantsStatusFilter([]);
    setParticipantsUDIDFilter(false);
    setParticipantsLanguagesFilter([]);
    setParticipantsCountryFilter([]);
    setSearchedMultiParticipantsIDs([]);
    setSortOrder(null);
  };

  const handleSelectParticipant = (participantId: string) => {
    // add participantId to selectedParticipants, if already present, remove it
    if (selectedParticipants.includes(participantId)) {
      setSelectedParticipants(
        selectedParticipants.filter((id) => id !== participantId)
      );
    } else {
      setSelectedParticipants([...selectedParticipants, participantId]);
    }
  };

  const handleSelectAllParticipants = () => {
    // if all participants are already selected, deselect all
    if (selectedParticipants.length === (filteredParticipants?.length ?? 0)) {
      setSelectedParticipants([]);
    } else {
      setSelectedParticipants(
        filteredParticipants.map((p: Participant) => p.id.toString())
      );
    }
  };

  // clock to display the offset of the displayed data, updates every second
  useEffect(() => {
    // console.log("datefetchedDate: ", datefetchedDate.getTime());
    const interval = setInterval(() => {
      if (
        datefetchedDate === null ||
        datefetchedDate === undefined ||
        isNaN(datefetchedDate.getTime())
      ) {
        setDisplayedDataDateOffet("Date not provided");
      } else {
        const currentLocalDate = new Date();
        const currentUTCDate = new Date(
          currentLocalDate.getTime() +
            currentLocalDate.getTimezoneOffset() * 60000
        );
        const offset = new Date(
          currentUTCDate.getTime() - datefetchedDate.getTime()
        );
        // if offset is less than 24 hours display, else display error
        const minute = 60000;
        const hour = minute * 60;
        const maxtime = 24 * hour;
        if (offset.getTime() < 0) {
          setDisplayedDataDateOffet("Error Data is in the future");
        } else if (offset.getTime() < maxtime) {
          const offsetString = offset.toISOString().substring(11, 19);
          setDisplayedDataDateOffet(offsetString);
        } else {
          setDisplayedDataDateOffet("Error Data is over 24 hours old");
        }
        // if less than 30 seconds disable refresh button
        const disableTime = 10 * 1000;
        if (offset.getTime() < disableTime) {
          setRefreshButtonEnabled(false);
        } else {
          setRefreshButtonEnabled(true);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [datefetchedDate]);

  const handleRefreshData = () => {
    // does a fetch of the data again
    // console.log("handleRefreshData");
    setRefreshButtonEnabled(false);
    setDisplayedDataDateOffet("00:00:00");
    // Force pull real data
    fetchData(true);
  };

  // After closing add participants modal, fetch real data
  useEffect(() => {
    // console.log(showAddParticipantModal);
    // console.log(userAdded);
    if (!showAddParticipantModal && userAdded) {
      const fetchDataInUse = async () => {
        if (!showAddParticipantModal && userAdded) {
          // console.log("REDNDEDE");
          await fetchData();
        }
      };

      fetchDataInUse();
    }
  }, [showAddParticipantModal, userAdded]);

  useEffect(() => {
    // get all unique participants statuses
    // const statuses = [
    //   ...new Set(
    //     originalParticipants
    //       .map((p) => p.participant_status)
    //       .filter((status): status is string => Boolean(status))
    //   ),
    // ].sort();

    // setParticipantsStatuses(statuses);

    // change statuses based on tableType
    if (tableTypeRef.current === "ClientParticipants") {
      setParticipantsStatuses(["active", "blocked"]);
      // NOTE -> status 'inactive' has been removed
    } else if (tableTypeRef.current === "StudyParticipants") {
      // NOTE -> some of the statuses have been removed
      setParticipantsStatuses([
        // "active",
        // "inactive",
        // "pending",
        "in progress",
        // "completed",
        "blocked"
      ]);
    } else if (
      tableTypeRef.current === "SurveyParticipants" ||
      tableTypeRef.current === "DiaryParticipants"
    ) {
      // NOTE -> some of the statuses have been removed
      setParticipantsStatuses([
        // "active",
        // "inactive",
        // "pending",
        "in progress",
        // "completed",
        "blocked"
      ]);
    }

    // get all unique participants Tags
    const pTags: string[] = [
      ...new Set(
        originalParticipants
          .map((p) => p.participant_tags)
          .filter((tags): tags is string[] => Boolean(tags))
          .flat()
      )
    ].sort();
    setParticipantsTags(
      pTags.map((tag) => {
        return { id: tag, name: tag };
      })
    );

    // // get all unique participants buckets
    // const pBucketNames: string[] = [
    //   ...new Set(
    //     originalParticipants.flatMap((p) =>
    //       p.participant_buckets
    //         ? p.participant_buckets.map((bucket) => bucket.name)
    //         : []
    //     )
    //   )
    // ].sort();

    const pBuckets: ParticipantBucket[] = originalParticipants.flatMap(
      (p) => p.participant_buckets ?? []
    );

    // Explicitly type the accumulator in the reduce function to match the participant_buckets array
    const uniqueBuckets: ParticipantBucket[] = pBuckets.reduce(
      (
        acc: Participant["participant_buckets"],
        bucket: ParticipantBucket
      ): ParticipantBucket[] => {
        if (!acc) {
          return [bucket];
        }
        const existingBucket = acc.find((b) => b.name === bucket.name);
        if (!existingBucket) {
          return [...acc, bucket];
        }
        return acc;
      },
      []
    );
    setParticipantsBuckets(uniqueBuckets);

    if (tableTypeRef.current === "StudyParticipants") {
      setParticipantsBucketCompliance(["compliant", "non-compliant"]);
    }
  }, [originalParticipants]);

  useEffect(() => {
    // get all unique participants languages
    const pLanguages = [
      ...new Set(
        originalParticipants
          .map((p) => p.participant_lang_iso)
          .filter((lang): lang is string => Boolean(lang))
      )
    ].sort();
    setParticipantsLanguages(
      pLanguages.map((lang) => {
        const pLanguage = languages.find((l: Language) => l.iso_code === lang);
        if (pLanguage) {
          return pLanguage;
        }
        return { iso_code: lang, name: lang };
      })
    );
  }, [originalParticipants, languages]);

  useEffect(() => {
    // get all unique participants countries
    const pCountries = [
      ...new Set(
        originalParticipants
          .map((p) => p.participant_country_iso)
          .filter((country): country is string => Boolean(country))
      )
    ].sort();
    setParticipantsCountries(
      pCountries.map((country) => {
        const pCountry = countries.find((c: Country) => c.iso_code === country);
        if (pCountry) {
          return pCountry;
        }
        return { iso_code: country, name: country, country_code: country };
      })
    );
  }, [originalParticipants, countries]);

  function handleExportParticipants() {
    console.log("handleExportParticipants");

    if (surveyCompletionData && surveyCompletionData !== "error") {
      exportParticipants(
        filteredParticipants,
        tableTypeRef.current,
        tableDisplayName,
        timezones,
        timezone,
        researcherTimezone,
        surveyCompletionData
      );
    } else {
      exportParticipants(
        filteredParticipants,
        tableTypeRef.current,
        tableDisplayName,
        timezones,
        timezone,
        researcherTimezone
      );
    }
  }

  return (
    <div>
      {/* loading */}
      {!loadingLanguages &&
      !loadingCountries &&
      !loadingTimezones &&
      !loadingParticipants &&
      !errorFetchingData?.errorCode &&
      clientID ? (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="participants_page" className="container-fluid">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <h1 className="table_name">
                      {tableType === "ClientParticipants"
                        ? tableDisplayName + " Participants"
                        : "Participants"}
                    </h1>
                  </div>
                  <div className="col-12 col-md-12 col-lg-6 d-flex justify-content-start justify-content-md-end">
                    <div className="participants_page_heading_right p-0">
                      <h5>{totalParticipants} Participants</h5>
                      {(hasPermission("subject") ||
                        hasPermission("participant_all") ||
                        hasPermission("participant", "write")) && (
                        <>
                          {totalParticipants > 0 &&
                            (tableTypeRef.current !== "SurveyParticipants" &&
                            tableTypeRef.current !== "DiaryParticipants" ? (
                              <button
                                className="btn btn-primary me-2"
                                onClick={() => handleExportParticipants()}
                              >
                                <Icons.IconUpload
                                  className="icon icon_white"
                                  style={{
                                    transform: "rotate(180deg)"
                                  }}
                                />
                                Export
                              </button>
                            ) : surveyCompletionData ? (
                              <button
                                className="btn btn-primary me-2"
                                onClick={() => handleExportParticipants()}
                              >
                                <Icons.IconUpload
                                  className="icon icon_white"
                                  style={{
                                    transform: "rotate(180deg)"
                                  }}
                                />
                                Export
                              </button>
                            ) : (
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                              ></span>
                            ))}

                          {(tableTypeRef.current === "ClientParticipants" ||
                            tableTypeRef.current === "StudyParticipants") && (
                            <button
                              className="btn btn-primary"
                              onClick={() => setShowImportModal(true)}
                            >
                              <Icons.IconUpload className="icon icon_white" />
                              Import
                            </button>
                          )}
                          <button
                            className="btn btn-primary ms-2"
                            onClick={() => setShowAddParticipantModal(true)}
                          >
                            <Icons.IconAdd className="icon icon_white icon_plus" />
                            Add Participants
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col d-flex justify-content-end participants_page_header_second">
                    {/* timezone buttons */}
                    <div title="Change the timezone to view participant data">
                      <TimezoneSwitcher
                        timezone={timezone}
                        handleChangeTimezone={handleChangeTimezone}
                        researcherTimezone={
                          researcherTimezone ? researcherTimezone : null
                        }
                        tableType={tableTypeRef.current}
                      />
                    </div>
                    {/* select rows buttons */}
                    <div title="Select the number of rows to display">
                      <TableRowsSelector
                        handleChangeRows={handleChangeRows}
                        rowsPerPage={participantsPerPage}
                        tableName="Participants"
                        arrayRowSelector={[10, 20, 50, 100]}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  {/* FiltersContainer */}
                  <FiltersContainer
                    key="FiltersContainer"
                    tableType={tableTypeRef.current}
                    filterApplied={filterApplied}
                    setFilterApplied={setFilterApplied}
                    handleClearFilters={handleClearFilters}
                    jsonTagsData={participantsTags}
                    buckets={participantsBuckets}
                    bucketCompliance={participantsBucketCompliance}
                    bucketsSelected={bucketsSelected}
                    languages={participantsLanguages}
                    countries={participantsCountries}
                    statuses={participantsStatuses}
                    // searchedParticipantsIDs
                    participantIDs={searchedMultiParticipantsIDs}
                    setSearch={setParticipantsSearch}
                    setTagsFilter={setParticipantsTagsFilter}
                    setFlagFilter={setParticipantsFlagFilter}
                    setBucketsFilter={setParticipantsBucketsFilter}
                    setBucketsComplianceFilter={
                      setParticipantsBucketComplianceStatusFilter
                    }
                    setStatusFilter={setParticipantsStatusFilter}
                    setLanguagesFilter={setParticipantsLanguagesFilter}
                    setCountryFilter={setParticipantsCountryFilter}
                    setUDIDFilter={setParticipantsUDIDFilter}
                    setParticipantIDs={setSearchedMultiParticipantsIDs}
                    setSortOrderByDate={handleChangeSortOrder}
                  />
                  <div className="col-auto">
                    {/* show if there are selected Participants */}
                    {(hasPermission("subject") ||
                      hasPermission("participant", "all") ||
                      hasPermission("participant", "write") ||
                      hasPermission("participant", "delete")) &&
                      selectedParticipants &&
                      selectedParticipants.length > 0 && (
                        <BulkActions
                          selectedParticipants={selectedParticipants}
                          tableType={tableTypeRef.current}
                          allSelected={
                            selectedParticipants.length === totalParticipants
                          }
                        />
                      )}
                  </div>
                </div>
                {!originalParticipants || originalParticipants.length === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="no_results_container">
                        <h3>No participants found</h3>
                      </div>
                    </div>
                  </div>
                ) : !filteredParticipants ||
                  (filteredParticipants &&
                    filteredParticipants.length === 0) ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="no_results_container">
                        <h3>No results found</h3>
                        <p>Try adjusting your search or filters</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <ParticipantsTable
                    tableType={tableTypeRef.current}
                    currentParticipants={filteredParticipants.slice(
                      (currentPage - 1) * participantsPerPage,
                      currentPage * participantsPerPage
                    )}
                    selectedParticipants={selectedParticipants}
                    filteredParticipants={filteredParticipants}
                    participantID={selectedParticipant}
                    allTimezones={timezones}
                    timezone={timezone}
                    countries={participantsCountries}
                    languages={participantsLanguages}
                    activeParticipantId={activeParticipantId}
                    researcherTimezone={
                      researcherTimezone ? researcherTimezone : null
                    }
                    complianceStatus={participantsBucketComplianceStatusFilter}
                    handleSelectParticipant={handleSelectParticipant}
                    handleSelectAllParticipants={handleSelectAllParticipants}
                    handleViewParticipant={handleViewParticipant}
                    handleParticipantActionsDropdown={
                      handleParticipantActionsDropdown
                    }
                    surveyID={surveyID}
                  />
                )}
                <div className="row">
                  <div className="col-auto">
                    <PaginationNavigation
                      currentPage={currentPage}
                      totalResults={
                        filteredParticipants ? filteredParticipants.length : 0
                      }
                      resultsPerPage={participantsPerPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                  <div className="col-auto ms-auto">
                    {displayedDataDateOffet}
                    <button
                      id="refresh_button"
                      className="btn btn-primary ms-2"
                      onClick={() => handleRefreshData()}
                      disabled={!refreshButtonEnabled}
                    >
                      Refresh
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="col-sm p-0">
          <div className="container-fluid">
            <div className="row">
              <div id="participants_page" className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="error_container">
                      {!clientID && <p>Client </p>}
                      {loadingLanguages ||
                      loadingCountries ||
                      loadingTimezones ||
                      loadingParticipants ? (
                        <div className="d-flex justify-content-center custom_spinner_container full_page">
                          <div className="spinner-border" role="status"></div>
                        </div>
                      ) : !clientID ? (
                        <ErrorPage
                          errorCode="404"
                          error="Client not found"
                          debugCode={loadingErrorText}
                        />
                      ) : (
                        <ErrorPage
                          errorCode={"500"}
                          error={
                            errorFetchingData
                              ? errorFetchingData.errorMessage
                              : "Error fetching data"
                          }
                          debugCode={loadingErrorText}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {clientID && selectedParticipant !== "" && (
        <ViewParticipantModal
          participantID={selectedParticipant}
          closeParticipantModal={() => handleCloseModal()}
          shown={showModal}
          jsonLanguagesData={languages}
          jsonCountriesData={countries}
          jsonTimezonesData={timezones}
          tableType={tableTypeRef.current}
          clientID={clientID}
          surveyID={surveyID}
          studyID={studyID}
        />
      )}

      {showImportModal && (
        <ImportParticipantsModal
          closeModal={() => handleCloseImportModal()}
          shown={showImportModal}
          tableType={tableTypeRef.current}
        />
      )}

      {showAddParticipantModal &&
        (tableType === "ClientParticipants" ? (
          <AddLeadsModal
            closeModal={() => handleCloseAddParticipantModal()}
            shown={showAddParticipantModal}
            languages={languages}
            countries={countries}
            setUserAdded={setUserAdded}
          />
        ) : (
          <AddParticipantsModal
            closeModal={() => handleCloseAddParticipantModal()}
            shown={showAddParticipantModal}
            tableType={tableTypeRef.current}
            languages={languages}
            countries={countries}
            setUserAdded={setUserAdded}
          />
        ))}
    </div>
  );
};

export default DisplayParticipants;
