// Form to add survey to the database

import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  fetchAllExistingStudyNotifications,
  fetchAllWSCSurveys
} from "../../../models/study.model.ts";
import {
  fetchAllSurveys,
  sendSurveyData
} from "../../../models/survey.model.ts";
import ErrorPage from "../../../pages/Error/error.page.tsx";
import {
  ExistingStudyNotification,
  ExistingStudyNotifications,
  FieldDropdown
} from "../../../types.ts";
import { resizeImage } from "../../../utilities/utils.ts";
import StepAddSurveyInformation from "./Steps/stepAddSurveyInformation.component.tsx";
import StepConfiguration from "./Steps/stepSurveyConfiguration.component.tsx";
import StepNotifications from "./Steps/stepSurveyNotifications.component.tsx";
import "./addSurveyWizard.scss";
// kola image
import { IconLogout } from "../../../assets/images/icons/icons.ts";
import kolaImage from "../../../assets/images/kola_wizard.webp";

// This document will include all the fields required for the Wizard that will create a new survey

// Page 1: Survey Information

export type AddSurveyWizardProps = {
  currentPage: number;
  totalPages: number;
  handleWizardBack: () => void;
  handleWizardForward: () => void;
  handleWizardSubmit: () => void;
  handleInputChange: (inputName: string, e: React.ChangeEvent<any>) => void;
  // inputError object with key value pairs
  inputErrors?: {
    [key: string]: string | null;
  };
  pageValid: boolean;
  surveyType: "diary" | "single";
};

export interface AddSurveyWizardStepInformationProps
  extends AddSurveyWizardProps {
  surveyName: string | null;
  surveyDescription: string | null;
  // surveyAppDisplayName: string | null;
  surveyIcon: string | null;
  availableSurveyTypes: string[];
  surveyAutoStart: boolean;
  surveyStartDate: string | null;
}
export interface AddSurveyWizardStepConfigurationProps
  extends AddSurveyWizardProps {
  activeDays: number;
  graceDays: number;
  bonusDays: number;
  minimumDailyCompletes: number;
  maximumDailyCompletes: number;
  surveySystem: string | null;
  surveySystemDetails: string | null;
  setSurveySystemDetails: React.Dispatch<React.SetStateAction<string | null>>;
  testLinkIncluded: boolean;
  testSurveySystem: string;
  testSurveySystemDetails: string;
  wscSurveyOptionsData: FieldDropdown[];
  wscSurveysData: FieldDropdown[];
  surveyName: string | null;
}

export interface AddSurveyWizardStepNotificationsProps
  extends AddSurveyWizardProps {
  includeNotifications: boolean;
  welcomeNotification: string;
  dailyReminderNotification: string;
  dailyReminderTime: string | null;
  nonComplianceNotification: string;
  nonComplianceTime: string | null;
  welcomeNotificationsData: ExistingStudyNotification[];
  dailyReminderNotificationsData: ExistingStudyNotification[];
  nonComplianceNotificationsData: ExistingStudyNotification[];
  newWelcomeNotification: string | null;
  newDailyReminderNotification: string | null;
  newNonComplianceNotification: string | null;
  singleNonComplianceNotificationStartDelay: number;
  singleNonComplianceNotificationInBetweenDelay: number;
  surveyName: string | null;
}

const AddSurveyWizard = () => {
  const { clientID, studyID } = useParams(); // Get from URL
  const navigate = useNavigate();
  // const totalPages = 1;
  const [totalPages, setTotalPages] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const allPagesNames = useMemo(
    () => ["Survey Information", "Configuration", "Notifications"],
    []
  );

  const imageSizes: number = 300;

  const [currentPageName, setCurrentPageName] = useState<string>(
    allPagesNames[0]
  );

  // Survey Information
  const [availableSurveyTypes, setAvailableSurveyTypes] = useState<string[]>([
    "single",
    "diary"
  ]);
  const [surveyType, setSurveyType] =
    useState<AddSurveyWizardStepInformationProps["surveyType"]>("single");
  const [surveyName, setSurveyName] =
    useState<AddSurveyWizardStepInformationProps["surveyName"]>(null);
  const [surveyDescription, setSurveyDescription] =
    useState<AddSurveyWizardStepInformationProps["surveyDescription"]>(null);
  // const [surveyAppDisplayName, setSurveyAppDisplayName] =
  //   useState<AddSurveyWizardStepInformationProps["surveyAppDisplayName"]>(null);
  const [surveyIcon, setSurveyIcon] =
    useState<AddSurveyWizardStepInformationProps["surveyIcon"]>(null);

  const [surveyAutoStart, setSurveyAutoStart] =
    useState<AddSurveyWizardStepInformationProps["surveyAutoStart"]>(false);
  const [surveyStartDate, setSurveyStartDate] =
    useState<AddSurveyWizardStepInformationProps["surveyStartDate"]>(null);

  // Survey Configuration
  const [activeDays, setActiveDays] =
    useState<AddSurveyWizardStepConfigurationProps["activeDays"]>(1);
  const [graceDays, setGraceDays] =
    useState<AddSurveyWizardStepConfigurationProps["graceDays"]>(0);
  const [bonusDays, setBonusDays] =
    useState<AddSurveyWizardStepConfigurationProps["bonusDays"]>(0);
  const [minimumDailyCompletes, setMinimumDailyCompletes] =
    useState<AddSurveyWizardStepConfigurationProps["minimumDailyCompletes"]>(1);
  const [maximumDailyCompletes, setMaximumDailyCompletes] =
    useState<AddSurveyWizardStepConfigurationProps["maximumDailyCompletes"]>(1);
  const [surveySystem, setSurveySystem] =
    useState<AddSurveyWizardStepConfigurationProps["surveySystem"]>(null);
  const [surveySystemDetails, setSurveySystemDetails] =
    useState<AddSurveyWizardStepConfigurationProps["surveySystemDetails"]>(
      null
    );
  const [testLinkIncluded, setSingleTestLinkIncluded] =
    useState<AddSurveyWizardStepConfigurationProps["testLinkIncluded"]>(false);

  const [testSurveySystemDetails, setTestSurveySystemDetails] =
    useState<AddSurveyWizardStepConfigurationProps["testSurveySystemDetails"]>(
      ""
    );

  const [testSurveySystem, setTestLinkSurveySystem] =
    useState<AddSurveyWizardStepConfigurationProps["testSurveySystem"]>("wsc");

  const [wscSurveyOptions, setWscSurveyOptions] = useState<FieldDropdown[]>();
  const [wscSurveys, setWscSurveys] = useState<FieldDropdown[]>();

  // Survey Notifications
  const [welcomeNotification, setWelcomeNotification] =
    useState<AddSurveyWizardStepNotificationsProps["welcomeNotification"]>(
      "new"
    );
  const [dailyReminderNotification, setDailyReminderNotification] =
    useState<
      AddSurveyWizardStepNotificationsProps["dailyReminderNotification"]
    >("new");
  const [dailyReminderTime, setDailyReminderTime] =
    useState<AddSurveyWizardStepNotificationsProps["dailyReminderTime"]>(null);
  const [nonComplianceNotification, setNonComplianceNotification] =
    useState<
      AddSurveyWizardStepNotificationsProps["nonComplianceNotification"]
    >("new");
  const [nonComplianceTime, setNonComplianceTime] =
    useState<AddSurveyWizardStepNotificationsProps["nonComplianceTime"]>(null);

  const [newWelcomeNotification, setNewWelcomeNotification] =
    useState<AddSurveyWizardStepNotificationsProps["newWelcomeNotification"]>(
      null
    );

  const [newDailyReminderNotification, setNewDailyReminderNotification] =
    useState<
      AddSurveyWizardStepNotificationsProps["newDailyReminderNotification"]
    >(null);

  const [newNonComplianceNotification, setNewNonComplianceNotification] =
    useState<
      AddSurveyWizardStepNotificationsProps["newNonComplianceNotification"]
    >(null);

  const [wizardSubmitting, setWizardSubmitting] = useState<boolean>(false);
  const [wizardSubmitError, setWizardSubmitError] = useState<boolean>(false);

  // errors
  const [inputErrors, setInputErrors] = useState<
    AddSurveyWizardProps["inputErrors"]
  >({});

  const [pageValid, setPageValid] = useState<boolean>(false);

  const [includeNotifications, setIncludeNotifications] =
    useState<AddSurveyWizardStepNotificationsProps["includeNotifications"]>(
      false
    );

  const [diaryWelcomeNotificationsData, setWelcomeNotificationsData] = useState<
    ExistingStudyNotification[]
  >([]);
  const [
    diaryDailyReminderNotificationsData,
    setDailyReminderNotificationsData
  ] = useState<ExistingStudyNotification[]>([]);
  const [
    diaryNonComplianceNotificationsData,
    setNonComplianceNotificationsData
  ] = useState<ExistingStudyNotification[]>([]);

  const [singleWelcomeNotificationsData, setSingleWelcomeNotificationsData] =
    useState<ExistingStudyNotification[]>([]);
  const [
    singleNonComplianceNotificationsData,
    setSingleNonComplianceNotificationsData
  ] = useState<ExistingStudyNotification[]>([]);

  const [
    singleDailyReminderNotificationsData,
    setSingleDailyReminderNotificationsData
  ] = useState<ExistingStudyNotification[]>([]);

  const [
    singleNonComplianceNotificationStartDelay,
    setSingleNonComplianceNotificationStartDelay
  ] = useState<number>(0);

  const [
    singleNonComplianceNotificationInBetweenDelay,
    setSingleNonComplianceNotificationInBetweenDelay
  ] = useState<number>(0);

  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(true);
  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  const [loadingMessageID, setLoadingMessageID] = useState<number>(1);

  const allLoadingMessages = [
    {
      id: 1,
      message: "Loading Phrases"
    },
    {
      id: 2,
      message: "Loading Notifications"
    },
    {
      id: 3,
      message: "Loading WSC surveys"
    }
  ];

  const displayAutoStartWarning = () => {
    Swal.fire({
      title: "Please Note",
      html: `This will create a tag called "Auto-Start" and an active automation that will move the participants at the designated date indicated below. <br><br>Please tag the participants with the "Auto-Start" tag, and activate the automation when needed. `,
      icon: "info",
      showCancelButton: false,
      confirmButtonColor: "#3085d6",
      width: "40rem"
    });
  };

  useEffect(() => {
    if (loading && loadingMessageID) {
      // cycle though loading messages every 2 seconds
      const interval = setInterval(() => {
        // look at current messageID and set next messageID
        const currentMessageID = loadingMessageID;
        const nextMessageID = currentMessageID + 1;
        // if nextMessageID is greater than the length of allLoadingMessages, then set to 1
        const newMessageID =
          nextMessageID > allLoadingMessages.length ? 1 : nextMessageID;
        setLoadingMessageID(newMessageID);
      }, 3000);

      return () => clearInterval(interval);
    }
  }, [loading, loadingMessageID, allLoadingMessages.length]);

  const [isImageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const image = new Image();
    image.src = kolaImage;
    image.onload = () => setImageLoaded(true);
    image.onerror = () => setImageLoaded(false);
  }, []);

  const [ellipsis, setEllipsis] = useState<string>(".");

  useEffect(() => {
    if (loading || wizardSubmitting) {
      // cycle through ellipsis, adding a dot every second and then resetting
      const interval = setInterval(() => {
        if (ellipsis.length < 3) {
          setEllipsis(ellipsis + ".");
        } else {
          setEllipsis(".");
        }
      }, 800);

      return () => clearInterval(interval);
    }
  }, [loading, wizardSubmitting, ellipsis]);

  // set the current page to the index of the allPagesNames array
  useEffect(() => {
    setCurrentPage(allPagesNames.indexOf(currentPageName));
  }, [currentPageName, allPagesNames]);

  useEffect(() => {
    setTotalPages(allPagesNames.length);
  }, [allPagesNames]);

  // validateWizard has no input parameters and returns a boolean
  const validateWizard = useCallback(() => {
    let allValid = true;
    const stringMaxShort = 255;
    const stringMaxLong = 1500;
    const validNfieldLinks = [
      "interviewing.nfieldmr.com/",
      "interviewingap.nfieldmr.com/"
    ];
    const reservedNames = ["compliant"];
    let issues = {};
    if (currentPageName === "Survey Information") {
      // Survey Name
      if (surveyName === "") {
        issues = {
          ...issues,
          surveyName: "Please enter a survey name"
        };
        allValid = false;
      } else if (surveyName && surveyName?.length < 4) {
        // issues = {
        //   ...issues,
        //   surveyName: "Survey name must be at least 4 characters",
        // };
        // allValid = false;
      } else if (surveyName && surveyName?.length > stringMaxShort) {
        issues = {
          ...issues,
          surveyName: `Must be less than ${stringMaxShort} characters`
        };
        allValid = false;
      } else if (surveyName) {
        //  check if name contains any reserved words
        const reservedName = reservedNames.find((name) =>
          surveyName.toLowerCase().includes(name)
        );

        if (reservedName) {
          issues = {
            ...issues,
            surveyName: `Name cannot contain reserved word: ${reservedName}`
          };
          allValid = false;
        }
      } else {
        issues = {
          ...issues,
          surveyName: null
        };
      }
      // Survey Description
      if (surveyDescription === "") {
        issues = {
          ...issues,
          surveyDescription: "Please enter a survey description"
        };
        allValid = false;
      } else if (
        surveyDescription &&
        surveyDescription?.length > stringMaxLong
      ) {
        issues = {
          ...issues,
          surveyDescription: `Must be less than ${stringMaxLong} characters`
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          surveyDescription: null
        };
      }

      // if (surveyAppDisplayName === "") {
      //   issues = {
      //     ...issues,
      //     surveyAppDisplayName: "Please enter a survey app display name"
      //   };
      //   allValid = false;
      // } else if (
      //   surveyAppDisplayName &&
      //   surveyAppDisplayName?.length > stringMaxLong
      // ) {
      //   issues = {
      //     ...issues,
      //     surveyAppDisplayName: `Must be less than ${stringMaxLong} characters`
      //   };
      //   allValid = false;
      // }

      if (surveyIcon === "") {
        issues = {
          ...issues,
          surveyIcon: "Please upload a survey icon"
        };
        allValid = false;
      } else {
        if (surveyIcon) {
          // Extract MIME type from the data URL
          const mimeTypeMatch = surveyIcon.match(
            /^data:(image\/(png|jpg|jpeg|webp|svg|gif|tiff));base64,/
          );
          if (!mimeTypeMatch) {
            issues = {
              ...issues,
              surveyIcon: "Please upload a valid image"
            };
            allValid = false;
          }
        } else {
          issues = {
            ...issues,
            surveyIcon: null
          };
        }
      }
      if (
        surveyAutoStart === true &&
        (surveyStartDate === "" || surveyStartDate === null)
      ) {
        issues = {
          ...issues,
          surveyStartDate: "Please select a date"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          surveyStartDate: null
        };
      }
    }

    if (currentPageName === "Configuration") {
      if (surveySystem === "") {
        issues = {
          ...issues,
          surveySystem: "Please select a survey system"
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          surveySystem: null
        };
      }

      if (surveySystemDetails === "") {
        issues = {
          ...issues,
          surveySystemDetails: "Please enter survey system details"
        };
        allValid = false;
      } else if (
        surveySystem === "nfield" &&
        !validNfieldLinks.some((link) => surveySystemDetails?.includes(link))
      ) {
        issues = {
          ...issues,
          surveySystemDetails: "Please enter a valid Nfield link"
        };
        allValid = false;
      } else if (
        surveySystem === "custom" &&
        surveySystemDetails &&
        (!surveySystemDetails?.includes("https://") ||
          !surveySystemDetails?.includes(".") ||
          surveySystemDetails.length < 12)
      ) {
        issues = {
          ...issues,
          surveySystemDetails: "Please enter a valid link"
        };
        allValid = false;
      } else if (
        surveySystemDetails &&
        surveySystemDetails?.length > stringMaxShort
      ) {
        issues = {
          ...issues,
          surveySystemDetails: `Must be less than ${stringMaxShort} characters`
        };
        allValid = false;
      } else {
        issues = {
          ...issues,
          surveySystemDetails: null
        };
      }

      if (testLinkIncluded === true) {
        if (testSurveySystemDetails === "") {
          issues = {
            ...issues,
            testSurveySystemDetails: "Please enter survey system details"
          };
          allValid = false;
        } else if (
          testSurveySystem === "nfield" &&
          !validNfieldLinks.some((link) =>
            testSurveySystemDetails?.includes(link)
          )
        ) {
          issues = {
            ...issues,
            testSurveySystemDetails: "Please enter a valid Nfield link"
          };
          allValid = false;
        } else if (
          testSurveySystem === "custom" &&
          testSurveySystemDetails &&
          (!testSurveySystemDetails?.includes("https://") ||
            !testSurveySystemDetails?.includes(".") ||
            testSurveySystemDetails.length < 12)
        ) {
          issues = {
            ...issues,
            testSurveySystemDetails: "Please enter a valid link"
          };
          allValid = false;
        } else if (
          testSurveySystemDetails &&
          testSurveySystemDetails?.length > stringMaxShort
        ) {
          issues = {
            ...issues,
            testSurveySystemDetails: `Must be less than ${stringMaxShort} characters`
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            testSurveySystemDetails: null
          };
        }
      }

      if (surveyType === "diary") {
        // Active Days
        if (activeDays === null) {
          issues = {
            ...issues,
            activeDays: "Please enter a number of active days"
          };
          allValid = false;
        } else if (activeDays && activeDays < 1) {
          issues = {
            ...issues,
            activeDays: "Active days must be at least 1"
          };
          allValid = false;
        } else if (activeDays && activeDays > 365) {
          issues = {
            ...issues,
            activeDays: "Active days must be less than 365"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            activeDays: null
          };
        }
        // Grace Days
        if (graceDays === null) {
          issues = {
            ...issues,
            graceDays: "Please enter a number of grace days"
          };
          allValid = false;
        } else if (graceDays && graceDays < 0) {
          issues = {
            ...issues,
            graceDays: "Grace days must be at least 0"
          };
          allValid = false;
        } else if (graceDays && graceDays > 365) {
          issues = {
            ...issues,
            graceDays: "Grace days must be less than 365"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            graceDays: null
          };
        }
        // Bonus Days
        if (bonusDays === null) {
          issues = {
            ...issues,
            bonusDays: "Please enter a number of bonus days"
          };
          allValid = false;
        } else if (bonusDays && bonusDays < 0) {
          issues = {
            ...issues,
            bonusDays: "Bonus days must be at least 0"
          };
          allValid = false;
        } else if (bonusDays && bonusDays > 365) {
          issues = {
            ...issues,
            bonusDays: "Bonus days must be less than 365"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            bonusDays: null
          };
        }
        // Minimum Daily Completes
        if (minimumDailyCompletes === null) {
          issues = {
            ...issues,
            minimumDailyCompletes: "Please enter a number of minimum completes"
          };
          allValid = false;
        } else if (minimumDailyCompletes && minimumDailyCompletes < 1) {
          issues = {
            ...issues,
            minimumDailyCompletes: "Minimum completes must be at least 1"
          };
          allValid = false;
        } else if (minimumDailyCompletes > maximumDailyCompletes) {
          issues = {
            ...issues,
            minimumDailyCompletes:
              "Minimum completes must be less than maximum completes"
          };
          allValid = false;
        } else {
          issues = {
            ...issues,
            minimumDailyCompletes: null
          };
        }
      }
    }

    if (currentPageName === "Notifications" && includeNotifications) {
      // Welcome Notification
      // Daily Reminder Time

      if (surveyType === "diary") {
        if (
          dailyReminderNotification === "" ||
          dailyReminderNotification === null
        ) {
          issues = {
            ...issues,
            dailyReminderNotification: "Please enter a notification"
          };
          allValid = false;
        }

        if (dailyReminderNotification === "new") {
          if (
            !newDailyReminderNotification ||
            newDailyReminderNotification === "" ||
            newDailyReminderNotification === null
          ) {
            issues = {
              ...issues,
              newDailyReminderNotification: "Please enter a notification"
            };
            allValid = false;
          } else if (
            newDailyReminderNotification &&
            newDailyReminderNotification?.length > stringMaxLong
          ) {
            issues = {
              ...issues,
              newDailyReminderNotification: `Must be less than ${stringMaxLong} characters`
            };
            allValid = false;
          }
        }

        if (dailyReminderTime === "") {
          issues = {
            ...issues,
            dailyReminderTime: "Please enter a time"
          };
          allValid = false;
        }
      }

      // nonComplianceTime
      if (nonComplianceTime === "") {
        issues = {
          ...issues,
          nonComplianceTime: "Please enter a time"
        };
        allValid = false;
      }

      if (welcomeNotification === "" || welcomeNotification === null) {
        issues = {
          ...issues,
          welcomeNotification: "Please enter a notification"
        };
        allValid = false;
      }

      if (
        nonComplianceNotification === "" ||
        nonComplianceNotification === null
      ) {
        issues = {
          ...issues,
          nonComplianceNotification: "Please enter a notification"
        };
        allValid = false;
      }

      if (welcomeNotification === "new") {
        if (
          !newWelcomeNotification ||
          newWelcomeNotification === "" ||
          newWelcomeNotification === null
        ) {
          issues = {
            ...issues,
            newWelcomeNotification: "Please enter a notification"
          };
          allValid = false;
        } else if (
          newWelcomeNotification &&
          newWelcomeNotification?.length > stringMaxLong
        ) {
          issues = {
            ...issues,
            newWelcomeNotification: `Must be less than ${stringMaxLong} characters`
          };
          allValid = false;
        }
      }

      if (nonComplianceNotification === "new") {
        if (
          !newNonComplianceNotification ||
          newNonComplianceNotification === "" ||
          newNonComplianceNotification === null
        ) {
          issues = {
            ...issues,
            newNonComplianceNotification: "Please enter a notification"
          };
          allValid = false;
        } else if (
          newNonComplianceNotification &&
          newNonComplianceNotification?.length > stringMaxLong
        ) {
          issues = {
            ...issues,
            newNonComplianceNotification: `Must be less than ${stringMaxLong} characters`
          };
          allValid = false;
        }
      }
    }

    // console.log("validateWizard");
    // console.log("currentPageName", currentPageName);
    // console.log(currentPageName === allPagesNames[2]);
    // console.log("allValidWizard", allValid);
    // console.log("issues", issues);
    // console.log("inputErrors.surveyName", issues?.surveyName);
    setInputErrors(issues);
    // allValid is true if inputErrors is empty
    if (!allValid) {
      console.log("allValid", allValid);
      console.log("issues", issues);
    }
    return allValid;
  }, [
    currentPageName,
    surveyName,
    surveyDescription,
    // surveyAppDisplayName,
    surveyIcon,
    surveyAutoStart,
    surveyStartDate,
    surveySystem,
    surveySystemDetails,
    testLinkIncluded,
    testSurveySystem,
    testSurveySystemDetails,
    surveyType,
    activeDays,
    graceDays,
    bonusDays,
    minimumDailyCompletes,
    maximumDailyCompletes,
    dailyReminderTime,
    nonComplianceTime,
    welcomeNotification,
    dailyReminderNotification,
    nonComplianceNotification,
    includeNotifications,
    newWelcomeNotification,
    newDailyReminderNotification,
    newNonComplianceNotification,
    setInputErrors
  ]);

  // validate useEffect, dependent on all fields
  useEffect(() => {
    setPageValid(validateWizard());
  }, [validateWizard]);

  useEffect(() => {
    // set notifications based on surveyType
    const getNotificationValue = (
      data: ExistingStudyNotification[] | undefined
    ) => {
      return data && data[0] && data[0].phraseID ? data[0].phraseID : "new";
    };

    if (surveyType === "diary") {
      setWelcomeNotification(
        getNotificationValue(diaryWelcomeNotificationsData)
      );
      setDailyReminderNotification(
        getNotificationValue(diaryDailyReminderNotificationsData)
      );
      setNonComplianceNotification(
        getNotificationValue(diaryNonComplianceNotificationsData)
      );
    } else {
      setWelcomeNotification(
        getNotificationValue(singleWelcomeNotificationsData)
      );
      setDailyReminderNotification(
        getNotificationValue(singleDailyReminderNotificationsData)
      );
      setNonComplianceNotification(
        getNotificationValue(singleNonComplianceNotificationsData)
      );
    }
  }, [
    surveyType,
    diaryWelcomeNotificationsData,
    diaryDailyReminderNotificationsData,
    diaryNonComplianceNotificationsData,
    singleWelcomeNotificationsData,
    singleDailyReminderNotificationsData,
    singleNonComplianceNotificationsData
  ]);
  useEffect(() => {
    console.log("wscSurveys", wscSurveys);
    setSurveySystem(wscSurveys ? wscSurveys[0].id : "");
  }, [wscSurveys]);

  useEffect(() => {
    setSurveySystemDetails(wscSurveyOptions ? wscSurveyOptions[0].id : "");
  }, [wscSurveyOptions]);

  // load data
  useEffect(() => {
    setWscSurveys([
      {
        id: "wsc",
        name: "WSC"
      },
      {
        id: "nfield",
        name: "nField"
      },
      {
        id: "viewEQ",
        name: "View EQ"
      },
      {
        id: "custom",
        name: "Custom"
      }
    ]);

    async function getAllCreateSurveyNotifications() {
      try {
        type JsonData = string | ExistingStudyNotifications;

        const jsonData: JsonData = await fetchAllExistingStudyNotifications();
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "a931888cce26c74ac03bbeb81d5fe69f"
          ]);
          throw new Error("Error fetching data");
        } else {
          // console.log("jsonData is an object");
          // console.log("jsonData", jsonData);
          setWelcomeNotificationsData([...jsonData.diaryWelcomeNotifications]);
          setDailyReminderNotificationsData([
            ...jsonData.diaryDailyReminderNotifications
          ]);
          setNonComplianceNotificationsData([
            ...jsonData.diaryNonComplianceNotifications
          ]);
          setSingleWelcomeNotificationsData([
            ...jsonData.singleWelcomeNotifications
          ]);
          setSingleNonComplianceNotificationsData([
            ...jsonData.singleNonComplianceNotifications
          ]);
          setSingleDailyReminderNotificationsData([
            ...jsonData.singleDailyReminderNotifications
          ]);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching notifications data:",
          error
        );
        throw error;
      }
    }

    async function fetchSurveys() {
      try {
        const jsonData = await fetchAllSurveys(
          clientID as string,
          studyID as string
        );
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "0109a50b4cc5cb997c931eb29fc2a171"
          ]);
        } else {
          //   if any survey_type is diary, then set availableSurveyTypes to single else set to diary and single
          let availableTypes: string[] = ["diary", "single"];
          jsonData.some((survey) => {
            if (survey.survey_type === "diary") {
              availableTypes = ["single"];
              return true; // stop the loop
            }
            return false;
          });
          setAvailableSurveyTypes(availableTypes);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "790242d21b5a333257ff1ec847d2903d"
        ]);
      }
    }

    async function fetchWSCSurveys() {
      try {
        const jsonData = await fetchAllWSCSurveys();
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "0109a50b4cc5csb997c931eb29fc2a171"
          ]);
        } else {
          setWscSurveyOptions(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingError(true); // Set loadingError to true in case of an error
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "790242d21b5da333257ff1ec847d2903d"
        ]);
      }
    }

    // Create an array of promises for each handleData call
    const promises = [
      fetchWSCSurveys(),
      getAllCreateSurveyNotifications(),
      fetchSurveys()
    ];

    // Use Promise.all to wait for all promises to complete
    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
        setLoadingError(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setLoadingError(true); // An error occurred
      });
  }, [clientID, studyID]);

  function handleInputChange(inputName: string, e: React.ChangeEvent<any>) {
    // console.log("inputName", inputName);

    // page Information
    if (inputName === "surveyType") {
      setSurveyType(e.target.value);
      return;
    }

    if (inputName === "surveyName") {
      setSurveyName(e.target.value);
      return;
    }

    if (inputName === "surveyDescription") {
      setSurveyDescription(e.target.value);
      return;
    }

    // if (inputName === "surveyAppDisplayName") {
    //   setSurveyAppDisplayName(e.target.value);
    //   return;
    // }

    if (inputName === "surveyIcon") {
      const file = e.target.files?.[0];
      if (file) {
        resizeImage(file, imageSizes).then((resizedImage) => {
          if (typeof resizedImage === "string") {
            setSurveyIcon(resizedImage);
          } else {
            setSurveyIcon("Invalid");
          }
        });
      }
      return;
    }

    if (inputName === "surveyStartDate") {
      setSurveyStartDate(e.target.value);
      return;
    }

    if (inputName === "surveyAutoStart") {
      setSurveyAutoStart(e.target.checked);
      if (e.target.checked === true) {
        displayAutoStartWarning();
      }
      return;
    }

    // page Configuration
    if (inputName === "activeDays") {
      let val: number;
      try {
        val = parseInt(e.target.value);
      } catch {
        val = 0;
      }
      setActiveDays(val);
      return;
    }

    if (inputName === "graceDays") {
      let val: number;
      try {
        val = parseInt(e.target.value);
      } catch {
        val = 0;
      }
      setGraceDays(val);
      return;
    }

    if (inputName === "bonusDays") {
      let val: number;
      try {
        val = parseInt(e.target.value);
      } catch {
        val = 0;
      }
      setBonusDays(val);
      return;
    }

    if (inputName === "minimumDailyCompletes") {
      let val: number;
      try {
        val = parseInt(e.target.value);
      } catch {
        val = 0;
      }
      setMinimumDailyCompletes(val);
      return;
    }

    if (inputName === "maximumDailyCompletes") {
      let val: number;
      try {
        val = parseInt(e.target.value);
      } catch {
        val = 0;
      }
      setMaximumDailyCompletes(val);
      return;
    }

    if (inputName === "surveySystem") {
      setSurveySystem(e.target.value);
      if (e.target.value === "wsc" && wscSurveyOptions && wscSurveyOptions[0]) {
        setSurveySystemDetails(wscSurveyOptions[0].id);
      } else if (e.target.value === "nfield") {
        setSurveySystemDetails("https://interviewing.nfieldmr.com/");
      } else if (e.target.value === "custom") {
        setSurveySystemDetails("https://");
      } else if (e.target.value === "viewEQ") {
        setSurveySystemDetails("");
      } else {
        setSurveySystemDetails("");
      }
      return;
    }

    if (inputName === "surveySystemDetails") {
      setSurveySystemDetails(e.target.value);
      return;
    }

    // Test link
    if (inputName === "testLinkIncluded") {
      setSingleTestLinkIncluded(e.target.checked);
      return;
    }

    if (inputName === "testSurveySystem") {
      setTestLinkSurveySystem(e.target.value);
      if (e.target.value === "wsc" && wscSurveyOptions && wscSurveyOptions[0]) {
        setTestSurveySystemDetails(wscSurveyOptions[0].id);
      } else if (e.target.value === "nfield") {
        setTestSurveySystemDetails("https://interviewing.nfieldmr.com/");
      } else if (e.target.value === "custom") {
        setTestSurveySystemDetails("https://");
      } else if (e.target.value === "viewEQ") {
        setTestSurveySystemDetails("");
      } else {
        setTestSurveySystemDetails("");
      }
      return;
    }

    if (inputName === "testSurveySystemDetails") {
      setTestSurveySystemDetails(e.target.value);
      return;
    }

    // page Notifications

    if (inputName === "welcomeNotification") {
      setWelcomeNotification(e.target.value);
      setNewWelcomeNotification(null);
      return;
    }

    if (inputName === "dailyReminderNotification") {
      setDailyReminderNotification(e.target.value);
      setNewDailyReminderNotification(null);
      return;
    }

    if (inputName === "dailyReminderTime") {
      setDailyReminderTime(e.target.value);
      return;
    }

    if (inputName === "nonComplianceNotification") {
      setNonComplianceNotification(e.target.value);
      setNewNonComplianceNotification(null);
      return;
    }

    if (inputName === "nonComplianceTime") {
      setNonComplianceTime(e.target.value);
      return;
    }

    if (inputName === "newWelcomeNotification") {
      setNewWelcomeNotification(e.target.value);
      return;
    }

    if (inputName === "newDailyReminderNotification") {
      setNewDailyReminderNotification(e.target.value);
      return;
    }

    if (inputName === "newNonComplianceNotification") {
      setNewNonComplianceNotification(e.target.value);
      return;
    }

    if (inputName === "singleNonComplianceNotificationStartDelay") {
      setSingleNonComplianceNotificationStartDelay(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0
      );
      return;
    }

    if (inputName === "singleNonComplianceNotificationInBetweenDelay") {
      setSingleNonComplianceNotificationInBetweenDelay(
        parseInt(e.target.value) > 0 ? parseInt(e.target.value) : 0
      );
      return;
    }

    if (inputName === "includeNotifications") {
      setIncludeNotifications(e.target.checked);
      return;
    }

    console.log("Error: " + inputName + " not found");
    console.log("e", e);
    console.log("e.target.value", e.target.value);
    return;
  }

  // checkEmptyFieldsBeforeSubmit return boolean
  function checkEmptyFieldsBeforeSubmit() {
    let allValid = true;

    if (currentPageName === "Survey Information") {
      if (surveyName === null) {
        setSurveyName("");
        // console.log("studyName", studyName);
        allValid = false;
      }

      if (surveyDescription === null) {
        setSurveyDescription("");
        // console.log("studyDescription", studyDescription);
        allValid = false;
      }

      // if (surveyAppDisplayName === null) {
      //   setSurveyAppDisplayName("");
      //   // console.log("studyAppDisplayName", studyAppDisplayName);
      //   allValid = false;
      // }

      if (surveyIcon === null) {
        setSurveyIcon("");
        // console.log("studyIcon", studyIcon);
        allValid = false;
      }
    }

    if (currentPageName === "Configuration") {
      if (surveySystem === null) {
        setSurveySystem("");
        allValid = false;
      }

      if (surveySystemDetails === null) {
        setSurveySystemDetails("");
        allValid = false;
      }

      if (surveyType === "diary") {
        if (activeDays === null) {
          setActiveDays(0);
          allValid = false;
        }

        if (graceDays === null) {
          setGraceDays(0);
          allValid = false;
        }

        if (bonusDays === null) {
          setBonusDays(0);
          allValid = false;
        }

        if (minimumDailyCompletes === null) {
          setMinimumDailyCompletes(0);
          allValid = false;
        }

        if (maximumDailyCompletes === null) {
          setMaximumDailyCompletes(0);
          allValid = false;
        }
      }
    }

    if (currentPageName === "Notifications") {
      if (welcomeNotification === null) {
        setWelcomeNotification("");
        allValid = false;
      }

      if (dailyReminderNotification === null) {
        setDailyReminderNotification("");
        allValid = false;
      }

      if (nonComplianceNotification === null) {
        setNonComplianceNotification("");
        allValid = false;
      }

      if (surveyType === "diary" && includeNotifications) {
        if (dailyReminderTime === null) {
          setDailyReminderTime("");
          allValid = false;
        }
        if (nonComplianceTime === null) {
          setNonComplianceTime("");
          allValid = false;
        }
      }

      if (surveyType === "single" && includeNotifications) {
        if (nonComplianceTime === null) {
          setNonComplianceTime("");
          allValid = false;
        }
        setDailyReminderTime("");
      }
    }

    return allValid;
  }

  function handleWizardForward() {
    if (checkEmptyFieldsBeforeSubmit()) {
      setCurrentPageName(allPagesNames[currentPage + 1]);
    }
  }

  function handleWizardBack() {
    setCurrentPageName(allPagesNames[currentPage - 1]);
  }

  function handleWizardSubmit() {
    // console.log("handleWizardSubmit");
    if (checkEmptyFieldsBeforeSubmit()) {
      setWizardSubmitting(true);
      // console.log("Submitting data...");
      // send data to server and redirect to client page on success
      if (!clientID || !studyID) {
        let displayMessage = `<strong>Please contact support</strong><br><br>`;
        displayMessage += `Cannot submit form<br>`;
        displayMessage += `Missing clientID or studyID<br>`;

        Swal.fire({
          icon: "error",
          title: `Error`,
          html: displayMessage,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        setWizardSubmitError(true);
        return;
      }
      if (
        !surveyType ||
        !surveyName ||
        surveyName === "" ||
        !surveyDescription ||
        surveyDescription === "" ||
        // !surveyAppDisplayName ||
        // surveyAppDisplayName === "" ||
        !surveyIcon ||
        surveyIcon === "" ||
        activeDays === null ||
        graceDays === null ||
        bonusDays === null ||
        minimumDailyCompletes === null ||
        maximumDailyCompletes === null ||
        !surveySystem ||
        surveySystem === "" ||
        !surveySystemDetails ||
        surveySystemDetails === "" ||
        !welcomeNotification ||
        welcomeNotification === "" ||
        !dailyReminderNotification ||
        dailyReminderNotification === "" ||
        !nonComplianceNotification ||
        nonComplianceNotification === "" ||
        (testLinkIncluded &&
          (testSurveySystem === "" ||
            !testSurveySystem ||
            !testSurveySystemDetails ||
            testSurveySystemDetails === ""))
      ) {
        let displayMessage = `<strong>Please contact support</strong><br><br>`;
        displayMessage += `Cannot submit form<br>`;
        displayMessage += `Values are blank<br>`;
        if (!surveyType) {
          displayMessage += `surveyType: ${surveyType}<br>`;
        }
        if (!surveyName) {
          displayMessage += `surveyName: ${surveyName}<br>`;
        }
        if (!surveyDescription) {
          displayMessage += `surveyDescription: ${surveyDescription}<br>`;
        }
        // if (!surveyAppDisplayName) {
        //   displayMessage += `surveyAppDisplayName: ${surveyAppDisplayName}<br>`;
        // }
        if (!surveyIcon) {
          displayMessage += `surveyIcon: ${surveyIcon}<br>`;
        }
        if (!activeDays) {
          displayMessage += `activeDays: ${activeDays}<br>`;
        }
        if (!graceDays) {
          displayMessage += `graceDays: ${graceDays}<br>`;
        }
        if (!bonusDays) {
          displayMessage += `bonusDays: ${bonusDays}<br>`;
        }
        if (!minimumDailyCompletes) {
          displayMessage += `minimumDailyCompletes: ${minimumDailyCompletes}<br>`;
        }
        if (!maximumDailyCompletes) {
          displayMessage += `maximumDailyCompletes: ${maximumDailyCompletes}<br>`;
        }
        if (!surveySystem) {
          displayMessage += `surveySystem: ${surveySystem}<br>`;
        }
        if (!surveySystemDetails) {
          displayMessage += `surveySystemDetails: ${surveySystemDetails}<br>`;
        }

        if (testLinkIncluded) {
          if (!testSurveySystem) {
            displayMessage += `testSurveySystem: ${testSurveySystem}<br>`;
          }
          if (!testSurveySystemDetails) {
            displayMessage += `testSurveySystemDetails: ${testSurveySystemDetails}<br>`;
          }
        }

        if (!welcomeNotification) {
          displayMessage += `welcomeNotification: ${welcomeNotification}<br>`;
        }
        if (!dailyReminderNotification) {
          displayMessage += `dailyReminderNotification: ${dailyReminderNotification}<br>`;
        }
        if (!nonComplianceNotification) {
          displayMessage += `nonComplianceNotification: ${nonComplianceNotification}<br>`;
        }

        Swal.fire({
          icon: "error",
          title: `Error`,
          html: displayMessage,
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        setWizardSubmitError(true);
        return;
      }

      //     clientID: string;
      // studyID: string;
      // surveyType: "diary" | "single";
      // surveyName: string;
      // surveyDescription: string;
      // surveyAppDisplayName: string;
      // surveyIcon: string;
      // surveyStartDate: string | null;
      // surveyAutoStart: boolean | null;

      // surveyActiveDays: number;
      // surveyGraceDays: number;
      // surveyBonusDays: number;
      // surveyMinimumDailyCompletes: number;
      // surveyMaximumDailyCompletes: number;
      // surveySurveySystem: string;
      // surveySurveySystemDetails: string;

      // welcomeNotification: string;
      // dailyReminderNotification: string;
      // nonComplianceNotification: string;
      // newWelcomeNotification: string;
      // newDailyReminderNotification: string;
      // newNonComplianceNotification: string;
      // dailyReminderTime: string;
      // nonComplianceTime: string;
      // singleNonComplianceNotificationStartDelay: number;
      // singleNonComplianceNotificationInterval: number;

      sendSurveyData({
        clientID: clientID,
        studyID: studyID,
        surveyType: surveyType,
        surveyName: surveyName,
        surveyDescription: surveyDescription,
        // surveyAppDisplayName: surveyAppDisplayName,
        surveyIcon: surveyIcon,
        surveyStartDate: surveyStartDate,
        surveyAutoStart: surveyAutoStart,
        surveyActiveDays: activeDays,
        surveyGraceDays: graceDays,
        surveyBonusDays: bonusDays,
        surveyMinimumDailyCompletes: minimumDailyCompletes,
        surveyMaximumDailyCompletes: maximumDailyCompletes,
        surveySurveySystem: surveySystem,
        surveySurveySystemDetails: surveySystemDetails,
        testLinkIncluded: testLinkIncluded,
        testSurveySystem: testSurveySystem,
        testSurveySystemDetails: testSurveySystemDetails,
        includeNotifications: includeNotifications,
        welcomeNotification: welcomeNotification,
        dailyReminderNotification: dailyReminderNotification,
        nonComplianceNotification: nonComplianceNotification,
        newWelcomeNotification: newWelcomeNotification || undefined,
        newDailyReminderNotification: newDailyReminderNotification || undefined,
        newNonComplianceNotification: newNonComplianceNotification || undefined,
        dailyReminderTime: dailyReminderTime || undefined,
        nonComplianceTime: nonComplianceTime || undefined,
        singleNonComplianceNotificationStartDelay:
          singleNonComplianceNotificationStartDelay,
        singleNonComplianceNotificationInterval:
          singleNonComplianceNotificationInBetweenDelay
      })
        .then((response) => {
          // console.log("response", response);
          if (response === "success") {
            // clear local storage for sidebarData
            localStorage.removeItem("sidebarData");
            navigate(`/clients/${clientID}`);
          } else {
            console.log("Error sending data");
            throw new Error("Error sending data");
          }
        })
        .catch((error) => {
          console.log("Error sending data:", error);
          setWizardSubmitError(true);
        });
    }
  }

  function backCancel() {
    const link = `/clients/${clientID}/${studyID}`;
    console.log("link", link);
    navigate(link);
  }

  return (
    <div id="add_survey_form" className="m-auto col-12 col-lg-6">
      <div className="cancel-button-container">
        <button onClick={() => backCancel()} className="btn btn-primary-cancel">
          <IconLogout className="btnCancel-icon" />
          Cancel
        </button>
      </div>
      {!loading ? (
        !loadingError ? (
          <div>
            {wizardSubmitting ? (
              !wizardSubmitError ? (
                <div className="card card-default add_survey_form_page p-4 d-flex align-items-center justify-content-center">
                  {/* kola image */}
                  <img
                    className="mb-3 mt-5"
                    src={kolaImage}
                    alt="Kola wizard"
                    width="100%"
                    style={{
                      maxWidth: "300px"
                    }}
                  />
                  <h3 className="mb-5">Creating survey{ellipsis}</h3>
                </div>
              ) : (
                <h3 className="text-danger">
                  Error submitting survey. Please try again.
                </h3>
              )
            ) : currentPageName === "Survey Information" ? (
              <StepAddSurveyInformation
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                surveyType={surveyType}
                surveyName={surveyName}
                surveyDescription={surveyDescription}
                // surveyAppDisplayName={surveyAppDisplayName}
                surveyIcon={surveyIcon}
                surveyAutoStart={surveyAutoStart}
                surveyStartDate={surveyStartDate}
                // errors
                inputErrors={inputErrors}
                // data
                availableSurveyTypes={availableSurveyTypes}
              />
            ) : currentPageName === "Configuration" ? (
              <StepConfiguration
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                surveyType={surveyType}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                activeDays={activeDays}
                graceDays={graceDays}
                bonusDays={bonusDays}
                minimumDailyCompletes={minimumDailyCompletes}
                maximumDailyCompletes={maximumDailyCompletes}
                surveySystem={surveySystem}
                surveySystemDetails={surveySystemDetails}
                setSurveySystemDetails={setSurveySystemDetails}
                surveyName={surveyName}
                // Test link
                testLinkIncluded={testLinkIncluded}
                testSurveySystemDetails={testSurveySystemDetails}
                testSurveySystem={testSurveySystem}
                // Data
                wscSurveyOptionsData={wscSurveyOptions || []}
                wscSurveysData={wscSurveys ? wscSurveys : []}
                // Errors
                inputErrors={inputErrors}
              />
            ) : currentPageName === "Notifications" ? (
              <StepNotifications
                currentPage={currentPage + 1}
                totalPages={totalPages}
                pageValid={pageValid}
                surveyType={surveyType}
                handleWizardBack={handleWizardBack}
                handleWizardForward={handleWizardForward}
                handleWizardSubmit={handleWizardSubmit}
                handleInputChange={handleInputChange}
                // Fields
                includeNotifications={includeNotifications}
                welcomeNotification={welcomeNotification}
                dailyReminderNotification={dailyReminderNotification}
                dailyReminderTime={dailyReminderTime}
                nonComplianceNotification={nonComplianceNotification}
                nonComplianceTime={nonComplianceTime}
                singleNonComplianceNotificationStartDelay={
                  singleNonComplianceNotificationStartDelay
                }
                singleNonComplianceNotificationInBetweenDelay={
                  singleNonComplianceNotificationInBetweenDelay
                }
                // Data
                welcomeNotificationsData={
                  surveyType === "diary" && diaryWelcomeNotificationsData
                    ? diaryWelcomeNotificationsData
                    : surveyType === "single" && singleWelcomeNotificationsData
                      ? singleWelcomeNotificationsData
                      : []
                }
                dailyReminderNotificationsData={
                  surveyType === "diary" && diaryDailyReminderNotificationsData
                    ? diaryDailyReminderNotificationsData
                    : surveyType === "single" &&
                        singleDailyReminderNotificationsData
                      ? singleDailyReminderNotificationsData
                      : []
                }
                nonComplianceNotificationsData={
                  surveyType === "diary" && diaryNonComplianceNotificationsData
                    ? diaryNonComplianceNotificationsData
                    : surveyType === "single" &&
                        singleNonComplianceNotificationsData
                      ? singleNonComplianceNotificationsData
                      : []
                }
                newWelcomeNotification={newWelcomeNotification}
                newDailyReminderNotification={newDailyReminderNotification}
                newNonComplianceNotification={newNonComplianceNotification}
                surveyName={surveyName}
                // Errors
                inputErrors={inputErrors}
              />
            ) : currentPage > totalPages ? (
              <h1>Missing page</h1>
            ) : (
              <h1>Error</h1>
            )}
          </div>
        ) : (
          <ErrorPage
            errorCode="500"
            error="Error loading data"
            debugCode={loadingErrorText}
          />
        )
      ) : (
        <>
          {/* loading */}
          {!isImageLoaded ? (
            <div className="d-flex justify-content-center custom_spinner_container full_page">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <div className="card card-default add_survey_form_page p-4 d-flex align-items-center justify-content-center">
              {/* kola image */}
              <img
                className="mb-3 mt-5"
                src={kolaImage}
                alt="Kola wizard"
                width="100%"
                style={{
                  maxWidth: "300px"
                }}
              />
              <h3 className="mb-5">
                {allLoadingMessages[loadingMessageID - 1].message}
                {ellipsis}
              </h3>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AddSurveyWizard;
