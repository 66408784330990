import { json } from "@codemirror/lang-json";
import CodeMirror from "@uiw/react-codemirror";
import React, { useEffect, useState } from "react";
import { fetchSourceDataStructure } from "../../models/dfu.model";
import "./structureModal.scss";

type StructureModalProps = {};

const StructureModal: React.FC<StructureModalProps> = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);

  const [dataSourceStructure, setDataSourceStructure] = React.useState<
    { inputStructure: object; resultStructure: object } | undefined
  >(undefined);
  const [loading, setLoading] = React.useState<boolean>(true);
  const [error, setError] = React.useState<string | undefined>(undefined);

  useEffect(() => {
    const fetchDataStructure = async () => {
      setLoading(true);
      setError(undefined);
      setDataSourceStructure(undefined);
      const response = await fetchSourceDataStructure();
      if (response.rStatus === "success") {
        console.log(response.rData);
        setDataSourceStructure(response.rData);
      } else {
        setError(response.message);
      }
      setLoading(false);
    };
    fetchDataStructure();
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <button
            onClick={openModal}
            disabled={!dataSourceStructure || error !== undefined || loading}
            className="btn btn-primary"
          >
            {loading ? "Loading..." : error ? error : "View Data Structure"}
          </button>
        </div>
      </div>
      {dataSourceStructure && isOpen && (
        <div className="modal show modal_report_structure">
          <div className="modal-dialog">
            <div className="modal-content">
              <span
                className="close"
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                &times;
              </span>
              <div className="modal-body">
                <div>
                  <div className="modal-header py-1 ps-0">
                    <h4 className="modal-title p-0">1) Data structure</h4>
                  </div>
                  <CodeMirror
                    maxHeight="80vh"
                    value={JSON.stringify(
                      dataSourceStructure.inputStructure,
                      null,
                      2
                    )}
                    theme="light" // Set the theme to light
                    editable={false}
                    readOnly={true}
                    extensions={[json()]} // Enable json syntax highlighting
                  />
                </div>
                <div>
                  <div className="modal-header py-1 ps-0">
                    <h4 className="modal-title p-0">
                      2) JavaScript expected return structure
                    </h4>
                  </div>
                  <CodeMirror
                    maxHeight="80vh"
                    value={JSON.stringify(
                      dataSourceStructure.resultStructure,
                      null,
                      2
                    )}
                    theme="light" // Set the theme to light
                    editable={false}
                    readOnly={true}
                    extensions={[json()]} // Enable json syntax highlighting
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StructureModal;
