import { RefObject, useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import CustomFontAwesomeIcons from "../assets/data/FontAwesome5Pro.json";
import { FetchResponseError } from "../types";

// #region capitalize
// Capitalizes the first letter of a string
export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}
// #endregion capitalize

// #region capitalizeFirstLetterOfEachWord
export const capitalizeFirstLetterOfEachWord = (string: string) => {
  return string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
// #endregion capitalizeFirstLetterOfEachWord

// #region convertTime
// Converts a date and time from UTC to the date and time + passed in offset
// Date and time are passed in as a string, UTC time
// time example "2023-07-27T00:00:00.000Z"
// Offset is passed in as a number, representing the timezone offset in hours
// offset example -4
// Check date is valid before converting, else return "null"
// returns a date object with the converted date and time
export function convertTime(
  time: string | Date | null,
  offset: number
): Date | null {
  // If time is a Date object, convert it to string in ISO format, else use it as is.
  let timeStr: string | null = time instanceof Date ? time.toISOString() : time;

  // Check if time is empty or null
  if (timeStr !== "" && timeStr !== null) {
    const dateObj = new Date(timeStr);

    // Check if date is valid
    if (!isNaN(dateObj.getTime())) {
      // Decompose offset into hours and minutes
      const offsetHours = Math.floor(offset);
      const offsetMinutes = Math.round((offset - offsetHours) * 60);

      dateObj.setUTCHours(
        dateObj.getUTCHours() + offsetHours,
        dateObj.getUTCMinutes() + offsetMinutes
      );

      return dateObj;
    }
  }
  return null; // Return null for invalid or empty input
}
// #endregion convertTime

// #region formatDate
// Converts a date and time from UTC to the date and time + passed in offset
// Date and time are passed in as a string, UTC time
// time example "2023-07-27T00:00:00.000Z"
export const formatDate = (date: Date | null): string => {
  if (!date) {
    return "Invalid Date";
  }
  const day = date.getUTCDate();
  const month = date.getUTCMonth() + 1;
  const year = date.getUTCFullYear();
  const hour = date.getUTCHours();
  const minute = date.getUTCMinutes();
  // const ampm = hour >= 12 ? "pm" : "am";
  // const formattedHour = hour % 12 || 12;
  const formattedHour = hour < 10 ? "0" + hour.toString() : hour.toString();
  const formattedMinute =
    minute < 10 ? "0" + minute.toString() : minute.toString();

  // return `${day}.${month}.${year} ${formattedHour}:${formattedMinute} ${ampm}`;
  return `${day}.${month}.${year} ${formattedHour}:${formattedMinute}`;
};
// #endregion formatDate

// #region useOutsideClick
// Hook that listens for clicks outside the specified ref element.
// When a click outside is detected, the provided callback is invoked.
// @param ref - React ref object representing the element to listen for outside clicks.
// @param callback - Function to be called when an outside click is detected.
export const useOutsideClick = (
  ref: RefObject<HTMLElement>,
  callback: () => void
) => {
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback();
      }
    },
    [ref, callback]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]); // Only handleClickOutside in the dependencies array
};
// #endregion useOutsideClick

// #region isFetchResponseError
export function isFetchResponseError(
  object: any
): object is FetchResponseError {
  // console.log(object, "object");
  return (
    typeof object.errorCode === "string" &&
    typeof object.errorMessage === "string"
  );
}
// #endregion isFetchResponseError

// #region resizeImage
export async function resizeImage(
  file: File,
  size: number
): Promise<string | false> {
  return new Promise((resolve, reject) => {
    // Check if the file type is allowed
    if (!file.type.match(/image\/(png|jpg|jpeg|webp|svg\+xml|gif|tiff)/)) {
      resolve(false);
      return;
    }

    const reader = new FileReader();
    reader.onload = () => {
      const imageUrl = reader.result as string;

      const img = new Image();
      img.src = imageUrl;

      img.onload = () => {
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        if (ctx) {
          let width = img.width;
          let height = img.height;
          const maxSide = Math.max(width, height);

          if (maxSide > size) {
            const scaleFactor = size / maxSide;
            width *= scaleFactor;
            height *= scaleFactor;
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(img, 0, 0, width, height);
          const resizedImageUrl = canvas.toDataURL("image/png");
          resolve(resizedImageUrl);
        } else {
          resolve(false);
        }
      };
    };

    reader.onerror = (error) => {
      console.error("Error reading file:", error);
      resolve(false);
    };

    reader.readAsDataURL(file);
  });
}
// #endregion resizeImage

// #region copyToClipboard
export function copyToClipboard(type: string, text: string) {
  //------------Copy to clipboard----------------
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", Swal.stopTimer);
      toast.addEventListener("mouseleave", Swal.resumeTimer);
    }
  });
  navigator.clipboard.writeText(text);
  //Swal toast message to Say Link has been copied to your clipboard
  Toast.fire({
    icon: "success",
    title: `${type} copied to clipboard`
  });
}
// #endregion copyToClipboard

export function isWorkerBusy(): boolean {
  //Check for worker
  let workerID = localStorage.getItem("workerID");
  if (workerID !== null) {
    Swal.fire({
      icon: "error",
      title: "Please wait for the current worker to finish",
      confirmButtonColor: "#3085d6"
    });
    return true;
  }
  return false;
}

// #region logStorage
// log all localStorage and sessionStorage items
export function logStorage() {
  console.log("-----BEGIN LOGGING STORAGE ITEMS-----");
  console.log("localStorage:");
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (!key) {
      continue;
    }
    const value = localStorage.getItem(key);
    console.log(key, value);
  }

  console.log("sessionStorage:");
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);
    if (!key) {
      continue;
    }
    const value = sessionStorage.getItem(key);
    console.log(key, value);
  }

  console.log("-----END LOGGING STORAGE ITEMS-----");
}
// #endregion logStorage

// #region isValidHexColor
// validate if color is valid hex color
export function isValidHexColor(color: string): boolean {
  return /^#[0-9A-F]{6}$/i.test(color);
}
// #endregion isValidHexColor

// #region getAllFontAwesomeIconNames
export function getAllFontAwesomeIconNames(): string[] {
  return Object.keys(CustomFontAwesomeIcons);
}
// #endregion getAllFontAwesomeIconNames
