import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  Channel,
  ChannelMessages,
  ChannelParticipants,
  Participant,
  UnassignedParticipant
} from "../types";

// #region type validators
// Validations for data coming from the API
// #region isChannel
const isChannel = (obj: any): obj is Channel => {
  const validChannelID = typeof obj.id === "string";
  const validChannelName = typeof obj.channel_name === "string";
  const validChannelType = typeof obj.channel_type === "string";
  const validChannelCreatedAt = typeof obj.created_at === "string";
  const validChannelThumbnailUrl = typeof obj.thumbnail_url === "string";

  const allValid =
    validChannelID &&
    validChannelName &&
    validChannelType &&
    validChannelCreatedAt &&
    validChannelThumbnailUrl;

  if (!allValid) {
    let errors = [];
    if (!validChannelID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validChannelName) {
      if (!obj.hasOwnProperty("channel_name")) {
        errors.push("missing channel_name");
      } else {
        errors.push(`Invalid type for channel_name`);
      }
    }

    if (!validChannelType) {
      if (!obj.hasOwnProperty("channel_type")) {
        errors.push("missing channel_type");
      } else {
        errors.push(`Invalid type for channel_type`);
      }
    }

    if (!validChannelCreatedAt) {
      if (!obj.hasOwnProperty("created_at")) {
        errors.push("missing created_at");
      } else {
        errors.push(`Invalid type for created_at`);
      }
    }

    if (!validChannelThumbnailUrl) {
      if (!obj.hasOwnProperty("thumbnail_url")) {
        errors.push("missing thumbnail_url");
      } else {
        errors.push(`Invalid type for thumbnail_url`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid Channel`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return allValid;
};
// #endregion isChannel

// #region isChannelMessages
const isChannelMessages = (obj: any): obj is ChannelMessages => {
  const validMessageId = typeof obj.id === "string";
  const validMessage =
    typeof obj.message_content === "string" || obj.message_content === null;
  const validAttachmentKey =
    typeof obj.attachment_key === "string" || obj.attachment_key === null;
  const validChannelID = typeof obj.channel_id === "string";
  const validParentID =
    typeof obj.parent_id === "string" || obj.parent_id === null;
  const validParticipantID =
    typeof obj.participant_id === "string" || obj.participant_id === null;
  const validResearcherID =
    typeof obj.researcher_id === "string" || obj.researcher_id === null;
  const validCreatedAt = typeof obj.created_at === "string";
  const validUpdatedAt = typeof obj.updated_at === "string";
  const validSeen = typeof obj.seen === "number";
  const validFlagged = typeof obj.flagged === "number";
  const validPinned = typeof obj.pinned === "number";
  const validCategoryID =
    typeof obj.category_id === "string" || obj.category_id === null;
  const validSender = typeof obj.sender === "string" || obj.sender === null;

  const allValid =
    validMessageId &&
    validMessage &&
    validAttachmentKey &&
    validChannelID &&
    validParentID &&
    validParticipantID &&
    validResearcherID &&
    validCreatedAt &&
    validUpdatedAt &&
    validSeen &&
    validFlagged &&
    validPinned &&
    validCategoryID &&
    validSender;

  if (!allValid) {
    // //console.log("Invalid channel message object", obj);
    let errors: string[] = [];
    if (!validMessageId) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validMessage) {
      if (!obj.hasOwnProperty("message_content")) {
        errors.push("missing message_content");
      } else {
        errors.push(`Invalid type for message_content`);
      }
    }

    if (!validAttachmentKey) {
      if (!obj.hasOwnProperty("attachment_key")) {
        errors.push("missing attachment_key");
      } else {
        errors.push(`Invalid type for attachment_key`);
      }
    }

    if (!validChannelID) {
      if (!obj.hasOwnProperty("channel_id")) {
        errors.push("missing channel_id");
      } else {
        errors.push(`Invalid type for channel_id`);
      }
    }

    if (!validParentID) {
      if (!obj.hasOwnProperty("parent_id")) {
        errors.push("missing parent_id");
      } else {
        errors.push(`Invalid type for parent_id`);
      }
    }

    if (!validParticipantID) {
      if (!obj.hasOwnProperty("participant_id")) {
        errors.push("missing participant_id");
      } else {
        errors.push(`Invalid type for participant_id`);
      }
    }

    if (!validResearcherID) {
      if (!obj.hasOwnProperty("researcher_id")) {
        errors.push("missing researcher_id");
      } else {
        errors.push(`Invalid type for researcher_id`);
      }
    }

    if (!validCreatedAt) {
      if (!obj.hasOwnProperty("created_at")) {
        errors.push("missing created_at");
      } else {
        errors.push(`Invalid type for created_at`);
      }
    }

    if (!validUpdatedAt) {
      if (!obj.hasOwnProperty("updated_at")) {
        errors.push("missing updated_at");
      } else {
        errors.push(`Invalid type for updated_at`);
      }
    }

    if (!validSeen) {
      if (!obj.hasOwnProperty("seen")) {
        errors.push("missing seen");
      } else {
        errors.push(`Invalid type for seen`);
      }
    }

    if (!validFlagged) {
      if (!obj.hasOwnProperty("flagged")) {
        errors.push("missing flagged");
      } else {
        errors.push(`Invalid type for flagged`);
      }
    }

    if (!validPinned) {
      if (!obj.hasOwnProperty("pinned")) {
        errors.push("missing pinned");
      } else {
        errors.push(`Invalid type for pinned`);
      }
    }

    if (!validCategoryID) {
      if (!obj.hasOwnProperty("category_id")) {
        errors.push("missing category_id");
      } else {
        errors.push(`Invalid type for category_id`);
      }
    }

    if (!validSender) {
      if (!obj.hasOwnProperty("sender")) {
        errors.push("missing sender");
      } else {
        errors.push(`Invalid type for sender`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid Channel Message`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return allValid;
};
// #endregion isChannelMessages

// #region isChannelParticipants
const isChannelParticipants = (obj: any): obj is ChannelParticipants => {
  //console.log(obj, "obj");
  if (obj == null) {
    return false;
  }

  // if (typeof obj.participant_tags === "string") {
  //   try {
  //     obj.participant_tags = JSON.parse(obj.participant_tags);
  //   } catch (error) {
  //     //console.log("Error parsing participant_tags:", error);
  //     return false; // or handle the error as appropriate
  //   }
  // }

  const validID = typeof obj.id === "string";
  const validParticipantName = typeof obj.sender_name === "string"; // Adjusted to participant_name
  const validParticipantEmail = typeof obj.participant_email === "string"; // Adjusted to participant_lang_iso
  const validParticipantLanguage =
    typeof obj.participant_lang_iso === "string" ||
    typeof obj.lang_iso === "string"; // Adjusted to participant_lang_iso
  const validParticipantCountry =
    typeof obj.participant_country_iso === "string"; // Adjusted to participant_lang_iso
  const validParticipantFlagged = typeof obj.flagged === "boolean"; // Adjusted to participant_lang_iso
  const validParticipantUnread =
    typeof obj.unread === "number" || obj.unread === undefined; // Adjusted to participant_lang_iso
  // this needs to be an array of strings
  const validParticipantTags =
    Array.isArray(obj.participant_tags) &&
    obj.participant_tags.every((tag: any) => typeof tag === "string");

  const allValid =
    validID &&
    validParticipantName &&
    validParticipantEmail &&
    validParticipantLanguage &&
    validParticipantCountry &&
    validParticipantFlagged &&
    validParticipantUnread &&
    validParticipantTags;

  if (!allValid) {
    // //console.log("Invalid participant object", obj);
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validParticipantName) {
      if (!obj.hasOwnProperty("sender_name")) {
        errors.push("missing sender_name");
      } else {
        errors.push(`Invalid type for sender_name`);
      }
    }

    if (!validParticipantEmail) {
      if (!obj.hasOwnProperty("participant_email")) {
        errors.push("missing participant_email");
      } else {
        errors.push(`Invalid type for participant_email`);
      }
    }

    if (!validParticipantLanguage) {
      if (!obj.hasOwnProperty("participant_lang_iso")) {
        errors.push("missing participant_lang_iso");
      } else {
        errors.push(`Invalid type for participant_lang_iso`);
      }
    }

    if (!validParticipantCountry) {
      if (!obj.hasOwnProperty("participant_country_iso")) {
        errors.push("missing participant_country_iso");
      } else {
        errors.push(`Invalid type for participant_country_iso`);
      }
    }

    if (!validParticipantFlagged) {
      if (!obj.hasOwnProperty("flagged")) {
        errors.push("missing flagged");
      } else {
        errors.push(`Invalid type for flagged`);
      }
    }

    if (!validParticipantUnread) {
      if (!obj.hasOwnProperty("unread")) {
        errors.push("missing unread");
      } else {
        errors.push(`Invalid type for unread`);
      }
    }

    if (!validParticipantTags) {
      if (!obj.hasOwnProperty("participant_tags")) {
        errors.push("missing participant_tags");
      } else {
        errors.push(`Invalid type for participant_tags`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid Channel Participant`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return allValid;
};
// #endregion isChannelParticipants

// #region isUnassignedChannelParticipants
const isUnassignedChannelParticipants = (obj: any): obj is Participant => {
  if (obj == null) {
    return false;
  }

  const validID = typeof obj.id === "string";
  const ValidSenderName = typeof obj.sender_name === "string";
  const ValidParticipantEmail = typeof obj.participant_email === "string";
  const ValidParticipantCountry =
    typeof obj.participant_country_iso === "string";
  const ValidParticipantLang = typeof obj.participant_lang_iso === "string";
  const ValidParticipantTags =
    Array.isArray(obj.participant_tags) &&
    obj.participant_tags.every((tag: any) => typeof tag === "string");

  const allValid =
    validID &&
    ValidSenderName &&
    ValidParticipantLang &&
    ValidParticipantEmail &&
    ValidParticipantCountry &&
    ValidParticipantTags;

  if (!allValid) {
    //console.log("Invalid channel object");
    //console.log(obj);
    let errors = [];
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!ValidSenderName) {
      if (!obj.hasOwnProperty("sender_name")) {
        errors.push("missing sender_name");
      } else {
        errors.push(`Invalid type for sender_name`);
      }
    }

    if (!ValidParticipantLang) {
      if (!obj.hasOwnProperty("participant_lang_iso")) {
        errors.push("missing participant_lang_iso");
      } else {
        errors.push(`Invalid type for participant_lang_iso`);
      }
    }

    if (!ValidParticipantEmail) {
      if (!obj.hasOwnProperty("participant_email")) {
        errors.push("missing participant_email");
      } else {
        errors.push(`Invalid type for participant_email`);
      }
    }

    if (!ValidParticipantCountry) {
      if (!obj.hasOwnProperty("participant_country_iso")) {
        errors.push("missing participant_country_iso");
      } else {
        errors.push(`Invalid type for participant_country_iso`);
      }
    }

    if (!ValidParticipantTags) {
      if (!obj.hasOwnProperty("participant_tags")) {
        errors.push("missing participant_tags");
      } else {
        errors.push(`Invalid type for participant_tags`);
      }
    }

    const displayMessage = `<strong>Please contact support</strong><br /><br />`;

    Swal.fire({
      icon: "error",
      title: `Invalid Channel Participant`,
      html: displayMessage + errors.join("<br />"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return allValid;
};
// #endregion isUnassignedChannelParticipants

// #endregion type validators

// #region fetchAllChannels
// Channel Related Data functionality
// Fetching all the channels in the study
export async function fetchAllChannels(
  studyID: string | undefined,
  clientID: string | undefined
): Promise<Channel[] | string> {
  try {
    const response = await apiFetcher<unknown>("/channel/get/all", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID
      }
    });

    // //console.log(response);

    if (response.status === 200 && response.data !== null) {
      if (
        response &&
        response.data &&
        Array.isArray(response.data) &&
        response.data.every(isChannel)
      ) {
        return response.data;
      } else {
        //console.error("Invalid channel data");
        // Depending on the function's expected return type, you might throw an error instead
        return "Invalid channel data";
      }
    } else {
      //console.log("Failed to fetch all channels");
      return "Failed to fetch all channels";
    }
  } catch (error) {
    //console.log(error);
    return "Failed to fetch all channels";
  }
}
// #endregion fetchAllChannels

// #region fetchParticipantsNotInChannel
// Fetching all the participants not in a channel
export const fetchParticipantsNotInChannel = async (
  clientID: string,
  channelID: string,
  studyID: string
): Promise<UnassignedParticipant[] | string | undefined> => {
  try {
    const response = await apiFetcher<any>(
      "/channel/participant/excluded",
      "POST",
      {
        body: {
          clientID: clientID,
          channelID: channelID,
          studyID: studyID
        }
      }
    );

    // //console.log(response);

    if (response.status === 200 && response.data !== null) {
      if (
        response &&
        response.data &&
        Array.isArray(response.data) &&
        response.data.every(isUnassignedChannelParticipants)
      ) {
        // Map the response to an array of Channels
        const excludedParticipants: UnassignedParticipant[] = response.data.map(
          (participant: any): UnassignedParticipant => ({
            id: participant.id, // Convert the 'id' to a string to match the Channel type
            sender_name: participant.sender_name,
            participant_email: participant.participant_email,
            participant_lang_iso: participant.participant_lang_iso,
            participant_country_iso: participant.participant_country_iso,
            participant_tags: participant.participant_tags
          })
        );

        // //console.log(participants, "participants");
        // Assuming this function is synchronous, otherwise you might need to return a Promise<Channel[]>
        return excludedParticipants;
      }
      //   return response.data;
    } else {
      //console.log("Failed to fetch all participants not in channel");
      return "Failed to fetch all participants not in channel";
    }
  } catch (error: any) {
    //console.error(error);
    return error.toString();
  }
};
// #endregion fetchParticipantsNotInChannel

// #region createChannel
// Create a new channel in the study
export const createChannel = async (
  clientID: string,
  studyID: string,
  channelName: string,
  channelType: string,
  channelThumbnail: string,
  originalImgName: string
) => {
  try {
    const response = await apiFetcher<any[]>("/channel/create", "POST", {
      body: {
        clientID: clientID,
        studyID: studyID,
        channelName: channelName,
        channelType: channelType,
        channelThumbnail: channelThumbnail,
        originalImageName: originalImgName
      }
    });

    //console.log(response);

    if (response && response.status === 200) {
      return response;
    }
  } catch (error) {
    //console.log(error);
    return error;
  }
};
// #endregion createChannel

// #region updateChannel
export const updateChannel = async (
  clientID: string,
  channelID: string,
  studyID: string,
  channelName: string,
  channelType: string
) => {
  try {
    const response = await apiFetcher<any[]>("/channel/update", "POST", {
      body: {
        clientID: clientID,
        channelID: channelID,
        studyID: studyID,
        channelName: channelName,
        channelType: channelType
      }
    });
    // //console.log(response);

    if (response && response.status === 202) {
      return response;
    } else {
      //console.log("Channel was not updated");
      return "Channel was not updated"; // This should be a FetchResponseError object
    }
  } catch (error) {
    //console.error(error);
    return "Failed to update channel"; // This should be a FetchResponseError object
  }
};
// #endregion updateChannel

// #region deleteChannel
export const deleteChannel = async (
  clientID: string,
  channelID: string,
  studyID: string
) => {
  try {
    const response = await apiFetcher<any[]>("/channel/remove", "POST", {
      body: {
        clientID: clientID,
        channelID: channelID,
        studyID: studyID
      }
    });
    //console.log(response);

    if (response && response.status === 202) {
      return response;
    } else {
      //console.log("Channel was not deleted");
      return "Channel was not deleted"; // This should be a FetchResponseError object
    }
  } catch (error) {
    //console.error(error);
    return "Failed to delete channel"; // This should be a FetchResponseError object
  }
};
// #endregion deleteChannel

// #region fetchChannelMessages
// Channel Message Related Data functionality
// Fetching all messages in a channel
export const fetchChannelMessages = async (
  clientID: string,
  channelID: string,
  studyID: string,
  participantID?: string | null
): Promise<ChannelMessages[] | undefined | null> => {
  try {
    const response: any = await apiFetcher<any>(
      "/channel/messages/get/all",
      "POST",
      {
        body: {
          clientID: clientID,
          channelID: channelID,
          studyID: studyID,
          participantID: participantID
        }
      }
    );

    // //console.log(response, "response");

    if (response.status === 200 || response.status === 204) {
      if (
        response &&
        response.data &&
        Array.isArray(response.data) &&
        response.data.every(isChannelMessages)
      ) {
        // Map the response to an array of Channels
        const messages: ChannelMessages[] = response.data.map(
          (message: any): ChannelMessages => ({
            id: message.id, // Convert the 'id' to a string to match the Channel type
            message: message.message_content,
            attachmentUrl: message.attachment_key,
            channel_id: message.channel_id,
            createdAt: message.created_at,
            updated_at: message.updated_at,
            flagged: message.flagged,
            senderName: message.sender_name,
            pinned: message.pinned,
            seen: message.seen,
            parentID: message.parent_id,
            researcherID: message.researcher_id,
            participantID: message.participant_id,
            type: message.type,
            sender: message.sender,
            deleted: message.deleted,
            edited: message.edited
          })
        );

        // //console.log(participants, "participants");
        // Assuming this function is synchronous, otherwise you might need to return a Promise<Channel[]>
        return messages;
      }
    } else {
      throw new Error("Response data is not as expected or null");
    }
  } catch (error: any) {
    //console.log("Fetch error:", error);
    return error.message || "Failed to fetch channel data";
  }
};
// #endregion fetchChannelMessages

// #region sendMessageInChannel
export const sendMessageInChannel = async (
  clientID: string,
  channelID: string,
  participantID: string | null,
  studyID: string,
  message?: string,
  attachmentKey?: string,
  messageID?: string | null
) => {
  try {
    const response = await apiFetcher<any[]>(
      "/channel/message/researcher/create",
      "POST",
      {
        body: {
          clientID: clientID,
          channelID: channelID,
          participantID: participantID,
          studyID: studyID,
          message: message,
          attachmentKey: attachmentKey,
          messageID: messageID // This will be used for replying to a message
        }
      }
    );
    // //console.log(response);
    if (response.status === 200) {
      return response;
    } else {
      //console.error("Failed to send message in channel");
      return "Failed to send message in channel"; // This should be a FetchResponseError object
    }
  } catch (error) {
    //console.error(error);
    return "Failed to send message in channel"; // This should be a FetchResponseError object
  }
};
// #endregion sendMessageInChannel

// #region deleteChatMessage
export const deleteChatMessage = async (
  client: string,
  study: string,
  channel: string,
  // participant?: number,
  message: string
  // topic?: string | null
) => {
  try {
    const response = await apiFetcher<any[]>(
      "/channel/message/remove",
      "POST",
      {
        body: {
          clientID: client,
          studyID: study,
          channelID: channel,
          messageID: message
        }
      }
    );
    // //console.log(response);

    if (response && response.status === 202) {
      return response;
    } else {
      //console.error("Failed to delete message");
      return "Failed to delete message"; // This should be a FetchResponseError object
    }
  } catch (error) {
    //console.error(error);
    return "Failed to delete message"; // This should be a FetchResponseError object
  }
};
// #endregion deleteChatMessage

// #region editChatMessage
export const editChatMessage = async (
  client: string,
  study: string,
  channel: string,
  messageID: string,
  updatedMessage?: string,
  // attachmentKey?: string | null,
  topicChat?: string | null
) => {
  try {
    const response = await apiFetcher<any[]>(
      "/channel/message/update",
      "POST",
      {
        body: {
          clientID: client,
          studyID: study,
          channelID: channel,
          messageID: messageID,
          message: updatedMessage,
          // attachmentKey: attachmentKey,
          topicChat: topicChat
        }
      }
    );
    // //console.log(response);

    if (response && response.status === 202) {
      return response;
    } else {
      //console.error("Failed to edit message");
      return "Failed to edit message"; // This should be a FetchResponseError object
    }
  } catch (error) {
    //console.error(error);
    return "Failed to edit message"; // This should be a FetchResponseError object
  }
};
// #endregion editChatMessage

// #region pinChannelMessage
export const pinChannelMessage = async (
  clientID: string,
  channelID: string,
  messageID: string,
  studyID: string,
  participantID?: string | null
) => {
  try {
    const response = await apiFetcher<any>("/channel/message/pin", "POST", {
      body: {
        clientID: clientID,
        channelID: channelID,
        messageID: messageID,
        studyID: studyID,
        participantID: participantID
      }
    });
    //console.log(response);
    return response;
  } catch (error) {
    //console.log(error);
    return error;
  }
};
// #endregion pinChannelMessage

// #region fetchChannelParticipants
// Channel Participant Related Data functionality
// Fetching all participants in a channel
export const fetchChannelParticipants = async (
  clientID: string,
  channelID: string,
  studyID: string
): Promise<ChannelParticipants[] | undefined> => {
  try {
    const response = await apiFetcher<any>(
      "/channel/get/participants",
      "POST",
      {
        body: {
          clientID: clientID,
          channelID: channelID,
          studyID: studyID
        }
      }
    );

    // //console.log(response, "response");

    if (response.status === 200 && response.data) {
      if (
        response &&
        response.data &&
        Array.isArray(response.data) &&
        response.data.every(isChannelParticipants)
      ) {
        // Map the response to an array of Channels
        const participants: ChannelParticipants[] = response.data.map(
          (participant): ChannelParticipants => ({
            id: String(participant.id), // Convert the 'id' to a string to match the Channel type
            sender_name: participant.sender_name,
            participant_email: participant.participant_email,
            participant_lang_iso: participant.participant_lang_iso,
            participant_country_iso: participant.participant_country_iso,
            flagged: participant.flagged,
            unread: participant.unread,
            last_seen: participant.last_seen,
            participant_tags: participant.participant_tags
          })
        );

        // //console.log(participants, "participants");
        // Assuming this function is synchronous, otherwise you might need to return a Promise<Channel[]>
        return participants;
      }
    } else {
      throw new Error("Response data is not as expected or null");
    }
  } catch (error: any) {
    //console.log("Fetch error:", error);
    return error.message || "Failed to fetch channel data";
  }
};
// #endregion fetchChannelParticipants

// #region addParticipantToChannel
export const addParticipantToChannel = async (
  clientID: string,
  channelID: string,
  participant: string[],
  studyID: string
): Promise<any> => {
  try {
    //console.log(participant);
    // The participant will need to be an array of ids, there can be multiple participants added at once or just one
    const response = await apiFetcher<any>("/channel/participant/add", "POST", {
      body: {
        clientID: clientID,
        channelID: channelID,
        participantID: participant,
        studyID: studyID
      }
    });

    //console.log(response);
    if (response.status === 200) {
      //console.log(response);
      if (
        !response.data ||
        response.data === null ||
        response.data === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // //console.log("response.data.id", response.data.jobID);

      //console.log(response.data.jobID, "response.data");
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "All participants added successfully to worker"
      };
    }
  } catch (error) {
    //console.log(error);
    return error;
  }
};
// #endregion addParticipantToChannel

// #region removeParticipantFromChannel
export const removeParticipantFromChannel = async (
  client: string,
  channel: string,
  participant: string[],
  study: string
): Promise<any> => {
  try {
    //console.log(participant);
    // The participant will need to be an array of ids, there can be multiple participants added at once or just one
    const response = await apiFetcher<any>(
      "/channel/participant/remove",
      "POST",
      {
        body: {
          clientID: client,
          channelID: channel,
          participantID: participant,
          studyID: study
        }
      }
    );
    if (response.status === 200) {
      //console.log(response);

      // return response;
      if (
        !response.data.data ||
        response.data.data === null ||
        response.data.data === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      //console.log("response.data.id", response.data.data);

      localStorage.setItem("workerID", response.data.data);
      return {
        rStatus: "success",
        rMessage: "All participants added successfully to worker"
      };
    }
  } catch (error) {
    //console.log(error);
    return error;
  }
};
// #endregion removeParticipantFromChannel

// #region flagChannelParticipant
export const flagChannelParticipant = async (
  client: string,
  channel: string,
  study: string,
  participant: string
) => {
  try {
    const response = await apiFetcher<any>(
      "/channel/participant/flagged",
      "POST",
      {
        body: {
          clientID: client,
          channelID: channel,
          studyID: study,
          participantID: participant
        }
      }
    );

    if (response.status === 202) {
      //console.log(response);
      return response;
    }
  } catch (error) {
    //console.log(error);
    return error;
  }
};
// #endregion flagChannelParticipant

// #region createUploadLink
export const createUploadLink = async (
  client: string,
  study: string,
  channel: string,
  survey: string | undefined,
  fileName: string
) => {
  //console.log(client, study, channel, survey, fileName);
  try {
    const response = await apiFetcher<any[]>(
      "/channel/message/get/attachmentsLink",
      "POST",
      {
        body: {
          clientId: client,
          studyId: study,
          channelID: channel,
          surveyName: survey,
          itemKey: fileName
        }
      }
    );
    //console.log(response);
    return response;
  } catch (error) {
    //console.log(error);
    return error;
  }
};
// #endregion createUploadLink

// #region uploadFile
export const uploadFile = async (url: string, file: File) => {
  //console.log(url);
  try {
    // create
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type
      },
      body: file
    });

    if (response.ok) {
      const result = await response.json();
      //console.log(result);
      return result;
    } else {
      // Handle the error if the response status is not OK
      const error = await response.json();
      //console.error("Upload failed:", error);
      throw new Error("Upload failed");
    }
  } catch (error) {
    //console.log(error);
    return error;
  }
};
// #endregion uploadFile
