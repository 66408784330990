import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  fetchAllParticipantHistoryById,
  fetchSingleParticipantByID,
  sendEditedParticipant
} from "../../models/participant.model";
import ErrorPage from "../../pages/Error/error.page";
import {
  Country,
  Language,
  Participant,
  ParticipantHistory,
  Timezone
} from "../../types";
import "./viewParticipantModal.scss";
import ViewParticipantModalFooter from "./viewParticipantModalFooter";
import ViewParticipantModalForm from "./viewParticipantModalForm";
import ViewParticipantModalHeader from "./viewParticipantModalHeader";
import ViewParticipantModalHistory from "./viewParticipantModalHistory.component";
import ViewParticipantModalTable from "./viewParticipantModalTable";
import ViewParticipantModalVariables from "./viewParticipantModalVariables.component";

interface ViewParticipantModalProps {
  participantID: string;
  closeParticipantModal: () => void;
  jsonLanguagesData: Language[];
  jsonCountriesData: Country[];
  jsonTimezonesData: Timezone[];
  shown: boolean;
  tableType: string;
  clientID: string;
  surveyID: string | undefined;
  studyID: string | undefined;
}

const ViewParticipantModal: React.FC<ViewParticipantModalProps> = ({
  participantID,
  closeParticipantModal,
  jsonLanguagesData,
  jsonCountriesData,
  jsonTimezonesData,
  shown,
  tableType,
  clientID,
  surveyID,
  studyID
}) => {
  // find participant by id
  const [participant, setParticipant] = useState<Participant | undefined>(
    undefined
  );
  // timezones
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);
  const [loadingHistory, setLoadingHistory] = useState<boolean>(true);
  const [loadingHistoryError, setLoadingHistoryError] =
    useState<boolean>(false);

  const [editing, setEditing] = useState<boolean>(false);
  const [viewParticipantVariables, setViewParticipantVariables] =
    useState<boolean>(false);
  const [viewParticipantHistory, setViewParticipantHistory] =
    useState<boolean>(false);
  const [participantHistory, setParticipantHistory] =
    useState<ParticipantHistory>([]);
  const [cheaterScore, setCheaterScore] = useState<number>(0);

  const [participationScore, setParticipationScore] = useState<number>(0);

  const [participantName, setParticipantName] = useState<string>("");
  const [participantSurName, setParticipantSurName] = useState<string>("");
  const [participantMobile, setParticipantMobile] = useState<string>("");
  const [participantMobileCode, setParticipantMobileCode] =
    useState<string>("");
  const [participantCountryID, setParticipantCountryID] = useState<string>("");
  const [participantLanguageID, setParticipantLanguageID] =
    useState<string>("");
  const [participantTimezone, setParticipantTimezone] = useState<string>("");
  const [participantTags, setParticipantTags] = useState<string[]>([]);
  // password
  const [participantPassword, setParticipantPassword] = useState<string>("");

  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  // const fetchClientsData = async () => {
  //   try {
  //     const jsonData = await fetchAllClients();
  //     // if jsonData is a string, then there was an error
  //     if (typeof jsonData === "string") {
  //       // console.log("jsonData is a string");
  //       // console.log("jsonData", jsonData);
  //       setLoadingError(true); // Set loadingError to true in case of an error
  //     } else {
  //       // console.log("jsonData is an object");
  //       // console.log("jsonData", jsonData);
  //       setClients(jsonData);
  //     }
  //   } catch (error) {
  //     console.error(
  //       "An error occurred while fetching participant data:",
  //       error
  //     );
  //     setLoadingError(true); // Set loadingError to true in case of an error
  //   }
  //   setLoading(false);
  // };

  // const promises = [fetchClientsData()];

  // Promise.all(promises)
  //   .then(() => {
  //     // All promises completed successfully
  //     setLoading(false);
  //   })
  //   .catch(() => {
  //     // At least one promise failed
  //     setLoading(false);
  //   });

  useEffect(() => {
    // Fetch participant data from JSON
    // console.log("participantID", participantID);
    const fetchParticipantData = async () => {
      setLoading(true);
      setLoadingError(false);
      setParticipant(undefined);
      setLoadingErrorText([]);
      try {
        const jsonData = await fetchSingleParticipantByID(
          clientID,
          participantID
        );
        setLoading(false);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "26d9e6a03c23f8cc007020e03cb49d8b"
          ]);
        } else {
          setParticipant(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "9df296ff529ec382fcdbacb04120a62f"
        ]);
        setLoading(false);
      }
    };

    // fetch the participant history
    const fetchParticipantHistory = async () => {
      setLoadingHistory(true);
      setLoadingHistoryError(false);
      setParticipantHistory([]);
      try {
        const jsonData = await fetchAllParticipantHistoryById(
          clientID,
          participantID
        );
        setLoadingHistory(false);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingHistoryError(true); // Set loadingError to true in case of an error
        } else {
          setParticipantHistory(jsonData.rData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant history:",
          error
        );
        setLoadingHistoryError(true); // Set loadingError to true in case of an error
        setLoadingHistory(false);
      }
    };

    fetchParticipantData();
    fetchParticipantHistory();
  }, [clientID, participantID]);

  const handleSave = () => {
    let errors = [];

    if (participantName === "") {
      errors.push("Participant Name is required");
    } else if (participantName.length > 255) {
      errors.push("Participant Name must be less than 255 characters");
    }

    if (participantSurName === "") {
      errors.push("Participant Surname is required");
    } else if (participantSurName.length > 255) {
      errors.push("Participant Surname must be less than 255 characters");
    }

    if (participantCountryID === "") {
      errors.push("Participant Country is required");
    }

    if (participantLanguageID === "") {
      errors.push("Participant Language is required");
    }

    if (participantTimezone === "") {
      errors.push("Participant Timezone is required");
    }

    if (cheaterScore > 100 || cheaterScore < 0) {
      errors.push("Cheater Score must be between 0 and 100");
    } else if (cheaterScore === null) {
      errors.push("Cheater Score must be a number");
    }

    if (participationScore > 100 || participationScore < 0) {
      errors.push("Participation Score must be between 0 and 100");
    } else if (participationScore === null) {
      errors.push("Participation Score must be a number");
    }

    if (participantPassword === " ") {
      errors.push(
        "Participant Password cannot be an empty space, please enter a valid password or leave it empty"
      );
    }

    // Participant password is allowed to be empty, it isn't updated if it is empty
    if (
      participantPassword.length > 0 &&
      (participantPassword.length < 6 || participantPassword.length > 255)
    ) {
      errors.push("Participant Password must be between 6 and 255 characters");
    }

    if (errors.length > 0) {
      let displayMessage = `<strong>Please correct the following errors:</strong><br><br>`;
      displayMessage += errors.join("<br>");

      Swal.fire({
        icon: "error",
        title: `Invalid Participant`,
        html: displayMessage,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return;
    } else {
      const sendingParticipantUpdate = async () => {
        const participantIDs: string = participantID;
        try {
          const response = await sendEditedParticipant(
            participantIDs,
            clientID,
            studyID,
            surveyID,
            // participant Fields
            participantName,
            participantSurName,
            participantMobile,
            participantMobileCode,
            participantCountryID,
            participantLanguageID,
            participantTimezone,
            participationScore,
            cheaterScore,
            participantTags,
            participantPassword.trim()
          );

          if (response.rStatus !== "success") {
            console.log("response: ", response);
            const errors = response.serverDataErrors?.join("<br>");
            Swal.fire({
              icon: "error",
              title: "Error updating participant",
              html: errors ? errors : response.rMessage,
              confirmButtonColor: "#3085d6"
            });
          } else {
            Swal.fire({
              icon: "success",
              title: "Participant updated successfully",
              showConfirmButton: true,
              confirmButtonColor: "#3085d6",
              // add refresh page button
              showDenyButton: true,
              denyButtonColor: "#3085d6",
              denyButtonText: "Refresh Page"
            }).then((result) => {
              if (result.isDenied) {
                window.location.reload();
              }
            });
            setEditing(false);
          }
        } catch (error) {
          setLoadingError(true);
          console.error(
            "An error occurred while sending participant update:",
            error
          );
          Swal.fire({
            icon: "error",
            title: "Error updating participant",
            text: `An error occurred while updating participant: ${participantIDs}`
          });
        }
      };

      const promises = [sendingParticipantUpdate()];

      Promise.all(promises)
        .then(() => {
          // All promises completed successfully
        })
        .catch(() => {
          // At least one promise failed
          console.log("At least one promise failed");
        });
    }
  };

  const handleCancel = () => {
    // rest participant variables

    setEditing(false);
  };

  return (
    <div className={`modal view_participant_modal ${shown ? "show" : "hide"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span
            className="close"
            onClick={() => {
              setEditing(false);
              closeParticipantModal();
            }}
          >
            &times;
          </span>
          <div className="container-fluid">
            <h5 className="modal_page_header">
              Participant{" "}
              {!viewParticipantVariables && !viewParticipantHistory
                ? "Profile"
                : viewParticipantVariables
                  ? "Variables"
                  : "History"}
            </h5>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : !loadingError && participant ? (
            <>
              {/* Display top section */}
              <ViewParticipantModalHeader
                participant={participant}
                jsonCountriesData={jsonCountriesData}
              />
              {!editing ? (
                // If not editing display table with participant data

                <>
                  {!viewParticipantVariables && !viewParticipantHistory ? (
                    <ViewParticipantModalTable
                      participant={participant}
                      jsonCountriesData={jsonCountriesData}
                      jsonLanguagesData={jsonLanguagesData}
                      jsonTimezonesData={jsonTimezonesData}
                    />
                  ) : null}
                  {viewParticipantVariables ? (
                    <ViewParticipantModalVariables
                      variables={participant.gsi_answers}
                    />
                  ) : null}
                  {viewParticipantHistory ? (
                    <ViewParticipantModalHistory
                      history={participantHistory}
                      participantTimeZone={participant.participant_timezone}
                      allTimezones={jsonTimezonesData}
                    />
                  ) : null}
                </>
              ) : (
                // If editing display form with participant data
                <ViewParticipantModalForm
                  participant={participant}
                  editing={editing}
                  countries={jsonCountriesData}
                  languages={jsonLanguagesData}
                  timezones={jsonTimezonesData}
                  // participant fields
                  participantName={participantName}
                  setParticipantName={setParticipantName}
                  participantSurName={participantSurName}
                  setParticipantSurName={setParticipantSurName}
                  participantMobile={participantMobile}
                  participantMobileCode={participantMobileCode}
                  setParticipantMobileCode={setParticipantMobileCode}
                  setParticipantMobile={setParticipantMobile}
                  participantCountryID={participantCountryID}
                  setParticipantCountryID={setParticipantCountryID}
                  participantLanguageID={participantLanguageID}
                  setParticipantLanguageID={setParticipantLanguageID}
                  participantTimezone={participantTimezone}
                  setParticipantTimezone={setParticipantTimezone}
                  participantTags={participantTags}
                  setParticipantTags={setParticipantTags}
                  participantPassword={participantPassword}
                  setParticipantPassword={setParticipantPassword}
                  // participant scores
                  cheaterScore={cheaterScore}
                  setCheaterScore={setCheaterScore}
                  participationScore={participationScore}
                  setParticipationScore={setParticipationScore}
                />
              )}
              {/* Display bottom section buttons */}
              <ViewParticipantModalFooter
                editing={editing}
                viewingVariables={viewParticipantVariables}
                viewingHistory={viewParticipantHistory}
                loadingHistory={loadingHistory}
                loadingHistoryError={loadingHistoryError}
                handleSave={handleSave}
                handleCancel={handleCancel}
                setEditing={setEditing}
                setViewParticipantVariables={setViewParticipantVariables}
                setViewParticipantHistory={setViewParticipantHistory}
              />
            </>
          ) : (
            <ErrorPage
              errorCode="404"
              error="Participant cannot be loaded"
              debugCode={loadingErrorText}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewParticipantModal;
