import React, { useState } from "react";

import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  fetchS3KeyForEQ,
  uploadingToS3
} from "../../../../models/survey.model";
import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldDropDown from "../../Fields/fieldDropdown.component";
import FieldDropDownWithSearch from "../../Fields/fieldDropdownWithSearch.component";
import FieldText from "../../Fields/fieldText.component";
import { AddStudyWizardStepSingleConfigurationProps } from "../addStudyWizard.component";
import AddStudyWizardFooter from "../addStudyWizardFooter.component";

interface StepSingleConfigurationProps
  extends AddStudyWizardStepSingleConfigurationProps {}

// export interface AddStudyWizardStepSingleConfigurationProps extends AddStudyWizardProps {
//   singleSurveySystem: string;
//   singleSurveySystemDetails: string;
// }

const StepSingleConfiguration: React.FC<StepSingleConfigurationProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  handleWizardBack,
  handleInputChange,
  handleWizardSubmit,
  pageValid,
  // Fields
  singleSurveySystem,
  singleSurveySystemDetails,
  setSingleSurveySystemDetails,
  wscSurveyOptionsData,
  wscSurveysData,
  inputErrors,
  // Test Link
  singleTestLinkIncluded,
  singleTestSurveySystem,
  singleTestSurveySystemDetails,
  setSingleTestSurveySystemDetails,

  singleSurveyFirst,
  surveyName
}) => {
  //Dropzone
  const { clientID } = useParams();
  const [files, setFiles] = useState<File[]>([]);
  const allowedFileTypes = ["video/mp4"];
  const [fileInfo, setFileInfo] = useState({
    name: "",
    type: ""
  });
  const [previewURLs, setPreviewURLs] = useState<string[]>([]);

  const onFilesAdded = async (acceptedFiles: File[]) => {
    //We should allow for Images(png, jpg , JPEG) Audio(mp3, wav) Videos (mp4) and PDFs
    // Validate file types
    const invalidFiles = acceptedFiles.filter(
      (file) => !allowedFileTypes.includes(file.type)
    );
    if (invalidFiles.length > 0) {
      Swal.fire({
        title: "Invalid File Type",
        text: "Please select a valid file type.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // Check file size
    const oversizedFiles = acceptedFiles.filter(
      (file) => file.size > MAX_FILE_SIZE
    );
    if (oversizedFiles.length > 0) {
      Swal.fire({
        title: "File Too Large",
        text: "Please select a file smaller than 2GB.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // replace " " with "_"
    acceptedFiles = acceptedFiles.map((file) => {
      // Create a new Blob instance from the original file data
      const blob = new Blob([file], { type: file.type });
      // Create a new File instance with the modified name and original file properties
      return new File([blob], file.name.replace(/ /g, "_"), {
        type: file.type,
        lastModified: file.lastModified
      });
    });

    setFiles(acceptedFiles);

    // Create previews
    const urls = acceptedFiles.map((file) => URL.createObjectURL(file));
    setPreviewURLs(urls);

    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setFileInfo({
          name: file.name,
          type: "video"
        });
      };
    }
  };
  const MAX_FILE_SIZE = 2 * 1024 * 1024 * 1024; // 2GB
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onFilesAdded,
    maxSize: MAX_FILE_SIZE,
    multiple: false
  });

  const uploadEQ = async (acceptedFiles: File[]) => {
    //get access key from server
    if (!clientID) {
      Swal.fire({
        title: "Client ID not found",
        text: "Please select a client ID.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    let fileName = acceptedFiles[0].name;
    if (acceptedFiles[0].name.length > 25) {
      const fileExtension = "." + acceptedFiles[0].type.split("/")[1];
      fileName = acceptedFiles[0].name.substring(0, 25) + fileExtension;
    }

    Swal.fire({
      title: "Uploading File",
      text: "Please wait...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      }
    });

    const key = await fetchS3KeyForEQ(clientID, fileName);

    if (key.statusCode === 200) {
      console.log(key.signedURL, acceptedFiles, "key");
      const uploadToS3 = await uploadingToS3(key.signedURL, acceptedFiles);

      if (uploadToS3 === true) {
        Swal.fire({
          title: "Success",
          text: "File uploaded successfully.",
          icon: "success",
          confirmButtonColor: "#3085d6"
        });

        setSingleSurveySystemDetails(key.key);
      } else {
        Swal.fire({
          title: "Error",
          text: "Error uploading file to server.",
          icon: "error",
          confirmButtonColor: "#3085d6"
        });
        return;
      }
    } else {
      Swal.fire({
        title: "Error",
        text: "Error fetching key from server.",
        icon: "error",
        confirmButtonColor: "#3085d6"
      });
    }
  };

  const onClickRemove = () => {
    setFiles([]);
    setPreviewURLs([]);
    setFileInfo({
      name: "",
      type: ""
    });
    setSingleSurveySystemDetails("");
  };
  return (
    <div className="card card-default add_study_form_page p-4">
      <h3 className="form_header">Single Configuration: {surveyName}</h3>
      <div className="add_study_form_page_inputs">
        <FieldDropDown
          label="Survey System"
          tooltip="The platform which will be linked for this survey - if you want to add a platform that's not Nfield or WSC, please use 'custom'."
          tooltipPosition="bottom"
          inputName={
            singleSurveyFirst
              ? "singleSurveySystemFirst"
              : "singleSurveySystemSecond"
          }
          inputValue={singleSurveySystem}
          inputOptions={wscSurveysData}
          onInputChange={handleInputChange}
        />
        {singleSurveySystem === "wsc" && (
          <FieldDropDownWithSearch
            label="WSC Survey"
            tooltip="Selection of the survey survey as it is listed / named within WSC. You can search and / or utilize the dropdown menu."
            inputName={
              singleSurveyFirst
                ? "singleSurveySystemDetailsFirst"
                : "singleSurveySystemDetailsSecond"
            }
            inputValue={singleSurveySystemDetails}
            inputOptions={wscSurveyOptionsData}
            onInputChange={handleInputChange}
            error={
              singleSurveyFirst
                ? inputErrors?.singleSurveySystemDetailsFirst
                  ? inputErrors.singleSurveySystemDetailsFirst
                  : null
                : inputErrors?.singleSurveySystemDetailsSecond
                  ? inputErrors.singleSurveySystemDetailsSecond
                  : null
            }
          />
        )}
        {singleSurveySystem === "nfield" && (
          <FieldText
            label="nField Survey"
            tooltip="This will be where you select the nField survey for the study"
            inputName={
              singleSurveyFirst
                ? "singleSurveySystemDetailsFirst"
                : "singleSurveySystemDetailsSecond"
            }
            inputValue={singleSurveySystemDetails}
            onInputChange={handleInputChange}
            error={
              singleSurveyFirst
                ? inputErrors?.singleSurveySystemDetailsFirst
                  ? inputErrors.singleSurveySystemDetailsFirst
                  : null
                : inputErrors?.singleSurveySystemDetailsSecond
                  ? inputErrors.singleSurveySystemDetailsSecond
                  : null
            }
          />
        )}
        {singleSurveySystem === "custom" && (
          <FieldText
            label="Custom Survey"
            tooltip="This will be where you select the custom survey for the study"
            inputName={
              singleSurveyFirst
                ? "singleSurveySystemDetailsFirst"
                : "singleSurveySystemDetailsSecond"
            }
            inputValue={singleSurveySystemDetails}
            onInputChange={handleInputChange}
            error={
              singleSurveyFirst
                ? inputErrors?.singleSurveySystemDetailsFirst
                  ? inputErrors.singleSurveySystemDetailsFirst
                  : null
                : inputErrors?.singleSurveySystemDetailsSecond
                  ? inputErrors.singleSurveySystemDetailsSecond
                  : null
            }
          />
        )}
        {singleSurveySystem === "viewEQ" && (
          <div className="upload">
            <div {...getRootProps()} className="chat-upload-dropzone">
              <input {...getInputProps()} />
              {files.length === 0 && (
                <small className="dropzone-text">
                  Drag 'n' drop here, or click to select a file
                  <br />
                  Accepted File Types: Video types (MP4)
                </small>
              )}
              {/* Updated previews display */}
              <div className="preview-container">
                {files.map((file, index) => {
                  if (file.type.startsWith("video")) {
                    return (
                      <video
                        key={index}
                        src={previewURLs[index]}
                        controls
                        className="video-preview"
                      ></video>
                    );
                  }
                })}
              </div>
            </div>
            {files.length > 0 && (
              <div className="upload-buttons">
                <button
                  className="btn-eq"
                  onClick={() => {
                    onClickRemove();
                  }}
                >
                  Remove
                </button>
                <button
                  className="btn-eq"
                  onClick={() => {
                    uploadEQ(files);
                  }}
                >
                  Upload
                </button>
              </div>
            )}
          </div>
        )}
        <FieldCheckbox
          label="Test Link Included"
          tooltip='Participants tagged as "Testing" will receive this test link instead of the live survey link.'
          inputName={
            singleSurveyFirst
              ? "singleTestLinkIncluded"
              : "singleTestLinkIncludedSecond"
          }
          inputValue={singleTestLinkIncluded}
          onInputChange={handleInputChange}
        />
        {singleTestLinkIncluded && (
          <>
            <FieldDropDown
              label="Test Link Survey System"
              tooltip="The platform which will be linked for this test link - if you want to add a platform that's not Nfield or WSC, please use 'custom'."
              inputName={
                singleSurveyFirst
                  ? "singleTestSurveySystem"
                  : "singleTestSurveySystemSecond"
              }
              inputValue={singleTestSurveySystem}
              inputOptions={wscSurveysData.filter(
                (survey) => survey.id !== "viewEQ"
              )}
              onInputChange={handleInputChange}
            />
            {singleTestSurveySystem === "wsc" ? (
              <FieldDropDownWithSearch
                label="WSC Survey"
                tooltip="Selection of the test link survey as it is listed / named within WSC. You can search and / or utilize the dropdown menu."
                inputName={
                  singleSurveyFirst
                    ? "singleTestSurveySystemDetails"
                    : "singleTestSurveySystemDetailsSecond"
                }
                inputValue={singleTestSurveySystemDetails}
                inputOptions={wscSurveyOptionsData}
                onInputChange={handleInputChange}
                error={
                  singleSurveyFirst
                    ? inputErrors?.singleTestSurveySystemDetails
                      ? inputErrors.singleTestSurveySystemDetails
                      : null
                    : inputErrors?.singleTestSurveySystemDetailsSecond
                      ? inputErrors.singleTestSurveySystemDetailsSecond
                      : null
                }
              />
            ) : singleTestSurveySystem === "nfield" ? (
              <FieldText
                label="nField Survey"
                tooltip="This will be where you select the nField survey for the test link"
                inputName={
                  singleSurveyFirst
                    ? "singleTestSurveySystemDetails"
                    : "singleTestSurveySystemDetailsSecond"
                }
                inputValue={singleTestSurveySystemDetails}
                onInputChange={handleInputChange}
                error={
                  singleSurveyFirst
                    ? inputErrors?.singleTestSurveySystemDetails
                      ? inputErrors.singleTestSurveySystemDetails
                      : null
                    : inputErrors?.singleTestSurveySystemDetailsSecond
                      ? inputErrors.singleTestSurveySystemDetailsSecond
                      : null
                }
              />
            ) : singleTestSurveySystem === "custom" ? (
              <FieldText
                label="Custom Survey"
                tooltip="This will be where you select the custom survey for the test link"
                inputName={
                  singleSurveyFirst
                    ? "singleTestSurveySystemDetails"
                    : "singleTestSurveySystemDetailsSecond"
                }
                inputValue={singleTestSurveySystemDetails}
                onInputChange={handleInputChange}
                error={
                  singleSurveyFirst
                    ? inputErrors?.singleTestSurveySystemDetails
                      ? inputErrors.singleTestSurveySystemDetails
                      : null
                    : inputErrors?.singleTestSurveySystemDetailsSecond
                      ? inputErrors.singleTestSurveySystemDetailsSecond
                      : null
                }
              />
            ) : null}
          </>
        )}

        <AddStudyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          handleWizardSubmit={handleWizardSubmit}
          pageValid={pageValid}
        />
      </div>
    </div>
  );
};

export default StepSingleConfiguration;
