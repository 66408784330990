import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { ResearcherPermission, ResearcherRole } from "../types";

// #region type validators

// #region isRole
function isRole(obj: any): obj is ResearcherRole {
  const validID = typeof obj.id === "string";
  const validName = typeof obj.name === "string";
  // const validPermissionsIDs =
  //   Array.isArray(obj.permissions_id) &&
  //   obj.permissions_id.every((id: any) => typeof id === "string") &&
  //   obj.permissions_id.length > 0;

  const AllValid = validID && validName;
  // && validPermissionsIDs;

  if (!AllValid) {
    console.log("---isParticipant---");
    console.log(obj);
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Participant`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Invalid type for name`);
      }
    }

    // if (!validPermissionsIDs) {
    //   if (!obj.hasOwnProperty("permissions_id")) {
    //     errors.push("missing permissions_id");
    //   } else if (
    //     !Array.isArray(obj.permissions_id) ||
    //     obj.permissions_id.length === 0
    //   ) {
    //     errors.push(`permissions_id is not an array or is empty`);
    //   } else {
    //     errors.push(`Invalid type for permissions_id`);
    //   }
    // }

    console.log(`Invalid roleID: ${obj.id}`);
    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `RoleID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Role`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return AllValid;
}
// #endregion isRole

// #region isPermission
function isPermission(obj: any): obj is ResearcherPermission {
  const validID = typeof obj.id === "string";
  const validName = typeof obj.permission === "string";
  const validDescription = typeof obj.permission_description === "string";

  const AllValid = validID && validName && validDescription;

  if (!AllValid) {
    console.log("---isPremission---");
    console.log(obj);
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Participant`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("permission")) {
        errors.push("missing permission");
      } else {
        errors.push(`Invalid type for permission`);
      }
    }

    if (!validDescription) {
      if (!obj.hasOwnProperty("permission_description")) {
        errors.push("missing permission_description");
      } else {
        errors.push(`Invalid type for permission_description`);
      }
    }

    console.log(`Invalid roleID: ${obj.id}`);
    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `PermissionID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Permission Role`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return AllValid;
}
// #endregion isPermission

// #region isValidPermissionsArray
function isValidPermissionsArray(
  permissions: any[]
): permissions is ResearcherPermission[] {
  return permissions.every(isPermission);
}
// #endregion isValidPermissionsArray

// #endregion type validators

// #region getAllRoles
export async function getAllRoles(): Promise<ResearcherRole[] | string> {
  const response = await apiFetcher<any>("/roles/get/all", "POST", {
    body: {}
  });

  console.log(response);
  if (response.status === 200 && response.data) {
    // There are roles
    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isRole)
    ) {
      return response.data;
    } else {
      return "Invalid Data Received";
    }
  } else if (response.status === 404 || response.status === 204) {
    console.log("No roles found");
    return [];
  } else if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Server Error`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Server Error";
  } else {
    return "Server Error";
  }
}
// #endregion getAllRoles

// #region getAllPermissions
export async function getAllPermissions(): Promise<
  { [key: string]: ResearcherPermission[] } | string
> {
  const response = await apiFetcher<any>("/permissions/get/all", "POST", {
    body: {}
  });

  if (response.status === 200 && response.data) {
    let validData = true;
    for (const key in response.data) {
      if (Array.isArray(response.data[key])) {
        if (!isValidPermissionsArray(response.data[key])) {
          validData = false;
          break;
        }
      } else {
        validData = false;
        break;
      }
    }

    if (validData) {
      return response.data;
    } else {
      return "Invalid Data Received";
    }
  } else if (response.status === 404 || response.status === 204) {
    console.log("No permissions found");
    return {};
  } else if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Server Error`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Server Error";
  } else {
    return "Server Error";
  }
}
// #endregion getAllPermissions

// #region getPermissionsByRoleID
// get permissions by role id
export async function getPermissionsByRoleID(
  roleID: string
): Promise<ResearcherPermission[] | string> {
  const response = await apiFetcher<any>("/roles/permissions/get/id", "POST", {
    body: {
      roleID: roleID
    }
  });

  console.log(response);
  if (response.status === 200 && response.data) {
    // There are roles
    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isPermission)
    ) {
      return response.data;
    } else {
      return "Invalid Data Received";
    }
  } else if (response.status === 404 || response.status === 204) {
    console.log("No roles found");
    return [];
  } else if (response.status === 500) {
    Swal.fire({
      icon: "error",
      title: `Server Error`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Server Error";
  } else {
    return "Server Error";
  }
}
// #endregion getPermissionsByRoleID

// #region sendCreatedRole
export async function sendCreatedRole({
  roleName,
  permissionsIDs
}: {
  roleName: string;
  permissionsIDs: string[];
}): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  const response = await apiFetcher<any>("/roles/create", "POST", {
    body: {
      role: {
        roleName: roleName,
        permissionsIDs: permissionsIDs
      }
    }
  });

  console.log(response);
  if (response.status === 200) {
    return {
      rStatus: "success",
      rMessage: "Role created successfully"
    };
  } else if (response.status === 400) {
    return {
      rStatus: "error",
      rMessage: "400 Server expecting different data"
    };
  } else if (response.status === 500) {
    return {
      rStatus: "error",
      rMessage: "500 Server returned an error"
    };
  } else if (response.status === 409) {
    console.log(response);
    return {
      rStatus: "error",
      rMessage: "A Role with these permissions already exists"
    };
  } else {
    return {
      rStatus: "error",
      rMessage: "Unknown Error"
    };
  }
}
// #endregion sendCreatedRole
