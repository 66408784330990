import React from "react";
import {
  convertEpochToStringTime,
  sortTodayEpoch
} from "../../../utilities/utils";

type ReportScheduledTimingsProps = {
  scheduledTimings: number[];
};

const ReportScheduledTimings: React.FC<ReportScheduledTimingsProps> = ({
  scheduledTimings
}) => {
  //   const [showAll, setShowAll] = useState<boolean>(false);
  //   const LIMIT = 5;
  return (
    <div className="">
      {!scheduledTimings || scheduledTimings.length === 0 ? (
        "Not Scheduled"
      ) : (
        <div className="multiple_scheduled_timings">
          {sortTodayEpoch(scheduledTimings).map((time, index) => (
            <div key={index} className="timing_span">
              {convertEpochToStringTime(time)}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ReportScheduledTimings;
