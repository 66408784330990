import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
// import { IconInvisible, IconVisible } from "../../assets/images/icons/icons";
import { Tag, fetchAllTags } from "../../models/tag.model";
import {
  Country,
  Language,
  ParamsSurveyPage,
  Participant,
  Timezone
} from "../../types";
// import Dropdown from "../Dropdown/dropdown.component";
import { IconInvisible, IconVisible } from "../../assets/images/icons/icons";
import RangeSlider from "../RangeSlider/rangeSlider.component";
import "./viewParticipantModal.scss";

interface ViewParticipantModalFormProps {
  participant: Participant;
  editing: boolean;
  languages: Language[];
  countries: Country[];
  timezones: Timezone[];
  // participant fields
  cheaterScore: number;
  participationScore: number;
  participantName: string;
  participantSurName: string;
  participantMobile: string;
  participantMobileCode: string;
  participantCountryID: string;
  participantLanguageID: string;
  participantTimezone: string;
  participantTags: string[];
  participantPassword: string | null;
  // Set participant fields
  setCheaterScore: (value: number) => void;
  setParticipationScore: (value: number) => void;
  setParticipantName: (value: string) => void;
  setParticipantSurName: (value: string) => void;
  setParticipantMobile: (value: string) => void;
  setParticipantMobileCode: (value: string) => void;
  setParticipantCountryID: (value: string) => void;
  setParticipantLanguageID: (value: string) => void;
  setParticipantTimezone: (value: string) => void;
  setParticipantTags: (value: string[]) => void;
  setParticipantPassword: (value: string) => void;
}

const ViewParticipantModalForm: React.FC<ViewParticipantModalFormProps> = ({
  participant,
  editing,
  languages,
  countries,
  timezones,
  // participant fields
  cheaterScore,
  participationScore,
  participantName,
  participantSurName,
  participantMobile,
  participantMobileCode,
  participantCountryID,
  participantLanguageID,
  participantTimezone,
  participantTags,
  participantPassword,
  // Set participant fields
  setCheaterScore,
  setParticipationScore,
  setParticipantName,
  setParticipantSurName,
  setParticipantMobile,
  setParticipantMobileCode,
  setParticipantCountryID,
  setParticipantLanguageID,
  setParticipantTimezone,
  setParticipantTags,
  setParticipantPassword
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [tags, setTags] = useState<Tag[]>([]);
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { clientID } = useParams<ParamsSurveyPage>();
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  /* --------------FETCHING DATA----------------- */
  const getTagData = async () => {
    try {
      setIsLoading(true);
      const data = await fetchAllTags(clientID as string);
      if (typeof data === "string") {
        setTags([]);
      } else {
        // Sort tags updated_at in descending order
        // data.sort((a, b) => {
        //   const dateA = new Date(a.updated_at);
        //   const dateB = new Date(b.updated_at);
        //   return dateB.getTime() - dateA.getTime();
        // });
        setTags(data);
      }
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTagData();
  }, []);

  /* -------------Functions----------------- */
  const handleTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "") {
      setShowDropdown(false);
    }

    // Regex for the tag name, no special characters except for "-" or "_", from 4 to 60 characters
    const tagRegex = /^[a-zA-Z0-9_-]{2,60}$/;

    // Add tags on comma
    if (event.target.value.includes(",")) {
      const newTags: string[] = event.target.value
        .split(",")
        .map((tag) => tag.trim())
        .filter(
          (tag) => tag && tagRegex.test(tag) && !participantTags.includes(tag)
        );

      if (newTags.length > 0) {
        (setParticipantTags as React.Dispatch<React.SetStateAction<string[]>>)(
          (prevTags: string[]) => [...prevTags, ...newTags]
        );
        event.target.value = ""; // Clear the input
        setError("");
      } else {
        setError(
          "Please enter a valid tag name from 2 to 60 characters with no special characters except for '-' or '_'"
        );
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Add tag on enter key
    // Regex for the tag name, no special characters except for "-" or "_", from 4 to 60 characters
    const tagRegex = /^[a-zA-Z0-9_-]{2,60}$/;

    setShowDropdown(true);
    setFilteredTags(
      tags.filter((tag) =>
        tag.name.toLowerCase().includes(event?.currentTarget?.value)
      )
    );

    if (tagRegex.test(event?.currentTarget?.value)) {
      if (event.key === "Enter" && inputRef.current?.value) {
        const newTag: string = inputRef.current.value.trim();
        if (newTag && !participantTags.includes(newTag)) {
          // Check if tag is unique
          (
            setParticipantTags as React.Dispatch<React.SetStateAction<string[]>>
          )((prevTags: string[]) => [...prevTags, newTag]);
        }
        inputRef.current.value = ""; // Clear the input
        event.preventDefault(); // Prevents form submission if this is inside a form
      }
      setError("");
    } else {
      setError(
        "Please enter a valid tag name from 2 to 60 characters with no special characters except for '-' or '_'"
      );
    }
  };

  //Handler for adding tags from dropdown
  const handleSelect = (tag: string) => {
    if (tag && !participantTags.includes(tag)) {
      // Check if tag is unique
      (setParticipantTags as React.Dispatch<React.SetStateAction<string[]>>)(
        (prevTags: string[]) => [...prevTags, tag]
      );
      setShowDropdown(false);
    } else {
      setShowDropdown(false);
    }
    //inputRef.current.value = "";
  };

  const removeTag = (tagToRemove: string) => {
    (setParticipantTags as React.Dispatch<React.SetStateAction<string[]>>)(
      (prevTags: string[]) =>
        prevTags.filter((tag: string) => tag !== tagToRemove)
    );
  };
  //This is for the dropdown
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && (event.target as Node)) {
        setShowDropdown(false);
      }
    };

    if (showDropdown) {
      window.addEventListener("click", handleClickOutside);
    }

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showDropdown]);

  // set participant variables
  useEffect(() => {
    setParticipantName(participant?.participant_name || "");
    setParticipantSurName(participant?.participant_surname || "");
    setParticipantMobile(participant?.participant_mobile_number || "");
    setParticipantMobileCode(participant?.participant_mobile_code || "");
    setParticipantCountryID(participant?.participant_country_iso || "");
    setParticipantLanguageID(participant?.participant_lang_iso || "");
    setParticipantTimezone(participant?.participant_timezone || "");
    setParticipantTags(participant?.participant_tags || []);

    // if (participant.edited_cheater_score) {
    //   setCheaterScore(participant.edited_cheater_score);
    // } else {
    //   setCheaterScore(participant?.cheater_score || 0);
    // }
    setCheaterScore(participant?.participant_cheating_score || 0);

    // if (participant.edited_rating_score) {
    //   setParticipationScore(participant.edited_rating_score);
    // } else {
    //   setParticipationScore(participant?.rating_score || 0);
    // }
    setParticipationScore(participant?.participant_score || 0);
  }, [participant]);

  const [participationBarClass, setParticipationBarClass] =
    useState<string>("");

  const [cheaterBarClass, setCheaterBarClass] = useState<string>("");

  // change the participant Participant score
  const handleParticipationScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setParticipationScore(+event.target.value);
  };

  // change the participant cheater score
  const handleCheaterScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheaterScore(+event.target.value);
  };

  // set the score bar colors
  useEffect(() => {
    let classValue;
    // set the Participant score colors
    // less than 25% = terrible
    // less than 50% = bad
    // less than 75% = warning
    // 75% or more = good
    const participationScoreGood = 75;
    const participationScoreWarning = 50;
    const participationScoreBad = 25;

    if (participationScore > participationScoreGood) {
      classValue = "score_bar_good";
    } else if (participationScore > participationScoreWarning) {
      classValue = "score_bar_warning";
    } else if (participationScore > participationScoreBad) {
      classValue = "score_bar_bad";
    } else {
      classValue = "score_bar_terrible";
    }

    setParticipationBarClass(classValue);
  }, [participationScore]);

  // set the score bar colors
  useEffect(() => {
    let classValue;
    // set the score bar colors
    // less than 25% = good
    // less than 50% = warning
    // less than 75% = bad
    // 75% or more = terrible
    const cheaterScoreGood = 25;
    const cheaterScoreWarning = 50;
    const cheaterScoreBad = 75;

    if (cheaterScore < cheaterScoreGood) {
      classValue = "score_bar_good";
    } else if (cheaterScore < cheaterScoreWarning) {
      classValue = "score_bar_warning";
    } else if (cheaterScore < cheaterScoreBad) {
      classValue = "score_bar_bad";
    } else {
      classValue = "score_bar_terrible";
    }

    setCheaterBarClass(classValue);
  }, [cheaterScore]);

  function handleChangeMobile(event: React.ChangeEvent<HTMLInputElement>) {
    // only allow numbers
    const re = /^[0-9\b]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setParticipantMobile(event.target.value);
    }
  }

  return (
    <div className="modal-body edit_participant_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-4">ID</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="_id"
              value={participant.id.toString()}
              disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Email</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="email"
              value={participant.participant_email}
              disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Mobile number</div>
          <div className="col-3 pe-0">
            {/* dropdown for country codes */}
            <select
              className="form-select form-control"
              value={participantMobileCode}
              onChange={(e) => setParticipantMobileCode(e.target.value)}
            >
              {
                // get country codes
                countries.map((country) => (
                  <option key={country.iso_code} value={country.country_code}>
                    {country.country_code}
                  </option>
                ))
              }
            </select>
          </div>
          <div className="col-5 ps-0">
            <input
              type="text"
              className="form-control"
              name="mobile_number"
              value={participantMobile}
              onChange={(e) => handleChangeMobile(e)}
              placeholder="Enter Mobile number"
              // disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Name</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="name"
              value={participantName}
              onChange={(e) => setParticipantName(e.target.value)}
              placeholder="Enter name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Surname</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="surname"
              value={participantSurName}
              onChange={(e) => setParticipantSurName(e.target.value)}
              placeholder="Enter surname"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Country</div>
          <div className="col-8">
            <select
              className="form-control"
              name="country_id"
              value={participantCountryID}
              onChange={(e) => setParticipantCountryID(e.target.value)}
            >
              <option value="">Select country</option>
              {countries.map((country) => (
                // if the country is the same as the participant's country, select it
                <option key={country.iso_code} value={country.iso_code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Language</div>
          <div className="col-8">
            <select
              className="form-control"
              name="language_id"
              value={participantLanguageID}
              onChange={(e) => setParticipantLanguageID(e.target.value)}
            >
              <option value="">Select language</option>
              {languages.map((language) => (
                // if the language is the same as the participant's language, select it
                <option key={language.iso_code} value={language.iso_code}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Timezone</div>
          <div className="col-8">
            <select
              className="form-control"
              name="timezone"
              value={participantTimezone}
              onChange={(e) => setParticipantTimezone(e.target.value)}
            >
              <option value="">Select timezone</option>
              {timezones.map((timezone) => (
                // if the timezone is the same as the participant's timezone, select it
                <option key={timezone.name} value={timezone.name}>
                  (
                  {timezone.offset > 0
                    ? "+" + timezone.offset
                    : timezone.offset}
                  ){" "}
                  {
                    // replace _ with space
                    timezone.name.replace(/_/g, " ")
                  }
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Participant score</div>
          <div className="col-8 participation_score_rating">
            <div className={`range ${participationBarClass}`}>
              <RangeSlider
                min={0}
                max={100}
                step={1}
                value={{ min: 0, max: participationScore }}
                onChange={(value) => {
                  setParticipationScore(value.max);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Cheater score</div>
          <div className="col-8 cheater_score_rating">
            <div className={`range ${cheaterBarClass}`}>
              <RangeSlider
                min={0}
                max={100}
                step={1}
                value={{ min: 0, max: cheaterScore }}
                onChange={(value) => {
                  setCheaterScore(value.max);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Tags</div>
          <div className="col-8">
            <div className="input-wrapper">
              Press "ENTER" or "," to add tag
              <input
                ref={inputRef}
                type="text"
                className="form-control"
                onChange={handleTagsChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter tags"
              />
              {showDropdown && !isLoading && filteredTags.length > 0 && (
                <div className="tag-dropdown" ref={dropdownRef}>
                  {filteredTags.map((tag) => (
                    <div
                      key={tag.id}
                      className="tag-dropdown-item"
                      onClick={() => handleSelect(tag?.name)}
                    >
                      {tag.name}
                    </div>
                  ))}
                </div>
              )}
              {participantTags.map((tag) => (
                <span key={tag} className="tag">
                  {tag}
                  <button
                    type="button"
                    className="closeTag"
                    onClick={() => removeTag(tag)}
                  >
                    X
                  </button>
                </span>
              ))}
            </div>
            {error && <div className="error_text">{error}</div>}
          </div>
        </div>
        <div className="row">
          <div className="col-4">Password</div>
          <div className="col-8 password_container">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              name="password"
              value={participantPassword || ""}
              onChange={(e) => setParticipantPassword(e.target.value)}
              placeholder="Enter new password"
            />
            <button
              type="button"
              className="btn_password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <IconVisible className="icon icon_black" />
              ) : (
                <IconInvisible className="icon icon_black" />
              )}
            </button>
          </div>
        </div>
        {/* <p>
          The min value is: <span>{value.min}</span>
        </p>
        <p>
          The max value is: <span>{value.max}</span>
        </p> */}
      </div>
    </div>
  );
};

export default ViewParticipantModalForm;
