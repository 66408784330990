// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.app_actions_dropdown.dropdown {
  position: absolute;
  right: 40px;
  background-color: white;
  z-index: 2;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  text-align: left;
  font-size: 14px;
  color: #1a2345;
}
.app_actions_dropdown.dropdown.hide {
  display: none;
}
.app_actions_dropdown.dropdown.show {
  display: block;
}
.app_actions_dropdown svg {
  width: 0.9em;
  height: 0.9em;
  margin-right: 2px;
  vertical-align: middle;
  transform: translateY(-0.1em);
}
.app_actions_dropdown .dropdown-item:hover {
  color: #3d52a2;
}
.app_actions_dropdown .dropdown-item:hover svg {
  fill: #3d52a2;
}`, "",{"version":3,"sources":["webpack://./src/components/Config/MobileApps/AppVersions/AppActions/appAction.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,WAAA;EAEA,uBAAA;EACA,UAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;AAHJ;AAKI;EACE,aAAA;AAHN;AAMI;EACE,cAAA;AAJN;AAQE;EACE,YAAA;EACA,aAAA;EACA,iBAAA;EACA,sBAAA;EACA,6BAAA;AANJ;AAUI;EACE,cAAA;AARN;AAUM;EACE,aAAA;AARR","sourcesContent":["@use \"../../../../../global.scss\" as *;\n\n.app_actions_dropdown {\n  &.dropdown {\n    position: absolute;\n    right: 40px;\n    // top: calc(1em + 20px);\n    background-color: white;\n    z-index: 2;\n    padding: 10px;\n    border-radius: 5px;\n    border: 1px solid #ccc;\n    text-align: left;\n    font-size: 14px;\n    color: #1a2345;\n\n    &.hide {\n      display: none;\n    }\n\n    &.show {\n      display: block;\n    }\n  }\n\n  svg {\n    width: 0.9em;\n    height: 0.9em;\n    margin-right: 2px;\n    vertical-align: middle;\n    transform: translateY(-0.1em);\n  }\n\n  .dropdown-item {\n    &:hover {\n      color: lighten($color: $main-color-text, $amount: 25);\n\n      svg {\n        fill: lighten($color: $main-color-text, $amount: 25);\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
