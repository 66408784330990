import React, { useEffect, useRef, useState } from "react";
import { Country, Language, LeadsParticipant, Timezone } from "../../types";
import RangeSlider from "../RangeSlider/rangeSlider.component";

interface ViewLeadModalFormProps {
  participant: LeadsParticipant;
  editing: boolean;
  languages: Language[];
  countries: Country[];
  timezones: Timezone[];
  // participant fields
  cheaterScore: number;
  participationScore: number;
  participantName: string;
  participantSurName: string;
  participantMobile: string;
  participantCountryID: string;
  participantLanguageID: string;
  participantTimezone: string;
  participantTags: string[];
  participantPassword: string | null;
  // participantGender: string;
  // participantAge: number | "";
  // Set participant fields
  setCheaterScore: (value: number) => void;
  setParticipationScore: (value: number) => void;
  setParticipantName: (value: string) => void;
  setParticipantSurName: (value: string) => void;
  setParticipantMobile: (value: string) => void;
  setParticipantCountryID: (value: string) => void;
  setParticipantLanguageID: (value: string) => void;
  setParticipantTimezone: (value: string) => void;
  setParticipantTags: (value: string[]) => void;
  setParticipantPassword: (value: string) => void;
  // setParticipantGender: (value: string) => void;
  // setParticipantAge: (value: number | "") => void;
}

const ViewLeadModalForm: React.FC<ViewLeadModalFormProps> = ({
  participant,
  editing,
  languages,
  countries,
  timezones,
  // participant fields
  cheaterScore,
  participationScore,
  participantName,
  participantSurName,
  participantMobile,
  participantCountryID,
  participantLanguageID,
  participantTimezone,
  participantTags,
  participantPassword,
  // participantGender,
  // participantAge,
  // Set participant fields
  setCheaterScore,
  setParticipationScore,
  setParticipantName,
  setParticipantSurName,
  setParticipantMobile,
  setParticipantCountryID,
  setParticipantLanguageID,
  setParticipantTimezone,
  setParticipantTags,
  setParticipantPassword
  // setParticipantGender,
  // setParticipantAge
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  useEffect(() => {
    setParticipantName(participant?.first_name || "");
    setParticipantSurName(participant?.last_name || "");
    setParticipantMobile(participant?.mobile_number || "");
    setParticipantCountryID(participant?.country_iso || "");
    setParticipantLanguageID(participant?.language_iso || "");
    setParticipantTimezone(participant?.timezone || "");
    setParticipantTags([]);
    // setParticipantGender(participant?.gender || "");
    // setParticipantAge(participant?.age || "");

    // if (participant.edited_cheater_score) {
    //   setCheaterScore(participant.edited_cheater_score);
    // } else {
    //   setCheaterScore(participant?.cheater_score || 0);
    // }
    setCheaterScore(participant?.cheater_score || 0);

    // if (participant.edited_rating_score) {
    //   setParticipationScore(participant.edited_rating_score);
    // } else {
    //   setParticipationScore(participant?.rating_score || 0);
    // }
    setParticipationScore(participant?.participation_score || 0);
  }, [participant]);

  const handleTagsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.replace(/[^a-zA-Z0-9,]/g, "");
    // Add tags on comma
    if (event.target.value.includes(",")) {
      const newTags: string[] = event.target.value
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag && !participantTags.includes(tag));
      (setParticipantTags as React.Dispatch<React.SetStateAction<string[]>>)(
        (prevTags: string[]) => [...prevTags, ...newTags]
      );
      event.target.value = ""; // Clear the input
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    // Add tag on enter key
    if (event.key === "Enter" && inputRef.current?.value) {
      const newTag: string = inputRef.current.value.trim();
      if (newTag && !participantTags.includes(newTag)) {
        // Check if tag is unique
        (setParticipantTags as React.Dispatch<React.SetStateAction<string[]>>)(
          (prevTags: string[]) => [...prevTags, newTag]
        );
      }
      inputRef.current.value = ""; // Clear the input
      event.preventDefault(); // Prevents form submission if this is inside a form
    }
  };

  const removeTag = (tagToRemove: string) => {
    (setParticipantTags as React.Dispatch<React.SetStateAction<string[]>>)(
      (prevTags: string[]) =>
        prevTags.filter((tag: string) => tag !== tagToRemove)
    );
  };

  const [participationBarClass, setParticipationBarClass] =
    useState<string>("");

  const [cheaterBarClass, setCheaterBarClass] = useState<string>("");

  // change the participant Lead score
  const handleParticipationScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setParticipationScore(+event.target.value);
  };

  // change the participant cheater score
  const handleCheaterScoreChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCheaterScore(+event.target.value);
  };

  // set the score bar colors
  useEffect(() => {
    let classValue;
    // set the Lead score colors
    // less than 25% = terrible
    // less than 50% = bad
    // less than 75% = warning
    // 75% or more = good
    const participationScoreGood = 75;
    const participationScorewarning = 50;
    const participationScoreBad = 25;

    if (participationScore > participationScoreGood) {
      classValue = "score_bar_good";
    } else if (participationScore > participationScorewarning) {
      classValue = "score_bar_warning";
    } else if (participationScore > participationScoreBad) {
      classValue = "score_bar_bad";
    } else {
      classValue = "score_bar_terrible";
    }

    setParticipationBarClass(classValue);
  }, [participationScore]);

  // set the score bar colors
  useEffect(() => {
    let classValue;
    // set the score bar colors
    // less than 25% = good
    // less than 50% = warning
    // less than 75% = bad
    // 75% or more = terrible
    const cheaterScoreGood = 25;
    const cheaterScoreWarning = 50;
    const cheaterScoreBad = 75;

    if (cheaterScore < cheaterScoreGood) {
      classValue = "score_bar_good";
    } else if (cheaterScore < cheaterScoreWarning) {
      classValue = "score_bar_warning";
    } else if (cheaterScore < cheaterScoreBad) {
      classValue = "score_bar_bad";
    } else {
      classValue = "score_bar_terrible";
    }

    setCheaterBarClass(classValue);
  }, [cheaterScore]);

  // function handleChangeAge(event: React.ChangeEvent<HTMLInputElement>) {
  //   if (event.target.value === "") {
  //     setParticipantAge("");
  //     return;
  //   }
  //   setParticipantAge(
  //     Number(event.target.value) > 0 && Number(event.target.value) < 200
  //       ? Number(event.target.value)
  //       : participantAge
  //   );
  // }

  function handleChangeMobile(event: React.ChangeEvent<HTMLInputElement>) {
    // only allow numbers and "+"
    const re = /^[0-9\b+]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setParticipantMobile(event.target.value);
    }
  }

  return (
    <div className="modal-body edit_participant_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-4">ID</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="_id"
              value={participant.id.toString()}
              disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Email</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="email"
              value={participant.email || "N/A"}
              disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Mobile number</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="mobile_number"
              value={participant.mobile_number ? participant.mobile_number : ""}
              // onChange={(e) => handleChangeMobile(e)}
              placeholder="Enter Mobile Number"
              disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Name</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="name"
              value={participantName}
              onChange={(e) => setParticipantName(e.target.value)}
              placeholder="Enter name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Surname</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="surname"
              value={participantSurName}
              onChange={(e) => setParticipantSurName(e.target.value)}
              placeholder="Enter surname"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-4">Country</div>
          <div className="col-8">
            <select
              className="form-control"
              name="country_id"
              value={participantCountryID}
              onChange={(e) => setParticipantCountryID(e.target.value)}
            >
              <option value="">Select country</option>
              {countries.map((country) => (
                // if the country is the same as the participant's country, select it
                <option key={country.iso_code} value={country.iso_code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Language</div>
          <div className="col-8">
            <select
              className="form-control"
              name="language_id"
              value={participantLanguageID}
              onChange={(e) => setParticipantLanguageID(e.target.value)}
            >
              <option value="">Select language</option>
              {languages.map((language) => (
                // if the language is the same as the participant's language, select it
                <option key={language.iso_code} value={language.iso_code}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Timezone</div>
          <div className="col-8">
            <select
              className="form-control"
              name="timezone"
              value={participantTimezone}
              onChange={(e) => setParticipantTimezone(e.target.value)}
            >
              <option value="">Select timezone</option>
              {timezones.map((timezone) => (
                // if the timezone is the same as the participant's timezone, select it
                <option key={timezone.name} value={timezone.name}>
                  (
                  {timezone.offset > 0
                    ? "+" + timezone.offset
                    : timezone.offset}
                  ){" "}
                  {
                    // replace _ with space
                    timezone.name.replace(/_/g, " ")
                  }
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-4">Gender</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="surname"
              value={participantGender}
              onChange={(e) =>
                setParticipantGender(
                  // regex check
                  e.target.value.replace(/[^a-zA-Z]/g, "").toLowerCase()
                )
              }
              placeholder="Enter Gender"
            />
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-4">Age</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="surname"
              value={participantAge || ""}
              onChange={(e) => handleChangeAge(e)}
              placeholder="Enter Age"
            />
          </div>
        </div> */}
        <div className="row">
          <div className="col-4">Participant score</div>
          <div className="col-8 participation_score_rating">
            <div className={`range ${participationBarClass}`}>
              <RangeSlider
                min={0}
                max={100}
                step={1}
                value={{ min: 0, max: participationScore }}
                onChange={(value) => {
                  setParticipationScore(value.max);
                }}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Cheater score</div>
          <div className="col-8 cheater_score_rating">
            <div className={`range ${cheaterBarClass}`}>
              <RangeSlider
                min={0}
                max={100}
                step={1}
                value={{ min: 0, max: cheaterScore }}
                onChange={(value) => {
                  setCheaterScore(value.max);
                }}
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-4">Tags</div>
          <div className="col-8">
            <div className="input-wrapper">
              <input
                ref={inputRef}
                type="text"
                className="form-control"
                onChange={handleTagsChange}
                onKeyDown={handleKeyDown}
                placeholder="Enter tags"
              />
              {participantTags.map((tag) => (
                <span key={tag} className="tag">
                  {tag}
                  <button
                    type="button"
                    className="closeTag"
                    onClick={() => removeTag(tag)}
                  >
                    X
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-4">Password</div>
          <div className="col-8 password_container">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              name="password"
              value={participantPassword || ""}
              onChange={(e) => setParticipantPassword(e.target.value)}
              placeholder="Enter new password"
            />
            <button
              type="button"
              className="btn_password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <IconVisible className="icon icon_black" />
              ) : (
                <IconInvisible className="icon icon_black" />
              )}
            </button>
          </div>
        </div> */}
        {/* <p>
          The min value is: <span>{value.min}</span>
        </p>
        <p>
          The max value is: <span>{value.max}</span>
        </p> */}
      </div>
    </div>
  );
};

export default ViewLeadModalForm;
