import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  base64Maker,
  checkImageMimeType,
  testImageLoad
} from "../../../../../src/utilities/imageUploadHandler.util";
import * as Icons from "../../../../assets/images/icons/icons";
import { usePermissions } from "../../../../contexts/UserContext";
import { fetchAllWSCSurveys } from "../../../../models/study.model";
import {
  sendDeleteSurvey,
  sendUpdatedSurveyDiary,
  surveySwitch
} from "../../../../models/survey.model";
import {
  ExistingSurveyNotifications,
  FieldDropdown,
  SurveyDiary
} from "../../../../types";
import { handleExportPhrase } from "../../../../utilities/languageLibrary.util";
import { copyToClipboard } from "../../../../utilities/utils";
import InformationTooltip from "../../../InputInformation/InfoInputs.component";
import imageChange from "../../../imageChanger/imageChangerHandler";

const DiarySurvey: React.FC<{
  surveyData: SurveyDiary;
  unfilteredNotificationData: ExistingSurveyNotifications;
}> = ({ surveyData, unfilteredNotificationData }) => {
  const { clientID, studyID, surveyID } = useParams(); // Get from URL
  const [loading, setLoading] = useState<boolean>(true);
  const validNfieldLinks = [
    "interviewing.nfieldmr.com/",
    "interviewingap.nfieldmr.com/"
  ];

  // status
  const [surveyStatus, setSurveyStatus] = useState<string>(
    surveyData.survey_status
  );
  // type
  const [surveyType, setSurveyType] = useState<string>(surveyData.survey_type);
  // name
  const [surveyName, setSurveyName] = useState<string>(surveyData.survey_name);
  // description
  const [surveyDescription, setSurveyDescription] = useState<string>(
    surveyData.survey_desc
  );
  // app display name
  // const [surveyAppDisplayName, setSurveyAppDisplayName] = useState<string>(
  //   surveyData.survey_app_display_name
  // );
  // new thumbnail url
  const [surveyThumbnailNewUrl, setSurveyThumbnailNewUrl] =
    useState<string>("");
  // preview thumbnail url
  const [surveyPreviewThumbnailUrl, setSurveyPreviewThumbnailUrl] =
    useState<string>(surveyData.thumbnail_url);
  // active days
  const [surveyActiveDays, setSurveyActiveDays] = useState<number>(
    surveyData.survey_active_days
  );
  // grace days
  const [surveyGraceDays, setSurveyGraceDays] = useState<number>(
    surveyData.survey_grace_days
  );
  // bonus days
  const [surveyBonusDays, setSurveyBonusDays] = useState<number>(
    surveyData.bonus_days
  );
  // min completes
  const [surveyMinCompletes, setSurveyMinCompletes] = useState<number>(
    surveyData.survey_min_completes
  );
  // max completes
  const [surveyMaxCompletes, setSurveyMaxCompletes] = useState<number>(
    surveyData.survey_max_completes
  );

  const [wscSurveyOptions, setWscSurveyOptions] = useState<FieldDropdown[]>();
  const [wscSurveys, setWscSurveys] = useState<FieldDropdown[]>();

  // survey system
  const [surveySystem, setSurveySystem] = useState<string>(
    surveyData.survey_system
  );
  const [surveySystemLink, setSurveySystemLink] = useState<string>(
    surveyData.survey_system_link
  );

  const [selectedOption, setSelectedOption] = useState<string | undefined>();

  const [testSurveySystem, setTestSurveySystem] = useState<string>(
    surveyData.test_survey_system || "wsc"
  );

  const [testSurveyLink, setTestSurveyLink] = useState<string>(
    surveyData.test_survey_system_link
  );

  // // welcome notification phrase
  // const [surveyWelcomeNotificationPhrase, setSurveyWelcomeNotificationPhrase] =
  //   useState<string>(surveyData?.notify_welcome.id);
  // // daily reminder notification phrase
  // const [
  //   surveyDailyReminderNotificationPhrase,
  //   setSurveyDailyReminderNotificationPhrase
  // ] = useState<string>(surveyData?.notify_daily.id);
  // // daily reminder time
  // const [surveyDailyReminderTime, setSurveyDailyReminderTime] =
  //   useState<string>(surveyData.survey_daily_reminder_time);
  // // non-compliance notification phrase
  // const [
  //   surveyNonComplianceNotificationPhrase,
  //   setSurveyNonComplianceNotificationPhrase
  // ] = useState<string>(surveyData?.notify_non_compliant.id);
  // // non-compliance time
  // const [surveyNonComplianceTime, setSurveyNonComplianceTime] =
  //   useState<string>(surveyData.survey_non_compliance_time);
  // disable save button
  const [disableSave, setDisableSave] = useState<boolean>(true);
  // flag state for checking image upload
  const [imageUploadFlag, setImageUploadFlag] = useState<boolean>(false);

  const { hasPermission } = usePermissions();

  /*-----------------------------------------------------------*/
  /*-------------Disable or enable the save button-------------*/
  /*-----------------------------------------------------------*/
  useEffect(() => {
    if (
      ((hasPermission("subject") ||
        hasPermission("survey", "write") ||
        hasPermission("survey", "all")) && // Check for "write" or "all" permission
        (surveyData?.survey_status !== surveyStatus ||
          surveyData?.survey_name !== surveyName ||
          surveyData?.survey_desc !== surveyDescription ||
          // surveyData?.survey_app_display_name !== surveyAppDisplayName ||
          surveyThumbnailNewUrl !== "" ||
          surveyData?.survey_active_days !== surveyActiveDays ||
          surveyData?.survey_grace_days !== surveyGraceDays ||
          surveyData?.bonus_days !== surveyBonusDays ||
          surveyData?.survey_min_completes !== surveyMinCompletes ||
          surveyData?.survey_max_completes !== surveyMaxCompletes)) ||
      surveyData?.test_survey_system !== testSurveySystem ||
      surveyData?.test_survey_system_link !== testSurveyLink
      // || surveyData?.notify_welcome.id !== surveyWelcomeNotificationPhrase ||
      // surveyData?.notify_daily.id !== surveyDailyReminderNotificationPhrase ||
      // surveyData?.survey_daily_reminder_time !== surveyDailyReminderTime ||
      // surveyData?.notify_non_compliant.id !==
      //   surveyNonComplianceNotificationPhrase ||
      // surveyData?.survey_non_compliance_time !== surveyNonComplianceTime
    ) {
      setDisableSave(false);
    } else {
      setDisableSave(true);
    }
  }, [
    surveyData,
    surveyStatus,
    surveyName,
    surveyDescription,
    // surveyAppDisplayName,
    surveyThumbnailNewUrl,
    surveyActiveDays,
    surveyGraceDays,
    surveyBonusDays,
    surveyMinCompletes,
    surveyMaxCompletes,
    testSurveySystem,
    testSurveyLink,
    // surveyWelcomeNotificationPhrase,
    // surveyDailyReminderNotificationPhrase,
    // surveyDailyReminderTime,
    // surveyNonComplianceNotificationPhrase,
    // surveyNonComplianceTime,
    hasPermission
  ]);

  async function fetchWSCSurveys() {
    try {
      const jsonData = await fetchAllWSCSurveys();
      if (typeof jsonData === "string") {
        // console.error("Error fetching data:", jsonData);
        throw new Error("Error fetching data");
      } else {
        setWscSurveyOptions(jsonData);
      }
    } catch (error) {
      console.log("Error fetching data:", error);
      throw error;
    }
  }

  useEffect(() => {
    setWscSurveys([
      {
        id: "wsc",
        name: "WSC"
      },
      {
        id: "nfield",
        name: "nField"
      },
      {
        id: "custom",
        name: "Custom"
      }
    ]);

    const promises = [fetchWSCSurveys()];

    Promise.all(promises)
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const [search, setSearch] = useState("");

  const [filteredOptions, setFilteredOptions] = useState<FieldDropdown[]>(
    wscSurveyOptions || []
  );

  useEffect(() => {
    // filter the options based on the search term
    const filtered = wscSurveyOptions?.filter((option) => {
      return option.name.toLowerCase().includes(search.toLowerCase());
    });
    setFilteredOptions(filtered || []);
  }, [search, wscSurveyOptions]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!wscSurveyOptions) return;

    const searchText = e.target.value;
    setSearch(searchText);

    // Check if something is found in the search
    const filtered = wscSurveyOptions.filter((option) => {
      return option.name.toLowerCase().includes(searchText.toLowerCase());
    });

    if (filtered.length > 0) {
      setSelectedOption(filtered[0].id);
      handleNewInputs("testSurveyLink", filtered[0].id);
    } else {
      // If nothing is found, clear the selected option
      setSelectedOption("");
      handleNewInputs("testSurveyLink", "");
    }
  };

  // Autofill the search bar with the test survey system id
  useEffect(() => {
    if (!wscSurveyOptions || surveyData.test_survey_system !== "wsc") return;

    const searchText = surveyData.test_survey_system_id;
    setSearch(searchText);

    // Check if something is found in the search
    const filtered = wscSurveyOptions.filter((option) => {
      return option.name.toLowerCase().includes(searchText.toLowerCase());
    });

    // If something is found, set the selected option
    if (filtered.length > 0) {
      setSelectedOption(filtered[0].id);
    } else {
      setSelectedOption("");
    }
  }, [
    surveyData.test_survey_system,
    surveyData.test_survey_system_id,
    wscSurveyOptions
  ]);

  const handleNewInputs = (
    name: string,
    value: React.SetStateAction<string>
  ) => {
    console.log("name:", name);
    console.log("value:", value);
    switch (name) {
      case "surveyStatus":
        setSurveyStatus(value);
        break;
      case "surveyName":
        setSurveyName(value);
        break;
      case "surveyDescription":
        setSurveyDescription(value);
        break;
      // case "surveyAppName":
      //   setSurveyAppDisplayName(value);
      //   break;
      case "surveyActiveDays":
        setSurveyActiveDays(Number(value));
        break;
      case "surveyGraceDays":
        setSurveyGraceDays(Number(value));
        break;
      case "surveyBonusDays":
        setSurveyBonusDays(Number(value));
        break;
      case "surveyMinCompletes":
        setSurveyMinCompletes(Number(value));
        break;
      case "surveyMaxCompletes":
        setSurveyMaxCompletes(Number(value));
        break;
      case "testSurveySystem":
        setTestSurveySystem(value);

        if (value === "nfield") {
          setTestSurveyLink("https://interviewing.nfieldmr.com/");
          setSelectedOption("");
          setSearch("");
        }

        if (value === "custom") {
          setTestSurveyLink("https://");
          setSelectedOption("");
          setSearch("");
        }

        if (value === "wsc") {
          setTestSurveyLink("");
          setSelectedOption("");
          setSearch("");
        }

        break;
      case "testSurveyLink":
        setTestSurveyLink(value);
        break;

      // case "surveyWelcomeNotification":
      //   setSurveyWelcomeNotificationPhrase(value);
      //   break;
      // case "surveyDailyReminderNotification":
      //   setSurveyDailyReminderNotificationPhrase(value);
      //   break;
      // case "surveyDailyReminderTime":
      //   setSurveyDailyReminderTime(value);
      //   break;
      // case "surveyNonComplianceNotification":
      //   console.log("value:", value);
      //   setSurveyNonComplianceNotificationPhrase(value);
      //   break;
      // case "surveyNonComplianceTime":
      //   setSurveyNonComplianceTime(value);
      //   break;
      default:
        console.error("Invalid input name");
        break;
    }
  };

  // Functions
  // This function deals with image upload
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    imageChange({
      event: e,
      surveyThumbnailOldUrl: surveyData.thumbnail_url,
      setImageUploadFlag,
      setSurveyThumbnailNewUrl,
      setSurveyPreviewThumbnailUrl
    });
  };

  // This will call the functions that we need for validating and sending the data respectively
  async function handleUpdateSurvey() {
    if (!clientID || !studyID || !surveyID) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Missing clientID, studyID, or surveyID"
      });
      return;
    }
    console.log("first validation layer");
    // first validation layer
    let validation = await validateSurvey();
    if (validation) {
      // check if second validation layer is needed
      if (isSensitiveValidationNeeded()) {
        console.log("second validation layer");
        // second validation layer
        let sensitiveValidation = await handleSensitiveValidation();
        if (sensitiveValidation) {
          console.log("save survey layer with second validation");
          // save survey layer
          saveSurvey();
        } else {
          console.log("second validation failed");
          return;
        }
      } else {
        console.log("save survey layer without second validation");
        // save survey layer
        saveSurvey();
        return;
      }
    } else {
      console.log("validation failed");
      return;
    }
  }

  /*---------------------------------------------------*/
  /*------------------Validate survey------------------*/
  /*---------------------------------------------------*/
  // Validation FIRST LAYER
  async function validateSurvey(): Promise<boolean> {
    const stringShortMax = 255;
    const stringLongMax = 1500;
    const dayMax = 999;
    let errors: string[] = [];

    // validate survey status - cannot be blank, must be between 4 and stringShortMax
    if (surveyStatus === "") {
      errors.push("Please enter a survey status");
    } else if (surveyStatus.length < 4) {
      errors.push("Survey status must be at least 4 characters");
    } else if (surveyStatus.length > stringShortMax) {
      errors.push(
        "Survey status must be less than " + stringShortMax + " characters"
      );
    }

    // validate survey name - cannot be blank, must be between 4 and stringShortMax
    if (surveyName === "") {
      errors.push("Please enter a survey name");
    } else if (surveyName.length < 4) {
      errors.push("Survey name must be at least 4 characters");
    } else if (surveyName.length > stringShortMax) {
      errors.push(
        "Survey name must be less than " + stringShortMax + " characters"
      );
    }

    // validate survey description - cannot be blank, must be less than stringLongMax
    if (surveyDescription === "") {
      errors.push("Please enter a survey description");
    } else if (surveyDescription.length > stringLongMax) {
      errors.push(
        "Survey description must be less than " + stringLongMax + " characters"
      );
    }

    // validate survey survey app display name - cannot be blank, must be less than stringLongMax
    // if (surveyAppDisplayName === "") {
    //   errors.push("Please enter survey app display name");
    // } else if (surveyAppDisplayName.length > stringLongMax) {
    //   errors.push(
    //     "Survey display name must be less than " + stringLongMax + " characters"
    //   );
    // }

    // Validate survey thumbnail URL
    if (imageUploadFlag === true) {
      let mimeTypeCheck = await checkImageMimeType(surveyThumbnailNewUrl);
      if (!mimeTypeCheck) {
        errors.push("The image must be a PNG, JPEG, JPG or WEBP file");
      } else {
        let imageCheck = await testImageLoad(surveyThumbnailNewUrl);
        if (!imageCheck) {
          errors.push("The image can not be loaded properly from the url");
        } else if (surveyThumbnailNewUrl !== surveyData.thumbnail_url) {
          // Convert to Base64 only if the URL is different from the original
          const base64 = await base64Maker(surveyThumbnailNewUrl);
          if (!base64) {
            errors.push(
              "Something is wrong with the image converter, contact support"
            );
          } else {
            console.log("Base64:", base64);
          }
        }
      }
    } else {
      console.log("Send back empty string image for backend");
    }

    // validate active days - cannot be blank,
    if (surveyActiveDays < 1) {
      errors.push("Active Days cannot be smaller than 1");
    } else if (surveyActiveDays > dayMax) {
      errors.push("Active Days cannot be greater than " + dayMax);
    }
    // validate grace days - cannot be blank,
    if (surveyGraceDays > dayMax) {
      errors.push("Grace Days cannot be greater than " + dayMax);
    }
    // validate bonus days - cannot be blank,
    if (surveyBonusDays > dayMax) {
      errors.push("Bonus Days cannot be greater than " + dayMax);
    }
    // validate min completes - cannot be blank,
    if (surveyMinCompletes < 1) {
      errors.push("Minimum Completes cannot be smaller than 1");
    } else if (surveyMinCompletes > dayMax) {
      errors.push("Minimum Completes cannot be greater than " + dayMax);
    }
    // validate max completes - cannot be blank,
    if (surveyMaxCompletes < 1) {
      errors.push("Maximum Completes cannot be smaller than 1");
    } else if (surveyMaxCompletes > dayMax) {
      errors.push("Maximum Completes cannot be greater than " + dayMax);
    }
    // validate max completes cant be less than min completes
    if (surveyMinCompletes > surveyMaxCompletes) {
      errors.push("Minimum Completes cannot be greater than Maximum Completes");
    }

    // validate test survey system
    if (testSurveySystem === "") {
      errors.push("Please select a test survey system");
    }

    // validate test survey link - cannot be blank, must be less than stringLongMax
    if (testSurveyLink === "") {
      errors.push("Please enter a test survey link");
    } else if (
      testSurveySystem === "nfield" &&
      !validNfieldLinks.some((link) => testSurveyLink?.includes(link))
    ) {
      errors.push("Please enter a valid test Nfield link");
    } else if (
      testSurveySystem === "custom" &&
      testSurveyLink &&
      (!testSurveyLink?.includes("https://") ||
        !testSurveyLink?.includes(".") ||
        testSurveyLink.length < 12)
    ) {
      errors.push("Please enter a test valid link");
    } else if (testSurveyLink && testSurveyLink?.length > stringShortMax) {
      errors.push(`Must be less than ${stringShortMax} characters`);
    }

    // if any errors:
    if (errors.length > 0) {
      let errorHtml =
        "<strong>Please fix the following errors:</strong><br /><br />";
      errors.forEach((error) => {
        errorHtml += `<p>${error}</p>`;
      });
      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: errorHtml
      });
      return false;
    }

    return true;
  }

  /*---------------------------------------------------------------------*/
  /*------------------Check if second validation needed------------------*/
  /*---------------------------------------------------------------------*/
  // Checks if second validation needed
  function isSensitiveValidationNeeded() {
    // CHECK if this validation is needed
    // comparing original value to the onchange values for sensitive data
    let active = surveyData.survey_active_days;
    let bonus = surveyData.bonus_days;
    let min = surveyData.survey_min_completes;
    let max = surveyData.survey_max_completes;

    if (
      surveyActiveDays !== active ||
      surveyBonusDays !== bonus ||
      surveyMinCompletes !== min ||
      surveyMaxCompletes !== max
    ) {
      console.log("Sensitive data validation needed!");
      return true;
    } else {
      console.log("No sensitive data validation needed!");
      return false;
    }
  }

  /*---------------------------------------------------------*/
  /*------------------Handle Sensitive Data------------------*/
  /*---------------------------------------------------------*/
  // Validation SECOND LAYER
  // active, bonus days & min and max completes is classified as sensitive data
  // THEREFOR - we are doing extra validation for these fields
  async function handleSensitiveValidation() {
    // CHECKING - starts here!
    console.log("Sensitive editing");
    //   Swal alert, requiring confirmation. User needs to type "ACCEPT" and press confirm to confirm
    const result = await Swal.fire({
      title: "Are you sure?",
      html: "You won't be able to revert this!<br/><br/>Type <b>ACCEPT</b> to change the values:",
      icon: "warning",
      input: "text",
      inputAttributes: {
        autocapitalize: "off"
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, change it!",
      showLoaderOnConfirm: true,
      preConfirm: (inputValue) => {
        if (inputValue !== "ACCEPT") {
          Swal.showValidationMessage(
            `Request failed: You need to type "ACCEPT"`
          );
          return false;
        }
        return true;
      }
    });

    // Check if the result is confirmed
    if (result.isConfirmed) {
      console.log("ACCEPT confirmed");
      return true; // Return true when changes are confirmed
    } else {
      console.log("Sensitive editing cancelled");
      Swal.fire({
        icon: "success",
        title: "Canceled",
        html: "Your changes have been canceled!"
      });
      return false; // Return false when changes are not confirmed
    }
  }

  /*-------------------------------------------------*/
  /*------------------Saving Survey------------------*/
  /*-------------------------------------------------*/
  // Send data to model and send to backend
  const saveSurvey = async () => {
    try {
      const jsonData: {
        rStatus: "error" | "success";
        rMessage: string;
      } = await sendUpdatedSurveyDiary(
        clientID!,
        studyID!,
        surveyID!,
        surveyStatus!,
        surveyName!,
        surveyDescription!,
        // surveyAppDisplayName!,
        surveyThumbnailNewUrl!,
        surveyActiveDays!,
        surveyGraceDays!,
        surveyBonusDays!,
        surveyMinCompletes!,
        surveyMaxCompletes!,
        // surveySystem!,
        testSurveyLink!,
        testSurveySystem!
        // surveyWelcomeNotificationPhrase!,
        // surveyDailyReminderNotificationPhrase!,
        // surveyDailyReminderTime!,
        // surveyNonComplianceNotificationPhrase!,
        // surveyNonComplianceTime!
      );

      if (jsonData.rStatus === "error") {
        Swal.fire({
          icon: "error",
          title: "Error",
          html: jsonData.rMessage
        });
      } else {
        // ok button which will reload the page
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Survey updated successfully",
          showConfirmButton: true,
          confirmButtonText: "OK"
        }).then(() => {
          // reload page
          window.location.reload();
        });
      }
    } catch (error) {
      console.error("An error occurred while sending data:", error);
      // setError("An error occurred while sending data");
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "An error occurred while sending data"
      });
    }
  };

  //------------Export phrases----------------
  const handleExportButton = () => {
    if (!clientID || !surveyID) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Missing clientID or studyID"
      });
      return;
    }
    handleExportPhrase(true, surveyID, "Survey", clientID);
  };

  async function deleteSurvey() {
    console.log("delete survey");
    if (!clientID || !studyID || !surveyID) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Missing clientID, studyID, or surveyID"
      });
      return;
    }
    let confirm = false;

    const currentWorkerID = localStorage.getItem("workerID");

    if (
      currentWorkerID !== null &&
      currentWorkerID !== undefined &&
      currentWorkerID !== ""
    ) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // confirm
    await Swal.fire({
      icon: "warning",
      title: `Are you sure you want to delete "${surveyName}" diary?`,
      html: `
      <div>         
        <label>Please enter "CONFIRM" to delete this diary.</label>
        <input id="swal-input1" class="swal2-input" style="margin-left: 10px; height:2.5rem; width:10rem;" placeholder="CONFIRM">
      </div>`,
      preConfirm: () => {
        const inputValue = (
          document.getElementById("swal-input1") as HTMLInputElement
        ).value;
        if (inputValue !== "CONFIRM") {
          Swal.showValidationMessage(`Please enter "CONFIRM".`);
        }
      },
      showCancelButton: true,
      confirmButtonText: "Yes, delete it",
      cancelButtonText: "No, cancel",
      confirmButtonColor: "#dd4545"
    }).then((result) => {
      if (result.isConfirmed) {
        confirm = true;
      }
    });

    console.log("confirm:", confirm);

    if (!confirm) return;

    const response = await sendDeleteSurvey(clientID, studyID, surveyID);
    if (response.rStatus === "error") {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: response.rMessage
      });
    } else {
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Worker has been created successfully",
        showConfirmButton: true,
        confirmButtonText: "OK",
        confirmButtonColor: "#3085d6"
      }).then(() => {
        // redirect to the study page
        window.location.href = `/clients/${clientID}/${studyID}`;
      });
    }
  }

  const handleSurveySwitch = async () => {
    if (!surveyData.test_survey_system) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please select a test survey system"
      });
      return;
    }

    if (!surveyData.test_survey_system_link) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Please enter a test survey link"
      });
      return;
    }

    if (!clientID || !studyID || !surveyID) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: "Missing clientID, studyID, or surveyID"
      });
      return;
    }

    // Check if the links are the same
    if (surveyData.survey_system_link === surveyData.test_survey_system_link) {
      Swal.fire({
        icon: "error",
        title: "Error",
        html: `<p>The Survey System Link and Test Survey System Link are the same!</p> <p>Please update and <strong>Save</strong> the Test Survey System Link before switching.</p>`
      });
      return;
    }

    //Swal alert Confirmation, old system and link - new system and link
    Swal.fire({
      title: "Are you sure?",
      html: `
      <div class="container">
        <div class="row">
          <div class="col-12 pb-3">
            <div class="d-flex justify-content-center flex-column">
            <span><strong>From</strong></span>
            <span><strong>System:</strong> ${surveyData.survey_system}</span>
            <span><strong>Link:</strong> ${surveyData.survey_system_link}</span>
            </div>
          </div>
          
          <div class="col-12">
            <div class="d-flex justify-content-center flex-column">
            <span><strong>To</strong> </span>
            <span><strong>System:</strong> ${surveyData.test_survey_system}</span>
            <span><strong>Link:</strong> ${surveyData.test_survey_system_link}</span>
            </div>
          </div>

        </div>
      </div>
      <hr>
      <p>You will be sent an email to confirm the switch.</p>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Switch"
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await surveySwitch(clientID, studyID, surveyID);
        if (response.rStatus === "error") {
          Swal.fire({
            icon: "error",
            title: "Error",
            html: response.rMessage
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Please check your email for confirmation",
            showConfirmButton: true,
            confirmButtonText: "OK",
            confirmButtonColor: "#3085d6"
          });
        }
      }
    });
  };

  const handleCopySurveySettings = () => {
    // Remove thumbnail_url from surveyData
    const { thumbnail_url, ...copyData } = surveyData;

    copyToClipboard(
      "Survey Settings",
      JSON.stringify(copyData, null, 2).replace(/\\"/g, "")
    );
  };

  return (
    <div className="container m-auto col-12 col-md-6 col-lg-8">
      <div className="row align-items-start justify-content-start">
        <div className="col card">
          <div className="card-body">
            <div className="row">
              <div className="col d-flex justify-content-between align-items-center mb-2">
                <h5 className="card-title m-0">Diary Survey Settings</h5>
                <button
                  className="btn btn-primary icon_btn"
                  type="button"
                  onClick={() => {
                    handleCopySurveySettings();
                  }}
                >
                  <Icons.IconCopy
                    className="icon icon_white"
                    style={{ width: "15px", height: "15px" }}
                  />
                </button>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <p>Survey Type</p>
              </div>
              <div className="col-8">
                <input
                  className="form-control edit_input"
                  type="text"
                  value={surveyType || "N/A"}
                  name="surveyType"
                  placeholder="Survey Type"
                  disabled={true}
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <p>Survey Status</p>
              </div>
              <div className="col-8">
                <select
                  className="form-control edit_input"
                  value={surveyStatus || "N/A"}
                  name="surveyStatus"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </select>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label>Name</label>
              </div>
              <div className="col-8">
                <input
                  className="form-control edit_input"
                  type="text"
                  value={surveyName || "N/A"}
                  name="surveyName"
                  placeholder="Survey Name"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label>Description</label>
              </div>
              <div className="col-8">
                <textarea
                  className="form-control edit_input text_input"
                  value={surveyDescription || "N/A"}
                  name="surveyDescription"
                  placeholder="Survey Description"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            {/* <div className="row align-items-center">
              <div className="col-4">
                <label>Survey App Display Name</label>
              </div>
              <div className="col-8">
                <input
                  className="form-control edit_input"
                  type="text"
                  value={surveyAppDisplayName || "N/A"}
                  name="surveyAppName"
                  placeholder="Survey App Name"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div> */}
            <div className="row align-items-center">
              <div className="col-4">
                <label>Icon</label>
              </div>
              <div className="col-8">
                {!surveyPreviewThumbnailUrl ||
                surveyPreviewThumbnailUrl === "" ? (
                  <p className="margin_fields">No image</p>
                ) : (
                  <p className="margin_fields">
                    <img
                      src={surveyPreviewThumbnailUrl}
                      alt="survey thumbnail"
                      className="survey_thumbnail"
                    />
                  </p>
                )}
              </div>
            </div>
            {(hasPermission("subject") ||
              hasPermission("survey", "all") ||
              hasPermission("survey", "write")) && (
              <div className="row align-items-center">
                <div className="col-4"></div>
                <div className="col-8">
                  <input
                    className="form-control margin_fields"
                    type="file"
                    accept="image/png, image/jpeg, image/webp"
                    id="formFile"
                    name="surveyThumbnailUrl"
                    onChange={handleImageChange}
                  ></input>
                </div>
              </div>
            )}
            <div className="row align-items-center">
              <div className="col-4">
                <label>Active Days</label>
              </div>
              <div className="col-8">
                <input
                  className={`form-control edit_input ${
                    surveyData?.survey_active_days !== surveyActiveDays
                      ? "input-red"
                      : ""
                  }`}
                  type="number"
                  min="1"
                  max="999"
                  value={surveyActiveDays.toString() || "N/A"}
                  name="surveyActiveDays"
                  placeholder="Survey Active Days"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label>Grace Days</label>
              </div>
              <div className="col-8">
                <input
                  className="form-control edit_input"
                  type="number"
                  min="0"
                  max="999"
                  value={surveyGraceDays.toString() || "N/A"}
                  name="surveyGraceDays"
                  placeholder="Survey Grace Days"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label>Bonus Days</label>
              </div>
              <div className="col-8">
                <input
                  className={`form-control edit_input ${
                    surveyData?.bonus_days !== surveyBonusDays
                      ? "input-red"
                      : ""
                  }`}
                  type="number"
                  min="0"
                  max="999"
                  value={surveyBonusDays.toString() || "N/A"}
                  name="surveyBonusDays"
                  placeholder="Survey Bonus Days"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label>Minimum Completes</label>
              </div>
              <div className="col-8">
                <input
                  className={`form-control edit_input ${
                    surveyData?.survey_min_completes !== surveyMinCompletes
                      ? "input-red"
                      : ""
                  }`}
                  type="number"
                  min="1"
                  max="999"
                  value={surveyMinCompletes.toString() || "N/A"}
                  name="surveyMinCompletes"
                  placeholder="Survey Minimum Completes"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label>Maximum Completes</label>
              </div>
              <div className="col-8">
                <input
                  className={`form-control edit_input ${
                    surveyData?.survey_max_completes !== surveyMaxCompletes
                      ? "input-red"
                      : ""
                  }`}
                  type="number"
                  min="1"
                  max="999"
                  value={surveyMaxCompletes.toString() || "N/A"}
                  name="surveyMaxCompletes"
                  placeholder="Survey Maximum Completes"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-4">
                <label>Survey System</label>
              </div>
              <div className="col-8">
                <input
                  className="form-control edit_input"
                  type="text"
                  value={surveySystem.toUpperCase() || "N/A"}
                  name="surveySystem"
                  placeholder="Survey System"
                  disabled={true}
                />
              </div>
            </div>
            {/* Displaying the variable links , WSC Name and then the other two will not have a header */}
            <div className="row align-items-center">
              {surveySystem && surveySystem === "wsc" ? (
                <div className="col-4">
                  <label>{surveyData.survey_system_id || "N/A"}</label>
                </div>
              ) : (
                <div className="col-4"></div>
              )}
              <div className="col-8">
                <div className="input-group mb-3 edit_input">
                  <input
                    type="text"
                    className="form-control "
                    placeholder="Survey WSC Name"
                    aria-label="Survey WSC Name"
                    value={surveyData.survey_system_link || "N/A"}
                    disabled={true}
                  />
                  <button
                    className="btn btn-primary "
                    type="button"
                    onClick={() =>
                      copyToClipboard("Link", surveyData.survey_system_link)
                    }
                  >
                    <Icons.IconCopy
                      className="icon icon_white"
                      style={{ width: "20px", height: "20px" }}
                    />
                  </button>
                </div>
              </div>
            </div>
            {/* Switch Test Survey Link  */}
            {surveySystem !== "viewEQ" && (
              <>
                <div className="row align-items-center">
                  <div className="col-4">
                    {!disableSave && (
                      <InformationTooltip
                        marginTop="-3px"
                        position={"top"}
                        tooltipText={`Save your changes before switching the test survey system`}
                      />
                    )}
                    <label className="">Switch Test Survey Link</label>
                  </div>
                  <div className="col-8">
                    <div className="input-group d-flex justify-content-center align-items-center">
                      <button
                        className={`btn ${!disableSave && "disable-cursor"}`}
                        type="button"
                        onClick={async () => {
                          if (!disableSave) return;
                          await handleSurveySwitch();
                        }}
                      >
                        <Icons.IconSwitch
                          className="icon"
                          style={{
                            width: "20px",
                            height: "20px",
                            transform: "rotate(90deg)"
                          }}
                        />
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-4">
                    <label>Test Survey System</label>
                  </div>
                  <div className="col-8">
                    {/* <input
                  className="form-control edit_input"
                  type="text"
                  value={testSurveySystem.toUpperCase() || "N/A"}
                  name="surveySystem"
                  placeholder="Survey System"
                  disabled={true}
                /> */}
                    <div className="input-group mb-3 edit_input">
                      <select
                        name={testSurveySystem}
                        id={testSurveySystem}
                        className="form-select"
                        value={testSurveySystem}
                        onChange={(e) =>
                          handleNewInputs("testSurveySystem", e.target.value)
                        }
                      >
                        {
                          // display the options
                          wscSurveys &&
                            wscSurveys.map((option) => {
                              return (
                                <option key={option.id} value={option.id}>
                                  {option.name}
                                </option>
                              );
                            })
                        }
                      </select>
                    </div>
                  </div>
                </div>

                {testSurveySystem === "wsc" ? (
                  <>
                    {/* Previous link was wsc */}
                    {surveyData.test_survey_system === "wsc" && (
                      <div className="row align-items-center">
                        <div className="col-4">
                          <label>
                            {surveyData.test_survey_system_id || "N/A"}
                          </label>
                        </div>

                        <div className="col-8">
                          <div className="input-group mb-3 edit_input">
                            <input
                              type="text"
                              className="form-control "
                              placeholder="Survey WSC Name"
                              aria-label="Survey WSC Name"
                              value={
                                surveyData.test_survey_system_link || "N/A"
                              }
                              disabled={true}
                            />
                            <button
                              className="btn btn-primary "
                              type="button"
                              onClick={() =>
                                copyToClipboard(
                                  "Link",
                                  surveyData.test_survey_system_link
                                )
                              }
                            >
                              <Icons.IconCopy
                                className="icon icon_white"
                                style={{ width: "20px", height: "20px" }}
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="row align-items-start">
                      <div className="col-4">
                        <label className="label-multi-line">
                          Test WSC Survey
                        </label>
                      </div>
                      <div className="col-8">
                        <div className="input-group mb-3 edit_input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search"
                            value={search}
                            onChange={handleSearchChange}
                            disabled={loading}
                          />
                        </div>
                        <div className="input-group mb-3 edit_input">
                          <select
                            name={selectedOption}
                            id={selectedOption}
                            className="form-select"
                            value={selectedOption || ""}
                            onChange={(e) => {
                              handleNewInputs("testSurveyLink", e.target.value);
                              setSelectedOption(e.target.value);
                            }}
                            disabled={loading}
                          >
                            <option value="" disabled>
                              Select an option
                            </option>
                            {filteredOptions.map((option) => {
                              return (
                                <option key={option.id} value={option.id}>
                                  {/* Apply ellipsis to long option text */}
                                  {option.name.length > 50
                                    ? `${option.name.substring(0, 50)}...`
                                    : option.name}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                  </>
                ) : testSurveySystem === "nfield" ? (
                  <div className="row align-items-center">
                    <div className="col-4">
                      <label>Test nField Survey</label>
                    </div>
                    <div className="col-8">
                      <input
                        className="form-control edit_input"
                        type="text"
                        value={testSurveyLink}
                        name="testSurveyLink"
                        placeholder="https://interviewing.nfieldmr.com/"
                        onChange={(e) =>
                          handleNewInputs(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                ) : testSurveySystem === "custom" ? (
                  <div className="row align-items-center">
                    <div className="col-4">
                      <label>Test Custom Survey</label>
                    </div>
                    <div className="col-8">
                      <input
                        className="form-control edit_input"
                        type="text"
                        value={testSurveyLink}
                        name="testSurveyLink"
                        placeholder="https://"
                        onChange={(e) =>
                          handleNewInputs(e.target.name, e.target.value)
                        }
                      />
                    </div>
                  </div>
                ) : (
                  <div className="row align-items-center"></div>
                )}
              </>
            )}

            {/* Displaying the variable links , WSC Name and then the other two will not have a header */}

            {/* <div className="row align-items-center">
              <div className="col-4">
                <label>Welcome Notification</label>
              </div>
              <div className="col-8">
                <select
                  className="form-control edit_input"
                  name="surveyWelcomeNotification"
                  placeholder="Survey Welcome Notification"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                >
                  {unfilteredNotificationData?.diaryWelcomeNotifications.map(
                    (notification) => (
                      <option
                        selected={
                          notification.phraseID === surveyData.notify_welcome.id
                        }
                        key={notification.phraseID}
                        value={notification.phraseID}
                      >
                        {notification.phrase.length > 50
                          ? `${notification.phrase.substring(0, 50)}...`
                          : notification.phrase}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div> */}
            {/* <div className="row align-items-center">
              <div className="col-4">
                <label>Daily Reminder Notification</label>
              </div>
              <div className="col-8">
                <select
                  className="form-control edit_input"
                  name="surveyDailyReminderNotification"
                  placeholder="Survey Daily Reminder Notification"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                >
                  {unfilteredNotificationData?.diaryDailyReminderNotifications.map(
                    (notification) => (
                      <option
                        selected={
                          notification.phraseID === surveyData.notify_daily.id
                        }
                        key={notification.phraseID}
                        value={notification.phraseID}
                      >
                        {notification.phrase.length > 50
                          ? `${notification.phrase.substring(0, 50)}...`
                          : notification.phrase}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div> */}
            {/* <div className="row align-items-center">
              <div className="col-4">
                <label>Daily Reminder Time</label>
              </div>
              <div className="col-8">
                <input
                  className="form-control edit_input"
                  type="time"
                  value={surveyDailyReminderTime || "N/A"}
                  name="surveyDailyReminderTime"
                  placeholder="Survey Daily Reminder Time"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div> */}
            {/* <div className="row align-items-center">
              <div className="col-4">
                <label>Non-Compliance Notification</label>
              </div>
              <div className="col-8">
                <select
                  className="form-control edit_input"
                  name="surveyNonComplianceNotification"
                  placeholder="Survey Non-Compliance Notification"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                >
                  {unfilteredNotificationData?.diaryNonComplianceNotifications.map(
                    (notification) => (
                      <option
                        selected={
                          notification.phraseID ===
                          surveyData.notify_non_compliant.id
                        }
                        key={notification.phraseID}
                        value={notification.phraseID}
                      >
                       
                        {notification.phrase.length > 50
                          ? `${notification.phrase.substring(0, 50)}...`
                          : notification.phrase}
                      </option>
                    )
                  )}
                </select>
              </div>
            </div> */}
            {/* <div className="row align-items-center">
              <div className="col-4">
                <label>Non-Compliance Time</label>
              </div>
              <div className="col-8">
                <input
                  className="form-control edit_input"
                  type="time"
                  value={surveyNonComplianceTime || "N/A"}
                  name="surveyNonComplianceTime"
                  placeholder="Survey Non-Compliance Time"
                  onChange={(e) =>
                    handleNewInputs(e.target.name, e.target.value)
                  }
                  disabled={
                    hasPermission("survey", "write")
                      ? false
                      : hasPermission("survey", "all")
                        ? false
                        : hasPermission("subject")
                          ? false
                          : true
                  }
                />
              </div>
            </div> */}
            <div className="row align-items-center">
              <div className="col-4">
                <label htmlFor="studyLanguages">Phrase Library</label>
              </div>
              <div className="col btn_save_study d-flex justify-content-end">
                <button
                  className="col-3 btn btn-primary d-flex align-items-center justify-content-center"
                  onClick={handleExportButton}
                >
                  <Icons.IconUpload
                    className="icon icon_white"
                    style={{
                      transform: "rotate(180deg)",
                      width: "20px",
                      height: "20px",
                      marginRight: "5px"
                    }}
                  />
                  Export
                </button>
              </div>
            </div>
            <div className="row align-items-center">
              {(hasPermission("subject") ||
                hasPermission("survey", "write") ||
                hasPermission("survey", "all")) && (
                <button
                  className="col-3 btn btn-danger"
                  onClick={() => deleteSurvey()}
                >
                  Delete
                </button>
              )}
              <div className="col btn_save_study">
                <button
                  className="col-3 btn btn-primary"
                  onClick={handleUpdateSurvey}
                  disabled={disableSave}
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiarySurvey;
