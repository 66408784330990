import React, { useState } from "react";
import {
  Actions,
  AutomationLog,
  AutomationRuleData,
  Timezone
} from "../../../../types";
import { convertTime, formatDate } from "../../../../utilities/utils";
import Action from "../Actions/action.component";
import Condition from "../Conditions/conditionsContainer.component";

export interface AutomationLogsTableProps {
  currentLogs?: AutomationLog[];
  handleViewParticipant: (participantID: string) => void;
  dropdownRuleData: AutomationRuleData;

  // Timezones
  allTimezones: Timezone[];
  timezone: string;
  researcherTimezone: Timezone | null;
}

const AutomationLogsTable: React.FC<AutomationLogsTableProps> = ({
  currentLogs,
  handleViewParticipant,
  dropdownRuleData,
  allTimezones,
  researcherTimezone,
  timezone
}) => {
  const [action, setActions] = useState<Actions[]>([]); // Create state variable for loading status
  const [expandedRowId, setExpandedRowId] = useState<string | null>(null);
  const [expandedCondition, setExpandedCondition] = useState<string | null>(
    null
  );

  const removeAction = () => {
    console.log("removeAction");
  };
  const toggleRow = (rowId: string) => {
    if (expandedRowId === rowId) {
      // If the row is already expanded, hide it
      setExpandedRowId(null);
    } else {
      // If a different row is expanded or none, show the clicked row
      setExpandedRowId(rowId);
    }
  };

  const toggleCondition = (conditionId: string) => {
    if (expandedCondition === conditionId) {
      // If the row is already expanded, hide it
      setExpandedCondition(null);
    } else {
      // If a different row is expanded or none, show the clicked row
      setExpandedCondition(conditionId);
    }
  };
  return (
    <table className="table automation_logs_table">
      <thead>
        <tr>
          {/* participant Mobile, Email, Rule, Actions, Schedules, Run Time, Status */}
          <th>User ID</th>
          <th>External ID</th>
          <th>Mobile</th>
          <th>Email</th>
          <th>Rule</th>
          <th>Conditions</th>
          <th>Actions</th>
          <th>Scheduled</th>
          <th>Run Time</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody className="automations_table_body">
        {currentLogs &&
          currentLogs.map((log) => {
            let timezoneOffset = 0;
            // get participant's timezone offset based off of their timezone, filter though allTimezones
            const participant_offset = allTimezones.find(
              (timezone) => timezone.name === log.participant_timezone
            )?.offset;
            if (timezone === "Participant's Timezone" && participant_offset) {
              timezoneOffset = Number(participant_offset);
            } else if (timezone === "UTC") {
              timezoneOffset = 0;
            } else if (timezone === "ResearcherTimezone") {
              timezoneOffset = researcherTimezone
                ? researcherTimezone.offset
                : 0;
            }

            return (
              <React.Fragment key={log.id}>
                <tr className="automation_log_single_container">
                  <td>
                    <span
                      className="automation_log_participant_name"
                      onClick={() =>
                        handleViewParticipant(log.participant_id.toString())
                      }
                    >
                      {log.participant_id}
                    </span>
                  </td>
                  <td>
                    <span>{log.participant_external_id || "N/A"}</span>
                  </td>
                  <td>{log.participant_mobile_number}</td>
                  <td>{log.participant_email}</td>
                  <td>{log.automation_name}</td>
                  {/* Condition */}
                  <td className="text-center">
                    <div>
                      <button
                        className="show-hide-button"
                        onClick={() => toggleCondition(log.id)}
                      >
                        {expandedCondition === log.id
                          ? "Hide Conditions"
                          : "Show Conditions"}
                      </button>
                    </div>
                    {expandedCondition === log.id && (
                      <div className="condition">
                        {log.automation_conditions ? (
                          Object.keys(log.automation_conditions).map(
                            (index) => {
                              return (
                                <Condition
                                  childConditionGroups={
                                    log.automation_conditions
                                  }
                                  setChildConditionGroups={() => {}}
                                  dropdownRuleData={dropdownRuleData}
                                  editable={false}
                                  viewEditRule={false}
                                />
                              );
                              // return (
                              //   <div key={index}>
                              //     <div>
                              //       <span>Operator: {(condition.operator)}</span>
                              //     </div>
                              //     <div>
                              //       <span>Child Rules:</span>
                              //       {condition.childRules.map((childRule, i) => (
                              //         <div key={i}>
                              //           <span>Type: {childRule.type}</span>
                              //           <span>Value: {childRule.value}</span>
                              //           <span>Options: {childRule.options}</span>
                              //           <span>Variable: {childRule.variable}</span>
                              //           <span>Selection: {childRule.selection}</span>
                              //         </div>
                              //       ))}
                              //     </div>
                              //   </div>
                              // );
                            }
                          )
                        ) : (
                          <div>No conditions available</div>
                        )}
                      </div>
                    )}
                  </td>
                  <td className="text-center">
                    <div>
                      <button
                        className="show-hide-button"
                        onClick={() => toggleRow(log.id)}
                      >
                        {expandedRowId === log.id
                          ? "Hide Actions"
                          : "Show Actions"}
                      </button>
                    </div>
                    {expandedRowId === log.id && (
                      <div className="action">
                        {Array.isArray(log.automation_actions) &&
                        log.automation_actions.length > 0 ? (
                          log.automation_actions.map((index) => (
                            <Action
                              key={index.id}
                              actionID={index.id}
                              removeAction={removeAction}
                              setActions={setActions}
                              dropdownRuleData={dropdownRuleData}
                              editable={false}
                              actionType={index.type}
                              actionVariable={index.variable}
                              actionSelection={index.selection}
                              actionValue={index.value}
                              viewEditRule={false}
                            />
                          ))
                        ) : (
                          <div>No actions available</div>
                        )}
                      </div>
                    )}
                  </td>
                  <td>{log.automation_scheduled ? "Yes" : "No"}</td>
                  <td>
                    {formatDate(
                      convertTime(log.automation_run_time, timezoneOffset)
                    )}
                  </td>
                  <td>{log.automation_status}</td>
                </tr>
              </React.Fragment>
            );
          })}
      </tbody>
    </table>
  );
};

export default AutomationLogsTable;
