import React, { useState } from "react";
import "../appVersions.scss";
import { MajorVersions } from "../../../../../types";
import MajorActions from "../MajorActions/majorAction.component";
import { IconSettings } from "../../../../../assets/images/icons/icons";
import InformationTooltip from "../../../../InputInformation/InfoInputs.component";

export type MajorVersionSingleProps = {
  majorVersion: MajorVersions;
  isSelected?: boolean;
  activeMajorID: number | null;
  handleSelectMajorVersion: (majorID: number) => void;
  handleSettingsClick: (action: string) => void;
};

const MajorVersionSingle: React.FC<MajorVersionSingleProps> = ({
  majorVersion,
  isSelected,
  activeMajorID,
  handleSelectMajorVersion,
  handleSettingsClick
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const { major_version, alias, deprecated } = majorVersion;

  return (
    <>
      <div
        key={major_version}
        className={`single_major_container ${isSelected || isHovered ? "container_selected" : ""}`}
        onClick={() => {
          handleSelectMajorVersion(major_version);
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="major_name_container">
          <div className={`major_name ${deprecated && "deprecated"}`}>
            {alias}: <strong>{major_version}</strong>
            {deprecated && (
              <InformationTooltip
                marginTop="-12px"
                marginLeft="2px"
                position={"right"}
                tooltipText={`This version is deprecated, participants will be prompted to update to the latest version.`}
              />
            )}
          </div>
          <div className="icons_container">
            <button
              className="text-end large"
              onClick={(e) => {
                handleSettingsClick(major_version.toString());
                e.stopPropagation();
              }}
              disabled={activeMajorID === major_version}
            >
              <IconSettings />
            </button>
          </div>
          {activeMajorID === major_version && (
            <MajorActions
              majorVersion={majorVersion}
              closeDropdown={() => handleSettingsClick("close")}
              shown={activeMajorID === major_version}
              selectedMajorVersion={major_version}
              outsideDropdown={() => handleSettingsClick("close")}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MajorVersionSingle;
