import React from "react";
import FieldCheckbox from "../../Fields/fieldCheckbox.component";
import FieldDate from "../../Fields/fieldDate.component";
import FieldImage from "../../Fields/fieldImage.component";
import FieldStatus from "../../Fields/fieldStatus.component";
import FieldText from "../../Fields/fieldText.component";
import FieldTextArea from "../../Fields/fieldTextArea.component";
// language dropdown
import FieldDropdownLanguages from "../../Fields/fieldDropdownLanguages.component";
import FieldFile from "../../Fields/fieldFile.component";
import { AddStudyWizardStepInformationProps } from "../addStudyWizard.component";
import AddStudyWizardFooter from "../addStudyWizardFooter.component";

interface StepAddStudyInformationProps
  extends AddStudyWizardStepInformationProps {}

const StepAddStudyInformation: React.FC<StepAddStudyInformationProps> = ({
  handleWizardForward,
  currentPage,
  totalPages,
  handleWizardBack,
  handleWizardSubmit,
  handleInputChange,
  // Fields
  studyStatus,
  studyName,
  studyDescription,
  studyInformation,
  studyIcon,
  // studyReplyEmail,
  // studyStartDate,
  // studyAutoStart,
  // studyDeadline,
  // studyBudget,
  diaryIncluded,
  diaryName,
  diaryDescription,
  // diaryAppDisplayName,
  diaryIcon,
  diaryAutoStart,
  diaryStartDate,
  singleIncludedFirst,
  singleIncludedSecond,
  singleNameFirst,
  singleDescriptionFirst,
  // singleAppDisplayNameFirst,
  singleIconFirst,
  singleAutoStartFirst,
  singleStartDateFirst,
  singleNameSecond,
  singleDescriptionSecond,
  // singleAppDisplayNameSecond,
  singleIconSecond,
  singleAutoStartSecond,
  singleStartDateSecond,
  // Single Resource
  resourceIncluded,
  resourceName,
  resourceDescription,
  resourceThumbnail,
  resourceFile,
  resourceFileName,
  resourceLanguages,
  // Second,
  resourceIncludedSecond,
  resourceNameSecond,
  resourceDescriptionSecond,
  resourceThumbnailSecond,
  resourceFileSecond,
  resourceFileNameSecond,
  resourceLanguagesSecond,

  inputErrors,
  pageValid,
  allLanguages,
  selectedLanguages
}) => {
  return (
    <div className="card card-default add_study_form_page p-4">
      <h3 className="form_header mb-3">Study Information</h3>
      <div className="add_study_form_page_inputs">
        <FieldText
          label="Study Name"
          tooltip="The project name that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
          tooltipPosition="bottom"
          inputName="studyName"
          inputValue={studyName ? studyName : ""}
          onInputChange={handleInputChange}
          error={inputErrors?.studyName ? inputErrors.studyName : null}
        />
        <FieldTextArea
          label="Study Description"
          tooltip="The project description that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
          inputName="studyDescription"
          inputValue={studyDescription ? studyDescription : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyDescription ? inputErrors.studyDescription : null
          }
        />
        <FieldTextArea
          label="Study Information"
          tooltip="Additional info that will be accessible to respondents in the app, when they click on the Information symbol on the home screen."
          inputName="studyInformation"
          inputValue={studyInformation ? studyInformation : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyInformation ? inputErrors.studyInformation : null
          }
        />
        {/* Disabled Language Field */}
        <FieldDropdownLanguages
          label="Languages"
          tooltip="This will be linked to Resources (same functionality as V1)"
          inputName="studyLanguages"
          selectedLanguages={selectedLanguages}
          allLanguages={allLanguages}
          onInputChange={handleInputChange}
          error={
            inputErrors?.selectedLanguages
              ? inputErrors.selectedLanguages
              : null
          }
        />
        <FieldImage
          label="Study Icon"
          tooltip="The project's icon which will display in both the RMS and also in the app as the main study image."
          inputName="studyIcon"
          inputImage={studyIcon}
          onInputChange={handleInputChange}
          error={inputErrors?.studyIcon ? inputErrors.studyIcon : null}
        />
        <FieldStatus
          label="Study Status"
          tooltip="Control as to whether the study functions are active or inactive (not turned on). This can be edited at a later stage."
          inputName="studyStatus"
          inputValue={studyStatus}
          onInputChange={handleInputChange}
        />

        {/* Reply to email */}
        {/* <FieldText
          label="Study Reply Email"
          tooltip="This is the email address that participants can reply to, used in Automations or Messages. Leave blank for no-reply email."
          inputName="studyReplyEmail"
          inputValue={studyReplyEmail ? studyReplyEmail : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyReplyEmail ? inputErrors.studyReplyEmail : null
          }
        /> */}

        {/* <FieldCheckbox
          label="Study Auto Start"
          subLabel="This will move all Participants in the Pending Bucket to Day 1"
          inputName="studyAutoStart"
          inputValue={studyAutoStart ? studyAutoStart : false}
          onInputChange={handleInputChange}
        />
        <FieldDate
          label="Study Start Date"
          inputName="studyStartDate"
          inputValue={studyStartDate ? studyStartDate : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyStartDate ? inputErrors.studyStartDate : null
          } 
        />*/}
        {/* <FieldDate
          label="Study Deadline"
          inputName="studyDeadline"
          inputValue={studyDeadline ? studyDeadline : ""}
          onInputChange={handleInputChange}
          error={inputErrors?.studyDeadline ? inputErrors.studyDeadline : null}
        />
        <FieldText
          label="Study Budget"
          inputName="studyBudget"
          inputValue={studyBudget ? studyBudget : ""}
          onInputChange={handleInputChange}
          error={inputErrors?.studyBudget ? inputErrors.studyBudget : null}
        /> */}

        <FieldCheckbox
          label="Resource Included"
          tooltip="If selected, you can set the resource settings at the same time as the study settings. If not, you can create a resource at a later stage"
          inputName="resourceIncluded"
          inputValue={resourceIncluded ? resourceIncluded : false}
          onInputChange={handleInputChange}
        />
        {/* if resourceIncluded */}
        {resourceIncluded && (
          <>
            <FieldText
              label="Resource Name"
              tooltip="This is the resource's name which will be used for displaying in RMS and the app"
              inputName="resourceName"
              inputValue={resourceName ? resourceName : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceName ? inputErrors.resourceName : null
              }
            />
            <FieldTextArea
              label="Resource Description"
              tooltip="This is the resource's description which will be used for displaying in RMS and the app"
              inputName="resourceDescription"
              inputValue={resourceDescription ? resourceDescription : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceDescription
                  ? inputErrors.resourceDescription
                  : null
              }
            />
            <FieldImage
              label="Resource Thumbnail"
              tooltip="This is the resource's thumbnail which will be used for displaying in RMS and the app"
              inputName="resourceThumbnail"
              inputImage={resourceThumbnail}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceThumbnail
                  ? inputErrors.resourceThumbnail
                  : null
              }
            />
            <FieldFile
              label="Resource File"
              tooltip="This is the resource's file which will be used for displaying in RMS and the app"
              inputName="resourceFile"
              file={resourceFile}
              fileName={resourceFileName}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceFile ? inputErrors.resourceFile : null
              }
            />
            <FieldDropdownLanguages
              label="Resource Languages"
              tooltip="This will be linked to Resources (same functionality as V1)"
              inputName="resourceLanguages"
              selectedLanguages={resourceLanguages}
              allLanguages={allLanguages}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceLanguages
                  ? inputErrors.resourceLanguages
                  : null
              }
            />

            {/* 2nd Resource */}
            <FieldCheckbox
              label="Second Resource Included"
              tooltip="If selected, you can set the resource settings at the same time as the study settings. If not, you can create a resource at a later stage"
              inputName="resourceIncludedSecond"
              inputValue={
                resourceIncludedSecond ? resourceIncludedSecond : false
              }
              onInputChange={handleInputChange}
            />
          </>
        )}

        {/* if resourceIncluded */}
        {resourceIncludedSecond && (
          <>
            <FieldText
              label="Resource Name"
              tooltip="This is the resource's name which will be used for displaying in RMS and the app"
              inputName="resourceNameSecond"
              inputValue={resourceNameSecond ? resourceNameSecond : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceNameSecond
                  ? inputErrors.resourceNameSecond
                  : null
              }
            />
            <FieldTextArea
              label="Resource Description"
              tooltip="This is the resource's description which will be used for displaying in RMS and the app"
              inputName="resourceDescriptionSecond"
              inputValue={
                resourceDescriptionSecond ? resourceDescriptionSecond : ""
              }
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceDescriptionSecond
                  ? inputErrors.resourceDescriptionSecond
                  : null
              }
            />
            <FieldImage
              label="Resource Thumbnail"
              tooltip="This is the resource's thumbnail which will be used for displaying in RMS and the app"
              inputName="resourceThumbnailSecond"
              inputImage={resourceThumbnailSecond}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceThumbnailSecond
                  ? inputErrors.resourceThumbnailSecond
                  : null
              }
            />
            <FieldFile
              label="Resource File"
              tooltip="This is the resource's file which will be used for displaying in RMS and the app"
              inputName="resourceFileSecond"
              file={resourceFileSecond}
              fileName={resourceFileNameSecond}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceFileSecond
                  ? inputErrors.resourceFileSecond
                  : null
              }
            />
            <FieldDropdownLanguages
              label="Resource Languages"
              tooltip="This will be linked to Resources (same functionality as V1)"
              inputName="resourceLanguagesSecond"
              selectedLanguages={resourceLanguagesSecond}
              allLanguages={allLanguages}
              onInputChange={handleInputChange}
              error={
                inputErrors?.resourceLanguagesSecond
                  ? inputErrors.resourceLanguagesSecond
                  : null
              }
            />
          </>
        )}

        <FieldCheckbox
          label="Diary Included"
          tooltip="If selected, you can set the diary settings now using the wizard functionality. If not, you can still add a diary manually at a later stage."
          inputName="diaryIncluded"
          inputValue={diaryIncluded ? diaryIncluded : false}
          onInputChange={handleInputChange}
        />
        {/* if diaryIncluded */}
        {diaryIncluded && (
          <>
            <FieldText
              label="Diary Name"
              tooltip="The name of the diary that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
              inputName="diaryName"
              inputValue={diaryName ? diaryName : ""}
              onInputChange={handleInputChange}
              error={inputErrors?.diaryName ? inputErrors.diaryName : null}
            />
            <FieldTextArea
              label="Diary Description"
              tooltip="A description of the diary that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
              inputName="diaryDescription"
              inputValue={diaryDescription ? diaryDescription : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryDescription
                  ? inputErrors.diaryDescription
                  : null
              }
            />
            {/* <FieldText
              label="Diary App Display Name"
              tooltip="It was used before, but due to phrase library upgrades, the normal Diary name is being used for app displaying"
              inputName="diaryAppDisplayName"
              inputValue={diaryAppDisplayName ? diaryAppDisplayName : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryAppDisplayName
                  ? inputErrors.diaryAppDisplayName
                  : null
              }
            /> */}
            {/* <FieldCheckbox
          label="Study Auto Start"
          subLabel="This will move all Participants in the Pending Bucket to Day 1"
          inputName="studyAutoStart"
          inputValue={studyAutoStart ? studyAutoStart : false}
          onInputChange={handleInputChange}
        />
        <FieldDate
          label="Study Start Date"
          inputName="studyStartDate"
          inputValue={studyStartDate ? studyStartDate : ""}
          onInputChange={handleInputChange}
          error={
            inputErrors?.studyStartDate ? inputErrors.studyStartDate : null
          } */}
            <FieldCheckbox
              label="Diary Auto Start"
              subLabel={`Automatically moves participants with the "auto start" tag to the diary's first bucket on the Start Date`}
              inputName="diaryAutoStart"
              inputValue={diaryAutoStart ? diaryAutoStart : false}
              onInputChange={handleInputChange}
            />
            <FieldDate
              label="Diary Start Date"
              tooltip="This will be where you select the start date of the diary for the study"
              inputName="diaryStartDate"
              inputValue={diaryStartDate ? diaryStartDate : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.diaryStartDate ? inputErrors.diaryStartDate : null
              }
            />
            <FieldImage
              label="Diary Icon"
              tooltip="This will be where you select the icon of the diary for the study"
              inputName="diaryIcon"
              inputImage={diaryIcon}
              onInputChange={handleInputChange}
              error={inputErrors?.diaryIcon ? inputErrors.diaryIcon : null}
            />
          </>
        )}
        <FieldCheckbox
          label="Single Included"
          tooltip="If selected, you can set the settings for a single (standalone) survey now using the wizard functionality. If not, you can still add an additional survey manually at a later stage."
          inputName="singleIncludedFirst"
          inputValue={singleIncludedFirst ? singleIncludedFirst : false}
          onInputChange={handleInputChange}
        />
        {/* if singleIncluded */}
        {singleIncludedFirst && (
          <>
            <FieldText
              label="Single Name"
              tooltip="The name of the single, standalone survey that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
              inputName="singleNameFirst"
              inputValue={singleNameFirst ? singleNameFirst : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleNameFirst
                  ? inputErrors.singleNameFirst
                  : null
              }
            />
            <FieldTextArea
              label="Single Description"
              tooltip="A description of the single, standalone survey that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
              inputName="singleDescriptionFirst"
              inputValue={singleDescriptionFirst ? singleDescriptionFirst : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleDescriptionFirst
                  ? inputErrors.singleDescriptionFirst
                  : null
              }
            />
            {/* <FieldText
              label="Single App Display Name"
              tooltip="It was used before, but due to phrase library upgrades, the normal Single survey name is being used for app displaying"
              inputName="singleAppDisplayNameFirst"
              inputValue={
                singleAppDisplayNameFirst ? singleAppDisplayNameFirst : ""
              }
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleAppDisplayNameFirst
                  ? inputErrors.singleAppDisplayNameFirst
                  : null
              }
            /> */}
            <FieldCheckbox
              label="Single Auto Start"
              subLabel={`Automatically moves participants with the "auto start" tag to the single's first day on the Start Date`}
              inputName="singleAutoStartFirst"
              inputValue={singleAutoStartFirst ? singleAutoStartFirst : false}
              onInputChange={handleInputChange}
            />
            <FieldDate
              label="Single Start Date"
              tooltip="This will be where you select the start date of the single for the study"
              inputName="singleStartDateFirst"
              inputValue={singleStartDateFirst ? singleStartDateFirst : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleStartDateFirst
                  ? inputErrors.singleStartDateFirst
                  : null
              }
            />
            <FieldImage
              label="Single Icon"
              tooltip="This will be where you select the icon of the single for the study"
              inputName="singleIconFirst"
              inputImage={singleIconFirst}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleIconFirst
                  ? inputErrors.singleIconFirst
                  : null
              }
            />
            <FieldCheckbox
              label="Second Single Included"
              tooltip="This will be where you select the a second singles option for the study"
              inputName="singleIncludedSecond"
              inputValue={singleIncludedSecond ? singleIncludedSecond : false}
              onInputChange={handleInputChange}
            />
          </>
        )}

        {singleIncludedSecond && (
          <>
            <FieldText
              label="Single Name"
              tooltip="The name of the single, standalone survey that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
              inputName="singleNameSecond"
              inputValue={singleNameSecond ? singleNameSecond : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleNameSecond
                  ? inputErrors.singleNameSecond
                  : null
              }
            />
            <FieldTextArea
              label="Single Description"
              tooltip="A description of the single, standalone survey that will be displayed in both the RMS, and within the App for respondents to see. It will be translated within the app."
              inputName="singleDescriptionSecond"
              inputValue={
                singleDescriptionSecond ? singleDescriptionSecond : ""
              }
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleDescriptionSecond
                  ? inputErrors.singleDescriptionSecond
                  : null
              }
            />
            {/* <FieldText
              label="Single App Display Name"
              tooltip="This will be where you select the app display name of the single for the study"
              inputName="singleAppDisplayNameSecond"
              inputValue={
                singleAppDisplayNameSecond ? singleAppDisplayNameSecond : ""
              }
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleAppDisplayNameSecond
                  ? inputErrors.singleAppDisplayNameSecond
                  : null
              }
            /> */}
            <FieldCheckbox
              label="Single Auto Start"
              subLabel={`Automatically moves participants with the "auto start" tag to the single's first day on the Start Date`}
              inputName="singleAutoStartSecond"
              inputValue={singleAutoStartSecond ? singleAutoStartSecond : false}
              onInputChange={handleInputChange}
            />
            <FieldDate
              label="Single Start Date"
              tooltip="This will be where you select the start date of the single for the study"
              inputName="singleStartDateSecond"
              inputValue={singleStartDateSecond ? singleStartDateSecond : ""}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleStartDateSecond
                  ? inputErrors.singleStartDateSecond
                  : null
              }
            />
            <FieldImage
              label="Single Icon"
              tooltip="This will be where you select the icon of the single for the study"
              inputName="singleIconSecond"
              inputImage={singleIconSecond}
              onInputChange={handleInputChange}
              error={
                inputErrors?.singleIconSecond
                  ? inputErrors.singleIconSecond
                  : null
              }
            />
          </>
        )}
        {/* submit */}
        <AddStudyWizardFooter
          currentPage={currentPage}
          totalPages={totalPages}
          handleWizardBack={handleWizardBack}
          handleWizardForward={handleWizardForward}
          pageValid={pageValid}
          handleWizardSubmit={handleWizardSubmit}
        />
      </div>
    </div>
  );
};

export default StepAddStudyInformation;
