import React, { useEffect, useState } from "react";
import "./appVersions.scss";
import { IconAdd } from "../../../../assets/images/icons/icons";
import MajorVersionSingle from "./MajorVersionSingle/majorVerisonSingle.component";
import AddMinorVersionModal from "../../../AddMinorVersionModal/addMinorModal.component";
import MinorVersionSingle from "./MinorVersionSingle/minorVersionSingle.component";
import {
  fetchAppVersions,
  fetchMinorVersionsByMajor,
  newMajorVersion
} from "../../../../models/appVersions.model";
import { AppPlatforms, MajorVersions, MinorVersions } from "../../../../types";
import Swal from "sweetalert2";

const AppVersions: React.FC = () => {
  const [isMinorLoading, setIsMinorLoading] = useState<boolean>(false);
  const [isMajorLoading, setIsMajorLoading] = useState<boolean>(true);

  const [environmentControl, setEnvironmentControl] =
    useState<keyof AppPlatforms["android"]>("production");
  const [platformControl, setPlatformControl] =
    useState<keyof AppPlatforms>("android");
  const [selectedMajorVersion, setSelectedMajorVersion] = useState<
    number | null
  >(null);
  const [activeMinorID, setActiveMinorID] = useState<number | null>(null);
  const [activeMajorID, setActiveMajorID] = useState<number | null>(null);

  // Data
  const [majorVersions, setMajorVersions] = useState<AppPlatforms | null>(null);
  const [minorVersions, setMinorVersions] = useState<MinorVersions[]>([]);
  const [filteredMajorVersions, setFilteredMajorVersions] = useState<
    MajorVersions[]
  >([]);
  const [latestMinorVersion, setLatestMinorVersion] = useState<number | null>(
    null
  );

  // Modal
  const [showAddMinorModal, setShowAddMinorModal] = useState(false);

  const handleSelectMajorVersion = async (majorID: number) => {
    if (isMinorLoading) return;
    setSelectedMajorVersion(majorID);

    // Fetch minor versions
    await getMinorVersions(majorID);
  };

  // Fetch Major Versions
  useEffect(() => {
    const fetchMajorVersions = async () => {
      setIsMajorLoading(true);
      try {
        const response = await fetchAppVersions();
        if (typeof response === "string") {
          console.error("Error fetching major versions", response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while fetching major versions",
            confirmButtonColor: "#3085d6"
          });
          return;
        } else {
          setMajorVersions(response);
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while fetching major versions",
          confirmButtonColor: "#3085d6"
        });
        setMajorVersions(null);
      } finally {
        setIsMajorLoading(false);
      }
    };

    const promises = [fetchMajorVersions()];

    Promise.all(promises)
      .then(() => {
        setIsMajorLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching major versions", error);
        setIsMajorLoading(false);
      });
  }, []);

  // Fetch Minor Versions
  const getMinorVersions = async (majorID: number) => {
    try {
      setIsMinorLoading(true);
      const response = await fetchMinorVersionsByMajor(
        "unique_app_id",
        platformControl,
        majorID,
        environmentControl
      );

      if (typeof response === "string") {
        console.error("Error fetching major versions", response);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while fetching minor versions",
          confirmButtonColor: "#3085d6"
        });
        setIsMinorLoading(false);
        return;
      } else {
        setMinorVersions(response.slice().reverse());
      }

      setIsMinorLoading(false);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while fetching minor versions",
        confirmButtonColor: "#3085d6"
      });
      setIsMinorLoading(false);
    } finally {
      setIsMinorLoading(false);
    }
  };

  // New Major Versions
  const createMajorVersion = async () => {
    const result = await Swal.fire({
      icon: "info",
      title: "New Major Version",
      html: `Are you sure you want to create a new major version? <br>
      You will be adding a new major version to <strong>BOTH</strong> Environments and Platforms.
      `,
      input: "text",
      inputPlaceholder: "Enter alias",
      inputValidator: (value) => {
        if (!value) {
          return "Alias is required.";
        } else if (value.length < 3 || value.length > 50) {
          return "Alias must be between 3 and 50 characters.";
        } else if (!/^[a-zA-Z0-9\s]*$/.test(value)) {
          return "Alias must be alphanumeric.";
        }
      },
      preConfirm: (alias) => {
        return alias;
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      cancelButtonText: "Cancel",
      confirmButtonColor: "#3085d6"
    });

    if (result.isConfirmed) {
      Swal.fire({
        title: "Creating New Major Version",
        text: "Please wait...",
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });
      try {
        const response = await newMajorVersion(platformControl, result.value);
        if (response) {
          await Swal.fire({
            icon: "success",
            title: "Success!",
            text: "New major version created successfully",
            didClose: () => {
              window.location.reload();
            }
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while creating the new major version",
            confirmButtonColor: "#3085d6"
          });
        }
      } catch (error) {
        console.error("Error creating new major version:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while creating the new major version",
          confirmButtonColor: "#3085d6"
        });
      }
    }
  };

  // Dropdown Minor Action handling
  const handleMinorActionsDropdown = (minorID: string | null) => {
    if (minorID === "close" || minorID === "outside") {
      setActiveMinorID(null);
      return;
    }

    if (minorID === activeMinorID) {
      setActiveMinorID(null);
    } else {
      setActiveMinorID(Number(minorID));
    }
  };

  // Dropdown Major Action handling
  const handleMajorActionsDropdown = (majorID: string | null) => {
    if (majorID === "close" || majorID === "outside") {
      setActiveMajorID(null);
      return;
    }

    if (majorID === activeMajorID) {
      setActiveMajorID(null);
    } else {
      setActiveMajorID(Number(majorID));
    }
  };

  // Filter Major Versions
  useEffect(() => {
    // If major version changes, set filtered major versions based on platform and environment
    if (majorVersions) {
      const filteredMajorVersions = majorVersions[platformControl][
        environmentControl
      ]
        .slice()
        .reverse();
      setFilteredMajorVersions(filteredMajorVersions);

      // If selected major version is in the filtered list, fetch minor versions
      if (
        selectedMajorVersion &&
        filteredMajorVersions.some(
          (version) => version.major_version === selectedMajorVersion
        )
      ) {
        getMinorVersions(selectedMajorVersion);
      } else {
        setSelectedMajorVersion(null);
        setMinorVersions([]);
      }

      // Select the first major version if none are selected and there are major versions
      if (!selectedMajorVersion && filteredMajorVersions.length > 0) {
        const firstMajorVersion = filteredMajorVersions[0].major_version;
        setSelectedMajorVersion(firstMajorVersion);
        getMinorVersions(firstMajorVersion);
      }
    }
  }, [majorVersions, platformControl, environmentControl]);

  // Set the latest minor version, if there are minor versions, the latest is the first in the array that isn't inactive
  useEffect(() => {
    if (minorVersions.length > 0) {
      const latest = minorVersions.find((minor) => minor.active);
      if (latest) {
        setLatestMinorVersion(latest.minor_version);
      } else {
        setLatestMinorVersion(null);
      }
    }
  }, [minorVersions]);

  return (
    <>
      <div className={`container-fluid app_versions_container`}>
        <h3>App Versions Manager</h3>
        <div className="major_minor_container">
          {/* HEADER */}
          <div className="environment_header d-flex">
            {/* Right Header */}
            <div
              className="environment_header_tabs d-flex"
              style={{ flexBasis: "85%" }}
            >
              <div className="d-flex align-items-center  w-100">
                <div
                  className={`environment_header_tab ${
                    platformControl === "android" ? "selected" : ""
                  }`}
                  onClick={() => {
                    if (isMajorLoading || isMinorLoading) return;
                    setPlatformControl("android");
                  }}
                >
                  <h5 className="tab_title">Android</h5>
                </div>
                <div
                  className={`environment_header_tab ms-2 ${
                    platformControl === "ios" ? "selected" : ""
                  }`}
                  onClick={() => {
                    if (isMajorLoading || isMinorLoading) return;
                    setPlatformControl("ios");
                  }}
                >
                  <h5 className="tab_title">iOS</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="environment_header d-flex">
            {/* Left Header */}
            <div
              className="environment_header_tabs"
              style={{ flexBasis: "15%" }}
            >
              <div
                className={`environment_header_tab ${
                  environmentControl === "production" ? "selected" : ""
                }`}
                onClick={() => {
                  if (isMajorLoading || isMinorLoading) return;
                  setEnvironmentControl("production");
                }}
              >
                <h5 className="tab_title">Production</h5>
              </div>
              <div
                className={`environment_header_tab ms-2 ${
                  environmentControl === "staging" ? "selected" : ""
                }`}
                onClick={() => {
                  if (isMajorLoading || isMinorLoading) return;
                  setEnvironmentControl("staging");
                }}
              >
                <h5 className="tab_title">Staging</h5>
              </div>
            </div>
          </div>

          <div className="content_container">
            {/* Left Side Major Vers*/}
            <div className="major_versions_container">
              <div className="major_versions_header_container">
                <h5 className="m-0">Major Versions</h5>
                {/* Add */}
                <div className="d-flex align-items-center icons_container">
                  <div
                    className="icon_add medium"
                    onClick={() => {
                      createMajorVersion();
                    }}
                  >
                    <IconAdd />
                  </div>
                </div>
              </div>
              <div className="all_app_versions_container">
                {isMajorLoading ? (
                  <div className="d-flex justify-content-center mt-2 custom_spinner_container">
                    <div className="spinner-border" role="status"></div>
                  </div>
                ) : filteredMajorVersions.length === 0 ? (
                  <div className="text-center">No major versions found.</div>
                ) : (
                  filteredMajorVersions.map((majorVersion, index) => (
                    <MajorVersionSingle
                      key={index}
                      majorVersion={majorVersion}
                      isSelected={
                        selectedMajorVersion === majorVersion.major_version
                      }
                      activeMajorID={activeMajorID}
                      handleSettingsClick={handleMajorActionsDropdown}
                      handleSelectMajorVersion={() =>
                        handleSelectMajorVersion(majorVersion.major_version)
                      }
                    />
                  ))
                )}
                {/* <div className="text-center">No results found</div> */}
              </div>
            </div>
            {/* Right Side Minor Vers*/}
            <div className="minor_versions_container">
              <table className="table table-hover">
                <thead className="table-light">
                  <tr>
                    <th scope="">
                      <div className="d-flex justify-content-between">
                        <div> Minor Version</div>
                        {environmentControl === "staging" &&
                          selectedMajorVersion && (
                            <div className="d-flex align-items-center icons_container">
                              <div
                                className="icon_add small d-flex align-items-center"
                                onClick={() => {
                                  setShowAddMinorModal(true);
                                }}
                              >
                                <IconAdd />
                              </div>
                            </div>
                          )}
                      </div>
                    </th>
                    <th scope="col">Description</th>
                    <th scope="col">Status</th>
                    <th scope="col">Installs</th>
                    <th scope="col">Rollbacks</th>
                    <th scope="col">Created At</th>
                    <th scope="col" className="text-end actions_cell">
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {/* Display Minor Versions */}
                  {isMinorLoading ? (
                    <tr>
                      <td colSpan={7} className="text-center">
                        <div className="d-flex justify-content-center custom_spinner_container">
                          <div className="spinner-border" role="status"></div>
                        </div>
                      </td>
                    </tr>
                  ) : minorVersions.length === 0 ? (
                    <tr>
                      <td colSpan={7} className="text-center">
                        {selectedMajorVersion === null
                          ? "Please select a major version."
                          : "No minor versions found."}
                      </td>
                    </tr>
                  ) : (
                    selectedMajorVersion &&
                    minorVersions.map((minorVersion, index) => (
                      <MinorVersionSingle
                        key={index}
                        minorVersion={minorVersion}
                        activeMinorID={activeMinorID}
                        handleSettingsClick={handleMinorActionsDropdown}
                        environmentType={environmentControl}
                        platformType={platformControl}
                        selectedMajorVersion={selectedMajorVersion}
                        latestMinorVersion={latestMinorVersion}
                        fetchMinorCallBack={() =>
                          getMinorVersions(selectedMajorVersion)
                        }
                      />
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      {showAddMinorModal && selectedMajorVersion && (
        <AddMinorVersionModal
          closeModal={() => setShowAddMinorModal(false)}
          shown={showAddMinorModal}
          environment={environmentControl}
          major_version={selectedMajorVersion}
          platform={platformControl}
          fetchMinorCallBack={() => getMinorVersions(selectedMajorVersion)}
        />
      )}
    </>
  );
};

export default AppVersions;
