// filters container component
// receives filters from parent component and renders them

import React, { useEffect, useState } from "react";
import * as Icons from "../../../assets/images/icons/icons";
import {
  Country,
  Language,
  LanguageLibraryCategoryType,
  Participant,
  ResearcherRole,
  Tags,
  Timezone
} from "../../../types";
import BucketsFilter from "./filterBuckets.components";
import BucketsComplianceFilter from "./filterBucketsCompliance.component";
import FilterLangLibraryCategoryType from "./filterCategoryType.component";
import CountryFilter from "./filterCountries.component";
import FilterDatePicker from "./filterDatePicker.componenet";
import LanguagesFilter from "./filterLanguages.component";
import MultipleParticipantIDsFilter from "./filterMultipleParticipantIDs.component";
import FilterRoles from "./filterRoles.component";
import RulesFilter from "./filterRules.component";
import ParticipantSearch from "./filterSearch.component";
import BasicStringFilterProps from "./filterStrings.component";
import TagsFilter from "./filterTags.component";
import TimezonesFilter from "./filterTimezones.component";
import UDIDFilter from "./filterUDID.component";
import "./filtersContainer.scss";
import SortOrderByDate from "./sortOrderByDate.component";

interface FiltersContainerProps {
  tableType: string;
  filterApplied: boolean;
  setFilterApplied: (value: boolean) => void;
  handleClearFilters: () => void;
  jsonTagsData?: Tags[];
  buckets?: Participant["participant_buckets"];
  bucketCompliance?: string[];
  bucketsSelected?: boolean;
  languages?: Language[];
  countries?: Country[];
  timezones?: Timezone[];
  roles?: ResearcherRole[];
  statuses?: string[];
  flagFilter?: string[];
  rules?: string[];
  genders?: string[];
  clients?: string[];
  reportTypes?: string[];
  date?: string;
  fromDate?: string;
  toDate?: string;
  ageRanges?: string[];
  categoryTypes?: LanguageLibraryCategoryType[];
  participantIDs?: string[];
  setSearch?: (value: string) => void;
  setTagsFilter?: (tags: string[]) => void;
  setBucketsFilter?: (buckets: string[]) => void;
  setBucketsComplianceFilter?: (bucketsCompliance: string[]) => void;
  setStatusFilter?: (status: string[]) => void;
  setFlagFilter?: (flagFilter: string[]) => void;
  setLanguagesFilter?: (languages: string[]) => void;
  setCountryFilter?: (countries: string[]) => void;
  setTimezoneFilter?: (timezones: string[]) => void;
  setUDIDFilter?: (UIDstatus: boolean) => void;
  setRulesFilter?: (rules: string[]) => void;
  setCategoryTypeFilter?: (categoryTypeID: string[]) => void;
  setGendersFilter?: (genders: string[]) => void;
  setClientsFilter?: (clients: string[]) => void;
  setFilterReportTypes?: (reportTypes: string[]) => void;
  setFromDate?: (fromDate: string) => void;
  setToDate?: (toDate: string) => void;
  setAgeRangesFilter?: (ageRanges: string[]) => void;
  setParticipantIDs?: (participantIDs: string[]) => void;
  setRolesFilter?: (roles: string[]) => void;
  setSortOrderByDate?: (sortOrder: string) => void;
}

const FiltersContainer: React.FC<FiltersContainerProps> = ({
  tableType,
  setSearch,
  jsonTagsData,
  setTagsFilter,
  buckets,
  setBucketsFilter,
  bucketCompliance,
  setBucketsComplianceFilter,
  bucketsSelected,
  filterApplied,
  languages,
  countries,
  timezones,
  statuses,
  flagFilter,
  rules,
  categoryTypes,
  genders,
  clients,
  reportTypes,
  fromDate,
  toDate,
  date,
  ageRanges,
  participantIDs,
  roles,
  setStatusFilter,
  setFlagFilter,
  setLanguagesFilter,
  setCountryFilter,
  setTimezoneFilter,
  setUDIDFilter,
  handleClearFilters,
  setRulesFilter,
  setCategoryTypeFilter,
  setGendersFilter,
  setClientsFilter,
  setFilterReportTypes,
  setFromDate,
  setToDate,
  setAgeRangesFilter,
  setParticipantIDs,
  setRolesFilter,
  setSortOrderByDate
}) => {
  // Store filters to be displayed
  const [fields, setFields] = useState<string[]>([]);

  useEffect(() => {
    // Set filters to be displayed based on table type
    switch (tableType) {
      case "ClientParticipants":
        setFields([
          "search",
          "tags",
          "status",
          "languages",
          "country",
          "flag",
          "participantIDs"
          // "sortOrderByDate",
          // "UDID"
        ]);
        break;
      case "StudyParticipants":
        setFields([
          "search",
          "tags",
          "status",
          "languages",
          "country",
          "flag",
          "participantIDs",
          "buckets",
          "bucketCompliance",
          "sortOrderByDate"
          // ,"UDID"
        ]);
        break;
      case "SurveyParticipants":
        setFields([
          "search",
          "tags",
          "status",
          "languages",
          "country",
          "flag",
          "participantIDs",
          "sortOrderByDate"
          // , "UDID"
        ]);
        break;
      case "DiaryParticipants":
        setFields([
          "search",
          "tags",
          "status",
          "languages",
          "country",
          "flag",
          "participantIDs",
          "sortOrderByDate"
          // , "UDID"
        ]);
        break;
      case "AutomationLogs":
        setFields(["search", "status", "rules"]);
        break;
      case "LanguageLibrary":
        setFields(["langLibraryCategoryType"]);
        break;
      case "leadsTable":
        setFields([
          "search",
          "languages",
          "country",
          // "ageRange",
          // "gender",
          "leadsIDs",
          "sortOrderByDate"
        ]);
        break;
      case "allReports":
        setFields(["search", "date", "clients", "reportType"]);
        break;
      case "clientReports":
        setFields(["search", "date", "reportType"]);
        break;
      case "researchersTable":
        setFields(["search", "country", "languages", "timezone", "role"]);
        break;
      case "resources":
        setFields(["search", "languages", "status"]);
        break;
      case "custom_reports":
        setFields(["search"]);
        break;
      default:
        setFields(["UnknownTableType"]);
    }
  }, [tableType]);

  return (
    <div className="table_filter_options col-auto me-auto ">
      {/* map fields so order can be changed */}
      {fields.map((field, index) => {
        switch (field) {
          case "search":
            return setSearch ? (
              <ParticipantSearch
                key={`table_filter_options_${field}_${index}`}
                onSearchChange={setSearch}
                filtersCleared={!filterApplied}
              />
            ) : (
              <span className="m-2">setSearch is missing</span>
            );
          case "tags":
            return setTagsFilter && jsonTagsData ? (
              <TagsFilter
                key={`table_filter_options_${field}_${index}`}
                jsonTagsData={jsonTagsData}
                onTagsFilterChange={setTagsFilter}
                filtersCleared={!filterApplied}
                location={"table"}
              />
            ) : (
              <span className="m-2">setTagsFilter is missing</span>
            );
          case "buckets":
            return setBucketsFilter && buckets ? (
              <BucketsFilter
                key={`table_filter_options_${field}_${index}`}
                buckets={buckets}
                onBucketsFilterChange={setBucketsFilter} // Use the prop callback directly
                filtersCleared={!filterApplied}
                location={"table"}
              />
            ) : null;

          case "bucketCompliance":
            return setBucketsComplianceFilter &&
              bucketCompliance &&
              bucketsSelected ? (
              <BucketsComplianceFilter
                key={`table_filter_options_${field}_${index}`}
                bucketCompliance={bucketCompliance}
                onComplianceFilterChange={setBucketsComplianceFilter}
                filtersCleared={!filterApplied}
              />
            ) : null;

          case "status":
            return setStatusFilter && statuses ? (
              // <StatusFilter
              //   key={`table_filter_options_${field}_${index}`}
              //   onStatusFilterChange={setStatusFilter}
              //   filtersCleared={!filterApplied}
              //   statuses={statuses}
              // />
              <BasicStringFilterProps
                key={`table_filter_options_${field}_${index}`}
                jsonData={statuses}
                onFilterChange={setStatusFilter}
                filtersCleared={!filterApplied}
                location={"table"}
                name="Status"
              />
            ) : (
              <span className="m-2">
                statuses or setStatusFilter is missing
              </span>
            );
          case "languages":
            return setLanguagesFilter && languages ? (
              <LanguagesFilter
                key={`table_filter_options_${field}_${index}`}
                jsonlanguagesData={languages}
                onLanguagesFilterChange={setLanguagesFilter}
                filtersCleared={!filterApplied}
                location={"table"}
              />
            ) : (
              <span className="m-2">
                setLanguagesFilter or languages is missing
              </span>
            );
          case "country":
            return countries && setCountryFilter ? (
              <CountryFilter
                key={`table_filter_options_${field}_${index}`}
                jsonCountriesData={countries}
                onCountryFilterChange={setCountryFilter}
                filtersCleared={!filterApplied}
                location={"table"}
              />
            ) : (
              <span className="m-2">
                setCountryFilter or countries is missing
              </span>
            );
          case "flag":
            return (
              setFlagFilter && (
                // <FlagFilter
                //   key={`table_filter_options_${field}_${index}`}
                //   onFlagFilterChange={setFlagFilter}
                //   filtersCleared={!filterApplied}
                //   flag={["flag"]}
                // />
                <BasicStringFilterProps
                  key={`table_filter_options_${field}_${index}`}
                  jsonData={["flagged", "unflagged"]}
                  onFilterChange={setFlagFilter}
                  filtersCleared={!filterApplied}
                  location={"table"}
                  name="Flag"
                />
              )
            );
          case "timezone":
            return setTimezoneFilter && timezones ? (
              <TimezonesFilter
                key={`table_filter_options_${field}_${index}`}
                jsonTimezonesData={timezones}
                onTimezoneFilterChange={setTimezoneFilter}
                filtersCleared={!filterApplied}
                location={"table"}
              />
            ) : (
              <span className="m-2">
                setTimezoneFilter or timezones is missing
              </span>
            );
          case "role":
            return setRolesFilter && roles ? (
              <FilterRoles
                key={`table_filter_options_${field}_${index}`}
                jsonRolesData={roles}
                onRoleFilterChange={setRolesFilter}
                filtersCleared={!filterApplied}
                location={"table"}
              />
            ) : (
              <span className="m-2">setRolesFilter or roles is missing</span>
            );
          case "UDID":
            return setUDIDFilter ? (
              <UDIDFilter
                key={`table_filter_options_${field}_${index}`}
                onUDIDFilterChange={setUDIDFilter}
                filtersCleared={!filterApplied}
              />
            ) : (
              <span className="m-2">setUDIDFilter is missing</span>
            );
          case "rules":
            return setRulesFilter && rules ? (
              <RulesFilter
                key={`table_filter_options_${field}_${index}`}
                jsonRulesData={rules}
                onRulesFilterChange={setRulesFilter}
                filtersCleared={!filterApplied}
                location={"table"}
              />
            ) : (
              <span className="m-2">setRulesFilter or rules is missing</span>
            );
          case "langLibraryCategoryType":
            return categoryTypes && setCategoryTypeFilter ? (
              <FilterLangLibraryCategoryType
                key={`table_filter_options_${field}_${index}`}
                jsonTypesData={categoryTypes}
                onFilterChange={setCategoryTypeFilter}
                filtersCleared={!filterApplied}
                location={"table"}
              />
            ) : (
              <span className="m-2">langLibraryCategoryType</span>
            );
          case "ageRange":
            return setAgeRangesFilter && ageRanges ? (
              <BasicStringFilterProps
                key={`table_filter_options_${field}_${index}`}
                jsonData={ageRanges}
                onFilterChange={setAgeRangesFilter}
                filtersCleared={!filterApplied}
                location={"table"}
                name="Age"
                includeSearch={false}
              />
            ) : (
              <span className="m-2">setGenderFilter or genders is missing</span>
            );
          case "gender":
            return setGendersFilter && genders ? (
              <BasicStringFilterProps
                key={`table_filter_options_${field}_${index}`}
                jsonData={genders}
                onFilterChange={setGendersFilter}
                filtersCleared={!filterApplied}
                location={"table"}
                name="Gender"
                includeSearch={false}
              />
            ) : (
              <span className="m-2">setGenderFilter or genders is missing</span>
            );
          case "clients":
            return setClientsFilter && clients ? (
              <BasicStringFilterProps
                key={`table_filter_options_${field}_${index}`}
                jsonData={clients}
                onFilterChange={setClientsFilter}
                filtersCleared={!filterApplied}
                location={"reports"}
                name="Client"
                includeSearch={true}
              />
            ) : (
              <span className="m-2">
                setClientsFilter or clients is missing
              </span>
            );
          case "reportType":
            return setFilterReportTypes && reportTypes ? (
              <BasicStringFilterProps
                key={`table_filter_options_${field}_${index}`}
                jsonData={reportTypes}
                onFilterChange={setFilterReportTypes}
                filtersCleared={!filterApplied}
                location={"reports"}
                name="Report Type"
                includeSearch={true}
              />
            ) : (
              <span className="m-2">
                setFilterReportTypes or reportTypes is missing
              </span>
            );
          case "date":
            return date ? (
              <FilterDatePicker
                location={"reports"}
                filtersCleared={!filterApplied}
                fromDate={fromDate ? fromDate : ""}
                toDate={toDate ? toDate : ""}
                setFromDate={setFromDate ? setFromDate : () => {}}
                setToDate={setToDate ? setToDate : () => {}}
              />
            ) : (
              <span className="m-2">Date is missing</span>
            );
          case "participantIDs":
            return setParticipantIDs && participantIDs ? (
              <MultipleParticipantIDsFilter
                key={`table_filter_options_${field}_${index}`}
                jsonData={participantIDs}
                onFilterChange={setParticipantIDs}
                filtersCleared={!filterApplied}
                location={"table"}
                name="Participant IDs"
              />
            ) : (
              <span className="m-2">
                setParticipantIDs or participantIDs is missing
              </span>
            );
          case "leadsIDs":
            return setParticipantIDs && participantIDs ? (
              <MultipleParticipantIDsFilter
                key={`table_filter_options_${field}_${index}`}
                jsonData={participantIDs}
                onFilterChange={setParticipantIDs}
                filtersCleared={!filterApplied}
                location={"table"}
                name="Lead IDs"
              />
            ) : (
              <span className="m-2">
                setParticipantIDs or participantID is missing
              </span>
            );

          case "sortOrderByDate":
            return setSortOrderByDate ? (
              <SortOrderByDate
                key={`table_filter_options_${field}_${index}`}
                filtersCleared={!filterApplied}
                onSortOrderByDateChange={setSortOrderByDate}
              />
            ) : (
              //This is where the message goes if the table does not have a date joined field. (setSortOrderByDate is missing)
              <span className="m-2"></span>
            );
          case "UnknownTableType":
            return <p key={field + index}>Unknown table type: {tableType}</p>;
          default:
            return (
              <span className="m-2" key={"unknown" + index}>
                Unknown case: {field}
              </span>
            );
        }
      })}
      {fields && fields[0] !== "UnknownTableType" && (
        <button
          className="btn btn-primary btn_clear_filters"
          disabled={!filterApplied}
          onClick={handleClearFilters}
          title="Clear filters"
        >
          <Icons.IconBroom className="icon icon_white" />
        </button>
      )}
    </div>
  );
};

export default FiltersContainer;
