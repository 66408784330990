// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner_loader {
  position: absolute;
  z-index: 4;
  width: 20px;
  height: 20px;
}

.chat_container {
  height: 80vh;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.chat_container .card {
  box-shadow: unset;
}
.chat_container .row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: inherit;
}
.chat_container .col_1 {
  padding: 0;
  height: inherit;
}
.chat_container .col_2 {
  padding: 0px 1px;
  height: inherit;
  z-index: 2;
}
.chat_container .col_3 {
  padding: 0;
  padding-left: 0px;
  height: inherit;
}

.update-channel-buttons {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  margin: auto;
}`, "",{"version":3,"sources":["webpack://./src/components/StudySurveyTabSingle/Chat/chat.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;AACF;;AACA;EAME,YAAA;EAGA,0CAAA;EACA,kBAAA;AALF;AAOE;EACE,iBAAA;AALJ;AAQE;EACE,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;AANJ;AASE;EACE,UAAA;EACA,eAAA;AAPJ;AAUE;EACE,gBAAA;EACA,eAAA;EACA,UAAA;AARJ;AAWE;EACE,UAAA;EACA,iBAAA;EACA,eAAA;AATJ;;AAYA;EACE,aAAA;EACA,8BAAA;EACA,SAAA;EACA,YAAA;AATF","sourcesContent":[".spinner_loader {\n  position: absolute;\n  z-index: 4;\n  width: 20px;\n  height: 20px;\n}\n.chat_container {\n  // display: flex;\n  // flex-direction: row;\n  // justify-content: space-between;\n  // background-color: rgb(161, 161, 161);\n  // padding: 0px 15px;\n  height: 80vh;\n  // max height with viewheight - 300 px\n  // max-height: calc(10vh);\n  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);\n  border-radius: 5px;\n\n  .card {\n    box-shadow: unset;\n  }\n\n  .row_container {\n    display: flex;\n    flex-direction: row;\n    justify-content: space-between;\n    height: inherit;\n  }\n\n  .col_1 {\n    padding: 0;\n    height: inherit;\n  }\n\n  .col_2 {\n    padding: 0px 1px;\n    height: inherit;\n    z-index: 2;\n  }\n\n  .col_3 {\n    padding: 0;\n    padding-left: 0px;\n    height: inherit;\n  }\n}\n.update-channel-buttons {\n  display: flex;\n  justify-content: space-between;\n  gap: 10px;\n  margin: auto;\n\n  // @media screen and (max-width: 768px) {\n  //   grid-template-columns: repeat(1, 1fr);\n  // }\n}\n\n// .chat_container > :nth-child(2) {\n//   flex-grow: 1; /* Grow the second child to fill remaining space */\n// }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
