import React, { useEffect, useState } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import {
  updateResource,
  uploadResource
} from "../../../../models/resources.model";
import { Language, ResourceData } from "../../../../types";
import { capitalize, resizeImage } from "../../../../utilities/utils";
import LanguagesFilter from "../../../Tables/Filters/filterLanguages.component";
import "./manageResourceModal.scss";

interface ResourceManagerModalProps {
  closeModal: () => void;
  shown: boolean;
  type: "share" | "edit" | "create" | "delete" | "";
  currentResource?: ResourceData | null;
  languages: Language[];
}

export type UploadData = {
  name: string;
  description: string;
  langISOs: string[];
  fileName: string;
  file: string;
  thumbnail: string;
};

export type UpdateData = {
  name: string;
  description: string;
  langISOs: string[];
  status: string;
  thumbnail: string;
};

const ResourceManagerModal: React.FC<ResourceManagerModalProps> = ({
  closeModal,
  shown,
  type,
  languages,
  currentResource
}) => {
  const { clientID, studyID } = useParams();

  // File from the user
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
  // File type

  // Check if the file has been uploaded

  // Errors states, used to display errors to the user
  const [hasUploaded, setHasUploaded] = useState(false);
  const [fileName, setFileName] = useState<string>("");

  // For loading spinner
  const [submitting, setSubmitting] = useState(false);
  const [dots, setDots] = useState(".");

  // Update Form
  const [sendFormDataUpdate, setSendFormDataUpdate] = useState<UpdateData>({
    name: "",
    description: "",
    langISOs: [],
    status: "",
    thumbnail: ""
  });

  // Upload Form
  const [sendFormDataUpload, setSendFormDataUpload] = useState<UploadData>({
    name: "",
    description: "",
    langISOs: [],
    fileName: "",
    file: "",
    thumbnail: ""
  });

  const [dataChange, setDataChange] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>([]);

  // For pre-populating inputs, if editing
  useEffect(() => {
    if (type === "edit" && currentResource) {
      setSendFormDataUpdate({
        name: currentResource.name,
        description: currentResource.description,
        langISOs: currentResource.lang_iso,
        status: currentResource.status,
        thumbnail: currentResource.thumbnail_url
      });
      setSelectedLanguages(currentResource.lang_iso);
    }
  }, [currentResource]);

  // =================================== CLEARING ===================================

  const clearFile = async () => {
    setFileName("");
    setSubmitting(false);
    setHasUploaded(false);
    setSendFormDataUpload({
      name: "",
      description: "",
      langISOs: [],
      fileName: "",
      file: "",
      thumbnail: ""
    });
    setSendFormDataUpdate({
      name: "",
      description: "",
      langISOs: [],
      status: "",
      thumbnail: ""
    });
    setDataChange(false);
  };

  // Closes the modal, clears the file
  const handleClose = () => {
    clearFile();
    closeModal();
  };

  // =================================== INPUTS ===================================

  // Handles the pdf upload
  const handleOnDrop = async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    console.log("HIT");
    if (file) {
      console.log(file);
      // Only allow PDF files
      if (file.name.endsWith(".pdf")) {
        // ===================== CSV =====================
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = reader.result;
          console.log("data", data);
          if (data) {
            // const base64 = data.toString().split(",")[1];
            // console.log("base64 pdf", base64);
            setSendFormDataUpload((prevState) => ({
              ...prevState,
              file: data.toString(),
              fileName: file.name
            }));
            setHasUploaded(true);
            setFileName(file.name);
          }
        };
        reader.readAsDataURL(file);
        setDataChange(true);
      } else {
        Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Please upload a PDF file",
          confirmButtonColor: "#3085d6"
        });
        return false;
      }
    } else {
      console.log("NO FILE");
    }
  };

  // Name | Description | Status | Thumbnail Changes
  const handleInputChange = async (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    console.log(e.target.name, "e.target.name");
    if (
      e.target.name === "resource_thumbnail" &&
      e.target instanceof HTMLInputElement
    ) {
      const file = e.target.files?.[0];
      console.log(file, "file");
      // if file is not one of these: .jpg,jpeg,.png,.webp, return
      if (
        !file?.name.endsWith(".jpg") &&
        !file?.name.endsWith(".jpeg") &&
        !file?.name.endsWith(".png") &&
        !file?.name.endsWith(".webp")
      ) {
        Swal.fire({
          icon: "error",
          title: "Invalid file type",
          text: "Please upload a .jpg, .jpeg, .png, or .webp file",
          confirmButtonColor: "#3085d6"
        });
        return;
      }
      if (file) {
        const reader = new FileReader();
        reader.onloadend = async () => {
          const result = reader.result;
          console.log(result, "result");

          if (typeof result === "string") {
            const resizedImage = await resizeImage(file, 800);
            if (!resizedImage) {
              Swal.fire({
                icon: "error",
                title: "Error resizing image",
                text: "Please try a different image",
                confirmButtonColor: "#3085d6"
              });
              return;
            }

            if (type === "create") {
              setSendFormDataUpload((prevState) => ({
                ...prevState,
                thumbnail: resizedImage
              }));
            } else if (type === "edit") {
              setSendFormDataUpdate((prevState) => ({
                ...prevState,
                thumbnail: resizedImage
              }));
            } else {
              console.log("Unknown type: ", type);
            }
          }
        };
        reader.readAsDataURL(file);
        setDataChange(true);
      }
    } else {
      // Handle other input/select types
      console.log(e.target.value, "e.target.value");
      if (type === "create") {
        setSendFormDataUpload((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value
        }));
      } else if (type === "edit") {
        console.log(e.target.name, "e.target.name");
        console.log(e.target.value, "e.target.value");
        setSendFormDataUpdate((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value
        }));
      } else {
        console.log("Unknown type: ", type);
      }
      setDataChange(true);
    }
  };

  // Handle language changes
  useEffect(() => {
    // Update the languages
    console.log(selectedLanguages);
    console.log(currentResource?.lang_iso);

    if (type === "create") {
      setSendFormDataUpload((prevState) => ({
        ...prevState,
        langISOs: selectedLanguages
      }));
      setDataChange(true);
    } else if (type === "edit") {
      setSendFormDataUpdate((prevState) => ({
        ...prevState,
        langISOs: selectedLanguages
      }));

      // if selectedLanguages is different from currentResource, set dataChange to true
      if (
        currentResource &&
        selectedLanguages.length > 0 &&
        selectedLanguages.sort().join(",") !==
          currentResource.lang_iso.sort().join(",")
      ) {
        console.log(selectedLanguages, currentResource.lang_iso, "CHANGING");
        setDataChange(true);
      }
    } else {
      console.error("Unknown type: ", type);
    }
  }, [selectedLanguages]);

  // =================================== SUBMISSION ===================================
  // Updating resource
  const handleSubmitEdit = async () => {
    // Validate sendFormDataUpdate

    console.log(sendFormDataUpdate, "sendFormDataUpdate");

    let errors = [];
    if (!sendFormDataUpdate.name) {
      errors.push("Name is required");
    }
    if (!sendFormDataUpdate.description) {
      errors.push("Description is required");
    }
    if (sendFormDataUpdate.langISOs.length === 0) {
      errors.push("Language is required");
    }
    if (!sendFormDataUpdate.thumbnail) {
      errors.push("Thumbnail is required");
    }
    if (!sendFormDataUpdate.status) {
      errors.push("Status is required");
    }

    if (errors.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Resource update failed",
        html: errors.join("<br> "),
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // New sendFormDataUpdate, trims name and description
    const trimmedFormDataUpdate = {
      ...sendFormDataUpdate,
      name: sendFormDataUpdate.name.trim(),
      description: sendFormDataUpdate.description.trim()
    };

    setSubmitting(true);
    if (clientID && currentResource && trimmedFormDataUpdate) {
      const updateResponse = await updateResource(
        clientID,
        currentResource?.id,
        trimmedFormDataUpdate
      );

      if (updateResponse.rStatus === "success") {
        Swal.fire({
          icon: "success",
          title: "Resource updated successfully",
          confirmButtonColor: "#3085d6",
          didClose: () => {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Resource update failed",
          text: `${updateResponse.rMessage}`,
          confirmButtonColor: "#3085d6"
        });
      }

      console.log(updateResponse, "updateResponse");
    }
    setSubmitting(false);
  };

  // Uploading resource
  const handleSubmitUpload = async () => {
    // Check if all required fields are filled

    let errors = [];

    if (!sendFormDataUpload.name) {
      errors.push("Name is required");
    }
    if (!sendFormDataUpload.description) {
      errors.push("Description is required");
    }
    if (sendFormDataUpload.langISOs.length === 0) {
      errors.push("Language is required");
    }
    if (!sendFormDataUpload.file) {
      errors.push("File is required");
    }
    if (!sendFormDataUpload.thumbnail) {
      errors.push("Thumbnail is required");
    }

    if (errors.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Resource upload failed",
        html: errors.join("<br> "),
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    // New sendFormDataUpload, trims name and description
    const trimmedFormDataUpload = {
      ...sendFormDataUpload,
      name: sendFormDataUpload.name.trim(),
      description: sendFormDataUpload.description.trim()
    };

    // Set busy submitting
    setSubmitting(true);
    if (clientID && studyID && trimmedFormDataUpload) {
      const uploadResponse = await uploadResource(
        clientID,
        studyID,
        trimmedFormDataUpload
      );

      if (uploadResponse.rStatus === "success") {
        Swal.fire({
          icon: "success",
          title: "Resource uploaded successfully",
          confirmButtonColor: "#3085d6",
          didClose: () => {
            window.location.reload();
          }
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Resource upload failed",
          text: `${uploadResponse.rMessage}`,
          confirmButtonColor: "#3085d6"
        });
      }
    } else {
      console.error("Client ID or Study ID not found");
    }
    setSubmitting(false);
  };

  // Loader when submitting
  useEffect(() => {
    // Update the dots every 500 milliseconds
    const intervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : "."));
    }, 500);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div
      className={`modal import_participants_modal resource_modal ${shown ? "show" : "hide"}`}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <span className="close" onClick={handleClose}>
            &times;
          </span>
          <div className="modal-header">
            <div className="container-fluid">
              <div className="row">
                <h3 className="modal_page_header">
                  {capitalize(type)} Resource
                </h3>
              </div>
            </div>
          </div>
          <div className="modal-body">
            <div className="container-fluid">
              {/* Name / Description / Languages / Statuses */}
              <div className="row">
                {/* Name */}
                <div className="mb-3 mt-1">
                  <label
                    className="form-label mb-1 mx-1"
                    htmlFor="form-stacked-text"
                  >
                    Name:
                  </label>
                  <input
                    className="form-control login_input"
                    type="text"
                    value={
                      type === "edit"
                        ? sendFormDataUpdate.name
                        : sendFormDataUpload.name
                    }
                    name="name"
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Name"
                    maxLength={255}
                  />
                </div>
                {/* Description */}
                <div className="mb-3">
                  <label
                    className="form-label mb-1 mx-1"
                    htmlFor="form-stacked-text"
                  >
                    Description:
                  </label>
                  <input
                    className="form-control login_input"
                    type="text"
                    value={
                      type === "edit"
                        ? sendFormDataUpdate.description
                        : sendFormDataUpload.description
                    }
                    name="description"
                    onChange={(e) => handleInputChange(e)}
                    placeholder="Description"
                    maxLength={255}
                  />
                </div>
                {/* LANGUAGES */}
                <div className="mb-3 d-flex flex-row align-items-center">
                  <LanguagesFilter
                    jsonlanguagesData={languages}
                    onLanguagesFilterChange={setSelectedLanguages}
                    filtersCleared={false}
                    location={"resource-modal"}
                    defaultSelectedLanguages={selectedLanguages}
                  />
                  <div className="ms-3">
                    Languages selected: {selectedLanguages.join(", ")}
                  </div>
                </div>
                {/* Status */}
                {type === "edit" && (
                  <div className="mb-3 d-flex flex-row">
                    <label className="form-label mb-1 mb-1 mx-1">Status:</label>
                    <div className="ms-3">
                      <input
                        type="radio"
                        value="active"
                        id="active"
                        checked={sendFormDataUpdate.status === "active"}
                        name="status"
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label className="mx-2" htmlFor="active">
                        Active
                      </label>
                    </div>

                    <div className="ms-3">
                      <input
                        type="radio"
                        value="inactive"
                        id="inactive"
                        checked={sendFormDataUpdate.status === "inactive"}
                        name="status"
                        onChange={(e) => handleInputChange(e)}
                      />
                      <label className="mx-2" htmlFor="inactive">
                        Inactive
                      </label>
                    </div>
                  </div>
                )}
              </div>

              {/* INPUT FOR FILES, SPINNER FOR SUBMITTING */}
              {submitting ? (
                <div className="spinner_holder">
                  <h5 className="mb-2">
                    {type === "create" ? "Uploading" : "Updating"}
                    {dots}
                  </h5>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="mb-3">
                      <label htmlFor="">Resource thumbnail:</label>
                      <input
                        className="form-control"
                        type="file"
                        accept=".jpg,.jpeg,.png,.webp"
                        id="formFile"
                        name="resource_thumbnail"
                        onChange={(e) => handleInputChange(e)}
                      ></input>
                    </div>
                    {type === "create" && (
                      <>
                        <label>Resource (PDF):</label>
                        <Dropzone onDrop={handleOnDrop}>
                          {({ getRootProps, getInputProps }) => (
                            <div>
                              {hasUploaded && (
                                <span
                                  className="clear_file"
                                  onClick={() => {
                                    clearFile();
                                  }}
                                >
                                  ×
                                </span>
                              )}
                              <div
                                className="drop_zone_upload"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                {hasUploaded ? (
                                  <p>{fileName}</p>
                                ) : (
                                  <p>Drop file or click here to upload...</p>
                                )}
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </>
                    )}
                  </div>
                </>
              )}

              {/* BOTTOM BUTTONS */}
              <div className="row">
                <div className="buttons">
                  {type === "create" ? (
                    <button
                      className={`btn btn-primary ${
                        !hasUploaded || submitting ? "disabled" : ""
                      }`}
                      id={`import_${type}`}
                      onClick={() => {
                        handleSubmitUpload();
                      }}
                    >
                      Create Resource
                    </button>
                  ) : type === "edit" ? (
                    <button
                      className={`btn btn-primary ${
                        !dataChange || submitting ? "disabled" : ""
                      }`}
                      id={`import_${type}`}
                      onClick={() => {
                        handleSubmitEdit();
                      }}
                    >
                      Update Resource
                    </button>
                  ) : null}

                  <button
                    className="btn btn-primary btn_cancel"
                    onClick={handleClose}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResourceManagerModal;
