import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { isTokenExpired } from "../../utilities/tokenHandler.util";
import "./surveySwitchLink.scss";
import { confirmSurveySwitch } from "../../models/survey.model";
import { useNavigate } from "react-router-dom";

type SurveySwitchLinkProps = {
  token: string;
};

const SurveySwitchLink: React.FC<SurveySwitchLinkProps> = ({ token }) => {
  const [validToken, setValidToken] = useState<boolean | "loading">("loading");
  const [submitMessage, setSubmitMessage] = useState<string | null>(null);
  const nav = useNavigate();

  useEffect(() => {
    if (isTokenExpired(token)) {
      setValidToken(false);
    } else {
      setValidToken(true);
    }
  }, [token]);

  useEffect(() => {
    if (validToken === true) {
      handleSubmit();
    }

    async function handleSubmit() {
      Swal.fire({
        title: "Switching Surveys",
        html: "Please wait while we switch your survey...",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        }
      });

      const response = await confirmSurveySwitch(token);

      // Failure.
      if (typeof response === "string") {
        if (response === "expired") {
          setSubmitMessage(
            "The link has expired. Please request to switch survey again"
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            html: "The link has expired. Please request to switch survey again",
            confirmButtonColor: "#1a2345"
          }).then((result) => {
            if (result.dismiss || result.isDismissed || result.isConfirmed) {
              // timeout redirect
              setTimeout(() => {
                nav("/clients");
              }, 2000);
            }
          });
        }

        if (response === "notFound") {
          setSubmitMessage(
            "The survey you are trying to switch does not exist"
          );
          Swal.fire({
            icon: "error",
            title: "Error",
            html: "The survey you are trying to switch does not exist",
            confirmButtonColor: "#1a2345"
          }).then((result) => {
            if (result.dismiss || result.isDismissed || result.isConfirmed) {
              setTimeout(() => {
                nav("/clients");
              }, 2000);
            }
          });
        }

        if (response === "failed") {
          setSubmitMessage("Failed to switch survey, please contact support.");
          Swal.fire({
            icon: "error",
            title: "Error",
            html: "Failed to switch survey, please contact support.",
            confirmButtonColor: "#1a2345"
          }).then((result) => {
            if (result.dismiss || result.isDismissed || result.isConfirmed) {
              setTimeout(() => {
                nav("/clients");
              }, 2000);
            }
          });
        }
        return;
      }

      setSubmitMessage("Survey switch successful. Redirecting...");
      // Success
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Survey switch successful. Redirecting...",
        confirmButtonColor: "#1a2345"
      });

      // timeout redirect
      setTimeout(() => {
        if (typeof response !== "string") {
          nav(
            `/clients/${response.clientId}/${response.studyId}/${response.surveyId}`
          );
        }
      }, 3000);
    }
  }, [token, validToken]);

  return (
    <div className="container survey-switch-link">
      {submitMessage ? (
        <div className="alert alert-success mt-2">{submitMessage}</div>
      ) : validToken === "loading" ? (
        <div className="d-flex justify-content-center custom_spinner_container full_page">
          <div className="spinner-border" role="status"></div>
        </div>
      ) : !validToken ? (
        <div className="d-flex flex-column justify-content-start align-items-center custom_spinner_container full_page">
          <h3 className="text-danger">The link has expired or is incorrect.</h3>
          <p>Please request to switch survey again.</p>
          <button className="btn btn-primary" onClick={() => nav("/clients")}>
            Go home
          </button>
        </div>
      ) : (
        <div className="text-center survey-switch-link card col-12 col-lg-5 m-auto my-3 p-3">
          <h3>Survey Switch</h3>
          <p>Switching your survey. Please wait...</p>
        </div>
      )}
    </div>
  );
};

export default SurveySwitchLink;
