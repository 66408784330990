import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  ImportPhrase,
  LanguageLibraryEntry,
  LanguageLibraryPhrase,
  LanguageLibraryTranslation,
  NewTranslation
} from "../types";

export type LanguageLibraryImportPhrase = {
  phraseID: string | null;
  phrase: string;
  translations: {
    languageISO: string;
    translation: string;
  }[];
};

// #region type validators
// #region isLanguageLibraryTranslation
function isLanguageLibraryTranslation(
  obj: any
): obj is LanguageLibraryTranslation {
  const validID = typeof obj.id === "string";
  const validLanguageISO = typeof obj.language_iso === "string";
  const validTranslation = typeof obj.translation === "string";
  const validTranslatedBy = typeof obj.translated_by === "string";
  const allValid =
    validID && validLanguageISO && validTranslation && validTranslatedBy;
  if (!allValid) {
    console.log("Invalid language library translation obj");
    console.log(obj);
    let errors: string[] = [];
    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`ID: ${obj.id}`);
      }
    }
    if (!validLanguageISO) {
      if (!obj.hasOwnProperty("language_iso")) {
        errors.push("missing language_iso");
      } else {
        errors.push(`Invalid type for language_iso`);
      }
    }
    if (!validTranslation) {
      if (!obj.hasOwnProperty("translation")) {
        errors.push("missing translation");
      } else {
        errors.push(`Invalid type for translation`);
      }
    }
    if (!validTranslatedBy) {
      if (!obj.hasOwnProperty("translated_by")) {
        errors.push("missing translated_by");
      } else {
        errors.push(`Invalid type for translated_by`);
      }
    }

    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `TraslationID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid translation`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return allValid;
}
// #endregion isLanguageLibraryTranslation

// #region isLanguageLibraryEntry
function isLanguageLibraryEntry(obj: any): obj is LanguageLibraryEntry {
  const validID = typeof obj.id === "string";
  const validClientID = typeof obj.clientID === "string";
  const validStudyID = typeof obj.studyID === "string";
  const validCategoryID = typeof obj.categoryID === "string";
  const validPhraseID = typeof obj.phraseID === "string";

  const allValid =
    validID &&
    validClientID &&
    validStudyID &&
    validCategoryID &&
    validPhraseID;

  if (!allValid) {
    console.log("Invalid language library entry obj");
    console.log(obj);
    if (!validID) {
      console.log("Invalid ID: ", typeof obj.id);
    }
    if (!validClientID) {
      console.log("Invalid clientID: ", typeof obj.clientID);
    }
    if (!validStudyID) {
      console.log("Invalid studyID: ", typeof obj.studyID);
    }
    if (!validCategoryID) {
      console.log("Invalid categoryID: ", typeof obj.categoryID);
    }
    if (!validPhraseID) {
      console.log("Invalid phraseID: ", typeof obj.phraseID);
    }
  }
  return allValid;
}
// #endregion isLanguageLibraryEntry

// #region isLanguageLibraryPhrase
function isLanguageLibraryPhrase(obj: any): obj is LanguageLibraryPhrase {
  const validID = typeof obj.id === "string";
  const validName = typeof obj.name === "string";

  const allValid = validID && validName;
  if (!allValid) {
    console.log("Invalid language library phrase obj");
    console.log(obj);

    let errors: string[] = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push(`ID: ${obj.id}`);
      }
    }
    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push(`Name: ${obj.name}`);
      }
    }

    errors.forEach((error) => {
      // remove first empty element
      if (error !== "") {
        console.log(error);
      }
    });

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `Translation: ${obj.automation_id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid translation`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }
  return allValid;
}
// #endregion isLanguageLibraryPhrase
// #endregion type validators

// #region fetchAllLanguageLibraryEntries
export async function fetchAllLanguageLibraryEntries(): Promise<
  LanguageLibraryEntry[] | string
> {
  const response = await apiFetcher<any>("/getLanguageLibraryEntries", "POST", {
    body: {}
  });

  // console.log(clientID, "clientid");
  // console.log(studyID, "studyid");
  //   console.log(response, "response");
  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    // console.log(response.data, "response");
    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isLanguageLibraryEntry)
    ) {
      // console.log("Successfully fetched all language library entries");

      return response.data;
    } else {
      console.log("Invalid data received");
      // Array.isArray(response.data)
      console.log(response.data, "response");
      console.log(Array.isArray(response.data));
      console.log(response.data.every(isLanguageLibraryEntry));
      return "Invalid data received";
    }
  } else {
    console.log("No ");
    console.log(response.status);
    if (response.status === 404) {
      return [];
    }
    return "Failed to fetch all language library entries";
  }
}
// #endregion fetchAllLanguageLibraryEntries

// #region fetchAllLanguageLibraryPhrasesByCatID
export async function fetchAllLanguageLibraryPhrasesByCatID(
  categoryID: string,
  signal?: AbortSignal
): Promise<LanguageLibraryPhrase[] | string> {
  const response = await apiFetcher<any>(
    "/getPhrases",
    "POST",
    {
      body: { categoryId: categoryID }
    },
    signal
  );

  // console.log(response.status);
  // console.log(response.data);
  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    // console.log(response.data, "response");
    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isLanguageLibraryPhrase)
    ) {
      return response.data;
    } else {
      console.log("Invalid data received");
      // Array.isArray(response.data)
      console.log(response.data, "response");
      console.log(Array.isArray(response.data));
      console.log(response.data.every(isLanguageLibraryPhrase));
      return "Invalid data received";
    }
  } else {
    // console.log(response.status);
    if (response.status === 404) {
      return [];
    }

    if (response.status === 400) {
      return "Please contact support";
    }

    if (response.status === 0) {
      if (response.error === "request_aborted") {
        return "Request aborted";
      }
      return "Network error";
    }

    return "Failed to fetch all language library phrases";
  }
}
// #endregion fetchAllLanguageLibraryPhrasesByCatID

// #region fetchAllLanguageLibraryTranslationsByPhrase
export async function fetchAllLanguageLibraryTranslationsByPhrase(
  phraseID: string,
  signal?: AbortSignal
): Promise<LanguageLibraryTranslation[] | string> {
  const response = await apiFetcher<any>(
    "/getTranslations",
    "POST",
    {
      body: {
        phraseId: phraseID
      }
    },
    signal
  );

  // console.log(clientID, "clientid");
  // console.log(studyID, "studyid");
  //   console.log(response, "response");
  if (response.status === 200 && response.data !== null) {
    // Perform type checking
    // console.log(response.data, "response");
    if (
      response.data &&
      Array.isArray(response.data) &&
      response.data.every(isLanguageLibraryTranslation)
    ) {
      //   console.log("Successfully fetched all language library entries");

      return response.data;
    } else {
      console.log("Invalid data received");
      // Array.isArray(response.data)
      console.log(response.data, "response");
      console.log(Array.isArray(response.data));
      console.log(response.data.every(isLanguageLibraryTranslation));
      return "Invalid data received";
    }
  }

  console.log(response.status);
  if (response.status === 404) {
    return [];
  }

  if (response.status === 400) {
    return "Please contact support";
  }

  if (response.status === 0) {
    if (response.error === "request_aborted") {
      return "Request aborted";
    }
    return "Network error";
  }

  return "Failed to fetch all language library translations";
}
// #endregion fetchAllLanguageLibraryTranslationsByPhrase

// #region createNewPhrase
export async function createNewPhrase(
  phraseCategoryID: string,
  phrase: string,
  translations: NewTranslation[]
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  const body = {
    phraseCategoryId: phraseCategoryID,
    phrase: phrase,
    translations: translations
  };

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  // console.log(body, "body");
  try {
    const response = await apiFetcher<any>("/createPhrase", "POST", {
      body: body
    });

    // console.log(response.data, "response");

    if (response.status === 200) {
      if (
        !response.data.jobID ||
        response.data.jobID === null ||
        response.data.jobID === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // console.log("response.data.id", response.data.jobID);
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "Phrase added to queue, please wait"
      };
    }

    if (response.status === 206) {
      return {
        rStatus: "success",
        rMessage: "Phrase Created"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage: "Category not found"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Server error"
  };
}
// #endregion createNewPhrase

// #region editTranslation
export async function editTranslation(
  translationID: string,
  translation: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  const body = {
    translationID: translationID,
    translation: translation
  };

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  // console.log(body, "body");
  try {
    const response = await apiFetcher<any>("/updateTranslations", "POST", {
      body: body
    });

    // console.log(response, "response");
    if (response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Translation Modified Successfully"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage: "Translation not found"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Server error"
  };
}
// #endregion editTranslation

// #region AutoTranslateTranslation
export async function AutoTranslateTranslation(
  translationID: string,
  translation: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  const body = {
    translationId: translationID,
    translation: translation,
    autoTranslate: true
  };

  // console.log(body, "body");

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>("/editTranslation", "POST", {
      body: body
    });

    // console.log(response, "response");
    if (response.status === 200) {
      if (
        !response.data.jobID ||
        response.data.jobID === null ||
        response.data.jobID === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // console.log("response.data.id", response.data.jobID);
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "Translation added to queue, please wait"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage: "Translation not found"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Server error"
  };
}
// #endregion AutoTranslateTranslation

// #region sendImportedTranslations
export async function sendImportedTranslations(
  categoryID: string,
  objImport: LanguageLibraryImportPhrase
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  // console.log(objImport, "objImport");

  const body = {
    phrases: {
      categoryID: categoryID,
      phraseID: objImport.phraseID ? objImport.phraseID : null,
      phrase: objImport.phrase
    },
    translations: objImport.translations.map((translation) => ({
      languageISO: translation.languageISO,
      translation: translation.translation
    }))
  };

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  // console.log(body, "body");

  try {
    const response = await apiFetcher<any>(
      "/languageLibrary/phrases/import",
      "POST",
      {
        body: body
      }
    );

    //We are not setting an Job Id for this action
    // if (response.status === 200) {
    //   if (
    //     !response.data.jobID ||
    //     response.data.jobID === null ||
    //     response.data.jobID === undefined
    //   ) {
    //     return {
    //       rStatus: "error",
    //       rMessage: "No worker ID received",
    //     };
    //   }

    //   // set Worker ID in local storage
    //   // console.log("response.data.id", response.data.jobID);
    //   localStorage.setItem("workerID", response.data.jobID);
    //   return {
    //     rStatus: "success",
    //     rMessage: "Translation added to queue, please wait",
    //   };
    // }

    if (response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Translation added successfully"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage: "Translation not found"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Server error"
  };
}
// #endregion sendImportedTranslations

// #region sendBulkImportedTranslations
export async function sendBulkImportedTranslations(
  objImport: ImportPhrase
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  // console.log(objImport, "objImport");
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await apiFetcher<any>(
      "/languageLibrary/phrases/bulkImport",
      "POST",
      {
        body: {
          phrases: objImport.phrases
        }
      }
    );
    console.log(response, "response");
    //We are not setting an Job Id for this action
    if (response.status === 200) {
      if (
        !response.data.jobID ||
        response.data.jobID === null ||
        response.data.jobID === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // console.log("response.data.id", response.data.jobID);
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "Translation added to queue, please wait"
      };
    } else if (response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Translation added successfully"
      };
    } else {
      return {
        rStatus: "error",
        rMessage: "Server error while sending data"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: `Failed to send data to server${error}`
    };
  }
}
// #endregion sendBulkImportedTranslations

// #region exportAllCategoryPhrase
export async function exportAllCategoryPhrase(
  categoryID: string
): Promise<string> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const response = await apiFetcher<any>(
      "/languageLibrary/phrases/export",
      "POST",
      {
        body: {
          categoryID: categoryID
        }
      }
    );

    console.log(response, "response");
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.jobID
    ) {
      localStorage.setItem("workerID", response.data.jobID);
      return "success";
    } else {
      return "Error exporting phrases";
    }
  } catch (error) {
    return "Server error";
  }
}
// #endregion exportAllCategoryPhrase

// #region exportAllStudyPhrases
export async function exportAllStudyPhrases(
  studyID: string,
  clientID: string
): Promise<string> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const response = await apiFetcher<any>(
      "/languageLibrary/phrases/studyPhraseExport",
      "POST",
      {
        body: {
          studyID: studyID,
          clientID: clientID
        }
      }
    );

    console.log(response, "response");
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.jobID
    ) {
      localStorage.setItem("workerID", response.data.jobID);
      return "success";
    } else {
      return "Error exporting phrases";
    }
  } catch (error) {
    return "Server error";
  }
}
// #endregion exportAllStudyPhrases

// #region exportAllSurveyPhrases
export async function exportAllSurveyPhrases(
  surveyID: string,
  clientID: string
): Promise<string> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const response = await apiFetcher<any>(
      "/languageLibrary/phrases/surveyPhraseExport",
      "POST",
      {
        body: {
          surveyID: surveyID,
          clientID: clientID
        }
      }
    );

    // console.log(response, "response");
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.jobID
    ) {
      localStorage.setItem("workerID", response.data.jobID);
      return "success";
    } else {
      return "Error exporting phrases";
    }
  } catch (error) {
    return "Server error";
  }
}
// #endregion exportAllSurveyPhrases

// #region deletePhraseRequest
export async function deletePhraseRequest(phraseID: string): Promise<string> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const response = await apiFetcher<any>(
      "/languageLibrary/phrases/phraseDelete",
      "POST",
      {
        body: {
          phraseID: phraseID
        }
      }
    );

    console.log(response, "response");
    if (
      response &&
      response.status === 200 &&
      response.data &&
      response.data.jobID
    ) {
      localStorage.setItem("workerID", response.data.jobID);
      return "success";
    } else {
      switch (response && response.status) {
        case 403:
          return "Permissions not granted";
        case 400:
          return "Please contact support";
        case 404:
          return "Phrase not found";
        default:
          return "Failed to delete phrase";
      }
    }
  } catch (error) {
    return "Server error";
  }
}
// #endregion deletePhraseRequest
