import Swal from "sweetalert2";
import { dfuApiFetcher } from "../services/API.service";
import { CustomReportSettings, ShortCustomReport } from "../types";

// #region type validators

// #region isShortCustomReport
function isShortCustomReport(obj: unknown): obj is ShortCustomReport {
  if (typeof obj !== "object" || obj === null) return false;
  const validID = "id" in obj && typeof obj.id === "number";
  const validName = "report_name" in obj && typeof obj.report_name === "string";
  const validDateCreated =
    "date_created" in obj && typeof obj.date_created === "string";
  const validDateModified =
    "date_modified" in obj && typeof obj.date_modified === "string";
  const validDateLastRun =
    "date_last_run" in obj &&
    (typeof obj.date_last_run === "string" || obj.date_last_run === null);
  const validLastRunSuccess =
    "last_run_success" in obj &&
    (obj.last_run_success === "Y" ||
      obj.last_run_success === "N" ||
      obj.last_run_success === null);
  const validNumRuns = "num_runs" in obj && typeof obj.num_runs === "number";
  const validScheduledTimings =
    "scheduled_timings" in obj &&
    Array.isArray(obj.scheduled_timings) &&
    obj.scheduled_timings.every((item) => typeof item === "number");

  const AllValid =
    validID &&
    validName &&
    validDateCreated &&
    validDateModified &&
    validDateLastRun &&
    validLastRunSuccess &&
    validNumRuns &&
    validScheduledTimings;

  if (AllValid) {
    return true;
  }

  console.log(obj);

  const errors: string[] = [];

  if (!validID) {
    if (!obj.hasOwnProperty("id")) {
      errors.push("Missing id");
    } else {
      errors.push("Invalid id");
    }
  }

  if (!validName) {
    if (!obj.hasOwnProperty("report_name")) {
      errors.push("Missing report_name");
    } else {
      errors.push("Invalid report_name");
    }
  }

  if (!validDateCreated) {
    if (!obj.hasOwnProperty("date_created")) {
      errors.push("Missing date_created");
    } else {
      errors.push("Invalid date_created");
    }
  }

  if (!validDateModified) {
    if (!obj.hasOwnProperty("date_modified")) {
      errors.push("Missing date_modified");
    } else {
      errors.push("Invalid date_modified");
    }
  }

  if (!validDateLastRun) {
    if (!obj.hasOwnProperty("date_last_run")) {
      errors.push("Missing date_last_run");
    } else {
      errors.push("Invalid date_last_run");
    }
  }

  if (!validLastRunSuccess) {
    if (!obj.hasOwnProperty("last_run_success")) {
      errors.push("Missing last_run_success");
    } else {
      errors.push("Invalid last_run_success");
    }
  }

  if (!validNumRuns) {
    if (!obj.hasOwnProperty("num_runs")) {
      errors.push("Missing num_runs");
    } else {
      errors.push("Invalid num_runs");
    }
  }

  if (!validScheduledTimings) {
    if (!obj.hasOwnProperty("scheduled_timings")) {
      errors.push("Missing scheduled_timings");
    } else {
      errors.push("Invalid scheduled_timings");
    }
  }

  console.log(errors);

  let displayMessage = `<strong>Please contact support</strong><br><br>`;
  if ("id" in obj) {
    displayMessage += `ID: ${obj.id}<br>`;
  }

  Swal.fire({
    icon: "error",
    title: `Invalid ShortCustomReport`,
    html: displayMessage + errors.join("<br>"),
    showConfirmButton: true,
    confirmButtonColor: "#3085d6"
  });

  return false;
}
// #endregion

// #region isCustomReportSettings
function isCustomReportSettings(obj: unknown): obj is CustomReportSettings {
  if (typeof obj !== "object" || obj === null) return false;
  const validID = "id" in obj && typeof obj.id === "number";
  const validName = "report_name" in obj && typeof obj.report_name === "string";
  const validScript =
    "report_script" in obj && typeof obj.report_script === "string";
  const validTimings =
    "scheduled_timings" in obj &&
    Array.isArray(obj.scheduled_timings) &&
    obj.scheduled_timings.every((item) => typeof item === "number");

  const AllValid = validID && validName && validScript && validTimings;

  if (AllValid) {
    return true;
  }

  console.log(obj);

  const errors: string[] = [];

  if (!validID) {
    if (!obj.hasOwnProperty("id")) {
      errors.push("Missing id");
    } else {
      errors.push("Invalid id");
    }
  }

  if (!validName) {
    if (!obj.hasOwnProperty("report_name")) {
      errors.push("Missing report_name");
    } else {
      errors.push("Invalid report_name");
    }
  }

  if (!validScript) {
    if (!obj.hasOwnProperty("report_script")) {
      errors.push("Missing report_script");
    } else {
      errors.push("Invalid report_script");
    }
  }

  if (!validTimings) {
    if (!obj.hasOwnProperty("scheduled_timings")) {
      errors.push("Missing scheduled_timings");
    } else {
      errors.push("Invalid scheduled_timings");
    }
  }

  console.log(errors);

  let displayMessage = `<strong>Please contact support</strong><br><br>`;
  if ("id" in obj) {
    displayMessage += `ID: ${obj.id}<br>`;
  }

  Swal.fire({
    icon: "error",
    title: `Invalid CustomReportSettings`,
    html: displayMessage + errors.join("<br>"),
    showConfirmButton: true,
    confirmButtonColor: "#3085d6"
  });

  return false;
}
// #endregion

export type ExecutionRecord = {
  id: number;
  scheduled_run_time: number;
  successful_execution: "Y" | "N" | null;
  // s3_link: string | null;
};

export type ScriptDetail = {
  id: number;
  report_name: string;
  report_script: string;
  report_client_id: number;
  created_at: string;
  updated_at: string;
  executions: ExecutionRecord[];
};

function isExecutionRecord(obj: unknown): obj is ExecutionRecord {
  if (typeof obj !== "object" || obj === null) return false;
  const validID = "id" in obj && typeof obj.id === "number";
  const validScheduledRunTime =
    "scheduled_run_time" in obj && typeof obj.scheduled_run_time === "number";
  const validSuccessfulExecution =
    "successful_execution" in obj &&
    (obj.successful_execution === "Y" ||
      obj.successful_execution === "N" ||
      obj.successful_execution === null);

  const AllValid = validID && validScheduledRunTime && validSuccessfulExecution;

  if (AllValid) {
    return true;
  }

  console.log(obj);

  const errors: string[] = [];

  if (!validID) {
    if (!obj.hasOwnProperty("id")) {
      errors.push("Missing id");
    } else {
      errors.push("Invalid id");
    }
  }

  if (!validScheduledRunTime) {
    if (!obj.hasOwnProperty("scheduled_run_time")) {
      errors.push("Missing scheduled_run_time");
    } else {
      errors.push("Invalid scheduled_run_time");
    }
  }

  if (!validSuccessfulExecution) {
    if (!obj.hasOwnProperty("successful_execution")) {
      errors.push("Missing successful_execution");
    } else {
      errors.push("Invalid successful_execution");
    }
  }

  console.log(errors);

  let displayMessage = `<strong>Please contact support</strong><br><br>`;
  if ("id" in obj) {
    displayMessage += `ID: ${obj.id}<br>`;
  }

  Swal.fire({
    icon: "error",
    title: `Invalid ExecutionRecord`,
    html: displayMessage + errors.join("<br>"),
    showConfirmButton: true,
    confirmButtonColor: "#3085d6"
  });

  return false;
}

function isScriptDetail(obj: unknown): obj is ScriptDetail {
  if (typeof obj !== "object" || obj === null) return false;
  const validID = "id" in obj && typeof obj.id === "number";
  const validReportName =
    "report_name" in obj && typeof obj.report_name === "string";
  const validReportScript =
    "report_script" in obj && typeof obj.report_script === "string";
  const validReportClientID =
    "report_client_id" in obj && typeof obj.report_client_id === "number";
  const validCreatedAt =
    "created_at" in obj && typeof obj.created_at === "string";
  const validUpdatedAt =
    "updated_at" in obj && typeof obj.updated_at === "string";
  const validExecutions =
    "executions" in obj &&
    Array.isArray(obj.executions) &&
    obj.executions.every((item) => isExecutionRecord(item));

  const AllValid =
    validID &&
    validReportName &&
    validReportScript &&
    validReportClientID &&
    validCreatedAt &&
    validUpdatedAt &&
    validExecutions;

  if (AllValid) {
    return true;
  }

  console.log(obj);

  const errors: string[] = [];

  if (!validID) {
    if (!obj.hasOwnProperty("id")) {
      errors.push("Missing id");
    } else {
      errors.push("Invalid id");
    }
  }

  if (!validReportName) {
    if (!obj.hasOwnProperty("report_name")) {
      errors.push("Missing report_name");
    } else {
      errors.push("Invalid report_name");
    }
  }

  if (!validReportScript) {
    if (!obj.hasOwnProperty("report_script")) {
      errors.push("Missing report_script");
    } else {
      errors.push("Invalid report_script");
    }
  }

  if (!validReportClientID) {
    if (!obj.hasOwnProperty("report_client_id")) {
      errors.push("Missing report_client_id");
    } else {
      errors.push("Invalid report_client_id");
    }
  }

  if (!validCreatedAt) {
    if (!obj.hasOwnProperty("created_at")) {
      errors.push("Missing created_at");
    } else {
      errors.push("Invalid created_at");
    }
  }

  if (!validUpdatedAt) {
    if (!obj.hasOwnProperty("updated_at")) {
      errors.push("Missing updated_at");
    } else {
      errors.push("Invalid updated_at");
    }
  }

  if (!validExecutions) {
    if (!obj.hasOwnProperty("executions")) {
      errors.push("Missing executions");
    }
    // else {
    //   errors.push("Invalid executions");
    // }
  }

  console.log(errors);

  let displayMessage = `<strong>Please contact support</strong><br><br>`;
  if ("id" in obj) {
    displayMessage += `ID: ${obj.id}<br>`;
  }

  if (errors.length > 0) {
    Swal.fire({
      icon: "error",
      title: `Invalid ScriptDetail`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return false;
}

// #endregion

export async function fetchSourceDataStructure(): Promise<
  | {
      rStatus: "success";
      rData: {
        inputStructure: object;
        resultStructure: object;
      };
    }
  | { rStatus: "error"; message: string }
> {
  try {
    const response = await dfuApiFetcher<unknown>("/structure/get", "POST", {
      body: {}
    });
    if (response.status === 200) {
      if (
        response.data &&
        typeof response.data === "object" &&
        "inputStructure" in response.data &&
        typeof response.data.inputStructure === "object" &&
        response.data.inputStructure !== null &&
        "resultStructure" in response.data &&
        typeof response.data.resultStructure === "object" &&
        response.data.resultStructure !== null
      ) {
        return {
          rStatus: "success",
          rData: {
            inputStructure: response.data.inputStructure,
            resultStructure: response.data.resultStructure
          }
        };
      } else {
        return {
          rStatus: "error",
          message: "Unexpected response data structure"
        };
      }
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    return {
      rStatus: "error",
      message: "Failed to fetch data structure"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to fetch data structure"
    };
  }
}

export async function validateScript(
  script: string
): Promise<
  | { rStatus: "success" }
  | { rStatus: "error"; message: string; returnError?: string }
> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });
    const response = await dfuApiFetcher<unknown>("/script/check", "POST", {
      body: {
        script: script
      }
    });
    Swal.close();
    if (response.status === 200) {
      return {
        rStatus: "success"
      };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    console.log(response);

    if (response.status === 400) {
      if (
        response.data &&
        typeof response.data === "object" &&
        "error" in response.data &&
        typeof response.data.error === "string"
      ) {
        return {
          rStatus: "error",
          message: "Failed to validate script",
          returnError: response.data.error
        };
      }

      if (
        response.data &&
        typeof response.data === "object" &&
        "message" in response.data &&
        typeof response.data.message === "string"
      ) {
        return {
          rStatus: "error",
          message: "Failed to validate script",
          returnError: response.data.message
        };
      }
    }

    return {
      rStatus: "error",
      message: "Failed to validate script"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to validate script"
    };
  }
}

export async function submitCreateReport({
  clientID,
  reportName,
  reportCode,
  scheduleTime
}: {
  clientID: number;
  reportName: string;
  reportCode: string;
  scheduleTime: number[];
}): Promise<{ rStatus: "success" | "error"; message?: string }> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await dfuApiFetcher<unknown>("/script/schedule", "POST", {
      body: {
        clientID,
        script: reportCode,
        name: reportName,
        dateTime: scheduleTime
      }
    });

    Swal.close();

    if (response.status === 200 || response.status === 202) {
      return {
        rStatus: "success"
      };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    return {
      rStatus: "error",
      message: "Failed to submit report"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to submit report"
    };
  }
}

export async function submitUpdateReport({
  clientID,
  reportID,
  reportName,
  reportCode,
  scheduleTime
}: {
  clientID: number;
  reportID: number;
  reportName: string;
  reportCode: string;
  scheduleTime: number[];
}): Promise<{ rStatus: "success" | "error"; message?: string }> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await dfuApiFetcher<unknown>(
      "/script/specific/update",
      "POST",
      {
        body: {
          clientID,
          scriptId: reportID,
          script: reportCode,
          name: reportName,
          dateTime: scheduleTime
        }
      }
    );

    Swal.close();

    if (response.status === 200 || response.status === 202) {
      return {
        rStatus: "success"
      };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    return {
      rStatus: "error",
      message: "Failed to submit report"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to submit report"
    };
  }
}

export async function fetchScriptList(
  clientID: number
): Promise<
  | { rStatus: "success"; rData: ShortCustomReport[] }
  | { rStatus: "error"; message: string }
> {
  try {
    const response = await dfuApiFetcher<unknown>("/script/list/get", "POST", {
      body: {
        clientID
      }
    });

    if (response.status === 200) {
      if (
        Array.isArray(response.data) &&
        response.data.every((item) => isShortCustomReport(item))
      ) {
        return {
          rStatus: "success",
          rData: response.data
        };
      }

      return {
        rStatus: "error",
        message: "Unexpected response data structure"
      };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    return {
      rStatus: "error",
      message: "Failed to fetch script list"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to fetch script list"
    };
  }
}

export async function fetchScript(
  clientID: number,
  scriptID: number
): Promise<
  | { rStatus: "success"; rData: CustomReportSettings }
  | { rStatus: "error"; message: string }
> {
  try {
    const response = await dfuApiFetcher<unknown>(
      "/script/specific/get",
      "POST",
      {
        body: {
          clientID,
          scriptId: scriptID
        }
      }
    );

    if (response.status === 200) {
      if (
        response.data &&
        typeof response.data === "object" &&
        "scriptDetails" in response.data
      ) {
        if (isCustomReportSettings(response.data.scriptDetails)) {
          return {
            rStatus: "success",
            rData: response.data.scriptDetails
          };
        }
      }
      return {
        rStatus: "error",
        message: "Unexpected response data structure"
      };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    return {
      rStatus: "error",
      message: "Failed to fetch script"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to fetch script"
    };
  }
}

// script/specific/history

export async function fetchScriptHistory(
  clientID: number,
  scriptID: number
): Promise<
  | { rStatus: "success"; rData: ScriptDetail }
  | { rStatus: "error"; message: string }
> {
  try {
    const response = await dfuApiFetcher<unknown>(
      "/script/specific/history",
      "POST",
      {
        body: {
          clientID,
          scriptId: scriptID
        }
      }
    );

    if (response.status === 200) {
      if (
        response.data &&
        typeof response.data === "object" &&
        "scriptDetails" in response.data
      ) {
        if (isScriptDetail(response.data.scriptDetails)) {
          return {
            rStatus: "success",
            rData: response.data.scriptDetails
          };
        }
      }
      return {
        rStatus: "error",
        message: "Unexpected response data structure"
      };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    return {
      rStatus: "error",
      message: "Failed to fetch script history"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to fetch script history"
    };
  }
}

export async function sendExecuteScript(
  clientID: number,
  scriptID: number
): Promise<{ rStatus: "success" } | { rStatus: "error"; message: string }> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await dfuApiFetcher<unknown>(
      "/script/specific/execute",
      "POST",
      {
        body: {
          clientID,
          scriptId: scriptID
        }
      }
    );

    Swal.close();

    if (response.status === 200 || response.status === 202) {
      return {
        rStatus: "success"
      };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    return {
      rStatus: "error",
      message: "Failed to execute script"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to execute script"
    };
  }
}

export async function sendDeleteScript(
  clientID: number,
  scriptID: number
): Promise<{ rStatus: "success" } | { rStatus: "error"; message: string }> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await dfuApiFetcher<unknown>(
      "/script/specific/delete",
      "POST",
      {
        body: {
          clientID,
          scriptId: scriptID
        }
      }
    );

    Swal.close();

    if (response.status === 200 || response.status === 202) {
      return {
        rStatus: "success"
      };
    }

    if (response.status === 0) {
      return {
        rStatus: "error",
        message: "Network error"
      };
    }

    return {
      rStatus: "error",
      message: "Failed to delete script"
    };
  } catch (error) {
    return {
      rStatus: "error",
      message: "Failed to delete script"
    };
  }
}
