import Swal from "sweetalert2";
import {
  UpdateData,
  UploadData
} from "../components/StudySurveyTabSingle/Resources/ResourceModal/manageResourceModal";
import { apiFetcher } from "../services/API.service";
import { ResourceData } from "../types";

// #region isResource
function isResource(obj: any): boolean {
  const validID = typeof obj.id === "string";
  const validStatus = typeof obj.status === "string";
  const validName = typeof obj.name === "string";
  const validDescription = typeof obj.description === "string";
  const validFileName = typeof obj.file_name === "string";
  const validResourceFileUrl = typeof obj.resource_file_url === "string";
  const validThumbnailUrl = typeof obj.thumbnail_url === "string";
  const validCreatedAt = typeof obj.created_at === "string";
  const validUpdatedAt = typeof obj.updated_at === "string";
  const validLangIso = Array.isArray(obj.lang_iso);

  const AllValid =
    validID &&
    validStatus &&
    validName &&
    validDescription &&
    validFileName &&
    validResourceFileUrl &&
    validThumbnailUrl &&
    validCreatedAt &&
    validUpdatedAt &&
    validLangIso;

  if (!AllValid) {
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("Missing ID");
      } else {
        errors.push("Invalid Type for ID");
      }
    }

    if (!validStatus) {
      if (!obj.hasOwnProperty("status")) {
        errors.push("Missing Status");
      } else {
        errors.push("Invalid Type for Status");
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("Missing Name");
      } else {
        errors.push("Invalid Type for Name");
      }
    }

    if (!validDescription) {
      if (!obj.hasOwnProperty("description")) {
        errors.push("Missing Description");
      } else {
        errors.push("Invalid Type for Description");
      }
    }

    if (!validFileName) {
      if (!obj.hasOwnProperty("file_name")) {
        errors.push("Missing File Name");
      } else {
        errors.push("Invalid Type for File Name");
      }
    }

    if (!validResourceFileUrl) {
      if (!obj.hasOwnProperty("resource_file_url")) {
        errors.push("Missing Resource File URL");
      } else {
        errors.push("Invalid Type for Resource File URL");
      }
    }

    if (!validThumbnailUrl) {
      if (!obj.hasOwnProperty("thumbnail_url")) {
        errors.push("Missing Thumbnail URL");
      } else {
        errors.push("Invalid Type for Thumbnail URL");
      }
    }

    if (!validCreatedAt) {
      if (!obj.hasOwnProperty("created_at")) {
        errors.push("Missing Created At");
      } else {
        errors.push("Invalid Type for Created At");
      }
    }

    if (!validUpdatedAt) {
      if (!obj.hasOwnProperty("updated_at")) {
        errors.push("Missing Updated At");
      } else {
        errors.push("Invalid Type for Updated At");
      }
    }

    if (!validLangIso) {
      if (!obj.hasOwnProperty("lang_iso")) {
        errors.push("Missing Lang Iso");
      } else {
        errors.push("Invalid Type for Lang Iso");
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `Resource Name: ${obj.name}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Resource`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isResource

// #region getResources
// Get all resources
export async function getResources(
  clientID: string,
  studyID: string
): Promise<ResourceData[] | string> {
  try {
    const response = await apiFetcher("/resources/get", "POST", {
      body: {
        clientID,
        studyID
      }
    });
    console.log(response, "response");
    if (response && response.status === 200 && response?.data !== null) {
      console.log("in here");
      if (Array.isArray(response.data) && response.data.every(isResource)) {
        console.log(response.data, "response.data");
        return response.data;
      } else {
        console.log("in here");
        return "Invalid data types";
      }
    } else {
      switch (response.status) {
        case 204:
          return [];
        case 500:
          return "Internal server error";
        case 404:
          return "Resources not found";
        case 403:
          return "Unauthorized, permission";
        default:
          return `Failed to sending request, status code: ${response.status}`;
      }
    }
  } catch (error) {
    return "Failed to sending request";
  }
}
// #endregion getResources

// #region updateResource
// Update Resource
export async function updateResource(
  clientID: string,
  resourceID: string,
  resourceData: UpdateData
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher("/resources/update", "POST", {
      body: {
        clientID,
        resourceID,
        status: resourceData.status,
        name: resourceData.name,
        description: resourceData.description,
        langISOs: resourceData.langISOs,
        thumbnail: resourceData.thumbnail
      }
    });

    console.log(clientID, resourceID, resourceData, "updateResource");
    console.log(response, "response");
    if (response && response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Resource updated successfully"
      };
    } else {
      switch (response.status) {
        case 500:
          return {
            rStatus: "error",
            rMessage: "Internal server error"
          };
        case 404:
          return {
            rStatus: "error",
            rMessage: "Resource not found"
          };
        case 403:
          return {
            rStatus: "error",
            rMessage: "Unauthorized, permission"
          };

        case 409:
          return {
            rStatus: "error",
            rMessage: "No changes made to the resource"
          };

        default:
          return {
            rStatus: "error",
            rMessage: `Failed to sending request, status code: ${response.status}`
          };
      }
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Failed to sending request"
    };
  }
}
// #endregion updateResource

// #region uploadResource
// Create Resource
export async function uploadResource(
  clientID: string,
  studyID: string,
  resourceData: UploadData
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher("/resources/upload", "POST", {
      body: {
        clientID,
        studyID,
        name: resourceData.name,
        description: resourceData.description,
        langISOs: resourceData.langISOs,
        fileName: resourceData.fileName,
        file: resourceData.file,
        thumbnail: resourceData.thumbnail
      }
    });

    console.log(clientID, studyID, resourceData, "uploadResource");

    console.log(response, "response");
    if (response && response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Resource uploaded successfully"
      };
    } else {
      switch (response.status) {
        case 500:
          return {
            rStatus: "error",
            rMessage: "Internal server error"
          };

        case 404:
          return {
            rStatus: "error",
            rMessage: "Resource not found"
          };

        case 403:
          return {
            rStatus: "error",
            rMessage: "Unauthorized, permission"
          };

        case 409:
          return {
            rStatus: "error",
            rMessage: "Resource already exists"
          };

        default:
          return {
            rStatus: "error",
            rMessage: `Failed to sending request, status code: ${response.status}`
          };
      }
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Failed to sending request"
    };
  }
}
// #endregion uploadResource

// #region deleteResource
// Delete Resource
export async function deleteResource(
  clientID: string,
  studyID: string,
  resourceID: string
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  try {
    const response = await apiFetcher("/resources/delete", "POST", {
      body: {
        clientID,
        studyID,
        resourceID
      }
    });

    console.log(clientID, studyID, resourceID, "deleteResource");
    console.log(response, "response");
    if (response && (response.status === 200 || response.status === 202)) {
      return {
        rStatus: "success",
        rMessage: "Resource deleted successfully"
      };
    } else {
      switch (response.status) {
        case 500:
          return {
            rStatus: "error",
            rMessage: "Internal server error"
          };

        case 404:
          return {
            rStatus: "error",
            rMessage: "Resource not found"
          };

        case 403:
          return {
            rStatus: "error",
            rMessage: "Unauthorized, permission"
          };

        default:
          return {
            rStatus: "error",
            rMessage: `Failed to sending request, status code: ${response.status}`
          };
      }
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Failed to sending request"
    };
  }
}

// #endregion deleteResource
