import { ResearcherConfigData } from "../models/config.model";
import { getResearcherConfig } from "./config.util";

// #region fetchResearcherConfigAndSetImage
// Function to fetch researcher config and set image source -- profilePage
export async function fetchResearcherConfigAndSetImage(): Promise<string> {
  try {
    const responseData: ResearcherConfigData | false =
      await getResearcherConfig();
    console.log("** VALIDATING Backend data: **");
    if (responseData && responseData.profile_picture) {
      // Check if the profile_picture is valid
      // if (await testImageLoad(responseData.profile_picture)) {
      if (await testImageLoad(responseData.profile_picture)) {
        console.log("Successful, can load image url");
        return responseData.profile_picture;
      } else {
        // Set a default image if the profile_picture is not valid
        console.log("Error, the url is not a valid image");
        return "../../../../images/placeholders/default_profile.jpg";
      }
    } else {
      // Set a default image if there's no profile_picture in the response
      console.log("Error, no image in response");
      return "../../../../images/placeholders/default_profile.jpg";
    }
  } catch (error) {
    // Set a default image if there's an error fetching the researcher config
    console.error(error);
    return "../../../../images/placeholders/default_profile.jpg";
  }
}
// #endregion fetchResearcherConfigAndSetImage

// #region validImageChecker
export async function validImageChecker(image: string): Promise<boolean> {
  try {
    console.log("** VALIDATING Frontend data: **");
    if (image) {
      // Check if the profile_picture is valid
      if ((await isBase64Image(image)) && (await testImageLoad(image))) {
        console.log("Successful, can create image, send to backend");
        return true;
      } else {
        // Set a default image if the profile_picture is not valid
        console.log("Error, the picture is not a valid image");
        return false;
      }
    } else {
      // Set a default image if there's no profile_picture in the response
      console.log("Error, no image in response");
      return false;
    }
  } catch (error) {
    // Set a default image if there's an error fetching the researcher config
    console.error(error);
    return false;
  }
}
// #endregion validImageChecker

// #region base64Maker
// Function to convert image URL to Base64 string
export async function base64Maker(url: string): Promise<string | null> {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error("Error converting image to Base64:", error);
    return null;
  }
}
// #endregion base64Maker

// #region checkImageMimeType
// Check the MIME type of the image
export async function checkImageMimeType(url: string): Promise<boolean> {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const mimeType = response.headers.get("Content-Type");
    if (!mimeType) {
      throw new Error("No Content-Type header found");
    }
    return ["image/png", "image/jpeg", "image/jpg", "image/webp"].includes(
      mimeType
    );
  } catch (error) {
    console.error("Error fetching image:", error);
    return false;
  }
}
// #endregion checkImageMimeType

// #region isBase64Image
// Check if this image is base64
export async function isBase64Image(str: string): Promise<boolean> {
  // Regular expression to check if string is a valid Base64 image
  const base64ImagePattern =
    /^data:image\/(png|jpeg|jpg|webp);base64,([A-Za-z0-9+/]+={0,2})$/;

  // Check if the string matches the Base64 image pattern
  if (!base64ImagePattern.test(str)) {
    return Promise.resolve(false);
  }
  return Promise.resolve(true);
}
// #endregion isBase64Image

// #region testImageLoad
// Check if this image can be loaded
export async function testImageLoad(url: string): Promise<boolean> {
  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
    img.src = url;
  });
}
// #endregion testImageLoad
