import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import { ConfigPhraseCategory, Country, Language, Timezone } from "../types";

export interface SystemConfigData {
  countries: Country[];
  languages: Language[];
  timezones: Timezone[];
  phraseCategories: ConfigPhraseCategory[];
}

export interface ResearcherConfigData {
  timezone_name: string;
  first_name: string;
  surname: string;
  alias: string;
  email: string;
  profile_picture?: string | null;
  multi_factor_auth: boolean;
}

// #region type validation

// #region isTimezone
function isTimezone(obj: any): obj is Timezone {
  const validName = typeof obj.name === "string";
  const validOffset = typeof obj.offset === "number";

  const AllValid = validName && validOffset;
  if (!AllValid) {
    let errors: string[] = [];

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push("Invalid type for name");
      }
    }
    if (!validOffset) {
      if (!obj.hasOwnProperty("offset")) {
        errors.push("missing offset");
      } else {
        errors.push("Invalid type for offset");
      }
    }

    console.log(errors);
    if (errors.length > 0) {
      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `TimeZoneName: ${obj.name}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid Timezone`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return AllValid;
}
// #endregion isTimezone

// #region isCountry
function isCountry(obj: any): obj is Country {
  const validCode = typeof obj.iso_code === "string";
  const validName = typeof obj.name === "string";
  const validCountryCode = typeof obj.country_code === "string";

  const AllValid = validCode && validName && validCountryCode;
  if (!AllValid) {
    console.log(obj);
    let errors: string[] = [];

    if (!validCode) {
      if (!obj.hasOwnProperty("iso_code")) {
        errors.push("missing iso_code");
      } else {
        errors.push("Invalid type for iso_code");
      }
    }
    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push("Invalid type for name");
      }
    }

    if (!validCountryCode) {
      if (!obj.hasOwnProperty("country_code")) {
        errors.push("missing country_code");
      } else {
        errors.push("Invalid type for country_code");
      }
    }

    console.log(errors);
    if (errors.length > 0) {
      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `CountryID: ${obj.id}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid Country`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return AllValid;
}
// #endregion isCountry

// #region isLanguage
export function isLanguage(obj: any): obj is Language {
  const validIsoCode = typeof obj.iso_code === "string";
  const validName = typeof obj.name === "string";

  const AllValid = validIsoCode && validName;
  if (!AllValid) {
    console.log(obj);
    let errors: string[] = [];

    if (!validIsoCode) {
      if (!obj.hasOwnProperty("iso_code")) {
        errors.push("missing iso_code");
      } else {
        errors.push("Invalid type for iso_code");
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push("Invalid type for name");
      }
    }

    if (errors.length > 0) {
      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `LanguageISO: ${obj.iso_code}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid Language`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return AllValid;
}
// #endregion isLanguage

// #region isPhraseCategory
function isPhraseCategory(obj: any): obj is ConfigPhraseCategory {
  console.log(obj);
  const validID = typeof obj.id === "string";
  const validCategory = typeof obj.category === "string";
  const validPhrase_category_name =
    typeof obj.phrase_category_name === "string";

  // const AllValid = validID && validCategory && validName;
  const AllValid = validID && validCategory && validPhrase_category_name;
  if (!AllValid) {
    console.log(obj);
    let errors: string[] = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
      } else {
        errors.push("Invalid type for id");
      }
    }
    if (!validCategory) {
      if (!obj.hasOwnProperty("category")) {
        errors.push("missing category");
      } else {
        errors.push("Invalid type for category");
      }
    }

    if (!validPhrase_category_name) {
      if (!obj.hasOwnProperty("name")) {
        errors.push("missing name");
      } else {
        errors.push("Invalid type for name");
      }
    }

    console.log(errors);
    if (errors.length > 0) {
      let displayMessage = `<strong>Please contact support</strong><br><br>`;
      displayMessage += `CategoryID: ${obj.id}<br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid Phrase Category`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return AllValid;
}
// #endregion isPhraseCategory

// #region isSystemConfigData
function isSystemConfigData(obj: any): obj is SystemConfigData {
  // console.log(obj.phraseCategories);
  const validCountries =
    Array.isArray(obj.countries) && obj.countries.every(isCountry);
  const validLanguages =
    Array.isArray(obj.languages) && obj.languages.every(isLanguage);
  const validTimezones =
    Array.isArray(obj.timezones) && obj.timezones.every(isTimezone);

  const validPhraseCategories =
    Array.isArray(obj.phraseCategories) &&
    obj.phraseCategories.every(isPhraseCategory);

  const AllValid =
    validCountries && validLanguages && validTimezones && validPhraseCategories;
  if (!AllValid) {
    console.log(obj);
    let errors: string[] = [];

    if (!validCountries) {
      if (!obj.hasOwnProperty("countries")) {
        errors.push("missing countries");
      }
    }

    if (!validLanguages) {
      if (!obj.hasOwnProperty("languages")) {
        errors.push("missing languages");
      }
    }

    if (!validTimezones) {
      if (!obj.hasOwnProperty("timezones")) {
        errors.push("missing timezones");
      }
    }

    if (!validPhraseCategories) {
      if (!obj.hasOwnProperty("phraseCategories")) {
        errors.push("missing phraseCategories");
      }
    }

    console.log(errors);
    if (errors.length > 0) {
      let displayMessage = `<strong>Please contact support</strong><br><br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid Config Data`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }
  return AllValid;
}
// #endregion isSystemConfigData

// #region isResearcherConfigData
function isResearcherConfigData(obj: any): obj is ResearcherConfigData {
  // Define validation checks for each property
  // console.log(obj, "Calling isResearcherConfigData function");
  const validTimezoneID = typeof obj.timezone_name === "string";
  const validName = typeof obj.first_name === "string";
  const validSurname = typeof obj.surname === "string";
  const validEmail = typeof obj.email === "string";
  const validProfilePhoto: boolean =
    typeof obj.profile_picture === "string" ||
    obj.profile_picture === null ||
    obj.profile_picture === undefined;
  const validMFA = typeof obj.multi_factor_auth === "boolean";

  // Combine the validation checks using logical AND
  const allValid =
    validTimezoneID &&
    validName &&
    validSurname &&
    validEmail &&
    validMFA &&
    validProfilePhoto;

  if (!allValid) {
    console.log(obj);
    let errors: string[] = [];

    if (!validTimezoneID) {
      errors.push("Invalid 'timezoneID' property");
    }

    if (!validName) {
      errors.push("Invalid 'name' property");
    }

    if (!validSurname) {
      errors.push("Invalid 'surname' property");
    }

    if (!validEmail) {
      errors.push("Invalid 'email' property");
    }

    if (!validProfilePhoto) {
      errors.push("Invalid 'photo' property");
    }

    if (!validMFA) {
      errors.push("Invalid 'MFA' property");
    }

    console.log(errors);
    if (errors.length > 0) {
      let displayMessage = `<strong>Please contact support</strong><br><br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid User Config Data`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }

  return allValid;
}
// #endregion isResearcherConfigData

// #endregion type validation

// #region fetchSystemConfig
export async function fetchSystemConfig(): Promise<SystemConfigData | string> {
  try {
    const response = await apiFetcher<any>("/systemConfig", "POST", {
      body: {}
    });

    // console.log("response", response);

    if (response.status === 200 && response.data !== null) {
      // console.log(response.data);
      if (isSystemConfigData(response.data)) {
        // console.log("Valid data received");
        return response.data;
      } else {
        console.log("Invalid data received");
        return "Invalid data received";
      }
    } else {
      console.log("Failed to fetch all Config");
      return "Failed to fetch all Config";
    }
  } catch (error) {
    console.error("An error occurred while fetching Config:", error);
    return "Failed to fetch all Config";
  }
}
// #endregion fetchSystemConfig

// #region fetchResearcherConfig
export async function fetchResearcherConfig(): Promise<
  ResearcherConfigData | string
> {
  try {
    const response = await apiFetcher<any>("/getResearcherData", "POST", {
      body: {}
    });
    // console.log(response.data);
    // console.log(response.status);
    if (response.status === 200 && response.data !== null) {
      if (isResearcherConfigData(response.data)) {
        // console.log("Valid data received");
        return response.data as ResearcherConfigData;
      } else {
        console.log("Invalid data received");
        return "Invalid data received";
      }
    } else if (response.status === 202) {
      // console.log("202");
      return response.status.toString();
    } else if (response.status === 400) {
      // console.log("400");
      return response.status.toString();
    } else if (response.status === 404) {
      // console.log("404");
      return response.status.toString();
    } else {
      console.log("User data has been pulled unsuccessfully");
      return response.status.toString();
    }
  } catch (error) {
    console.error("An error occurred while fetching ResearcherData:", error);
    return "Failed to fetch all ResearcherData";
  }
}
// #endregion fetchResearcherConfig

// #region updateResearcherData
export async function updateResearcherData(sendFormDataObject: {
  first_name: string;
  surname: string;
  timezone: string;
  profile_picture: string;
}): Promise<boolean> {
  try {
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    const response = await apiFetcher<any>("/updateResearcherData", "POST", {
      body: sendFormDataObject
    });

    // console.log(response, "This is the response data received");
    // **********************************   NOTE PLEASE  ********************************** //
    // if you change the response messages CHANGE IT IN profileSettings.component - updateData FUNCTION too!!!!!!!!!
    if (response.status === 202) {
      return true;
    }

    if (response.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Invalid Data`,
        html: `Please check your data and try again`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return false;
    }

    if (response.status === 404) {
      Swal.fire({
        icon: "error",
        title: `User not found`,
        html: `Please contact support`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return false;
    }

    if (response.status === 500) {
      Swal.fire({
        icon: "error",
        title: `Server Error`,
        html: `Please contact support`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return false;
    }

    Swal.fire({
      icon: "error",
      title: `Server Error`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });

    return false;
  } catch (error) {
    console.error("An error occurred while updating researcher data:", error);
    Swal.fire({
      icon: "error",
      title: `Server Error`,
      html: `Please contact support`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return false;
  }
}
// #endregion updateResearcherData
