import Swal from "sweetalert2";
import "../App.scss";
import { advanceAddingRewards } from "../models/advanceAdding.model";
import { ParticipantIDs } from "../models/participant.model";

const AdvancedAdding = async (
  source: string,
  IDList: ParticipantIDs[],
  clientID: string,
  studyID?: string
) => {
  //Check for worker first
  let workerID = localStorage.getItem("workerID");
  if (workerID && workerID !== null) {
    Swal.fire({
      icon: "error",
      title: "Please wait for the current worker to finish",
      confirmButtonColor: "#3085d6"
    });
    return;
  }

  let alreadyAdded: string[] = [];
  let unidentifiedUsers: string[] = [];
  let needsToBeAdded: string[] = [];

  try {
    await Swal.fire({
      title: "Advanced Adding",
      text: `Please enter a list of participant IDs separated by a comma, space or new line`,
      input: "textarea",
      inputPlaceholder: `Participant IDs`,
      inputAttributes: {
        "aria-label": `Participant IDs`
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      //This only runs on confirm
      inputValidator: (value) => {
        //Validating the input
        if (!value) {
          return `Please enter a list of a valid Participant IDs`;
        }
        // let idRegex = /^[0-9\s,]+$/;
        // if (value && !idRegex.test(value)) {
        //   return `Participant IDs must be numbers`;
        // }
        //Splitting the data if it is valid
        let addedParticipantsIDs = value.split(/[\s,]+/);
        //remove duplication
        addedParticipantsIDs = [...new Set(addedParticipantsIDs)];
        //Looping through the participants to split them into the 3 arrays needsToBeTagged which get the whole have the whole object, unidentifiedUsers which only gets the id and alreadyTagged which gets the whole object
        if (addedParticipantsIDs && addedParticipantsIDs.length > 0) {
          for (let i = 0; i < addedParticipantsIDs.length; i++) {
            const id = addedParticipantsIDs[i];
            const participant = IDList.find(
              (p) => p.id === id || p.external_id === id
            );
            if (participant) {
              if (!needsToBeAdded.includes(participant.id)) {
                needsToBeAdded.push(participant.id);
              }
            } else {
              unidentifiedUsers.push(id);
            }
          }
        } else {
          return "Failed to split given IDs";
        }
      }
    }).then((result) => {
      const needsToBeTaggedList =
        needsToBeAdded && needsToBeAdded.length > 0
          ? needsToBeAdded.map((id) => id + " ").join("\n")
          : "No IDs to add";
      // const alreadyTaggedList =
      //   AlreadyAdded && AlreadyAdded.length > 0
      //     ? AlreadyAdded
      //       .map(
      //         (id) =>
      //           id + " "
      //       )
      //       .join("\n")
      //     : "No duplicate IDs found";
      const unidentifiedUsersList =
        unidentifiedUsers && unidentifiedUsers.length > 0
          ? unidentifiedUsers.join("\n")
          : "No unidentified IDs found";
      let extraInfo = `By selecting "Continue", you will proceed to send ${needsToBeAdded?.length ? needsToBeAdded.length : 0} to ${source}`;
      if (result.isConfirmed) {
        Swal.fire({
          title: "Advanced Adding",
          html:
            '<div class="advanced_adding_body">Below, you will find the details of participants to be included.</div>' +
            `<div class="advanced_adding_info">${extraInfo}` +
            '<div class="advanced_adding">' +
            '<div class="advanced_adding_column">' +
            '<div class="advanced_adding_title">Ready to Add</div>' +
            '<div class="advanced_adding_content">' +
            '<textarea class="advanced_adding_textarea_success" id="needsToBeTagged" readonly>' +
            needsToBeTaggedList +
            "</textarea>" +
            "</div>" +
            "</div>" +
            // '<div class="advanced_adding_column">' +
            // '<div class="advanced_adding_title">Already Added</div>' +
            // '<div class="advanced_adding_content">' +
            // '<textarea class="advanced_adding_textarea_warning" id="alreadyTagged" readonly>' +
            // alreadyTaggedList +
            // "</textarea>" +
            // "</div>" +
            // "</div>" +
            '<div class="advanced_adding_column">' +
            `<div class="advanced_adding_title">Unidentified IDs</div>` +
            '<div class="advanced_adding_content">' +
            '<textarea class="advanced_adding_textarea_failed" id="unidentifiedUsers" readonly>' +
            unidentifiedUsersList +
            "</textarea>" +
            "</div>" +
            "</div>" +
            "</div>",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          showConfirmButton: needsToBeAdded?.length > 0 ? true : false,
          confirmButtonText: "Continue",
          cancelButtonText: "Cancel",
          allowOutsideClick: false
        }).then(async (result) => {
          if (result.isConfirmed && needsToBeAdded.length > 0) {
            Swal.fire({
              title: "Adding Points",
              text: `Please enter the amount of points you want add to the ${needsToBeAdded.length} participants`,
              input: "number",
              inputAttributes: {
                min: "0",
                step: "1"
              },
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: "Add Points",
              cancelButtonText: "Cancel",
              allowOutsideClick: false,
              inputValidator: (value) => {
                if (!value) {
                  return "Please enter a valid number";
                }
              }
            }).then(async (result) => {
              if (result.isConfirmed) {
                let results;
                switch (source) {
                  case "rewards":
                    results = await advanceAddingRewards(
                      clientID,
                      needsToBeAdded,
                      result.value,
                      studyID ? studyID : undefined
                    );
                    break;
                  default:
                    return;
                }
                if (results === "Success") {
                  Swal.fire({
                    icon: "success",
                    title: "Points added successfully",
                    confirmButtonColor: "#3085d6"
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: `Failed to add points\n${results}`,
                    confirmButtonColor: "#3085d6"
                  });
                }
              } else {
                return;
              }
            });
          }
          return;
        });
      }
      return;
    });
  } catch (e) {
    console.log("Error adding participant");
  }
};

export default AdvancedAdding;
