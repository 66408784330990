import React, { useEffect, useState } from "react";
import { ExecutionRecord } from "../../../types";
import { formatDate } from "../../../utilities/utils.ts";
import PaginationNavigation from "../../Tables/PaginationNavigation/paginationNavigation";

type ModalCustomReportDetailsTableProps = {
  executions: ExecutionRecord[];
};

const ModalCustomReportDetailsTable: React.FC<
  ModalCustomReportDetailsTableProps
> = ({ executions }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentExecutions, setCurrentExecutions] = useState<ExecutionRecord[]>(
    []
  );
  const historyPerPage = 15;

  useEffect(() => {
    if (executions && executions.length > 0) {
      setCurrentExecutions(
        executions.slice(
          (currentPage - 1) * historyPerPage,
          currentPage * historyPerPage
        )
      );
    }
  }, [executions, currentPage]);
  return (
    <div>
      <div className="table-responsive mb-2">
        <table className="cust_table table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Date Run</th>
              <th>Successful Execution</th>
            </tr>
          </thead>
          <tbody>
            {currentExecutions.length === 0 ? (
              <tr>
                <td colSpan={3} className="text-center">
                  No history available
                </td>
              </tr>
            ) : (
              currentExecutions.map((record) => (
                <tr key={record.id}>
                  <td>{record.id}</td>
                  <td>{formatDate(new Date(record.scheduled_run_time))}</td>
                  <td>
                    {record.successful_execution === "Y"
                      ? "Success"
                      : record.successful_execution === "N"
                        ? "Failure"
                        : "N/A"}
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
      {executions.length > 0 && (
        <PaginationNavigation
          currentPage={currentPage}
          totalResults={executions ? executions.length : 0}
          resultsPerPage={historyPerPage}
          setCurrentPage={setCurrentPage}
        />
      )}
    </div>
  );
};

export default ModalCustomReportDetailsTable;
