import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { usePermissions } from "../../contexts/UserContext";
import {
  fetchScriptList,
  sendDeleteScript,
  sendExecuteScript
} from "../../models/dfu.model";
import { ShortCustomReport } from "../../types";
import FiltersContainer from "../Tables/Filters/filtersContainer.component";
import PaginationNavigation from "../Tables/PaginationNavigation/paginationNavigation";
import TableCustomReports from "../Tables/TableCustomReports/tableCustomReports.component";
import ModalCustomReportDetails from "./ModalCustomReportDetails/modalCustomReportDetails.component";
import "./customReportsTableContainer.scss";

type CustomReportsTableContainerProps = {
  createNewReport: () => void;
  updateReport: (id: number) => void;
};

const CustomReportsTableContainer: React.FC<
  CustomReportsTableContainerProps
> = ({ createNewReport, updateReport }) => {
  const { hasPermission } = usePermissions();
  const nav = useNavigate();

  const [reports, setReports] = useState<ShortCustomReport[]>([]);
  const [filteredReports, setFilteredReports] = useState<ShortCustomReport[]>(
    []
  );
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [searchText, setSearchText] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<string>("");
  const tableType = "custom_reports";
  const { clientID } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentReports, setCurrentReports] = useState<ShortCustomReport[]>([]);
  const [reportsPerPage] = useState(20);
  const [selectedReport, setSelectedReport] = useState<
    ShortCustomReport["id"] | null
  >(null);

  useEffect(() => {
    async function getScriptList() {
      const numClientID = clientID ? parseInt(clientID) : undefined;
      if (!numClientID || isNaN(numClientID)) {
        console.error("Client ID is not a number");
        return;
      }
      setLoading(true);
      setLoadingError("");
      // Fetch reports data from database
      const response = await fetchScriptList(numClientID);
      setLoading(false);

      if (response.rStatus === "error") {
        setLoadingError(response.message);
      }

      if (response.rStatus === "success") {
        setReports(response.rData);
      }

      setCurrentPage(1);
    }
    getScriptList();
  }, [clientID]);

  useEffect(() => {
    if (loadingError || !reports || reports.length === 0) {
      return;
    }

    let preliminaryFiltered = reports.filter((report) =>
      report.report_name.toLowerCase().includes(searchText.toLowerCase())
    );

    setFilteredReports(preliminaryFiltered);
  }, [reports, searchText, loadingError]);

  useEffect(() => {
    if (searchText !== "") {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  }, [searchText]);

  const handleClearFilters = () => {
    // Clear the filter state
    setSearchText("");
  };

  useEffect(() => {
    if (loadingError) {
      return;
    }

    setCurrentReports(
      filteredReports.slice(
        (currentPage - 1) * reportsPerPage,
        currentPage * reportsPerPage
      )
    );
  }, [currentPage, filteredReports, reportsPerPage, loadingError]);

  async function runScript(id: number) {
    console.log("Running script with ID: ", id);
    const numClientID = clientID ? parseInt(clientID) : undefined;
    if (!numClientID || isNaN(numClientID)) {
      console.error("Client ID is not a number");
      return;
    }

    const script = reports.find((report) => report.id === id);
    if (!script) {
      console.error("Script not found");
      return;
    }

    const swalVerifyRes = await Swal.fire({
      title: "Are you sure?",
      html: `<strong>${script.report_name}</strong><br/>This will run the script immediately`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, run",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
      confirmButtonColor: "#3085d6"
    });
    if (!swalVerifyRes.isConfirmed) {
      return;
    }

    const res = await sendExecuteScript(numClientID, id);
    if (res.rStatus === "error") {
      console.error(res.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.message
      });
      return;
    }
    const swalRedirectRes = await Swal.fire({
      icon: "success",
      title: "Success",
      text: "Script execution has been added to the worker",
      confirmButtonText: "Go to Reports",
      showCancelButton: true,
      cancelButtonText: "OK",
      allowOutsideClick: false,
      confirmButtonColor: "#3085d6",
      reverseButtons: true
    });

    if (swalRedirectRes.isConfirmed) {
      nav("/reports");
    }
  }

  async function deleteScript(id: number) {
    console.log("Deleting script with ID: ", id);
    const numClientID = clientID ? parseInt(clientID) : undefined;
    if (!numClientID || isNaN(numClientID)) {
      console.error("Client ID is not a number");
      return;
    }

    const script = reports.find((report) => report.id === id);
    if (!script) {
      console.error("Script not found");
      return;
    }

    const swalVerifyRes = await Swal.fire({
      title: "Are you sure?",
      html: `This action cannot be undone<br/>Type <strong class="noHightlight">${script.report_name.toUpperCase()}</strong> to confirm`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete",
      cancelButtonText: "No, cancel",
      reverseButtons: true,
      confirmButtonColor: "#d33",
      input: "text",
      inputPlaceholder: "Type the report name to confirm",
      preConfirm: (inputValue) => {
        if (inputValue !== script.report_name.toUpperCase()) {
          Swal.showValidationMessage("Report name does not match");
          return false;
        }
        return true;
      }
    });
    if (!swalVerifyRes.isConfirmed) {
      return;
    }

    const res = await sendDeleteScript(numClientID, id);
    if (res.rStatus === "error") {
      console.error(res.message);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: res.message,
        confirmButtonColor: "#3085d6"
      });
      return;
    }

    Swal.fire({
      icon: "success",
      title: "Success",
      text: "Script has been deleted",
      confirmButtonColor: "#3085d6"
    });

    const updatedReports = reports.filter((report) => report.id !== id);
    setReports(updatedReports);
  }

  return (
    <>
      <div className="custom_reports_table_container">
        {(hasPermission("subject") ||
          hasPermission("scripts", "all") ||
          hasPermission("scripts", "write")) && (
          <button
            className="btn btn-primary mb-2"
            onClick={() => createNewReport()}
          >
            Create New Report
          </button>
        )}

        <div>
          {loading ? (
            <div className="d-flex justify-content-center custom_spinner_container">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : loadingError ? (
            <div>{loadingError}</div>
          ) : (
            <>
              <div className="row">
                <FiltersContainer
                  key="FiltersContainer"
                  tableType={tableType}
                  filterApplied={filterApplied}
                  setFilterApplied={setFilterApplied}
                  handleClearFilters={handleClearFilters}
                  setSearch={setSearchText}
                />
              </div>
              <div className="row">
                {!reports || reports.length === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="no_results_container">
                        <h3>No reports found</h3>
                      </div>
                    </div>
                  </div>
                ) : filteredReports.length === 0 ? (
                  <div className="row">
                    <div className="col-12">
                      <div className="no_results_container">
                        <h3>No results found</h3>
                        <p>Try adjusting your search or filters</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <TableCustomReports
                    currentReports={currentReports}
                    filteredReportsLength={filteredReports.length}
                    updateReport={updateReport}
                    openReport={(id: number) => setSelectedReport(id)}
                    runScript={runScript}
                    deleteReport={deleteScript}
                  />
                )}
              </div>
              <div className="row">
                <div className="col-auto">
                  {filteredReports.length > 0 && (
                    <PaginationNavigation
                      currentPage={currentPage}
                      totalResults={filteredReports.length}
                      resultsPerPage={reportsPerPage}
                      setCurrentPage={setCurrentPage}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {selectedReport && clientID && (
        <ModalCustomReportDetails
          clientID={parseInt(clientID)}
          reportID={selectedReport}
          close={() => setSelectedReport(null)}
        />
      )}
    </>
  );
};

export default CustomReportsTableContainer;
