import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { IconBroom, IconLogout } from "../../../../assets/images/icons/icons";
import {
  MessagesSent,
  RecipientsList,
  ResendCommunication,
  fetchRecipients
} from "../../../../models/messages.model";
import {
  Country,
  Language,
  ParamsSurveyPage,
  Timezone
} from "../../../../types";
import {
  getSystemConfigCountries,
  getSystemConfigLanguages
} from "../../../../utilities/config.util";
import { convertTime, formatDate } from "../../../../utilities/utils";
import MultipleDropdown from "../../../Dropdown/multipleDropdown.component";
import PaginationNavigation from "../../../Tables/PaginationNavigation/paginationNavigation";
import TimezoneSwitcher from "../../../Tables/TimeZoneSwitcher/timezoneSwitcher";
import ViewParticipantModal from "../../../ViewParticipantModal/viewParticipantModal";
import "./history.scss";

interface RecipientHistoryProps {
  communication: MessagesSent;
  phraseLibraryID: string;
  hideRecipientHistory: () => void;
  timezones: Timezone[];
  timezone: string;
  researcherTimezone: Timezone | null;
  handleChangeTimezone: (timezone: string) => void;
}
const RecipientHistory: React.FC<RecipientHistoryProps> = ({
  communication,
  phraseLibraryID,
  hideRecipientHistory,
  timezones,
  timezone,
  handleChangeTimezone,
  researcherTimezone
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");
  const [data, setData] = useState<RecipientsList | undefined>();
  const [filterData, setFilterData] = useState<RecipientsList | undefined>();
  const [searchInput, setSearchInput] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const options = ["Pending", "Sent", "Failed", "Delivered"];
  const { clientID, studyID } = useParams<ParamsSurveyPage>();
  //Pagination
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [itemsPerPage, setItemsPerPage] = useState<number>(10);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [selectedParticipantID, setSelectedParticipantID] =
    useState<string>("");

  const [languages, setLanguages] = useState<Language[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);

  const [loadingLanguages, setLoadingLanguages] = useState<boolean>(true);
  const [loadingCountries, setLoadingCountries] = useState<boolean>(true);

  /*----------------------------------------------------*/
  /*------------------Loading data----------------------*/
  const loadData = async () => {
    setIsLoading(true);
    try {
      const response = await fetchRecipients(
        clientID!,
        studyID!,
        communication.id
      );
      if (typeof response !== "string") {
        setData(response);
      } else setError(response);
    } catch (error) {
      setError("Error loading data");
    }
    setIsLoading(false);
  };
  useEffect(() => {
    loadData();
  }, [communication]);
  //Filter data
  useEffect(() => {
    if (data) {
      let filteredData = data;
      //Filter by status
      if (selectedType.length > 0) {
        filteredData = filteredData.filter((item) =>
          selectedType.some((type) =>
            item.status.toLowerCase().includes(type.toLowerCase())
          )
        );
      }

      if (searchInput.length > 0) {
        filteredData = filteredData.filter(
          (item) =>
            item.recipientsFullName
              .toLowerCase()
              .includes(searchInput.toLowerCase()) ||
            item.email.toLowerCase().includes(searchInput.toLowerCase()) ||
            item.userID.includes(searchInput)
        );
      }
      //Search by Country
      // if (searchInput.length > 0) {
      //   filteredData = filteredData.filter(item => item.country.toLowerCase().includes(searchInput.toLowerCase()));
      // }

      setFilterData(filteredData);
    }
  }, [data, selectedType, searchInput]);

  const currentItems = filterData?.slice(indexOfFirstItem, indexOfLastItem);
  /*----------------------------------------------------*/
  /*------------------Functions-------------------------*/
  const handleTypeChange = (option: string) => {
    setSelectedType((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };
  const handleClearFilters = () => {
    setSearchInput("");
    setSelectedType([]);
  };
  const ResendMessage = async (participantId: string) => {
    try {
      const workerID = localStorage.getItem("workerID");
      console.log(workerID);
      if (workerID && typeof workerID === "string") {
        Swal.fire({
          icon: "error",
          title: "Please wait for the current worker to finish",
          confirmButtonColor: "#3085d6"
        });
      } else {
        const response = await ResendCommunication(
          clientID!,
          studyID!,
          communication.id,
          [participantId]
        );
        if (typeof response !== "string") {
          Swal.fire({
            icon: "success",
            title: "Communication has been queued",
            text: `The communication has been queued and will be sent shortly.`,
            confirmButtonColor: "#3085d6"
          });
        } else {
          setError(response);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: `An error occurred while sending the communication.${response}`,
            confirmButtonColor: "#3085d6"
          });
        }
      }
    } catch (error) {
      setError("Error loading data");
    }
  };
  /*----------------------------------------------------*/

  function handleViewParticipant(id: string) {
    setSelectedParticipantID(id);
  }

  function handleCloseModalViewParticipant() {
    setSelectedParticipantID("");
  }

  useEffect(() => {
    // get languages from local storage using getConfigLanguages
    const fetchAllConfigLanguages = async () => {
      try {
        const jsonData: Language[] | false = await getSystemConfigLanguages();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setError("Error getting languages");
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setLanguages(jsonData);
          } catch {
            console.error("Error setting languages");
            throw new Error("Error setting languages");
          }
        }
      } catch {
        setError("Error getting languages");
      }
    };

    const fetchAllConfigCountries = async () => {
      try {
        const jsonData: Country[] | false = await getSystemConfigCountries();
        // console.log("jsonData:", jsonData);
        if (jsonData === false) {
          setError("Error getting countries");
        } else {
          try {
            // console.log("jsonData:", jsonData);
            setCountries(jsonData);
          } catch {
            console.error("Error setting countries");
            throw new Error("Error setting countries");
          }

          // setCountries([{ iso_code: "en", name: "English" }]);
        }
      } catch {
        setError("Error getting countries");
      }
    };

    const promises = [fetchAllConfigLanguages(), fetchAllConfigCountries()];

    Promise.all(promises)
      .then(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
      })
      .catch(() => {
        setLoadingLanguages(false);
        setLoadingCountries(false);
      });
  }, []);

  return (
    <>
      <div className="history-title-container">
        <div className="d-inline-block d-md-flex justify-content-between d-md-inline-block gap-2">
          <div className="flex-grow-1">
            <div className="d-flex">
              <button
                className="btn btn-primary btn-exit me-2"
                onClick={hideRecipientHistory}
              >
                <IconLogout className="icon icon_white" />
              </button>
              <h5 className="history-title align-content-center">
                Recipient History for messageID: {communication.id}
              </h5>
            </div>

            <div>Type: {communication.type}</div>
            <div className="">
              Message:{" "}
              <span className="">{communication.phraseLibrary.message}</span>
            </div>
          </div>
          <div className="flex-shrink-0 align-content-end">
            <TimezoneSwitcher
              tableType="messagesTable"
              timezone={timezone}
              handleChangeTimezone={handleChangeTimezone}
              researcherTimezone={researcherTimezone}
            />
          </div>
        </div>
      </div>
      <div className="history-info-container">
        <div className="history-filter">
          <div className="history-filter-dropdown">
            <MultipleDropdown
              placeholder="Status"
              options={options}
              onOptionToggled={handleTypeChange}
              selectedOptions={selectedType}
            />
          </div>
          <div className="history-filter-search-container">
            <input
              className="search-input"
              type="text"
              id="search"
              name="search"
              placeholder="Search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button
              className="btn btn-primary btn-clear"
              disabled={false}
              onClick={handleClearFilters}
            >
              <IconBroom className="icon icon_white" />
            </button>
          </div>
        </div>
        <div className="table-responsive ">
          <table className="table table-container">
            <thead>
              <tr>
                <th scope="col">Recipient ID</th>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Country</th>
                <th scope="col">Timezone</th>
                <th scope="col">Time Sent</th>
                <th scope="col">Status</th>
                {/* <th scope="col"></th> */}
              </tr>
            </thead>
            <tbody>
              {isLoading || loadingLanguages || loadingCountries ? (
                <tr>
                  <td colSpan={7} style={{ textAlign: "center" }}>
                    <div className="d-flex justify-content-center custom_spinner_container">
                      <div className="spinner-border" role="status"></div>
                    </div>
                  </td>
                </tr>
              ) : error ? (
                <tr>
                  <td colSpan={7}>{error}</td>
                </tr>
              ) : currentItems && currentItems.length > 0 ? (
                currentItems.map((item, index) => {
                  let timezoneOffset = 0;
                  // get participant's timezone offset based off of their timezone, filter though allTimezones
                  const participant_offset = timezones.find(
                    (tz) => tz.name === item.timezone
                  )?.offset;
                  if (
                    timezone === "Participant's Timezone" &&
                    participant_offset
                  ) {
                    timezoneOffset = Number(participant_offset);
                  } else if (timezone === "UTC") {
                    timezoneOffset = 0;
                  } else if (timezone === "ResearcherTimezone") {
                    timezoneOffset = researcherTimezone
                      ? researcherTimezone.offset
                      : 0;
                  }

                  return (
                    <tr key={index}>
                      <td>{item.userID}</td>
                      <td>
                        <span
                          className="participant_name clickable fw-bold"
                          onClick={() =>
                            handleViewParticipant(item.userID.toString())
                          }
                        >
                          {item.recipientsFullName}
                        </span>
                      </td>
                      <td>{item.email}</td>
                      <td>
                        {countries.find(
                          (country) => country.iso_code === item.country
                        )?.name || "N/A"}
                      </td>
                      <td>
                        {item.timezone}{" "}
                        {participant_offset !== undefined &&
                          `(${participant_offset >= 0 ? "+" : "-"}${participant_offset})`}
                      </td>
                      <td>
                        {convertTime(communication.date, timezoneOffset)
                          ? formatDate(
                              convertTime(communication.date, timezoneOffset)
                            )
                          : "Scheduled for sending"}
                      </td>
                      <td>{item.status.toUpperCase()}</td>
                      {/* <td className="text-end">
                      <button
                        className="btn btn-primary btn-view"
                        onClick={() => ResendMessage(item.userID)}
                      >
                        Resend
                      </button>
                    </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={7}>No Recipients Found</td>
                </tr>
              )}
            </tbody>
          </table>
          <PaginationNavigation
            currentPage={currentPage}
            totalResults={filterData?.length || 0}
            resultsPerPage={itemsPerPage}
            setCurrentPage={setCurrentPage}
          />

          {clientID && selectedParticipantID !== "" && (
            <ViewParticipantModal
              participantID={selectedParticipantID}
              closeParticipantModal={() => handleCloseModalViewParticipant()}
              shown={true}
              jsonLanguagesData={languages}
              jsonCountriesData={countries}
              jsonTimezonesData={timezones}
              tableType="participant"
              clientID={clientID}
              studyID={studyID}
              surveyID={undefined}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default RecipientHistory;
