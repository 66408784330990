import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";

// #region advanceAddingRewards

export const advanceAddingRewards = async (
  clientID: string,
  participantIDs: string[],
  points: string,
  studyID?: string
) => {
  try {
    let workerID = localStorage.getItem("workerID");
    if (workerID && workerID !== null) {
      Swal.fire({
        icon: "error",
        title: "Please wait for the current worker to finish",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    Swal.fire({
      title: "Sending data",
      html: "Please wait while we send the data to the server",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    let body = {
      clientID: clientID,
      participantIDs: participantIDs,
      points: parseInt(points),
      bulk: true,
      ...(studyID && { studyID: studyID })
    };
    const response = await apiFetcher<any>(`/rewards/create`, "POST", {
      body: body
    });

    if (response.status === 200 && response.data !== null) {
      if (
        !response.data ||
        typeof response.data !== "object" ||
        !("jobID" in response.data) ||
        (typeof response.data.jobID !== "number" &&
          typeof response.data.jobID !== "string")
      ) {
        return "Invalid worker data received";
      }
      const workerID = response.data.jobID;
      localStorage.setItem("workerID", workerID.toString());
      return "Success";
    }

    if (response.status === 202) {
      return "Worker ID not received";
    }

    if (response.status === 400) {
      return "Please contact support";
    }

    if (response.status === 406) {
      return "Participant blocked at a higher level";
    }

    if (response.status === 404) {
      return "Participant not found";
    }

    return "Failed to send all participants actions";
  } catch (error) {
    console.error(error);
  }
};

// #endregion advanceAddingRewards
