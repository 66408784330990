import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MDILogoIcon from "../../assets/images/logo/MDI logo Icon.webp";
import "./sidebar.scss";
// import { ReactComponent as IconClient } from "../../assets/images/icons/suitcase-solid.svg";
import * as Icons from "../../assets/images/icons/icons";
import {
  SidebarData,
  SidebarDataStudy,
  SidebarDataSurvey,
  SidebarProps
} from "../../types";
import {
  getSidebarData,
  setSidebarData
} from "../../utilities/sideBarInfo.util";
import SidebarLevelFour from "./sidebarLevelFour.component";
import SidebarLevelThree from "./sidebarLevelThree.component";
import SidebarLevelTwo from "./sidebarLevelTwo.component";

import { usePermissions } from "../../contexts/UserContext";

// import { on } from 'events';

// SidebarComponent receives the page as a prop

const SidebarComponent: React.FC<SidebarProps> = ({
  page,
  sidebarPinned,
  onSidebarPinChange,
  currentClient,
  currentStudy,
  currentSurvey,
  currentClientLogo,
  currentStudyPhoto,
  currentSurveyPhoto
}) => {
  const { clientID, studyID, surveyID } = useParams(); // Get from URL
  //   const [theme, setTheme] = useState(localStorage.getItem("theme"));
  //     useEffect(() => {
  //         setTheme(localStorage.getItem("theme"));
  //       }, [localStorage.getItem("theme")]);

  const { hasPermission } = usePermissions();

  // console.log(isAdmin);

  const sideBarPinClickHandler = () => {
    onSidebarPinChange();
  };

  const navigate = useNavigate();

  /*
        Different buttons icons will be displayed based on the link
        Client is the default page
        The default page will be highlighted
        Default page icons:
            - Clients
            - Profile
            - Settings
            - Logout
        Studies page icons:
            - Studies
            - Participants
            - Rewards
            - Messages
            - Settings

    */

  // Create states to store visibility of each icon
  const [selectedIcon, setSelectedIcon] = useState("");
  const [clientsIcon, setClientsIcon] = useState(false);
  const [studiesIcon, setStudiesIcon] = useState(false);
  const [participantsIcon, setParticipantsIcon] = useState(false);
  const [rewardsIcon, setRewardsIcon] = useState(false);
  const [groupsIcon, setGroupsIcon] = useState(false);
  const [messagesIcon, setMessagesIcon] = useState(false);
  const [languagesIcon, setLanguagesIcon] = useState(true);
  const [settingsIcon, setSettingsIcon] = useState(false);
  const [profileIcon, setProfileIcon] = useState(true);
  const [adminIcon, setAdminIcon] = useState(true);
  const [logoutIcon, setLogoutIcon] = useState(true);
  const [importIcon, setImportIcon] = useState(false);
  const [logsIcon, setLogsIcon] = useState(false);
  const [tagsIcon, setTagsIcon] = useState(false);
  const [leadsIcon, setLeadsIcon] = useState(true);
  const [clientReportsIcon, setClientReportsIcon] = useState(false);
  const [globalReportsIcon, setGlobalReportsIcon] = useState(true);
  const [sidebarDisplayData, setSidebarDisplayData] = useState<SidebarData[]>(
    []
  );
  const [customReportsIcon, setCustomReportsIcon] = useState(false);

  const [sidebarConfigState, setSidebarConfigState] = useState<boolean>(false);
  // Config icons
  const [configIcon, setConfigIcon] = useState(false);
  const [configMobileApps, setConfigMobileApps] = useState(false);

  const [showLevelTwo, setShowLevelTwo] = useState<boolean>(false);
  const [showLevelThree, setShowLevelThree] = useState<boolean>(false);
  const [showLevelFour, setShowLevelFour] = useState<boolean>(false);
  const [levelTwoID, setLevelTwoID] = useState<string>("");
  const [levelThreeID, setLevelThreeID] = useState<string>("");
  const [levelThreeData, setLevelThreeData] = useState<SidebarDataStudy[]>([]);
  const [levelFourData, setLevelFourData] = useState<SidebarDataSurvey[]>([]);

  const [loadingSidebarInfo, setLoadingSidebarInfo] = useState<boolean>(true);

  const [showCustomReportsClientsPopup, setShowCustomReportsClientsPopup] =
    useState<boolean>(false);

  // have a state array for the higher icons, they will be greyed out when the lower icons are selected
  const [higherIcons, setHigherIcons] = useState([""]);

  // show the sidebar when hovered over the toggler
  const [sidebarHovered, setSidebarHovered] = useState(false);

  const sidebarTogglerHoverHandler = (hovered: boolean) => {
    setSidebarHovered(hovered);
  };

  useEffect(() => {
    setLoadingSidebarInfo(true);
    const fetchSidebarConfig = async () => {
      const jsonData: false | SidebarData[] = await getSidebarData();
      if (jsonData === false) {
        const jsonNewData: boolean = await setSidebarData();
        if (!jsonNewData) {
          setSidebarDisplayData([]);
        } else {
          // get the data again
          const jsonDataThree: false | SidebarData[] = await getSidebarData();
          if (!jsonDataThree) {
            setSidebarDisplayData([]);
          } else {
            setSidebarDisplayData(jsonDataThree);
          }
        }
      } else {
        setSidebarDisplayData(jsonData);
      }
    };

    fetchSidebarConfig()
      .then(() => {
        setLoadingSidebarInfo(false);
      })
      .catch((error) => {
        console.error("An error occurred:", error);
        setLoadingSidebarInfo(false);
      });
  }, []);

  // based on the link the relevant icons will be displayed

  useEffect(() => {
    const displayIcons = () => {
      setSelectedIcon(page);
      setClientsIcon(false);
      setStudiesIcon(false);
      setParticipantsIcon(false);
      setRewardsIcon(false);
      setMessagesIcon(false);
      // setLanguagesIcon(false);
      setSettingsIcon(false);
      setRewardsIcon(false);
      setImportIcon(false);
      setGroupsIcon(false);
      setLogsIcon(false);
      setTagsIcon(false);
      setConfigIcon(true);
      setLogoutIcon(true);
      setCustomReportsIcon(true);

      if (page === "clients") {
        setSelectedIcon("clients");
        setClientsIcon(true);
      } else if (page === "phraseLibrary") {
        setClientsIcon(false);
        setSelectedIcon("phraseLibrary");
      } else if (page === "leads") {
        setClientsIcon(false);
        setSelectedIcon("leads");
      } else if (page === "global_reports") {
        setClientsIcon(false);
        setSelectedIcon("global_reports");
      } else if (page === "admin") {
        setClientsIcon(false);
        setSelectedIcon("admin");
      } else if (page === "config") {
        setSidebarConfigState(true);
        setClientsIcon(false);
        setConfigIcon(false);
        setSelectedIcon("configMobileApps");
        setConfigMobileApps(true);

        // hide all other icons
        setStudiesIcon(false);
        setParticipantsIcon(false);
        setRewardsIcon(false);
        setMessagesIcon(false);
        setLanguagesIcon(false);
        setSettingsIcon(false);
        setRewardsIcon(false);
        setImportIcon(false);
        setGroupsIcon(false);
        setLogsIcon(false);
        setTagsIcon(false);
        setConfigIcon(false);
        setProfileIcon(false);
        setAdminIcon(false);
        setLeadsIcon(false);
        setClientReportsIcon(false);
        setGlobalReportsIcon(false);
        setCustomReportsIcon(false);
      } else {
        setClientsIcon(true);
        setParticipantsIcon(true);
        setRewardsIcon(true);
        setImportIcon(true);
        setGroupsIcon(true);
        setLogsIcon(true);
        setTagsIcon(true);
        setSettingsIcon(true);
        setClientReportsIcon(true);
        setHigherIcons(["clients"]);
        setCustomReportsIcon(true);
      }

      if (page === "client") {
        setSelectedIcon("clients");
      }
      if (page === "study" || page === "survey") {
        setSelectedIcon("studies");
        setStudiesIcon(true);
        setHigherIcons(["clients", "studies"]);
      }

      if (page === "profile") {
        setClientsIcon(false);
        setStudiesIcon(false);
        setParticipantsIcon(false);
        setRewardsIcon(false);
        setMessagesIcon(false);
        setSettingsIcon(false);
        setImportIcon(false);
        setGroupsIcon(false);
        setLogsIcon(false);
        setTagsIcon(false);
        setClientReportsIcon(false);
        setCustomReportsIcon(false);
        setHigherIcons([""]);
      }

      // TODO: unhide each as development is done
      setMessagesIcon(false);
      setImportIcon(false);
      setGroupsIcon(false);
      setLogsIcon(false);
      setSettingsIcon(false);
    };
    displayIcons();
  }, [page]);

  const handleIconClick = (icon: string) => {
    // change the link based on the icon clicked

    const linkMain = "client"; // fixed to client
    const linkSub = icon; // based on icon clicked
    const linkSubID = clientID; // based on current client

    // if the icon is languages, then the link is different, language library
    if (icon === "phraseLibrary") {
      const link = `/${linkSub}`;
      navigate(link);
      return;
    }

    if (icon === "leads") {
      const link = `/${linkSub}`;
      navigate(link);
      return;
    }

    if (icon === "global_reports") {
      const link = `/reports`;
      navigate(link);
      return;
    }

    // config
    if (icon === "config") {
      const link = `/config`;
      navigate(link);
      return;
    }

    // Viewing a client
    const link = `/${linkMain}/${linkSub}/${linkSubID}`;
    navigate(link);

    // console.log("page: ", page);
    // console.log("linkMain: ", linkMain);
    // console.log("linkSub: ", linkSub);
    // console.log("linkSubID: ", linkSubID);
    // console.log(link);
    // alert(link);
  };

  const handleStudyIconClick = () => {
    // set the page to navigate to when the study icon is clicked
    let studyIconLink = "/clients/" + clientID;
    if (studyID !== null && studyID !== undefined) {
      studyIconLink = studyIconLink + "/" + studyID;
    }
    navigate(studyIconLink);
  };

  const handleClientIconClick = () => {
    // set the page to navigate to when the client icon is clicked
    let clientIconLink = "/clients";
    if (clientID !== null && clientID !== undefined) {
      clientIconLink = clientIconLink + "/" + clientID;
    }
    navigate(clientIconLink);
  };

  function handleHideAllLevels() {
    setShowLevelTwo(false);
    setShowLevelThree(false);
    setShowLevelFour(false);
    // clear level IDs
    setLevelTwoID("");
    setLevelThreeID("");
  }

  function handleShowLevelThree(ID: string) {
    setLevelTwoID(ID);
    setLevelThreeID("");
    setShowLevelThree(true);
    setLevelThreeData(
      sidebarDisplayData.filter((client) => client.clientID === ID)[0]
        .clientStudies as SidebarDataStudy[]
    );
    // console.log("handleShowLevelThree");
  }

  function handleShowLevelFour(ID: string) {
    // console.log("ID", ID);
    setLevelThreeID(ID);
    setShowLevelFour(true);

    // Use `find` to get the first matching client
    const client = sidebarDisplayData.find(
      (client) => client.clientID === levelTwoID
    );

    if (!client) {
      console.error("Client not found");
      setLevelFourData([]);
      return;
    }

    if (!client.clientStudies) {
      console.error("Client studies not found");
      setLevelFourData([]);
      return;
    }

    // Use `find` to get the first matching study
    const study = client.clientStudies.find((study) => study.studyID === ID);

    if (!study) {
      console.error("Study not found");
      setLevelFourData([]);
      return;
    }

    // console.log("study", study);
    // console.log("surveys", study.studySurveys);

    const surveys: SidebarDataSurvey[] | undefined = study.studySurveys;
    if (surveys && surveys.length > 0) {
      // console.log("surveys", surveys.length, surveys);
      setLevelFourData(surveys);
    } else {
      console.log("No surveys");
      setLevelFourData([]);
    }
  }

  return (
    <div
      className={`sidebar_container
      sidebar_${sidebarPinned ? "pinned" : "not_pinned"}
      sidebar_${sidebarHovered ? "hovered" : "not_hovered"}
       ${sidebarConfigState && "sidebar_config"}`}
      onMouseEnter={() => sidebarTogglerHoverHandler(true)}
      onMouseLeave={() => {
        sidebarTogglerHoverHandler(false);
        handleHideAllLevels();
      }}
    >
      <div id="sidebarLevelOne" className="sidebarLevel">
        <button
          className="sidebar_pin clickable"
          onClick={sideBarPinClickHandler}
          title="Pin Sidebar"
        >
          <Icons.IconPin className="icon icon_white" />
        </button>
        <img
          id="sidebar_logo"
          className="clickable"
          title="All Clients"
          src={MDILogoIcon}
          alt="MDI Logo"
          onClick={() => navigate("/clients")}
          onMouseEnter={() => setShowLevelTwo(true)}
          // onMouseLeave={() => setShowLevelTwo(false)}
        />
        <div className="sidebar_buttons">
          <div className="sidebar_buttons_top">
            {clientsIcon && (
              <>
                {/* If client is set, use client logo, else show client icon */}
                {currentClient !== null && currentClient !== undefined ? (
                  <button
                    className={`sidebar_button clickable button_client_logo${
                      selectedIcon === "clients" ? " selected" : ""
                    }${
                      higherIcons.includes("clients")
                        ? " higher"
                        : " not_higher"
                    }`}
                    onClick={() => handleClientIconClick()}
                    data-toggle="tooltip"
                    title="Client"
                    style={{
                      backgroundImage: `url(${currentClientLogo})`
                    }}
                  ></button>
                ) : (
                  <button
                    className={`sidebar_button clickable ${
                      selectedIcon === "clients" ? " selected" : ""
                    }${higherIcons.includes("clients") ? " higher" : ""}`}
                    onClick={() => handleClientIconClick()}
                    data-toggle="tooltip"
                    title="Clients"
                  >
                    <Icons.IconClient className="icon icon_white" />
                  </button>
                )}
              </>
            )}
            {studiesIcon && (
              <>
                {/* If study is set, use study photo, else show study icon */}
                {currentStudy !== null && currentStudy !== undefined ? (
                  <button
                    className={`sidebar_button clickable button_study_logo${
                      selectedIcon === "studies" ? " selected" : ""
                    }${higherIcons.includes("studies") ? " higher" : ""}`}
                    onClick={() => handleStudyIconClick()}
                    data-toggle="tooltip"
                    title="Studies"
                    style={{
                      backgroundImage: `url(${currentStudyPhoto})`
                    }}
                  ></button>
                ) : (
                  <button
                    className={`sidebar_button clickable ${
                      selectedIcon === "studies" ? " selected" : ""
                    }${higherIcons.includes("studies") ? " higher" : ""}`}
                    onClick={() => handleStudyIconClick()}
                    data-toggle="tooltip"
                    title="Studies"
                  >
                    <Icons.IconStudies className="icon icon_white" />
                  </button>
                )}
              </>
            )}

            {(hasPermission("subject") || hasPermission("participant")) &&
              participantsIcon && (
                <button
                  className={`sidebar_button clickable ${
                    selectedIcon === "participants" ? "selected" : ""
                  }`}
                  onClick={() => handleIconClick("participants")}
                  data-toggle="tooltip"
                  title="Participants"
                >
                  <Icons.IconParticipants className="icon icon_white" />
                </button>
              )}
            {(hasPermission("subject") || hasPermission("rewards")) &&
              rewardsIcon && (
                <button
                  className={`sidebar_button clickable ${
                    selectedIcon === "rewards" ? "selected" : ""
                  }`}
                  onClick={() => handleIconClick("rewards")}
                  data-toggle="tooltip"
                  title="Rewards"
                >
                  <Icons.IconRewards className="icon icon_white" />
                </button>
              )}
            {importIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "import" ? "selected" : ""
                }`}
                onClick={() => handleIconClick("import")}
                data-toggle="tooltip"
                title="Import"
              >
                <Icons.IconUpload className="icon icon_white" />
              </button>
            )}
            {groupsIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "groups" ? "selected" : ""
                }`}
                onClick={() => handleIconClick("groups")}
                data-toggle="tooltip"
                title="Groups"
              >
                <Icons.IconContacts className="icon icon_white" />
              </button>
            )}
            {logsIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "logs" ? "selected" : ""
                }`}
                onClick={() => handleIconClick("logs")}
                data-toggle="tooltip"
                title="Logs"
              >
                <Icons.IconDb className="icon icon_white" />
              </button>
            )}
            {(hasPermission("subject") || hasPermission("tag")) && tagsIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "tags" ? "selected" : ""
                }`}
                onClick={() => handleIconClick("tags")}
                data-toggle="tooltip"
                title="Tags"
              >
                <Icons.IconTags className="icon icon_white" />
              </button>
            )}

            {messagesIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "messages" ? "selected" : ""
                }`}
                onClick={() => handleIconClick("messages")}
                data-toggle="tooltip"
                title="Messages"
              >
                <Icons.IconMessages className="icon icon_white" />
              </button>
            )}
            {settingsIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "settings" ? "selected" : ""
                }`}
                onClick={() => handleIconClick("settings")}
                data-toggle="tooltip"
                title="Settings"
              >
                <Icons.IconSettings className="icon icon_white" />
              </button>
            )}
            {/* {(hasPermission("reports") || hasPermission("subject")) &&
              clientReportsIcon && (
                <button
                  className={`sidebar_button clickable ${
                    selectedIcon === "client_reports" ? "selected" : ""
                  }`}
                  onClick={() => handleIconClick("reports")}
                  data-toggle="tooltip"
                  title="Client Reports"
                >
                  <Icons.IconReports className="icon icon_white client_reports" />
                </button>
              )} */}
            {hasPermission("config") && configMobileApps && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "configMobileApps" ? "selected" : ""
                }`}
                onClick={() => handleIconClick("config")}
                data-toggle="tooltip"
                title="Config Mobile Apps"
              >
                <Icons.IconMobile className="icon icon_white icon_config" />
              </button>
            )}
          </div>
          <div className="sidebar_buttons_bottom">
            {(hasPermission("config") || hasPermission("phrases")) &&
              languagesIcon && (
                <button
                  className={`sidebar_button clickable ${
                    selectedIcon === "phraseLibrary" ? "selected" : ""
                  }`}
                  onClick={() => handleIconClick("phraseLibrary")}
                  data-toggle="tooltip"
                  title="Phrase Library"
                >
                  <Icons.IconLanguages className="icon icon_white icon_languages" />
                </button>
              )}
            {/* {(hasPermission("config") || hasPermission("leads")) &&
              leadsIcon && (
                <button
                  className={`sidebar_button clickable ${
                    selectedIcon === "leads" ? "selected" : ""
                  }`}
                  onClick={() => handleIconClick("leads")}
                  data-toggle="tooltip"
                  title="Leads"
                >
                  <Icons.IconLeads className="icon icon_white icon_leads" />
                </button>
              )} */}

            {(hasPermission("config") || hasPermission("global_reports")) &&
              globalReportsIcon && (
                <button
                  className={`sidebar_button clickable ${
                    selectedIcon === "global_reports" ? "selected" : ""
                  }`}
                  onClick={() => handleIconClick("global_reports")}
                  data-toggle="tooltip"
                  title="Global Reports"
                >
                  <Icons.IconMultiReports className="icon icon_white global_reports" />
                </button>
              )}
            {/*
            TODO: Uncomment when custom reports are ready
            <SidebarScriptsButtonContainer
              customReportsIcon={customReportsIcon}
              selectedIcon={selectedIcon}
              sidebarDisplayData={sidebarDisplayData}
            /> */}
            {/* config */}
            {hasPermission("config") && configIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "config" ? "selected" : ""
                }`}
                onClick={() => handleIconClick("config")}
                data-toggle="tooltip"
                title="Config"
              >
                <Icons.IconMobile className="icon icon_white icon_config" />
              </button>
            )}
            {hasPermission("config") && adminIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "admin" ? "selected" : ""
                }`}
                onClick={() => navigate("/admin")}
                data-toggle="tooltip"
                title="Admin"
              >
                <Icons.IconAdmin className="icon icon_white icon_admin" />
              </button>
            )}
            {profileIcon && (
              <button
                className={`sidebar_button clickable ${
                  selectedIcon === "profile" ? "selected" : ""
                }`}
                onClick={() => navigate("/profile")}
                data-toggle="tooltip"
                title="Profile"
              >
                <Icons.IconProfile className="icon icon_white" />
              </button>
            )}

            {logoutIcon && (
              <button
                className={`sidebar_button clickable`}
                onClick={() => navigate("/login")}
                data-toggle="tooltip"
                title="Logout"
              >
                <Icons.IconLogout className="icon icon_white" />
              </button>
            )}
          </div>
        </div>
        {/* {if sidebar not pinned show toggler} */}
        {!sidebarPinned && (
          <div id="sidebar_toggler">
            <Icons.IconSideArrow className="icon icon_white" />
          </div>
        )}
      </div>
      {sidebarDisplayData && sidebarDisplayData.length > 0 ? (
        <>
          {showLevelTwo &&
            // do not show level two if in the config section
            !sidebarConfigState && (
              <SidebarLevelTwo
                sidebarDisplayData={sidebarDisplayData}
                showLevel={showLevelTwo}
                currentClientID={
                  clientID !== null && clientID !== undefined ? clientID : ""
                }
                handleShowLevelLowerLevel={handleShowLevelThree}
              />
            )}
          {(hasPermission("subject") || hasPermission("study")) &&
            showLevelThree &&
            showLevelTwo &&
            levelTwoID &&
            levelThreeData &&
            levelThreeData.length > 0 && (
              <SidebarLevelThree
                sidebarDisplayData={levelThreeData}
                showLevel={showLevelThree}
                hoveredClientID={levelTwoID}
                handleShowLevelLowerLevel={handleShowLevelFour}
                currentStudyID={
                  studyID !== null && studyID !== undefined ? studyID : ""
                }
              />
            )}
          {/* All levels need to be set as shown to prevent state bugs */}
          {(hasPermission("subject") || hasPermission("survey")) &&
            showLevelFour &&
            showLevelTwo &&
            showLevelThree &&
            levelThreeID &&
            levelFourData &&
            levelFourData.length > 0 && (
              <SidebarLevelFour
                sidebarDisplayData={levelFourData}
                showLevel={showLevelFour}
                hoveredClientID={levelTwoID}
                hoveredStudyID={levelThreeID}
                currentSurveyID={
                  surveyID !== null && surveyID !== undefined ? surveyID : ""
                }
              />
            )}
        </>
      ) : showLevelTwo && loadingSidebarInfo ? (
        <div
          id="sidebarLevelTwo"
          className={`sidebarLevel ${showLevelTwo ? "show" : "hide"}`}
        >
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only"></span>
          </div>
        </div>
      ) : (
        <div
          id="sidebarLevelTwo"
          className={`sidebarLevel ${showLevelTwo ? "show" : "hide"}`}
        >
          <p>Error</p>
        </div>
      )}
    </div>
  );
};

export default SidebarComponent;
