// Actions dropdown component for participant
import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import * as Icons from "../../../../../assets/images/icons/icons";
import { useOutsideClick } from "../../../../../utilities/utils";
import "./appAction.scss";

import {
  promoteStagingMinorVersion,
  rollbackMinorVersion,
  updateMinorVersion
} from "../../../../../models/appVersions.model";

interface AppActionsProps {
  minorID: number;
  closeDropdown: () => void;
  shown: boolean;
  environmentType: string;
  platformType: string;
  selectedMajorVersion: number;
  outsideDropdown: () => void;
  status: boolean;
  description: string;
  latestMinorVersion: number | null;
  fetchMinorCallBack: () => void;
}

const AppActions: React.FC<AppActionsProps> = ({
  minorID,
  closeDropdown,
  shown,
  environmentType,
  platformType,
  selectedMajorVersion,
  outsideDropdown,
  status,
  latestMinorVersion,
  description,
  fetchMinorCallBack
}) => {
  const [fields, setFields] = useState<string[]>([]);

  // Actions available based on environment type
  useEffect(() => {
    switch (environmentType) {
      case "production": {
        let fields = [];
        if (latestMinorVersion !== minorID) {
          fields.push(status === false ? "AppActive" : "AppInactive");
        }

        // Description
        fields.push("Description");

        // Roll back to this version
        if (status === true && latestMinorVersion !== minorID) {
          fields.push("Rollback");
        }

        setFields(fields);
        break;
      }
      case "staging": {
        let fields = [status === false ? "AppActive" : "AppInactive"];

        // Description
        fields.push("Description");

        // Promote
        if (status === true) {
          fields.push("Promote");
        }

        setFields(fields);
        break;
      }
      default:
        setFields(["NoActionsAvailable"]);
    }
  }, [status, environmentType, latestMinorVersion, minorID]);

  const handleOnActionClick = (action: string) => {
    const sendingAppActions = async () => {
      try {
        switch (action) {
          case "Promote": {
            // Swal alert, preconfirm with PROMOTE input
            const result = await Swal.fire({
              icon: "warning",
              title: "Promote To Production",
              html: `Promoting this version to production will make it available to all users.<br>
              Please type 'PROMOTE' to confirm.`,
              input: "text",
              inputPlaceholder: "PROMOTE",
              showCancelButton: true,
              confirmButtonText: "Promote",
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancel",
              cancelButtonColor: "#d33",
              preConfirm: (inputValue) => {
                if (inputValue.toLowerCase().trim() !== "promote") {
                  Swal.showValidationMessage(
                    `Request failed: You need to type "PROMOTE" to confirm.`
                  );
                  return false;
                }
                return true;
              }
            });

            if (!result.isConfirmed) {
              return;
            }

            Swal.fire({
              title: "Promoting Staging Minor Version To Production",
              html: "Please wait...",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            const response = await promoteStagingMinorVersion(
              platformType,
              environmentType,
              selectedMajorVersion,
              minorID
            );

            if (response) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Promoted To Production",
                confirmButtonColor: "#3085d6",
                didClose: () => {
                  window.location.reload();
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed To Promote To Production",
                confirmButtonColor: "#3085d6"
              });
            }

            break;
          }
          case "AppInactive": {
            console.log("AppInactive");
            // Swal alert, preconfirm button
            const result = await Swal.fire({
              icon: "info",
              title: "Set To Inactive",
              html: `Are you sure you want to set this version to inactive?<br>
              <strong>You will no longer be able to rollback to / promote this version once inactive.</strong>`,
              showCancelButton: true,
              confirmButtonText: "Set To Inactive",
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancel",
              cancelButtonColor: "#d33"
            });

            if (!result.isConfirmed) {
              return;
            }

            Swal.fire({
              title: "Updating Minor Version Status",
              html: "Please wait...",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            const response = await updateMinorVersion(
              platformType,
              selectedMajorVersion,
              minorID,
              environmentType,
              undefined,
              false
            );

            if (response) {
              Swal.fire({
                icon: "success",
                title: "Success",
                html: "Set to Inactive",
                confirmButtonColor: "#3085d6",
                didClose: () => {
                  fetchMinorCallBack();
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to set to Inactive",
                confirmButtonColor: "#3085d6"
              });
            }

            break;
          }
          case "AppActive": {
            console.log("AppActive");
            // Swal alert, preconfirm button
            const result = await Swal.fire({
              icon: "info",
              title: "Set To Active",
              html: `Are you sure you want to set this version to active?<br>
              <strong>You will be able to rollback to / promote this version once active.</strong>`,
              showCancelButton: true,
              confirmButtonText: "Set To Active",
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancel",
              cancelButtonColor: "#d33",
              showLoaderOnConfirm: true
            });

            if (!result.isConfirmed) {
              return;
            }

            Swal.fire({
              title: "Updating Minor Version Status",
              html: "Please wait...",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            const response = await updateMinorVersion(
              platformType,
              selectedMajorVersion,
              minorID,
              environmentType,
              undefined,
              true
            );

            if (response) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Set to Active",
                confirmButtonColor: "#3085d6",
                didClose: () => {
                  fetchMinorCallBack();
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to set to Active",
                confirmButtonColor: "#3085d6"
              });
            }

            break;
          }
          case "Rollback": {
            // Swal alert, preconfirm input ROLLBACK
            const result = await Swal.fire({
              title: `Rollback To Minor Version: ${minorID}`,
              html: `Are you sure you want to rollback to this version?<br>
              <strong>This will set the latest minor version to inactive.</strong>`,
              input: "text",
              inputPlaceholder: "ROLLBACK",
              showCancelButton: true,
              confirmButtonText: "Rollback",
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancel",
              cancelButtonColor: "#d33",
              showLoaderOnConfirm: true,
              preConfirm: (inputValue) => {
                if (inputValue.toLowerCase().trim() !== "rollback") {
                  Swal.showValidationMessage(
                    `Request failed: You need to type "ROLLBACK" to confirm.`
                  );
                  return false;
                }
                return true;
              }
            });

            if (!result.isConfirmed) {
              return;
            }

            Swal.fire({
              title: `Rolling Back To Minor Version: ${minorID}`,
              html: "Please wait...",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            const response = await rollbackMinorVersion(
              platformType,
              selectedMajorVersion,
              minorID
            );

            if (response) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: `Rolled Back To Minor Version: ${minorID}`,
                confirmButtonColor: "#3085d6",
                didClose: () => {
                  window.location.reload();
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: `Failed To Rollback To Minor Version: ${minorID}`,
                confirmButtonColor: "#3085d6"
              });
            }

            break;
          }
          case "Description": {
            // Swal alert, preconfirm button
            const result = await Swal.fire({
              icon: "info",
              title: "Update Description",
              html: `Please enter the description for this version`,
              showCancelButton: true,
              confirmButtonText: "Update Description",
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancel",
              cancelButtonColor: "#d33",
              input: "text",
              inputValue: description,
              inputPlaceholder: "Description",
              showLoaderOnConfirm: true,
              inputValidator: (value) => {
                if (!value) {
                  return "Description cannot be empty.";
                } else if (value.length < 3 || value.length > 250) {
                  return "Description must be between 3 and 250 characters.";
                } else if (value === description) {
                  return "Description cannot be the same as the current description.";
                }
              },
              preConfirm: (inputValue) => {
                return inputValue;
              }
            });

            if (!result.isConfirmed) {
              return;
            }

            Swal.fire({
              title: "Updating Minor Version Description",
              html: "Please wait...",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            const response = await updateMinorVersion(
              platformType,
              selectedMajorVersion,
              minorID,
              environmentType,
              result.value,
              undefined
            );

            if (response) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: "Description Updated",
                confirmButtonColor: "#3085d6",
                didClose: () => {
                  fetchMinorCallBack();
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to update description",
                confirmButtonColor: "#3085d6"
              });
            }

            break;
          }
          default:
            console.log("Unknown action", action);
        }
      } catch (error) {
        console.error("An error occurred while sending app actions:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while sending app actions",
          confirmButtonColor: "#3085d6"
        });
      }
    };

    const promises = [sendingAppActions()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        closeDropdown();
      })
      .catch(() => {
        // At least one promise failed
        console.log("At least one promise failed");
      });
  };

  const refSettings = useRef<HTMLDivElement>(null);

  useOutsideClick(refSettings, () => {
    console.log("Outside click");
    // Action to take on outside click
    if (refSettings) {
      outsideDropdown();
    }
  });

  return (
    <div
      className={`dropdown app_actions_dropdown ${shown ? "show" : "hide"}`}
      ref={refSettings}
    >
      <div className="dropdown-content">
        {fields.length === 0 ? (
          <span>No Actions Available</span>
        ) : (
          fields.map((field: string, index: number) => {
            switch (field) {
              case "Promote":
                return (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnActionClick("Promote");
                    }}
                  >
                    <Icons.IconArrowUp className="icon" />
                    Promote To Production
                  </button>
                );
              case "AppInactive":
                return (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnActionClick("AppInactive");
                    }}
                  >
                    <Icons.IconX className="icon" />
                    Set To Inactive
                  </button>
                );
              case "AppActive":
                return (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnActionClick("AppActive");
                    }}
                  >
                    <Icons.IconCheck className="icon" />
                    Set To Active
                  </button>
                );
              case "Rollback":
                return (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnActionClick("Rollback");
                    }}
                  >
                    <Icons.IconSwitch className="icon" />
                    Rollback To This Version
                  </button>
                );
              case "Description":
                return (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={() => {
                      handleOnActionClick("Description");
                    }}
                  >
                    <Icons.IconEdit className="icon" />
                    Update Description
                  </button>
                );
              default:
                return <p key={"unknown" + index}>Unknown case {field}</p>;
            }
          })
        )}
      </div>
    </div>
  );
};

export default AppActions;
