import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { fetchSingleLeadByID, sendEditedLead } from "../../models/leads.model";
import ErrorPage from "../../pages/Error/error.page";
import {
  Client,
  Country,
  Language,
  LeadsParticipant,
  Timezone
} from "../../types";
// import ViewParticipantModalVariables from "../ViewParticipantModal/viewParticipantModalVariables.component";
import "./viewLeadModal.scss";
import ViewLeadModalEvents from "./viewLeadModalEvents";
import ViewLeadModalFooter from "./viewLeadModalFooter";
import ViewLeadModalForm from "./viewLeadModalForm";
import ViewLeadModalHeader from "./viewLeadModalHeader";
import ViewLeadModalTable from "./viewLeadModalTable";

interface ViewLeadModalProps {
  participantID: string;
  closeLeadModal: () => void;
  jsonLanguagesData: Language[];
  jsonCountriesData: Country[];
  jsonTimezonesData: Timezone[];
  shown: boolean;
  tableType: string;
  clientID: string;
  surveyID: string | undefined;
  studyID: string | undefined;
  allClients: Client[];
}

const ViewLeadModal: React.FC<ViewLeadModalProps> = ({
  participantID,
  closeLeadModal,
  jsonLanguagesData,
  jsonCountriesData,
  jsonTimezonesData,
  shown,
  tableType,
  clientID,
  surveyID,
  studyID,
  allClients
}) => {
  // find participant by id
  const [participant, setParticipant] = useState<LeadsParticipant | undefined>(
    undefined
  );
  // timezones
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingError, setLoadingError] = useState<boolean>(false);

  const [editing, setEditing] = useState<boolean>(false);
  const [viewHistory, setViewHistory] = useState<boolean>(false);

  const [cheaterScore, setCheaterScore] = useState<number>(0);

  const [participationScore, setParticipationScore] = useState<number>(0);

  const [participantName, setParticipantName] = useState<string>("");
  const [participantSurName, setParticipantSurName] = useState<string>("");
  const [participantMobile, setParticipantMobile] = useState<string>("");
  const [participantCountryID, setParticipantCountryID] = useState<string>("");
  const [participantLanguageID, setParticipantLanguageID] =
    useState<string>("");
  const [participantTimezone, setParticipantTimezone] = useState<string>("");
  const [participantTags, setParticipantTags] = useState<string[]>([]);
  // password
  const [participantPassword, setParticipantPassword] = useState<string | null>(
    null
  );
  // const [participantGender, setParticipantGender] = useState<string>("");
  // const [participantAge, setParticipantAge] = useState<number | "">("");

  const [loadingErrorText, setLoadingErrorText] = useState<string[]>([]);

  const [viewParticipantVariables, setViewParticipantVariables] =
    useState<boolean>(false);

  useEffect(() => {
    // Fetch participant data from JSON
    // console.log("participantID", participantID);
    const fetchLeadData = async () => {
      // setLoading(true);
      try {
        const jsonData = await fetchSingleLeadByID(participantID);
        // if jsonData is a string, then there was an error
        if (typeof jsonData === "string") {
          // console.log("jsonData is a string");
          // console.log("jsonData", jsonData);
          setLoadingError(true); // Set loadingError to true in case of an error
          setLoadingErrorText((prevErrors) => [
            ...prevErrors,
            "26d9e6a03c23f8cc007020e03cb49d8b"
          ]);
        } else {
          setParticipant(jsonData);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching participant data:",
          error
        );
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "9df296ff529ec382fcdbacb04120a62f"
        ]);
      }
    };

    const promises = [fetchLeadData()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setLoading(false);
      })
      .catch(() => {
        // At least one promise failed
        setLoading(false);
        setParticipant(undefined);
        setLoadingErrorText((prevErrors) => [
          ...prevErrors,
          "2587b00d55584bd284d17a4fce630ab3"
        ]);
      });
  }, []);

  const handleSave = () => {
    const sendingLeadUpdate = async () => {
      const participantIDs: string = participantID;
      try {
        const response: string = await sendEditedLead(
          participantIDs,
          // participant Fields
          participantName,
          participantSurName,
          participantMobile,
          participantCountryID,
          participantLanguageID,
          participantTimezone,
          participationScore,
          cheaterScore,
          participantTags,
          participantPassword
          // participantAge,
          // participantGender
        );

        if (response !== "success") {
          console.log("response: ", response);
          Swal.fire({
            icon: "error",
            title: "Error updating lead",
            text: response,
            confirmButtonColor: "#3085d6"
          });
        } else {
          Swal.fire({
            icon: "success",
            title: "Lead updated successfully",
            showConfirmButton: true,
            confirmButtonColor: "#3085d6",
            // add refresh page button
            showDenyButton: true,
            denyButtonColor: "#3085d6",
            denyButtonText: "Refresh Page"
          }).then((result) => {
            if (result.isDenied) {
              window.location.reload();
            }
          });
        }
      } catch (error) {
        setLoadingError(true);
        console.error("An error occurred while sending lead update:", error);
        Swal.fire({
          icon: "error",
          title: "Error updating lead",
          text: `An error occurred while updating lead: ${participantIDs}`
        });
      }
    };

    const promises = [sendingLeadUpdate()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        setEditing(false);
        closeLeadModal();
      })
      .catch(() => {
        // At least one promise failed
        console.log("At least one promise failed");
      });
  };

  const handleCancel = () => {
    // rest participant variables

    setEditing(false);
  };

  return (
    <div className={`modal view_participant_modal ${shown ? "show" : "hide"}`}>
      <div className="modal-dialog">
        <div className="modal-content">
          <span
            className="close"
            onClick={() => {
              setEditing(false);
              closeLeadModal();
            }}
          >
            &times;
          </span>
          <div className="container-fluid">
            <h5 className="modal_page_header">
              Lead{" "}
              {viewHistory
                ? "History"
                : viewParticipantVariables
                  ? "Variables"
                  : "Profile"}
            </h5>
          </div>
          {loading ? (
            <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
              <div className="spinner-border" role="status"></div>
            </div>
          ) : !loadingError && participant ? (
            <>
              {/* Display top section */}
              <ViewLeadModalHeader
                participant={participant}
                jsonCountriesData={jsonCountriesData}
              />
              {viewHistory ? (
                // TODO: add timezone switcher component
                <>
                  <ViewLeadModalEvents
                    participantID={participantID}
                    allClients={allClients}
                  />
                </>
              ) : // ) : viewParticipantVariables ? (
              //   <ViewParticipantModalVariables
              //     variables={participant.gsi_answers}
              //   />
              !editing ? (
                // If not editing display table with participant data
                <ViewLeadModalTable
                  participant={participant}
                  jsonCountriesData={jsonCountriesData}
                  jsonLanguagesData={jsonLanguagesData}
                  jsonTimezonesData={jsonTimezonesData}
                />
              ) : (
                // If editing display form with participant data
                <ViewLeadModalForm
                  participant={participant}
                  editing={editing}
                  countries={jsonCountriesData}
                  languages={jsonLanguagesData}
                  timezones={jsonTimezonesData}
                  // participant fields
                  participantName={participantName}
                  setParticipantName={setParticipantName}
                  participantSurName={participantSurName}
                  setParticipantSurName={setParticipantSurName}
                  participantMobile={participantMobile}
                  setParticipantMobile={setParticipantMobile}
                  participantCountryID={participantCountryID}
                  setParticipantCountryID={setParticipantCountryID}
                  participantLanguageID={participantLanguageID}
                  setParticipantLanguageID={setParticipantLanguageID}
                  participantTimezone={participantTimezone}
                  setParticipantTimezone={setParticipantTimezone}
                  participantTags={participantTags}
                  setParticipantTags={setParticipantTags}
                  participantPassword={participantPassword}
                  setParticipantPassword={setParticipantPassword}
                  // participantGender={participantGender}
                  // setParticipantGender={setParticipantGender}
                  // participantAge={participantAge}
                  // setParticipantAge={setParticipantAge}
                  // participant scores
                  cheaterScore={cheaterScore}
                  setCheaterScore={setCheaterScore}
                  participationScore={participationScore}
                  setParticipationScore={setParticipationScore}
                />
              )}

              {/* Display bottom section buttons */}
              <ViewLeadModalFooter
                editing={editing}
                viewHistory={viewHistory}
                handleSave={handleSave}
                handleCancel={handleCancel}
                setEditing={setEditing}
                setViewHistory={setViewHistory}
                viewingVariables={viewParticipantVariables}
                setViewParticipantVariables={setViewParticipantVariables}
              />
            </>
          ) : (
            <ErrorPage
              errorCode="404"
              error="Lead cannot be loaded"
              debugCode={loadingErrorText}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewLeadModal;
