import React from "react";
import { useParams } from "react-router-dom";
import MajorVersionUpdate from "../../components/MajorVersionUpdate/majorUpdate.component";

const MajorUpdate: React.FC = () => {
  const { token } = useParams();
  return !token || token === "" ? (
    <div className="container">
      <h3 className="text-danger">
        No token provided in link. Please check your email and try again.
      </h3>
    </div>
  ) : (
    <MajorVersionUpdate token={token} />
  );
};

export default MajorUpdate;
