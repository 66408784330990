import React, { useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import * as Icons from "../../../../../assets/images/icons/icons";
import { useOutsideClick } from "../../../../../utilities/utils";
import "./majorAction.scss";
import {
  updateMajorVersion,
  updateMajorVersionAlias
} from "../../../../../models/appVersions.model";
import { MajorVersions } from "../../../../../types";

interface MajorActionsProps {
  majorVersion: MajorVersions;
  closeDropdown: () => void;
  shown: boolean;
  selectedMajorVersion: number;
  outsideDropdown: () => void;
}

const MajorActions: React.FC<MajorActionsProps> = ({
  majorVersion,
  closeDropdown,
  shown,
  selectedMajorVersion,
  outsideDropdown
}) => {
  const { major_version, alias, deprecated } = majorVersion;
  const [fields, setFields] = useState<string[]>([]);

  // Actions available for Major Versions
  useEffect(() => {
    let fields = ["Alias"];

    if (deprecated) {
      fields.push("Enable");
    } else {
      fields.push("Deprecate");
    }

    setFields(fields);
  }, [deprecated]);

  const handleOnActionClick = (action: string, value?: string) => {
    const sendingMajorActions = async () => {
      try {
        switch (action) {
          case "Deprecate": {
            const result = await Swal.fire({
              icon: "warning",
              title: `Deprecate Major Version: ${selectedMajorVersion}`,
              html: `Are you sure you want to deprecate this major version?<br>
              You will be required to confirm the deprecation via <strong>email</strong>.<br> 
              <strong>This action will deprecate the selected major version across all platforms and environments.</strong> <br>
              <strong>Participants will receive a message to update to the latest version.</strong> 
              `,
              showCancelButton: true,
              confirmButtonText: "Deprecate",
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancel",
              cancelButtonColor: "#d33"
            });

            if (!result.isConfirmed) {
              return;
            }

            Swal.fire({
              title: `Deprecating Major Version: ${selectedMajorVersion}`,
              html: "Please wait...",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            const response = await updateMajorVersion(major_version);

            if (response) {
              Swal.fire({
                icon: "success",
                title: "Success",
                html: `Please check your email to confirm the deprecation of Major Version: ${selectedMajorVersion}`,
                confirmButtonColor: "#3085d6"
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed To Deprecate Major Version",
                confirmButtonColor: "#3085d6"
              });
            }

            break;
          }
          case "Enable": {
            const result = await Swal.fire({
              icon: "info",
              title: `Enable Major Version: ${selectedMajorVersion}`,
              html: `Are you sure you want to enable this major version?<br>
              You will be required to confirm the enabling via <strong>email</strong>.<br>
              <strong>This action will enable the selected major version across all platforms and environments.</strong> <br>
              <strong>Participants with this major version will receive updates again.</strong> `,
              showCancelButton: true,
              confirmButtonText: "Enable",
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancel",
              cancelButtonColor: "#d33",
              showLoaderOnConfirm: true
            });

            if (!result.isConfirmed) {
              return;
            }

            Swal.fire({
              title: "Enabling Major Version",
              html: "Please wait...",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            const response = await updateMajorVersion(major_version);

            if (response) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: `Please check your email to confirm the enabling of Major Version: ${selectedMajorVersion}`,
                confirmButtonColor: "#3085d6"
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed To Enable Major Version",
                confirmButtonColor: "#3085d6"
              });
            }

            break;
          }
          case "Alias": {
            const result = await Swal.fire({
              icon: "info",
              title: `Update Alias for Major Version: ${selectedMajorVersion}`,
              html: `Please enter the new alias for Major Version: ${selectedMajorVersion}`,
              showCancelButton: true,
              confirmButtonText: "Update Alias",
              confirmButtonColor: "#3085d6",
              cancelButtonText: "Cancel",
              cancelButtonColor: "#d33",
              input: "text",
              inputValue: alias,
              showLoaderOnConfirm: true,
              inputValidator: (value) => {
                if (!value) {
                  return "Alias is required";
                } else if (value.length < 3) {
                  return "Alias must be at least 3 characters";
                } else if (value.length > 50) {
                  return "Alias must be at most 50 characters";
                }
              },
              preConfirm(inputValue) {
                return inputValue;
              }
            });

            if (!result.isConfirmed || !result.value) {
              return;
            }

            Swal.fire({
              title: "Updating Alias",
              html: "Please wait...",
              allowOutsideClick: false,
              didOpen: () => {
                Swal.showLoading();
              }
            });

            const response = await updateMajorVersionAlias(
              major_version,
              result.value
            );

            if (response) {
              Swal.fire({
                icon: "success",
                title: "Success",
                text: `Alias Updated For Major Version: ${selectedMajorVersion}`,
                confirmButtonColor: "#3085d6",
                didClose: () => {
                  window.location.reload();
                }
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to update Alias",
                confirmButtonColor: "#3085d6"
              });
            }
            break;
          }

          default:
            console.log("Unknown action", action);
        }
      } catch (error) {
        console.error("An error occurred while sending app actions:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "An error occurred while sending app actions",
          confirmButtonColor: "#3085d6"
        });
      }
    };

    const promises = [sendingMajorActions()];

    Promise.all(promises)
      .then(() => {
        // All promises completed successfully
        closeDropdown();
      })
      .catch(() => {
        // At least one promise failed
        console.log("At least one promise failed");
      });
  };

  const refSettings = useRef<HTMLDivElement>(null);

  useOutsideClick(refSettings, () => {
    if (refSettings) {
      outsideDropdown();
    }
  });

  return (
    <div
      className={`dropdown major_actions_dropdown ${shown ? "show" : "hide"}`}
      ref={refSettings}
    >
      <div className="dropdown-content">
        {fields.length === 0 ? (
          <span>No Actions Available</span>
        ) : (
          fields.map((field: string, index: number) => {
            switch (field) {
              case "Deprecate":
                return (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={(e) => {
                      handleOnActionClick("Deprecate");
                      e.stopPropagation();
                    }}
                  >
                    <Icons.IconX className="icon" />
                    Deprecate
                  </button>
                );
              case "Enable":
                return (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={(e) => {
                      handleOnActionClick("Enable");
                      e.stopPropagation();
                    }}
                  >
                    <Icons.IconCheck className="icon" />
                    Enable
                  </button>
                );
              case "Alias":
                return (
                  <button
                    key={field + index}
                    className="dropdown-item"
                    onClick={(e) => {
                      handleOnActionClick("Alias");
                      e.stopPropagation();
                    }}
                  >
                    <Icons.IconEdit className="icon" />
                    Update Alias
                  </button>
                );
              default:
                return <p key={"unknown" + index}>Unknown case {field}</p>;
            }
          })
        )}
      </div>
    </div>
  );
};

export default MajorActions;
