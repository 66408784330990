import Swal from "sweetalert2";
import { apiFetcher } from "../services/API.service";
import {
  CreateParticipantSingle,
  FetchResponseError,
  LeadsParticipant,
  LeadsParticipantEvent
} from "../types";

export interface LeadsData {
  utcLastUpdate: string;
  leads: LeadsParticipant[];
}

// #region type validators
// #region isLeadsData
function isLeadsData(obj: any): obj is LeadsData {
  // console.log("Checking if object is a Participant");
  // console.log(obj, "obj");
  // console.log(obj.utcLastUpdate, "obj.utcLastUpdate");
  const validUTC = typeof obj.utcLastUpdate === "string";
  const validParticipants =
    (Array.isArray(obj.leads) &&
      obj.leads.every((leads: any) => isLeadsParticipant(leads))) ||
    obj.leads === 0;
  const AllValid: boolean = validUTC && validParticipants;
  if (!AllValid) {
    let errors: string[] = [];

    if (!validUTC) {
      if (!obj.hasOwnProperty("utcLastUpdate")) {
        errors.push("missing utcLastUpdate");
      }
    }

    if (!validParticipants) {
      if (!obj.hasOwnProperty("leads")) {
        errors.push("missing leads");
      }
    }

    if (errors && errors.length > 0) {
      // only show error if missing utcLastUpdate or leads
      let displayMessage = `<strong>Please contact support</strong><br><br>`;

      Swal.fire({
        icon: "error",
        title: `Invalid LeadsData`,
        html: displayMessage + errors.join("<br>"),
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
    }
  }

  return AllValid;
}
// #endregion isLeadsData

// #region isLeadsParticipant
function isLeadsParticipant(obj: any): boolean {
  const validID = typeof obj.id === "string";
  const validName =
    typeof obj.first_name === "string" || obj.first_name === null;
  const validSurname =
    typeof obj.last_name === "string" || obj.last_name === null;
  const validEmail = typeof obj.email === "string" || obj.email === null;
  const validMobile =
    typeof obj.mobile_number === "string" || obj.mobile_number === null;
  const validCountryISO =
    typeof obj.country_iso === "string" || obj.country_iso === null;
  const validTimezone =
    typeof obj.timezone === "string" || obj.timezone === null;
  const validAge = typeof obj.age === "number" || obj.age === null;
  const validGender = typeof obj.gender === "string" || obj.gender === null;
  const validRegion = typeof obj.region === "string" || obj.region === null;
  const validCurrentClient = typeof obj.current_client === "string";
  const validLastClient =
    typeof obj.last_client === "string" || obj.last_client === null;
  const validCheaterScore =
    typeof obj.cheater_score === "number" || obj.cheater_score === null;
  const validParticipationScore =
    typeof obj.participation_score === "number" ||
    obj.participation_score === null;
  const validLastUsed =
    typeof obj.last_used === "string" || obj.last_used === null;
  const validSource = typeof obj.source === "string" || obj.source === null;
  const validLanguageISO =
    typeof obj.language_iso === "string" || obj.language_iso === null;
  const validCreatedAt =
    typeof obj.created_at === "string" || obj.created_at === null;

  const validWSCVariables =
    (Array.isArray(obj.wsc_variables) &&
      obj.wsc_variables.every(
        (variable: any) =>
          typeof variable.var_name === "string" &&
          typeof variable.var_value === "string"
      )) ||
    obj.wsc_variables === undefined ||
    obj.wsc_variables === null;

  const AllValid =
    validID &&
    validName &&
    validSurname &&
    validEmail &&
    validMobile &&
    validCountryISO &&
    validTimezone &&
    validAge &&
    validGender &&
    validRegion &&
    validCurrentClient &&
    validLastClient &&
    validCheaterScore &&
    validParticipationScore &&
    validLastUsed &&
    validSource &&
    validLanguageISO &&
    validCreatedAt &&
    validWSCVariables;
  if (!AllValid) {
    // console.log("---isParticipant---");
    // console.log(obj);
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Participant Lead`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    if (!validName) {
      if (!obj.hasOwnProperty("first_name")) {
        errors.push("missing first_name");
      } else {
        errors.push(`Invalid type for first_name`);
      }
    }

    if (!validSurname) {
      if (!obj.hasOwnProperty("last_name")) {
        errors.push("missing last_name");
      } else {
        errors.push(`Invalid type for last_name`);
      }
    }

    if (!validEmail) {
      if (!obj.hasOwnProperty("email")) {
        errors.push("missing email");
      } else {
        errors.push(`Invalid type for email`);
      }
    }

    if (!validMobile) {
      if (!obj.hasOwnProperty("mobile_number")) {
        errors.push("missing mobile_number");
      } else {
        errors.push(`Invalid type for mobile_number`);
      }
    }

    if (!validCountryISO) {
      if (!obj.hasOwnProperty("country_iso")) {
        errors.push("missing country_iso");
      } else {
        errors.push(`Invalid type for country_iso`);
      }
    }

    if (!validTimezone) {
      if (!obj.hasOwnProperty("timezone")) {
        errors.push("missing timezone");
      } else {
        errors.push(`Invalid type for timezone`);
      }
    }

    if (!validAge) {
      if (!obj.hasOwnProperty("age")) {
        errors.push("missing age");
      } else {
        errors.push(`Invalid type for age`);
      }
    }

    if (!validGender) {
      if (!obj.hasOwnProperty("gender")) {
        errors.push("missing gender");
      }
      errors.push(`Invalid type for gender`);
    }

    if (!validRegion) {
      if (!obj.hasOwnProperty("region")) {
        errors.push("missing region");
      } else {
        errors.push(`Invalid type for region`);
      }
    }

    if (!validCurrentClient) {
      if (!obj.hasOwnProperty("current_client")) {
        errors.push("missing current_client");
      } else {
        errors.push(`Invalid type for current_client`);
      }
    }

    if (!validLastClient) {
      if (!obj.hasOwnProperty("last_client")) {
        errors.push("missing last_client");
      } else {
        errors.push(`Invalid type for last_client`);
      }
    }

    if (!validCheaterScore) {
      if (!obj.hasOwnProperty("cheater_score")) {
        errors.push("missing cheater_score");
      } else {
        errors.push(`Invalid type for cheater_score`);
      }
    }

    if (!validParticipationScore) {
      if (!obj.hasOwnProperty("participation_score")) {
        errors.push("missing participation_score");
      } else {
        errors.push(`Invalid type for participation_score`);
      }
    }

    if (!validLastUsed) {
      if (!obj.hasOwnProperty("last_used")) {
        errors.push("missing last_used");
      } else {
        errors.push(`Invalid type for last_used`);
      }
    }

    if (!validSource) {
      if (!obj.hasOwnProperty("source")) {
        errors.push("missing source");
      } else {
        errors.push(`Invalid type for source`);
      }
    }

    if (!validLanguageISO) {
      if (!obj.hasOwnProperty("language_iso")) {
        errors.push("missing language_iso");
      } else {
        errors.push(`Invalid type for language_iso`);
      }
    }

    if (!validCreatedAt) {
      if (!obj.hasOwnProperty("created_at")) {
        errors.push("missing created_at");
      } else {
        errors.push(`Invalid type for created_at`);
      }
    }

    if (!validWSCVariables) {
      if (!obj.hasOwnProperty("wsc_variables")) {
        errors.push("missing wsc_variables");
      } else {
        errors.push(`Invalid type for wsc_variables`);
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `ParticipantLeadID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Participant Lead`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isLeadsParticipant

// #region isLeadsParticipantEvent
function isLeadsParticipantEvent(obj: any): boolean {
  const validID = typeof obj.id === "string";

  const AllValid = validID;

  if (!AllValid) {
    // console.log("---isParticipantHistory---");
    // console.log(obj);
    let errors = [];

    if (!validID) {
      if (!obj.hasOwnProperty("id")) {
        errors.push("missing id");
        let displayMessage = `<strong>Please contact support</strong><br><br>`;

        Swal.fire({
          icon: "error",
          title: `Invalid Participant History`,
          html: displayMessage + errors.join("<br>"),
          showConfirmButton: true,
          confirmButtonColor: "#3085d6"
        });
        return false;
      } else {
        errors.push(`Invalid type for id`);
      }
    }

    let displayMessage = `<strong>Please contact support</strong><br><br>`;
    displayMessage += `ParticipantID: ${obj.id}<br>`;

    Swal.fire({
      icon: "error",
      title: `Invalid Participant History`,
      html: displayMessage + errors.join("<br>"),
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
  }

  return AllValid;
}
// #endregion isLeadsParticipantEvent
// #endregion type validators

// #region fetchAllLeadsParticipants
export async function fetchAllLeadsParticipants(
  forceRefresh?: boolean
): Promise<LeadsData | FetchResponseError> {
  const body = {
    forceRefresh: forceRefresh ? forceRefresh : false
  };

  try {
    const response = await apiFetcher<any>("/leads/get/all", "POST", {
      body: body
    });

    // console.log("response", response);

    if (response.status === 200 && response.data !== null) {
      // console.log(response.data);

      // THERE ARE PARTICIPANTS
      if (isLeadsData(response.data)) {
        return response.data as LeadsData;
      } else {
        console.log("Invalid data received");

        return {
          errorCode: "500",
          errorMessage: "Invalid data received"
        } as FetchResponseError;
      }

      // NO PARTICIPANTS
    } else if (response.status === 404 || response.status === 204) {
      console.log("No participants found");
      return {
        leads: [],
        utcLastUpdate: ""
      } as LeadsData;
    } else {
      console.log("Failed to fetch all participants");
      return {
        errorCode: "500",
        errorMessage: "Failed to fetch all participants"
      } as FetchResponseError;
    }
  } catch (error) {
    console.error("An error occurred while fetching participants:", error);
    return {
      errorCode: "500",
      errorMessage: "An error occurred while fetching participants"
    } as FetchResponseError;
  }
}
// #endregion fetchAllLeadsParticipants

// #region sendSingleLeadActions
export async function sendSingleLeadActions(
  leadsAction: string,
  leadID: string,
  leadsActionValue?: {
    key: string;
    value: string | string[];
  } | null
): Promise<string> {
  let route: string | null = null;
  let sendValue: any = null;

  sendValue = {
    leads: [leadID] as string[],
    bulk: false
  };

  if (leadsAction === "remove") {
    route = `/leads/remove`;
    // sendValue remains the same
  } else {
    return `Invalid Action for ${leadsAction}`;
  }

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  // console.log("body", body);
  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: sendValue
    });

    // console.log("response", response);

    if (response.status === 202) {
      return "Success";
    }

    if (response.status === 400) {
      return "Please contact support";
    }

    if (response.status === 404) {
      return "Lead not found";
    }

    if (
      response.status === 200 ||
      response.status === 206 ||
      response.status === 418
    ) {
      return "Incorrect response received";
    }

    return "Failed to send all lead actions";
  } catch (error) {
    console.error("An error occurred while sending lead actions:", error);
    return "An error occurred while sending lead actions";
  }
}
// #endregion sendSingleLeadActions

// #region createLeadImport
export async function createLeadImport(
  createLeads: CreateParticipantSingle[]
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const body = {
      leads: createLeads
    };

    // console.log("body", body);

    const response = await apiFetcher<any>("/leads/create", "POST", {
      body: body
    });
    // console.log(response);
    // console.log(response.status);
    // console.log(response.data);

    if (response.status === 200) {
      if (
        !response.data.jobID ||
        response.data.jobID === null ||
        response.data.jobID === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // console.log("response.data.id", response.data.jobID);
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "All leads added successfully to worker"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }

    if (response.status === 413) {
      return {
        rStatus: "error",
        rMessage: "File too large. Please retry with fewer leads"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Server error"
  };
}
// #endregion createLeadImport

// #region fetchSingleLeadByID
export async function fetchSingleLeadByID(
  participantID: string
): Promise<LeadsParticipant | string> {
  const body = {
    leadID: participantID
  };
  // console.log("body", body);
  try {
    const response = await apiFetcher<any>("/getLead", "POST", {
      body: body
    });

    // console.log("response", response);
    // console.log("response", response.data);

    if (response.status === 200 && response.data !== null) {
      if (isLeadsParticipant(response.data)) {
        // console.log(
        //   "VALID participant data received"
        // );
        const participant = response.data as LeadsParticipant;
        return participant;
      } else {
        console.log("Invalid lead data received");
        return "Invalid lead data received";
      }
    } else {
      console.log("response.status", response.status);
      console.log("Failed to fetch lead");
      return "Failed to fetch lead";
    }
  } catch (error) {
    console.error("An error occurred while fetching lead:", error);
    return "An error occurred while fetching lead";
  }
}
// #endregion fetchSingleLeadByID

// #region sendBulkLeadsActions
export async function sendBulkLeadsActions(
  leadsAction: string,
  leadIDs: string[],
  participantsActionValue?: {
    key: string;
    value: string | string[];
  } | null
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  let route: string | null = null;
  let sendValue: any = null;

  sendValue = {
    leads: leadIDs,
    bulk: true
  };

  if (leadsAction === "remove") {
    route = `/leads/remove`;
    // sendValue remains the same
  } else {
    return {
      rStatus: "error",
      rMessage: `Invalid Action for ${leadsAction}`
    };
  }

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  // console.log("body", body);
  try {
    const response = await apiFetcher<any>(route, "POST", {
      body: sendValue
    });
    // console.log("response", response);

    if (response.status === 200 && response.data !== null) {
      if (
        !response.data ||
        !response.data.jobID ||
        typeof response.data.jobID !== "number"
      ) {
        console.log("Invalid worker data received");
        return {
          rStatus: "error",
          rMessage: "Invalid worker data received"
        };
      }
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "All participants added successfully to worker"
      };
    }

    if (response.status === 202) {
      return {
        rStatus: "success",
        rMessage: "Worker ID not received. Please contact support"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support"
      };
    }

    if (response.status === 404) {
      return {
        rStatus: "error",
        rMessage: "Lead not found"
      };
    }

    return {
      rStatus: "error",
      rMessage: "Failed to send all participants actions"
    };
  } catch (error) {
    console.error(
      "An error occurred while sending participants actions:",
      error
    );

    return {
      rStatus: "error",
      rMessage: "An error occurred while sending participants actions"
    };
  }
}
// #endregion sendBulkLeadsActions

// #region sendEditedLead
export async function sendEditedLead(
  participantID: string,
  // participant Fields
  participant_name?: string,
  participant_surname?: string,
  participant_mobile_number?: string,
  participant_country_iso?: string,
  participant_lang_iso?: string,
  participant_timezone?: string,
  participant_score?: number,
  participant_cheating_score?: number,
  participant_tags?: string[],
  participant_password?: string | null,
  participant_age?: number | "",
  participant_gender?: string
): Promise<string> {
  // console.log(participant_cheating_score);
  //const participantsActionValue = {
  // key: "lead",
  // value: {
  //   participant_name: participant_name ? participant_name : null,
  //   participant_surname: participant_surname ? participant_surname : null,
  //   // participant_mobile_number: participant_mobile_number
  //   //   ? participant_mobile_number
  //   //   : null,
  //   participant_country_iso: participant_country_iso
  //     ? participant_country_iso
  //     : null,
  //   participant_lang_iso: participant_lang_iso ? participant_lang_iso : null,
  //   participant_timezone: participant_timezone ? participant_timezone : null,
  //   participant_score: participant_score ? participant_score : null,
  //   participant_cheating_score: participant_cheating_score
  //     ? participant_cheating_score
  //     : null,
  //   participant_tags: participant_tags ? participant_tags : null,
  //   participant_age: participant_age ? participant_age : null,
  //   participant_gender: participant_gender ? participant_gender : null,
  // },
  //};

  let body = {};
  body = {
    lead: {
      id: participantID,
      participant_name: participant_name ? participant_name : null,
      participant_surname: participant_surname ? participant_surname : null,
      participant_mobile_number: participant_mobile_number
        ? participant_mobile_number
        : null,
      participant_country_iso: participant_country_iso
        ? participant_country_iso
        : null,
      participant_lang_iso: participant_lang_iso ? participant_lang_iso : null,
      participant_timezone: participant_timezone ? participant_timezone : null,
      participant_score: participant_score ? participant_score : null,
      participant_cheating_score: participant_cheating_score
        ? participant_cheating_score
        : null,
      participant_tags: participant_tags ? participant_tags : null,
      participant_age: participant_age ? participant_age : null
    }
  };

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });
  try {
    const response = await apiFetcher<any>("/leads/update/id", "POST", {
      body: body
    });
    // console.log("response", response);
    if (response.status === 202) {
      // console.log(response.data);
      return "success";
    }

    if (response.status === 400) {
      return "Please contact support";
    }

    if (response.status === 404) {
      // return "Lead not found";
      return `Lead not found: ${participantID}`;
    }
  } catch (error) {
    console.error("An error occurred while :", error);
    return `An error occurred while updating Lead: ${participantID}`;
  }

  return `An error occurred while updating Lead: ${participantID}`;
}
// #endregion sendEditedLead

// #region fetchAllLeadsNotInClient
export async function fetchAllLeadsNotInClient(
  clientID: string
): Promise<LeadsParticipant[] | FetchResponseError> {
  const body = {
    clientID: clientID
  };

  // console.log("body", body);
  // force: forceNewData ? forceNewData : false,
  try {
    const response = await apiFetcher<any>(
      "/leads/client/get/excluded",
      "POST",
      {
        body: body
      }
    );

    // console.log("response", response);

    if (response.status === 200 && response.data !== null) {
      // console.log(response.data);

      // THERE ARE PARTICIPANTS
      if (
        response.data &&
        Array.isArray(response.data) &&
        response.data.every(isLeadsParticipant)
      ) {
        // console.log("Successfully fetched all leads participants");

        return response.data as LeadsParticipant[];
      } else {
        console.log("Invalid data received");

        return {
          errorCode: "500",
          errorMessage: "Invalid data received"
        } as FetchResponseError;
      }
      // NO PARTICIPANTS
    } else if (response.status === 404) {
      console.log("No participants found");
      return [];
    } else {
      console.log("Failed to fetch all participants");
      return {
        errorCode: "500",
        errorMessage: "Failed to fetch all participants"
      } as FetchResponseError;
    }
  } catch (error) {
    console.error("An error occurred while fetching participants:", error);
    return {
      errorCode: "500",
      errorMessage: "An error occurred while fetching participants"
    } as FetchResponseError;
  }
}
// #endregion fetchAllLeadsNotInClient

// #region addParticipantToClient
export async function addParticipantToClient(
  clientID: string,
  leads: string[]
): Promise<{
  rStatus: "success" | "error";
  rMessage: string;
}> {
  const body = {
    clientID: clientID,
    leads: leads
  };

  // console.log("body", body);

  Swal.fire({
    title: "Sending data",
    html: "Please wait while we send the data to the server",
    allowOutsideClick: false,
    didOpen: () => {
      Swal.showLoading();
    }
  });

  try {
    const response = await apiFetcher<any>("/leads/client/add", "POST", {
      body: body
    });

    if (response.status === 200) {
      if (
        !response.data.jobID ||
        response.data.jobID === null ||
        response.data.jobID === undefined
      ) {
        return {
          rStatus: "error",
          rMessage: "No worker ID received"
        };
      }

      // set Worker ID in local storage
      // console.log("response.data.id", response.data.jobID);
      localStorage.setItem("workerID", response.data.jobID);
      return {
        rStatus: "success",
        rMessage: "All participants added successfully to worker"
      };
    }

    if (response.status === 400) {
      return {
        rStatus: "error",
        rMessage: "Please contact support - Wrong format sent to server"
      };
    }
  } catch (error) {
    return {
      rStatus: "error",
      rMessage: "Please contact support - Server error"
    };
  }

  return {
    rStatus: "error",
    rMessage: "Please contact support - Server error"
  };
}
// #endregion addParticipantToClient

// #region fetchSingleLeadHistory
export async function fetchSingleLeadHistory(
  participantID: string
): Promise<LeadsParticipantEvent[] | string> {
  const body = {
    leadID: participantID
  };
  // console.log("body", body);
  try {
    const response = await apiFetcher<any>("/leads/get/events", "POST", {
      body: body
    });

    // console.log("response", response);
    // console.log("response", response.data);

    if (response.status === 200 && response.data !== null) {
      if (!Array.isArray(response.data)) {
        console.log("Invalid lead data received");
        return "Invalid lead data received";
      } else if (response.data.every(isLeadsParticipantEvent)) {
        return response.data as LeadsParticipantEvent[];
      } else {
        console.log("Invalid lead data received");
        return "Invalid lead data received";
      }
    }

    if (response.status === 404) {
      return [];
    }

    if (response.status === 400) {
      Swal.fire({
        icon: "error",
        title: `Error!`,
        text: `Please contact support<br/>Incorrect data format sent`,
        showConfirmButton: true,
        confirmButtonColor: "#3085d6"
      });
      return "Failed to fetch lead";
    }

    console.log("response.status", response.status);
    console.log("Failed to fetch lead");
    Swal.fire({
      icon: "error",
      title: `Error!`,
      text: `Please contact support<br/>Server error`,
      showConfirmButton: true,
      confirmButtonColor: "#3085d6"
    });
    return "Failed to fetch lead";
  } catch (error) {
    console.error("An error occurred while fetching lead:", error);
    return "An error occurred while fetching lead";
  }
}
// #endregion fetchSingleLeadHistory
