import React, { useEffect, useRef, useState } from "react";
import { fetchAllLanguageLibraryTranslationsByPhrase } from "../../../models/translations.model";
import {
  Language,
  LanguageLibraryPhrase,
  LanguageLibraryTranslation
} from "../../../types";
import SingleTranslation from "./singleTranslation.component";
import "./translations.scss";
// get languages from config
import Swal from "sweetalert2";
import { exportTranslations } from "../../../utilities/languageLibrary.util";
import LanguagesFilter from "../../Tables/Filters/filterLanguages.component";

export type TranslationContainerProps = {
  selectedPhrase: LanguageLibraryPhrase | null;
  loadingPhrase: boolean;
  loadingTranslation: boolean;
  setLoadingTranslation: (loading: boolean) => void;
  handleEditTranslationClick: (translation: LanguageLibraryTranslation) => void;
  allLanguages: Language[];
  translationEdited: boolean;
  setTranslationEdited: (edited: boolean) => void;
};

const TranslationContainer: React.FC<TranslationContainerProps> = ({
  loadingPhrase,
  loadingTranslation,
  setLoadingTranslation,
  handleEditTranslationClick,
  allLanguages,
  translationEdited,
  setTranslationEdited,
  selectedPhrase
}) => {
  const controllerRef = useRef(new AbortController());
  const [languageLibraryTranslations, setLanguageLibraryTranslations] =
    useState<LanguageLibraryTranslation[] | null>(null); // Set initial state to null

  const [loadingError, setLoadingError] = useState<string>("");
  const [languagesFilter, setLanguagesFilter] = useState<string[]>([]);
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [filteredTranslations, setFilteredTranslations] = useState<
    LanguageLibraryTranslation[] | null
  >(null);

  useEffect(() => {
    setLanguageLibraryTranslations(null);
    setLoadingTranslation(true);
    setLanguagesFilter([]);
    setFilterApplied(false);
    setLoadingError("");

    if (selectedPhrase || translationEdited === true) {
      // abort previous fetch
      controllerRef.current.abort();
      // Create a new AbortController
      controllerRef.current = new AbortController();
      const signal = controllerRef.current.signal;

      console.log("HIT");
      const fetchAllLanguageLibraryTranslations = async () => {
        try {
          const jsonData: string | LanguageLibraryTranslation[] =
            await fetchAllLanguageLibraryTranslationsByPhrase(
              selectedPhrase ? selectedPhrase.id : "",
              signal
            );
          // console.log("jsonData", jsonData);
          // if jsonData is a string, then there was an error
          if (typeof jsonData === "string") {
            // console.log("jsonData is a string");
            // console.log("jsonData", jsonData);
            setLanguageLibraryTranslations(null);

            setLoadingError(jsonData);
            if (!signal || !signal.aborted) {
              setLoadingTranslation(false);
            }
          } else {
            // console.log("jsonData is an object");
            // console.log("jsonData", jsonData);

            try {
              setLanguageLibraryTranslations(jsonData);
              setFilteredTranslations(jsonData);
              if (jsonData.length > 0) {
                setLoadingError("");
              } else {
                setLoadingError("No translations available.");
              }
            } catch (error) {
              console.log(
                "An error occurred while fetching translations data:",
                error
              );
              setLanguageLibraryTranslations(null);
              setLoadingError(
                "Failed to set translations. Please try again later."
              );
            }
            setLoadingTranslation(false);
          }
        } catch (error) {
          console.log(
            "An error occurred while fetching translations data:",
            error
          );
          setLanguageLibraryTranslations(null);
          setLoadingTranslation(false);
          setLoadingError(
            "Failed to load translations. Please try again later."
          );
        }
      };

      if (translationEdited === true) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Translation Modified Successfully",
          showConfirmButton: true,
          confirmButtonColor: "#3085d6",
          allowOutsideClick: false
        });
      }

      const promises = [fetchAllLanguageLibraryTranslations()];
      setTranslationEdited(false);

      Promise.all(promises).catch(() => {
        // At least one promise failed
        setLoadingError("Failed to load translations. Please try again later.");
      });
    } else {
      console.log("selectedPhraseID empty");
      setLanguageLibraryTranslations(null);
      setLoadingTranslation(false);
      setLoadingError(
        "No translations available yet. Add phrases to the library to get started."
      );
    }
  }, [
    selectedPhrase,
    translationEdited,
    setLoadingTranslation,
    setTranslationEdited
  ]);

  useEffect(() => {
    // console.log("languageLibraryTranslations:", languageLibraryTranslations);

    if (
      languagesFilter.length > 0 &&
      languageLibraryTranslations &&
      languageLibraryTranslations.length > 0
    ) {
      // console.log("languagesFilter.length > 0");
      // console.log("languageLibraryTranslations.length > 0");
      const filteredTranslations: LanguageLibraryTranslation[] = [];
      languageLibraryTranslations.forEach((translation) => {
        if (languagesFilter.includes(translation.language_iso)) {
          filteredTranslations.push(translation);
        }
      });
      // console.log("filteredTranslations:", filteredTranslations);
      setFilteredTranslations(filteredTranslations);
      setFilterApplied(true);
    } else {
      // console.log("languagesFilter.length === 0");
      // console.log("languageLibraryTranslations.length === 0");
      setFilteredTranslations(languageLibraryTranslations);
      setFilterApplied(false);
    }
  }, [languagesFilter, languageLibraryTranslations]);

  function handleExportClick() {
    console.log(selectedPhrase);
    console.log(languageLibraryTranslations);
    console.log(allLanguages);
    console.log(languagesFilter);
    if (
      selectedPhrase === null ||
      languageLibraryTranslations === null ||
      allLanguages === null
    ) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Failed to export translations. Missing data.",
        showConfirmButton: true,
        confirmButtonColor: "#3085d6",
        allowOutsideClick: false
      });
      return;
    }

    const filteredLangsTranslations =
      languagesFilter.length > 0
        ? languageLibraryTranslations.filter((translation) =>
            languagesFilter.includes(translation.language_iso)
          )
        : languageLibraryTranslations;

    exportTranslations(selectedPhrase, filteredLangsTranslations, allLanguages);
  }

  return (
    <>
      <div className="translation_container card">
        <>
          <div className="top_bar card-header">
            <div>Translations</div>

            {/*
              Should never have to add a translation
              <div
                className={`create_translation`}
                onClick={handleAddTranslationClick}
              >
                +
              </div> */}
            {selectedPhrase && (
              <button
                id="btn_translations_export"
                className="btn btn-primary ms-2"
                onClick={handleExportClick}
              >
                Export
              </button>
            )}
            <div className="table_filter_options col-auto ms-auto">
              <LanguagesFilter
                key={`table_filter_options`}
                jsonlanguagesData={allLanguages}
                onLanguagesFilterChange={setLanguagesFilter}
                filtersCleared={!filterApplied}
                location={"table"}
              />
            </div>
          </div>
          <div className="main_translation_singles_container">
            {
              /* If loading, display loading message */
              loadingPhrase || loadingTranslation ? (
                <div className="d-flex justify-content-center custom_spinner_container mt-5 mb-5">
                  <div className="spinner-border" role="status"></div>
                </div>
              ) : loadingError !== "" ? (
                <div className="error_container">
                  <div className="error_text p-2">{loadingError}</div>
                </div>
              ) : !languageLibraryTranslations ||
                languageLibraryTranslations.length === 0 ? (
                <p>No translations available</p>
              ) : filteredTranslations && filteredTranslations.length > 0 ? (
                /* map though languageLibraryTranslations */
                filteredTranslations.map((translation) => (
                  <SingleTranslation
                    key={translation.id}
                    translation={translation}
                    handleEditTranslationClick={handleEditTranslationClick}
                  />
                ))
              ) : (
                <p>No translations found</p>
              )
            }
          </div>
        </>
      </div>
    </>
  );
};

export default TranslationContainer;
