import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  ChevronLeft,
  ChevronRight,
  IconBroom
} from "../../../../../assets/images/icons/icons";
import { usePermissions } from "../../../../../contexts/UserContext";
import {
  MessagesParticipants,
  MessagesTags
} from "../../../../../models/messages.model";
import { Country, Language } from "../../../../../types";
import MultipleDropdown from "../../../../Dropdown/multipleDropdown.component";
import LoadingPencil from "../../../../Loaders/LoadingPencil";
import "./listing.scss";

interface listingProps {
  participants: MessagesParticipants[] | undefined;
  recipients: MessagesParticipants[] | undefined;
  setRecipients: (recipients: MessagesParticipants[]) => void;
  tags: MessagesTags[] | undefined;
  languages: Language[];
  countries: Country[];
}

const ParticipantList: React.FC<listingProps> = ({
  participants,
  recipients,
  setRecipients,
  tags,
  languages,
  countries
}) => {
  const [filterData, setFilterData] = useState<
    MessagesParticipants[] | undefined
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  //Filters
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [selectedStatus, setSelectedStatus] = useState<string[]>([]);
  const statusOption: string[] = [
    // Disused features disabled
    // "Active",
    // "Pending Invitation",
    "Blocked",
    // "Declined",
    "In Progress",
    // "Completed",
    // "Invitation Not Sent",
    // "App Invitation Sent",
    "Flagged"
  ];
  //The other options are pass in as props
  const [selectedLanguage, setSelectedLanguage] = useState<string[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string[]>([]);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [searchInput, setSearchInput] = useState<string>("");
  const [checkedParticipants, setCheckedParticipants] = useState<string[]>([]);
  // const [displayedParticipants, setDisplayedParticipants] = useState<
  //   MessagesParticipants[]
  // >([]);
  // const [sliceEnd, setSliceEnd] = useState<number>(200); // initial number of items to display

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;

  const handleNext = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevious = () => {
    setCurrentPage((prevPage) => (prevPage === 1 ? prevPage : prevPage - 1));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [filterData]);

  const { hasPermission } = usePermissions();

  // useEffect(() => {
  //   if (participants) {
  //     setDisplayedParticipants(participants.slice(0, sliceEnd));
  //   }
  // }, [participants, sliceEnd]);

  // const loadMoreData = () => {
  //   if (participants && sliceEnd < participants.length) {
  //     setSliceEnd(sliceEnd + 200); // Load 200 more items
  //   }
  // };

  // const handleScroll: React.UIEventHandler<HTMLDivElement> = (e) => {
  //   const target = e.currentTarget;
  //   const bottom =
  //     target.scrollHeight - target.scrollTop === target.clientHeight;
  //   if (bottom) {
  //     loadMoreData();
  //   }
  // };

  // useEffect(() => {
  //   //console.log(currentParticipants, filteredParticipants);
  //   if (!errorFetchingData && filteredParticipants) {
  //     setCurrentParticipants(
  //       filteredParticipants.slice(
  //         (currentPage - 1) * participantsPerPage,
  //         currentPage * participantsPerPage
  //       )
  //     );
  //   }
  // }, [
  //   currentPage,
  //   participantsPerPage,
  //   filteredParticipants,
  //   errorFetchingData,
  // ]);

  // // useEffect to update the number of displayed participants
  // useEffect(() => {
  //   if (participants) {
  //     const start = (currentPage - 1) * itemsPerPage;
  //     const end = currentPage * itemsPerPage;
  //     console.log("start", start, "end", end);
  //     setFilterData(participants.slice(start, end));
  //   }
  // }, [currentPage, participants]);

  /*------------------Loading data----------------------*/
  /*-----------------useEffect to handle the scroll list---------------------*/
  useEffect(() => {
    // Update the CSS variables when the component mounts or when showFilters changes
    const header = document.querySelector(".header-container");
    const filter = document.querySelector(".filter-container");

    const headerHeight =
      header instanceof HTMLElement ? header.offsetHeight : 0;
    const filterHeight =
      showFilters && filter instanceof HTMLElement ? filter.offsetHeight : 0;

    document.documentElement.style.setProperty(
      "--header-container-height",
      `${headerHeight}px`
    );
    document.documentElement.style.setProperty(
      "--filter-container-height",
      `${filterHeight}px`
    );
  }, [showFilters]);

  /*-------------------------------------------------*/
  /*------------------Filtering----------------------*/
  const handleStatusChange = (option: string) => {
    setSelectedStatus((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const handleLanguageChange = (option: string) => {
    setSelectedLanguage((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const handleCountryChange = (option: string) => {
    setSelectedCountry((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };

  const handleTagsChange = (option: string) => {
    setSelectedTags((prev) =>
      prev.includes(option)
        ? prev.filter((item) => item !== option)
        : [...prev, option]
    );
  };
  //Clear filters
  const handleClearFilters = () => {
    setSelectedStatus([]);
    setSelectedLanguage([]);
    setSelectedCountry([]);
    setSelectedTags([]);
    setSearchInput("");
  };
  //Show filters
  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };
  //Filtering data
  useEffect(() => {
    if (!Array.isArray(participants)) {
      setFilterData([]);
      return;
    }
    setIsLoading(true);
    let filteredParticipants = [...participants];
    // Filter by tags
    if (selectedTags.length > 0) {
      //Set selected tags to lower case
      const lowerCaseSelectedTags = selectedTags.map((st) => st.toLowerCase());
      //Filter the participants by the selected tags
      filteredParticipants = filteredParticipants.filter((p) =>
        p.participant_tags.some((tag) =>
          lowerCaseSelectedTags.includes(tag.toString().toLowerCase())
        )
      );
    }

    // Filter by status, with special handling for "Flagged"
    if (selectedStatus.length > 0) {
      const lowerCaseSelectedStatus = selectedStatus.map((st) =>
        st.toLowerCase()
      );
      if (lowerCaseSelectedStatus.includes("flagged")) {
        filteredParticipants = filteredParticipants.filter(
          (participant) => participant.participant_flagged === 1
        );
      } else {
        // If "Flagged" is not selected, filter by the selected statuses
        filteredParticipants = filteredParticipants.filter((participant) =>
          lowerCaseSelectedStatus.includes(
            participant.study_status.toLowerCase()
          )
        );
      }
    }

    // Filter by language
    //Filtering for language is a bit different because the language is stored as a iso_code but the options are iso_code - name but some
    //of the iso_code are the same for different languages
    if (selectedLanguage.length > 0) {
      const languageISO = languages
        .filter((language) =>
          selectedLanguage.includes(language.iso_code + " - " + language.name)
        )
        .map((language) => language.iso_code);
      filteredParticipants = filteredParticipants.filter((participant) =>
        languageISO.some(
          (languageIso) => participant.participant_lang_iso === languageIso
        )
      );
    }

    // Filter by country using the country ISO code country_iso "ZA" the selectedCountry is the names of the countries
    if (selectedCountry.length > 0) {
      const countryISO = countries
        .filter((country) => selectedCountry.includes(country.name))
        .map((country) => country.iso_code);
      filteredParticipants = filteredParticipants.filter((participant) =>
        countryISO.some(
          (countryIso) => participant.participant_country_iso === countryIso
        )
      );
    }

    // Filter by search term
    if (searchInput.length > 0) {
      const trimmedSearchInput = searchInput.trim().toLowerCase();
      filteredParticipants = filteredParticipants.filter(
        (participant) =>
          participant.participant_full_name
            .toLowerCase()
            .includes(trimmedSearchInput) ||
          participant.id === trimmedSearchInput ||
          participant.external_id === trimmedSearchInput ||
          participant.participant_email
            .toLowerCase()
            .includes(trimmedSearchInput)
      );
    }

    // // filter out recipients
    // if (recipients) {
    //   filteredParticipants = filteredParticipants.filter(
    //     (participant) => !recipients.some((r) => r.id === participant.id)
    //   );
    // }

    setIsLoading(false);
    setFilterData(filteredParticipants);
  }, [
    selectedStatus,
    selectedLanguage,
    selectedCountry,
    selectedTags,
    searchInput,
    participants,
    // recipients,
    setRecipients
  ]);

  /*------------------------------------------------*/
  /*------------------Function----------------------*/
  // Function to check or uncheck all participants
  const toggleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      // Set all filtered participant IDs as checked
      const allParticipantIds = filterData?.map((p) => p.id) || [];
      setCheckedParticipants(allParticipantIds);
    } else {
      // Clear all selections
      setCheckedParticipants([]);
    }
  };

  // Function to check or uncheck a single participant
  const toggleCheckParticipant = (
    participantId: string,
    isChecked: boolean
  ) => {
    setCheckedParticipants((currentChecked) => {
      if (isChecked) {
        // Add participant ID to the list
        return [...currentChecked, participantId];
      } else {
        // Remove participant ID from the list
        return currentChecked.filter((id) => id !== participantId);
      }
    });
  };

  // Function to add multiple to recipients
  const handleMultipleAddRecipient = () => {
    if (checkedParticipants.length === 0) {
      Swal.fire({
        icon: "warning",
        title: "No participants selected",
        text: "Please select at least one participant.",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    const participantsToAdd =
      filterData?.filter((p) => checkedParticipants.includes(p.id)) || [];
    setRecipients(participantsToAdd);
  };
  // Function to add a single participant to recipients
  const handleSingleAddRecipient = (participantId: string) => {
    if (recipients && recipients.some((r) => r.id === participantId)) {
      Swal.fire({
        icon: "warning",
        title: "Participant already added",
        text: "This participant is already in the recipient list.",
        confirmButtonColor: "#3085d6"
      });
      return;
    }
    const participantToAdd = filterData?.find((p) => p.id === participantId);

    if (participantToAdd) {
      setRecipients([...(recipients || []), participantToAdd]);
    }
  };

  return (
    <div className="participant-container">
      <div className="header-container">
        <h5 className="title">Participants</h5>
        <h5 className="count">{filterData?.length}</h5>
        <button className="filter-toggle-btn" onClick={toggleFilters}>
          Filter
        </button>
      </div>
      {showFilters && (
        <div className="container p-4 filter-container">
          <div className="row pb-1">
            <div className="col p-1">
              <MultipleDropdown
                placeholder="Status"
                options={statusOption}
                selectedOptions={selectedStatus}
                onOptionToggled={handleStatusChange}
              />
            </div>
            <div className="col p-1">
              <MultipleDropdown
                placeholder="Language"
                options={languages.map(
                  (language) => language.iso_code + " - " + language.name
                )}
                selectedOptions={selectedLanguage}
                onOptionToggled={handleLanguageChange}
              />
            </div>
          </div>
          <div className="row  pb-1">
            <div className="col p-1">
              <MultipleDropdown
                placeholder="Country"
                options={countries.map((country) => country.name)}
                selectedOptions={selectedCountry}
                onOptionToggled={handleCountryChange}
              />
            </div>
            <div className="col p-1">
              <MultipleDropdown
                placeholder="Tags"
                options={
                  tags && Array.isArray(tags)
                    ? tags.map((tag) => tag.name)
                    : ["No tags Found"]
                }
                selectedOptions={selectedTags}
                onOptionToggled={handleTagsChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col p-1 search-input">
              <input
                className="form-control"
                type="text"
                placeholder="Search"
                value={searchInput}
                onChange={(e) => setSearchInput(e.target.value)}
              />
              <button
                className="btn btn-primary btn-clear"
                disabled={false}
                onClick={handleClearFilters}
              >
                <IconBroom className="icon icon_white" />
              </button>
            </div>
          </div>
        </div>
      )}
      <div className="list-container participants">
        {typeof participants === "string" ? (
          <p> No participants found</p>
        ) : (
          <div className="participant-list">
            {(hasPermission("subject") ||
              hasPermission("message", "all") ||
              hasPermission("messages", "write")) && (
              <div className="list-header">
                <div className="checkbox-container">
                  <input
                    type="checkbox"
                    onChange={(e) => toggleSelectAll(e.target.checked)}
                    checked={
                      checkedParticipants &&
                      checkedParticipants.length === filterData?.length &&
                      filterData?.length !== 0
                    }
                    // The above will check the 'Select All' checkbox if all participants are selected
                  />
                  <h5 className="select-all">Select All</h5>
                  <div className="participant-actions">
                    <button
                      className="action-btn"
                      onClick={handleMultipleAddRecipient}
                    >
                      Add <strong>{checkedParticipants.length}</strong>
                    </button>
                  </div>
                </div>
              </div>
            )}
            {filterData && filterData.length > 0 ? (
              filterData
                .slice(
                  (currentPage - 1) * itemsPerPage,
                  currentPage * itemsPerPage
                )
                .map((participant) => (
                  <div className="list-item" key={participant.id}>
                    {(hasPermission("subject") ||
                      hasPermission("message", "all") ||
                      hasPermission("messages", "write")) && (
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          onChange={(e) =>
                            toggleCheckParticipant(
                              participant.id,
                              e.target.checked
                            )
                          }
                          checked={checkedParticipants.includes(participant.id)}
                        />
                      </div>
                    )}
                    <div className="participant-info">
                      <h5 className="name">
                        {participant.participant_full_name}
                      </h5>
                      <h5 className="language">
                        {participant.participant_lang_iso}
                      </h5>
                    </div>
                    <div className="participant-actions">
                      {(hasPermission("subject") ||
                        hasPermission("message", "all") ||
                        hasPermission("messages", "write")) && (
                        <button
                          className="action-btn"
                          onClick={() =>
                            handleSingleAddRecipient(participant.id)
                          }
                        >
                          Add
                        </button>
                      )}
                    </div>
                  </div>
                ))
            ) : isLoading ? (
              <LoadingPencil title={"participants"} isVisible={isLoading} />
            ) : (
              <div className="no-participants">
                <p>No participants found</p>
              </div>
            )}
            {filterData && filterData.length > itemsPerPage && (
              <div className="d-flex justify-content-center participant-pagination">
                <button
                  className="btn btn-link"
                  onClick={handlePrevious}
                  disabled={currentPage === 1}
                >
                  <ChevronLeft />
                </button>
                <button
                  className="btn btn-link"
                  onClick={handleNext}
                  disabled={currentPage * itemsPerPage >= filterData.length}
                >
                  <ChevronRight />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default ParticipantList;
