import React, { useEffect, useRef, useState } from "react";
import { IconInvisible, IconVisible } from "../../assets/images/icons/icons";
import {
  Country,
  Language,
  Researcher,
  ResearcherRole,
  Timezone
} from "../../types";

interface ViewResearcherModalFormProps {
  researcher: Researcher;
  editing: boolean;
  languages: Language[];
  countries: Country[];
  timezones: Timezone[];
  roles: ResearcherRole[];
  // researcher fields
  researcherName: string;
  researcherSurName: string;
  researcherMobile: string;
  researcherCountryID: string;
  researcherLanguageID: string;
  researcherTimezone: string;
  researcherPassword: string | null;
  researcherRoleID: string;
  canBeSaved: boolean;
  // Set researcher fields
  setResearcherName: (value: string) => void;
  setResearcherSurName: (value: string) => void;
  setResearcherMobile: (value: string) => void;
  setResearcherCountryID: (value: string) => void;
  setResearcherLanguageID: (value: string) => void;
  setResearcherTimezone: (value: string) => void;
  setResearcherPassword: (value: string) => void;
  setResearcherRoleID: (value: string) => void;
  setCanBeSaved: (value: boolean) => void;
}

const ViewResearcherModalForm: React.FC<ViewResearcherModalFormProps> = ({
  researcher,
  editing,
  languages,
  countries,
  timezones,
  roles,
  // researcher fields
  researcherName,
  researcherSurName,
  researcherMobile,
  researcherCountryID,
  researcherLanguageID,
  researcherTimezone,
  researcherPassword,
  researcherRoleID,
  canBeSaved,
  // Set researcher fields
  setResearcherName,
  setResearcherSurName,
  setResearcherMobile,
  setResearcherCountryID,
  setResearcherLanguageID,
  setResearcherTimezone,
  setResearcherPassword,
  setResearcherRoleID,
  setCanBeSaved
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  //Validate researcher fields
  useEffect(() => {
    let error = "";

    if (researcherName === "") {
      error = "Name is required";
    }
    if (researcherSurName === "") {
      error = "Surname is required";
    }

    setErrorMessage(error);
    setCanBeSaved(error === "");
    console.log(canBeSaved);
  }, [researcherName, researcherSurName]);

  useEffect(() => {
    setResearcherName(researcher?.first_name || "");
    setResearcherSurName(researcher?.surname || "");
    setResearcherMobile(researcher?.full_mobile || "");
    setResearcherCountryID(researcher?.country_iso || "");
    setResearcherLanguageID(researcher?.language_iso || "");
    setResearcherTimezone(researcher?.timezone || "");
    setResearcherRoleID(researcher?.role_id || "");
  }, [researcher]);

  function handleChangeMobile(event: React.ChangeEvent<HTMLInputElement>) {
    // only allow numbers and "+"
    const re = /^[0-9\b+]+$/;
    if (event.target.value === "" || re.test(event.target.value)) {
      setResearcherMobile(event.target.value);
    }
  }

  return (
    <div className="modal-body edit_researcher_container">
      <div className="container-fluid">
        <div className="row">
          <div className="col-4">ID</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="_id"
              value={researcher.id.toString()}
              disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Email</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="email"
              value={researcher.email}
              disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Mobile number</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="mobile_number"
              value={researcherMobile}
              onChange={(e) => handleChangeMobile(e)}
              placeholder="Enter Mobile Number"
              //disabled={true} //unchangeable
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Name</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="name"
              value={researcherName}
              onChange={(e) => setResearcherName(e.target.value.trim())}
              placeholder="Enter name"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-4">Surname</div>
          <div className="col-8">
            <input
              type="text"
              className="form-control"
              name="surname"
              value={researcherSurName}
              onChange={(e) => setResearcherSurName(e.target.value.trim())}
              placeholder="Enter surname"
            />
          </div>
        </div>

        <div className="row">
          <div className="col-4">Country</div>
          <div className="col-8">
            <select
              className="form-control"
              name="country_id"
              value={researcherCountryID}
              onChange={(e) => setResearcherCountryID(e.target.value)}
            >
              <option value="">Select country</option>
              {countries.map((country) => (
                // if the country is the same as the researcher's country, select it
                <option key={country.iso_code} value={country.iso_code}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Language</div>
          <div className="col-8">
            <select
              className="form-control"
              name="language_id"
              value={researcherLanguageID}
              onChange={(e) => setResearcherLanguageID(e.target.value)}
            >
              <option value="">Select language</option>
              {languages.map((language) => (
                // if the language is the same as the researcher's language, select it
                <option key={language.iso_code} value={language.iso_code}>
                  {language.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-4">Timezone</div>
          <div className="col-8">
            <select
              className="form-control"
              name="timezone"
              value={researcherTimezone}
              onChange={(e) => setResearcherTimezone(e.target.value)}
            >
              <option value="">Select timezone</option>
              {timezones.map((timezone) => (
                // if the timezone is the same as the researcher's timezone, select it
                <option key={timezone.name} value={timezone.name}>
                  (
                  {timezone.offset > 0
                    ? "+" + timezone.offset
                    : timezone.offset}
                  ){" "}
                  {
                    // replace _ with space
                    timezone.name.replace(/_/g, " ")
                  }
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* roles */}
        <div className="row">
          <div className="col-4">Role</div>
          <div className="col-8">
            <select
              className="form-control"
              name="role"
              value={researcherRoleID}
              onChange={(e) => setResearcherRoleID(e.target.value)}
            >
              <option value="">Select role</option>
              {roles.map((role) => (
                // if the role is the same as the researcher's role, select it
                <option key={role.id} value={role.id}>
                  {role.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-4">Password</div>
          <div className="col-8 password_container">
            <input
              type={showPassword ? "text" : "password"}
              className="form-control"
              name="password"
              value={researcherPassword || ""}
              onChange={(e) => setResearcherPassword(e.target.value.trim())}
              placeholder="Enter new password"
            />
            <button
              type="button"
              className="btn_password"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <IconVisible className="icon icon_black" />
              ) : (
                <IconInvisible className="icon icon_black" />
              )}
            </button>
          </div>
        </div> */}
      </div>
      <p className="error-message">{errorMessage}</p>
    </div>
  );
};

export default ViewResearcherModalForm;
